import styled, { css } from 'styled-components'

export const Container = styled.p<{ $redBg?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  gap: 1.2rem;
  padding: 1rem;
  border-radius: 0.4rem;
  color: ${({ theme }) => theme.colors.tundora};
  background: ${({ theme }) => theme.colors.selectiveYellow};
  ${({ $redBg }) =>
    $redBg &&
    css`
      background: ${({ theme }) => theme.colors.redLight};
    `};
`

export const Number = styled.span`
  ${({ theme }) => theme.textStyles.titleH4};
`
export const Text = styled.span`
  ${({ theme }) => theme.textStyles.titleH5};
`
