import styled, { css } from 'styled-components'

export const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Dot = styled.span<{
  variant?:
    | 'default'
    | 'validate'
    | 'delete'
    | 'leather'
    | 'sourcing'
    | 'accessories'
    | 'inspiration'
    | 'design'
    | 'forumSelect'
    | 'bursted'

  $little: boolean | undefined
}>`
  width: 6px;
  height: 6px;
  ${({ $little }) =>
    $little &&
    css`
      width: 4px;
      height: 4px;
    `};
  border-radius: 50%;
  background-color: currentColor;
  ${(props) => {
    switch (props.variant) {
      case 'default':
        return css`
          background-color: ${(props) => props.theme.colors.selectiveTextYellow};
        `
      case 'validate':
        return css`
          background-color: ${(props) => props.theme.colors.chateauGreen};
        `
      case 'delete':
        return css`
          background-color: ${(props) => props.theme.colors.cinnabar};
        `
      case 'leather':
        return css`
          background-color: ${(props) => props.theme.colors.roseOfSharon};
        `
      case 'sourcing':
        return css`
          background-color: ${(props) => props.theme.colors.danube};
        `
      case 'accessories':
        return css`
          background-color: ${(props) => props.theme.colors.pizza};
        `
      case 'inspiration':
        return css`
          background-color: ${(props) => props.theme.colors.royalPurple};
        `
      case 'design':
        return css`
          background-color: ${(props) => props.theme.colors.fuchsiaPink};
        `
      case 'forumSelect':
        return css`
          background-color: ${(props) => props.theme.colors.tundora};
        `
    }
  }}
`
