import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const getSampleById = getQueryService({
  query: gql(`
    query sampleById($sampleId: ID!) {
            sampleById(sampleId: $sampleId) {
                id
                isArchived
                isDeleted
                marketplaceImported
                marketplaceImportError
                universeRelation {
                    type
                    universeEbmsId
                }
                collection {
                    id
                    exhibitionApiId
                    exhibitor {
                        ebmsId
                        name
                        pavilion {
                            ebmsId
                            name
                        }
                    }
                    # This is resource consuming; please use it only on sampleById and collectionByExhibitor queries
                    samplesStats {
                        status
                        count
                    }
                }
                universe {
                    ebmsId
                    name
                }
                labels {
                    id
                    user {
                        id
                        email
                    }
                    url
                    path
                    order
                }
                name
                collectionRef
                qrCode
                applyToCollectionUniverse
                status
                statusUpdatedAt
                boxApplyToCollection
                box {
                    id
                    name
                }
                forumApplyToCollection
                forum {
                    id
                    name
                    color
                }
                subForumApplyToCollection
                subForum {
                    id
                    name
                    color
                }
                mkpForum
                flagItems {
                    id
                    group {
                        id
                        miraklRef
                    }
                    name
                    pivot {
                        value
                        applyToCollection
                    }
                }
                photos {
                    id
                    user {
                        id
                        email
                    }
                    url
                    path
                    order
                }
                editorials{
                    name,
                    description
                }
                createdAt
                updatedAt
            }
        }
    `),
  transformer: (response) => {
    return response
  },
})
