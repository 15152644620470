import * as Yup from 'yup'

import { Icons } from '../../Icon'

import { LoginFormProps, LoginFormValues } from './index'

const basic: LoginFormProps = {
  formikForm: {
    initialValues: {
      email: '',
      password: '',
    } as LoginFormValues,
    validationSchema: Yup.object().shape({
      email: Yup.string().required().email(),
      password: Yup.string().required(),
    }),
    onSubmit: () => undefined,
    validateOnChange: true,
    validateOnMount: true,
  },
  fields: {
    email: {
      name: 'email',
      label: 'E-mail',
      required: true,
    },
    password: {
      name: 'password',
      label: 'Mot de passe',
      required: true,
    },
  },
  forgottenPasswordLink: {
    text: 'Mot de passe oublié ?',
  },
  submitButton: {
    text: 'Se connecter',
    rightIconProps: { icon: Icons.arrowRight, width: 22, height: 22 },
  },
}

const basicValidated: LoginFormProps = {
  ...basic,
  submitButton: {
    text: 'Se connecter',
    disabled: false,
  },
}

export const loginFormMocks = {
  basic,
  basicValidated,
}
