import styled from 'styled-components'
import { Field } from 'formik'

import breakpoints from '../../theme/breakpoints'
import ActionButton from '../ActionButton'
import Icon from '../Icon'
import FormikForm from '../form/FormikForm'
import FormErrors from '../form/FormErrors'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
  ${breakpoints.up('desktop')} {
  }
`
export const Buttons = styled.div`
  display: flex;
  flex-flow: row;
  gap: 2.4rem;
`
export const MiraklTitle = styled.div`
  ${({ theme }) => theme.textStyles.textBold};
`
export const AddButton = styled(ActionButton)`
  flex: 1 1 auto;
  & span {
    margin-left: auto;
  }
  ${breakpoints.up('tablet')} {
    flex: 0 1 auto;
    width: 100%;
    justify-content: space-between;
    & span {
      margin-left: 0;
    }
  }
`
export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.8rem;
  & li {
  }
`
export const Item = styled.button`
  ${({ theme }) => theme.textStyles.titleH4};
  text-align: left;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
  border: 0 none;
  padding: 1.2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.tundora};
  background-color: ${({ theme }) => theme.colors.concrete};
`
export const ItemIcon = styled(Icon)`
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  ${Item}:hover &,
  ${Item}:focus & {
    opacity: 1;
  }
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  background-color: ${({ theme }) => theme.colors.concrete};
  & input {
    ${({ theme }) => theme.textStyles.titleH4};
    flex: 1;
    padding: 0;
    margin: 0;
    border: 0 none;
    border-bottom: 0.15rem solid ${({ theme }) => theme.colors.mineShaft};
    color: ${({ theme }) => theme.colors.mineShaft};
    background: transparent;
    outline: 0 none;
  }
`
export const FormBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
`
export const FormBlockMirakl = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
export const StyledField = styled(Field)`
  max-width: 40%;
`
export const FormButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`
export const FormButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0 none;
  background: transparent;
  cursor: pointer;
`
export const FormIcon = styled(Icon)``
export const DeleteButton = styled(ActionButton)`
  margin-inline: auto;
`
export const Errors = styled(FormErrors)``
