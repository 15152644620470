import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { fonts } from '../../../theme'
import CollapsibleItem from '../../CollapsibleItem'
import ActionButton from '../../ActionButton'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const StyledFormikForm = styled(FormikForm)`
  width: 100%;
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  justify-content: center;
`

export const StyledFormikField = styled(FormikField)`
  & > div {
    gap: 0.8rem;
    margin-left: 3rem;
  }
`

export const Title = styled.div`
  font-size: 1.4rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.gray};
`

export const TextField = styled(FormikField)``

export const Collapsible = styled(CollapsibleItem)`
  margin-bottom: 1.2rem;
  border-radius: 0.4rem;
  span.styled__Title-sc-1hyycnl-2 {
    font-size: 15px;
  }
`

export const Button = styled(ActionButton)`
  margin: auto;
  margin-top: 2.4rem;
`
