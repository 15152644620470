import React, { useEffect, useRef, useState } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import { actions, selectors } from '../../../redux'
import ExhibitionSheetExhibitorsTemplate from '../../../templates/ExhibitionSheetExhibitor'
import { useExhibitionSheetExhibitorsTemplateProps } from '../../../templates/ExhibitionSheetExhibitor/useProps'
import FiltersExport from '../../../components/FiltersExport'
import { useFiltersExportTemplateProps } from '../../../templates/Filters/usePropsExport'
import { Icons } from '../../../components/Icon'
import { FiltersExportFormValues } from '../../../components/forms/FiltersExportForm'

const ExhibitionSheetExhibitor: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const exhibition = useSelector(selectors.exhibitions.exhibitions)
  const exportExhibitors = useSelector(selectors.exhibitors.exportExhibitors)
  const configuration = useSelector(selectors.configuration.configurationInfo)
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [universes, setUniverses] = useState<string[]>([])
  const [pavilions, setPavilions] = useState<string[]>([])
  const [countries, setCountries] = useState<string[]>([])
  const [exhibitorsNbr, setExhibitorsNbr] = useState<string>('')
  const [initialValues, setInitialValues] = useState<FiltersExportFormValues>({
    UNIVERSES: [],
    PAVILIONS: [],
    COUNTRIES: [],
  })
  const universesConfig = configuration?.dictionaries.find((item) => item.type === 'UNIVERSES')
    ?.items

  const ref = useRef<FormikProps<FiltersExportFormValues>>(null)

  useEffect(() => {
    if (exhibition) {
      let filteredExhibitors = exhibition.exhibitors
      if (universes.length !== 0) {
        filteredExhibitors = filteredExhibitors.filter((item) =>
          universes.includes(item.universe.name)
        )
      }
      if (pavilions.length !== 0) {
        filteredExhibitors = filteredExhibitors.filter((item) =>
          pavilions.includes(item.pavilion.name)
        )
      }
      if (countries.length !== 0) {
        filteredExhibitors = filteredExhibitors.filter((item) =>
          countries.includes(item.country as string)
        )
      }
      setExhibitorsNbr(filteredExhibitors.length.toString())
    }
  }, [countries, exhibition, pavilions, universes])

  useEffect(() => {
    exhibitionId &&
      dispatch(
        actions.exhibitions.exhibitionByApiIdRequest({
          exhibitionApiId: exhibitionId,
        })
      )
  }, [dispatch, exhibitionId])

  useEffect(() => {
    if (exportExhibitors.success || exportExhibitors.errors) {
      handleFiltersClick()
      setTimeout(() => {
        dispatch(actions.exhibitors.resetExportExhibitors())
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, exportExhibitors])

  useEffect(() => {
    if (ref.current) {
      setUniverses(ref.current?.values.UNIVERSES)
      setPavilions(ref.current?.values.PAVILIONS)
      setCountries(ref.current?.values.COUNTRIES)
    }
  }, [initialValues])

  const handleFiltersClick = () => {
    setOpenFilters((prevOpenFilters) => !prevOpenFilters)
    if (universes.length === universesConfig?.length) setUniverses([])
  }

  const handleExportAllClick = () => {
    setUniverses([])
    setCountries([])
    setPavilions([])
  }

  const filterstemplateProps = useFiltersExportTemplateProps({
    handleFiltersClick,
    openFilters,
    isExponent: true,
    pavilions,
    universes,
    countries,
    handleExportAllClick,
    initialValues,
  })

  const templateProps = useExhibitionSheetExhibitorsTemplateProps(
    exhibition,
    handleFiltersClick,
    exhibitorsNbr,
    universes
  )

  return (
    <>
      <ExhibitionSheetExhibitorsTemplate
        {...templateProps}
        layoutProps={{
          ...templateProps.layoutProps,
          toastModal:
            exportExhibitors && (exportExhibitors.success || exportExhibitors.errors)
              ? {
                  text: "L'export a bien été envoyé par email.",
                  isOpen: true,
                  icon: { icon: Icons.check, height: 20, width: 20 },
                }
              : undefined,
        }}
        formikForm={{
          innerRef: ref,
          initialValues: {
            UNIVERSES: universesConfig
              ? (universesConfig.map((item) => item.value) as string[])
              : [],
            PAVILIONS: [],
            COUNTRIES: [],
          },
          onSubmit: () => undefined,
          onContextUpdate(context) {
            setInitialValues(context.values)
          },
        }}
      />
      <FiltersExport
        {...filterstemplateProps}
        isOpen={openFilters}
        onClose={handleFiltersClick}
        exhibitorFilters
      />
    </>
  )
}

export default ExhibitionSheetExhibitor
