import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ParamsMailsTemplate from '../../templates/Params'
import { useParamsMailsTemplateProps } from '../../templates/Params/usePropsMails'
import { selectors, actions } from '../../redux'
import { Icons } from '../../components/Icon'
import ModalToast from '../../components/ModalToast'

const ParamsEmails: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()

  const templateProps = useParamsMailsTemplateProps()
  const mailsUpsert = useSelector(selectors.mails.mailTemplateUpsert)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (mailsUpsert && mailsUpsert.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.mails.resetMailTemplateUpsert())
      }, 2000)
    }
  }, [dispatch, mailsUpsert])

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text="Les modifications ont bien été apportées à l'e-mail."
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}{' '}
      <ParamsMailsTemplate {...templateProps} />
    </>
  )
}

export default ParamsEmails
