import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const editorialById = getQueryService({
  query: gql(`
    query editorialById($editorialId: ID!) {
        editorialById(editorialId: $editorialId) {
            id
            name
            description
            exhibition {
                id
                apiId
                name
            }
            samples {
                id
				isDeleted
                name
                status
                forum{
                    name
                    color
                }
                subForum{
                    name
                    color
                }
                mkpForum
                flagItems {
                    id
                    group {
                    id
                    miraklRef
                    }
                    name
                    cascadingGroups
                }
                photos {
                id
                user {
                id
                email
                }
                url
                path
                order
            }
            }
        }
    }
`),
  transformer: (response) => {
    return response
  },
})
