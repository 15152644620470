import { all, put, takeEvery } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'
import { Collection, Exhibitor } from '../../graphql/generated/graphql'
import { selectors } from '../exhibitors/redux'

export default class ExhibitorsSagas {
  static *onInit() {
    yield put(actions.exhibitors.resetExhibitors())
  }

  static *onExhibitorsAutocomplete({
    payload,
  }: ReturnType<typeof actions.exhibitors.exhibitorsAutocompleteRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.queries.exhibitorsAutocomplete, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.setSuccessMessage(undefined))
      yield put(actions.exhibitors.exhibitorsAutocompleteError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.setSuccessMessage('Le mot de passe a bien été modifié'))
      yield put(actions.exhibitors.exhibitorsAutocompleteSuccess())
    }
  }

  static *onExhibitorsSearch({
    payload,
  }: ReturnType<typeof actions.exhibitors.exhibitorsServiceRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.queries.exhibitors, payload)
    if (rs.errors) {
      yield put(actions.exhibitors.exhibitorsServiceError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.setExhibitors(rs.data.exhibitors))
      yield put(actions.exhibitors.exhibitorsServiceSuccess())
    }
  }

  static *onCollectionsByExhibitor({
    payload,
  }: ReturnType<typeof actions.exhibitors.collectionsByExhibitorRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.queries.collectionsByExhibitor, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.collectionsByExhibitorError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(
        actions.exhibitors.setCollectionExhibitions(rs.data.collectionsByExhibitor as Collection[])
      )
      yield put(actions.exhibitors.collectionsByExhibitorSuccess())
    }
  }

  static *onExhibitorsCollectionSearch({
    payload,
  }: ReturnType<typeof actions.exhibitors.exhibitorsCollectionServiceRequest>) {
    yield put(actions.exhibitors.setCollectionExhibitionsPending(true))

    const rs = yield* ApiSagas.call(services.exhibitors.queries.collectionByExhibitor, payload)
    if (rs.errors) {
      yield put(actions.exhibitors.exhibitorsCollectionServiceError(rs.errors))
      yield put(actions.exhibitors.setCollectionExhibitionsPending(false))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.setCollection(rs.data.collectionByExhibitor as Collection))
      yield put(
        actions.exhibitors.setExhibitor(rs.data.collectionByExhibitor.exhibitor as Exhibitor)
      )
      yield put(actions.exhibitors.exhibitorsCollectionServiceSuccess())
      yield put(actions.exhibitors.setCollectionExhibitionsPending(false))
    }
  }

  static *onCollectionParcelCreate({
    payload,
  }: ReturnType<typeof actions.exhibitors.collectionParcelCreateRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.mutations.collectionParcelCreate, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.collectionParcelCreateError(rs.errors))
      return
    }
    if (rs.data) {
      const newCollection = { ...selectors.collection, ...rs.data.collectionParcelCreate }
      yield put(actions.exhibitors.setCollection(newCollection as Collection))
      yield put(actions.exhibitors.collectionParcelCreateSuccess(rs.errors))
    }
  }

  static *onInternalNoteCreate({
    payload,
  }: ReturnType<typeof actions.exhibitors.internalNoteCreateRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.mutations.internalNoteCreate, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.internalNoteCreateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.internalNoteCreateSuccess())
    }
  }

  static *onInternalNoteDelete({
    payload,
  }: ReturnType<typeof actions.exhibitors.internalNoteDeleteRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.mutations.internalNoteDelete, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.internalNoteDeleteError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.internalNoteDeleteSuccess())
    }
  }

  static *onInternalNoteUpdate({
    payload,
  }: ReturnType<typeof actions.exhibitors.internalNoteUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.mutations.internalNoteUpdate, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.internalNoteUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.internalNoteUpdateSuccess())
    }
  }

  static *onCollectionParcelUpdate({
    payload,
  }: ReturnType<typeof actions.exhibitors.collectionParcelUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.mutations.collectionParcelUpdate, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.collectionParcelUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      const newCollection = { ...selectors.collection, ...rs.data.collectionParcelUpdate }
      yield put(actions.exhibitors.setCollection(newCollection as Collection))
      yield put(actions.exhibitors.collectionParcelUpdateSuccess())
    }
  }

  static *onCollectionParcelDelete({
    payload,
  }: ReturnType<typeof actions.exhibitors.collectionParcelDeleteRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.mutations.collectionParcelDelete, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.collectionParcelDeleteError(rs.errors))
      return
    }
    if (rs.data) {
      const newCollection = { ...selectors.collection, ...rs.data.collectionParcelDelete }
      yield put(actions.exhibitors.setCollection(newCollection as Collection))
      yield put(actions.exhibitors.collectionParcelDeleteSuccess())
    }
  }

  static *onCollectionUpdate({
    payload,
  }: ReturnType<typeof actions.exhibitors.collectionUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.mutations.collectionUpdate, payload)

    if (rs.errors) {
      yield put(actions.exhibitors.collectionUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.collectionUpdateSuccess())
    }
  }

  static *onExportExhibitors({
    payload,
  }: ReturnType<typeof actions.exhibitors.exportExhibitorsRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitors.queries.exportExhibitors, payload)
    if (rs.errors) {
      yield put(actions.exhibitors.exportExhibitorsError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitors.exportExhibitorsSuccess())
      return
    }
  }

  static *listeners() {
    yield all([
      takeEvery(actions.exhibitors.exhibitorsAutocompleteRequest, this.onExhibitorsAutocomplete),
      takeEvery(actions.exhibitors.exhibitorsServiceRequest, this.onExhibitorsSearch),
      takeEvery(
        actions.exhibitors.exhibitorsCollectionServiceRequest,
        this.onExhibitorsCollectionSearch
      ),
      takeEvery(actions.exhibitors.collectionParcelCreateRequest, this.onCollectionParcelCreate),
      takeEvery(actions.exhibitors.collectionParcelUpdateRequest, this.onCollectionParcelUpdate),
      takeEvery(actions.exhibitors.collectionParcelDeleteRequest, this.onCollectionParcelDelete),
      takeEvery(actions.exhibitors.collectionUpdateRequest, this.onCollectionUpdate),
      takeEvery(actions.exhibitors.internalNoteCreateRequest, this.onInternalNoteCreate),
      takeEvery(actions.exhibitors.internalNoteDeleteRequest, this.onInternalNoteDelete),
      takeEvery(actions.exhibitors.internalNoteUpdateRequest, this.onInternalNoteUpdate),
      takeEvery(actions.exhibitors.collectionsByExhibitorRequest, this.onCollectionsByExhibitor),
      takeEvery(actions.exhibitors.exportExhibitorsRequest, this.onExportExhibitors),
    ])
  }
}
