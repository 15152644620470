import React, { useEffect, useRef, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import LoginTemplate from '../../templates/Login'
import { actions, selectors } from '../../redux'
import LoginChoice, { LoginChoiceTemplateProps } from '../../templates/LoginChoice'
import { loginChoiceTemplateMocks } from '../../templates/LoginChoice/mocks'
import { useLoginTemplateProps } from '../../templates/Login/useProps'
import { LoginFormValues } from '../../components/forms/LoginForm'
import { Icons } from '../../components/Icon'
import { LayoutNotConnectedMocks } from '../../layouts/LayoutNotConnected/mocks'

const LoginPage: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isUserPv, setIsUserPv] = useState<boolean | undefined>(undefined)

  const config = useSelector(selectors.configuration.configurationInfo)

  const ref = useRef<FormikProps<LoginFormValues>>(null)

  const templateProps = useLoginTemplateProps()

  const loginChoiceTemplateProps: LoginChoiceTemplateProps = loginChoiceTemplateMocks.basic

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const accessToken = urlParams.get('access_token')
    if (accessToken) {
      dispatch(actions.auth.onAuthByAzure({ token: accessToken }))
    }
  }, [dispatch])

  useEffect(() => {
    if (isUserPv) {
      const azureLoginUrl =
        config?.azure?.loginUrl ?? 'https://api.samples.premierevision.com/azure/redirect'
      window.location.replace(azureLoginUrl)
    }
  }, [config?.azure?.loginUrl, isUserPv])

  return isUserPv === undefined ? (
    <LoginChoice
      {...loginChoiceTemplateProps}
      setChoicePv={() => setIsUserPv(true)}
      setChoiceExt={() => setIsUserPv(false)}
    />
  ) : !isUserPv ? (
    <LoginTemplate
      {...templateProps}
      layout={{
        ...LayoutNotConnectedMocks.basic,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              setIsUserPv(undefined)
            },
          },
        },
      }}
      loginForm={{
        ...templateProps.loginForm,
        formikForm: {
          ...templateProps.loginForm.formikForm,
          onContextUpdate: (context) => {
            setIsValid(context.isValid)
          },
          innerRef: ref,
        },
        submitButton: {
          ...templateProps.loginForm.submitButton,
          disabled: !isValid,
        },
      }}
    />
  ) : null
}

export default LoginPage
