import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { fonts } from '../../../theme'
import ColorPicker from '../../ColorPicker'
import FormFieldCheckboxGroup from '../../form/fields/FormFieldCheckboxGroup'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const StyledFormikForm = styled(FormikForm)`
  width: 100%;
`

export const CheckboxGroup = styled(FormFieldCheckboxGroup)`
  & > div {
    gap: 0.8rem;
  }
`

export const Title = styled.div`
  font-size: 1.4rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.gray};
`

export const TextField = styled(FormikField)``

export const StyledColorPicker = styled(ColorPicker)``
