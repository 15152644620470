import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitors = getQueryService({
  query: gql(`
    query exhibitors(
	$q: String
	$universesEbmsIds: [ID!]
	$pavilionsEbmsIds: [ID!]
	$countries: [String!]
	$exhibitionsApiIds: [ID!]
	$first: Int!
	$page: Int
	) {
		exhibitors(
			q: $q
			universesEbmsIds: $universesEbmsIds
			pavilionsEbmsIds: $pavilionsEbmsIds
			countries: $countries
			exhibitionsApiIds: $exhibitionsApiIds
			first: $first
			page: $page
		) {
			paginatorInfo {
				total
				perPage
				lastPage
				currentPage
			}
			data {
				ebmsId
				name
				countryCode
				country
				universe {
					ebmsId
					name
				}
				pavilion {
					ebmsId
					name
				}
				isNew
			}
		}
	}
`),
  transformer: (response) => {
    return response
  },
})
