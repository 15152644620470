import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { SearchBarProps } from '../../components/SearchBar'
import { UserCardProps } from '../../components/UserCard'
import { FiltersInlineProps } from '../../components/FiltersInline'

import * as SC from './styled'

export type UsersAccessTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  searchBar?: SearchBarProps
  title?: string
  userList?: UserCardProps[]
  filtersInline?: FiltersInlineProps
  emptyString?: string
  anchorPrefix?: string
}

const UsersAccessTemplate: FC<UsersAccessTemplateProps> = ({
  layoutProps,
  title,
  searchBar,
  filtersInline,
  userList,
  emptyString,
  anchorPrefix = '',
}) => {
  let previousLetter = '' as string | null
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <SC.Wrapper maxWidth={'default'}>
        {title && <SC.Title tag={'h2'} text={title} />}
        <SC.Search {...searchBar} />
        {filtersInline && <SC.Filters {...filtersInline} />}
        <SC.Content>
          {userList && userList.length > 0 ? (
            <>
              <SC.Users>
                {userList.map((user, index) => {
                  const firstNameFirstLetter = user?.lastName
                    ? Array.from(user.lastName)[0].toLocaleLowerCase()
                    : null
                  const hasId = firstNameFirstLetter && firstNameFirstLetter !== previousLetter
                  previousLetter = hasId ? firstNameFirstLetter : previousLetter
                  return (
                    <SC.User
                      {...user}
                      key={index}
                      link={{ href: user.link?.href }}
                      {...(hasId && {
                        id: anchorPrefix + firstNameFirstLetter,
                      })}
                    />
                  )
                })}
              </SC.Users>
            </>
          ) : emptyString ? (
            <SC.Empty>{emptyString}</SC.Empty>
          ) : (
            <SC.Empty>Pas de résultats</SC.Empty>
          )}
        </SC.Content>
      </SC.Wrapper>
    </LayoutWithBottomActions>
  )
}

export default UsersAccessTemplate
