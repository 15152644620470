import React, { useEffect, useState } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'

import { actions, selectors } from '../../../redux'
import SampleSheetDataTemplate from '../../../templates/SampleSheetData'
import { useSampleSheetDataTemplateProps } from '../../../templates/SampleSheetData/useProps'
import ModalToast from '../../../components/ModalToast'
import { Icons } from '../../../components/Icon'

const SampleSheetData: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const sample = useSelector(selectors.samples.sample)
  const sampleUpdate = useSelector(selectors.samples.sampleUpdate)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { sampleId } = useParams<{ sampleId: string }>()

  useEffect(() => {
    if (sampleUpdate.success && sampleId) {
      setIsModalVisible(true)
      dispatch(actions.samples.getSampleByIdRequest({ sampleId }))
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.samples.resetSampleUpdate())
      }, 2000)
    }
  }, [dispatch, sampleId, sampleUpdate.success])

  useEffect(() => {
    sample &&
      sample.id === sampleId &&
      sample.universe &&
      dispatch(
        actions.samples.getFlagGroupsByUniverseRequest({
          universeEbmsId: sample.universeRelation
            ? sample.universeRelation.universeEbmsId
            : sample.universe.ebmsId,
        })
      )
    sample &&
      sample.id === sampleId &&
      sample.collection &&
      dispatch(
        actions.samples.getSampleFormByUniverseRequest({
          collectionId: sample.collection?.id,
          universeEbmsId: sample.universeRelation
            ? sample.universeRelation.universeEbmsId
            : sample.universe.ebmsId,
        })
      )
  }, [dispatch, sample, sampleId])

  const templateProps = useSampleSheetDataTemplateProps()

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text={sampleUpdate.success ? i18next.t('exponent.collection.sampleUpdate.success') : ''}
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}{' '}
      <SampleSheetDataTemplate {...templateProps} />
    </>
  )
}

export default SampleSheetData
