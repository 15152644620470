import styled, { css } from 'styled-components'

import { ForumVariant } from '../../types/forumVariant'

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.1rem;
`

const defaultS = css`
  --color: ${(props) => props.theme.colors.selectiveTextYellow};
  --background-color: ${(props) => props.theme.colors.eggSour};
  --border-color: 1px solid ${(props) => props.theme.colors.selectiveYellow};
  --border-color--active: 1px solid ${(props) => props.theme.colors.selectiveTextYellowDark};
`

const validateVariant = css`
  --color: ${(props) => props.theme.colors.chateauGreen};
  --background-color: ${(props) => props.theme.colors.appleGreen};
  --border-color: 1px solid ${(props) => props.theme.colors.chateauGreen};
  --border-color--active: 1px solid ${(props) => props.theme.colors.chateauGreen};
`

const deleteVariant = css`
  --color: ${(props) => props.theme.colors.cinnabar};
  --background-color: ${(props) => props.theme.colors.redLight};
  --border-color: 1px solid ${(props) => props.theme.colors.cinnabar};
  --border-color--active: 1px solid ${(props) => props.theme.colors.cinnabar};
`

export const StatutSelection = styled.div<{
  $variant?: ForumVariant
  $isOn?: boolean
  $noHover?: boolean
  $color?: string
  $backgroundColor?: string
}>`
  ${(props) => {
    switch (props.$variant) {
      case 'default':
        return css`
          ${defaultS}
        `
      case 'validate':
        return css`
          ${validateVariant}
        `
      case 'delete':
        return css`
          ${deleteVariant}
        `
    }
  }}
  // custom colors
  ${({ $color, $backgroundColor, $variant }) =>
    $backgroundColor &&
    !$variant &&
    css`
      --color: ${$color};
      --background-color: ${$backgroundColor};
      --border-color: ${$color};
      --border-color--active: ${$color};
    `}
  ${({ $backgroundColor, $variant }) =>
    !$backgroundColor &&
    !$variant &&
    css`
      --background-color: ${(props) =>
        props.theme.colors.concrete}; // Remplacez par la couleur de fond par défaut souhaitée
    `}
  ${({ theme }) => theme.textStyles.textBold}
  padding: 1.6rem 1.2rem;
  display: inline-flex;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.1rem;
  border-radius: 0.4rem;
  width: 100%;
  color: var(--color);
  background-color: var(--background-color);
  border: 1px solid var(--color);
  transition: border-color 0.5s, background-color 0.5s, color 0.5s;
  ${({ $isOn }) =>
    $isOn === true &&
    css`
      border-color: var(--border-color--active);
    `}
  ${({ $noHover }) =>
    !$noHover &&
    css`
      &:hover {
        cursor: pointer;
        border-color: var(--border-color--active);
      }
    `}
`
