import React, { useEffect, useState } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { actions, selectors } from '../../../redux'
import ExponentSheetInternalNoteTemplate from '../../../templates/ExponentSheetInternalNote'
import { useExponentSheetInternalNoteTemplateProps } from '../../../templates/ExponentSheetInternalNote/useProps'
import ModalToast from '../../../components/ModalToast'
import { Icons } from '../../../components/Icon'

const ExponentSheetInternalNote: React.FC<RouteProps> = () => {
  const collection = useSelector(selectors.exhibitors.collection)
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)
  const internalNoteCreate = useSelector(selectors.exhibitors.internalNoteCreate)
  const internalNoteDelete = useSelector(selectors.exhibitors.internalNoteDelete)
  const internalNoteUpdate = useSelector(selectors.exhibitors.internalNoteUpdate)

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const dispatch = useDispatch()

  const { exposantId } = useParams<{ exposantId: string }>()

  useEffect(() => {
    if (internalNoteCreate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitors.resetInternalNoteCreate())
      }, 2000)
    }
    if (internalNoteDelete.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitors.resetInternalNoteDelete())
      }, 2000)
    }
    if (internalNoteUpdate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitors.resetInternalNoteUpdate())
      }, 2000)
    }
  }, [
    dispatch,
    exhibitor,
    exposantId,
    internalNoteCreate.success,
    internalNoteDelete.success,
    internalNoteUpdate.success,
  ])

  const templateProps = useExponentSheetInternalNoteTemplateProps(
    exhibitor,
    collection,
    internalNoteCreate,
    internalNoteDelete,
    internalNoteUpdate
  )

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text={
            internalNoteCreate.success
              ? "La note a bien été ajoutée à l'exposant."
              : internalNoteDelete.success
              ? 'La note interne a bien été supprimée.'
              : internalNoteUpdate.success
              ? "Les modifications ont bien été apportées à la note interne de l'exposant."
              : ''
          }
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}{' '}
      <ExponentSheetInternalNoteTemplate {...templateProps} />
    </>
  )
}

export default ExponentSheetInternalNote
