import styled, { css } from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

import ActionButton from '../ActionButton'
import FilterTag from '../FilterTag'
import FiltersSmall from '../forms/FiltersSmall'
import { breakpoints, colors } from '../../theme'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  gap: 0.8rem;
`
export const TagList = styled(ScrollContainer)`
  grid-column: 1 / span 1;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.8rem;
  overflow: auto;
  white-space: nowrap;
`
export const Tag = styled(FilterTag)`
  display: flex;
  white-space: nowrap;
  align-items: center;
`
export const Button = styled(ActionButton)<{ $isOpen?: boolean }>`
  grid-column: 2 / span 1;
  & svg {
    transform: ${({ $isOpen }) =>
      $isOpen === true &&
      css`
        rotate(180deg)
      `};
  }
`
export const Content = styled.div<{ $isOpen?: boolean }>`
  grid-row: 2 / span 1;
  grid-column: 1 / span 2;
  position: relative;
  display: flex;
  justify-content: flex-end;
  border-top: 0.1rem solid ${({ $isOpen }) => ($isOpen !== true ? 'transparent' : colors.tundora)};
  transition: border-color 100ms ease-in-out 200ms;
  ${breakpoints.up('tablet')} {
    border-top: 0 none;
  }
`
export const Filters = styled(FiltersSmall)<{ $isOpen?: boolean }>`
  transition: opacity 300ms ease-in-out;
  padding-top: 0.8rem;
  ${({ $isOpen }) =>
    $isOpen !== true &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0ms;
      &,
      & * {
        pointer-events: none;
      }
    `}
  ${breakpoints.up('desktop')} {
    max-width: min-content;
  }
`
