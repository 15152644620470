import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleUpdateImageLabel = getMutationService({
  mutation: gql(`
    mutation sampleLabelUpdate($sampleLabelId: ID!, $uploadPath: String!) {
      sampleLabelUpdate(sampleLabelId: $sampleLabelId, uploadPath: $uploadPath) {
        id
        name
        labels {
          id
          user {
            id
            email
          }
          url
          path
          order
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
