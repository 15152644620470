import React, { FC } from 'react'

import { Icons } from '../Icon'
import { ActionButtonProps } from '../ActionButton'
import { ActionButtonScheme } from '../ActionButton/types'

import * as SC from './styled'

export type FilterButtonProps = ActionButtonProps & {
  className?: string
  open?: boolean
}

const FilterButton: FC<FilterButtonProps> = ({ className, open, ...actionButtonProps }) => {
  return (
    <SC.Content
      className={className}
      variantScheme={ActionButtonScheme.Link}
      {...actionButtonProps}
      {...(open
        ? { rightIconProps: { icon: Icons.caretRight, width: 9, height: 9 } }
        : { leftIconProps: { icon: Icons.caretLeft, width: 9, height: 9 } })}
    />
  )
}

export default FilterButton
