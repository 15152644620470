import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import i18next from 'i18next'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { layoutSampleDataMocks } from '../../layouts/LayoutSampleData/mocks'
import { SampleDataTraineeTemplateProps } from '../SampleDataTrainee'
import { cameraMocks } from '../../components/Camera/mocks'
import { returnSampleDataSteps } from '../../relay/DataSample/SampleDataSteps'
import { actions, selectors } from '../../redux'
import { router } from '../../router'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { ModalVariant } from '../../components/Modal/types'
import { SampleLabel, SampleStatus, UserRole } from '../../graphql/generated/graphql'

export const useSampleDataLabelTemplateProps = (title?: string): SampleDataTraineeTemplateProps => {
  const [openCamera, setOpenCamera] = useState(false)
  const sampleData = useSelector(selectors.samples.sampleData)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { sampleId } = useParams<{ sampleId: string }>()
  const { exposantId } = useParams<{ exposantId: string }>()
  const user = useSelector(selectors.auth.user)
  const sample = useSelector(selectors.samples.sample)
  const config = useSelector(selectors.configuration.configurationInfo)
  const refresh = useSelector(selectors.auth.authByToken)

  const [forceRefresh, setForceRefresh] = useState<boolean>(true)
  const [openUpload, setOpenUpload] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [picture, setPicture] = useState<string | undefined>(undefined)
  const [index, setIndex] = useState<number | null>(null)
  const [isButtonPending, setIsButtonPending] = useState<boolean>(false)
  const [pictures, setPictures] = useState<string[] | undefined>(sampleData.label || [])
  const [oldPictures, setOldPictures] = useState<SampleLabel[]>(sampleDataAllInfo.label || [])
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const collection = useSelector(selectors.exhibitors.collection)

  const layoutProps = useDefaultLayoutTemplateProps('exponents')

  // Force loader to make reloading more smooth
  useEffect(() => {
    const timerId = setTimeout(() => {
      setForceRefresh(false)
    }, 500)

    return () => clearTimeout(timerId)
  }, [])

  const handleScreenshot = useCallback(
    (src: string) => {
      if (index !== null) {
        if (pictures && oldPictures && src && pictures[index] !== undefined) {
          const updatedPictures = [...pictures]
          const updatedOldPictures = [...oldPictures]
          updatedPictures[index] = src
          updatedOldPictures[index] = {
            ...updatedOldPictures[index],
            url: src,
          }
          setPictures(updatedPictures)
          setOldPictures(updatedOldPictures)
        }
        setOpenCamera(false)
        setOpenUpload(false)
        setIndex(null)
      } else {
        pictures && setPictures([...pictures, src])
        oldPictures &&
          user &&
          sampleId &&
          setOldPictures([
            ...oldPictures,
            {
              attachedAt: '',
              id: '',
              order: 0,
              path: '',
              sampleId: sampleId,
              url: src,
              user: user,
            },
          ])
        setOpenCamera(false)
        setOpenUpload(false)
        setIndex(null)
      }
    },
    [index, oldPictures, pictures, sampleId, user]
  )

  const handleRetake = (index: number) => {
    setIndex(index)
    setPicture(undefined)
    setOpenCamera(true)
  }

  const handleRetakeUpload = (index: number) => {
    setIndex(index)
    setPicture(undefined)
  }

  const isSkipableStep = useCallback(() => {
    if (
      user?.role === UserRole.Admin ||
      user?.role === UserRole.SuperAdmin ||
      user?.role === UserRole.ModePv ||
      user?.role === UserRole.FashionExpert
    ) {
      return true
    } else {
      return false
    }
  }, [user])

  const updateSampleLabel = useCallback(
    (oldPictures: SampleLabel[]) => {
      oldPictures.forEach((item) => {
        const imageData = item
        const isNew = imageData.url.startsWith('data:image') ? true : false
        if (isNew) {
          const decodedData = window.atob(imageData.url.split(',')[1])
          const arrayBuffer = new ArrayBuffer(decodedData.length)
          const uint8Array = new Uint8Array(arrayBuffer)
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i)
          }
          const blob = new Blob([arrayBuffer], { type: 'image/jpeg' })
          const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
          dispatch(
            actions.configuration.uploadRequest({
              sampleId: sampleId || '',
              file,
              sampleLabelId: imageData.id !== '' ? imageData.id : undefined,
            })
          )
        }
      })
    },
    [sampleId, dispatch]
  )
  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, dispatch, sample])

  useEffect(() => {
    if (isButtonPending) {
      setTimeout(() => {
        setIsButtonPending(false)
        const link = router(sampleDataRoutes(sampleData, 'next'), {
          exposantId,
          sampleId,
        })
        navigate(link)
      }, 2500)
    }
  }, [exposantId, isButtonPending, navigate, sampleData, sampleId])

  return useMemo(
    () => ({
      title: title,
      isPending: forceRefresh,
      uploadHandler: (picture) => handleScreenshot(picture),
      layoutProps: {
        ...layoutSampleDataMocks.basic,
        steps: {
          steps: returnSampleDataSteps(sampleData, exposantId, sampleId),
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(sampleDataRoutes(sampleData, 'prev'), {
                exposantId,
                sampleId,
                apiId: collection?.exhibition.apiId as string,
              })
              navigate(link)
            },
          },
          note:
            collection?.internalNotes && collection?.internalNotes.length !== 0
              ? {
                  rounded: true,
                  number: collection?.internalNotes
                    ? collection?.internalNotes.length.toString()
                    : '0',
                  onClick: () => {
                    setIsNoteOpen(true)
                  },
                }
              : undefined,
          next: {
            text: 'Suivant',
            disabled: isButtonPending
              ? true
              : isSkipableStep()
              ? false
              : pictures && pictures.length > 0
              ? false
              : true,
            isPending: isButtonPending,
            onClick: () => {
              dispatch(
                actions.samples.setSampleData({
                  ...sampleData,
                  label: pictures ? pictures : [],
                })
              )
              dispatch(
                actions.samples.setSampleDataAllInfo({
                  ...sampleDataAllInfo,
                  label: oldPictures ? oldPictures : [],
                })
              )
              updateSampleLabel(oldPictures)
              setIsButtonPending(true)
            },
            rightIconProps: {
              icon: Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
          nextPlus: {
            text: 'Rafraichir',
            isPending: refresh.pending,
            disabled: refresh.pending,
            onClick: () => {
              dispatch(actions.auth.authByTokenRequest())
            },
            rightIconProps: {
              icon: Icons.link,
              width: 18,
              height: 18,
            },
          },
        },
        marketplaceWarning:
          marketplaceDateDaysLeft && marketplaceDateHoursLeft
            ? {
                number: `J - ${marketplaceDateDaysLeft}`,
                text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
                redBg: true,
              }
            : undefined,
        title: `${sampleData.exhibitorRef} #${sampleId}`,
        noteModal: {
          onClose: () => setIsNoteOpen(false),
          isOpen: isNoteOpen,
          variant: ModalVariant.Small,
        },
        notes: {
          title: "Note interne sur l'exposant",
          notes:
            collection && collection.internalNotes && collection.internalNotes.length !== 0
              ? collection.internalNotes.map((note) => ({
                  text: note.body,
                  author: {
                    fullName:
                      collection?.internalNotes.length !== 0
                        ? `par ${note.user.lastName} ${note.user.firstName}`
                        : '',
                    date:
                      collection?.internalNotes.length !== 0
                        ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                        : '',
                  },
                }))
              : [],
          tags: {
            newTag: collection?.exhibitor?.isNew,
            returnTag: collection?.samplesToReturn !== 0,
          },
          onClose: () => setIsNoteOpen(false),
        },
        openImage: openImage,
        onClose: () => setOpenImage(false),
        finalImage: picture,
        ...layoutProps,
      },
      pictureButton: {
        text: 'afficher l’étiquette exposant',
      },
      images: pictures,
      handleRetake: handleRetake,
      handleRetakeUpload: handleRetakeUpload,
      camera: {
        cameraModal: openCamera
          ? {
              isOpen: openCamera,
              onClose: () => setOpenCamera(false),
            }
          : undefined,

        openCameraHandler: () => setOpenCamera(true),
        cameraProps: {
          ...cameraMocks.basic,
          onScreenshot: handleScreenshot,
          onUserMediaError: () => {
            setOpenCamera(false)
            setOpenUpload(true)
          },
        },
        currentImage: picture ? picture : undefined,
        newImageButton: {
          text: 'Prendre une autre photo',
          onClick: () => {
            setPicture(undefined)
            setOpenCamera(true)
          },
        },
        retakeButton: {
          text: 'Reprendre la photo',
        },
        uploadButton: {
          text: 'Importer une image',
        },
        uploadModal: {
          title: i18next.t('sampleData.uploadModal.title'),
          text: i18next.t('sampleData.uploadModal.text'),
          button: {
            text: i18next.t('sampleData.uploadModal.button.text'),
          },
          isOpen: openUpload,
          onClose: () => setOpenUpload(false),
          uploadHandler: (picture) => {
            handleScreenshot(picture)
          },
        },
      },
      onOpen: (picture: string) => {
        setOpenImage(true)
        setPicture(picture)
      },
    }),
    [
      collection,
      dispatch,
      exposantId,
      forceRefresh,
      handleScreenshot,
      isButtonPending,
      isNoteOpen,
      isSkipableStep,
      layoutProps,
      marketplaceDateDaysLeft,
      marketplaceDateHoursLeft,
      navigate,
      oldPictures,
      openCamera,
      openImage,
      openUpload,
      picture,
      pictures,
      refresh.pending,
      sampleData,
      sampleDataAllInfo,
      sampleId,
      title,
      updateSampleLabel,
    ]
  )
}
