import { router, routesPath } from '../../router'

export const returnSampleDataSteps = (routeType?: string) => {
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  if (routeType === 'Fabrics') {
    const stepsFabrics = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        href: router(routesPath.sampleSheetEditCategory, { routeType }),
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '2. Destination produit',
        href: router(routesPath.sampleSheetEditProductDestination, { routeType }),
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '3. Motif',
        href: router(routesPath.sampleSheetEditPattern, { routeType }),
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '4. Codes de performances',
        href: router(routesPath.sampleSheetEditPerformanceCode, { routeType }),
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '5. Certifications',
        href: router(routesPath.sampleSheetEditCertifications, { routeType }),
      },
      {
        isCurrent: lastSegment === 'material',
        value: '6. Composition',
        href: router(routesPath.sampleSheetEditMaterial, { routeType }),
      },
      {
        isCurrent: lastSegment === 'color',
        value: '7. Couleur',
        href: router(routesPath.sampleSheetEditColor, { routeType }),
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '8. INFO shooting',
        href: router(routesPath.sampleSheetEditShooting, { routeType }),
      },
    ]
    return stepsFabrics
  }
  if (routeType === 'Leather') {
    const stepsLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        href: router(routesPath.sampleSheetEditCategory, { routeType }),
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '2. Destination produit',
        href: router(routesPath.sampleSheetEditProductDestination, { routeType }),
      },
      {
        isCurrent: lastSegment === 'animal',
        value: '3. Animal',
        href: router(routesPath.sampleSheetEditAnimal, { routeType }),
      },
      {
        isCurrent: lastSegment === 'tanningType',
        value: '4. Type de tannage',
        href: router(routesPath.sampleSheetEditTanningType, { routeType }),
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '5. Aspect',
        href: router(routesPath.sampleSheetEditAspect, { routeType }),
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '6. Codes de performances',
        href: router(routesPath.sampleSheetEditPerformanceCode, { routeType }),
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '7. Certifications',
        href: router(routesPath.sampleSheetEditCertifications, { routeType }),
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '8. INFO shooting',
        href: router(routesPath.sampleSheetEditShooting, { routeType }),
      },
    ]
    return stepsLeather
  }
  if (routeType === 'Accessories') {
    const stepsAccessories = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        href: router(routesPath.sampleSheetEditCategory, { routeType }),
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '2. Destination produit',
        href: router(routesPath.sampleSheetEditProductDestination, { routeType }),
      },
      {
        isCurrent: lastSegment === 'accessorieType',
        value: "3. Type d'accessoire",
        href: router(routesPath.sampleSheetEditAccessorieType, { routeType }),
      },
      {
        isCurrent: lastSegment === 'material',
        value: '4. Composition',
        href: router(routesPath.sampleSheetEditMaterial, { routeType }),
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '5. Aspect',
        href: router(routesPath.sampleSheetEditAspect, { routeType }),
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '6. Codes de performance',
        href: router(routesPath.sampleSheetEditPerformanceCode, { routeType }),
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '7. Certifications',
        href: router(routesPath.sampleSheetEditCertifications, { routeType }),
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '8. INFO shooting',
        href: router(routesPath.sampleSheetEditShooting, { routeType }),
      },
    ]
    return stepsAccessories
  }
  if (routeType === 'Manufacturing-Leather' || routeType === 'Manufacturing') {
    const stepsManufacturingLeather = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        href: router(routesPath.sampleSheetEditCategory, { routeType }),
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '2. codes de performances',
        href: router(routesPath.sampleSheetEditPerformanceCode, { routeType }),
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '3. Certifications',
        href: router(routesPath.sampleSheetEditCertifications, { routeType }),
      },
      {
        isCurrent: lastSegment === 'manufacturingType',
        value: '4. Type de produit',
        href: router(routesPath.sampleSheetEditManufacturingType, { routeType }),
      },
      {
        isCurrent: lastSegment === 'material',
        value: '5. Composition',
        href: router(routesPath.sampleSheetEditMaterial, { routeType }),
      },
    ]
    return stepsManufacturingLeather
  }
  if (routeType === 'Yarns') {
    const stepsYarns = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        href: router(routesPath.sampleSheetEditCategory, { routeType }),
      },
      {
        isCurrent: lastSegment === 'productDestination',
        value: '2. Destination produit',
        href: router(routesPath.sampleSheetEditProductDestination, { routeType }),
      },
      {
        isCurrent: lastSegment === 'unitValue',
        value: '3. Unit Value',
        href: router(routesPath.sampleSheetEditUnitValue, { routeType }),
      },
      {
        isCurrent: lastSegment === 'numberEnd',
        value: '4. Number End',
        href: router(routesPath.sampleSheetEditNumberEnd, { routeType }),
      },
      {
        isCurrent: lastSegment === 'aspect',
        value: '5. Aspect',
        href: router(routesPath.sampleSheetEditAspect, { routeType }),
      },
      {
        isCurrent: lastSegment === 'technologyActivity',
        value: '6. Technology Activity',
        href: router(routesPath.sampleSheetEditTechnologyActivity, { routeType }),
      },
      {
        isCurrent: lastSegment === 'performanceCode',
        value: '7. codes de performances',
        href: router(routesPath.sampleSheetEditPerformanceCode, { routeType }),
      },
      {
        isCurrent: lastSegment === 'certifications',
        value: '8. Certifications',
        href: router(routesPath.sampleSheetEditCertifications, { routeType }),
      },
      {
        isCurrent: lastSegment === 'shooting',
        value: '9. INFO shooting',
        href: router(routesPath.sampleSheetEditShooting, { routeType }),
      },
      {
        isCurrent: lastSegment === 'color',
        value: '10. Couleur',
        href: router(routesPath.sampleSheetEditColor, { routeType }),
      },
      {
        isCurrent: lastSegment === 'material',
        value: '11. Composition',
        href: router(routesPath.sampleSheetEditMaterial, { routeType }),
      },
    ]
    return stepsYarns
  }
  if (routeType === 'Designs') {
    const stepsDesign = [
      {
        isCurrent: lastSegment === 'category',
        value: '1. Catégories',
        href: router(routesPath.sampleSheetEditCategory, { routeType }),
      },
      {
        isCurrent: lastSegment === 'pattern',
        value: '2. Motif',
        href: router(routesPath.sampleSheetEditPattern, { routeType }),
      },
      {
        isCurrent: lastSegment === 'patternType',
        value: '3. Type de motif',
        href: router(routesPath.sampleSheetEditPatternType, { routeType }),
      },
    ]
    return stepsDesign
  } else return []
}
