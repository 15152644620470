import React, { FC } from 'react'

import * as SC from './styled'

export type AlphabetKeys =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z'
  | 'default'

export type AlphabeticSortProps = {
  className?: string
  current?: AlphabetKeys
  alphabet?: string[]
  onClick: (letter: AlphabetKeys) => void
}

const AlphabeticSort: FC<AlphabeticSortProps> = ({ className, current, onClick, alphabet }) => {
  const handleClick = (letter: AlphabetKeys) => {
    return () => {
      onClick(letter)
    }
  }
  return (
    <SC.Container className={className}>
      <SC.Letter onClick={handleClick('default')} $isCurrent={current === 'default'}>
        #
      </SC.Letter>
      {alphabet &&
        alphabet.map((letter) => (
          <SC.Letter
            key={letter}
            $isCurrent={letter === current}
            onClick={handleClick(letter as AlphabetKeys)}
          >
            {letter}
          </SC.Letter>
        ))}
    </SC.Container>
  )
}

export default AlphabeticSort
