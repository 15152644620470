import React, { FC } from 'react'

import { LoginFormProps } from '../../components/forms/LoginForm'
import LayoutNotConnected, { LayoutNotConnectedProps } from '../../layouts/LayoutNotConnected'

import * as SC from './styled'

export type LoginTemplateProps = {
  className?: string
  title: string
  layout?: LayoutNotConnectedProps
  loginForm: LoginFormProps
}

const LoginTemplate: FC<LoginTemplateProps> = ({ layout, title, loginForm }) => {
  return (
    <LayoutNotConnected {...layout}>
      <SC.StyledContentWrapper maxWidth={'small'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Form {...loginForm} />
      </SC.StyledContentWrapper>
    </LayoutNotConnected>
  )
}

export default LoginTemplate
