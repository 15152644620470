import React, { FC } from 'react'

import * as SC from './styled'

export type InfosDisplayProps = {
  infos: { title: string; text: string; noUppercase?: boolean }[]
}

const InfosDisplay: FC<InfosDisplayProps> = ({ infos }) => {
  return (
    <SC.Content>
      {infos.map((item, key) => {
        let formattedText = ''
        if (!item.noUppercase) {
          formattedText = item.text.charAt(0).toUpperCase() + item.text.substring(1).toLowerCase()
        }
        const formattedTitle =
          item.title.charAt(0).toUpperCase() + item.title.substring(1).toLowerCase()
        return (
          <SC.Resume key={key}>
            <SC.ResumeTitle>{formattedTitle}</SC.ResumeTitle>
            <SC.ResumeText $noUppercase={item.noUppercase}>
              {formattedText ? formattedText : item.text}
            </SC.ResumeText>
          </SC.Resume>
        )
      })}
    </SC.Content>
  )
}

export default InfosDisplay
