import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Menu from '../Menu'
import SearchButton from '../SearchButton'

export const MobileNav = styled.nav`
  width: 100%;
  height: var(--header-height);
  background-color: ${({ theme }) => theme.colors.pureWhite};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

export const Nav = styled.div<{
  $variant?: 'default' | 'dark'
}>`
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 0 2rem;
  z-index: 1;
  ${(props) => {
    switch (props.$variant) {
      case 'default':
        return css`
          background-color: ${({ theme }) => theme.colors.pureWhite};
        `
      case 'dark':
        return css`
          background-color: ${({ theme }) => theme.colors.tundora};
        `
    }
  }};
`

export const Logo = styled(Icon)`
  width: 16.5rem;
  z-index: 1;
`

export const Search = styled(SearchButton)`
  color: ${({ theme }) => theme.colors.mineShaft};
`

export const MenuStyled = styled(Menu)<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 2rem 2rem;
  padding-top: var(--header-height);
  transform: translateY(20rem);
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  z-index: 0;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    `};
`
