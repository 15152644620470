import { routesPath } from '../../router'

export const routesFabrics = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'pattern',
    href: routesPath.exponentSampleDataPattern,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesFabricsMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'pattern',
    href: routesPath.exponentSampleDataPattern,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'color',
    href: routesPath.exponentSampleDataColor,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesLeather = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'animal',
    href: routesPath.exponentSampleDataAnimal,
  },
  {
    location: 'tanningType',
    href: routesPath.exponentSampleDataTanningType,
  },
  {
    location: 'aspect',
    href: routesPath.exponentSampleDataAspect,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesLeatherMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'animal',
    href: routesPath.exponentSampleDataAnimal,
  },
  {
    location: 'tanningType',
    href: routesPath.exponentSampleDataTanningType,
  },
  {
    location: 'aspect',
    href: routesPath.exponentSampleDataAspect,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesLeatherSpecial = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'pattern',
    href: routesPath.exponentSampleDataPattern,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesLeatherSpecialMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'pattern',
    href: routesPath.exponentSampleDataPattern,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesAccessories = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'accessorieType',
    href: routesPath.exponentSampleDataAccessorieType,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'aspect',
    href: routesPath.exponentSampleDataAspect,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesAccessoriesMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'accessorieType',
    href: routesPath.exponentSampleDataAccessorieType,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'aspect',
    href: routesPath.exponentSampleDataAspect,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesManufacturing = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesManufacturingMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'manufacturingType',
    href: routesPath.exponentSampleDataManufacturingType,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesManufacturingLeather = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesManufacturingLeatherMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'manufacturingType',
    href: routesPath.exponentSampleDataManufacturingType,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesYarns = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'unitValue',
    href: routesPath.exponentSampleDataUnitValue,
  },
  {
    location: 'numberEnd',
    href: routesPath.exponentSampleDataNumberEnd,
  },
  {
    location: 'aspect',
    href: routesPath.exponentSampleDataAspect,
  },
  {
    location: 'technologyActivity',
    href: routesPath.exponentSampleDataTechnologyActivity,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesYarnsMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.exponentSampleDataProductDestination,
  },
  {
    location: 'unitValue',
    href: routesPath.exponentSampleDataUnitValue,
  },
  {
    location: 'numberEnd',
    href: routesPath.exponentSampleDataNumberEnd,
  },
  {
    location: 'aspect',
    href: routesPath.exponentSampleDataAspect,
  },
  {
    location: 'technologyActivity',
    href: routesPath.exponentSampleDataTechnologyActivity,
  },
  {
    location: 'performanceCode',
    href: routesPath.exponentSampleDataPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.exponentSampleDataCertifications,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'shooting',
    href: routesPath.exponentSampleDataShooting,
  },
  {
    location: 'boxe',
    href: routesPath.exponentSampleDataBoxes,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'color',
    href: routesPath.exponentSampleDataColor,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesDesign = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'pattern',
    href: routesPath.exponentSampleDataPattern,
  },
  {
    location: 'patternType',
    href: routesPath.exponentSampleDataPatternType,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesDesignMarketplace = [
  {
    location: 'category',
    href: routesPath.exponentSampleDataCategory,
  },
  {
    location: 'pattern',
    href: routesPath.exponentSampleDataPattern,
  },
  {
    location: 'patternType',
    href: routesPath.exponentSampleDataPatternType,
  },
  {
    location: 'forum',
    href: routesPath.exponentSampleDataForum,
  },
  {
    location: 'subForum',
    href: routesPath.exponentSampleDataSubForum,
  },
  {
    location: 'mkpForum',
    href: routesPath.exponentSampleDataMkpForum,
  },
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesTrainee = [
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'color',
    href: routesPath.exponentSampleDataColor,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesTraineeShortDesign = [
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesTraineeShortManuf = [
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'material',
    href: routesPath.exponentSampleDataMaterial,
  },
  {
    location: 'manufacturingType',
    href: routesPath.exponentSampleDataManufacturingType,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]

export const routesTraineeShort = [
  {
    location: 'label',
    href: routesPath.exponentSampleDataLabel,
  },
  {
    location: 'exhibitorRef',
    href: routesPath.exponentSampleDataExhibitorRef,
  },
  {
    location: 'summary',
    href: routesPath.exponentSampleDataSummary,
  },
]
