import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { router, routesPath } from '../../router'
import { UserRole } from '../../graphql/generated/graphql'

import { ParamsTemplateProps } from '.'

export const useParamsTemplateProps = (): ParamsTemplateProps => {
  const user = useSelector(selectors.auth.user)
  const configationUpdate = useSelector(selectors.configuration.configurationUpdate)
  const importExhibitor = useSelector(selectors.configuration.importExhibitorEbms)
  const exportRedPoints = useSelector(selectors.configuration.exportRedPoints)
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const [isModalToastOpen, setIsModalToastOpen] = useState<boolean>(false)
  const [toastModalText, setToastModalText] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (exportRedPoints && (exportRedPoints.success || exportRedPoints.errors)) {
      setToastModalText(`Les exports points rouges ont été envoyés avec succès par mail.`)
      setIsModalToastOpen(true)
      setTimeout(() => {
        setIsModalToastOpen(false)
        dispatch(actions.configuration.resetExportRedPoints())
      }, 2000)
    }
  }, [dispatch, exportRedPoints])

  useEffect(() => {
    if (configationUpdate && configationUpdate.success) {
      setToastModalText(`Le délai d'import Marketplace a bien été modifié.`)
      setIsModalToastOpen(true)
      setTimeout(() => {
        setIsModalToastOpen(false)
        dispatch(actions.configuration.resetConfigurationUpdate())
        dispatch(actions.configuration.getConfigurationInfoRequest())
      }, 2000)
    }
  }, [configationUpdate, dispatch, navigate])

  useEffect(() => {
    if (importExhibitor && importExhibitor.success) {
      setToastModalText(`L'import du fichier à été effectué avec succès`)
      setIsModalToastOpen(true)
      setTimeout(() => {
        setIsModalToastOpen(false)
        dispatch(actions.configuration.resetImportExhibitorEbms())
      }, 2000)
    }
  }, [dispatch, importExhibitor])

  return useMemo(() => {
    const roleBasedInputList =
      user?.role === UserRole.SuperAdmin || user?.role === UserRole.Admin
        ? [
            { text: 'Les salons', href: '/salons/edition' },
            { text: 'Délai import Marketplace', href: '/parametres/delai-import-marketplace' },
            { text: 'Import Exposant Ebms ', href: '/parametres/import-exhibitor-ebms' },
            {
              text: 'Accès utilisateurs',
              href: '/parametres/acces-utilisateurs',
            },
            { text: 'Fiches échantillons', href: '/parametres/edition-echantillon' },
            {
              text: 'Caisses de rangements post-shooting',
              href: '/parametres/caisses-de-rangement',
            },
            { text: 'Emails automatiques', href: '/parametres/emails-automatiques' },
          ]
        : user?.role === UserRole.ModePv
        ? [
            {
              text: 'Caisses de rangements post-shooting',
              href: '/parametres/caisses-de-rangement',
            },
          ]
        : user?.role === UserRole.Marketplace
        ? [
            { text: 'Fiches échantillons', href: '/parametres/edition-echantillon' },
            { text: 'Emails automatiques', href: '/parametres/emails-automatiques' },
          ]
        : undefined

    return {
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.home)
              navigate(link)
            },
          },
        },
      },
      title: 'Paramètres',
      iconProps: {
        icon: Icons.arrowRight,
        width: 20,
      },
      inputList: roleBasedInputList,
      exportCta:
        user?.role === UserRole.SuperAdmin || user?.role === UserRole.Admin
          ? {
              exportText: 'Exports points rouges',
              onClick: () => {
                dispatch(actions.configuration.exportRedPointsRequest())
              },
            }
          : undefined,
      toastModal: {
        text: toastModalText,
        isOpen: isModalToastOpen,
        icon: { icon: Icons.check, height: 20, width: 20 },
      },
    }
  }, [dispatch, isModalToastOpen, layoutProps, navigate, toastModalText, user?.role])
}
