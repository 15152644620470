import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { Icons } from '../../components/Icon'
import { router, routesPath } from '../../router'
import { selectors } from '../../redux'
import { UserRole } from '../../graphql/generated/graphql'

import { SampleScanTemplateProps } from '.'

export const useSampleScanProps = (): SampleScanTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('scan')
  const navigate = useNavigate()
  const [openUpload, setOpenUpload] = useState(false)
  const user = useSelector(selectors.auth.user)

  return useMemo(
    () => ({
      layoutProps: {
        marginMainContent: false,
        ...layoutProps,
        bottomActions:
          user?.role !== UserRole.Rex &&
          user?.role !== UserRole.Marketplace &&
          user?.role !== UserRole.InputOperator
            ? {
                next: {
                  text: 'Scan multiple',
                  href: '/edition-multiple',
                  rightIconProps: {
                    icon: Icons.plus,
                    width: 18,
                    height: 18,
                  },
                },
              }
            : undefined,
      },
      closeBtn: () => {
        const link = router(routesPath.home)
        navigate(link)
      },
      scannerProps: {
        text: 'Scannez le QR code de l’echantillon',
        onDetect: (result: string) => {
          window.location.href = result
        },
        openError: () => setOpenUpload(true),
      },
      uploadModal: {
        title: 'Caméra indisponible',
        text:
          'Le scan est indisponible sans caméra. Veuillez vérifier dans les paramètres de votre appareil pour l’activer.',
        button: {
          text: 'Retour',
          onClick: () => {
            const link = router(routesPath.home)
            navigate(link)
          },
        },
        uploadHandler: undefined,
        isOpen: openUpload,
        onClose: () => {
          const link = router(routesPath.home)
          navigate(link)
        },
      },
    }),
    [layoutProps, navigate, openUpload, user?.role]
  )
}
