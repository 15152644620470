import styled from 'styled-components'

import Paragraph from '../Paragraph'
import ActionButton from '../ActionButton'
import Heading from '../Heading'
import Icon, { Icons } from '../Icon'

export const Note = styled.div``

export const Text = styled(Paragraph).attrs({ variant: 'titleH5', color: 'gray' })`
  background-color: ${({ theme }) => theme.colors.concrete};
  padding: 1rem;
  border-radius: 0.4rem;
`

export const Edit = styled(ActionButton)`
  ${({ theme }) => theme.textStyles.titleH7};
`

export const Author = styled.div`
  flex: 1;
  text-align: right;
`

export const Name = styled(Paragraph).attrs({ variant: 'textItalic', color: 'tundora' })`
  margin-bottom: 0;
`
export const Date = styled(Paragraph).attrs({ variant: 'textItalic', color: 'tundora' })`
  margin-bottom: 0;
`
export const NoteContainer = styled.div`
  padding: 12px 0;
`

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NoteTagContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
  flex-direction: column;
  gap: 12px;
`

export const Title = styled(Heading)``

export const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
`

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export const Close = styled(Icon).attrs({ icon: Icons.close, width: 20, height: 20 })``
