/* eslint-disable prettier/prettier */
import React, { FC } from 'react'

import { PerformanceCodeType } from '../../types/performanceCode'
import { Icons } from '../Icon'
import { frenchToEnglishMapping } from '../../types/frenchToEnglishMapping'

import * as SC from './styled'

export type PerformanceCodeProps = {
  className?: string
  text: string
  type: PerformanceCodeType
}

const PerformanceCode: FC<PerformanceCodeProps> = ({ className, text, type }) => {
  const englishType = frenchToEnglishMapping[type] || undefined

  return (
    <SC.Container className={className}>
      {text !== 'Aucun' && text !== '#NON SPÉCIFIÉ' && englishType && (
        <SC.IconContainer>
          <SC.StyledIcon icon={Icons[englishType]} />
        </SC.IconContainer>
      )}
      <SC.Text>{text}</SC.Text>
    </SC.Container>
  )
}

export default PerformanceCode
