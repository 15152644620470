import React, { FC } from 'react'

import * as SC from './styled'

type Tab = {
  name: string
  selected: boolean
  onClick: () => void
}

export type TabsProps = {
  className?: string
  tabs: Array<Tab>
}

const Tabs: FC<TabsProps> = ({ className, tabs }) => {
  return (
    <SC.Container className={className}>
      {tabs.map((tab, idx) => (
        <SC.Tab key={`tab-${idx}`} $selected={tab.selected} onClick={tab.onClick}>
          {tab.name}
        </SC.Tab>
      ))}
    </SC.Container>
  )
}

export default Tabs
