import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import i18next from 'i18next'
import { useSelector } from 'react-redux'

import {
  Collection,
  ExhibitionForum,
  FlagItem,
  Sample,
  SampleStatus,
  UserRole,
} from '../../graphql/generated/graphql'
import { sampleLabelReturn } from '../../relay/SampleSheet/SampleLabelReturn'
import { SampleCardProps } from '../../components/SampleCard'
import { useSampleSheetTabsProps } from '../../relay/SampleSheet/useSampleSheetTabsProps'
import { sampleStatusFrenchMapping } from '../../types/sampleStatusFrenchMapping'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutSampleSheetTemplateProps } from '../../layouts/LayoutSampleSheet/useProps'
import { router, routesPath } from '../../router'
import { selectors } from '../../redux'
import { convertMkpToString } from '../../relay/SampleSheet/ConvertMkpToString'

import { SampleSheetCollectionTemplateProps } from '.'

export const useSampleSheetCollectionTemplateProps = (
  collection: Collection | null,
  sample: Sample | undefined
): SampleSheetCollectionTemplateProps => {
  const { sampleId } = useParams<{ sampleId: string }>()
  const navigate = useNavigate()
  const [status, setStatus] = useState<SampleStatus | undefined>(undefined)
  const [forum, setForum] = useState<ExhibitionForum | undefined>(undefined)
  const [subForum, setSubForum] = useState<ExhibitionForum | undefined>(undefined)
  const [mkpForum, setMkpForum] = useState<FlagItem | undefined>(undefined)
  const [category, setCategory] = useState<FlagItem | undefined>(undefined)
  const user = useSelector(selectors.auth.user)
  const sampleById = useSelector(selectors.samples.getSampleById)

  const tabs = useSampleSheetTabsProps(sampleId, 'collection', user?.role)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const sampleLayoutProps = useLayoutSampleSheetTemplateProps(
    status,
    forum,
    subForum,
    mkpForum,
    sample,
    category
  )

  useEffect(() => {
    if (sample) {
      sample.status && setStatus(sample.status)
      sample.forum && setForum(sample.forum)
      sample.subForum && setSubForum(sample.subForum)
      if (sample.flagItems) {
        setMkpForum(
          sample &&
            sample.flagItems &&
            sample.flagItems.find(
              (item) => item.group && item.group.miraklRef === 'NON_MARKETPLACE_forum'
            )
        )
        setCategory(
          sample &&
            sample.flagItems &&
            sample.flagItems.find((item) => item.group && item.group.miraklRef === 'category')
        )
      }
    }
  }, [sample])

  const statistics: any = useMemo(() => {
    if (
      sample?.collection?.parcels &&
      user?.role !== UserRole.Intern &&
      user?.role !== UserRole.FashionExpert &&
      user?.role !== UserRole.InputOperator &&
      user?.role !== UserRole.Shooting
    ) {
      const sampleStats = sample && [
        {
          title: i18next.t('sampleCollection.statistics.statisticsSelected'),
          number: sample.collection.samples.length,
          color: '#fff5d9',
        },
        {
          title: i18next.t('sampleCollection.statistics.statisticsRecepted'),
          number: sample.collection.parcels.reduce((sum, item) => sum + item.samplesTotal, 0),
          color: '#e0e0e0',
        },
        {
          title: 'Retirés du forum',
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.Deleted)?.count ||
            0,
          color: '#FFE3E3',
        },
        {
          title: 'Validé forum',
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.Validated)
              ?.count || 0,
          color: '#DAEFE0',
        },
        {
          title: 'Ok Shooting',
          number:
            collection?.samplesStats?.find(
              (item) => item.status === SampleStatus.SelectedOkShooting
            )?.count || 0,
          color: '#FFE7DD',
        },
        {
          title: 'Ok vérif',
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.ValidatedChecked)
              ?.count || 0,
          color: '#34AB55',
        },
        {
          title: 'Ok étiquette',
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.ValidatedLabelled)
              ?.count || 0,
          color: '#98D3A8',
        },
      ]
      return {
        title: i18next.t('sampleCollection.statistics.title'),
        chart: {
          data:
            sample?.collection?.parcels &&
            sampleStats &&
            sampleStats.filter((item) => {
              return (
                !(
                  item.title === 'Validé forum' ||
                  item.title === 'Ok Shooting' ||
                  item.title === 'Retiré du forum' ||
                  item.title === 'Ok étiquette' ||
                  item.title === 'Ok vérif'
                ) || item.number > 0
              )
            }),
          label: [
            `${sample.collection.parcels.reduce(
              (sum, item) => sum + item.samplesTotal,
              0
            )} échantillons`,
            'réceptionnés',
          ],
        },
      }
    }
    return undefined
  }, [collection?.samplesStats, sample, user?.role])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...sampleLayoutProps,
        bottomActions: {
          ...sampleLayoutProps.bottomActions,
          back: undefined,
          next: undefined,
        },
        tabs: tabs,
      },
      pending: sampleById.pending,
      comment: collection?.comment
        ? {
            title: 'Commentaire de la collection',
            text: collection.comment,
            note: collection.note?.toString(),
          }
        : undefined,
      samples: {
        title: 'Échantillons',
        warnings:
          sample?.collection && sample?.collection.samples && sample?.collection.samples.length < 5
            ? [sampleLabelReturn(sample?.collection.samples.length)]
            : undefined,
        numbered:
          sample?.collection && sample?.collection.samples && sample?.collection.samples.length > 5
            ? {
                number: sampleLabelReturn(sample?.collection.samples.length),
              }
            : undefined,
        list: collection?.samples?.map((sample) => {
          const category =
            sample.flagItems && sample.flagItems.find((item) => item.group.miraklRef === 'category')
          return {
            title: sample.name,
            category: category && category?.name,
            status: {
              type:
                sample.status === SampleStatus.ValidatedOkMarketplace
                  ? SampleStatus.SelectedOkShooting
                  : sample.status,
              value:
                sample.status === SampleStatus.ValidatedOkMarketplace
                  ? (sampleStatusFrenchMapping[SampleStatus.SelectedOkShooting] as string)
                  : (sampleStatusFrenchMapping[sample.status] as string),
            },
            forumColor: sample.forum?.color,
            forum: sample.forum?.name,
            subForum: sample.subForum?.name,
            mkpForum: sample.mkpForum ? convertMkpToString(sample.mkpForum) : undefined,
            okMkp: sample.marketplaceImported,
            noMkp: sample.marketplaceImportError,
            number: `${sample.id}`,
            isDelete: sample.isDeleted,
            image:
              sample.photos && sample.photos[0]
                ? {
                    src: sample.photos[0].url,
                    alt: sample.photos[0].id,
                  }
                : undefined,
            onClick: () => {
              const link = router(routesPath.sampleSheetData, {
                sampleId: sample.id,
              })
              navigate(link)
            },
          }
        }) as SampleCardProps[] | undefined,
      },
      statistics: statistics,
    }),
    [
      collection?.comment,
      collection?.note,
      collection?.samples,
      layoutProps,
      navigate,
      sample?.collection,
      sampleById.pending,
      sampleLayoutProps,
      statistics,
      tabs,
    ]
  )
}
