import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutExhibitionSheetTemplateProps } from '../../layouts/LayoutExhibitionSheet/useProps'
import { useExhibitionSheetTabsProps } from '../../relay/ExhibitionSheet/UseExponentSheetTabsProps'
import {
  Exhibition,
  ExhibitionForum,
  SampleStatus,
  UserRole,
} from '../../graphql/generated/graphql'
import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../components/form/fields/FormFieldRadioGroup/types'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'

import { ExhibitionSheetForumSelected, ExhibitionSheetSamplesTemplateProps } from '.'

export const useExhibitionSheetSamplesTemplateProps = (
  exhibition: Exhibition | null,
  handleFiltersClick: () => void,
  setForumSelected: React.Dispatch<React.SetStateAction<string>>,
  forumSelected: string,
  forum: ExhibitionForum | undefined
): ExhibitionSheetSamplesTemplateProps => {
  const dispatch = useDispatch()
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const user = useSelector(selectors.auth.user)
  const sampleStats = useSelector(selectors.exhibitions.sampleStats)
  const tabs = useExhibitionSheetTabsProps(exhibitionId, 'samples', user?.role)
  const exhibitionLayoutProps = useLayoutExhibitionSheetTemplateProps(exhibition)
  const layoutProps = useDefaultLayoutTemplateProps('lounges')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [percentageSelected, setPercentageSelected] = useState<string>('0%')
  const [mappedForumsAndSubForums, setMappedForumsAndSubForums] = useState<any>(undefined)
  const validatedSampleStat =
    sampleStats && sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Validated)

  const receiptedSampleStat =
    sampleStats && sampleStats.find((sampleStat) => sampleStat.status === 'SAMPLES_RECEIVED')

  useEffect(() => {
    const updateMappedForums = () => {
      if (exhibition) {
        const newMappedForums = exhibition.forums.map((forum) => {
          const allSubForumsOption = {
            label: 'Tous les sous-forums',
            value: forum.id,
            dotted: false,
            color: forum.color as string,
            backgroundColor: getContrastColor(forum.color as string),
          }
          const subForums = forum.subForums
            ? [
                allSubForumsOption,
                ...forum.subForums.map((subForum) => ({
                  label: subForum.name,
                  value: subForum.id,
                  dotted: false,
                  color: forum.color as string,
                  backgroundColor: getContrastColor(forum.color as string),
                })),
              ]
            : [allSubForumsOption]

          return {
            value: forum.name,
            name: 'forum',
            requied: true,
            color: forum.color as string,
            backgroundColor: getContrastColor(forum.color as string),
            optionsFont: VariantRadioFont.SmallBold,
            optionsSize: VariantRadioSize.big,
            options: subForums,
          }
        })

        setMappedForumsAndSubForums(newMappedForums)
      }
    }

    updateMappedForums()
  }, [exhibition, forumSelected])

  useEffect(() => {
    exhibitionId &&
      dispatch(
        actions.exhibitions.getSampleStatsRequest({
          exhibitionApiId: exhibitionId,
          forumId: Number(forumSelected) || null,
        })
      )
  }, [dispatch, exhibitionId, forumSelected])

  const checkIfNewForumSelected = useCallback(
    (newValue: string) => {
      if (newValue !== forumSelected) {
        setIsOpen(false)
        setForumSelected(newValue)
      }
    },
    [forumSelected, setIsOpen, setForumSelected]
  )

  const samplesRecepted = sampleStats
    ? sampleStats
        .find((sampleStat) => sampleStat.status === 'SAMPLES_RECEIVED')
        ?.count.toString() || ''
    : ''

  const samplesSelected = sampleStats
    ? sampleStats
        .find((sampleStat) => sampleStat.status === SampleStatus.Selected)
        ?.count.toString() || ''
    : ''

  const sampleCompletedData = sampleStats
    ? sampleStats
        .find((sampleStat) => sampleStat.status === 'SAMPLES_COMPLETED_DATA')
        ?.count.toString() || ''
    : ''

  const samplesSelectedWithPhoto = sampleStats
    ? sampleStats
        .find((sampleStat) => sampleStat.status === 'SELECTED_WITH_PHOTO')
        ?.count.toString() || ''
    : ''

  const samplesOkMarketplace = sampleStats
    ? sampleStats
        .find((sampleStat) => sampleStat.status === 'STATUS_OK_MARKETPLACE')
        ?.count.toString() || ''
    : ''

  const samplesErrorImportMarketplace = sampleStats
    ? sampleStats
        .find((sampleStat) => sampleStat.status === 'STATUS_ERROR_MARKETPLACE')
        ?.count.toString() || ''
    : ''

  useEffect(() => {
    if (sampleStats && samplesRecepted)
      setPercentageSelected(
        `${(
          ((sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Selected)?.count ||
            0) /
            Number(samplesRecepted)) *
          100
        ).toFixed(1)} %`
      )
  }, [sampleStats, samplesRecepted])
  const statsDeleted =
    sampleStats && sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Deleted)
  const statsValidated =
    sampleStats && sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Validated)

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...exhibitionLayoutProps,
        bottomActions: {
          ...exhibitionLayoutProps.bottomActions,
          next:
            user?.role === UserRole.Admin ||
            user?.role === UserRole.SuperAdmin ||
            user?.role === UserRole.ModePv ||
            user?.role === UserRole.Marketplace
              ? {
                  text: 'Exporter les statistiques',
                  rightIconProps: {
                    icon: Icons.export,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => handleFiltersClick(),
                }
              : undefined,
        },
        tabs: tabs,
      },
      formikForm: {
        initialValues: ({
          forum: '',
        } as unknown) as ExhibitionSheetForumSelected,
        onSubmit: (values) => console.log(values),
        onContextUpdate(context) {
          checkIfNewForumSelected(context.values.forum)
        },
        validateOnChange: true,
      },
      allForum: [
        {
          value: 'Tout les forums',
          name: 'forum',
          requied: true,
          optionsFont: VariantRadioFont.SmallBold,
          optionsSize: VariantRadioSize.big,
          color: '#464646',
          backgroundColor: '#F2F2F2',
          options: [
            {
              label: 'Tous les forums',
              color: '#464646',
              backgroundColor: '#F2F2F2',
              value: '',
              dotted: false,
            },
          ],
        },
      ],
      subForumList: mappedForumsAndSubForums ? mappedForumsAndSubForums : [],
      samplesSelected: {
        nbr: samplesSelected,
        text: `Echantillon${Number(samplesSelected) > 1 ? 's' : ''} présélectionné${
          Number(samplesSelected) > 1 ? 's' : ''
        }`,
      },
      samplesRecepted: {
        nbr: samplesRecepted,
        text: `Echantillon${Number(samplesRecepted) > 1 ? 's' : ''} réceptionné${
          Number(samplesRecepted) > 1 ? 's' : ''
        }`,
      },
      samplesPercentSelected: {
        nbr: percentageSelected,
        text: 'Echantillons présélectionnés',
      },
      samplesSelectedWithPhoto: {
        nbr: samplesSelectedWithPhoto,
        text: 'Echantillons présélectionnés avec photo shooting',
      },
      samplesCompletedData: {
        nbr: sampleCompletedData,
        text: 'Echantillons saisie data complète',
      },
      samplesOkMarketplace: {
        nbr: samplesOkMarketplace,
        text: 'Echantillons OK Marketplace',
      },
      samplesErrorImportMarketplace: {
        nbr: samplesErrorImportMarketplace,
        text: 'Echantillons KO Marketplace',
      },
      statisticsTop:
        sampleStats &&
        sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Selected)?.count !== 0
          ? {
              title: 'Les statistiques',
              chart: {
                subtitle: 'Statut sélection',
                data: [
                  {
                    title: 'Présélectionnés',
                    number:
                      sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Selected)
                        ?.count || 0,
                    color: '#fff5d9',
                  },
                  {
                    title: 'Ok shooting',
                    number:
                      sampleStats.find(
                        (sampleStat) => sampleStat.status === SampleStatus.SelectedOkShooting
                      )?.count || 0,
                    color: '#FFE7DD',
                  },
                  {
                    title: `Retiré${
                      statsDeleted && Number(statsDeleted.count) > 1 ? 's' : ''
                    } du forum`,
                    number:
                      sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Deleted)
                        ?.count || 0,
                    color: '#FFE3E3',
                  },
                  {
                    title: `Validé${
                      statsValidated && Number(statsValidated.count) > 1 ? 's' : ''
                    } forum`,
                    number:
                      sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Validated)
                        ?.count || 0,
                    color: '#DAEFE0',
                  },
                ],
                label: [
                  `${(receiptedSampleStat && receiptedSampleStat.count) || '0'}`,
                  `échantillon${receiptedSampleStat && receiptedSampleStat?.count < 1 ? '' : 's'}`,
                  `réceptionné${receiptedSampleStat && receiptedSampleStat?.count < 1 ? '' : 's'}`,
                ],
              },
            }
          : undefined,
      statisticsBottom:
        sampleStats && validatedSampleStat && validatedSampleStat.count !== 0
          ? {
              title: 'Les statistiques',
              chart: {
                subtitle: 'Statut selection definitive',
                data: [
                  {
                    title: `Validé${
                      statsValidated && Number(statsValidated.count) > 1 ? 's' : ''
                    } forum`,
                    number: (validatedSampleStat && validatedSampleStat.count) || 0,
                    color: '#DAEFE0',
                  },
                  {
                    title: 'Ok étiquette',
                    number:
                      sampleStats.find(
                        (sampleStat) => sampleStat.status === SampleStatus.ValidatedLabelled
                      )?.count || 0,
                    color: '#98D3A8',
                  },
                  {
                    title: 'Ok vérif',
                    number:
                      sampleStats.find(
                        (sampleStat) => sampleStat.status === SampleStatus.ValidatedChecked
                      )?.count || 0,
                    color: '#34AB55',
                  },
                ],
                label: [
                  `${(validatedSampleStat && validatedSampleStat.count.toString()) || ''}`,
                  `échantillon${statsValidated && Number(statsValidated.count) > 1 ? 's' : ''} en `,
                  `validé forum`,
                ],
              },
            }
          : undefined,
      isCollapsibleOpen: isOpen,
      onCollapsibleCLick: () => setIsOpen(true),
      collapsibleTitle: forum ? forum.name : 'Tous les forums',
      collapsibleSecondTitle: forum
        ? forum.subForums.find((subForum) => subForum.id === forumSelected)?.name
        : undefined,
      forum: {
        color: forum ? (forum.color as string) : undefined,
        backgroundColor: forum ? getContrastColor(forum.color as string) : undefined,
      },
    }),
    [
      checkIfNewForumSelected,
      exhibitionLayoutProps,
      forum,
      forumSelected,
      handleFiltersClick,
      isOpen,
      layoutProps,
      mappedForumsAndSubForums,
      percentageSelected,
      receiptedSampleStat,
      sampleStats,
      samplesRecepted,
      samplesSelected,
      samplesSelectedWithPhoto,
      sampleCompletedData,
      samplesOkMarketplace,
      samplesErrorImportMarketplace,
      statsDeleted,
      statsValidated,
      tabs,
      user?.role,
      validatedSampleStat,
    ]
  )
}
