import { useMemo } from 'react'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutExhibitionSheetTemplateProps } from '../../layouts/LayoutExhibitionSheet/useProps'
import { useExhibitionSheetTabsProps } from '../../relay/ExhibitionSheet/UseExponentSheetTabsProps'
import { Exhibition } from '../../graphql/generated/graphql'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { selectors } from '../../redux'

import { ExhibitionSheetInfosTemplateProps } from '.'

export const useExhibitionSheetInfosTemplateProps = (
  exhibition: Exhibition | null
): ExhibitionSheetInfosTemplateProps => {
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const user = useSelector(selectors.auth.user)

  const tabs = useExhibitionSheetTabsProps(exhibitionId, 'infos', user?.role)
  const exhibitionLayoutProps = useLayoutExhibitionSheetTemplateProps(exhibition)

  const layoutProps = useDefaultLayoutTemplateProps('lounges')

  const formattedStartSampleDate = dayjs(exhibition?.dateSampleStart).format('DD')
  const formattedEndSampleDate = dayjs(exhibition?.dateSampleEnd).format('DD MMMM YYYY')

  const isSameMonthSample = dayjs(exhibition?.dateSampleStart).isSame(
    dayjs(exhibition?.dateSampleEnd),
    'month'
  )

  const dateRangeSample = isSameMonthSample
    ? `${formattedStartSampleDate} - ${formattedEndSampleDate}`
    : `${formattedStartSampleDate} ${dayjs(exhibition?.dateSampleStart).format(
        'MMMM'
      )} - ${formattedEndSampleDate}`

  const mappedForumsAndSubForums = exhibition?.forums.map((forum) => {
    return {
      id: forum.id,
      name: forum.name,
      backgroundColor: getContrastColor(forum.color as string),
      color: forum.color as string,
      subForums: forum.subForums
        ? forum.subForums.map((subForum) => ({
            id: subForum.id,
            name: subForum.name,
          }))
        : undefined,
    }
  })

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...exhibitionLayoutProps,
        tabs: tabs,
      },
      dateTitle: 'Période de sélection échantillons',
      date: dateRangeSample,
      forumsTitle: 'Les forums',
      subForumList: mappedForumsAndSubForums ? mappedForumsAndSubForums : [],
    }),
    [dateRangeSample, exhibitionLayoutProps, layoutProps, mappedForumsAndSubForums, tabs]
  )
}
