import styled from 'styled-components'

import PerformanceCode from '../../components/PerformanceCode'
import { breakpoints } from '../../theme'
import ActionButton from '../../components/ActionButton'
import Heading from '../../components/Heading'
import SampleChart from '../../components/SampleChart'
import Icon, { Icons } from '../../components/Icon'
import NumberedTag from '../../components/NumberedTag'
import SimpleLoader from '../../components/SimpleLoader'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
    gap: 24px;
  }
`

export const Loader = styled(SimpleLoader)`
  margin-left: 50%;
`
export const SliderContainer = styled.div`
  display: block;
  max-width: 257px;
  margin: auto;
  margin-bottom: 3rem;
  width: 100%;
  ${breakpoints.up('tablet')} {
    max-width: 350px;
  }
`
export const LabelContainerMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  ${breakpoints.up('tablet')} {
    display: none;
    max-height: 257px;
  }
`

export const MarketplaceWarning = styled(NumberedTag)`
  margin-top: 12px;
  margin-bottom: 24px;
`

export const LabelContainerDesktop = styled.div`
  display: none;
  ${breakpoints.up('tablet')} {
    display: flex;
    width: 50%;
    height: 520px;
    max-height: 520px;
  }
`

export const CloseBg = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    width: 50%;
  }
`

export const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  ${breakpoints.up('tablet')} {
    object-fit: contain;
    border-radius: 14px;
    width: 100%;
  }
`

export const ImageLabel = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  max-height: 80px;
  ${breakpoints.up('tablet')} {
    object-fit: contain;
    border-radius: 14px;
    width: 100%;
  }
`

export const BlockImage = styled.div`
  flex: 1;
`

export const Section = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  margin: 0;
  margin-top: 24px;
`

export const Title = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
  ${breakpoints.up('tablet')} {
  }
`

export const Chart = styled(SampleChart)``

export const StyledPerformanceCode = styled(PerformanceCode)``

export const PerformanceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const PerformanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const PerfomanceTitle = styled.span`
  margin-top: 24px;
  ${(props) => props.theme.textStyles.titleH5};
  color: ${(props) => props.theme.colors.gray};
`

export const ActionButtonDesktop = styled(ActionButton)`
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 70%;
  width: 100%;
`

export const BlockImageDesktop = styled.div`
  position: relative;
  height: auto;
  width: 100%;
`

export const Close = styled(Icon).attrs({ icon: Icons.close, width: 30, height: 30 })`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${({ theme }) => theme.colors.pureBlack};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.pureWhite};
  cursor: pointer;
  &:hover:hover {
    transition: transform 200ms ease-in, background-color 150ms ease-in, color 150ms ease-in;
    transform: scale(1.01);
  }
`
