import { burgerMocks } from '../Burger/mocks'
import { menuMocks } from '../Menu/mocks'
import { searchButtonMocks } from '../SearchButton/mocks'

import { MobileNavProps } from './index'

const basic: MobileNavProps = {
  logoLink: {
    href: '/',
  },
  burger: burgerMocks.basic,
  menu: { ...menuMocks.basic, isOpen: false },
  variant: 'default',
  search: false,
  searchButton: searchButtonMocks.basic,
}

const withoutSearch: MobileNavProps = {
  ...basic,
  searchButton: undefined,
}

export const mobileNavMocks = {
  basic,
  withoutSearch,
}
