import React, { FC } from 'react'

import Dot from '../Dot'
import { ForumVariant } from '../../types/forumVariant'

import * as SC from './styled'

export type BoxInfoProps = {
  className?: string
  text?: string
  secondText?: string
  isOn?: boolean
  noHover?: boolean
  firstdot?: boolean
  // pre-established colors
  variant?: ForumVariant
  // custom colors
  color?: string
  backgroundColor?: string
}

const BoxInfo: FC<BoxInfoProps> = ({
  className,
  text,
  secondText,
  firstdot,
  variant,
  isOn,
  noHover,
  color,
  backgroundColor,
}) => {
  return (
    <SC.StatutSelection
      className={className}
      $variant={variant}
      $isOn={isOn}
      $backgroundColor={backgroundColor}
      $color={color}
      $noHover={noHover}
    >
      {firstdot && <Dot />}
      {text && <SC.TextContainer className="text-content">{text}</SC.TextContainer>}
      {secondText && (
        <>
          <Dot />
          <SC.TextContainer className="text-content">{secondText}</SC.TextContainer>
        </>
      )}
    </SC.StatutSelection>
  )
}

export default BoxInfo
