import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutExhibitionSheetTemplateProps } from '../../layouts/LayoutExhibitionSheet/useProps'
import { useExhibitionSheetTabsProps } from '../../relay/ExhibitionSheet/UseExponentSheetTabsProps'
import { Exhibition, UserRole } from '../../graphql/generated/graphql'
import { selectors } from '../../redux'
import { parseFiltersExhibitorsFromStore } from '../../components/Filters/utils'
import { Icons } from '../../components/Icon'

import { ExhibitionSheetExhibitorsTemplateProps } from '.'

export const useExhibitionSheetExhibitorsTemplateProps = (
  exhibition: Exhibition | null,
  handleFiltersClick: () => void,
  exhibitorsNbr: string,
  universes: string[]
): ExhibitionSheetExhibitorsTemplateProps => {
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const user = useSelector(selectors.auth.user)
  const tabs = useExhibitionSheetTabsProps(exhibitionId, 'exhibitor', user?.role)
  const exhibitionLayoutProps = useLayoutExhibitionSheetTemplateProps(exhibition)
  const config = useSelector(selectors.configuration.configurationInfo)

  const data = config && parseFiltersExhibitorsFromStore(config?.dictionaries)

  const layoutProps = useDefaultLayoutTemplateProps('lounges')
  const universesConfig = config?.dictionaries.find((item) => item.type === 'UNIVERSES')?.items

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...exhibitionLayoutProps,
        tabs: tabs,
        bottomActions: {
          ...exhibitionLayoutProps.bottomActions,
          next:
            user?.role === UserRole.Admin ||
            user?.role === UserRole.SuperAdmin ||
            user?.role === UserRole.ModePv ||
            user?.role === UserRole.Marketplace
              ? {
                  text: 'Exporter les statistiques',
                  rightIconProps: {
                    icon: Icons.export,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => handleFiltersClick(),
                }
              : undefined,
        },
      },
      exhibitorsNbr: exhibitorsNbr,
      exhibitorsNbrtext: `Exposant${Number(exhibitorsNbr) > 1 ? 's' : ''}`,
      formikForm: {
        initialValues: {
          UNIVERSES: [],
          COUNTRIES: [],
        },
        onSubmit: () => undefined,
      },
      universTitle:
        universes && universes.length === universesConfig?.length
          ? 'Tous les univers'
          : universes.length === 0
          ? 'Selectionner un univers'
          : undefined,
      universMultipleTitle:
        universes && universes.length !== universesConfig?.length ? universes : undefined,
      UNIVERSES: {
        name: 'UNIVERSES',
        options: data
          ? (data.find((item) => item.name === 'UNIVERSES')?.options || []).map((option) => ({
              ...option,
              forceBackgroundColor: '#F2F2F2',
              display: 'default',
            }))
          : [],
      },
    }),
    [
      data,
      exhibitionLayoutProps,
      exhibitorsNbr,
      handleFiltersClick,
      layoutProps,
      tabs,
      universes,
      universesConfig?.length,
      user?.role,
    ]
  )
}
