import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleDataTemplate from '../../../templates/SampleData'
import { selectors } from '../../../redux'
import { useSampleDataTemplateProps } from '../../../templates/SampleData/useProps'

const ExponentSampleDataMkpForum: React.FC<RouteProps> = () => {
  const form = useSelector(selectors.samples.sampleFormByUniverse)
  const templateProps = useSampleDataTemplateProps(
    'Présélection PV',
    form.find((item) => item.name === 'MKP/Forum' || item.name === 'MKP/FORUM')
  )

  return <SampleDataTemplate {...templateProps} />
}

export default ExponentSampleDataMkpForum
