import { firstLoginFormMocks } from '../../components/forms/FirstLoginForm/mocks'
import { LayoutNotConnectedMocks } from '../../layouts/LayoutNotConnected/mocks'

import { FirstLoginTemplateProps } from './index'

const basic: FirstLoginTemplateProps = {
  title: 'Première connexion',
  layout: LayoutNotConnectedMocks.basic,
  firstLoginForm: firstLoginFormMocks.basic,
}

export const firstLoginTemplateMocks = {
  basic,
}
