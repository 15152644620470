import styled from 'styled-components'

import Link from '../Link'
import Icon from '../Icon'

export const LinkTo = styled(Link)`
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export const SearchIcon = styled(Icon)``
