import styled, { css } from 'styled-components'

import Heading from '../../components/Heading'
import { breakpoints, colors } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'
import { PVColors } from '../../types/pv-colors'
import FormikForm from '../../components/form/FormikForm'
import FormErrors from '../../components/form/FormErrors'
import FormFieldText from '../../components/form/fields/FormFieldInput'

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-block: 1.2rem;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem;
  }
`

export const Title = styled(Heading)`
  margin: 0;
`

export const Subtitle = styled(Heading)`
  ${({ theme }) => theme.textStyles.titleH4}
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:first-child) {
      margin-top: 3.6rem;
    }
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${colors.gray};
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
`

const itemStyles = css<{ $color?: PVColors }>`
  // default
  --bg: ${({ theme }) => theme.colors.concrete};
  --bg--hover: ${({ theme }) => theme.colors.grayLight};
  --border-color: ${({ theme }) => theme.colors.concrete};
  --border-color--hover: ${({ theme }) => theme.colors.grayLight};
  --color: ${({ theme }) => theme.colors.tundora};
  // styles
  ${({ theme }) => theme.textStyles.titleH6}
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  min-height: 4.6rem;
  margin: 0;
  overflow: hidden;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  border-color: var(--border-color);
  color: var(--color);
  background-color: var(--bg);
  border: 0.1rem solid var(--border-color);
  transition: all 200ms ease-in;
  // variants
  ${({ theme, $color }) =>
    $color === PVColors.Accessories
      ? css`
          --bg: ${theme.colors.albescentWhite};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.pizza};
          --color: ${theme.colors.pizza};
        `
      : $color === PVColors.Design
      ? css`
          --bg: ${theme.colors.amour};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.fuchsiaPink};
          --color: ${theme.colors.fuchsiaPink};
        `
      : $color === PVColors.Inspiration
      ? css`
          --bg: ${theme.colors.whiteLilac};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.royalPurple};
          --color: ${theme.colors.royalPurple};
        `
      : $color === PVColors.Leather
      ? css`
          --bg: ${theme.colors.janna};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.roseOfSharon};
          --color: ${theme.colors.roseOfSharon};
        `
      : $color === PVColors.Sourcing &&
        css`
          --bg: ${theme.colors.linkWater};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.danube};
          --color: ${theme.colors.danube};
        `}
`

export const Item = styled.li<{ $color?: PVColors }>`
  ${itemStyles}
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:first-child) {
      margin-top: 3.6rem;
    }
  }
`
export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  white-space: normal;
  &::before {
    content: '';
    flex: 0 0 auto;
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: currentColor;
  }
`
export const Number = styled.span``

export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  gap: 2.4rem;

  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:first-child) {
      margin-top: 5.6rem;
    }
  }
  ${breakpoints.up('large')} {
    gap: 3.6rem;
  }
`

export const FieldText = styled(FormFieldText)`
  width: 100%;
`

export const Errors = styled(FormErrors)``
