import styled from 'styled-components'

import ColoredLinkBlock from '../ColoredLinkBlock'
import MenuLink from '../MenuLink'
import SearchBar from '../SearchBar'

export const Menu = styled.div``

export const Search = styled(SearchBar)``

export const List = styled.div`
  margin-bottom: 0.5rem;
`

export const ListBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`

export const Link = styled(MenuLink)``
export const ColoredLink = styled(ColoredLinkBlock)``
