import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'
import AccountForm from '../../components/forms/AccountForm'
import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'
import { breakpoints } from '../../theme'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  ${breakpoints.up('tablet')} {
    padding-top: 36px;
  }
`

export const Description = styled.p`
  ${({ theme }) => theme.textStyles.titleH7};
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 2.4rem;
`

export const StyledContentWrapper = styled(ContentWrapper)`
  max-width: 37rem;
`

export const Form = styled(AccountForm)`
  margin: 0 auto;
  max-width: 900px;
  margin-top: 2.4rem;
`

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
`

export const SuccessMessage = styled.p`
  ${({ theme }) => theme.textStyles.titleH7};
  color: ${({ theme }) => theme.colors.chateauGreen};
  margin-top: 2.4rem;
  text-align: center;
`

export const Logout = styled(ActionButton)`
  ${({ theme }) => theme.textStyles.titleH7};
`
