import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

import ActionButton from '../ActionButton'
import FilterTag from '../FilterTag'
import Filters from '../Filters'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
`

export const TagList = styled(ScrollContainer)`
  display: flex;
  flex-flow: row nowrap;
  gap: 0.8rem;
  overflow: auto;
  white-space: nowrap;
`

export const Tag = styled(FilterTag)``
export const Button = styled(ActionButton)`
  flex: 0 0 auto;
`
export const ModalFilters = styled(Filters)``
