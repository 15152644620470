import { accountFormMocks } from '../../components/forms/AccountForm/mocks'
import { defaultLayoutMocks } from '../../layouts/DefaultLayout/mocks'

import { AccountTemplateProps } from './index'

const basic: AccountTemplateProps = {
  title: 'Mon compte',
  layout: defaultLayoutMocks.basic,
  form: accountFormMocks.basic,
  logout: {
    text: 'Déconnexion',
  },
}

export const accountTemplateMocks = {
  basic,
}
