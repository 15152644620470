import styled from 'styled-components'

import { breakpoints } from '../../theme'

export const SliderContainer = styled.div``

export const Title = styled.div`
  ${(props) => props.theme.textStyles.titleH5};
  color: ${(props) => props.theme.colors.gray};
  padding-bottom: 6px;
`

export const Error = styled.div`
  ${(props) => props.theme.textStyles.textBold};
  color: ${(props) => props.theme.colors.red};
  padding: 24px;
`

export const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  ${breakpoints.up('tablet')} {
    object-fit: cover;
    border-radius: 14px;
  }
`

export const ImageContainer = styled.div``
