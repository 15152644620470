import React, { FC } from 'react'

import LayoutSampleData, { LayoutSampleDataProps } from '../../layouts/LayoutSampleData'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'
import RichTextEditor, { RichTextEditorProps } from '../../components/RichTextEditor'
import { FormFieldInputProps } from '../../components/form/fields/FormFieldInput'
import { FormikFormProps } from '../../components/form/FormikForm'
import FormikField from '../../components/form/FormikField'

import * as SC from './styled'

export type ManageEmailFormValues = {
  subject: string
}

export type ManageEmailsTemplateProps = {
  layoutProps?: LayoutSampleDataProps
  toastModal?: ModalToastProps
  formikForm: FormikFormProps<ManageEmailFormValues>
  fields: {
    subject: FormFieldInputProps
    richTextEditor: RichTextEditorProps
  }
}

const ManageEmailsTemplate: FC<ManageEmailsTemplateProps> = ({
  layoutProps,
  toastModal,
  formikForm,
  fields,
}) => {
  return (
    <LayoutSampleData {...layoutProps} modals={<>{toastModal && <ModalToast {...toastModal} />}</>}>
      <SC.Content>
        <SC.Form {...formikForm}>
          <FormikField
            {...fields.subject}
            type="text"
            hasFloatingLabel={true}
            Component={SC.SubjectField}
          />
          <RichTextEditor {...fields.richTextEditor} />
        </SC.Form>
      </SC.Content>
    </LayoutSampleData>
  )
}

export default ManageEmailsTemplate
