import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { Icons } from '../../components/Icon'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { Exhibition } from '../../graphql/generated/graphql'
import { LoungeCardProps } from '../../components/LoungeCard'

import { LoungeList, LoungesTemplateProps } from '.'

export const useLoungesTemplateProps = (): LoungesTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { redirect } = useParams<{ redirect: string }>()
  const layoutProps = useDefaultLayoutTemplateProps(redirect === 'recherche' ? 'lounges' : 'params')
  const config = useSelector(selectors.configuration.configurationInfo)
  const lounges = useSelector(selectors.exhibitions.exhibitionsSearch)
  const currentYear = new Date().getFullYear().toString()
  const [search, setSearch] = useState<string>('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  useEffect(() => {
    config &&
      dispatch(
        actions.exhibitions.getExhibitionsRequest({
          q: search,
          first: 1000,
          page: 1,
        })
      )
  }, [config, config?.exhibitionActiveApiId, dispatch, search])

  const setLoungeAndRedirect = (lounge: Exhibition) => {
    const newArray = lounge?.forums.map((item) => ({
      ...item,
      selected: true,
      subForums:
        item.subForums &&
        item.subForums.map((subForum) => {
          return {
            ...subForum,
            selected: true,
          }
        }),
    }))
    const updatedLoungeConfig = {
      ...lounge,
      edit: true,
      forums: newArray,
    }
    dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig))
    const link = router(routesPath.loungesInfos)
    navigate(link)
  }

  const sortedData: LoungeList[] | null =
    lounges &&
    lounges
      .reduce((acc: LoungeList[], exhibition) => {
        const { apiId, name, seasonCode, dateStart, seasonYear } = exhibition
        const seasonFullYear = new Date(dateStart).getFullYear().toString()
        const season = `${seasonCode}${seasonYear}`
        const existingYearIndex = acc.findIndex((item) => item.year === seasonFullYear)
        const loungeItem: LoungeCardProps = {
          title: name,
          text: `Exhibition ID ${apiId}`,
          season: season,
          active:
            config && redirect === 'recherche' && apiId === config.exhibitionActiveApiId.toString()
              ? true
              : false,
          onClick: () => {
            if (redirect === 'edition') setLoungeAndRedirect(exhibition)
            if (redirect === 'recherche') {
              const link = router(routesPath.exhibitionSheetInfos, {
                exhibitionId: apiId as string,
              })
              navigate(link)
            }
          },
        }

        if (
          config &&
          redirect === 'recherche' &&
          apiId === config.exhibitionActiveApiId.toString()
        ) {
          acc.unshift({
            year: `En cours`,
            isCollapsibleOpen: true,
            noIcon: true,
            lounge: [loungeItem],
          })
        } else if (existingYearIndex !== -1) {
          acc[existingYearIndex].lounge.push(loungeItem)
        } else {
          acc.push({
            year: seasonFullYear,
            isCollapsibleOpen:
              search !== ''
                ? true
                : redirect === 'recherche'
                ? false
                : seasonFullYear === currentYear,
            lounge: [loungeItem],
          })
        }

        return acc
      }, [])
      .sort((a, b) => {
        if (a.year === 'En cours') {
          return -1 // Place 'En cours' at the top
        } else if (b.year === 'En cours') {
          return 1
        } else {
          return parseInt(b.year, 10) - parseInt(a.year, 10) // Sort by year
        }
      })

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            onClick: () => {
              const link =
                redirect === 'edition' ? router(routesPath.params) : router(routesPath.home)
              navigate(link)
            },
            rounded: true,
          },
          next:
            redirect === 'edition'
              ? {
                  text: 'Nouveau salon',
                  onClick: () => {
                    const link = router(routesPath.loungesInfos)
                    navigate(link)
                  },
                  rightIconProps: {
                    icon: Icons.plus,
                    width: 18,
                    height: 18,
                  },
                }
              : undefined,
        },
      },
      title: redirect === 'edition' ? 'Les salons' : 'Salons',
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      loungeList: sortedData as LoungeList[],
    }
  }, [layoutProps, navigate, redirect, search, sortedData])
}
