import React, { FC } from 'react'

import { FormikFormProps } from '../../components/form/FormikForm'
import ContentWrapper from '../../components/ContentWrapper'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import FormikField from '../../components/form/FormikField'
import { FormFieldRadioGroupProps } from '../../components/form/fields/FormFieldRadioGroup'
import { BoxInfoProps } from '../../components/BoxInfo'

import * as SC from './styled'

export type variant =
  | 'default'
  | 'validate'
  | 'delete'
  | 'leather'
  | 'sourcing'
  | 'accessories'
  | 'inspiration'
  | 'design'
  | 'forumSelect'

export type MultipleEditionDataSelectionTemplateFormValues = {
  radios?: string
}

export type MultipleEditionDataSelectionTemplateProps = {
  className?: string
  layoutProps?: LayoutWithBottomActionsProps
  title?: string
  subTitle?: string
  text?: string
  formikForm: FormikFormProps<MultipleEditionDataSelectionTemplateFormValues>
  radios?: FormFieldRadioGroupProps
  forumSelected?: BoxInfoProps
}

const MultipleEditionDataSelectionTemplate: FC<MultipleEditionDataSelectionTemplateProps> = ({
  layoutProps,
  text,
  title,
  subTitle,
  radios,
  formikForm,
  forumSelected,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        {title && <SC.Title tag={'h2'} text={title} />}
        <SC.SubTitle>{subTitle}</SC.SubTitle>
        <SC.Text>{text}</SC.Text>
        {forumSelected && <SC.StyledBoxInfo {...forumSelected} firstdot />}
        <SC.Form {...formikForm}>
          <SC.FormWrapper>
            <SC.FormFields>
              {radios && <FormikField Component={SC.StyledRadiosGroup} {...radios} />}
            </SC.FormFields>
          </SC.FormWrapper>
        </SC.Form>
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default MultipleEditionDataSelectionTemplate
