import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const upload = getMutationService({
  mutation: gql(`
    mutation upload($file: Upload!) { upload(file: $file) }
    `),
  transformer: (response) => {
    return response
  },
})
