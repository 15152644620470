import React, { FC, useState } from 'react'
import Collapsible, { CollapsibleProps } from 'react-collapsible'

import { Icons } from '../Icon'
import Dot from '../Dot'

import * as SC from './styled'

export type CollapsibleItemProps = Omit<CollapsibleProps, 'trigger'> & {
  className?: string
  title?: string
  secondTitle?: string
  onClick?: () => void
  children?: React.ReactNode
  operatorIcon?: boolean
  noBorder?: boolean
  noIcon?: boolean
  variant?: 'default' | 'concreteBg'
  color?: string
  backgroundColor?: string
  isCollapsibleOpen?: boolean
}

const CollapsibleItem: FC<CollapsibleItemProps> = ({
  className,
  title,
  secondTitle,
  onClick,
  children,
  variant,
  operatorIcon,
  noBorder,
  noIcon,
  isCollapsibleOpen,
  color,
  backgroundColor,
  ...collapsible
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isCollapsibleOpen ? isCollapsibleOpen : false)

  return (
    <SC.CollapsibleItem className={className} $noBorder={noBorder}>
      <Collapsible
        {...collapsible}
        onTriggerOpening={() => setIsOpen(true)}
        onTriggerClosing={() => setIsOpen(false)}
        open={isCollapsibleOpen ? isCollapsibleOpen : false}
        trigger={
          <SC.Head
            $variant={variant}
            $color={color}
            $backgroundColor={backgroundColor}
            className="head"
            onClick={onClick}
          >
            <SC.TitleContainer>
              <SC.Title $color={color}>{title}</SC.Title>
              {secondTitle && (
                <>
                  <Dot />
                  <SC.Title $color={color}>{secondTitle}</SC.Title>
                </>
              )}
            </SC.TitleContainer>
            {!noIcon ? (
              <SC.IconContainer>
                {isOpen ? (
                  <SC.Minus
                    icon={operatorIcon ? Icons.minus : Icons.caretTop}
                    width={operatorIcon ? 14 : 10}
                    height={operatorIcon ? 14 : 10}
                  />
                ) : (
                  <SC.Plus
                    icon={operatorIcon ? Icons.plus : Icons.caretDown}
                    width={operatorIcon ? 14 : 10}
                    height={operatorIcon ? 14 : 10}
                  />
                )}
              </SC.IconContainer>
            ) : null}
          </SC.Head>
        }
        easing={'ease'}
      >
        <SC.Content>{children}</SC.Content>
      </Collapsible>
    </SC.CollapsibleItem>
  )
}

export default CollapsibleItem
