import React, { FC, InputHTMLAttributes } from 'react'

import { VariantColored } from '../../../CollapsibleColored'

import * as SC from './styled'

export type FormFieldScoreProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & {
  className?: string
  name: string
  value?: string
  options: { label: string; value: string; disabled?: boolean; variant?: VariantColored }[]
  required?: boolean
  disabled?: boolean
  help?: string
  error?: string
}

const FormFieldScore: FC<FormFieldScoreProps> = ({
  className,
  name,
  options,
  disabled,
  help,
  error,
  ...inputProps
}) => {
  return (
    <SC.Container className={className}>
      <SC.Options>
        {options?.map((option) => (
          <SC.Option
            key={option.value}
            $isInputDisabled={disabled}
            $isOptionDisabled={option.disabled}
          >
            <SC.OptionRadio
              {...inputProps}
              type="radio"
              name={name}
              value={option.value}
              disabled={disabled || option.disabled}
              checked={inputProps.value === option.value}
            />
            <SC.Label>{option.label}</SC.Label>
          </SC.Option>
        ))}
      </SC.Options>
      {!error && help && <SC.Help>{help}</SC.Help>}
      {error && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldScore
