import React, { FC } from 'react'
import { NumericFormatProps } from 'react-number-format'

import { FormFieldInputProps } from '../FormFieldInput'

import * as SC from './styled'

export type FormFieldInputNumberProps = FormFieldInputProps & NumericFormatProps

const FormFieldInputNumber: FC<FormFieldInputNumberProps> = (props) => {
  const { ...extraProps } = props

  return <SC.Input {...extraProps} InputComponent={SC.InputComponent} inputProps={extraProps} />
}

export default FormFieldInputNumber
