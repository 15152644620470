import i18next from 'i18next'

export const sampleLabelReturn = (totalSample: number): string => {
  if (totalSample === 0) return i18next.t('exponent.collection.noSampleEdit')
  if (totalSample === 1) return '1 échantillon présélectionné'
  else
    return i18next.t('exponent.collection.noSamplesEdit', {
      samples: totalSample.toString(),
    })
}
