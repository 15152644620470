import React, { FC, useCallback, useRef } from 'react'

import { ModalProps } from '../Modal'
import { ActionButtonProps } from '../ActionButton'
import { ActionButtonSize } from '../ActionButton/types'
import { ModalVariant } from '../Modal/types'

import * as SC from './styled'

export type ModalUploadProps = ModalProps & {
  className?: string
  title?: string
  text?: string
  button?: ActionButtonProps
  uploadHandler?: (file: string) => void
}

const ModalUpload: FC<ModalUploadProps> = ({
  className,
  title,
  text,
  button,
  uploadHandler,
  ...modal
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange = (e: any) => {
    const files = e.target.files
    const file = files[0]
    getBase64(file)
  }

  const onLoad = (fileString?: any) => {
    if (!fileString) return
    uploadHandler?.(fileString)
  }

  const getBase64 = (file: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      onLoad(reader.result)
    }
  }

  const handleButtonClick = useCallback(() => {
    if (!inputRef.current) return
    inputRef.current.click()
  }, [])

  return (
    <SC.Container className={className} variant={ModalVariant.Small} {...modal}>
      <SC.Content>
        <SC.Head>
          {title && <SC.Title text={title} tag={'h3'} />}
          <SC.Button onClick={modal.onClose}>
            <SC.Close />
          </SC.Button>
        </SC.Head>
        {text && <SC.Text text={text} />}
        <SC.Form>
          <input
            ref={inputRef}
            type="file"
            onChange={onChange}
            accept="image/png, image/jpeg, image/gif, image/webp"
          />
        </SC.Form>
        {uploadHandler ? (
          <SC.StyledButton
            {...button}
            onClick={handleButtonClick}
            variantSize={ActionButtonSize.Large}
          />
        ) : (
          <SC.StyledButton {...button} variantSize={ActionButtonSize.Large} />
        )}
      </SC.Content>
    </SC.Container>
  )
}

export default ModalUpload
