import React, { FC } from 'react'

import { Icons } from '../../components/Icon'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type SampleSheetsTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title?: string
  subtitle?: string
  buttons?: ActionButtonProps[]
}

const SampleSheetsTemplate: FC<SampleSheetsTemplateProps> = ({
  layoutProps,
  title,
  subtitle,
  buttons,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <SC.StyledContentWrapper maxWidth={'default'}>
        {title && <SC.Title tag={'h2'} text={title} />}
        {subtitle && <SC.Subtitle tag={'h5'} text={subtitle} />}
        {buttons && buttons.length > 0 && (
          <SC.List>
            {buttons.map((button, index) => (
              <SC.StyledActionButton
                key={`SampleSheets-${index}`}
                rightIconProps={{
                  icon: Icons.arrowRight,
                  width: 20,
                }}
                variantScheme={ActionButtonScheme.Secondary}
                variantSize={ActionButtonSize.Large}
                {...button}
              />
            ))}
          </SC.List>
        )}
      </SC.StyledContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default SampleSheetsTemplate
