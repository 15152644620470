import styled, { css } from 'styled-components'

import { VariantColored } from '../../../CollapsibleColored'
import colors from '../../../../theme/colors'

import { VariantCheckboxFont, VariantCheckboxSize } from './types'

export type Display = 'default' | 'simple' | 'withDot' | 'withDotMargin'

// const leatherVariantSelected = css`
//   border: 1px solid ${(props) => props.theme.colors.roseOfSharon};
// `

// const sourcingVariantSelected = css`
//   border: 1px solid ${(props) => props.theme.colors.danube};
// `

// const accessoriesVariantSelected = css`
//   border: 1px solid ${(props) => props.theme.colors.pizza};
// `

// const inspirationVariantSelected = css`
//   border: 1px solid ${(props) => props.theme.colors.royalPurple};
// `

// const designVariantSelected = css`
//   border: 1px solid ${(props) => props.theme.colors.fuchsiaPink};
// `

// const leatherVariant = css`
//   color: ${(props) => props.theme.colors.roseOfSharon};
//   background-color: ${(props) => props.theme.colors.janna};
//   border: 1px solid ${(props) => props.theme.colors.janna};
//   &:hover {
//     border-color: ${(props) => props.theme.colors.roseOfSharon};
//   }
// `

// const sourcingVariant = css`
//   color: ${(props) => props.theme.colors.danube};
//   background-color: ${(props) => props.theme.colors.linkWater};
//   border: 1px solid ${(props) => props.theme.colors.linkWater};
//   &:hover {
//     border-color: ${(props) => props.theme.colors.danube};
//   }
// `

// const accessoriesVariant = css`
//   color: ${(props) => props.theme.colors.pizza};
//   background-color: ${(props) => props.theme.colors.albescentWhite};
//   border: 1px solid ${(props) => props.theme.colors.albescentWhite};
//   &:hover {
//     border-color: ${(props) => props.theme.colors.pizza};
//   }
// `

// const inspirationVariant = css`
//   color: ${(props) => props.theme.colors.royalPurple};
//   background-color: ${(props) => props.theme.colors.whiteLilac};
//   border: 1px solid ${(props) => props.theme.colors.whiteLilac};
//   &:hover {
//     border-color: ${(props) => props.theme.colors.royalPurple};
//   }
// `

// const designVariant = css`
//   color: ${(props) => props.theme.colors.fuchsiaPink};
//   background-color: ${(props) => props.theme.colors.amour};
//   border: 1px solid ${(props) => props.theme.colors.amour};
//   &:hover {
//     border-color: ${(props) => props.theme.colors.fuchsiaPink};
//   }
// `

export const Container = styled.div<{
  $isDisabled: boolean
  $size?: VariantCheckboxSize
  $variantFont?: VariantCheckboxFont
}>`
  ${({ theme, $variantFont }) =>
    $variantFont === VariantCheckboxFont.SmallBold
      ? theme.textStyles.titleH6
      : $variantFont === VariantCheckboxFont.Bold
      ? theme.textStyles.textBold
      : $variantFont === VariantCheckboxFont.BoldCaps
      ? css`
          ${theme.textStyles.titleH4}
        `
      : theme.textStyles.titleH5}
  ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.4;
    `}
`

export const Label = styled.label<{
  $isDisabled: boolean
  $isError?: boolean
  $display?: Display
  $variant?: VariantColored
  $checked?: boolean
  $forceBackgroundColor?: string
  $color?: string
  $size?: VariantCheckboxSize
}>`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  /* &:hover {
    border: 1px solid;
    border-color: ${colors.mineShaft};
  } */
  ${(props) =>
    props.$isError &&
    css`
      background: ${props.theme.colors.yourPink};
    `}
  ${(props) =>
    props.$isDisabled &&
    css`
      cursor: not-allowed;
    `}
  ${(props) =>
    props.$display === 'default' &&
    css`
      ${({ theme }) => theme.textStyles.titleH4}
      justify-content: space-between;
      background: ${({ theme }) => theme.colors.concrete};
    `}
  ${(props) =>
    props.$display === 'simple' &&
    css`
      ${({ theme }) => theme.textStyles.titleH5}
      justify-content: flex-start;
      gap: 0.8rem;
    `}
  ${(props) =>
    props.$display === 'withDot' &&
    css`
      ${({ theme }) => theme.fonts.GothamMedium}
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.tundora};
      justify-content: space-between;
      gap: 0.8rem;
      padding-left: 1.2rem 2rem;
    `}
  ${(props) =>
    props.$variant &&
    css`
      padding: 1.2rem 2rem;
    `}
  ${(props) =>
    props.$forceBackgroundColor &&
    css`
      color: ${props.$color};
      background-color: ${props.$forceBackgroundColor};
      padding: 1.2rem 2rem;
    `}
  ${(props) =>
    props.$checked &&
    css`
      /* border: 1px solid;
      border-color: ${(props) => props.theme.colors.concrete}; */
    `}
  min-height: ${({ $size }) => $size === VariantCheckboxSize.big && '4.6rem'};
`

export const LabelText = styled.p<{ $required: boolean; $isError?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  ${(props) =>
    props.$required &&
    css`
      &:after {
        content: ' *';
        color: ${(props) => props.theme.colors.red};
      }
    `}
`

export const StyledIcon = styled.span`
  flex: 0 0 auto;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.pureWhite};
`

export const Exponent = styled.span`
  ${({ theme }) => theme.textStyles.textBold};
  color: ${({ theme }) => theme.colors.silver};
  font-size: 1rem;
  height: 2rem;
`

export const Checkbox = styled.input<{ $isError?: boolean }>`
  position: relative;
  appearance: none;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid ${({ theme }) => theme.colors.mineShaft};
  margin: 0;
  cursor: pointer;
  border-radius: 0;
  flex-shrink: 0;
  border-radius: 0.4rem;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  &::before {
    content: '';
    display: inline-block;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    opacity: 0;
    transition: all 0.1s ease-out;
    background-color: ${({ theme }) => theme.colors.pureBlack};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked::before {
    opacity: 1;
  }
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.gray};
  text-align: right;
  margin-top: 0.6rem;
`
export const Error = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.6rem;
  text-align: right;
`
export const LabelHeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
`

export const CheckboxContainer = styled.div`
  width: 100%;
`
