import { MenuDesktopProps } from './index'

const basic: MenuDesktopProps = {
  links: [
    {
      text: 'Gestion colis',
      href: '/exposants/redirect',
      ariaCurrent: true,
    },
    {
      text: 'Séléction échantillons',
      href: '/exposants/search',
    },
    {
      text: 'Scan échantillon',
      href: '/scan-echantillon',
    },
    {
      text: 'Exposants',
      href: '/exposants/search',
    },
    {
      text: 'Salons',
      href: '/salons',
    },
    {
      text: 'Paramètres',
      href: '/parametres',
    },
    {
      text: 'Mon compte',
      href: '/mon-compte',
    },
  ],
}

export const menuDesktopMocks = {
  basic,
}
