import React, { FC } from 'react'

import * as SC from './styled'

export type ContentWrapperProps = {
  className?: string
  children?: React.ReactNode
  maxWidth?: SC.MaxWidth
  noMargin?: boolean
}

const ContentWrapper: FC<ContentWrapperProps> = ({
  className,
  children,
  maxWidth = 'default',
  noMargin,
}) => {
  return (
    <SC.Container className={className} $maxWidth={maxWidth} $noMargin={noMargin}>
      {children}
    </SC.Container>
  )
}

export default ContentWrapper
