import React, { FC, useEffect, useRef, useState } from 'react'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type StepsProps = {
  className?: string
  steps: (LinkProps & { isCurrent: boolean; value: string; resume?: string[] })[]
}

const Steps: FC<StepsProps> = ({ className, steps }) => {
  const [currentStep, setCurrentStep] = useState<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (currentStep !== null && containerRef.current) {
      const currentElement = containerRef.current.children[currentStep] as HTMLElement
      if (currentElement)
        currentElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
    }
  }, [currentStep])

  useEffect(() => {
    const index = steps.findIndex((step) => step.isCurrent)
    setCurrentStep(index !== -1 ? index : null)
  }, [steps])

  return (
    <SC.Container className={className} ref={containerRef}>
      {steps.map((step, index) => {
        const { value, isCurrent, resume, ...linkProps } = step
        return (
          <SC.Step key={`step-${index}`} $isCurrent={isCurrent} {...linkProps}>
            <SC.Value>{value}</SC.Value>
            {resume && resume.map((item, idx) => <SC.Resume key={idx}>{item}</SC.Resume>)}
          </SC.Step>
        )
      })}
    </SC.Container>
  )
}

export default Steps
