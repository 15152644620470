import styled from 'styled-components'

import Heading from '../../components/Heading'
import { breakpoints, fonts } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'
import Divider from '../../components/Divider'
import Steps from '../../components/Steps'
import StatutSelection from '../../components/StatutSelection/index'
import ActionButton from '../../components/ActionButton'
import FormikForm from '../../components/form/FormikForm'
import FormikField from '../../components/form/FormikField'
import ContentWithSteps from '../../components/ContentWithSteps'

export const Content = styled(ContentWithSteps)``

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const StyledSteps = styled(Steps)``

export const PageTitle = styled.h2`
  ${fonts.GothamBold};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-top: 0.7rem;
  ${breakpoints.up('tablet')} {
    padding-top: 4.4rem;
  }
`

export const Title = styled(Heading)`
  padding-bottom: 1.2rem;
  ${breakpoints.up('tablet')} {
    padding-top: 1rem;
    margin-top: 0;
  }
`

export const SubTitle = styled.div`
  font-size: 1.7rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.tundora};
  padding-bottom: 1.2rem;
`

export const StyledDivider = styled(Divider)``

export const CTAListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem 0;
  padding-bottom: 3rem;
`

export const ForumSelect = styled(StatutSelection)`
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  ${breakpoints.up('tablet')} {
    justify-content: inherit;
  }
`

export const NewForumButton = styled(ActionButton)`
  ${breakpoints.up('tablet')} {
    width: 100%;
    justify-content: flex-end;
  }
`

export const EditButton = styled(ActionButton)`
  ${breakpoints.up('tablet')} {
    width: 100%;
    justify-content: flex-end;
  }
`

export const StyledFormikForm = styled(FormikForm)`
  width: 100%;
`

export const StyledFormikField = styled(FormikField)`
  & > div {
    gap: 0.8rem;
  }
`
