export const shortUniversConverter = (universe: string | undefined): string => {
  switch (universe) {
    case 'Accessories':
      return 'ACCESS'
    case 'Designs':
      return 'DESIGN'
    case 'Fabrics':
      return 'FAB'
    case 'Leather':
      return 'LEA'
    case 'Manufacturing':
      return 'MANUF'
    case 'Manufacturing Leather':
      return 'MANUF-LEA'
    case 'Smart':
    case 'Smart Creation':
      return 'SMART'
    case 'Yarns':
      return 'YARNS'
    default:
      return ''
  }
}
