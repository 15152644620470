import { all, fork } from 'redux-saga/effects'

import ApiSagas from './api/sagas'
import AppSagas from './app/sagas'
import AuthSagas from './auth/sagas'
import UserSagas from './user/sagas'
import ExhibitorsSagas from './exhibitors/sagas'
import ConfigurationSagas from './configuration/sagas'
import BoxesSagas from './boxes/sagas'
import SamplesSagas from './samples/sagas'
import ExhibitionsSagas from './exhibitions/sagas'
import EditosSagas from './editos/sagas'
import MultipleEditionSagas from './multipleEdition/sagas'
import MailsSagas from './mails/sagas'

function* allListeners() {
  yield all([
    ApiSagas.listeners(),
    AppSagas.listeners(),
    AuthSagas.listeners(),
    UserSagas.listeners(),
    ExhibitorsSagas.listeners(),
    ConfigurationSagas.listeners(),
    BoxesSagas.listeners(),
    SamplesSagas.listeners(),
    ExhibitionsSagas.listeners(),
    EditosSagas.listeners(),
    MultipleEditionSagas.listeners(),
    MailsSagas.listeners(),
  ])
}

export default function* rootSaga() {
  yield fork(allListeners)
}
