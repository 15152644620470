import React from 'react'
import { RouteProps } from 'react-router-dom'

import UsersManagementTemplate from '../../templates/UsersManagement'
import { useUsersManagementTemplateProps } from '../../templates/UsersManagement/useProps'

const ParamsUserCreate: React.FC<RouteProps> = () => {
  const templateProps = useUsersManagementTemplateProps()

  return (
    <>
      <UsersManagementTemplate {...templateProps} />
    </>
  )
}

export default ParamsUserCreate
