/* eslint-disable import/no-named-as-default-member */
import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormSubmitButton from '../../form/FormSubmitButton'
import FormErrors from '../../form/FormErrors'
import Heading from '../../Heading'
import FormFieldCheckboxGroup from '../../form/fields/FormFieldCheckboxGroup'
import CollapsibleItem from '../../CollapsibleItem'
import FormFieldRadioGroup from '../../form/fields/FormFieldRadioGroup'
import { fonts } from '../../../theme'
import ActionButton from '../../ActionButton'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Group = styled.div`
  margin-bottom: 1.2rem;
`

export const ResetButton = styled(ActionButton)`
  ${fonts.GothamMedium}
  align-self: center;
  margin-top: 1rem;
  font-weight: 400;
`

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.colors.mineShaft};
  padding: 12px 0 12px 0;
`

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  padding: 12px 0 12px 0;
`
export const Value = styled(Heading).attrs({ tag: 'h5' })`
  padding: 1rem;
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.6rem;
`

export const FormTitle = styled.p`
  ${fonts.RobotoLight}
  font-size: 1.7rem;
  margin-bottom: 1.2rem;
`

export const FieldCheckboxGroup = styled(FormFieldCheckboxGroup)`
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 48px;
`

export const Collapsible = styled(CollapsibleItem)``

export const Errors = styled(FormErrors)``

export const SubmitButton = styled(FormSubmitButton)`
  ${fonts.GothamBold}
  width: 100%;
  align-self: center;
  margin-top: 2.4rem;
  justify-content: flex-end;
`

export const SubmitUniverseButton = styled(FormSubmitButton)`
  ${fonts.GothamBold}
  align-self: center;
  margin-top: 2.4rem;
  justify-content: flex-end;
`

export const Fieldset = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  gap: 2.4rem;
  border: 0 none;
  padding: 0;
  margin: 0;
`
export const Radio = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`
export const StyledRadiosGroup = styled(FormFieldRadioGroup)``
