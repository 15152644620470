import React, { FC } from 'react'

import { SampleStatus } from '../../graphql/generated/graphql'

import * as SC from './styled'

export type SampleResumeInfoProps = {
  className?: string
  status?: { type: SampleStatus; value: string }
  forum?: { values: Array<string>; color?: string; backgroundColor?: string }
  okMkp?: boolean
  noMkp?: boolean
  mkpForum?: string
  isDelete?: boolean
}

const SampleResumeInfo: FC<SampleResumeInfoProps> = ({
  className,
  status,
  forum,
  okMkp,
  noMkp,
  mkpForum,
  isDelete,
}) => {
  return (
    <SC.Container className={className}>
      {isDelete ? (
        <SC.Status $status={SampleStatus.Deleted}>
          Supprimé
          <SC.Dot />
        </SC.Status>
      ) : (
        <SC.Status $status={status && status.type}>
          {status && status.value}
          <SC.Dot />
        </SC.Status>
      )}
      {forum && (
        <SC.Forum $backgroundColor={forum.backgroundColor} $color={forum.color}>
          {forum.values.map((val, key) => (
            <SC.Content key={key}>
              {val}
              <SC.Dot />
            </SC.Content>
          ))}
        </SC.Forum>
      )}
      {mkpForum && (
        <SC.MpkForum $okMkp={okMkp} $noMkp={noMkp}>
          {mkpForum}
          <SC.Dot />
        </SC.MpkForum>
      )}
    </SC.Container>
  )
}

export default SampleResumeInfo
