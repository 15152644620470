import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import UsersAccessTemplate from '../../templates/UsersAccess'
import { useParamsUsersTemplateProps } from '../../templates/UsersAccess/useProps'
import { actions, selectors } from '../../redux'
import { filtersInlineMocks } from '../../components/FiltersInline/mocks'
import { User, UserRole } from '../../graphql/generated/graphql'
import { Icons } from '../../components/Icon'
import ModalToast from '../../components/ModalToast'

const ParamsUsers: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [tags, setTags] = useState<UserRole[]>([])
  const [allUsers, setAllUsers] = useState<User[]>([])
  const users = useSelector(selectors.user.users)
  const useNewUser = useSelector(selectors.user.createUser)
  const useUpdateUser = useSelector(selectors.user.updateUser)
  const userDelete = useSelector(selectors.user.userDelete)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  useEffect(() => {
    dispatch(
      actions.user.getUsersRequest({
        q: search,
        first: 10,
        page: currentPage,
        roles: tags,
      })
    )
  }, [currentPage, dispatch, search, tags, useUpdateUser])

  useEffect(() => {
    users && currentPage !== 1 && setAllUsers([...allUsers, ...users])
    users && currentPage === 1 && setAllUsers([...users])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    setAllUsers([])
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, search])

  useEffect(() => {
    if (useNewUser.success) {
      setCurrentPage(1)
      setAllUsers([])
      dispatch(actions.user.setUsers([]))
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.user.resetCreateUser())
      }, 2000)
    }
    if (useUpdateUser.success) {
      setCurrentPage(1)
      setAllUsers([])
      dispatch(actions.user.setUsers([]))
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.user.resetUpdateUser())
      }, 2000)
    }
    if (userDelete.success) {
      setCurrentPage(1)
      setAllUsers([])
      dispatch(actions.user.setUsers([]))
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.user.resetUserDelete())
      }, 2000)
    }
  }, [dispatch, tags, useNewUser.success, useUpdateUser.success, userDelete.success])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const templateProps = useParamsUsersTemplateProps(search, handleSearchChange, allUsers)

  const roleTranslations = {
    ADMIN: 'DSI - Super admin',
    FASHION_EXPERT: 'Expert en Mode',
    INPUT_OPERATOR: 'Opérateur de Saisie',
    INTERN: 'Stagiaire',
    MARKETPLACE: 'Équipe marketplace',
    MODE_PV: 'Équipe mode PV',
    REX: 'REX',
    SHOOTING: 'Équipe shooting',
    SUPER_ADMIN: 'DSI - Super admin',
  }

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text={
            useUpdateUser.success
              ? `Les modifications ont été apportées à l'utilisateur ${useUpdateUser.data?.lastName}  ${useUpdateUser.data?.firstName}.`
              : useNewUser.success
              ? "L'utilisateur a été créé. Un e-mail lui a été envoyé pour configurer son mot de passe."
              : userDelete.success
              ? `L'utilsateur ${userDelete.data?.lastName}  ${userDelete.data?.firstName} a bien été supprimé.`
              : ''
          }
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}
      <UsersAccessTemplate
        {...templateProps}
        filtersInline={{
          ...filtersInlineMocks.basic,
          tags: tags.map((tag) => ({ text: roleTranslations[tag] || tag })),
          filters: {
            formikForm: {
              initialValues: {
                roles: [],
              },
              onContextUpdate: (context) => {
                setTags(context.values.roles as UserRole[])
              },
              onSubmit: (values) => console.log('values', values),
              validateOnChange: true,
            },
            field: {
              name: 'roles',
              label: 'Rôles',
              required: true,
              displayLabel: false,
              options: [
                {
                  label: 'DSI - Super admin',
                  value: UserRole.SuperAdmin,
                  display: 'simple',
                },
                {
                  label: 'Équipe marketplace',
                  value: UserRole.Marketplace,
                  display: 'simple',
                },
                {
                  label: 'Équipe shooting',
                  value: UserRole.Shooting,
                  display: 'simple',
                },
                {
                  label: 'Équipe mode PV',
                  value: UserRole.ModePv,
                  display: 'simple',
                },
                {
                  label: 'Expert mode',
                  value: UserRole.FashionExpert,
                  display: 'simple',
                },
                {
                  label: 'Opérateur de saisie',
                  value: UserRole.InputOperator,
                  display: 'simple',
                },
                {
                  label: 'REX',
                  value: UserRole.Rex,
                  display: 'simple',
                },
                {
                  label: 'Stagiaire',
                  value: UserRole.Intern,
                  display: 'simple',
                },
              ],
            },
          },
        }}
      />
    </>
  )
}

export default ParamsUsers
