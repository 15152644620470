import React, { FC } from 'react'

import LayoutExhibitionSheet, {
  LayoutExhibitionSheetProps,
} from '../../layouts/LayoutExhibitionSheet'
import { FormikFormProps } from '../../components/form/FormikForm'
import { FormFieldRadioGroupProps } from '../../components/form/fields/FormFieldRadioGroup'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { SampleChartProps } from '../../components/SampleChart'

import * as SC from './styled'

export type ExhibitionSheetForumSelected = {
  forum: string
}

export type ExhibitionSheetSamplesTemplateProps = {
  layoutProps?: LayoutExhibitionSheetProps
  subForumList?: FormFieldRadioGroupProps[]
  formikForm: FormikFormProps<ExhibitionSheetForumSelected>
  isCollapsibleOpen?: boolean
  onCollapsibleCLick?: () => void
  allForum?: FormFieldRadioGroupProps[]
  collapsibleTitle?: string
  collapsibleSecondTitle?: string
  forum?: {
    color?: string
    backgroundColor?: string
  }
  samplesRecepted?: {
    text: string
    nbr: string
  }
  samplesPercentSelected?: {
    text: string
    nbr: string
  }
  samplesSelected?: {
    text: string
    nbr: string
  }
  samplesSelectedWithPhoto?: {
    text: string
    nbr: string
  }
  samplesCompletedData?: {
    text: string
    nbr: string
  }
  samplesOkMarketplace?: {
    text: string
    nbr: string
  }
  samplesErrorImportMarketplace?: {
    text: string
    nbr: string
  }
  statisticsTop?: {
    title?: string
    subtitle?: string
    chart?: SampleChartProps
  }
  statisticsBottom?: {
    chart?: SampleChartProps
  }
}

const ExhibitionSheetSamplesTemplate: FC<ExhibitionSheetSamplesTemplateProps> = ({
  layoutProps,
  subForumList,
  formikForm,
  samplesRecepted,
  samplesPercentSelected,
  samplesSelected,
  samplesSelectedWithPhoto,
  samplesCompletedData,
  samplesOkMarketplace,
  samplesErrorImportMarketplace,
  statisticsTop,
  statisticsBottom,
  isCollapsibleOpen,
  onCollapsibleCLick,
  allForum,
  collapsibleTitle,
  collapsibleSecondTitle,
  forum,
}) => {
  return (
    <LayoutExhibitionSheet {...layoutProps}>
      <SC.StyledContentWrapper>
        <SC.ResumeSubForum>
          <SC.Form {...formikForm}>
            <SC.CollapsibleTop
              title={collapsibleTitle ? collapsibleTitle : ''}
              secondTitle={collapsibleSecondTitle ? collapsibleSecondTitle : undefined}
              variant="concreteBg"
              noBorder
              isCollapsibleOpen={isCollapsibleOpen}
              onClick={onCollapsibleCLick}
              {...forum}
            >
              {subForumList &&
                subForumList.map((item, key) => (
                  <SC.Collapsible
                    title={item.value as string}
                    color={item.color}
                    backgroundColor={getContrastColor(item.color as string)}
                    key={key}
                    noBorder
                  >
                    <SC.StyledFormikField Component={SC.StyledRadiosGroup} {...item} />
                  </SC.Collapsible>
                ))}
              {allForum && (
                <SC.StyledFormikField Component={SC.StyledRadiosGroup} {...allForum[0]} />
              )}
            </SC.CollapsibleTop>
          </SC.Form>
        </SC.ResumeSubForum>
        <SC.InfosContainer>
          {samplesRecepted && (
            <SC.Infos>
              <SC.ExhibitorNbr>{samplesRecepted.nbr}</SC.ExhibitorNbr>{' '}
              <SC.Text>{samplesRecepted.text}</SC.Text>
            </SC.Infos>
          )}
          {samplesPercentSelected && (
            <SC.Infos>
              <SC.ExhibitorNbr>{samplesPercentSelected.nbr}</SC.ExhibitorNbr>{' '}
              <SC.Text>{samplesPercentSelected.text}</SC.Text>
            </SC.Infos>
          )}

          {samplesSelected && (
            <SC.Infos>
              <SC.ExhibitorNbr>{samplesSelected.nbr}</SC.ExhibitorNbr>{' '}
              <SC.Text>{samplesSelected.text}</SC.Text>
            </SC.Infos>
          )}
          {samplesCompletedData && (
            <SC.Infos>
              <SC.ExhibitorNbr>{samplesCompletedData.nbr}</SC.ExhibitorNbr>{' '}
              <SC.Text>{samplesCompletedData.text}</SC.Text>
            </SC.Infos>
          )}
          {samplesSelectedWithPhoto && (
            <SC.Infos>
              <SC.ExhibitorNbr>{samplesSelectedWithPhoto.nbr}</SC.ExhibitorNbr>{' '}
              <SC.Text>{samplesSelectedWithPhoto.text}</SC.Text>
            </SC.Infos>
          )}
          {samplesOkMarketplace && (
            <SC.Infos>
              <SC.ExhibitorNbr>{samplesOkMarketplace.nbr}</SC.ExhibitorNbr>{' '}
              <SC.Text>{samplesOkMarketplace.text}</SC.Text>
            </SC.Infos>
          )}
          {samplesErrorImportMarketplace && (
            <SC.Infos>
              <SC.ExhibitorNbr>{samplesErrorImportMarketplace.nbr}</SC.ExhibitorNbr>{' '}
              <SC.Text>{samplesErrorImportMarketplace.text}</SC.Text>
            </SC.Infos>
          )}
        </SC.InfosContainer>
        {statisticsTop && (
          <SC.Section>
            {statisticsTop.title && <SC.Title tag="h2" text={statisticsTop.title} />}
            {statisticsTop.chart && <SC.Chart {...statisticsTop.chart} />}
          </SC.Section>
        )}
        {statisticsBottom && (
          <SC.Section>
            {statisticsBottom.chart && <SC.Chart {...statisticsBottom.chart} dataBefore />}
          </SC.Section>
        )}
      </SC.StyledContentWrapper>
    </LayoutExhibitionSheet>
  )
}

export default ExhibitionSheetSamplesTemplate
