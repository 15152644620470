import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { filtersMocks } from '../../components/Filters/mocks'
import { SimpleCardProps } from '../../components/SimpleCard'
import { filtersTagsMocks } from '../../components/FiltersTags/mocks'
import { Exhibitor } from '../../graphql/generated/graphql'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { FilterTagProps } from '../../components/FilterTag'

import { ExponentSearchTemplateProps } from '.'

export const useExponentSearchProps = ({
  selectedTab,
  handleTabClick,
  handleFiltersClick,
  parsedExhibitors,
  tags,
  search,
  handleSearchChange,
  setParsedExhibitors,
  exhibitors,
  currentPage,
  allExhibitors,
  setAllExhibitors,
}: {
  selectedTab: boolean
  handleTabClick: (bool: boolean) => void
  handleFiltersClick: () => void
  parsedExhibitors: SimpleCardProps[] | undefined
  tags: Array<FilterTagProps>
  search: string
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void
  setParsedExhibitors: React.Dispatch<React.SetStateAction<SimpleCardProps[] | undefined>>
  exhibitors: any
  currentPage: number
  allExhibitors: Exhibitor[]
  setAllExhibitors: React.Dispatch<React.SetStateAction<Exhibitor[]>>
}): ExponentSearchTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { redirect } = useParams<{ redirect: string }>()
  const [pending, setPending] = useState<boolean>(true)
  const config = useSelector(selectors.configuration.configurationInfo)
  const layoutProps = useDefaultLayoutTemplateProps(
    redirect === 'redirect'
      ? 'parcels'
      : redirect === 'samples'
      ? 'samples'
      : redirect === 'search'
      ? 'exponents'
      : undefined,
    true
  )
  // Parse exhibitors list
  useEffect(() => {
    const parseExhibitorsCards = (exhibitors: Exhibitor[] | undefined, appendCards = false) => {
      const newObj: SimpleCardProps[] = []
      exhibitors?.forEach((item: Exhibitor) => {
        const card: SimpleCardProps = {
          topInfo: {
            tag: item.name,
            name: item.country ? item.country : '',
            universe: item.universe.name,
            pavilion: item.pavilion.name,
          },
          onClick: () => {
            dispatch(actions.exhibitors.setExhibitor(item))
            if (redirect === 'redirect') {
              const link = router(routesPath.exponentSheetParcels, {
                exposantId: item.ebmsId,
                apiId: config?.exhibitionActiveApiId.toString(),
              })
              navigate(link)
            } else if (!redirect) {
              const link = router(routesPath.exponentSheet, {
                exposantId: item.ebmsId,
                apiId: config?.exhibitionActiveApiId.toString(),
              })
              navigate(link)
            }
          },
        }
        newObj.push(card)
      })

      if (appendCards) {
        setParsedExhibitors((prevCards) => {
          const uniqueCards = newObj.filter((newCard) =>
            prevCards?.every(
              (prevCard) =>
                prevCard.topInfo?.tag !== undefined &&
                newCard.topInfo?.tag !== undefined &&
                prevCard.topInfo.tag !== newCard.topInfo.tag
            )
          )
          return prevCards ? [...prevCards, ...uniqueCards] : uniqueCards
        })
      } else {
        setParsedExhibitors(newObj)
      }
    }

    if (exhibitors && currentPage > 1) {
      parseExhibitorsCards(exhibitors?.data, true)
      setPending(false)
    } else if (exhibitors) {
      parseExhibitorsCards(exhibitors?.data, false)
    }
  }, [
    exhibitors,
    currentPage,
    setParsedExhibitors,
    dispatch,
    redirect,
    navigate,
    config?.exhibitionActiveApiId,
  ])

  useEffect(() => {
    const newExhibitors = exhibitors?.data || []
    const existingKeys = allExhibitors.map((exhibitor) => exhibitor.name)

    newExhibitors.forEach((exhibitor: Exhibitor) => {
      if (!existingKeys.includes(exhibitor.name)) {
        setAllExhibitors((prevExhibitors) => [...prevExhibitors, exhibitor])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitors])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {},
      },
      title: 'Recherche',
      tabs: {
        tabs: [
          {
            name: 'Echantillons',
            selected: selectedTab,
            onClick: () => handleTabClick(true),
          },
          {
            name: 'Exposants',
            selected: !selectedTab,
            onClick: () => handleTabClick(false),
          },
        ],
      },
      backButtonProps: {
        open: false,
        onClick: handleFiltersClick,
      },
      cards: {
        cards: parsedExhibitors,
      },
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      pending: pending,
      filtersTags: {
        text: 'Filtrer',
        filters: { ...filtersMocks.basic, isOpen: false },
        tags: tags.map((tag) => ({ text: tag.text, category: tag.category })),
        button: { ...filtersTagsMocks.basic.button, onClick: handleFiltersClick },
      },
    }),
    [
      handleFiltersClick,
      handleSearchChange,
      handleTabClick,
      layoutProps,
      parsedExhibitors,
      pending,
      search,
      selectedTab,
      tags,
    ]
  )
}
