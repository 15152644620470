import styled from 'styled-components'

import breakpoints from '../../theme/breakpoints'
import SimpleCard from '../SimpleCard'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  ${breakpoints.up('large')} {
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    justify-items: stretch;
  }
`

export const Card = styled(SimpleCard)`
  width: 100%;
  max-width: none;
  ${breakpoints.up('tablet')} {
    &:only-child {
      grid-column-end: span 2;
    }
  }
`
