import React, { FC, useCallback, useState } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { SearchBarProps } from '../../components/SearchBar'
import { PVColors } from '../../types/pv-colors'
import { Icons } from '../../components/Icon'
import { ForumBox, ForumBoxItem } from '../../graphql/generated/graphql'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import Dot from '../../components/Dot'
import { colors } from '../../theme'

import * as SC from './styled'

export type ForumProps = {
  name: string
  color?: PVColors
  number?: string
  subForumBoxes?: ForumBoxItem[]
}

export type BoxesListTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title?: string
  subtitle?: string
  text?: string
  forums?: ForumBox[] | null
  searchBar?: SearchBarProps
}

const BoxesListTemplate: FC<BoxesListTemplateProps> = ({
  layoutProps,
  title,
  subtitle,
  text,
  forums,
  searchBar,
}) => {
  const [isOpen, setIsOpen] = useState<number[]>([])

  const handleToggle = useCallback(
    (i: number, open: boolean) => {
      if (open && !isOpen.includes(i)) {
        setIsOpen([...isOpen, i])
      } else if (!open && isOpen.includes(i)) {
        setIsOpen([
          ...isOpen.filter((nbr) => {
            return nbr !== i
          }),
        ])
      }
    },
    [isOpen]
  )

  return (
    <LayoutWithBottomActions {...layoutProps}>
      <SC.StyledContentWrapper maxWidth="default">
        {title && <SC.Title tag={'h2'} text={title} />}
        {searchBar && <SC.Search {...searchBar} />}
        {subtitle && <SC.Subtitle tag={'h3'} text={subtitle} />}
        {text && <SC.Text>{text}</SC.Text>}
        {forums && forums.length > 0 ? (
          <SC.Forums>
            {forums.map((forum, index) => {
              return (
                <SC.Forum key={index}>
                  <SC.StyledCollapsible
                    onTriggerOpening={() => handleToggle(index, true)}
                    onTriggerClosing={() => handleToggle(index, false)}
                    trigger={
                      <SC.Trigger
                        $color={forum.color !== '#cccccc' ? forum.color : colors.tundora}
                        $backgroundColor={
                          forum.color !== '#cccccc'
                            ? getContrastColor(forum.color as string)
                            : colors.concrete
                        }
                      >
                        <SC.Label>
                          <Dot />
                          {forum.name}
                        </SC.Label>
                        <SC.TriggerIcon
                          icon={isOpen.includes(index) ? Icons.caretTop : Icons.caretDown}
                          width={12}
                          height={6}
                        />
                      </SC.Trigger>
                    }
                    easing={'ease'}
                  >
                    {forum.subForumBoxes && forum.subForumBoxes?.length > 0 && (
                      <SC.List>
                        {forum.subForumBoxes.map((subforum, i) => (
                          <SC.Item
                            key={i}
                            $color={forum.color !== '#cccccc' ? forum.color : colors.tundora}
                            $backgroundColor={
                              forum.color !== '#cccccc'
                                ? getContrastColor(forum.color as string)
                                : colors.concrete
                            }
                          >
                            {subforum?.forumId === null && (
                              <SC.Label>{subforum && subforum.id}#</SC.Label>
                            )}
                            <Dot />
                            <SC.Label>{subforum && subforum.name}</SC.Label>
                          </SC.Item>
                        ))}
                      </SC.List>
                    )}
                  </SC.StyledCollapsible>
                </SC.Forum>
              )
            })}
          </SC.Forums>
        ) : (
          <SC.Error>Pas de données</SC.Error>
        )}
      </SC.StyledContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default BoxesListTemplate
