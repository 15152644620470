import styled from 'styled-components'

import FilterTag from '../FilterTag'
import ModalFullScreen from '../ModalFullScreen'
import FilterButton from '../FilterButton'
import { breakpoints, fonts, textStyles } from '../../theme'
import FiltersExportForm from '../forms/FiltersExportForm'
import Heading from '../Heading'
import FormFieldCheckbox from '../form/fields/FormFieldCheckbox'
import ActionButton from '../ActionButton'
import FormikForm from '../form/FormikForm'
import FormFieldRadioGroup from '../form/fields/FormFieldRadioGroup'
import FormSubmitButton from '../form/FormSubmitButton'

export const Container = styled(ModalFullScreen)`
  cursor: pointer;
`

export const FormUniverse = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  padding: 12px 0 12px 0;
`
export const StyledRadiosGroup = styled(FormFieldRadioGroup)``

export const Radio = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const SubmitUniverseButton = styled(FormSubmitButton)`
  ${fonts.GothamBold}
  align-self: center;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  justify-content: flex-end;
`

export const Content = styled.div`
  padding: 2rem 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  z-index: 10;
  position: relative;
  padding-bottom: 9rem;
  ${breakpoints.up('tablet')} {
    padding: 4rem 2rem;
    padding-bottom: 9rem;
    width: 43%;
    margin-left: auto;
    overflow-y: auto;
    height: 100%;
  }
`

export const Title = styled(Heading)`
  text-transform: capitalize;
  padding-bottom: 12px;
`

export const Header = styled(Heading)`
  padding-bottom: 24px;
`

export const Tags = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
`
export const Tag = styled(FilterTag)``

export const Form = styled(FiltersExportForm)`
  margin-bottom: 24px;
`

export const BackButton = styled(FilterButton)`
  width: fit-content;
  margin-bottom: 18px;
`

export const Infos = styled.div`
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.colors.concrete};
`

export const InfosContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tundora};
`

export const ExhibitorNbr = styled.span`
  ${textStyles.titleH4}
`

export const Text = styled.span`
  padding-left: 12px;
  ${textStyles.titleH5}
`

export const Checkbox = styled(FormFieldCheckbox)`
  padding: 5px 4px;
  background-color: ${({ theme }) => theme.colors.concrete};
  margin-bottom: 24px;
`

export const Button = styled(ActionButton)`
  margin: auto;
`

export const ExportButton = styled(ActionButton)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
`
