import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import { actions, selectors } from '../redux'
import { router, routesPath } from '../router'
import { UserRole } from '../graphql/generated/graphql'
import { mapSampleData } from '../relay/DataSample/MapSampleData'
import { mapSampleDataAllInfo } from '../relay/DataSample/MapSampleDataAllInfo'

interface AuthGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const isConnected = useSelector(selectors.auth.isConnected)
  const isInited = useSelector(selectors.app.isInit)

  const user = useSelector(selectors.auth.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sample = useSelector(selectors.samples.sample)
  const [routeType, setRouteType] = useState<string | undefined>(undefined)
  const lastVisited = useSelector(selectors.auth.setUserLastVisited)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const sampleData = useSelector(selectors.samples.sampleData)
  const config = useSelector(selectors.configuration.configurationInfo)

  useEffect(() => {
    let universeEmbsId = undefined
    if (sample?.universeRelation) {
      const category = config?.dictionaries.find((item) => item.type === 'UNIVERSES')
      universeEmbsId = category?.items.find(
        (item) => item.key === sample.universeRelation?.universeEbmsId
      )?.value
    }
    if (sample?.universe) {
      const universeName = universeEmbsId ? universeEmbsId : sample.universe.name
      if (
        sample?.universe &&
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.InputOperator
      ) {
        setRouteType(universeName)
      }
      if (
        sample &&
        user?.role !== UserRole.Intern &&
        (user?.role === UserRole.Marketplace ||
          user?.role === UserRole.ModePv ||
          user?.role === UserRole.Admin ||
          user?.role === UserRole.SuperAdmin ||
          user?.role === UserRole.FashionExpert)
      ) {
        setRouteType(`${universeName}Marketplace`)
      } else if (
        sample.universe &&
        (user?.role === UserRole.Intern || user?.role === UserRole.InputOperator)
      ) {
        if (universeName === 'Fabrics' || universeName === 'Yarns') {
          setRouteType('Trainee')
        }
        if (universeName === 'Designs') {
          setRouteType('TraineeDesign')
        }
        if (universeName === 'Smart' || universeName === 'Smart Creation') {
          setRouteType('Smart')
        }
        if (universeName === 'Manufacturing' || universeName === 'Manufacturing Leather') {
          setRouteType('TraineeManuf')
        }
        if (universeName === 'Leather' || universeName === 'Accessories') {
          setRouteType('TraineeShort')
        }
      }
    }
  }, [user?.role, sample, config?.dictionaries])

  useEffect(() => {
    if (sample && sample.flagItems && routeType) {
      dispatch(
        actions.samples.setSampleData(
          mapSampleData(sample, undefined, sampleData.page, routeType, user)
        )
      )
      dispatch(actions.samples.setSampleDataAllInfo(mapSampleDataAllInfo(sample)))
    }
  }, [dispatch, routeType, sample, sampleData.page, user])

  useEffect(() => {
    if (
      lastVisited?.includes('/saisie-donnees-echantillon/') ||
      lastVisited?.includes('/leatherChoice') ||
      lastVisited?.includes('/smartCreationChoice')
    ) {
      const sampleId = lastVisited.split('/')[4]
      sampleId && dispatch(actions.samples.getSampleByIdRequest({ sampleId }))
    }
  }, [dispatch, lastVisited, navigate])

  useEffect(() => {
    if (lastVisited?.includes('/saisie-donnees-echantillon/')) {
      sample &&
        sample.universe &&
        dispatch(
          actions.samples.getFlagGroupsByUniverseRequest({
            universeEbmsId: sample.universeRelation
              ? sample.universeRelation.universeEbmsId
              : sample.universe.ebmsId,
          })
        )
      sample &&
        sample.collection &&
        dispatch(
          actions.samples.getSampleFormByUniverseRequest({
            collectionId: sample.collection?.id,
            universeEbmsId: sample.universeRelation
              ? sample.universeRelation.universeEbmsId
              : sample.universe.ebmsId,
          })
        )
    }
  }, [dispatch, lastVisited, navigate, sample])

  useEffect(() => {
    if (
      lastVisited?.includes('/saisie-donnees-echantillon/') &&
      sample &&
      sampleDataAllInfo.routeType
    ) {
      navigate(lastVisited)
      dispatch(actions.auth.setUserLastVisited(null))
    }
  }, [dispatch, lastVisited, navigate, sample, sampleDataAllInfo.routeType])

  if (!isInited) {
    return
  }
  if (!isConnected) {
    return <Navigate to={router(routesPath.login)} />
  }

  return children
}

export default AuthGuard
