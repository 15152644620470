import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import i18next from 'i18next'
import { useNavigate, useParams } from 'react-router'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import {
  ExhibitionForum,
  FlagGroup,
  FlagItem,
  SampleStatus,
  UserRole,
} from '../../graphql/generated/graphql'
import { mapSampleData } from '../../relay/DataSample/MapSampleData'
import { router, routesPath } from '../../router'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import { mapSampleDataAllInfo } from '../../relay/DataSample/MapSampleDataAllInfo'
import { PerformanceCodeType } from '../../types/performanceCode'
import { useSampleSheetTabsProps } from '../../relay/SampleSheet/useSampleSheetTabsProps'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutSampleSheetTemplateProps } from '../../layouts/LayoutSampleSheet/useProps'

import { SampleSheetDataTemplateProps } from '.'

export const useSampleSheetDataTemplateProps = (): SampleSheetDataTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sample = useSelector(selectors.samples.sample)
  const sampleData = useSelector(selectors.samples.sampleData)
  const user = useSelector(selectors.auth.user)
  const config = useSelector(selectors.configuration.configurationInfo)
  const { sampleId } = useParams<{ sampleId: string }>()
  const flagGroupsByUniverse = useSelector(selectors.samples.flagGroupsByUniverse)
  const [status, setStatus] = useState<SampleStatus | undefined>(undefined)
  const [forum, setForum] = useState<ExhibitionForum | undefined>(undefined)
  const [subForum, setSubForum] = useState<ExhibitionForum | undefined>(undefined)
  const [mkpForum, setMkpForum] = useState<FlagItem | undefined>(undefined)
  const [category, setCategory] = useState<FlagItem | undefined>(undefined)
  const [performanceCode, setPerformanceCode] = useState<FlagItem[] | undefined>(undefined)
  const [flagGroup, setFlagGroup] = useState<{ title: string; text: string }[]>([])
  const [pictureModal, setPictureModal] = useState<boolean>(false)
  const [routeType, setRouteType] = useState<string | undefined>(undefined)
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const tabs = useSampleSheetTabsProps(sampleId, 'data', user?.role)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const sampleById = useSelector(selectors.samples.getSampleById)

  const sampleLayoutProps = useLayoutSampleSheetTemplateProps(
    status,
    forum,
    subForum,
    mkpForum,
    sample,
    category
  )
  const isArchived = sample?.isArchived

  useEffect(() => {
    if (sample) {
      sample.status && setStatus(sample.status)
      sample.forum && setForum(sample.forum)
      sample.subForum && setSubForum(sample.subForum)
      if (sample.flagItems) {
        setMkpForum(
          sample &&
            sample.flagItems &&
            sample.flagItems.find(
              (item) => item.group && item.group.miraklRef === 'NON_MARKETPLACE_forum'
            )
        )
        setCategory(
          sample &&
            sample.flagItems &&
            sample.flagItems.find((item) => item.group && item.group.miraklRef === 'category')
        )
        setPerformanceCode(
          sample &&
            sample.flagItems &&
            sample.flagItems.filter(
              (item) => item.group && item.group.miraklRef === 'performance_code'
            )
        )
      }
    }
  }, [sample])

  useEffect(() => {
    const newArr: FlagGroup[] = []
    const uniqueGroupIds: Set<string> = new Set()

    if (flagGroupsByUniverse && sample?.flagItems) {
      sample.flagItems.forEach((item) => {
        const groupId = item.group?.miraklRef
        if (
          groupId &&
          !uniqueGroupIds.has(groupId) &&
          groupId !== 'NON_MARKETPLACE_forum' &&
          groupId !== 'performance_code' &&
          groupId !== 'category' &&
          groupId !== 'NON_MARKETPLACE_shooting'
        ) {
          uniqueGroupIds.add(groupId)
          const group = flagGroupsByUniverse.find((group) => group.miraklRef === groupId)
          if (group) {
            const groupObject: any = {
              id: groupId,
              name: group.name,
              items: [],
            }
            sample.flagItems.forEach((item) => {
              if (item.group?.miraklRef === groupId) {
                groupObject.items.push({
                  id: item.id,
                  name: `${item.name} ${
                    item.pivot && item.pivot.value ? item.pivot.value + '%' : ''
                  }`,
                })
              }
            })

            newArr.push(groupObject as FlagGroup)
          }
        }
      })
    }

    const sampleInfo: any = []
    newArr.forEach((item) => {
      const itemNames = item.items && item.items.map((item) => item.name).join(' - ')
      const infos = { title: item.name, text: itemNames }
      sampleInfo.push(infos)
    })
    const getOrderIndex = (title: string) => {
      const orderReference = flagGroupsByUniverse?.map((category) => category.name) || []
      const index = orderReference.indexOf(title)
      return index === -1 ? orderReference.length : index // Maintenant garantit de retourner un nombre
    }

    const sortedItems = sampleInfo.sort((a: { title: string }, b: { title: string }) => {
      return getOrderIndex(a.title) - getOrderIndex(b.title)
    })

    setFlagGroup(sortedItems)
  }, [flagGroupsByUniverse, sample])

  useEffect(() => {
    if (sample?.universe) {
      if (
        sample?.universe &&
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.InputOperator
      ) {
        setRouteType(sample?.universe.name)
      }
      if (
        sample &&
        user?.role !== UserRole.Intern &&
        (user?.role === UserRole.Marketplace ||
          user?.role === UserRole.ModePv ||
          user?.role === UserRole.Admin ||
          user?.role === UserRole.SuperAdmin ||
          user?.role === UserRole.FashionExpert)
      ) {
        setRouteType(`${sample.universe.name}Marketplace`)
      } else if (
        sample.universe &&
        (user?.role === UserRole.Intern || user?.role === UserRole.InputOperator)
      ) {
        if (sample.universe.name === 'Fabrics' || sample.universe.name === 'Yarns') {
          setRouteType('Trainee')
        }
        if (sample.universe.name === 'Designs') {
          setRouteType('TraineeDesign')
        }
        if (sample.universe.name === 'Smart' || sample.universe.name === 'Smart Creation') {
          setRouteType('Smart')
        }
        if (
          sample.universe.name === 'Manufacturing' ||
          sample.universe.name === 'Manufacturing Leather'
        ) {
          setRouteType('TraineeManuf')
        }
        if (sample.universe.name === 'Leather' || sample.universe.name === 'Accessories') {
          setRouteType('TraineeShort')
        }
      }
    }
  }, [user?.role, sample, sampleData])

  useEffect(() => {
    if (sample && sample.flagItems && routeType) {
      dispatch(
        actions.samples.setSampleData(mapSampleData(sample, 'sample', 'sample', routeType, user))
      )
      dispatch(actions.samples.setSampleDataAllInfo(mapSampleDataAllInfo(sample)))
    }
  }, [dispatch, routeType, sample, user])

  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, dispatch, sample])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...sampleLayoutProps,
        bottomActions: {
          ...sampleLayoutProps.bottomActions,
          back: undefined,
          next:
            !isArchived && user?.role !== UserRole.Shooting && user?.role !== UserRole.Rex
              ? {
                  text: 'Éditer la fiche',
                  rightIconProps: {
                    icon: Icons.pencil,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => {
                    if (
                      sample?.collection &&
                      sample?.collection.exhibitor &&
                      sample.universe &&
                      user?.role !== 'INPUT_OPERATOR' &&
                      (sample?.universe.name === 'Smart' ||
                        sample?.universe.name === 'Smart Creation')
                    ) {
                      const link = router(routesPath.exponentSampleDataSmartCreationChoice, {
                        exposantId: sample.collection.exhibitor.ebmsId,
                        sampleId: sample.id,
                      })
                      navigate(link)
                    } else if (
                      sample?.collection &&
                      sample?.collection.exhibitor &&
                      sample.universe &&
                      sample?.universe.name === 'Leather' &&
                      user?.role !== 'INTERN' &&
                      user?.role !== 'INPUT_OPERATOR'
                    ) {
                      const link = router(routesPath.exponentSampleDataLeatherChoice, {
                        exposantId: sample.collection.exhibitor.ebmsId,
                        sampleId: sample.id,
                      })
                      navigate(link)
                    } else {
                      if (sample?.collection && sample?.collection.exhibitor) {
                        const link = router(sampleDataRoutes(sampleData, 'next'), {
                          exposantId: sample.collection.exhibitor.ebmsId,
                          sampleId: sample.id,
                        })
                        navigate(link)
                      }
                    }
                  },
                }
              : undefined,
        },
        tabs: tabs,
      },
      pending: sampleById.pending,
      sampleInfos: {
        infos: flagGroup && flagGroup,
      },
      marketplaceWarning:
        marketplaceDateDaysLeft && marketplaceDateHoursLeft
          ? {
              number: `J - ${marketplaceDateDaysLeft}`,
              text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
              redBg: true,
            }
          : undefined,
      statistics:
        sample?.collection?.parcels &&
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.FashionExpert &&
        user?.role !== UserRole.InputOperator &&
        user?.role !== UserRole.Shooting
          ? {
              title: i18next.t('sample.data.statistics.title'),
              chart: {
                data: [
                  {
                    title: i18next.t('exponent.collection.statisticsSelected'),
                    number: sample.collection.samples.length,
                    color: '#fff5d9',
                  },
                  {
                    title: i18next.t('exponent.collection.statisticsRecepted'),
                    number: sample.collection.parcels.reduce(
                      (sum, item) => sum + item.samplesTotal,
                      0
                    ),
                    color: '#e0e0e0',
                  },
                ],
                label: [
                  `${sample.collection.parcels.reduce(
                    (sum, item) => sum + item.samplesTotal,
                    0
                  )} échantillons`,
                  'réceptionnés',
                ],
              },
            }
          : undefined,
      performanceTitle: 'Codes performances',
      performanceCode:
        performanceCode &&
        performanceCode.map((item) => ({
          text: item.name,
          type: item.name as PerformanceCodeType,
        })),

      // display: sample?.labels
      //   ? {
      //       text: 'afficher l’étiquette exposant',
      //       onClick: () => setPictureModal(true),
      //     }
      //   : undefined,

      image:
        sample?.labels &&
        sample.labels.map((item) => ({
          src: item.url,
          alt: item.path,
        })),
      modal: {
        isOpen: pictureModal,
        onClose: () => setPictureModal(false),
      },
    }),
    [
      flagGroup,
      isArchived,
      layoutProps,
      marketplaceDateDaysLeft,
      marketplaceDateHoursLeft,
      navigate,
      performanceCode,
      pictureModal,
      sample?.collection,
      sample?.id,
      sample?.labels,
      sample?.universe,
      sampleById.pending,
      sampleData,
      sampleLayoutProps,
      tabs,
      user?.role,
    ]
  )
}
