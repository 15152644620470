import React, { FC } from 'react'

import ExponentParcels, { ExponentParcelsProps } from '../../components/ExponentParcels'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import LayoutExponentSheet, { LayoutExponentSheetProps } from '../../layouts/LayoutExponentSheet'

import * as SC from './styled'

export type ExponentSheetParcelsTemplateProps = {
  layoutProps?: LayoutExponentSheetProps
  pending?: boolean
  parcels?: ExponentParcelsProps
  deleteModal?: ModalDeleteProps
}

const ExponentSheetParcelsTemplate: FC<ExponentSheetParcelsTemplateProps> = ({
  layoutProps,
  pending,
  parcels,
  deleteModal,
}) => {
  return (
    <LayoutExponentSheet {...layoutProps} modals={deleteModal && <ModalDelete {...deleteModal} />}>
      {pending ? <SC.Loader /> : <>{parcels ? <ExponentParcels {...parcels} /> : null}</>}
    </LayoutExponentSheet>
  )
}

export default ExponentSheetParcelsTemplate
