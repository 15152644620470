import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { FormFieldInputNumberProps } from '../../form/fields/FormFieldInputNumber'

import * as SC from './styled'

export type SampleSelectFormValues = {
  number: number | string
}

export type SampleSelectFormProps = {
  className?: string
  title?: string
  formikForm: FormikFormProps<SampleSelectFormValues>
  field: FormFieldInputNumberProps
  errors?: FormErrorsProps['errors']
}

const SampleSelectForm: FC<SampleSelectFormProps> = ({
  className,
  title,
  formikForm,
  field,
  errors,
}) => {
  return field ? (
    <SC.Container className={className}>
      {title && <SC.Title tag="h2" text={title} />}
      <SC.Form {...formikForm}>
        <SC.Fieldset>
          <FormikField hasFloatingLabel={false} Component={SC.StyledField} {...field} />
        </SC.Fieldset>
        {errors && <SC.Errors errors={errors} />}
      </SC.Form>
    </SC.Container>
  ) : null
}

export default SampleSelectForm
