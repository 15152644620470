import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Paragraph from '../Paragraph'

export type TagVariant = 'new' | 'return'

export const Tag = styled.div<{ $variant: TagVariant }>`
  display: inline-flex;
  justify-content: flex-start;
  gap: 1.2rem;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  width: 100%;
  ${({ $variant, theme }) =>
    $variant === 'new' &&
    css`
      color: ${theme.colors.eletricViolet};
      background-color: ${theme.colors.blueChalk};
    `};

  ${({ $variant, theme }) =>
    $variant === 'return' &&
    css`
      color: ${theme.colors.eletricViolet};
      background-color: ${theme.colors.blueChalk};
    `};
`
export const NewIcon = styled(Icon)``
export const ReturnIcon = styled(Icon)``

export const Text = styled(Paragraph)`
  margin-bottom: 0;
  color: currentColor;
`
