import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitionUpdate = getMutationService({
  mutation: gql(` 
    mutation exhibitionUpdate($exhibitionApiId: ID!, $input: ExhibitionUpsertInput!, $forums: [ExhibitionUpdateForumsInput!]!) {
      exhibitionUpdate(exhibitionApiId: $exhibitionApiId, input: $input, forums: $forums) {
        id
        apiId
        name
        seasonCode
        seasonYear
        dateStart
        dateEnd
        dateSampleStart
        dateSampleEnd
        isActive
        forums {
          id
          name
          color
          subForums {
            id
            name
          }
        }
        boxes {
          id
          name
        }
        createdAt
        updatedAt
      }
    }

    `),
  transformer: (response) => {
    return response
  },
})
