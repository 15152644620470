import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { router } from '../../router'
import { actions, selectors } from '../../redux'
import { returnSampleDataSteps } from '../../relay/DataSample/SampleDataSteps'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import { fillSummary } from '../../relay/DataSample/SummarysSampleData'
import { SummaryProps } from '../../components/Summary'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { SampleStatus, UserRole } from '../../graphql/generated/graphql'
import { ModalVariant } from '../../components/Modal/types'

import { SampleDataSummaryTemplateProps } from '.'

export const useSampleDataSummaryTemplateProps = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
): SampleDataSummaryTemplateProps => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()
  const user = useSelector(selectors.auth.user)
  const config = useSelector(selectors.configuration.configurationInfo)
  const collection = useSelector(selectors.exhibitors.collection)
  const sampleData = useSelector(selectors.samples.sampleData)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const sample = useSelector(selectors.samples.sample)

  const content = user && fillSummary(sampleData, sampleDataAllInfo, user)
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)

  const isContentUndefined =
    content &&
    content?.items &&
    content?.items.some((item) => {
      if (
        user?.role === UserRole.Admin ||
        user?.role === UserRole.SuperAdmin ||
        user?.role === UserRole.ModePv ||
        user?.role === UserRole.FashionExpert
      ) {
        if (item.title === 'Couleur') {
          return false
        }
      }
      return item.content && item.content[0].text === ''
    })

  // UPDATE IS NOW ON EACH STEP

  // const setFlagsItems = (data: any) => {
  //   const flagGroups: SampleFlagGroupInput[] = []
  //   if (data) {
  //     for (const key in data) {
  //       if (data[key] && data[key].items) {
  //         if (Array.isArray(data[key].items)) {
  //           const items = data[key]
  //           flagGroups.push({
  //             groupId: data[key].id,
  //             applyToCollection: data[key].applyToAllCollection,
  //             flagItems: items.items.map((flagItem: any) => ({
  //               itemId: flagItem.id,
  //               value: flagItem.value ? flagItem.value : null,
  //             })),
  //           })
  //         }
  //       }
  //     }
  //     return flagGroups
  //   }
  // }

  // const updateSample = useCallback(() => {
  //   if (sampleId) {
  //     dispatch(
  //       actions.samples.sampleUpdateRequest({
  //         sampleId: sampleId,
  //         input: {
  //           flagGroups: setFlagsItems(sampleDataAllInfo),
  //           boxId: sampleDataAllInfo.boxe?.items?.id,
  //           forumId: sampleDataAllInfo.forum.id,
  //           forumApplyToCollection: sampleDataAllInfo.forum.applyToAllCollection,
  //           subForumId: sampleDataAllInfo.subForum.id,
  //           subForumApplyToCollection: sampleDataAllInfo.subForum.applyToAllCollection,
  //           name: sampleDataAllInfo.exhibitorRef,
  //         },
  //       })
  //     )
  //     if (sampleDataAllInfo.label) {
  //       sampleDataAllInfo.label.forEach((item) => {
  //         const imageData = item
  //         const isNew = imageData.url.startsWith('data:image') ? true : false
  //         if (isNew) {
  //           const decodedData = window.atob(imageData.url.split(',')[1])
  //           const arrayBuffer = new ArrayBuffer(decodedData.length)
  //           const uint8Array = new Uint8Array(arrayBuffer)
  //           for (let i = 0; i < decodedData.length; i++) {
  //             uint8Array[i] = decodedData.charCodeAt(i)
  //           }
  //           const blob = new Blob([arrayBuffer], { type: 'image/jpeg' })
  //           const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
  //           dispatch(
  //             actions.configuration.uploadRequest({
  //               sampleId: sampleId,
  //               file,
  //               sampleLabelId: imageData.id !== '' ? imageData.id : undefined,
  //             })
  //           )
  //         }
  //       })
  //     }
  //   }
  // }, [sampleId, dispatch, sampleDataAllInfo])

  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, dispatch, sample])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        title: `${sampleData.exhibitorRef} #${sampleId}`,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(sampleDataRoutes(sampleData, 'prev'), {
                exposantId,
                sampleId,
              })
              navigate(link)
            },
          },
          next: {
            text: 'Valider',
            disabled: !!isContentUndefined,
            onClick: () => {
              setModalOpen(true)
              sampleId && dispatch(actions.samples.getSamplePdfRequest({ sampleId: sampleId }))
            },
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
          },
          note:
            collection?.internalNotes && collection?.internalNotes.length !== 0
              ? {
                  rounded: true,
                  number: collection?.internalNotes
                    ? collection?.internalNotes.length.toString()
                    : '0',
                  onClick: () => {
                    setIsNoteOpen(true)
                  },
                }
              : undefined,
        },
        steps: {
          steps: returnSampleDataSteps(sampleData, exposantId, sampleId),
        },
        notes: {
          title: "Note interne sur l'exposant",
          notes:
            collection && collection.internalNotes && collection.internalNotes.length !== 0
              ? collection.internalNotes.map((note) => ({
                  text: note.body,
                  author: {
                    fullName:
                      collection?.internalNotes.length !== 0
                        ? `par ${note.user.lastName} ${note.user.firstName}`
                        : '',
                    date:
                      collection?.internalNotes.length !== 0
                        ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                        : '',
                  },
                }))
              : [],
          tags: {
            newTag: collection?.exhibitor?.isNew,
            returnTag: collection?.samplesToReturn !== 0,
          },
          onClose: () => setIsNoteOpen(false),
        },
        noteModal: {
          onClose: () => setIsNoteOpen(false),
          isOpen: isNoteOpen,
          variant: ModalVariant.Small,
        },
        marketplaceWarning:
          marketplaceDateDaysLeft && marketplaceDateHoursLeft
            ? {
                number: `J - ${marketplaceDateDaysLeft}`,
                text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
                redBg: true,
              }
            : undefined,
      },
      title: 'Vérifiez et confirmez les informations',
      summary: content as SummaryProps,
      // error: sampleUpdate && sampleUpdate.errors ? sampleUpdate.errors[0].label : undefined,
    }),
    [
      collection,
      content,
      dispatch,
      exposantId,
      isContentUndefined,
      isNoteOpen,
      layoutProps,
      marketplaceDateDaysLeft,
      marketplaceDateHoursLeft,
      navigate,
      sampleData,
      sampleId,
      setModalOpen,
    ]
  )
}
