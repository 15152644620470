import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const authByToken = getQueryService({
  query: gql(`
      query authByToken {
      authByToken {
        id
        type
        role
        firstName
        lastName
        email
        lastLoginAt
        externalAccessFrom
        externalAccessTo
        createdAt
        updatedAt
        type
        lastVisited
      }
    }
  `),
  transformer: (response) => {
    return response
  },
})
