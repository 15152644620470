import styled, { css } from 'styled-components'

import { breakpoints } from '../../theme'

export const Container = styled.div`
  position: relative;
`
export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  ${breakpoints.up('tablet')} {
    display: none;
  }
`

export const SvgTablet = styled.svg`
  display: none;
  ${breakpoints.up('tablet')} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

export const SvgWrapper = styled.div`
  height: 100px;
  width: 100px;
`

export const Text = styled.p<{ $pathHeight: number }>`
  ${({ theme }) => theme.textStyles.titleH4};
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  top: 5%;
  /* ${({ $pathHeight }) =>
    $pathHeight &&
    css`
      bottom: calc((50% + 1.5rem) + (${$pathHeight}px / 2));
    `}; */
  left: 0;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
`
