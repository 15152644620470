import styled from 'styled-components'

import Icon from '../Icon'
import FormFieldSwitch from '../form/fields/FormFieldSwitch'
import { breakpoints } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
`
export const List = styled.dl`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  margin: 0;
`
export const SliderContainer = styled.div`
  display: block;
  max-width: 350px;
  margin: auto;
  margin-bottom: 3rem;
  width: 100%;
`
export const Item = styled.div<{ $withIcon?: boolean }>`
  display: flex;
  flex-flow: column;
  gap: ${({ $withIcon }) => ($withIcon === true ? '1.2rem' : '0.6rem')};
  &:not(:first-child) {
    padding-top: 1.2rem;
    border-top: 0.1rem solid ${({ theme }) => theme.colors.tundora};
  }
`
export const ItemTitle = styled.dt`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.gray};
`
export const ItemContent = styled.dd<{ $withIcon?: boolean }>`
  margin: 0;
  display: flex;
  align-items: center;
  gap: ${({ $withIcon }) => ($withIcon === true ? '1.2rem' : '0.8rem')};
`
export const ItemIcon = styled(Icon)`
  flex: 0 0 auto;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.3rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.concrete};
`
export const ItemText = styled.span`
  ${({ theme }) => theme.textStyles.titleH4}
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const AllCollectionSwitch = styled(FormFieldSwitch)`
  ${({ theme }) => theme.textStyles.textBook}
  pointer-events: none;
`
export const BottomNote = styled.div`
  ${({ theme }) => theme.textStyles.textItalic}
  align-self: flex-end;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  text-align: end;
`

export const Image = styled.img`
  max-width: 70%;
  height: 70%;
  object-fit: contain;
  border-radius: 4px;
  ${breakpoints.up('tablet')} {
    object-fit: contain;
    border-radius: 14px;
  }
`
export const BlockImage = styled.div`
  flex: 1;
`
