import React, { FC } from 'react'

import * as SC from './styled'

export type ParagraphProps = {
  className?: string
  text?: string
  color?: SC.Color
  variant?: SC.Variant
  removeMargins?: boolean
}

const Paragraph: FC<ParagraphProps> = ({
  className,
  text,
  removeMargins,
  variant = 'textBook',
  color = 'mineShaft',
}) => {
  return (
    <SC.Paragraph
      className={className}
      $variant={variant}
      $color={color}
      $removeMargins={removeMargins}
    >
      {text}
    </SC.Paragraph>
  )
}

export default Paragraph
