const colors = {
  pureBlack: '#000',
  pureWhite: '#ffffff',
  red: '#FE576B',
  error: '#FE576B',

  // Charte
  mineShaft: '#232323',
  tundora: '#464646',
  gray: '#8C8C8C',
  silver: '#CCCCCC',
  alabaster: '#F9F9F9',
  concrete: '#F2F2F2',
  grayLight: '#E0E0E0',
  redLight: '#FFE3E3',

  // Univers
  chateauGreen: '#34AB55',
  appleGreen: '#DAEFE0',
  burningOrange: '#FF763C',
  pippin: '#FFE7DD',
  cinnabar: '#E4474A',
  yourPink: '#FFC5C5',
  selectiveYellow: '#FDF5D9',
  selectiveTextYellow: '#A87E0A',
  selectiveTextYellowDark: '#FFBB00',
  eggSour: '#FFF5D9',
  fuchsiaPink: '#BC3DB2',
  amour: '#F5E2F3',
  royalPurple: '#7030A0',
  whiteLilac: '#FCFAFD',
  salem: '#05813B',
  pizza: '#CB990E',
  albescentWhite: '#F7F0DB',
  roseOfSharon: '#BF5000',
  janna: '#F5E5D9',
  danube: '#5D82C9',
  linkWater: '#E7ECF7',

  // Tags
  eletricViolet: '#9747FF',
  blueChalk: '#F4E1FF',
}

export default colors
