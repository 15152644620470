import styled from 'styled-components'

import Icon, { Icons } from '../../../Icon'
import FormInput from '../FormFieldInput'

export const Input = styled(FormInput)``

export const EyeOpen = styled(Icon).attrs({ icon: Icons.eyeOpen })`
  height: 13px;
  color: ${({ theme }) => theme.colors.mineShaft};
`
export const EyeClose = styled(Icon).attrs({ icon: Icons.eyeClosed })`
  height: 16px;
  color: ${({ theme }) => theme.colors.mineShaft};
`

export const Toggler = styled.button`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
`
