import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const editorialsSearch = getQueryService({
  query: gql(`
    query editorialsSearch(
        $exhibitionApiId: ID
        $q: String
        $first: Int!
        $page: Int
    ) {
        editorialsSearch(
            exhibitionApiId: $exhibitionApiId
            q: $q
            first: $first
            page: $page
        ) {
            paginatorInfo {
                total
                perPage
                lastPage
                currentPage
            }
            data {
                id
                name
                description
                samples {
				    isDeleted
                    id
                    name
                    status
                    photos {
                    id
                    user {
                    id
                    email
                    }
                    url
                    path
                    order
                }
                }
            }
        }
    }
`),
  transformer: (response) => {
    return response
  },
})
