import styled from 'styled-components'

import QrScanner from '../../components/QrScanner'
import SampleCard from '../../components/SampleCard'
import { fonts, colors, breakpoints } from '../../theme'
import Dot from '../../components/Dot'
import BoxInfo from '../../components/BoxInfo'
import SimpleLoader from '../../components/SimpleLoader'

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const Loader = styled(SimpleLoader)`
  margin: auto;
  top: 50%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
  margin-bottom: 7rem;
  overflow: auto;
  ${breakpoints.up('tablet')} {
    flex-grow: 0;
    margin-bottom: 0;
    max-height: 100vh;
    padding: 3.2rem 4rem 8rem 4rem;
    width: 40%;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 1;
  ${breakpoints.up('tablet')} {
    width: 100%;
  }
`

export const SubTitle = styled.div`
  ${fonts.GothamBold}
  color: ${colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

export const Text = styled.div`
  ${fonts.RobotoLight}
  color: ${colors.gray};
  font-size: 1.2rem;
  letter-spacing: 1px;
  padding-right: 1rem;
  margin-bottom: 2.2rem;
`

export const ForumSum = styled.div`
  ${fonts.GothamBold}
  font-size: 1.2rem;
  letter-spacing: 1px;
  padding: 0.5rem;
`

export const SubForumSum = styled.div`
  ${fonts.GothamBold}
  font-size: 1rem;
  letter-spacing: 1px;
  padding: 0.5rem;
`

export const StyledDot = styled(Dot)``

export const Card = styled(SampleCard)``

export const Scanner = styled(QrScanner)`
  max-height: 100vh;
  width: 100%;
  ${breakpoints.up('tablet')} {
    overflow: hidden;
    width: 60%;
  }
`
export const StyledBoxInfo = styled(BoxInfo)`
  margin-bottom: 24px;
`
