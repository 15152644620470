import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import FormFieldRadioGroup, {
  FormFieldRadioGroupProps,
} from '../../form/fields/FormFieldRadioGroup'
import FormikField from '../../form/FormikField'
import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import { FieldText } from '../LoginForm/styled'
import { VariantRadioFont, VariantRadioSize } from '../../form/fields/FormFieldRadioGroup/types'

import * as SC from './styled'

export type LoungeSubForumChoiceFormValues = {
  forumSelected: string
  subForumName: string
}

export type LoungeSubForumChoiceFormProps = {
  formikForm: FormikFormProps<LoungeSubForumChoiceFormValues>
  fields: {
    forumSelected: FormFieldRadioGroupProps
    subForumName: FormFieldInputProps
  }
}

const LoungeSubForumChoiceForm: FC<LoungeSubForumChoiceFormProps> = ({ formikForm, fields }) => {
  return (
    <SC.StyledFormikForm {...formikForm}>
      <SC.Collapsible
        title={'Sélectionner le forum associé'}
        variant="concreteBg"
        noBorder
        isCollapsibleOpen={true}
      >
        <SC.StyledFormikField
          Component={FormFieldRadioGroup}
          {...fields.forumSelected}
          optionsSize={VariantRadioSize.big}
          optionsFont={VariantRadioFont.SmallBold}
        />
      </SC.Collapsible>
      <FormikField {...fields.subForumName} Component={FieldText} />
    </SC.StyledFormikForm>
  )
}

export default LoungeSubForumChoiceForm
