import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleDeleteImageLabel = getMutationService({
  mutation: gql(`
    mutation sampleLabelDelete($sampleLabelId: ID!) {
        sampleLabelDelete(sampleLabelId: $sampleLabelId) {
            id
            name
            labels {
                id
                user {
                    id
                    email
                }
                url
                path
                order
            }
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
