import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { layoutSampleDataMocks } from '../../layouts/LayoutSampleData/mocks'
import { VariantRadioFont } from '../../components/form/fields/FormFieldRadioGroup/types'
import { FormFieldSwitchSize } from '../../components/form/fields/FormFieldSwitch/types'
import { router } from '../../router'
import { actions, selectors } from '../../redux'
import { returnSampleDataSteps } from '../../relay/DataSample/SampleDataSteps'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import {
  ForumBox,
  ForumBoxItem,
  SampleFlagGroupInput,
  SampleFormGroup,
  SampleStatus,
} from '../../graphql/generated/graphql'
import { VariantCollapsibleColoredFont } from '../../components/CollapsibleColored/types'
import { SampleDataAllInfo } from '../../types/sampleDataAllInfo'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { ModalVariant } from '../../components/Modal/types'
import { getContrastColor } from '../../relay/Forums/getContrastColor'

import { SampleDataFormValues, SampleDataTemplateProps } from '.'

export const usePropsSampleDataBoxes = (
  defaultBox?: ForumBoxItem,
  title?: string,
  formValues?: SampleFormGroup,
  boxes?: ForumBox[] | null
): SampleDataTemplateProps => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [boxEdit, setBoxEdit] = useState<boolean>(false)
  const [boxData, setBoxData] = useState<any>(undefined)
  const [applyToAllCollection, setApplyToAllCollection] = useState<boolean>(false)
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const [forceRefresh, setForceRefresh] = useState<boolean>(true)
  const config = useSelector(selectors.configuration.configurationInfo)
  const collection = useSelector(selectors.exhibitors.collection)
  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()
  const sampleData = useSelector(selectors.samples.sampleData)
  const sample = useSelector(selectors.samples.sample)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const ref = useRef<FormikProps<SampleDataFormValues>>(null)
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const refresh = useSelector(selectors.auth.authByToken)

  // Force loader to make reloading more smooth
  useEffect(() => {
    const timerId = setTimeout(() => {
      setForceRefresh(false)
    }, 500)

    return () => clearTimeout(timerId)
  }, [])

  useEffect(() => {
    if (sample?.applyToCollectionUniverse) {
      setApplyToAllCollection(sample?.applyToCollectionUniverse)
      return
    }

    if (sampleDataAllInfo) {
      const filteredItems = sampleDataAllInfo[lastSegment]
      if (
        filteredItems &&
        typeof filteredItems === 'object' &&
        'applyToAllCollection' in filteredItems &&
        filteredItems.applyToAllCollection
      ) {
        setApplyToAllCollection(filteredItems.applyToAllCollection)
      } else {
        setApplyToAllCollection(false)
      }
    }
  }, [lastSegment, sampleDataAllInfo, sample?.applyToCollectionUniverse])

  const findSubForumBoxByName = useCallback(
    (name: string | undefined) => {
      if (boxes) {
        for (const item of boxes) {
          if (Array.isArray(item.subForumBoxes)) {
            const subForumBox = item.subForumBoxes.find(
              (subForumBox: any) => subForumBox.name === name
            )
            if (subForumBox) {
              return subForumBox
            }
          }
        }
      }
      return null
    },
    [boxes]
  )

  const findColorBySubForumName = useCallback(() => {
    if (boxes) {
      const forumColor = boxes.find((boxe) => boxe.name === sampleData.forum)?.color
      return forumColor
    }
    return null
  }, [boxes, sampleData.forum])

  useEffect(() => {
    if (boxes) {
      const transformedData =
        boxes &&
        boxes.map((box) => {
          const collapsible = {
            title: box.name,
            variantFont: VariantCollapsibleColoredFont.SmallBold,
            backgroundColor: getContrastColor(box.color as string),
            color: box.color as string,
          }
          const radios = {
            name: 'radios',
            required: true,
            insideCollapsible: true,
            optionsFont: VariantRadioFont.SmallBold,
            options: box.subForumBoxes.map((subBox) => ({
              label: subBox && subBox.name,
              value: subBox && subBox.name,
              dotted: true,
            })),
          }

          return {
            collapsible,
            radios,
          }
        })
      setBoxData(transformedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxes, formValues])

  const setFlagsItems = (data: any) => {
    const flagGroups: SampleFlagGroupInput[] = []
    if (data) {
      for (const key in data) {
        if (data[key] && data[key].items) {
          if (Array.isArray(data[key].items)) {
            const items = data[key]
            flagGroups.push({
              groupId: data[key].id,
              applyToCollection: data[key].applyToAllCollection,
              flagItems: items.items.map((flagItem: any) => ({
                itemId: flagItem.id,
                value: flagItem.value ? flagItem.value : null,
              })),
            })
          }
        }
      }
      return flagGroups
    }
  }

  const updateSample = useCallback(
    (data: SampleDataAllInfo) => {
      if (sampleId) {
        dispatch(
          actions.samples.sampleUpdateRequest({
            sampleId: sampleId,
            input: {
              flagGroups: setFlagsItems(data),
              boxId: data.boxe?.items?.id,
            },
          })
        )
      }
    },
    [sampleId, dispatch]
  )

  const setStoreData = useCallback(
    (values: SampleDataFormValues) => {
      const filteredItems = findSubForumBoxByName(values.radios)
      const updatedSampleDataAllInfo: SampleDataAllInfo = {
        ...sampleDataAllInfo,
        boxe: {
          id: formValues?.id,
          items: filteredItems as ForumBoxItem,
          applyToAllCollection: applyToAllCollection,
        },
      }
      dispatch(
        actions.samples.setSampleData({
          ...sampleData,
          boxe: values.radios,
        })
      )
      dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
      updateSample(updatedSampleDataAllInfo)
    },
    [
      findSubForumBoxByName,
      sampleDataAllInfo,
      formValues?.id,
      applyToAllCollection,
      dispatch,
      sampleData,
      updateSample,
    ]
  )

  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, dispatch, sample])

  return useMemo(
    () => ({
      title: title,
      isPending: forceRefresh,
      layoutProps: {
        ...layoutSampleDataMocks.basic,
        steps: {
          steps: returnSampleDataSteps(sampleData, exposantId, sampleId),
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(sampleDataRoutes(sampleData, 'prev'), {
                exposantId,
                sampleId,
              })
              navigate(link)
            },
          },
          note:
            collection?.internalNotes && collection?.internalNotes.length !== 0
              ? {
                  rounded: true,
                  number: collection?.internalNotes
                    ? collection?.internalNotes.length.toString()
                    : '0',
                  onClick: () => {
                    setIsNoteOpen(true)
                  },
                }
              : undefined,
          next: {
            text: 'Suivant',
            disabled: !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
            rightIconProps: {
              icon: Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
          nextPlus: {
            text: 'Rafraichir',
            isPending: refresh.pending,
            disabled: refresh.pending,
            onClick: () => {
              dispatch(actions.auth.authByTokenRequest())
            },
            rightIconProps: {
              icon: Icons.link,
              width: 18,
              height: 18,
            },
          },
        },
        marketplaceWarning:
          marketplaceDateDaysLeft && marketplaceDateHoursLeft
            ? {
                number: `J - ${marketplaceDateDaysLeft}`,
                text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
                redBg: true,
              }
            : undefined,
        title: `${sampleData.exhibitorRef} #${sampleId}`,
        notes: {
          title: "Note interne sur l'exposant",
          notes:
            collection && collection.internalNotes && collection.internalNotes.length !== 0
              ? collection.internalNotes.map((note) => ({
                  text: note.body,
                  author: {
                    fullName:
                      collection?.internalNotes.length !== 0
                        ? `par ${note.user.lastName} ${note.user.firstName}`
                        : '',
                    date:
                      collection?.internalNotes.length !== 0
                        ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                        : '',
                  },
                }))
              : [],
          tags: {
            newTag: collection?.exhibitor?.isNew,
            returnTag: collection?.samplesToReturn !== 0,
          },
          onClose: () => setIsNoteOpen(false),
        },
        noteModal: {
          onClose: () => setIsNoteOpen(false),
          isOpen: isNoteOpen,
          variant: ModalVariant.Small,
        },
        ...layoutProps,
      },
      formikForm: {
        title: title,
        innerRef: ref,
        initialValues: {
          radios: defaultBox && defaultBox.name,
        } as SampleDataFormValues,
        validationSchema: Yup.object().shape({
          radios: Yup.string().required(),
        }),
        onSubmit: (values) => {
          setStoreData(values)
          const link = router(sampleDataRoutes(sampleData, 'next'), {
            exposantId,
            sampleId,
          })
          navigate(link)
        },
        onContextUpdate: (context) => {
          setIsValid(context.isValid)
        },
        validateOnChange: true,
        validateOnMount: true,
      },
      allCollection: {
        name: 'allCollection',
        inlineLabel: 'Appliquer à toute la collection',
        variantSize: FormFieldSwitchSize.Small,
        value: applyToAllCollection,
        onChange: (value) => {
          setApplyToAllCollection(value.target.value)
        },
      },
      boxInfo:
        !boxEdit && defaultBox
          ? {
              text: sampleData.forum,
              secondText: defaultBox.name,
              isOn: true,
              backgroundColor: getContrastColor(findColorBySubForumName() as string),
              color: findColorBySubForumName() as string,
            }
          : undefined,
      editButton: !boxEdit
        ? {
            text: 'Modifier la caisse de rangement',
            onClick: () => setBoxEdit(true),
            type: 'button',
          }
        : undefined,
      multipleRadiosGroups: boxEdit ? boxData : undefined,
    }),
    [
      title,
      forceRefresh,
      sampleData,
      exposantId,
      sampleId,
      collection,
      isValid,
      refresh.pending,
      marketplaceDateDaysLeft,
      marketplaceDateHoursLeft,
      isNoteOpen,
      layoutProps,
      defaultBox,
      applyToAllCollection,
      boxEdit,
      findColorBySubForumName,
      boxData,
      navigate,
      dispatch,
      setStoreData,
    ]
  )
}
