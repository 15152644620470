import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { QrScannerProps } from '../../components/QrScanner'
import { Icons } from '../../components/Icon'
import ModalUpload, { ModalUploadProps } from '../../components/ModalUpload'

import * as SC from './styled'

export type SampleScanTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  scannerProps?: QrScannerProps
  closeBtn?: () => void
  uploadModal?: ModalUploadProps
}

const SampleScanTemplate: FC<SampleScanTemplateProps> = ({
  layoutProps,
  scannerProps,
  closeBtn,
  uploadModal,
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={<>{uploadModal && <ModalUpload {...uploadModal} />}</>}
    >
      <SC.TemplateContainer>
        <div onClick={closeBtn}>
          <SC.Close icon={Icons.close} width={28} height={28} color="white" />
        </div>
        <SC.Scanner {...scannerProps} />
      </SC.TemplateContainer>
    </LayoutWithBottomActions>
  )
}

export default SampleScanTemplate
