import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import {
  Editorial,
  EditorialCreateInput,
  EditorialUpdateInput,
} from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const exportEditorialsService = getCustomService<
  'exportEditorials',
  null,
  { editorialId: string }
>('exportEditorials')

export const editorialsSearchService = getCustomService<
  'editorialsSearch',
  null,
  { exhibitionApiId: string; q: string; first: number; page?: number }
>('editorialsSearch')

export const editorialByIdService = getCustomService<
  'editorialById',
  null,
  { editorialId: string }
>('editorialById')

export const editorialCreateService = getCustomService<
  'editorialCreate',
  null,
  { exhibitionApiId: string; input: EditorialCreateInput }
>('editorialCreate')

export const editorialUpdateService = getCustomService<
  'editorialUpdate',
  null,
  { editorialId: string; input: EditorialUpdateInput }
>('editorialUpdate')

export const editorialDeleteService = getCustomService<
  'editorialDelete',
  null,
  { editorialId: string }
>('editorialDelete')

export const editorialAttachSamplesService = getCustomService<
  'editorialAttachSamples',
  null,
  { editorialId: string; samplesIds: string[] }
>('editorialAttachSamples')

export const editorialDetachSamplesService = getCustomService<
  'editorialDetachSamples',
  null,
  { editorialId: string; samplesIds: string[] }
>('editorialDetachSamples')

//
// Initial state
//

export type EditosState = {
  editorials: Editorial[] | undefined
  editorial: Editorial | undefined
  // services
  editorialsSearch: typeof editorialsSearchService.state
  editorialCreate: typeof editorialCreateService.state
  editorialUpdate: typeof editorialUpdateService.state
  editorialDelete: typeof editorialUpdateService.state
  editorialAttachSamples: typeof editorialAttachSamplesService.state
  editorialDetachSamples: typeof editorialDetachSamplesService.state
  editorialById: typeof editorialByIdService.state
  exportEditorials: typeof exportEditorialsService.state
}

const initialState: EditosState = {
  editorials: undefined,
  editorial: undefined,
  // services
  editorialsSearch: editorialsSearchService.state,
  editorialCreate: editorialCreateService.state,
  editorialDelete: editorialUpdateService.state,
  editorialById: editorialByIdService.state,
  editorialAttachSamples: editorialAttachSamplesService.state,
  editorialDetachSamples: editorialDetachSamplesService.state,
  editorialUpdate: editorialUpdateService.state,
  exportEditorials: exportEditorialsService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'editos',
  initialState,
  reducers: {
    resetEditos: () => initialState,
    setEditorials: (state, { payload }: actionTypes.setEditorials) => {
      state.editorials = payload
    },
    setEditorial: (state, { payload }: actionTypes.setEditorial) => {
      state.editorial = payload
    },
    resetEditorialCreate: (state) => {
      state.editorialCreate = initialState.editorialCreate
    },
    resetExportEditorials: (state) => {
      state.exportEditorials = initialState.exportEditorials
    },
    resetEditorialDelete: (state) => {
      state.editorialDelete = initialState.editorialDelete
    },
    resetEditorialUpdate: (state) => {
      state.editorialUpdate = initialState.editorialUpdate
    },
    resetEditorialAttachSamples: (state) => {
      state.editorialAttachSamples = initialState.editorialAttachSamples
    },
    resetEditorialDetachSamples: (state) => {
      state.editorialDetachSamples = initialState.editorialDetachSamples
    },
    ...editorialsSearchService.reducers,
    ...editorialCreateService.reducers,
    ...editorialUpdateService.reducers,
    ...editorialDeleteService.reducers,
    ...editorialByIdService.reducers,
    ...editorialAttachSamplesService.reducers,
    ...editorialDetachSamplesService.reducers,
    ...exportEditorialsService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const editorials = (state: RootState) => root(state).editorials
const editorial = (state: RootState) => root(state).editorial
const exportEditorials = (state: RootState) => root(state).exportEditorials
const editorialsSearch = (state: RootState) => root(state).editorialsSearch
const editorialCreate = (state: RootState) => root(state).editorialCreate
const editorialDelete = (state: RootState) => root(state).editorialDelete
const editorialUpdate = (state: RootState) => root(state).editorialUpdate
const editorialAttachSamples = (state: RootState) => root(state).editorialAttachSamples
const editorialDetachSamples = (state: RootState) => root(state).editorialDetachSamples
const editorialById = (state: RootState) => root(state).editorialById

export const selectors = {
  editorials,
  editorial,
  exportEditorials,
  editorialsSearch,
  editorialCreate,
  editorialUpdate,
  editorialDelete,
  editorialAttachSamples,
  editorialDetachSamples,
  editorialById,
}
