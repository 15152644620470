import React, { FC, useCallback, useRef } from 'react'
import Webcam, { WebcamProps } from 'react-webcam'

import * as SC from './styled'

export type CameraProps = Partial<
  Omit<WebcamProps, 'screenshotFormat' | 'videoConstraints' | 'audio' | 'style'>
> & {
  className?: string
  onScreenshot: (src: string) => void
}

const videoConstraints: WebcamProps['videoConstraints'] = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
  aspectRatio: { ideal: 16 / 9 },
  facingMode: 'environment',
}

const Camera: FC<CameraProps> = ({ className, onScreenshot, ...camera }) => {
  const webcamRef = useRef<Webcam>(null)

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot() ?? ''
    onScreenshot(imageSrc)
  }, [webcamRef, onScreenshot])

  return (
    <SC.Container className={className}>
      <Webcam
        {...camera}
        ref={webcamRef}
        screenshotFormat="image/png"
        audio={false}
        videoConstraints={videoConstraints}
        className="camera"
        imageSmoothing={false}
      />
      {/* <SC.Svg
        width="375"
        height="768"
        viewBox="0 0 375 768"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M375 0H0V768H375V0ZM40 73C28.9543 73 20 81.9543 20 93V564C20 575.046 28.9543 584 40 584H335C346.046 584 355 575.046 355 564V93C355 81.9543 346.046 73 335 73H40Z"
          fill="#232323"
        />
      </SC.Svg>
      <SC.SvgTablet
        width="375"
        height="768"
        viewBox="0 0 375 768"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M375 0H0V768H375V0ZM120 133C112.954 133 107 138.954 107 145V623C107 629.046 112.954 635 120 635H255C262.046 635 268 629.046 268 623V145C268 138.954 262.046 133 255 133H120Z"
          fill="#232323"
        />
      </SC.SvgTablet> */}

      <SC.Button
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleCapture}
      >
        <circle cx="30" cy="30" r="25" fill="#F9F9F9" />
        <circle cx="30" cy="30" r="29" stroke="#F9F9F9" strokeWidth="2" />
      </SC.Button>
    </SC.Container>
  )
}

// function isMobileDevice() {
//   return /Mobi|Android/i.test(navigator.userAgent)
// }

export default Camera
