import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'
import { variant } from '../../../StatutSelection'
import { fonts } from '../../../../theme'

import { VariantRadioFont, VariantRadioSize } from './types'

const defaultVariant = css`
  --color: ${(props) => props.theme.colors.mineShaft};
  --background-color: ${(props) => props.theme.colors.concrete};
  --background-color--active: ${(props) => props.theme.colors.grayLight};
  --border-color: ${(props) => props.theme.colors.concrete};
  --border-color--active: ${(props) => props.theme.colors.grayLight};
`

const leatherVariant = css`
  --color: ${(props) => props.theme.colors.roseOfSharon};
  --background-color: ${(props) => props.theme.colors.janna};
  --background-color--active: ${(props) => props.theme.colors.janna};
  --border-color: ${(props) => props.theme.colors.janna};
  --border-color--active: ${(props) => props.theme.colors.roseOfSharon};
`

const sourcingVariant = css`
  --color: ${(props) => props.theme.colors.danube};
  --background-color: ${(props) => props.theme.colors.linkWater};
  --background-color--active: ${(props) => props.theme.colors.linkWater};
  --border-color: ${(props) => props.theme.colors.linkWater};
  --border-color--active: ${(props) => props.theme.colors.danube};
`

const accessoriesVariant = css`
  --color: ${(props) => props.theme.colors.pizza};
  --background-color: ${(props) => props.theme.colors.albescentWhite};
  --background-color--active: ${(props) => props.theme.colors.albescentWhite};
  --border-color: ${(props) => props.theme.colors.albescentWhite};
  --border-color--active: ${(props) => props.theme.colors.pizza};
`

const inspirationVariant = css`
  --color: ${(props) => props.theme.colors.royalPurple};
  --background-color: ${(props) => props.theme.colors.whiteLilac};
  --background-color--active: ${(props) => props.theme.colors.whiteLilac};
  --border-color: ${(props) => props.theme.colors.whiteLilac};
  --border-color--active: ${(props) => props.theme.colors.royalPurple};
`

const designVariant = css`
  --color: ${(props) => props.theme.colors.fuchsiaPink};
  --background-color: ${(props) => props.theme.colors.amour};
  --background-color--active: ${(props) => props.theme.colors.amour};
  --border-color: ${(props) => props.theme.colors.amour};
  --border-color--active: ${(props) => props.theme.colors.fuchsiaPink};
`

const transparentVariant = css`
  --color: ${(props) => props.theme.colors.mineShaft};
  --background-color: transparent;
  --background-color--active: transparent;
  --border-color: transparent;
  --border-color--active: transparent;
`

export const Container = styled.div<{ $isDisabled?: boolean; $forceBackgroundColor?: string }>`
  display: flex;
  flex-flow: column;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}

  ${({ $forceBackgroundColor }) =>
    $forceBackgroundColor &&
    css`
      background-color: ${$forceBackgroundColor};
    `}
`

export const Label = styled(FormLabel)`
  margin-bottom: 0.5rem;
`

export const Options = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Option = styled.label<{
  $isOptionDisabled?: boolean
  $isInputDisabled?: boolean
  $variant?: variant
  $size?: VariantRadioSize
  $variantFont?: VariantRadioFont
  $insideCollapsible?: boolean
  $color?: string
  $backgroundColor?: string
  $verticalLabel?: boolean
  $optionBefore?: boolean
}>`
  // colors
  ${(props) => {
    switch (props.$variant) {
      case 'leather':
        return css`
          ${leatherVariant}
        `
      case 'sourcing':
        return css`
          ${sourcingVariant}
        `
      case 'accessories':
        return css`
          ${accessoriesVariant}
        `
      case 'inspiration':
        return css`
          ${inspirationVariant}
        `
      case 'design':
        return css`
          ${designVariant}
        `
      default:
        return css`
          ${defaultVariant}
        `
    }
  }}
  // custom colors
  ${({ $color, $backgroundColor }) =>
    $backgroundColor &&
    css`
      --color: ${$color};
      --background-color: ${$backgroundColor};
      --border-color: ${$backgroundColor};
      --border-color--active: ${$color};
    `}
  // when inside collapsible
  ${({ $insideCollapsible }) => $insideCollapsible === true && transparentVariant}
  // fonts
  ${({ theme, $variantFont }) =>
    $variantFont === VariantRadioFont.SmallBold
      ? theme.textStyles.titleH6
      : $variantFont === VariantRadioFont.Bold
      ? theme.textStyles.textBold
      : $variantFont === VariantRadioFont.BoldCaps
      ? css`
          ${theme.textStyles.titleH4}
          text-transform: uppercase;
        `
      : theme.textStyles.titleH5}
  // styles aplied
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ $size }) => $size === VariantRadioSize.big && '4.6rem'};
  padding: 0.8rem 1rem;
  border-radius: 0.4rem;
  border: 1px solid;
  border-color: var(--border-color);
  line-height: 1.1;
  color: var(--color);
  background: var(--background-color);
  cursor: pointer;
  // states
  &:hover,
  &:has(input:checked) {
    border-color: var(--border-color--active);
    background: var(--background-color--active);
  }
  ${({ $isOptionDisabled }) =>
    $isOptionDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
  ${({ $isInputDisabled }) =>
    $isInputDisabled &&
    css`
      opacity: 1;
      cursor: not-allowed;
    `}
    ${({ $verticalLabel }) =>
    $verticalLabel &&
    css`
      background-color: ${({ theme }) => theme.colors.concrete};
      padding: 12px;
      &:hover,
      &:has(input:checked) {
        background-color: ${({ theme }) => theme.colors.grayLight};
      }
    `}
  ${({ $optionBefore }) =>
    $optionBefore &&
    css`
      justify-content: start;
      gap: 12px;
      border: none;
    `}
`

export const OptionRadio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: ${({ theme }) => theme.colors.mineShaft};
  width: 1.2rem;
  height: 1.2rem;
  border: 1.5px solid currentColor;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${({ theme }) => theme.colors.mineShaft};
  }

  &:checked:before {
    transform: scale(1);
  }
`

export const HeadTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  position: relative;
`

export const HeadTextContainerSecond = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  position: relative;
  margin-right: 12px;
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.gray};
  text-align: right;
  margin-top: 0.6rem;
`
export const Error = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.6rem;
  text-align: right;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`

export const HorizontalLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const VerticalLabel = styled.div`
  ${({ theme }) => theme.textStyles.titleH5}
`

export const Count = styled.span`
  ${fonts.GothamBold}
  color: ${({ theme }) => theme.colors.silver};
  font-size: 10px;
  position: absolute;
  right: -35px;
  top: -5px;
  width: 30px;
  text-align: left;
`
