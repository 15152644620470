import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import { FormSubmitButtonProps } from '../../form/FormSubmitButton'
import FormikField from '../../form/FormikField'
import { FormFieldCheckboxProps } from '../../form/fields/FormFieldCheckbox'
import { FormErrorsProps } from '../../form/FormErrors'
import { Icons } from '../../Icon'

import * as SC from './styled'

export type ResetPasswordFormValues = {
  password: string
  terms: boolean
}

export type ResetPasswordFormProps = {
  className?: string
  formikForm: FormikFormProps<ResetPasswordFormValues>
  fields: {
    password: FormFieldInputProps
    passwordConfirm: FormFieldCheckboxProps
  }
  rules: {
    length: { rule: string; isValid: boolean }
    specialCharacter: { rule: string; isValid: boolean }
    uppercase: { rule: string; isValid: boolean }
    number: { rule: string; isValid: boolean }
  }
  texts: {
    passwordSectionTitle: string
    passwordSectionText: string
  }
  errors?: FormErrorsProps['errors']
  submitButton: FormSubmitButtonProps
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  className,
  formikForm,
  fields,
  submitButton,
  texts,
  errors,
  rules,
}) => {
  return (
    <SC.Form className={className} {...formikForm}>
      <SC.Fields>
        <SC.SectionTitle>{texts.passwordSectionTitle}</SC.SectionTitle>
        <SC.PasswordText>{texts.passwordSectionText}</SC.PasswordText>
        <FormikField
          {...fields.password}
          type="password"
          hasFloatingLabel={true}
          Component={SC.FieldPassword}
        />
        <FormikField
          {...fields.passwordConfirm}
          type="password"
          hasFloatingLabel={true}
          Component={SC.FieldPassword}
        />
      </SC.Fields>
      {errors && <SC.Errors errors={errors} />}
      <SC.RulesList>
        {Object.keys(rules).map((key, idx) => (
          <SC.Rule key={idx} $isValid={rules[key as keyof typeof rules].isValid}>
            {rules[key as keyof typeof rules].rule}
            <SC.Check
              $isValid={rules[key as keyof typeof rules].isValid}
              icon={Icons.check}
              width={10}
              height={10}
            />
          </SC.Rule>
        ))}
      </SC.RulesList>
      <SC.SubmitButton
        {...submitButton}
        disabled={!submitButton.disabled}
        rightIconProps={{ icon: Icons.arrowRight, width: 22, height: 22 }}
      />
    </SC.Form>
  )
}

export default ResetPasswordForm
