import React, { FC, ImgHTMLAttributes } from 'react'

import LayoutSampleSheet, { LayoutSampleSheetProps } from '../../layouts/LayoutSampleSheet'
import InfosDisplay, { InfosDisplayProps } from '../../components/InfosDisplay'
import ActionButton, { ActionButtonProps } from '../../components/ActionButton'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { PerformanceCodeProps } from '../../components/PerformanceCode'
import { SampleChartProps } from '../../components/SampleChart'
import { ModalProps } from '../../components/Modal'
import SimpleSlider from '../../components/SimpleSlider'
import { NumberedTagProps } from '../../components/NumberedTag'

import * as SC from './styled'

export type SampleSheetDataTemplateProps = {
  layoutProps?: LayoutSampleSheetProps
  pending?: boolean
  sampleInfos?: InfosDisplayProps
  display?: ActionButtonProps
  performanceTitle?: string
  performanceCode?: PerformanceCodeProps[]
  image?: ImgHTMLAttributes<HTMLImageElement>[]
  statistics?: {
    title?: string
    chart?: SampleChartProps
  }
  modal?: ModalProps
  marketplaceWarning?: NumberedTagProps
}

const SampleSheetDataTemplate: FC<SampleSheetDataTemplateProps> = ({
  layoutProps,
  pending,
  sampleInfos,
  display,
  performanceTitle,
  performanceCode,
  image,
  marketplaceWarning,
  statistics,
}) => {
  return (
    <LayoutSampleSheet {...layoutProps}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          {marketplaceWarning && <SC.MarketplaceWarning {...marketplaceWarning} />}
          <SC.Container>
            {image && (
              <SC.LabelContainerMobile>
                {display && (
                  <ActionButton
                    {...display}
                    variantSize={ActionButtonSize.Small}
                    variantScheme={ActionButtonScheme.Tertiary}
                    rightIconProps={{ icon: Icons.eyeOpen, height: 11, width: 11 }}
                  />
                )}

                <SC.SliderContainer>
                  {image && <SimpleSlider image={image && image.map((img) => img)} />}
                </SC.SliderContainer>
              </SC.LabelContainerMobile>
            )}
            {image && (
              <SC.LabelContainerDesktop>
                <SC.BlockImageDesktop>
                  <SC.SliderContainer>
                    {image && <SimpleSlider image={image && image.map((img) => img)} />}
                  </SC.SliderContainer>
                  {display && (
                    <SC.ActionButtonDesktop
                      {...display}
                      variantSize={ActionButtonSize.Small}
                      variantScheme={ActionButtonScheme.Tertiary}
                      rightIconProps={{ icon: Icons.eyeOpen, height: 11, width: 11 }}
                    />
                  )}
                </SC.BlockImageDesktop>
              </SC.LabelContainerDesktop>
            )}
            <SC.Content>
              {sampleInfos && <InfosDisplay {...sampleInfos} />}
              {performanceCode && performanceCode.length > 0 && (
                <SC.PerformanceContainer>
                  <SC.PerfomanceTitle>{performanceTitle}</SC.PerfomanceTitle>
                  <SC.PerformanceList>
                    {performanceCode &&
                      performanceCode.map((item, key) => (
                        <SC.StyledPerformanceCode key={key} {...item} />
                      ))}
                  </SC.PerformanceList>
                </SC.PerformanceContainer>
              )}
            </SC.Content>
          </SC.Container>
          {statistics && (
            <SC.Section>
              {statistics.title && <SC.Title tag="h2" text={statistics.title} />}
              {statistics.chart && <SC.Chart {...statistics.chart} />}
            </SC.Section>
          )}
        </>
      )}
    </LayoutSampleSheet>
  )
}

export default SampleSheetDataTemplate
