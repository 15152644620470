import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const updateUser = getMutationService({
  mutation: gql(`
    mutation userUpdate($userId: ID!, $input: UserUpdateInput!) {
        userUpdate(userId: $userId, input: $input) {
          id
          type
          role
          email
          firstName
          lastName
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
