import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleDataTemplate from '../../../templates/SampleData'
import { selectors } from '../../../redux'
import { useSampleDataTemplateProps } from '../../../templates/SampleData/useProps'

const ExponentSampleDataAccessorieType: React.FC<RouteProps> = () => {
  const form = useSelector(selectors.samples.sampleFormByUniverse)
  const templateProps = useSampleDataTemplateProps(
    "Type d'accessoire",
    form.find((item) => item.miraklRef === 'type_of_accessories')
  )
  return <SampleDataTemplate {...templateProps} />
}

export default ExponentSampleDataAccessorieType
