import React, { FC } from 'react'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type MenuLinkProps = LinkProps & {
  className?: string
  removeBorder?: boolean
  selected?: boolean
}

const MenuLink: FC<MenuLinkProps> = ({ className, removeBorder, selected, ...link }) => {
  return (
    <SC.MenuLink
      className={className}
      $selected={selected}
      $removeBorder={removeBorder}
      {...link}
    ></SC.MenuLink>
  )
}

export default MenuLink
