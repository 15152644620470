import React, { FC } from 'react'

import LayoutSampleSheet, { LayoutSampleSheetProps } from '../../layouts/LayoutSampleSheet'
import { EditoCardProps } from '../../components/EditoCard'

import * as SC from './styled'

export type SampleSheetEditoTemplateProps = {
  layoutProps?: LayoutSampleSheetProps
  pending?: boolean
  editoList?: EditoCardProps[]
  emptyMessage?: string
}

const SampleSheetEditoTemplate: FC<SampleSheetEditoTemplateProps> = ({
  layoutProps,
  pending,
  editoList,
  emptyMessage,
}) => {
  return (
    <LayoutSampleSheet {...layoutProps}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          <SC.EditoListContainer>
            {editoList && editoList.length !== 0 ? (
              Object.keys(editoList).map((key, idx) => <SC.Edito key={key} {...editoList[idx]} />)
            ) : (
              <div>{emptyMessage}</div>
            )}
          </SC.EditoListContainer>
        </>
      )}
    </LayoutSampleSheet>
  )
}

export default SampleSheetEditoTemplate
