import { SampleMkpForum } from '../../graphql/generated/graphql'

export const convertMkpToEnum = (input: string): SampleMkpForum => {
  switch (input) {
    case 'Forum':
      return SampleMkpForum.Forum
    case 'Marketplace + Forum':
      return SampleMkpForum.MarketplaceForum
    case 'Marketplace':
      return SampleMkpForum.Marketplace
    default:
      throw new Error('Chaîne de caractères non valide')
  }
}
