import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import Icon, { Icons } from '../Icon'
import { ActionButtonScheme } from '../ActionButton/types'

import * as SC from './styled'

export type WarningProps = {
  className?: string
  label?: string
  text?: string
  actionButton?: ActionButtonProps
}

const Warning: FC<WarningProps> = ({ className, label, text, actionButton }) => {
  return label ? (
    <SC.Container className={className} $withContent={text || actionButton ? true : false}>
      <SC.Label>
        <Icon icon={Icons.danger} width={12} height={12} />
        <span>{label}</span>
      </SC.Label>
      {text && <SC.Text>{text}</SC.Text>}
      {actionButton && (
        <SC.StyledActionButton variantScheme={ActionButtonScheme.Transparent} {...actionButton} />
      )}
    </SC.Container>
  ) : null
}

export default Warning
