import React, { FC } from 'react'

import LayoutExhibitionSheet, {
  LayoutExhibitionSheetProps,
} from '../../layouts/LayoutExhibitionSheet'
import { EditoCardProps } from '../../components/EditoCard'
import { SearchBarProps } from '../../components/SearchBar'
import FormikField from '../../components/form/FormikField'
import { FormFieldTextAreaProps } from '../../components/form/fields/FormFieldTextArea'
import { FormErrorsProps } from '../../components/form/FormErrors'
import { FormikFormProps } from '../../components/form/FormikForm'
import { FormFieldInputProps } from '../../components/form/fields/FormFieldInput'

import * as SC from './styled'

export type EditoEditFormValues = {
  title: string
  description: string
}

export type ExhibitionSheetEditoTemplateProps = {
  layoutProps?: LayoutExhibitionSheetProps
  editoList?: EditoCardProps[]
  searchBar?: SearchBarProps
  emptyMessage?: string
  editMode?: boolean
  editForm: {
    title?: string
    formikForm: FormikFormProps<EditoEditFormValues>
    fields: {
      title: {
        label?: string
        props: FormFieldInputProps
      }
      description: {
        label?: string
        props: FormFieldTextAreaProps
      }
    }
    errors?: FormErrorsProps['errors']
  }
}

const ExhibitionSheetEditoTemplate: FC<ExhibitionSheetEditoTemplateProps> = ({
  layoutProps,
  editoList,
  searchBar,
  emptyMessage,
  editMode,
  editForm,
}) => {
  return (
    <>
      <LayoutExhibitionSheet {...layoutProps}>
        {!editMode ? (
          <SC.StyledContentWrapper>
            <SC.Search {...searchBar} />
            <SC.EditoListContainer>
              {editoList && editoList.length !== 0 ? (
                Object.keys(editoList).map((key, idx) => <SC.Edito key={key} {...editoList[idx]} />)
              ) : (
                <SC.Empty>{emptyMessage}</SC.Empty>
              )}
            </SC.EditoListContainer>
          </SC.StyledContentWrapper>
        ) : (
          <SC.Form {...editForm.formikForm}>
            {editForm.title && <SC.Title tag="h1" text={editForm.title} />}
            <SC.Fieldset>
              <FormikField Component={SC.StyledTextInput} {...editForm.fields.title.props} />
              <FormikField Component={SC.StyledTextarea} {...editForm.fields.description.props} />
            </SC.Fieldset>
            {editForm.errors && <SC.Errors errors={editForm.errors} />}
          </SC.Form>
        )}
      </LayoutExhibitionSheet>
    </>
  )
}

export default ExhibitionSheetEditoTemplate
