import React, { FC } from 'react'

import LayoutSampleSheet, { LayoutSampleSheetProps } from '../../layouts/LayoutSampleSheet'
import { SampleHistoryProps } from '../../components/SampleHistory'

import * as SC from './styled'

export type SampleSheetHistoryTemplateProps = {
  layoutProps?: LayoutSampleSheetProps
  pending?: boolean
  history: SampleHistoryProps
}

const SampleSheetHistoryTemplate: FC<SampleSheetHistoryTemplateProps> = ({
  layoutProps,
  pending,
  history,
}) => {
  return (
    <LayoutSampleSheet {...layoutProps}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          <SC.History {...history} />
        </>
      )}
    </LayoutSampleSheet>
  )
}

export default SampleSheetHistoryTemplate
