import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { FormikProps } from 'formik'

import { actions, selectors } from '../../../redux'
import ExhibitionSheetSamplesTemplate from '../../../templates/ExhibitionSheetSamples'
import { useExhibitionSheetSamplesTemplateProps } from '../../../templates/ExhibitionSheetSamples/useProps'
import FiltersExport from '../../../components/FiltersExport'
import { useFiltersExportSamplesTemplateProps } from '../../../templates/Filters/usePropsExportSamples'
import { FiltersFormValues, UniversesFormValues } from '../../../components/forms/FiltersForm'
import { Icons } from '../../../components/Icon'
import { ExhibitionForum } from '../../../graphql/generated/graphql'
import { findTagsFiltersInDictionaries } from '../../../components/Filters/utils'
import { FilterTagProps } from '../../../components/FilterTag'

const ExhibitionSheetSamples: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const exhibition = useSelector(selectors.exhibitions.exhibitions)
  const exportSamples = useSelector(selectors.samples.exportSamples)
  const config = useSelector(selectors.configuration.configurationInfo)
  const universes = config?.dictionaries.find((item) => item.type && item.type === 'UNIVERSES')
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [forumSelected, setForumSelected] = useState<string>('')
  const [forum, setForum] = useState<ExhibitionForum | undefined>(undefined)
  const [exportAll, setExportAll] = useState<boolean>(true)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [tags, setTags] = useState<Array<FilterTagProps>>([])
  const [filtersParsed, setFiltersParsed] = useState<any>([])
  const [universe, setUniverse] = useState<string | undefined>(undefined)
  const [searchFilters, setSearchFilters] = useState<FiltersFormValues | undefined>(undefined)
  const filters = useSelector(selectors.samples.sampleFilters)
  const samplesCountBy = useSelector(selectors.samples.samplesCountBy)
  const samplesCall = useSelector(selectors.samples.getSamples)

  const universeIds = useMemo(
    () =>
      config?.dictionaries.find((item) => item.type === 'UNIVERSES')?.items.map((item) => item.key),
    [config?.dictionaries]
  )

  useEffect(() => {
    universeIds &&
      dispatch(
        actions.samples.samplesCountByRequest({
          universesEbmsIds: universeIds,
          exhibitionsApiIds: exhibitionId,
        })
      )
  }, [dispatch, exhibitionId, universeIds])

  useEffect(() => {
    if (exhibitionId)
      dispatch(
        actions.samples.sampleCountByUniverseRequest({
          exhibitionApiId: exhibitionId,
        })
      )
  }, [dispatch, exhibitionId])

  useEffect(() => {
    if (exportSamples && (exportSamples.success || exportSamples.errors)) {
      setTimeout(() => {
        dispatch(actions.samples.resetExportSamples())
      }, 2000)
    }
  }, [dispatch, exportSamples])

  useEffect(() => {
    if (exhibitionId)
      dispatch(
        actions.exhibitions.exhibitionByApiIdRequest({
          exhibitionApiId: exhibitionId,
        })
      )
  }, [dispatch, exhibitionId])

  useEffect(() => {
    if (tags) setFiltersParsed(findTagsFiltersInDictionaries(tags, config?.dictionaries))
  }, [config?.dictionaries, tags])

  useEffect(() => {
    if (exhibitionId && filters) {
      const data = filters || []
      const { forums } = data || {}
      const filterParsedCall = filtersParsed
      const countriesInFilters: string[] =
        filterParsedCall.find((item: any) => item.type === 'COUNTRIES')?.filters || []
      const pavilionsEbmsIds =
        filterParsedCall.find((item: any) => item.type === 'PAVILIONS')?.filters || []
      const forumsInFilters: string[] | undefined =
        searchFilters &&
        searchFilters.Forum &&
        searchFilters.Forum.map((forum) => {
          const matchingKey = Object.keys(forums).find((key) => forums[key] === forum.trim())
          return matchingKey || ''
        })
      const filteredForumsInFilters =
        forumsInFilters && forumsInFilters.filter((forum) => forum !== '').concat(forumSelected)
      const filteredCountriesInFilters = countriesInFilters.filter((country) => country !== '')
      const findKeysForValues = (searchValues: FilterTagProps[]) => {
        const foundKeys: string[] = []
        //@ts-ignore
        Object.entries(data).forEach(([category, items]) => {
          if (
            category !== 'countries' &&
            category !== 'exhibitions' &&
            category !== 'pavilions' &&
            category !== 'univers' &&
            category !== 'secteur' &&
            category !== 'forums'
          ) {
            const itemsRecord: Record<string, unknown> = items as Record<string, unknown>
            Object.entries(itemsRecord).forEach(([itemId, itemName]) => {
              searchValues.forEach((value: FilterTagProps) => {
                if (value.text && value.text.includes(itemName as string)) {
                  foundKeys.push(itemId)
                }
              })
            })
          }
        })

        return foundKeys
      }
      dispatch(
        actions.samples.getSamplesNumberRequest({
          q: '',
          page: 1,
          universesEbmsIds: universe ? [universe] : undefined,
          countries: filteredCountriesInFilters,
          pavilionsEbmsIds,
          exhibitionsApiIds: [exhibitionId],
          forumsIds:
            filteredForumsInFilters && filteredForumsInFilters[0] === ''
              ? undefined
              : filteredForumsInFilters,
          flagItems: findKeysForValues(tags),
          withTrashed: false,
          first: 1,
        })
      )
    }
  }, [
    config?.dictionaries,
    dispatch,
    exhibitionId,
    filters,
    filtersParsed,
    forumSelected,
    searchFilters,
    tags,
    universe,
  ])

  useEffect(() => {
    if (universe) {
      dispatch(actions.samples.sampleSearchFiltersRequest({ universeEbmsId: universe }))
    }
  }, [universe, dispatch])

  useEffect(() => {
    if (exhibition) {
      const forum = exhibition.forums.find((forum) => {
        if (forum.id === forumSelected) {
          return true
        }
        return forum.subForums.some((subForum) => subForum.id === forumSelected)
      })
      setForum(forum)
    }
  }, [exhibition, forumSelected])

  const handleFiltersClick = () => {
    setOpenFilters((prevOpenFilters) => !prevOpenFilters)
  }

  useEffect(() => {
    if (tags.filter((tag) => tag.text !== undefined).length > 1) {
      setExportAll(false)
    }
    if (tags.filter((tag) => tag.text !== undefined).length === 1) {
      setExportAll(true)
    }
  }, [tags, tags.length])

  const handleExportAllClick = () => {
    setExportAll(true)
    setForum(undefined)
    setForumSelected('')
    setTags([])
  }

  const templateProps = useExhibitionSheetSamplesTemplateProps(
    exhibition,
    handleFiltersClick,
    setForumSelected,
    forumSelected,
    forum
  )
  const filterstemplateProps = useFiltersExportSamplesTemplateProps({
    handleFiltersClick,
    openFilters,
    isExponent: false,
    handleExportAllClick,
    exportAll,
    filters,
    tags,
    setTags,
    universe,
    forum,
    setSearchFilters,
    searchFilters,
  })

  const ref = useRef<FormikProps<UniversesFormValues>>(null)

  return (
    <>
      <ExhibitionSheetSamplesTemplate
        {...templateProps}
        layoutProps={{
          ...templateProps.layoutProps,
          toastModal:
            exportSamples && (exportSamples.success || exportSamples.errors)
              ? {
                  text: "L'export a bien été envoyé par email",
                  isOpen: true,
                  icon: { icon: Icons.check, height: 20, width: 20 },
                }
              : undefined,
        }}
      />
      <FiltersExport
        {...filterstemplateProps}
        isOpen={openFilters}
        onClose={handleFiltersClick}
        exhibitorFilters={false}
        samplesCount={samplesCountBy && samplesCountBy}
        universe={
          universes
            ? (universes?.items.find((item) => item.key === universe)?.value as string)
            : undefined
        }
        universes={universes}
        pending={samplesCall.pending}
        formikFormUniverses={{
          innerRef: ref,
          initialValues: {
            universe: '',
          },
          validationSchema: Yup.object().shape({
            universe: Yup.string().required(),
          }),
          onSubmit: (values) => {
            setUniverse(values.universe)
          },
          onContextUpdate: (context) => {
            setIsValid(context.isValid)
          },
          validateOnChange: true,
          validateOnMount: true,
        }}
        submitUniverseButton={{
          text: `Valider`,
          disabled: !isValid,
          onClick: () => ref.current && ref.current.submitForm(),
          rightIconProps: { icon: Icons.check, width: 20 },
        }}
        cleanUniverseButton={{
          text: `Changer l'univers`,
          onClick: () => setUniverse(undefined),
          rightIconProps: { icon: Icons.arrowRight, width: 20 },
        }}
      />
    </>
  )
}

export default ExhibitionSheetSamples
