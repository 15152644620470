import { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { FormikProps } from 'formik'

import { actions, selectors } from '../../redux'
import { useLayoutLoungeConfigTemplateProps } from '../../layouts/LayoutLoungeConfig/useProps'
import { router, routesPath } from '../../router'
import { ActionButtonScheme } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { LoungeSubForumFormValues } from '../../components/forms/LoungesSubForumForm'
import { LoungeSubForumChoiceFormValues } from '../../components/forms/LoungeSubForumChoiceForm'
import { Exhibition, ExhibitionForum } from '../../graphql/generated/graphql'
import { getContrastColor } from '../../relay/Forums/getContrastColor'

import { LoungesSubForumTemplateProps } from '.'

export const useLoungeSubForumTemplateProps = (): LoungesSubForumTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const layoutLoungeProps = useLayoutLoungeConfigTemplateProps()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isValidEdit, setIsValidEdit] = useState<boolean>(false)

  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)
  const ref = useRef<FormikProps<LoungeSubForumFormValues>>(null)
  const refEdit = useRef<FormikProps<LoungeSubForumChoiceFormValues>>(null)

  const addNewSubForum = useCallback(
    (values: LoungeSubForumChoiceFormValues) => {
      const selectedForum = loungeConfig?.forums.find((forum) => forum.id === values.forumSelected)
      if (selectedForum) {
        const newSubForum: any = {
          id: `new${selectedForum.subForums ? selectedForum.subForums.length.toString() : 'new0'}`,
          name: values.subForumName,
        }
        const updatedForum: ExhibitionForum = {
          ...selectedForum,
          subForums: selectedForum.subForums
            ? [...selectedForum.subForums, newSubForum]
            : [newSubForum],
        }

        const updatedForums = loungeConfig?.forums.map((item) =>
          item.id === updatedForum.id ? updatedForum : item
        )

        if (updatedForums) {
          const updatedLoungeConfig = {
            ...loungeConfig,
            forums: updatedForums,
          }
          dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
        }

        setEditMode(false)
      }
    },
    [loungeConfig, dispatch]
  )

  const updateSelectedSubForums = useCallback(
    (selectedSubForumsData: LoungeSubForumFormValues) => {
      return loungeConfig?.forums.map((forum) => {
        const updatedSubForums =
          forum.subForums &&
          forum.subForums.map((subForum) => {
            const isSelected = selectedSubForumsData[forum.name]?.includes(subForum.id)
            return {
              ...subForum,
              selected: isSelected || false,
            }
          })

        return {
          ...forum,
          subForums: updatedSubForums,
        }
      })
    },
    [loungeConfig]
  )

  const isValidContext = (context: any) => {
    if (!Object.keys(context).length) {
      return false
    }
    for (const forum in context) {
      if (Array.isArray(context[forum]) && context[forum].length > 0) {
        return true
      }
    }

    return false
  }

  const transformInitialValues = useCallback(() => {
    const result = {
      subForumsSelected: [],
    }

    loungeConfig?.forums.forEach((forum) => {
      //@ts-ignore
      if (forum.selected && forum.subForums) {
        const subForumNames = forum.subForums
          //@ts-ignore
          .filter((subForum) => subForum.selected)
          .map((subForum) => subForum.id)

        Object.assign(result, { [forum.name]: subForumNames })
      }
    })

    return result
  }, [loungeConfig?.forums])

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutLoungeProps,
        bottomActions: {
          ...layoutLoungeProps.bottomActions,
          next: !editMode
            ? {
                ...layoutLoungeProps.bottomActions?.next,
                disabled: !isValid,
                onClick: () => {
                  if (ref) ref.current?.submitForm()
                },
              }
            : {
                text: 'Valider',
                disabled: !isValidEdit,
                rightIconProps: {
                  icon: Icons.check,
                  width: 18,
                  height: 18,
                },
                onClick: () => {
                  refEdit.current?.submitForm()
                },
              },
          back: !editMode
            ? {
                ...layoutLoungeProps.bottomActions?.back,
                onClick: () => {
                  const link = router(routesPath.loungesForumsChoice)
                  navigate(link)
                },
              }
            : {
                ...layoutLoungeProps.bottomActions?.back,
                onClick: () => {
                  setEditMode(false)
                },
              },
        },
      },
      title: !editMode ? 'Les sous-forums' : undefined,
      subTitle: editMode ? 'Nouveau sous-forum' : undefined,
      buttonProps: {
        text: 'Nouveau sous-forum',
        variantScheme: ActionButtonScheme.Secondary,
        rightIconProps: { icon: Icons.plus, height: 12, width: 12 },
        type: 'button',
        onClick: () => setEditMode(true),
      },
      editMode: editMode,
      loungeSubForumFormProps: {
        formikForm: {
          innerRef: ref,
          initialValues: transformInitialValues(),
          validationSchema: Yup.object()
            .shape({
              subForumsSelected: Yup.array().required(
                'Vous devez sélectionner au moins une option'
              ),
            })
            .test(
              'au-moins-une-selection',
              'Vous devez sélectionner au moins une option',
              function (value) {
                const { subForumsSelected } = value
                return Boolean(subForumsSelected?.length)
              }
            ),
          onSubmit: (values) => {
            const forumsWithSelectedSubForums = updateSelectedSubForums(values)
            const updatedLoungeConfig = {
              ...loungeConfig,
              forums: forumsWithSelectedSubForums,
            }
            dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
            const link = router(routesPath.loungeResume)
            navigate(link)
          },
          onContextUpdate(context) {
            setIsValid(isValidContext(context.values))
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          forums: (loungeConfig?.forums || [])
            // @ts-ignore For parse purpose
            .filter((item) => item.selected)
            .map((item) => ({
              name: item.name,
              required: true,
              backgroundColor: item.color as string,
              options: item.subForums
                ? item.subForums.map((subItem) => ({
                    label: `${subItem.name}`,
                    value: subItem.id,
                    display: 'withDot',
                    //@ts-ignore
                    checked: subItem.selected,
                  }))
                : [],
            })),
        },
      },
      loungeSubForumChoiceFormProps: {
        formikForm: {
          innerRef: refEdit,
          initialValues: {
            forumSelected: '',
            subForumName: '',
          },
          validationSchema: Yup.object().shape({
            forumSelected: Yup.string().required(),
            subForumName: Yup.string()
              .required()
              .test(
                'unique-subforum',
                'Un sous-forum au forum associé porte le même nom, Veuillez corriger le nom du sous-forum.',
                function (value) {
                  const forumSelected = this.parent.forumSelected
                  const selectedForum = loungeConfig?.forums.find(
                    (forum) => forum.id === forumSelected
                  )

                  if (!selectedForum) {
                    return true
                  }
                  const matchingSubForum =
                    selectedForum.subForums && value
                      ? selectedForum.subForums.find(
                          (subForum) => subForum.name.toLowerCase() === value.toLowerCase()
                        )
                      : false

                  return !matchingSubForum
                }
              ),
          }),
          onSubmit: (values) => {
            addNewSubForum(values)
          },
          onContextUpdate(context) {
            setIsValidEdit(context.isValid)
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          forumSelected: {
            name: 'forumSelected',
            required: true,
            options: loungeConfig?.forums
              ? (loungeConfig?.forums || [])
                  // @ts-ignore For parse purpose
                  .filter((item) => item.selected)
                  .map((forum) => ({
                    label: `${forum.name}`,
                    value: forum.id,
                    display: 'withDot',
                    backgroundColor: getContrastColor(forum.color as string),
                    color: forum.color as string,
                  }))
              : [],
          },
          subForumName: {
            name: 'subForumName',
            label: 'Nom du sous-forum',
            type: 'text',
            required: true,
            hasFloatingLabel: true,
          },
        },
      },
    }
  }, [
    addNewSubForum,
    dispatch,
    editMode,
    isValid,
    isValidEdit,
    layoutLoungeProps,
    loungeConfig,
    navigate,
    transformInitialValues,
    updateSelectedSubForums,
  ])
}
