import styled from 'styled-components'

import ColoredLinkBlock from '../../components/ColoredLinkBlock'
import ContentWrapper from '../../components/ContentWrapper'
import Heading from '../../components/Heading'

export const Content = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Title = styled(Heading).attrs({ tag: 'h2' })`
  margin-bottom: 1.2rem;
`

export const Block = styled(ColoredLinkBlock)`
  flex: 1;
  text-decoration: none;
  &:last-child {
    flex-grow: 0;
    min-height: 8rem;
  }
`

export const List = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0.6rem;
  flex-direction: column;
  padding: 6px;
  margin-left: -2rem;
  margin-right: -2rem;
`
