import React, { useEffect } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import LoungesResumeTemplate from '../../../templates/LoungeResume'
import { useLoungeResumeTemplateProps } from '../../../templates/LoungeResume/useProps'
import { selectors } from '../../../redux'
import { router, routesPath } from '../../../router'

const LoungesResume: React.FC<RouteProps> = () => {
  const navigate = useNavigate()

  const templateProps = useLoungeResumeTemplateProps()
  const createExhibition = useSelector(selectors.exhibitions.createExhibition)
  const updateExhibition = useSelector(selectors.exhibitions.updateExhibition)

  useEffect(() => {
    if (
      (createExhibition && createExhibition.success) ||
      (updateExhibition && updateExhibition.success)
    ) {
      const link = router(routesPath.lounges, {
        redirect: 'edition',
      })
      navigate(link)
    }
  }, [
    createExhibition,
    createExhibition?.success,
    navigate,
    updateExhibition,
    updateExhibition?.success,
  ])

  return <LoungesResumeTemplate {...templateProps} />
}

export default LoungesResume
