import { all, put, takeEvery } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'
import { Editorial } from '../../graphql/generated/graphql'

export default class EditosSagas {
  static *onInit() {
    yield put(actions.editos.resetEditos())
  }

  static *onEditorialsSearch({
    payload,
  }: ReturnType<typeof actions.editos.editorialsSearchRequest>) {
    const rs = yield* ApiSagas.call(services.editos.queries.editorialsSearch, payload)

    if (rs.errors) {
      yield put(actions.editos.editorialsSearchError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.setEditorials(rs.data.editorialsSearch.data as Editorial[]))
      yield put(actions.editos.editorialsSearchSuccess())
    }
  }

  static *onEditorialById({ payload }: ReturnType<typeof actions.editos.editorialByIdRequest>) {
    const rs = yield* ApiSagas.call(services.editos.queries.editorialById, payload)

    if (rs.errors) {
      yield put(actions.editos.editorialByIdError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.setEditorial(rs.data.editorialById as Editorial))
      yield put(actions.editos.editorialByIdSuccess())
    }
  }

  static *onEditorialsCreate({
    payload,
  }: ReturnType<typeof actions.editos.editorialCreateRequest>) {
    const rs = yield* ApiSagas.call(services.editos.mutations.editorialCreate, payload)

    if (rs.errors) {
      yield put(actions.editos.editorialCreateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.editorialCreateSuccess())
    }
  }

  static *onEditorialsUpdate({
    payload,
  }: ReturnType<typeof actions.editos.editorialUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.editos.mutations.editorialUpdate, payload)

    if (rs.errors) {
      yield put(actions.editos.editorialUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.editorialUpdateSuccess())
    }
  }

  static *onEditorialsDelete({
    payload,
  }: ReturnType<typeof actions.editos.editorialDeleteRequest>) {
    const rs = yield* ApiSagas.call(services.editos.mutations.editorialDelete, payload)

    if (rs.errors) {
      yield put(actions.editos.editorialDeleteError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.editorialDeleteSuccess())
    }
  }

  static *onEditorialAttachSamples({
    payload,
  }: ReturnType<typeof actions.editos.editorialAttachSamplesRequest>) {
    const rs = yield* ApiSagas.call(services.editos.mutations.editorialAttachSamples, payload)

    if (rs.errors) {
      yield put(actions.editos.editorialAttachSamplesError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.editorialAttachSamplesSuccess())
    }
  }

  static *onEditorialDetachSamples({
    payload,
  }: ReturnType<typeof actions.editos.editorialDetachSamplesRequest>) {
    const rs = yield* ApiSagas.call(services.editos.mutations.editorialDetachSamples, payload)

    if (rs.errors) {
      yield put(actions.editos.editorialDetachSamplesError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.editorialDetachSamplesSuccess())
    }
  }

  static *onExportEditorials({
    payload,
  }: ReturnType<typeof actions.editos.exportEditorialsRequest>) {
    const rs = yield* ApiSagas.call(services.editos.queries.exportEditorials, payload)

    if (rs.errors) {
      yield put(actions.editos.exportEditorialsError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.editos.exportEditorialsSuccess())
      return
    }
  }
  static *listeners() {
    yield all([takeEvery(actions.editos.editorialsSearchRequest, this.onEditorialsSearch)])
    yield all([takeEvery(actions.editos.editorialCreateRequest, this.onEditorialsCreate)])
    yield all([takeEvery(actions.editos.editorialUpdateRequest, this.onEditorialsUpdate)])
    yield all([takeEvery(actions.editos.editorialDeleteRequest, this.onEditorialsDelete)])
    yield all([takeEvery(actions.editos.editorialByIdRequest, this.onEditorialById)])
    yield all([takeEvery(actions.editos.exportEditorialsRequest, this.onExportEditorials)])
    yield all([
      takeEvery(actions.editos.editorialAttachSamplesRequest, this.onEditorialAttachSamples),
    ])
    yield all([
      takeEvery(actions.editos.editorialDetachSamplesRequest, this.onEditorialDetachSamples),
    ])
  }
}
