import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SampleDataTemplate from '../../../templates/SampleData'
import { actions, selectors } from '../../../redux'
import { useSampleDataTemplateProps } from '../../../templates/SampleData/useProps'
import {
  FlagGroupResponseType,
  SampleFormGroup,
  SampleFormItem,
} from '../../../graphql/generated/graphql'

const ExponentSampleDataSubForum: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const [matchingSubForums, setMatchingSubForums] = useState<SampleFormGroup | undefined>(undefined)
  const configuration = useSelector(selectors.configuration.configurationInfo)
  const forums = useSelector(selectors.exhibitions.exhibitions)
  const sampleData = useSelector(selectors.samples.sampleData)

  useEffect(() => {
    configuration &&
      dispatch(
        actions.exhibitions.exhibitionByApiIdRequest({
          exhibitionApiId: configuration?.exhibitionActiveApiId.toString(),
        })
      )
  }, [configuration, configuration?.exhibitionActiveApiId, dispatch])

  useEffect(() => {
    if (forums) {
      const matchingSubForums = forums.forums
        .filter((forum) => forum.name === sampleData.forum)
        .map((forum) => forum.subForums)
        .flat()

      setMatchingSubForums({
        applyToCollection: false,
        id: '',
        items: (matchingSubForums as unknown) as SampleFormItem[],
        name: 'SOUS FORUM',
        miraklRef: 'subForum',
        responseType: FlagGroupResponseType.Single,
      })
    }
  }, [configuration, dispatch, forums, sampleData.forum])

  const templateProps = useSampleDataTemplateProps(
    'Sous-Forums',
    (matchingSubForums as unknown) as SampleFormGroup
  )
  return matchingSubForums ? <SampleDataTemplate {...templateProps} /> : null
}

export default ExponentSampleDataSubForum
