import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const flagItemCreate = getMutationService({
  mutation: gql(`
      mutation flagItemCreate(
        $flagGroupId: ID!
        $name: String!
        $miraklCategoryLabel: String
        $order: Int
      ) {
        flagItemCreate(
          flagGroupId: $flagGroupId
          name: $name
          miraklCategoryLabel: $miraklCategoryLabel
          order: $order
        ) {
          id
          name
          miraklRef
          responseType
          order
          items {
            id
            name
            miraklCategoryLabel
            cascadingGroups
            order
          }
        }
      }
    `),
  transformer: (response) => {
    return response
  },
})
