import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const LdsRing = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
`

const LdsRingDiv = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  margin: 8px;
  border: 2.5px solid #464646;
  border-radius: 50%;
  animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #464646 transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`
export type SimpleLoaderProps = {
  className?: string
}

const SimpleLoader: FC<SimpleLoaderProps> = ({ className }) => {
  return (
    <LdsRing className={className}>
      <LdsRingDiv></LdsRingDiv>
      <LdsRingDiv></LdsRingDiv>
      <LdsRingDiv></LdsRingDiv>
      <LdsRingDiv></LdsRingDiv>
    </LdsRing>
  )
}

export default SimpleLoader
