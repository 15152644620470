import React, { useEffect } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { actions, selectors } from '../../../redux'
import SampleSheetHistoryTemplate from '../../../templates/SampleSheetHistory'
import { useSampleSheetHistoryTemplateProps } from '../../../templates/SampleSheetHistory/useProps'

const SampleSheetHistory: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { sampleId } = useParams<{ sampleId: string }>()
  const sample = useSelector(selectors.samples.sample)

  useEffect(() => {
    if (sample && sampleId) {
      dispatch(
        actions.samples.getSampleHistoryByIdRequest({
          sampleId,
        })
      )
    }
  }, [dispatch, sample, sampleId])

  const templateProps = useSampleSheetHistoryTemplateProps()

  return (
    <>
      <SampleSheetHistoryTemplate {...templateProps} />
    </>
  )
}

export default SampleSheetHistory
