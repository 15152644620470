import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'
import FormFieldCheckbox from '../FormFieldCheckbox'

export const Wrapper = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`

export const Label = styled(FormLabel)`
  margin-bottom: 0.5rem;
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const Checkbox = styled(FormFieldCheckbox)`
  width: 100%;
`

export const Error = styled.p`
  ${({ theme }) => theme.textStyles.textBook}
  align-self: flex-end;
  font-style: italic;
  color: ${({ theme }) => theme.colors.red};
  margin-top: 0.5rem;
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textBook}
  color: grey;
  margin-top: 0.5rem;
`
