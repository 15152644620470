import React, { FC } from 'react'

import { SimpleCardProps } from '../SimpleCard'
import { ParcelCardProps } from '../ParcelCard'
import { ActionButtonProps } from '../ActionButton'
import { ParcelEditFormProps } from '../forms/ParcelEditForm'
import { ActionButtonScheme, ActionButtonSize } from '../ActionButton/types'

import * as SC from './styled'

export type ExponentParcelsProps = {
  className?: string
  list?: {
    addButton?: ActionButtonProps
    empty?: string
    linkText?: string
    onClick?: () => void
    title?: string
    parcels?: ParcelCardProps[]
  }
  add?: {
    title?: string
    text?: string
    card?: SimpleCardProps
    received?: string
    date?: string
  }
  edit?: {
    form?: ParcelEditFormProps
    deleteButton?: ActionButtonProps
  }
}

const ExponentParcels: FC<ExponentParcelsProps> = ({ className, list, add, edit }) => {
  return list ? (
    <SC.Container className={className}>
      {list.addButton && (
        <SC.AddButon {...list.addButton} variantScheme={ActionButtonScheme.Secondary} />
      )}
      {list?.parcels?.length && list.parcels.length !== 0 ? (
        <>
          {list.title && <SC.ListTitle text={list.title} tag="h5" />}
          <SC.ListCards>
            {list.parcels.map((parcel, index) => (
              <SC.ListCard key={`ListCard-${index}`} {...parcel} />
            ))}
          </SC.ListCards>
        </>
      ) : (
        list.empty && (
          <SC.Empty>
            {list.empty}
            {list.linkText && <SC.LinkEmpty text={list.linkText} onClick={list.onClick} />}
          </SC.Empty>
        )
      )}
    </SC.Container>
  ) : add ? (
    <SC.Container className={className}>
      {add.title && <SC.AddTitle text={add.title} tag="h1" />}
      {add.text && <SC.Text>{add.text}</SC.Text>}
      {add.card && <SC.AddCard {...add.card} />}
      {(add.received || add.date) && (
        <SC.AddInfos>
          {add.received && <SC.AddReceived>{add.received}</SC.AddReceived>}
          {add.date && <SC.AddDate>{add.date}</SC.AddDate>}
        </SC.AddInfos>
      )}
    </SC.Container>
  ) : edit ? (
    <SC.Container className={className}>
      {edit.form && <SC.EditForm {...edit.form} />}
      {edit.deleteButton && (
        <SC.DeleteButon
          variantScheme={ActionButtonScheme.Primary}
          variantSize={ActionButtonSize.Small}
          {...edit.deleteButton}
        />
      )}
    </SC.Container>
  ) : null
}

export default ExponentParcels
