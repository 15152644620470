import styled from 'styled-components'

import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'
import TabsScrollable from '../../components/TabsScrollable'
import Modal from '../../components/Modal'
import LoungeCard from '../../components/LoungeCard'
import ActionButton from '../../components/ActionButton'

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-block: 1.2rem 2.4rem;
`
export const TopContent = styled(StyledContentWrapper)`
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem 2.4rem;
  }
`
export const BottomContent = styled(StyledContentWrapper)`
  padding-block: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`
export const StyledLoungeCard = styled(LoungeCard)`
  margin-bottom: 1rem;
  border-radius: 0px;
`
export const NoteModal = styled(Modal)`
  overflow: auto;
`

export const Title = styled(Heading)`
  margin: 0;

  ${breakpoints.up('tablet')} {
  }
`

export const NoteTagContainer = styled.div`
  display: flex;
  width: '100%';
  margin-top: 12px;
  flex-direction: 'column';
  gap: 12px;

  ${breakpoints.up('tablet')} {
    flex-direction: 'row';
  }
`
export const Country = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.silver};

  &:not(:first-child) {
    margin-top: 0.2rem;
  }

  ${breakpoints.up('tablet')} {
  }
`

export const Infos = styled.ul`
  ${({ theme }) => theme.textStyles.titleH5}
  list-style: none;
  margin: 0;
  padding: 0;

  &,
  & li {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  & li:not(:first-child)::before {
    content: '';
    flex: 0 0 auto;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.mineShaft};
  }
`

export const StyledTabs = styled(TabsScrollable)`
  // this is possibly garbage
  max-width: calc(100vw - var(--scrollbar-width, 0px));

  ${breakpoints.up('tablet')} {
    max-width: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`

export const Separator = styled.hr`
  align-self: stretch;
  border: 0;
  margin: 0;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.tundora};

  ${breakpoints.up('tablet')} {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ReturnActionButton = styled(ActionButton)`
  padding: 0 25px;

  & span {
    flex: none;
  }
`
