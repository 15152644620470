import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { router, routesPath } from '../../router'
import { TabsScrollableProps } from '../../components/TabsScrollable'
import { UserRole } from '../../graphql/generated/graphql'

export const useExponentSheetTabsProps = (
  exposantId: string | undefined,
  apiId: string | undefined,
  currentTab: 'collection' | 'colis' | 'infos' | 'note' | 'archive',
  userRole?: UserRole,
  onReload?: () => void
): TabsScrollableProps => {
  const navigate = useNavigate()

  const tabs = useMemo(() => {
    const links = [
      {
        text: 'Collection',
        onClick: () => {
          const link = router(routesPath.exponentSheet, {
            exposantId,
            apiId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'collection' ? true : false,
      },
      {
        text: 'Informations générales',
        onClick: () => {
          const link = router(routesPath.exponentSheetInfos, {
            exposantId,
            apiId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'infos' ? true : false,
      },
      {
        text: 'Note interne',
        onClick: () => {
          const link = router(routesPath.exponentSheetInternalNote, {
            exposantId,
            apiId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'note' ? true : false,
      },
      {
        text: 'Colis',
        onClick: () => {
          const link = router(routesPath.exponentSheetParcels, {
            exposantId,
            apiId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'colis' ? true : false,
      },
      {
        text: 'Participations salon',
        onClick: () => {
          const link = router(routesPath.exponentSheetArchive, {
            exposantId,
            apiId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'archive' ? true : false,
      },
    ]

    const filteredLinks = links.filter((link) => {
      if (
        link.text === 'Participations salon' &&
        !['SUPER_ADMIN', 'ADMIN', 'REX', 'FASHION_EXPERT', 'MODE_PV', 'MARKETPLACE'].includes(
          userRole as string
        )
      ) {
        return false
      }

      return true
    })

    return filteredLinks
  }, [apiId, currentTab, exposantId, navigate, onReload, userRole])

  return { tabs }
}
