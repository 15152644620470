import styled from 'styled-components'

import Heading from '../../components/Heading'
import { fonts } from '../../theme'
import Icon from '../../components/Icon'
import ActionButton from '../../components/ActionButton'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
`
export const Text = styled.div`
  ${fonts.RobotoExtraLight};
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
`

export const TextFile = styled.div`
  ${fonts.RobotoRegular};
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
`

export const UploadButton = styled(ActionButton)`
  align-self: center;
`

export const FormInput = styled.form`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const Input = styled.input`
  ${fonts.RobotoRegular};
  border: none;
  width: 50px;
  padding: 12px 0px 12px 12px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.concrete};
`

export const InputContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.concrete};
  width: fit-content;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const IncrementIcon = styled(Icon)`
  height: 12px;
  width: 12px;
  transform: rotate(180deg);
`

export const DecrementIcon = styled(Icon)`
  height: 12px;
  width: 12px;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.gray};
  }

  &:active {
    transform: scale(0.95);
  }
`
