import React, { FC } from 'react'
import dayjs from 'dayjs'

import { ActionButtonProps } from '../ActionButton'
import { Icons } from '../Icon'
import { ActionButtonScheme } from '../ActionButton/types'

import * as SC from './styled'

export type ParcelCardProps = {
  className?: string
  name?: string
  titleTag?: React.ElementType
  createdAt?: string
  id?: string
  samplesDistinct?: number
  samplesTotal?: number
  updatedAt?: string
  user?: {
    firstName?: string
    lastName?: string
    id?: string
  }
  edit?: ActionButtonProps
}

const ParcelCard: FC<ParcelCardProps> = ({
  className,
  name,
  titleTag,
  createdAt,
  edit,
  samplesDistinct,
  samplesTotal,
  user,
}) => {
  return (
    <SC.Container className={className}>
      {(name || createdAt) && (
        <SC.Header>
          {name && (
            <SC.Title as={titleTag ?? 'h3'} text>
              {name}
            </SC.Title>
          )}
          {createdAt && <SC.Date>{dayjs(createdAt).format('DD.MM.YYYY')}</SC.Date>}
        </SC.Header>
      )}
      {samplesDistinct !== 0 && samplesTotal !== 0 && (
        <SC.Contents>
          {samplesTotal && (
            <li>
              <strong>{samplesTotal}</strong>
              <span>{`Echantillon${samplesTotal <= 1 ? '' : 's'}`}</span>
            </li>
          )}
          {samplesDistinct && (
            <li>
              <strong>{samplesDistinct}</strong>
              <span>{`Echantillon${samplesDistinct <= 1 ? '' : 's'} distinct${
                samplesDistinct <= 1 ? '' : 's'
              }`}</span>
            </li>
          )}
        </SC.Contents>
      )}
      {(user || edit) && (
        <SC.Footer $withSeparator={true}>
          {user && (
            <SC.Received>
              Réceptionné par {user?.lastName} {user?.firstName}
            </SC.Received>
          )}
          {edit && (
            <SC.Edit
              {...edit}
              variantScheme={ActionButtonScheme.Link}
              rightIconProps={{ icon: Icons.pencil, width: 16, height: 16 }}
            />
          )}
        </SC.Footer>
      )}
    </SC.Container>
  )
}

export default ParcelCard
