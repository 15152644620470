import styled from 'styled-components'

import breakpoints from '../../theme/breakpoints'
import Heading from '../Heading'
import ActionButton from '../ActionButton'
import SimpleCard from '../SimpleCard'
import ParcelCard from '../ParcelCard'
import ParcelEditForm from '../forms/ParcelEditForm'
import Link from '../Link'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`

export const LinkEmpty = styled(Link)`
  text-decoration: underline;
  font-weight: bold;
`

export const AddButon = styled(ActionButton)`
  width: 100%;
  justify-content: flex-end;
`
export const AddTitle = styled(Heading)`
  margin: 0;
`
export const ListTitle = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
`
export const Text = styled.p`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.mineShaft};
`
export const Empty = styled.p`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
`
export const AddCard = styled(SimpleCard)`
  max-width: none;
`
export const AddInfos = styled.div`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.mineShaft};
  display: flex;
  flex-flow: column;
  align-items: flex-end;
`
export const AddReceived = styled.p``
export const AddDate = styled.time``
export const ListCards = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const ListCard = styled(ParcelCard)``
export const EditForm = styled(ParcelEditForm)``
export const DeleteButon = styled(ActionButton)`
  align-self: center;
`
