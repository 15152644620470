import styled from 'styled-components'

import SearchBar from '../../components/SearchBar'
import AlphabeticSort from '../../components/AlphabeticSort'
import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'
import ExponentList from '../../components/ExponentList'
import FiltersTags from '../../components/FiltersTags'

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-block: 1.2rem;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem;
  }
`

export const Title = styled(Heading)`
  margin: 0;
  ${breakpoints.up('tablet')} {
  }
`

export const Search = styled(SearchBar)`
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:first-child) {
      margin-top: 3.6rem;
    }
  }
`
export const LoaderContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:first-child) {
      margin-top: 2.4rem;
    }
  }
`

export const StyledExponentList = styled(ExponentList)``

export const StyledFiltersTags = styled(FiltersTags)`
  width: 100%;
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`

export const StyledAlphabeticSort = styled(AlphabeticSort)`
  margin-left: auto;
`
