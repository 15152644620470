import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const importExhibitorEbms = getMutationService({
  mutation: gql(`
    mutation importExhibitorEbms($filePath: String!) {
      importExhibitorEbms(filePath: $filePath)
    }
  `),
  transformer: (response) => {
    return response
  },
})
