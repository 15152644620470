import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

import FormInput from '../FormFieldInput'
import { inputStyle } from '../FormFieldInput/styled'

export const InputComponent = styled(NumericFormat)`
  ${inputStyle}
`

export const Input = styled(FormInput)`
  input:focus::placeholder {
    color: transparent;
  }
`
