import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import { actions } from '..'
import { getAuthCookie, setAuthCookie } from '../../helpers/CookieHelpers'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'

export default class AuthSagas {
  static *onInit() {
    const cookie = getAuthCookie()
    if (cookie) {
      yield put(actions.auth.setImpersonate(cookie?.impersonate ?? false))
      yield put(actions.auth.setToken(cookie?.token))
      const rs = yield* ApiSagas.call(services.auth.queries.authByToken)

      if (rs.errors) {
        yield put(actions.auth.authByTokenError(rs.errors))
        return
      }
      if (!rs.errors) {
        yield put(actions.auth.setUser(rs?.data.authByToken))
        yield put(actions.auth.setUserLastVisited(rs.data.authByToken.lastVisited))
        yield put(actions.auth.authByTokenSuccess())
      }
    }
  }

  static *onLoginRequest({ payload }: ReturnType<typeof actions.auth.loginRequest>) {
    const rs = yield* ApiSagas.call(services.auth.queries.login, payload)
    if (rs.errors) {
      yield put(actions.auth.loginError(rs.errors))
      return
    }
    if (rs.data) {
      if (rs.data.authByCredentials?.token.accessToken) {
        yield put(actions.auth.setUser(rs?.data.authByCredentials.user))
        yield put(actions.auth.setUserLastVisited(rs.data.authByCredentials.user.lastVisited))
        setAuthCookie({ token: rs.data.authByCredentials?.token.accessToken, impersonate: false })
        yield put(actions.auth.setToken(rs.data.authByCredentials?.token.accessToken))
      }
      yield put(actions.auth.loginSuccess())
    }
  }

  static *onAuthByToken() {
    const rs = yield* ApiSagas.call(services.auth.queries.authByToken)

    if (rs.errors) {
      yield put(actions.auth.authByTokenError(rs.errors))
      return
    }
    if (!rs.errors) {
      yield put(actions.auth.setUser(rs?.data.authByToken))
      yield put(actions.auth.setUserLastVisited(rs.data.authByToken.lastVisited))
      yield put(actions.auth.authByTokenSuccess())
    }
  }

  static *onAuthByAzure({ payload }: ReturnType<typeof actions.auth.onAuthByAzure>) {
    setAuthCookie({ token: payload.token, impersonate: false })
    yield put(actions.auth.setToken(payload.token))
    yield spawn(AuthSagas.onAuthByToken)
  }

  static *onFirstLoginRequest({ payload }: ReturnType<typeof actions.auth.authFirstLoginRequest>) {
    const rs = yield* ApiSagas.call(services.auth.mutations.authFirstLogin, payload)

    if (rs.errors) {
      yield put(actions.auth.authFirstLoginError(rs.errors))
      return
    }

    if (rs.data) {
      if (rs.data.authFirstLogin?.token.accessToken) {
        yield put(actions.auth.setUser(rs?.data.authFirstLogin.user))
        setAuthCookie({ token: rs.data.authFirstLogin?.token.accessToken, impersonate: false })
        yield put(actions.auth.setToken(rs.data.authFirstLogin?.token.accessToken))
      }
    }
  }

  static *onAuthSendForgotPasswordNotification({
    payload,
  }: ReturnType<typeof actions.auth.authSendForgotPasswordNotificationRequest>) {
    const rs = yield* ApiSagas.call(
      services.auth.mutations.authSendForgotPasswordNotification,
      payload
    )

    if (rs.errors) {
      yield put(actions.auth.authSendForgotPasswordNotificationError(rs.errors))
      return
    }

    if (rs.data) {
      yield put(actions.auth.setSuccessMessage('Un mail vous a été envoyé.'))
      yield put(actions.auth.authSendForgotPasswordNotificationSuccess())
    }
  }

  static *onAuthResetPassword({
    payload,
  }: ReturnType<typeof actions.auth.authResetPasswordRequest>) {
    const rs = yield* ApiSagas.call(services.auth.mutations.authResetPassword, payload)

    if (rs.errors) {
      yield put(actions.auth.authResetPasswordError(rs.errors))
      return
    }

    if (rs.data) {
      if (rs.data.authResetPassword?.token.accessToken) {
        yield put(actions.auth.setUser(rs?.data.authResetPassword.user))
        setAuthCookie({ token: rs.data.authResetPassword?.token.accessToken, impersonate: false })
        yield put(actions.auth.setToken(rs.data.authResetPassword?.token.accessToken))
        yield put(actions.auth.authResetPasswordSuccess())
      }
    }
  }

  static *onAuthUserSetLastVisited({
    payload,
  }: ReturnType<typeof actions.auth.authUserSetLastVisitedRequest>) {
    const rs = yield* ApiSagas.call(services.auth.mutations.userSetLastVisited, payload)

    if (rs.errors) {
      yield put(actions.auth.authUserSetLastVisitedError(rs.errors))
      return
    }

    if (rs.data) {
      yield put(actions.auth.authUserSetLastVisitedSuccess())
    }
  }

  static *onAuthCheckEmailExists({
    payload,
  }: ReturnType<typeof actions.auth.authCheckEmailExistsRequest>) {
    const rs = yield* ApiSagas.call(services.auth.queries.authCheckEmailExists, payload)

    if (rs.errors) {
      yield put(actions.auth.authCheckEmailExistsError(rs.errors))
      return
    }

    if (rs.data) {
      yield put(actions.auth.authCheckEmailExistsSuccess(rs.data.authCheckEmailExists))
    }
  }

  static *listeners() {
    yield all([
      takeEvery(actions.auth.loginRequest, this.onLoginRequest),
      takeEvery(actions.auth.authFirstLoginRequest, this.onFirstLoginRequest),
      takeEvery(actions.auth.authByTokenRequest, this.onAuthByToken),
      takeEvery(
        actions.auth.authSendForgotPasswordNotificationRequest,
        this.onAuthSendForgotPasswordNotification
      ),
      takeEvery(actions.auth.authResetPasswordRequest, this.onAuthResetPassword),
      takeEvery(actions.auth.onAuthByAzure, this.onAuthByAzure),
      takeEvery(actions.auth.authCheckEmailExistsRequest, this.onAuthCheckEmailExists),
      takeEvery(actions.auth.authUserSetLastVisitedRequest, this.onAuthUserSetLastVisited),
    ])
  }
}
