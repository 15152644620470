import React, { FC } from 'react'

import * as SC from './styled'

export type HeadingProps = {
  className?: string
  text: string
  tag: SC.Variant
  customTag?: any
}

const Heading: FC<HeadingProps> = ({ className, text, tag, customTag }) => {
  if (text !== 'ECHANTILLON') {
    return (
      <SC.Heading as={customTag ? customTag : tag} $variant={tag} className={className}>
        {text}
      </SC.Heading>
    )
  }

  return <></>
}

export default Heading
