import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const internalNoteUpdate = getMutationService({
  mutation: gql(`
    mutation internalNoteUpdate(
        $internalNoteId: ID!
        $body: String!
    ) {
        internalNoteUpdate(
            internalNoteId: $internalNoteId
            body: $body
        ) {
            id
            user {
                id
                email
            }
            body
            writtenAt
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
