import { css } from 'styled-components'

// TYPES

export enum FontWeights {
  thin = 100,
  extraLight = 200,
  light = 300,
  regular = 400,
  medium = 500,
  semiBold = 600,
  bold = 700,
  extraBold = 800,
  black = 900,
}

export enum FontStyles {
  normal = 'normal',
  italic = 'italic',
  oblique = 'oblique',
}

type Font = {
  basename: string
  fontFamily: string
  fontWeight?: FontWeights
  fontStyle?: FontStyles
  fallback?: string
}

enum FontKeys {
  GothamBold = 'GothamBold',
  GothamMedium = 'GothamMedium',
  GothamBook = 'GothamBook',
  RobotoExtraLight = 'RobotoExtraLight',
  RobotoLight = 'RobotoLight',
  RobotoLightItalic = 'RobotoLightItalic',
  RobotoRegular = 'RobotoRegular',
}

// DECLARATIONS

export const declarations: { [key in FontKeys]: Font } = {
  RobotoRegular: {
    basename: 'Roboto-Regular',
    fontFamily: 'Roboto',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  RobotoLight: {
    basename: 'Roboto-Light',
    fontFamily: 'Roboto',
    fontWeight: FontWeights.light,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  RobotoLightItalic: {
    basename: 'Roboto-LightItalic',
    fontFamily: 'Roboto',
    fontWeight: FontWeights.light,
    fontStyle: FontStyles.italic,
    fallback: 'sans-serif',
  },
  RobotoExtraLight: {
    basename: 'Roboto-ExtraLight',
    fontFamily: 'Roboto',
    fontWeight: FontWeights.extraLight,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  GothamBold: {
    basename: 'Gotham-Bold',
    fontFamily: 'Gotham',
    fontWeight: FontWeights.bold,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  GothamMedium: {
    basename: 'Gotham-Medium',
    fontFamily: 'Gotham',
    fontWeight: FontWeights.medium,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  GothamBook: {
    basename: 'Gotham-Book',
    fontFamily: 'Gotham',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
}

// @FONT-FACE

export const fontsFaces: any[] = Object.values(declarations)?.map(
  (declaration) => css`
    @font-face {
      font-family: ${declaration.fontFamily};
      font-display: swap;
      font-weight: ${declaration.fontWeight || FontWeights.regular};
      font-style: ${declaration.fontStyle || FontStyles.normal};
      src: url('/fonts/${declaration.basename}/${declaration.basename}.woff2') format('woff2');
    }
  `
)

// CSS

const fontCSS = (font: Font): string => `
    font-family: "${font.fontFamily}", ${font.fallback || 'sans-serif'};
    font-weight: ${font.fontWeight || FontWeights.regular};
    font-style: ${font.fontStyle || FontStyles.normal};
  `

const fonts: { [key in FontKeys]: string } = Object.keys(declarations)?.reduce(
  (acc, fontKey) => ({
    ...acc,
    [fontKey]: fontCSS(declarations[fontKey as FontKeys]),
  }),
  {} as any
)

export default fonts
