import styled from 'styled-components'

import Heading from '../../components/Heading'
import { breakpoints, fonts } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'
import Divider from '../../components/Divider'
import Steps from '../../components/Steps'
import SimpleRadio from '../../components/SimpleRadio'
import ActionButton from '../../components/ActionButton'
import CollapsibleColoredItem from '../../components/CollapsibleColored'
import ContentWithSteps from '../../components/ContentWithSteps'

export const Content = styled(ContentWithSteps)``

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const StyledSteps = styled(Steps)``

export const PageTitle = styled.h2`
  ${fonts.GothamBold};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-top: 0.7rem;
  ${breakpoints.up('tablet')} {
    padding-top: 4.4rem;
  }
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
  ${breakpoints.up('tablet')} {
    margin-top: 1rem;
  }
`

export const SubTitle = styled.div`
  font-size: 1.7rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.tundora};
`

export const Collapsible = styled(CollapsibleColoredItem)`
  margin-bottom: 1.2rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
`

export const StyledDivider = styled(Divider)``

export const Radio = styled(SimpleRadio)`
  background-color: ${({ theme }) => theme.colors.concrete};
  padding: 1rem;
  margin: 2.4rem 0 1.4rem 0;
`

export const NewForumButton = styled(ActionButton)`
  width: fit-content;
  margin: auto;
  margin-top: 2rem;
`
