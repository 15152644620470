import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { FormFieldSwitchSize } from '../../components/form/fields/FormFieldSwitch/types'
import { Icons } from '../../components/Icon'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import { UniverseRelationType, UserRole } from '../../graphql/generated/graphql'

import { SampleDataSmartFormValues, SampleDataSmartTemplateProps } from '.'

export const useSampleDataSmartTemplateProps = (): SampleDataSmartTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const ref = useRef<FormikProps<SampleDataSmartFormValues>>(null)
  const sampleData = useSelector(selectors.samples.sampleData)
  const sampleUpdate = useSelector(selectors.samples.sampleUpdate)
  const sample = useSelector(selectors.samples.sample)
  const config = useSelector(selectors.configuration.configurationInfo)
  const user = useSelector(selectors.auth.user)
  const collection = useSelector(selectors.exhibitors.collection)
  const configuration = useSelector(selectors.configuration.configurationInfo)
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)
  const [applyToAllCollection, setApplyToAllCollection] = useState<boolean>(false)
  const [routeCall, setRouteCall] = useState<string>('')
  const [selectionClicked, setSelectionClicked] = useState<boolean>(false)
  const [openApplyToAllCollection, setOpenApplyToAllCollection] = useState<boolean>(false)
  const universes = configuration?.dictionaries.find((item) => item.type === 'UNIVERSES')

  const setStoreData = useCallback(
    (route: string) => {
      let routeType = ''
      if (
        exhibitor &&
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.InputOperator &&
        (user?.role === UserRole.Marketplace ||
          user?.role === UserRole.ModePv ||
          user?.role === UserRole.Admin ||
          user?.role === UserRole.SuperAdmin)
      ) {
        routeType = `${route}Marketplace`
        setRouteCall(route)
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            routeType,
          })
        )
      } else if (
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.InputOperator &&
        user?.role !== UserRole.Marketplace
      ) {
        setRouteCall(route)
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            routeType: route,
          })
        )
      } else {
        setRouteCall(route)
        if (route === 'Fabrics' || route === 'Yarns') {
          routeType = 'Trainee'
        }
        if (route === 'Designs') {
          routeType = 'TraineeDesign'
        }
        if (route === 'Manufacturing' || route === 'Manufacturing Leather') {
          routeType = 'TraineeManuf'
        }
        if (route === 'Leather' || route === 'Accessories') {
          routeType = 'TraineeShort'
        }
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            routeType,
          })
        )
      }
      setSelectionClicked(true)
    },
    [dispatch, exhibitor, sampleData, user?.role]
  )

  useEffect(() => {
    if (sampleUpdate?.success && sampleId) {
      dispatch(actions.samples.getSampleByIdRequest({ sampleId }))
      dispatch(actions.samples.resetSampleUpdate())
      const link = router(sampleDataRoutes(sampleData, 'next'), {
        exposantId,
        sampleId,
      })
      navigate(link)
    }
  }, [dispatch, exposantId, navigate, sampleData, sampleId, sampleUpdate?.success])

  useEffect(() => {
    if (sampleData.routeType !== 'Smart' && selectionClicked) {
      const universeEbmsId = universes?.items.find((item) => item.value === routeCall)?.key

      if (collection && sampleId && universeEbmsId) {
        dispatch(
          actions.samples.getSampleFormByUniverseRequest({
            collectionId: collection?.id,
            universeEbmsId,
          })
        )
      }

      const universeRelation = {
        type: UniverseRelationType.SmartCreation,
        universeEbmsId: universeEbmsId,
      }
      let inputSampleUpdate = {}

      if (sample?.universeRelation?.universeEbmsId !== universeEbmsId) {
        inputSampleUpdate = { universeRelation, flagGroups: [] }
      } else {
        inputSampleUpdate = { universeRelation }
      }

      if (applyToAllCollection) {
        inputSampleUpdate = { ...inputSampleUpdate, universeRelationApplyToCollection: true }

        sampleId &&
          sample?.universe.ebmsId &&
          universeEbmsId &&
          dispatch(
            actions.samples.sampleUpdateWithUniverseRequest({
              sampleId: sampleId,
              universeEbmsId: sample.universe.ebmsId,
              subUniverseEbmsId: universeEbmsId,
            })
          )
      } else {
        inputSampleUpdate = { ...inputSampleUpdate, universeRelationApplyToCollection: false }
      }

      sampleId &&
        dispatch(
          actions.samples.sampleUpdateRequest({
            sampleId: sampleId,
            input: { ...inputSampleUpdate },
          })
        )
    }
  }, [
    collection,
    dispatch,
    exposantId,
    navigate,
    routeCall,
    sampleData,
    sampleId,
    selectionClicked,
    universes?.items,
    applyToAllCollection,
    sample?.universe?.ebmsId,
    sample?.universeRelation?.universeEbmsId,
  ])

  useEffect(() => {
    ref.current &&
      ref.current.setValues({
        allCollection: applyToAllCollection,
      })
  }, [applyToAllCollection])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link =
                sampleData.page === 'exponent'
                  ? router(routesPath.exponentSheet, {
                      exposantId,
                      apiId: config?.exhibitionActiveApiId.toString(),
                    })
                  : router(routesPath.sampleSheetData, {
                      sampleId,
                    })
              navigate(link)
            },
          },
        },
      },
      title: 'SMART CREATIONS',
      subtitle: "Pour continuer sélectionnez l'univers correspondant à l'échantillon.",
      buttons: [
        { text: 'Fabrics', onClick: () => setStoreData('Fabrics') },
        { text: 'Yarns', onClick: () => setStoreData('Yarns') },
        { text: 'Accessories', onClick: () => setStoreData('Accessories') },
        { text: 'Leather', onClick: () => setStoreData('Leather') },
      ],
      deleteModal: {
        textBold:
          "Attention, vous êtes sur le point de changer l'univers pour tous les échantillons de l'exposant !",
        text: `Si des données échantillons avaient été saisies via un autre univers, ces données seront perdues.`,
        secondText: `Si vous ne souhaitez modifier l'univers que pour cet échantillon, veuillez décocher "Appliquer à toute la collection"`,
        isOpen: openApplyToAllCollection,
        cancelButton: {
          text: 'Annuler',
          onClick: () => {
            setApplyToAllCollection(false)
            setOpenApplyToAllCollection(false)
          },
        },
        deleteButton: {
          text: 'Oui, valider',
          rightIconProps: { icon: Icons.check, width: 18 },
          onClick: () => {
            setOpenApplyToAllCollection(false)
          },
        },
      },
      formikForm: {
        innerRef: ref,
        initialValues: {
          allCollection: applyToAllCollection,
        } as SampleDataSmartFormValues,
        onSubmit: (values) => console.log(values),
        validateOnChange: true,
      },
      allCollection: {
        name: 'allCollection',
        inlineLabel: 'Appliquer à toute la collection',
        variantSize: FormFieldSwitchSize.Small,
        value: applyToAllCollection,
        onChange: (value) => {
          setApplyToAllCollection(value.target.value)
          setOpenApplyToAllCollection(value.target.value)
        },
      },
    }),
    [
      applyToAllCollection,
      config?.exhibitionActiveApiId,
      exposantId,
      layoutProps,
      navigate,
      openApplyToAllCollection,
      sampleData.page,
      sampleId,
      setStoreData,
    ]
  )
}
