import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router'
import { FormikContextType, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { router } from '../../router'
import { actions, selectors } from '../../redux'
import { returnSampleDataSteps } from '../../relay/DataSample/SampleDataSteps'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import {
  SampleFlagGroupInput,
  SampleFormGroup,
  SampleStatus,
} from '../../graphql/generated/graphql'
import { sampleDataGetKeyToUpdate } from '../../relay/DataSample/SampleDataGetKeyToUpdate'
import { CustomSampleFormItem, SampleDataAllInfo } from '../../types/sampleDataAllInfo'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { ModalVariant } from '../../components/Modal/types'
import { SampleDataTraineeFormValues, SampleDataTraineeTemplateProps } from '../SampleDataTrainee'
import { FormFieldSwitchSize } from '../../components/form/fields/FormFieldSwitch/types'

export const useSampleDataMaterialTemplateProps = (
  title?: string,
  formValues?: SampleFormGroup,
  performanceCodes?: SampleFormGroup
): SampleDataTraineeTemplateProps => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()
  const config = useSelector(selectors.configuration.configurationInfo)
  const refresh = useSelector(selectors.auth.authByToken)
  const sampleData = useSelector(selectors.samples.sampleData)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const ref = useRef<FormikProps<SampleDataTraineeFormValues>>(null)
  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const sample = useSelector(selectors.samples.sample)
  const collection = useSelector(selectors.exhibitors.collection)
  const [search, setSearch] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<any>(formValues ? formValues.items : [])
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)
  const [checkboxesValues, setCheckboxesValues] = useState<SampleDataTraineeFormValues>({})
  const [applyToAllCollection, setApplyToAllCollection] = useState<boolean>(false)
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const [isValid, setIsValid] = useState<boolean>(false)
  const [forceRefresh, setForceRefresh] = useState<boolean>(true)
  const [isElasthaneCanceled, setIsElasthaneCanceled] = useState<boolean>(false)
  // Disabled popin
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isConfirmationElasthanneDeleted, setIsConfirmationElasthanneDeleted] = useState<boolean>(
    false
  )
  // Disabled popin
  // const isBiStretchPresent =
  //   sampleDataAllInfo.performanceCode.items &&
  //   sampleDataAllInfo.performanceCode.items.filter((item) => item.name === 'Bi-stretch')[0]
  // const isMonoStretchPresent =
  //   sampleDataAllInfo.performanceCode.items &&
  //   sampleDataAllInfo.performanceCode.items.filter((item) => item.name === 'Mono-stretch')[0]
  const elastanneItem =
    formValues &&
    formValues.items &&
    formValues.items.filter((item) => item.name === 'Élasthanne')[0]

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  // Force loader to make reloading more smooth
  useEffect(() => {
    const timerId = setTimeout(() => {
      setForceRefresh(false)
    }, 500)

    return () => clearTimeout(timerId)
  }, [])

  const setFlagsItems = (data: any) => {
    const flagGroups: SampleFlagGroupInput[] = []
    if (data) {
      for (const key in data) {
        if (data[key] && data[key].items) {
          if (Array.isArray(data[key].items)) {
            const items = data[key]
            flagGroups.push({
              groupId: data[key].id,
              applyToCollection: data[key].applyToAllCollection,
              flagItems: items.items.map((flagItem: any) => ({
                itemId: flagItem.id,
                value: flagItem.value ? flagItem.value : null,
              })),
            })
          }
        }
      }
      return flagGroups
    }
  }

  const updateSample = useCallback(
    (data: SampleDataAllInfo) => {
      if (sampleId) {
        dispatch(
          actions.samples.sampleUpdateRequest({
            sampleId: sampleId,
            input: {
              flagGroups: setFlagsItems(data),
            },
          })
        )
      }
    },
    [sampleId, dispatch]
  )

  const setStoreData = useCallback(
    (values: SampleDataTraineeFormValues) => {
      const keyToUpdate: any = sampleDataGetKeyToUpdate(sampleData)
      const filteredItemsCheckboxes =
        values.checkboxes &&
        Object.keys(values.checkboxes).filter(
          (key) => values.checkboxes && values.checkboxes[key].checked === true
        )
      const filteredItems: CustomSampleFormItem[] =
        formValues?.items
          .filter((item) => {
            const itemName = item.name.toLowerCase()
            return (
              values.checkboxes &&
              values.checkboxes[itemName] &&
              values.checkboxes[itemName].checked
            )
          })
          .map((item) => ({
            ...item,
            value:
              values.checkboxes &&
              values.checkboxes[item.name.toLowerCase()].percentage?.toString(),
          })) ?? []

      const updatedSampleDataAllInfo: SampleDataAllInfo = {
        ...sampleDataAllInfo,
        material: {
          id: formValues?.id,
          items: filteredItems,
          applyToAllCollection,
        },
      }
      dispatch(
        actions.samples.setSampleData({
          ...sampleData,
          [keyToUpdate]: filteredItemsCheckboxes,
        })
      )
      updateSample(updatedSampleDataAllInfo)
      dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
    },

    [
      applyToAllCollection,
      dispatch,
      formValues?.id,
      formValues?.items,
      sampleData,
      sampleDataAllInfo,
      updateSample,
    ]
  )

  const updatePerformanceCode = useCallback(
    (deletePerfCode: boolean) => {
      const monoStretch =
        performanceCodes && performanceCodes.items.filter((item) => item.name === 'Mono-stretch')
      const isAucunChecked =
        performanceCodes &&
        performanceCodes.items.filter(
          (item) =>
            item.name === 'aucun' ||
            item.name === 'aucune' ||
            item.name === 'Aucun' ||
            item.name === 'Aucune' ||
            item.name === 'AUCUNE' ||
            item.name === 'AUCUN'
        )

      const previousPerformanceCodes = sampleDataAllInfo.performanceCode
      // Disabled popin
      // const previousMaterial = sampleDataAllInfo.material

      const compiledPerformanceCodes = monoStretch
        ? [...(previousPerformanceCodes?.items || []), ...monoStretch]
        : previousPerformanceCodes?.items || []

      let updatedSampleDataAllInfo: SampleDataAllInfo = {
        ...sampleDataAllInfo,
        performanceCode: {
          ...sampleDataAllInfo.performanceCode,
          items: compiledPerformanceCodes,
        },
      }

      const previousPerformanceCodesSampleData = sampleData.performanceCode
      let compiledPerformanceCodesSampleData = [
        ...(previousPerformanceCodesSampleData || []),
        'Mono-stretch',
      ]
      let compiledMaterialSampleData = sampleData.material
      if (
        isAucunChecked !== undefined &&
        updatedSampleDataAllInfo.performanceCode.items &&
        compiledPerformanceCodesSampleData
      ) {
        updatedSampleDataAllInfo.performanceCode.items = updatedSampleDataAllInfo.performanceCode.items.filter(
          (item) =>
            item.name !== 'aucun' &&
            item.name !== 'aucune' &&
            item.name !== 'Aucun' &&
            item.name !== 'Aucune' &&
            item.name !== 'AUCUNE' &&
            item.name !== 'AUCUN'
        )
        compiledPerformanceCodesSampleData = compiledPerformanceCodesSampleData.filter(
          (item) =>
            item !== 'aucun' &&
            item !== 'aucune' &&
            item !== 'Aucun' &&
            item !== 'Aucune' &&
            item !== 'AUCUNE' &&
            item !== 'AUCUN'
        )
        // Disabled popin
        // Supprimer "Bi-stretch" et "Mono-stretch" si deletePerfCode est true
        // if (
        //   deletePerfCode &&
        //   previousMaterial.items &&
        //   sampleData.material &&
        //   sampleData.performanceCode
        // ) {
        //   // Crée une nouvelle liste d'items pour performanceCode sans 'Bi-stretch' et 'Mono-stretch'
        //   const newPerformanceCodeItems = updatedSampleDataAllInfo.performanceCode.items.filter(
        //     (item) => item.name !== 'Bi-stretch' && item.name !== 'Mono-stretch'
        //   )

        //   // Crée une nouvelle liste d'items pour material sans 'Élasthanne'
        //   const newMaterialItems = previousMaterial.items.filter(
        //     (item) => item.name !== 'Élasthanne' && item.name !== 'élasthanne'
        //   )
        //   // Met à jour updatedSampleDataAllInfo avec les nouvelles listes d'items
        //   updatedSampleDataAllInfo = {
        //     ...updatedSampleDataAllInfo,
        //     performanceCode: {
        //       ...updatedSampleDataAllInfo.performanceCode,
        //       items: newPerformanceCodeItems,
        //     },
        //     material: {
        //       ...updatedSampleDataAllInfo.material,
        //       items: newMaterialItems,
        //     },
        //   }
        //   compiledMaterialSampleData = sampleData.material.filter(
        //     (item) => item !== 'Élasthanne' && item !== 'élasthanne'
        //   )
        //   compiledPerformanceCodesSampleData = sampleData.performanceCode.filter(
        //     (item) => item !== 'Bi-stretch' && item !== 'Mono-stretch'
        //   )
        // }
        if (!deletePerfCode && sampleData.material && elastanneItem) {
          const previousMaterials = sampleDataAllInfo.material
          const elastanneExists =
            previousMaterials &&
            previousMaterials.items &&
            previousMaterials.items.some((item) => item.id === elastanneItem.id)

          // Crée une nouvelle liste de matériaux, ajoutant elastanneItem si il n'existe pas déjà
          const updatedMaterialsItems =
            elastanneExists || !previousMaterials.items
              ? previousMaterials.items
              : [...previousMaterials.items, elastanneItem]

          // Création d'un nouvel objet qui contient la liste mise à jour
          const updatedMaterials = {
            ...previousMaterials, // Conserve les autres propriétés de previousMaterials inchangées
            items: updatedMaterialsItems, // Utilise la nouvelle liste d'éléments
          }
          updatedSampleDataAllInfo = {
            ...updatedSampleDataAllInfo,
            material: {
              ...updatedSampleDataAllInfo.material,
              items: updatedMaterials.items,
            },
          }
          compiledMaterialSampleData = sampleData.material ?? []

          // Ajoute 'élasthanne' au tableau s'il n'est pas déjà présent.
          if (!compiledMaterialSampleData.includes('élasthanne')) {
            compiledMaterialSampleData = [...compiledMaterialSampleData, 'élasthanne']
          }
        }
      }

      dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
      dispatch(
        actions.samples.setSampleData({
          ...sampleData,
          performanceCode: compiledPerformanceCodesSampleData,
          material: compiledMaterialSampleData,
        })
      )
      updateSample(updatedSampleDataAllInfo)
    },
    [dispatch, elastanneItem, performanceCodes, sampleData, sampleDataAllInfo, updateSample]
  )

  const checkBoxesProps = useMemo(() => {
    const checkedStuff = checkboxesValues.checkboxes
    const checkboxes =
      formValues &&
      formValues.items.map((item) => ({
        name: item.name.toLowerCase(),
        checked: {
          label: item.name,
          name: `checkboxes.${item.name.toLowerCase()}.checked`,
        },
      }))
    return (
      checkboxes &&
      checkboxes.map((checkbox) =>
        checkedStuff?.[checkbox.name]
          ? { ...checkbox, percentage: { name: `checkboxes.${checkbox.name}.percentage` } }
          : checkbox
      )
    )
  }, [checkboxesValues.checkboxes, formValues])

  const handleContextUpdate = useCallback(
    (context?: FormikContextType<SampleDataTraineeFormValues>) => {
      if (context?.values && JSON.stringify(context.values) !== JSON.stringify(formValues)) {
        setCheckboxesValues(context.values)
      }
    },
    [formValues]
  )

  const transformDataToCheckboxes = useCallback((data: CustomSampleFormItem[] | undefined) => {
    const checkboxes: { [key: string]: any } = {}

    // Disable popin
    // if (elastanneItem && (isBiStretchPresent || isMonoStretchPresent)) {
    //   checkboxes[elastanneItem.name.toLowerCase()] = {
    //     name: elastanneItem.name.toLowerCase(),
    //     checked: true,
    //     percentage: undefined,
    //   }
    // }

    // Ajouter les autres éléments du tableau data
    if (data) {
      for (const item of data) {
        checkboxes[item.name.toLowerCase()] = {
          name: item.name.toLowerCase(),
          checked: true,
          percentage: item.value,
        }
      }
    }
    //End of data mapping

    return checkboxes
  }, [])

  useEffect(() => {
    if (checkBoxesProps) {
      let filteredItems = checkBoxesProps
      if (search !== '') {
        filteredItems = checkBoxesProps.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      }
      setFilteredItems(filteredItems)
    }
  }, [checkBoxesProps, search])

  useEffect(() => {
    if (sampleDataAllInfo) {
      const materialInfo = sampleDataAllInfo['material']
      const performanceCodesInfo = sampleDataAllInfo['performanceCode']
      const aucunPerfCode =
        performanceCodes &&
        performanceCodes.items.filter(
          (item) =>
            item.name === 'aucun' ||
            item.name === 'aucune' ||
            item.name === 'Aucun' ||
            item.name === 'Aucune' ||
            item.name === 'AUCUNE' ||
            item.name === 'AUCUN'
        )
      // Pass perf codes to so "none" value if no perf code left
      if (performanceCodesInfo.items?.length === 0) {
        const updatedSampleDataAllInfo: SampleDataAllInfo = {
          ...sampleDataAllInfo,
          performanceCode: {
            ...sampleDataAllInfo.performanceCode,
            items: aucunPerfCode,
          },
        }
        dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            performanceCode: aucunPerfCode && [aucunPerfCode[0].name],
          })
        )
      }

      if (sample?.applyToCollectionUniverse) {
        setApplyToAllCollection(sample?.applyToCollectionUniverse)
        return
      }

      if (
        materialInfo &&
        typeof materialInfo === 'object' &&
        'applyToAllCollection' in materialInfo &&
        materialInfo.applyToAllCollection
      ) {
        setApplyToAllCollection(materialInfo.applyToAllCollection)
      } else {
        setApplyToAllCollection(false)
      }
    }
  }, [dispatch, performanceCodes, sampleData, sampleDataAllInfo, sample?.applyToCollectionUniverse])

  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, dispatch, sample])

  const resetFormData = useCallback((shouldDeleteElasthanne: boolean) => {
    const checkboxes = ref.current?.values.checkboxes || {}

    if (shouldDeleteElasthanne) {
      // Si shouldDeleteElasthanne est true, supprime "élasthanne" de l'objet
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { élasthanne, ...updatedCheckboxes } = checkboxes
      return updatedCheckboxes
    } else {
      // Si shouldDeleteElasthanne est false, s'assurer que "élasthanne" est présent et checked à true
      return {
        ...checkboxes,
        élasthanne: { ...checkboxes.élasthanne, checked: true },
      }
    }
  }, [])

  return useMemo(
    () => ({
      title: title,
      isPending: forceRefresh,
      search: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      allCollection: {
        name: 'allCollection',
        inlineLabel: 'Appliquer à toute la collection',
        variantSize: FormFieldSwitchSize.Small,
        value: applyToAllCollection,
        onChange: (value) => {
          setApplyToAllCollection(value.target.value)
        },
      },
      layoutProps: {
        steps: {
          steps: returnSampleDataSteps(sampleData, exposantId, sampleId),
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(sampleDataRoutes(sampleData, 'prev'), {
                exposantId,
                sampleId,
                apiId: collection?.exhibition.apiId as string,
              })
              navigate(link)
            },
          },
          note:
            collection?.internalNotes && collection?.internalNotes.length !== 0
              ? {
                  rounded: true,
                  number: collection?.internalNotes
                    ? collection?.internalNotes.length.toString()
                    : '0',
                  onClick: () => {
                    setIsNoteOpen(true)
                  },
                }
              : undefined,
          next: {
            text: 'Suivant',
            disabled: !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
            rightIconProps: {
              icon: Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
          nextPlus: {
            text: 'Rafraichir',
            isPending: refresh.pending,
            disabled: refresh.pending,
            onClick: () => {
              dispatch(actions.auth.authByTokenRequest())
            },
            rightIconProps: {
              icon: Icons.link,
              width: 18,
              height: 18,
            },
          },
        },
        marketplaceWarning:
          marketplaceDateDaysLeft && marketplaceDateHoursLeft
            ? {
                number: `J - ${marketplaceDateDaysLeft}`,
                text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
                redBg: true,
              }
            : undefined,

        title: `${sampleData.exhibitorRef} #${sampleId}`,
        notes: {
          title: "Note interne sur l'exposant",
          notes:
            collection && collection.internalNotes && collection.internalNotes.length !== 0
              ? collection.internalNotes.map((note) => ({
                  text: note.body,
                  author: {
                    fullName:
                      collection?.internalNotes.length !== 0
                        ? `par ${note.user.lastName} ${note.user.firstName}`
                        : '',
                    date:
                      collection?.internalNotes.length !== 0
                        ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                        : '',
                  },
                }))
              : [],
          tags: {
            newTag: collection?.exhibitor?.isNew,
            returnTag: collection?.samplesToReturn !== 0,
          },
          onClose: () => setIsNoteOpen(false),
        },
        noteModal: {
          onClose: () => setIsNoteOpen(false),
          isOpen: isNoteOpen,
          variant: ModalVariant.Small,
        },
        deleteModal: !isConfirmationElasthanneDeleted
          ? // Disabled popin
            // {
            //     text: `Vous venez de désélectionner la composition “Élasthanne”. En cliquant sur “Valider”, les codes de performances Mono-Stretch ou Bi-Stretch associés à l'échantillon seront automatiquement désélectionnés.`,
            //     isOpen: isConfirmationOpen,
            //     cancelButton: {
            //       text: 'Annuler',
            //       onClick: () => {
            //         ref.current?.setFieldValue('checkboxes', resetFormData(false))
            //         setIsConfirmationOpen(false)
            //         setIsElasthaneCanceled(false)
            //       },
            //     },
            //     deleteButton: {
            //       text: 'Oui, valider',
            //       rightIconProps: { icon: Icons.check, width: 18 },
            //       onClick: () => {
            //         updatePerformanceCode(true)
            //         setIsConfirmationElasthanneDeleted(false)
            //         setIsConfirmationOpen(false)
            //       },
            //     },
            //   }
            {
              textBold:
                "Pour la composition Elasthanne, le code de performance associé n'est pas renseigné (Mono-Stretch ou Bi-Stretch).",
              text: `En cliquant sur "Valider" le code de performance Mono-Stretch sera automatiquement ajouté à l'échantillon.`,
              isOpen: isConfirmationOpen,
              cancelButton: {
                text: 'Annuler',
                onClick: () => {
                  ref.current?.setFieldValue('checkboxes', resetFormData(true))
                  setIsConfirmationOpen(false)
                  setIsElasthaneCanceled(false)
                },
              },
              deleteButton: {
                text: 'Oui, valider',
                rightIconProps: { icon: Icons.check, width: 18 },
                onClick: () => {
                  updatePerformanceCode(false)
                },
              },
            }
          : undefined,
        ...layoutProps,
      },
      notes: {
        title: "Note interne sur l'exposant",
        notes:
          collection && collection.internalNotes && collection.internalNotes.length !== 0
            ? collection.internalNotes.map((note) => ({
                text: note.body,
                author: {
                  fullName:
                    collection?.internalNotes.length !== 0
                      ? `par ${note.user.lastName} ${note.user.firstName}`
                      : '',
                  date:
                    collection?.internalNotes.length !== 0
                      ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                      : '',
                },
              }))
            : [],
        tags: {
          newTag: collection?.exhibitor?.isNew,
          returnTag: collection?.samplesToReturn !== 0,
        },
        onClose: () => setIsNoteOpen(false),
      },
      noteModal: {
        onClose: () => setIsNoteOpen(false),
        isOpen: isNoteOpen,
        variant: ModalVariant.Small,
      },
      form: {
        formikForm: {
          title: title,
          innerRef: ref,
          initialValues: {
            checkboxes: transformDataToCheckboxes(sampleDataAllInfo['material'].items),
          } as SampleDataTraineeFormValues,
          validationSchema: Yup.object().shape({
            checkboxes: Yup.object().required(),
          }),
          onSubmit: (values) => {
            setStoreData(values)
            const link = router(sampleDataRoutes(sampleData, 'next'), {
              exposantId,
              sampleId,
            })
            navigate(link)
          },
          onContextUpdate: (context) => {
            const isBiStretchPresent =
              sampleDataAllInfo.performanceCode.items &&
              sampleDataAllInfo.performanceCode.items.filter(
                (item) => item.name === 'Bi-stretch'
              )[0]
            const isMonoStretchPresent =
              sampleDataAllInfo.performanceCode.items &&
              sampleDataAllInfo.performanceCode.items.filter(
                (item) => item.name === 'Mono-stretch'
              )[0]
            const elasthaneCheckbox =
              context.values.checkboxes &&
              context.values.checkboxes.élasthanne &&
              context.values.checkboxes.élasthanne.checked

            if (elasthaneCheckbox && !isElasthaneCanceled) {
              if (!isMonoStretchPresent && !isBiStretchPresent) setIsConfirmationOpen(true)
              else setIsConfirmationOpen(false)
            }
            // Disabled popin
            //  else {
            //   if ((isMonoStretchPresent || isBiStretchPresent) && !isElasthaneCanceled) {
            //     setIsConfirmationElasthanneDeleted(true)
            //     setIsConfirmationOpen(true)
            //   }
            // }
            if (context.values.checkboxes) {
              const allCheckedFalse = Object.values(context.values.checkboxes).every(
                (item) => item.checked === false
              )
              setIsValid(!allCheckedFalse)
            } else {
              setIsValid(false)
            }

            handleContextUpdate(context)
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        checkboxes:
          checkBoxesProps && search === '' ? checkBoxesProps : search !== '' ? filteredItems : [],
        checkboxesChecked:
          ref.current?.values.checkboxes &&
          Object.keys(ref.current?.values.checkboxes).filter(
            (key) =>
              ref.current?.values.checkboxes && ref.current?.values.checkboxes[key].checked === true
          ),
      },
      onClose: () => undefined,
      openImage: false,
    }),
    [
      title,
      forceRefresh,
      search,
      applyToAllCollection,
      sampleData,
      exposantId,
      sampleId,
      collection,
      isValid,
      refresh.pending,
      marketplaceDateDaysLeft,
      marketplaceDateHoursLeft,
      isNoteOpen,
      isConfirmationElasthanneDeleted,
      isConfirmationOpen,
      layoutProps,
      transformDataToCheckboxes,
      sampleDataAllInfo,
      checkBoxesProps,
      filteredItems,
      navigate,
      dispatch,
      resetFormData,
      updatePerformanceCode,
      setStoreData,
      isElasthaneCanceled,
      handleContextUpdate,
    ]
  )
}
