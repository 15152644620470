import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitions = getQueryService({
  query: gql(`
    query exhibitions($q: String, $first: Int!, $page: Int) {
      exhibitions(q: $q, first: $first, page: $page) {
        paginatorInfo {
          total
          perPage
          lastPage
          currentPage
        }
        data {
          id
          apiId
          name
          seasonCode
          seasonYear
          shootingFolder
          dateStart
          dateEnd
          dateSampleStart
          dateSampleEnd
          forums {
            id
            name
            color
            subForums {
              id
              name
            }
          }
        }
      }
    }
`),
  transformer: (response) => {
    return response
  },
})
