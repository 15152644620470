import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Icons } from '../../components/Icon'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { router, routesPath } from '../../router'
import { sampleEditRouting } from '../../relay/SampleEdit/SampleEditRouting'
import { selectors, actions } from '../../redux'
import { getUniverseEbmsId } from '../../relay/SampleEdit/GetUniverseEbmsId'

import { SampleSheetsTemplateProps } from '.'

export const useSampleSheetsTemplateProps = (): SampleSheetsTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [routeType, setRouteType] = useState<string | undefined>(undefined)
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)

  const config = useSelector(selectors.configuration.configurationInfo)

  const universes = config?.dictionaries.find((item) => item.type === 'UNIVERSES')?.items

  useEffect(() => {
    if (shouldRedirect && routeType && universes) {
      dispatch(
        actions.samples.getFlagGroupsByUniverseRequest({
          universeEbmsId: getUniverseEbmsId(routeType, universes) as string,
        })
      )
      const link = router(sampleEditRouting(routeType, 'next'), {
        routeType,
      })
      navigate(link)
    }
  }, [dispatch, navigate, routeType, shouldRedirect, universes])

  const setRouteOnClick = (routeType: string) => {
    setRouteType(routeType)
    setShouldRedirect(true)
  }

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            onClick: () => {
              const link = router(routesPath.params)
              navigate(link)
            },
            rounded: true,
          },
        },
      },
      title: 'Fiches échantillons',
      subtitle: 'Sélectionnez l’univers',
      buttons: [
        {
          text: 'Accessories',
          onClick: () => setRouteOnClick('Accessories'),
        },
        {
          text: 'Designs',
          onClick: () => setRouteOnClick('Designs'),
        },
        {
          text: 'Fabrics',
          onClick: () => setRouteOnClick('Fabrics'),
        },
        {
          text: 'Leather',
          onClick: () => setRouteOnClick('Leather'),
        },

        {
          text: 'Manufacturing',
          onClick: () => setRouteOnClick('Manufacturing'),
        },
        {
          text: 'Manufacturing Leather',
          onClick: () => setRouteOnClick('Manufacturing-Leather'),
        },
        {
          text: 'Yarns',
          onClick: () => setRouteOnClick('Yarns'),
        },
      ],
    }),
    [layoutProps, navigate]
  )
}
