import { UserRole } from '../../graphql/generated/graphql'

export const getUserRoleDisplayName = (userRole: string): string => {
  switch (userRole) {
    case UserRole.Admin:
      return 'DSI - Admin'
    case UserRole.FashionExpert:
      return 'Expert mode'
    case UserRole.InputOperator:
      return 'Opérateur de saisie'
    case UserRole.Intern:
      return 'Stagiaire'
    case UserRole.Marketplace:
      return 'Équipe marketplace'
    case UserRole.ModePv:
      return 'Équipe mode PV'
    case UserRole.Rex:
      return 'REX'
    case UserRole.Shooting:
      return 'Équipe shooting'
    case UserRole.SuperAdmin:
      return 'DSI - Super admin'
    default:
      return ''
  }
}
