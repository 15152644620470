import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import { Icons } from '../Icon'
import { ActionButtonScheme, ActionButtonSize } from '../ActionButton/types'

import * as SC from './styled'

export type NoteProps = {
  className?: string
  title?: string
  text?: string
  author?: {
    fullName: string
    date: string
  }
  editButton?: ActionButtonProps
}

const Note: FC<NoteProps> = ({ className, title, text, author, editButton }) => {
  return (
    <SC.Note className={className}>
      {title && <SC.Title text={title} tag="h4" />}
      <SC.Text variant={'titleH5'} color={'gray'} text={text} />
      <SC.Bottom>
        {editButton && (
          <SC.Edit
            {...editButton}
            variantSize={ActionButtonSize.Small}
            variantScheme={ActionButtonScheme.Tertiary}
            rightIconProps={{ icon: Icons.pencil, width: 11, height: 12 }}
          />
        )}
        {author && (
          <SC.Author>
            <SC.Name text={author.fullName} />
            <SC.Date text={author.date} />
          </SC.Author>
        )}
      </SC.Bottom>
    </SC.Note>
  )
}

export default Note
