import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import {
  Exhibition,
  ExhibitionCreateForumInput,
  ExhibitionUpdateForumsInput,
  ExhibitionUpsertInput,
  SampleStat,
} from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const getExhibitionByApiIdService = getCustomService<
  'exhibitionByApiId',
  null,
  { exhibitionApiId: string }
>('exhibitionByApiId')

export const getExhibitionsService = getCustomService<
  'getExhibitions',
  null,
  { q: string; first: number; page: number }
>('getExhibitions')

export const createExhibitionService = getCustomService<
  'createExhibition',
  null,
  {
    input: ExhibitionUpsertInput
    forums: ExhibitionCreateForumInput | ExhibitionCreateForumInput[]
  }
>('createExhibition')

export const updateExhibitionService = getCustomService<
  'updateExhibition',
  null,
  {
    exhibitionApiId: string
    input: ExhibitionUpsertInput
    forums: ExhibitionUpdateForumsInput[]
  }
>('updateExhibition')

export const getSampleStatsService = getCustomService<
  'getSampleStats',
  null,
  { exhibitionApiId: string; forumId: number | null }
>('getSampleStats')

//
// Initial state
//

export type ExhibitionsState = {
  exhibitions: Exhibition | null
  exhibitionsSearch: Exhibition[] | null
  exhibitionConfig: Exhibition | undefined
  sampleStats: SampleStat[] | undefined
  // services
  getExhibitionByApiId: typeof getExhibitionByApiIdService.state
  getExhibitions: typeof getExhibitionsService.state
  createExhibition: typeof createExhibitionService.state
  updateExhibition: typeof updateExhibitionService.state
  getSampleStats: typeof getSampleStatsService.state
}

const initialState: ExhibitionsState = {
  exhibitions: null,
  exhibitionsSearch: null,
  exhibitionConfig: undefined,
  sampleStats: undefined,
  // services
  getExhibitionByApiId: getExhibitionByApiIdService.state,
  getExhibitions: getExhibitionsService.state,
  createExhibition: createExhibitionService.state,
  updateExhibition: updateExhibitionService.state,
  getSampleStats: getSampleStatsService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'exhibitions',
  initialState,
  reducers: {
    resetExhibitions: () => initialState,
    setExhibitions: (state, { payload }: actionTypes.setExhibitions) => {
      state.exhibitions = payload
    },
    setExhibitionConfig: (state, { payload }: actionTypes.setExhibitionConfig) => {
      state.exhibitionConfig = payload
    },
    setSampleStats: (state, { payload }: actionTypes.setSampleStats) => {
      state.sampleStats = payload
    },
    resetExhibitionConfig: (state) => {
      state.exhibitionConfig = initialState.exhibitionConfig
    },
    resetCreateExhibition: (state) => {
      state.createExhibition = initialState.createExhibition
    },
    resetUpdateExhibition: (state) => {
      state.updateExhibition = initialState.updateExhibition
    },
    setSearchExhibitions: (state, { payload }: actionTypes.setSearchExhibitions) => {
      state.exhibitionsSearch = payload
    },
    ...getExhibitionByApiIdService.reducers,
    ...getExhibitionsService.reducers,
    ...createExhibitionService.reducers,
    ...updateExhibitionService.reducers,
    ...getSampleStatsService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const exhibitions = (state: RootState) => root(state).exhibitions
const sampleStats = (state: RootState) => root(state).sampleStats
const exhibitionConfig = (state: RootState) => root(state).exhibitionConfig
const exhibitionsSearch = (state: RootState) => root(state).exhibitionsSearch
const getExhibitionByApiId = (state: RootState) => root(state).getExhibitionByApiId
const getExhibitions = (state: RootState) => root(state).getExhibitions
const createExhibition = (state: RootState) => root(state).createExhibition
const updateExhibition = (state: RootState) => root(state).updateExhibition
const getSampleStats = (state: RootState) => root(state).getSampleStats

export const selectors = {
  exhibitions,
  getExhibitionByApiId,
  getExhibitions,
  exhibitionsSearch,
  exhibitionConfig,
  createExhibition,
  updateExhibition,
  sampleStats,
  getSampleStats,
}
