import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { ExhibitionCreateBoxInput, ForumBox } from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const getForumBoxesService = getCustomService<
  'getForumBoxes',
  null,
  { exhibitionApiId?: string; q?: string }
>('getForumBoxes')

export const createBoxesService = getCustomService<
  'createBoxes',
  null,
  {
    exhibitionApiId: string
    input: ExhibitionCreateBoxInput
  }
>('createBoxes')

//
// Initial state
//

export type BoxesState = {
  forumBoxes: ForumBox[] | null
  // services
  getForumBoxes: typeof getForumBoxesService.state
  createBoxes: typeof createBoxesService.state
}

const initialState: BoxesState = {
  forumBoxes: null,
  // services
  getForumBoxes: getForumBoxesService.state,
  createBoxes: createBoxesService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    resetForumBoxes: () => initialState,
    setForumBoxes: (state, { payload }: actionTypes.setForumBoxes) => {
      state.forumBoxes = payload
    },
    resetCreateBoxes: (state) => {
      state.createBoxes = initialState.createBoxes
    },
    ...getForumBoxesService.reducers,
    ...createBoxesService.reducers,
  },
  extraReducers: {
    [REHYDRATE]: (state, action: PayloadAction<any>) => {
      const { forumBoxes = null } = action.payload?.boxes || {}

      return {
        ...initialState,
        forumBoxes,
      }
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const forumBoxes = (state: RootState) => root(state).forumBoxes
const getForumBoxes = (state: RootState) => root(state).getForumBoxes
const createBoxes = (state: RootState) => root(state).createBoxes

export const selectors = {
  forumBoxes,
  getForumBoxes,
  createBoxes,
}
