import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import { FormSubmitButtonProps } from '../../form/FormSubmitButton'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { LinkProps } from '../../Link'

import * as SC from './styled'

export type LoginFormValues = {
  email: string
  password: string
}

export type LoginFormProps = {
  className?: string
  formikForm: FormikFormProps<LoginFormValues>
  fields: {
    email: FormFieldInputProps
    password: FormFieldInputProps
  }
  forgottenPasswordLink: LinkProps
  errors?: FormErrorsProps['errors']
  submitButton: FormSubmitButtonProps
}

const LoginForm: FC<LoginFormProps> = ({
  className,
  formikForm,
  fields,
  forgottenPasswordLink,
  submitButton,
  errors,
}) => {
  return (
    <SC.Form className={className} {...formikForm}>
      <SC.Fields>
        <FormikField
          {...fields.email}
          type="email"
          hasFloatingLabel={true}
          Component={SC.FieldText}
        />
        <FormikField
          {...fields.password}
          type="password"
          hasFloatingLabel={true}
          Component={SC.FieldPassword}
        />
      </SC.Fields>
      <SC.ForgottenPassword {...forgottenPasswordLink} href="/mot-de-passe-oublie" />
      {errors && <SC.Errors errors={errors} />}
      <SC.SubmitButton {...submitButton} />
    </SC.Form>
  )
}

export default LoginForm
