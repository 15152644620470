import React, { FC } from 'react'
import { DateRange, useNavigation, CaptionProps } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { fr } from 'date-fns/locale'

import Icon, { Icons } from '../Icon'

import * as SC from './styled'

export type CalendarProps = {
  showOutsideDays?: boolean
  pickMode: 'range' | 'default'
  range?: DateRange | undefined
  setRange?: (range: DateRange | undefined) => void
  currentMonth?: {
    year: number
    month: number
  }
}

const CustomHeader: FC<CaptionProps> = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  return (
    <SC.Header>
      <SC.Button
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <Icon icon={Icons.arrowLeft} width={22} height={22} />
      </SC.Button>
      <SC.TitleDate>
        {props.displayMonth.toLocaleString('fr', { month: 'long', year: 'numeric' })}
      </SC.TitleDate>
      <SC.Button disabled={!nextMonth} onClick={() => nextMonth && goToMonth(nextMonth)}>
        <Icon icon={Icons.arrowRight} width={22} height={22} />
      </SC.Button>
    </SC.Header>
  )
}

const CustomCalendar: FC<CalendarProps> = ({
  showOutsideDays,
  pickMode,
  range,
  currentMonth,
  setRange,
}) => {
  return (
    <>
      {currentMonth && (
        <SC.Picker
          mode={pickMode}
          onSelect={setRange}
          selected={range}
          showOutsideDays={showOutsideDays ? showOutsideDays : true}
          locale={fr}
          components={{ Caption: CustomHeader }}
          defaultMonth={new Date(currentMonth?.year, currentMonth?.month)}
        />
      )}
    </>
  )
}

export default CustomCalendar
