import { mobileNavMocks } from '../../components/MobileNav/mocks'
import { sidebarNavMocks } from '../../components/SidebarNav/mocks'

import { DefaultLayoutProps } from './index'

const basic: DefaultLayoutProps = {
  mobileNav: mobileNavMocks.basic,
  sidebarNav: sidebarNavMocks.basic,
  marginMainContent: true,
}

export const defaultLayoutMocks = {
  basic,
}
