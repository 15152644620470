import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'
import { UserSetLastVisitedMutation } from '../../../generated/graphql'

export const userSetLastVisited = getMutationService({
  mutation: gql(`
    mutation userSetLastVisited($href: String!) {
      userSetLastVisited(path: $href) {
          lastVisited
        }
    }
  `),
  transformer: (response: UserSetLastVisitedMutation) => {
    return response
  },
})
