import React from 'react'
import { RouteProps } from 'react-router-dom'

import LoungeDatesTemplate from '../../../templates/LoungeDates'
import { useLoungeDatesTemplateProps } from '../../../templates/LoungeDates/useProps'

const LoungesDates: React.FC<RouteProps> = () => {
  const templateProps = useLoungeDatesTemplateProps()

  return <LoungeDatesTemplate {...templateProps} />
}

export default LoungesDates
