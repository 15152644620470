import React from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleSheetsEditTemplate from '../../../templates/SampleSheetsEdit'
import { useSampleSheetEditTemplateProps } from '../../../templates/SampleSheetsEdit/useProps'
import { selectors } from '../../../redux'

const SampleSheetEditShooting: React.FC<RouteProps> = () => {
  const { routeType } = useParams<{ routeType: string }>()
  const form = useSelector(selectors.samples.flagGroupsByUniverse)

  const templateProps = useSampleSheetEditTemplateProps(
    routeType,
    'Info shooting',
    form
      ? form.find(
          (item) => item.name === 'INFO SHOOTING' || item.miraklRef === '"NON_MARKETPLACE_shooting"'
        )
      : undefined
  )
  return <SampleSheetsEditTemplate {...templateProps} />
}

export default SampleSheetEditShooting
