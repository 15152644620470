import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { AuthCreateUserInput } from '../../graphql/generated/graphql'
import { actions, selectors } from '../../redux'
import { Icons } from '../../components/Icon'
import FormFieldRadioGroup from '../../components/form/fields/FormFieldRadioGroup'
import { UserFormValues } from '../../components/forms/UserForm'
import { router, routesPath } from '../../router'

import { UsersManagementTemplateProps } from '.'
import { usersManagementTemplateMocks } from './mocks'

export const useUsersManagementTemplateProps = (): UsersManagementTemplateProps => {
  const useNewUser = useSelector(selectors.user.createUser)
  const checkEmail = useSelector(selectors.auth.authCheckEmailExists)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const layoutProps = useDefaultLayoutTemplateProps('params')

  const [isValid, setIsValid] = useState<boolean>(false)
  const [isMailExist, setIsMailExist] = useState<boolean | null>(checkEmail.data)
  const [newUser, setNewUser] = useState<UserFormValues>({
    lastName: '',
    firstName: '',
    email: '',
    role: '',
  })
  const ref = useRef<FormikProps<UserFormValues>>(null)

  useEffect(() => {
    if (useNewUser.success) {
      const link = router(routesPath.paramsUsers)
      navigate(link)
    }
  }, [navigate, useNewUser.success])

  useEffect(() => {
    if (checkEmail && !checkEmail.pending) {
      setIsMailExist(checkEmail.data)
    }
  }, [checkEmail, navigate, isMailExist])

  useEffect(() => {
    if (isMailExist && ref.current && isValidEmail(newUser.email)) {
      ref.current.setErrors({ email: 'Cet e-mail existe déjà' })
    } else if (!isMailExist && ref.current) {
      ref.current.setErrors({ email: '' })
    }
  }, [isMailExist, newUser.email])

  useEffect(() => {
    if (newUser.email !== '') {
      dispatch(actions.auth.resetAuthCheckEmailExistsService())
    }
    if (isValidEmail(newUser.email)) {
      dispatch(actions.auth.authCheckEmailExistsRequest({ email: newUser.email }))
    }
    //ref.current?.validateField('email')
  }, [dispatch, newUser])

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return emailRegex.test(email)
  }

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          ...usersManagementTemplateMocks.basic.layoutProps?.bottomActions,
          back: {
            ...usersManagementTemplateMocks.basic.layoutProps?.bottomActions?.back,
            href: '/parametres/acces-utilisateurs',
          },
          next: {
            text: 'Valider',
            type: 'submit',
            disabled: !isValid,
            onClick: () => {
              dispatch(
                actions.user.createUserRequest({
                  input: newUser as AuthCreateUserInput,
                })
              )
            },
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
          },
        },
      },
      form: {
        legendIds: 'Identifiants de connexion',
        formikForm: {
          innerRef: ref,
          initialValues: {
            lastName: '',
            firstName: '',
            email: '',
            role: '',
          } as UserFormValues,
          validationSchema: Yup.object().shape({
            lastName: Yup.string().required(),
            firstName: Yup.string().required(),
            email: Yup.string().required('Email requis').email('Format e-mail invalide'),
            role: Yup.string().required(),
          }),
          onContextUpdate: (context) => {
            setNewUser(context.values)
            setIsValid(context.isValid)
          },
          onSubmit: () => undefined,
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          lastName: {
            name: 'lastName',
            label: 'Nom',
            required: true,
          },
          firstName: {
            name: 'firstName',
            label: 'Prénom',
            required: true,
          },
          email: {
            name: 'email',
            label: 'E-mail',
            required: true,
          },
          role: {
            name: 'role',
            required: true,
            Component: FormFieldRadioGroup,
            label: 'Son rôle utilisateur',
            options: [
              {
                label: 'DSI - Super admin',
                value: 'SUPER_ADMIN',
              },
              {
                label: 'Équipe marketplace',
                value: 'MARKETPLACE',
              },
              {
                label: 'Équipe mode PV',
                value: 'MODE_PV',
              },
              {
                label: 'Équipe shooting',
                value: 'SHOOTING',
              },
              {
                label: 'Expert mode',
                value: 'FASHION_EXPERT',
              },
              {
                label: 'Opérateur de saisie',
                value: 'INPUT_OPERATOR',
              },
              {
                label: 'REX',
                value: 'REX',
              },
              {
                label: 'Stagiaire',
                value: 'INTERN',
              },
            ],
          },
        },
      },
    }),
    [dispatch, isValid, layoutProps, newUser]
  )
}
