import React from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import MultipleEditionDataSelection, {
  MultipleEditionDataSelectionTemplateFormValues,
} from '../../../templates/MultipleEditionDataSelection'
import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../../components/form/fields/FormFieldRadioGroup/types'
import { actions, selectors } from '../../../redux'
import { router, routesPath } from '../../../router'
import { useStatutSelectionProps } from '../../../templates/MultipleEditionDataSelection/useProps'
import { Icons } from '../../../components/Icon'

const MultipleEditionMkpForumSelection: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sampleMkpForum = useSelector(selectors.multipleEdition.mkpForum)

  const onSubmitForm = (values: MultipleEditionDataSelectionTemplateFormValues) => {
    if (values && values.radios) {
      dispatch(actions.multipleEdition.setMkpForum(values.radios))
      const link = router(routesPath.multipleEditionScan)
      navigate(link)
    }
  }

  const templateProps = useStatutSelectionProps(onSubmitForm)

  return (
    <MultipleEditionDataSelection
      {...templateProps}
      layoutProps={{
        ...templateProps.layoutProps,
        bottomActions: {
          ...templateProps.layoutProps?.bottomActions,
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.multipleEdition)
              navigate(link)
            },
          },
        },
      }}
      subTitle="Présélection PV"
      text="Où souhaitez-vous sélectionner les échantillons ?"
      radios={{
        name: 'radios',
        required: true,
        optionsFont: VariantRadioFont.SmallBold,
        optionsSize: VariantRadioSize.big,
        options: [
          {
            label: 'Forum',
            value: 'Forum',
            dotted: true,
          },
          {
            label: 'Marketplace + Forum',
            value: 'Marketplace + Forum',
            dotted: true,
          },
          {
            label: 'Marketplace',
            value: 'Marketplace',
            dotted: true,
          },
        ],
      }}
      formikForm={{
        ...templateProps.formikForm,
        initialValues: {
          radios: sampleMkpForum,
        } as MultipleEditionDataSelectionTemplateFormValues,
      }}
    />
  )
}

export default MultipleEditionMkpForumSelection
