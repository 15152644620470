import styled from 'styled-components'

import { breakpoints, fonts } from '../../theme'
import CollapsibleColoredItem from '../../components/CollapsibleColored'
import Divider from '../../components/Divider'
import ContentWrapper from '../../components/ContentWrapper'

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    margin-bottom: 7rem;
  }
`

export const Collapsible = styled(CollapsibleColoredItem)`
  margin-bottom: 1.2rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
`

export const StyledDivider = styled(Divider)``

export const Resume = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.6rem;
  padding: 1.2rem 0;
`

export const ResumeSubForum = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.2rem 0;
  margin-top: 24px;
  gap: 0.6rem;
`

export const ResumeTitle = styled.div`
  ${fonts.RobotoExtraLight};
  font-size: 1.4rem;
  letter-spacing: 0.5px;
`

export const ResumeText = styled.div`
  ${fonts.RobotoLight};
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.tundora};
`

export const SubForum = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.tundora};
  ${({ theme }) => theme.textStyles.titleH6}
`
