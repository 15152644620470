import React, { FC, InputHTMLAttributes } from 'react'

import Dot from '../../../Dot'
import { variant } from '../../../StatutSelection'
import { VariantColored } from '../../../CollapsibleColored'

import * as SC from './styled'
import { VariantRadioFont, VariantRadioSize } from './types'

export type FormFieldRadioGroupProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & {
  className?: string
  label?: string
  name: string
  value?: string
  options: {
    label: string | React.ReactNode
    secondLabel?: string | React.ReactNode
    verticalLabel?: string | React.ReactNode
    value: string
    disabled?: boolean
    dotted?: boolean
    count?: number
    // for preestablished colors
    variant?: VariantColored
    // for custom colors
    backgroundColor?: string
    color?: string
  }[]
  optionBefore?: boolean
  optionsSize?: VariantRadioSize
  optionsFont?: VariantRadioFont
  required?: boolean
  disabled?: boolean
  help?: string
  error?: string
  hasError?: boolean
  variant?: variant
  forceBackgroundColor?: string
  insideCollapsible?: boolean
}

const FormFieldRadioGroup: FC<FormFieldRadioGroupProps> = ({
  className,
  name,
  label,
  options,
  optionBefore,
  optionsSize = VariantRadioSize.default,
  optionsFont = VariantRadioFont.Default,
  required,
  disabled,
  help,
  error,
  hasError,
  forceBackgroundColor,
  insideCollapsible,
  ...inputProps
}) => {
  return (
    <SC.Container
      className={className}
      $isDisabled={disabled}
      $forceBackgroundColor={forceBackgroundColor}
    >
      {label && (
        <SC.Label required={required} error={!!error || hasError}>
          {label}
        </SC.Label>
      )}
      <SC.Options>
        {options?.map((option) => {
          return (
            <SC.Option
              key={option.value}
              $isOptionDisabled={option.disabled}
              $isInputDisabled={disabled}
              $variant={option.variant}
              $backgroundColor={option.backgroundColor}
              $color={option.color}
              $size={optionsSize}
              $variantFont={optionsFont}
              $insideCollapsible={insideCollapsible}
              $verticalLabel={!!option.verticalLabel}
              $optionBefore={optionBefore}
            >
              {optionBefore && (
                <SC.OptionRadio
                  {...inputProps}
                  type="radio"
                  name={name}
                  value={option.value}
                  disabled={disabled || option.disabled}
                  checked={inputProps.value === option.value}
                />
              )}
              <SC.LabelContainer>
                <SC.HorizontalLabel>
                  <SC.HeadTextContainer>
                    {(option.dotted || option.variant) && (
                      <Dot {...(option.variant && { variant: option.variant })} />
                    )}
                    {option.label} {option.count != null && <SC.Count>{option.count}</SC.Count>}
                  </SC.HeadTextContainer>
                  {option.secondLabel && (
                    <SC.HeadTextContainerSecond>
                      {(option.dotted || option.variant) && (
                        <Dot {...(option.variant && { variant: option.variant })} />
                      )}
                      {option.secondLabel}
                    </SC.HeadTextContainerSecond>
                  )}
                </SC.HorizontalLabel>
                {option.verticalLabel && (
                  <SC.VerticalLabel>{option.verticalLabel}</SC.VerticalLabel>
                )}
              </SC.LabelContainer>
              {!optionBefore && (
                <SC.OptionRadio
                  {...inputProps}
                  type="radio"
                  name={name}
                  value={option.value}
                  disabled={disabled || option.disabled}
                  checked={inputProps.value === option.value}
                />
              )}
            </SC.Option>
          )
        })}
      </SC.Options>
      {!error && help && <SC.Help>{help}</SC.Help>}
      {error && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldRadioGroup
