import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { router, routesPath } from '../../router'
import { TabsScrollableProps } from '../../components/TabsScrollable'
import { UserRole } from '../../graphql/generated/graphql'

export const useExhibitionSheetTabsProps = (
  exhibitionId: string | undefined,
  currentTab: 'infos' | 'samples' | 'exhibitor' | 'edito',
  userRole?: UserRole
): TabsScrollableProps => {
  const navigate = useNavigate()
  const tabs = useMemo(() => {
    const links = [
      {
        text: 'Informations générales',
        onClick: () => {
          const link = router(routesPath.exhibitionSheetInfos, {
            exhibitionId,
          })
          navigate(link)
        },
        ariaCurrent: currentTab === 'infos' ? true : false,
      },
      {
        text: 'echantillons',
        onClick: () => {
          const link = router(routesPath.exhibitionSheetSamples, {
            exhibitionId,
          })
          navigate(link)
        },
        ariaCurrent: currentTab === 'samples' ? true : false,
      },
      {
        text: 'Exposants',
        onClick: () => {
          const link = router(routesPath.exhibitionSheetExhibitor, {
            exhibitionId,
          })
          navigate(link)
        },
        ariaCurrent: currentTab === 'exhibitor' ? true : false,
      },
      {
        text: 'édito',
        onClick: () => {
          const link = router(routesPath.exhibitionSheetEdito, {
            exhibitionId,
          })
          navigate(link)
        },
        ariaCurrent: currentTab === 'edito' ? true : false,
      },
    ]

    const filteredLinks = links.filter((link) => {
      if (
        link.text === 'édito' &&
        !['SUPER_ADMIN', 'ADMIN', 'REX', 'MODE_PV', 'MARKETPLACE'].includes(userRole as string)
      ) {
        return false
      }
      if (
        link.text === 'echantillons' &&
        !['SUPER_ADMIN', 'ADMIN', 'REX', 'MODE_PV', 'MARKETPLACE'].includes(userRole as string)
      ) {
        return false
      }
      if (
        link.text === 'Exposants' &&
        !['SUPER_ADMIN', 'ADMIN', 'REX', 'MODE_PV', 'MARKETPLACE'].includes(userRole as string)
      ) {
        return false
      }

      return true
    })

    return filteredLinks
  }, [currentTab, exhibitionId, navigate, userRole])

  return { tabs }
}
