import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const GetForumBox = getQueryService({
  query: gql(`
	query boxes($exhibitionApiId: ID, $q: String) {
		boxes(exhibitionApiId: $exhibitionApiId, q: $q) {
			name
			color
			forumBox {
				id
				name
				forumId
			}
			subForumBoxes {
				id
				name
				forumId
			}
		}
	}
`),
  transformer: (response) => {
    return response
  },
})
