import { ImgHTMLAttributes, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { Icons } from '../../components/Icon'
import { useSampleSheetTabsProps } from '../../relay/SampleSheet/useSampleSheetTabsProps'
import { SampleStatus, FlagItem, ExhibitionForum, Editorial } from '../../graphql/generated/graphql'
import { actions, selectors } from '../../redux'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutSampleSheetTemplateProps } from '../../layouts/LayoutSampleSheet/useProps'
import { EditoCardProps } from '../../components/EditoCard'
import { editos } from '../../graphql/services'
import { router, routesPath } from '../../router'

import { SampleSheetEditoTemplateProps } from '.'

export const useSampleSheetEditoTemplateProps = (
  setMode: React.Dispatch<React.SetStateAction<string>>
): SampleSheetEditoTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sampleId } = useParams<{ sampleId: string }>()
  const [forum, setForum] = useState<ExhibitionForum | undefined>(undefined)
  const [subForum, setSubForum] = useState<ExhibitionForum | undefined>(undefined)
  const [status, setStatus] = useState<SampleStatus | undefined>(undefined)
  const [mkpForum, setMkpForum] = useState<FlagItem | undefined>(undefined)
  const [category, setCategory] = useState<FlagItem | undefined>(undefined)
  const [parsedEditos, setParsedEditos] = useState<EditoCardProps[] | undefined>(undefined)
  const user = useSelector(selectors.auth.user)
  const sampleById = useSelector(selectors.samples.getSampleById)

  const editoAttach = useSelector(selectors.editos.editorialAttachSamples)
  const sample = useSelector(selectors.samples.sample)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const sampleLayoutProps = useLayoutSampleSheetTemplateProps(
    status,
    forum,
    subForum,
    mkpForum,
    sample,
    category
  )
  const tabs = useSampleSheetTabsProps(sampleId, 'edito', user?.role)
  useEffect(() => {
    if (sample) {
      sample.status && setStatus(sample.status)
      sample.subForum && setSubForum(sample.subForum)
      sample.forum && setForum(sample.forum)
      if (sample.flagItems) {
        setMkpForum(
          sample &&
            sample.flagItems &&
            sample.flagItems.find(
              (item) => item.group && item.group.miraklRef === 'NON_MARKETPLACE_forum'
            )
        )
        setCategory(
          sample &&
            sample.flagItems &&
            sample.flagItems.find((item) => item.group && item.group.miraklRef === 'category')
        )
      }
    }
  }, [sample])

  useEffect(() => {
    if (editoAttach && editoAttach.success) {
      setTimeout(() => {
        dispatch(actions.editos.resetEditorialAttachSamples())
      }, 2000)
    }
  }, [dispatch, editoAttach])

  useEffect(() => {
    if (editos && editos) {
      const parseEditosCards = () => {
        const newCards: EditoCardProps[] = []
        sample?.editorials &&
          sample?.editorials.forEach((edito: Editorial) => {
            const photos: ImgHTMLAttributes<HTMLImageElement>[] = []
            edito &&
              edito.samples &&
              edito.samples.forEach((sample) => {
                if (sample.photos) {
                  sample.photos.forEach((photo) => {
                    photos.push({ src: photo.url, alt: photo.id })
                  })
                }
              })
            const card: EditoCardProps = {
              title: edito.name,
              text: edito.description,
              images: photos,
              itemNbr: (edito.samples && edito.samples.length.toString()) || '0',
              onClick: () => {
                const link = router(routesPath.editoSheet, {
                  editoId: edito.id,
                  apiId: sample.collection.exhibitionApiId,
                })
                navigate(link)
              },
            }
            newCards.push(card)
          })
        return newCards
      }
      setParsedEditos(parseEditosCards)
    }
  }, [navigate, sample?.collection?.exhibitionApiId, sample?.editorials])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...sampleLayoutProps,
        bottomActions: {
          ...sampleLayoutProps.bottomActions,
          back: undefined,
          next: {
            text: "Ajouter à l'édito",
            rightIconProps: {
              icon: Icons.plus,
              width: 18,
              height: 18,
            },
            onClick: () => setMode('add'),
          },
        },
        tabs: tabs,
        modalToast:
          editoAttach && editoAttach.success
            ? {
                text: "L'échantillon a bien été ajouté aux editos",
                icon: {
                  icon: Icons.check,
                  width: 16,
                  height: 16,
                },
                isOpen: editoAttach.success,
              }
            : undefined,
      },
      pending: sampleById.pending,
      editoList: parsedEditos || [],
      emptyMessage: "L'échantillon n'a pas encore été ajouté a un edito",
    }),
    [editoAttach, layoutProps, parsedEditos, sampleById.pending, sampleLayoutProps, setMode, tabs]
  )
}
