import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const flagGroupsByUniverse = getQueryService({
  query: gql(`
    query flagGroupsByUniverse($universeEbmsId: ID!) {
      flagGroupsByUniverse(universeEbmsId: $universeEbmsId) {
        id
        name
        miraklRef
        responseType
        order
        items {
          id
          name
          miraklCategoryLabel
          cascadingGroups
          order
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
