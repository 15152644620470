import dayjs from 'dayjs'

import { Exhibition } from '../../graphql/generated/graphql'
import { router, routesPath } from '../../router'

export const returnLoungeConfigSteps = (loungeConfig: Exhibition | undefined) => {
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  const formattedStartDate = dayjs(loungeConfig?.dateStart).format('DD')
  const formattedEndDate = dayjs(loungeConfig?.dateEnd).format('DD MMMM')

  const formattedStartSampleDate = dayjs(loungeConfig?.dateSampleStart).format('DD')
  const formattedEndSampleDate = dayjs(loungeConfig?.dateSampleEnd).format('DD MMMM')

  const isSameMonth = dayjs(loungeConfig?.dateStart).isSame(dayjs(loungeConfig?.dateEnd), 'month')

  const isSameMonthSample = dayjs(loungeConfig?.dateSampleStart).isSame(
    dayjs(loungeConfig?.dateSampleEnd),
    'month'
  )

  const dateRange = isSameMonth
    ? `${formattedStartDate} - ${formattedEndDate}`
    : `${formattedStartDate} ${dayjs(loungeConfig?.dateStart).format(
        'DD MMMM'
      )} - ${formattedEndDate}`
  const dateRangeSample = isSameMonthSample
    ? `${formattedStartSampleDate} - ${formattedEndSampleDate}`
    : `${formattedStartSampleDate} ${dayjs(loungeConfig?.dateSampleStart).format(
        'MMMM'
      )} - ${formattedEndSampleDate}`

  const stepsLounge = [
    {
      isCurrent: lastSegment === 'salon',
      value: '1. Salon',
      href: loungeConfig?.name ? router(routesPath.loungesInfos) : undefined,
      resume: [
        loungeConfig?.name
          ? `${loungeConfig.name} ${loungeConfig.seasonCode}${loungeConfig.seasonYear}`
          : '',
      ],
    },
    {
      isCurrent: lastSegment === 'dates',
      value: '2. Dates salon',
      href: loungeConfig?.dateStart ? router(routesPath.loungesDates) : undefined,
      resume: [loungeConfig?.dateStart ? dateRange : ''],
    },
    {
      isCurrent: lastSegment === 'dates-echantillons',
      value: '3. PÉRIODE DE SÉLECTION',
      href: loungeConfig?.dateSampleStart ? router(routesPath.loungesDatesSamples) : undefined,
      resume: [loungeConfig?.dateSampleStart ? dateRangeSample : ''],
    },
    {
      isCurrent: lastSegment === 'forums' || lastSegment === 'choix-forums',
      value: '4. FORUMS',
      href: loungeConfig?.forums ? router(routesPath.loungesForums) : undefined,
      resume: [
        loungeConfig && Array.isArray(loungeConfig?.forums)
          ? loungeConfig?.forums
              //@ts-ignore
              .filter((item) => item.selected)
              .map((item) => item.name)
              .join(' - ')
          : '',
      ],
    },
    {
      isCurrent: lastSegment === 'choix-sous-forums',
      value: '5. SOUS-FORUMS',
      href: loungeConfig?.forums ? router(routesPath.loungesSubForumsChoice) : undefined,
      resume: [
        loungeConfig && Array.isArray(loungeConfig?.forums)
          ? loungeConfig?.forums
              //@ts-ignore
              .filter((forum) => forum.selected)
              .map((forum) => {
                const selectedSubForumsCount =
                  forum && forum.subForums
                    ? forum.subForums.filter(
                        //@ts-ignore
                        (subForum) => subForum.selected
                      ).length
                    : '0'
                return `${forum.name} (${selectedSubForumsCount})`
              })
              .join(' - ')
          : '',
      ],
    },
    {
      isCurrent: lastSegment === 'resume',
      value: '6. Récapitulatif',
      href: loungeConfig?.forums ? router(routesPath.loungeResume) : undefined,
    },
  ]
  return stepsLounge
}
