import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleGeneratePdf = getMutationService({
  mutation: gql(`
    mutation sampleGeneratePdf($sampleId: ID!) {
		sampleGeneratePdf(sampleId: $sampleId)
	}
    `),
  transformer: (response) => {
    return response
  },
})
