import styled from 'styled-components'

import breakpoints from '../../theme/breakpoints'
import Heading from '../Heading'
import ActionButton from '../ActionButton'

export const Container = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  color: ${({ theme }) => theme.colors.mineShaft};
`

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1.2rem 3.8rem;
    align-items: center;
  }
`

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.colors.gray};
  ${breakpoints.up('tablet')} {
    grid-column: 2 / span 1;
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textBold}
  ${breakpoints.up('tablet')} {
    grid-column: 2 / span 1;
  }
  white-space: pre-line;
`

export const Score = styled.p`
  ${({ theme }) => theme.textStyles.titleH3}
  white-space: nowrap;
  & strong {
    ${({ theme }) => theme.textStyles.titleH1}
  }
  ${breakpoints.up('tablet')} {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
  }
`

export const Edit = styled(ActionButton)`
  align-self: flex-end;
`
