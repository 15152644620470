import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const login = getQueryService({
  query: gql(`
    query authByCredentials($email: String!, $password: String!) {
      authByCredentials(email: $email, password: $password) {
        user {
          id
          type
          role
          firstName
          lastName
          email
          lastLoginAt
          externalAccessFrom
          externalAccessTo
          createdAt
          updatedAt
          type
          lastVisited
        }
        token {
          expiresIn
          tokenType
          accessToken
        }
      }
    }
`),
  transformer: (response) => {
    return response
  },
})
