import React, { useMemo } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import HomeTemplate, { HomeTemplateProps } from '../../templates/Home'
import { selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'

const HomePage: React.FC<RouteProps> = () => {
  const user = useSelector(selectors.auth.user)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const layoutProps = useDefaultLayoutTemplateProps(undefined)

  const templateProps: HomeTemplateProps = useMemo(
    () => ({
      layout: {
        ...layoutProps,
      },
      title: t('hello', { name: user?.firstName }),
      blocks: [
        {
          text: 'Gestion colis',
          colorVariant: 'yellow',
          onClick: () => {
            const link = router(routesPath.exponent, {
              redirect: 'redirect',
            })
            navigate(link)
          },
        },
        {
          text: 'Séléction échantillons',
          colorVariant: 'purple',
          onClick: () => {
            const link = router(routesPath.exponent, {
              redirect: 'search',
            })
            navigate(link)
          },
        },
        {
          text: 'Scan échantillons',
          colorVariant: 'green',
          onClick: () => {
            const link = router(routesPath.sampleScan)
            navigate(link)
          },
        },
        {
          text: 'Exposants',
          colorVariant: 'grey',
          onClick: () => {
            const link = router(routesPath.exponent, {
              redirect: 'search',
            })
            navigate(link)
          },
        },
      ],
    }),
    [layoutProps, navigate, t, user?.firstName]
  )

  return <HomeTemplate {...templateProps} />
}

export default HomePage
