import {
  ConfigurationDictionary,
  ConfigurationDictionaryItem,
} from '../../graphql/generated/graphql'
import { convertFiltersName } from '../../relay/SampleSheet/ConvertFiltersName'
import { FilterTagProps } from '../FilterTag'
import { Display } from '../form/fields/FormFieldCheckbox/styled'
import { FormFieldCheckboxGroupProps } from '../form/fields/FormFieldCheckboxGroup'

function getLabelByType(type: string | null | undefined): string {
  switch (type) {
    case 'UNIVERSES':
      return 'Univers'
    case 'PAVILIONS':
      return 'Secteur'
    case 'COUNTRIES':
      return 'Pays'
    default:
      return type?.toString() ?? ''
  }
}

export const convertFiltersNameFirstLetter = (name: string): string => {
  const formattedName = name.toLowerCase()
  return formattedName.charAt(0).toUpperCase() + formattedName.slice(1)
}

export function findTagsInDictionaries(
  tags: string[],
  dictionaries: ConfigurationDictionary[] | undefined
) {
  const result: { type: string; filters: string[] }[] = []
  // Identifiants spécifiques pour "Accessories" dans chaque catégorie
  if (dictionaries) {
    for (const dictionary of dictionaries) {
      const type = dictionary.type || ''
      const filtersByType: string[] = []

      // Continuer à traiter les autres tags pour le type de dictionnaire actuel
      for (const tag of tags) {
        if (tag !== 'Accessories') {
          // Exclure "Accessories" du traitement normal
          const filters = dictionary.items?.filter((item) => item.value === tag)
          if (filters && filters.length > 0) {
            filtersByType.push(...filters.map((item) => item.key || ''))
          }
        }
      }

      // Ajouter les résultats pour le type actuel s'il y a des filtres à inclure
      if (filtersByType.length > 0) {
        filtersByType.sort()
        result.push({ type, filters: filtersByType })
      }
    }
  }

  return result
}

export function findTagsFiltersInDictionaries(
  tags: FilterTagProps[],
  dictionaries: ConfigurationDictionary[] | undefined
) {
  const result: { type: string; filters: string[] }[] = []
  if (dictionaries) {
    const allowedTypes = ['UNIVERSES', 'COUNTRIES', 'PAVILIONS']
    const filteredDictionaries = dictionaries.filter(
      (item: ConfigurationDictionary) => item.type && allowedTypes.includes(item.type)
    )
    for (const dictionary of filteredDictionaries) {
      const type = dictionary.type || ''
      const filtersByType: string[] = []

      for (const tag of tags) {
        const tagType = tag.category || ''
        const relatedType = getRelatedType(tagType)
        if (relatedType && dictionary.type === relatedType) {
          const filters = dictionary.items?.filter((item) => item.value === tag.text)
          if (filters && filters.length > 0) {
            filtersByType.push(...filters.map((item) => item.key || ''))
          }
        }
      }

      // Ajouter les résultats pour le type actuel s'il y a des filtres à inclure
      if (filtersByType.length > 0) {
        filtersByType.sort()
        result.push({ type, filters: filtersByType })
      }
    }
  }

  return result
}

const getRelatedType = (tagType: string): string | undefined => {
  if (tagType === 'Secteur' || tagType === 'PAVILIONS') {
    return 'PAVILIONS' // Update this mapping based on your specific relationships
  }
  if (tagType === "Pays de l'exposant" || tagType === 'COUNTRIES') {
    return 'COUNTRIES'
  }
  if (tagType === 'UNIVERSES') {
    return 'UNIVERSES'
  } else {
    return undefined // No related type for other types
  }
}

export function parseFiltersSamplesFromStore(
  obj: ConfigurationDictionary[]
): FormFieldCheckboxGroupProps[] {
  const filters = Object.keys(obj).reduce((accumulator, key: any) => {
    const item = obj[key]
    if (key !== 'exhibitions') {
      accumulator.push({
        name: convertFiltersName(key) as string,
        label: convertFiltersName(key) as string,
        required: false,
        displayLabel: false,
        options: Object.keys(item).map((subKey) => ({
          //@ts-ignore
          label: convertFiltersNameFirstLetter(item[subKey]),
          //@ts-ignore
          value: item[subKey],
          key: key,
          exponent: '',
          display: 'simple' as Display,
        })),
      })
    }
    return accumulator
  }, [] as FormFieldCheckboxGroupProps[])
  return filters
}

export function parseFiltersSamplesExportFromStore(
  obj: ConfigurationDictionary[]
): FormFieldCheckboxGroupProps[] {
  const filters = Object.keys(obj).reduce((accumulator, key: any) => {
    const item = obj[key]
    if (key === 'exhibitions') {
      return accumulator // Ignorer la clé 'exhibitions' et laisser l'accumulateur inchangé
    }
    accumulator.push({
      name: convertFiltersName(key) as string,
      label: convertFiltersName(key) as string,
      required: true,
      displayLabel: false,
      options: Object.keys(item).map((subKey) => ({
        //@ts-ignore
        label: item[subKey],
        //@ts-ignore
        value: item[subKey],
        key: subKey,
        exponent: '',
        display: 'simple' as Display,
      })),
    })
    return accumulator
  }, [] as FormFieldCheckboxGroupProps[])

  return filters
}

export function parseFiltersExhibitorsFromStore(
  obj: ConfigurationDictionary[]
): FormFieldCheckboxGroupProps[] {
  const allowedTypes = ['UNIVERSES', 'COUNTRIES', 'PAVILIONS']
  const filters = obj
    .filter((item: ConfigurationDictionary) => item.type && allowedTypes.includes(item.type))
    .map((item: ConfigurationDictionary) => {
      const label = getLabelByType(item.type)
      return {
        name: item.type || '',
        label: label,
        required: true,
        displayLabel: false,
        options: item.items.map((subItem: ConfigurationDictionaryItem) => ({
          label: convertFiltersNameFirstLetter(subItem.value || ''),
          value: subItem.value || '',
          key: subItem.key,
          exponent: '',
          display: 'simple' as Display,
        })),
      }
    })

  return filters
}
