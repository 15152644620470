import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { FormikProps } from 'formik'

import { actions, selectors } from '../../redux'
import { useLayoutLoungeConfigTemplateProps } from '../../layouts/LayoutLoungeConfig/useProps'
import { router, routesPath } from '../../router'
import { VariantRadioFont } from '../../components/form/fields/FormFieldRadioGroup/types'
import { FormFieldRadioGroupProps } from '../../components/form/fields/FormFieldRadioGroup'
import { Exhibition } from '../../graphql/generated/graphql'
import { CollapsibleItemProps } from '../../components/CollapsibleItem'

import { LoungeForumFormValues, LoungesForumTemplateProps } from '.'

export const useLoungeForumsTemplateProps = (): LoungesForumTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const config = useSelector(selectors.configuration.configurationInfo)
  const lounges = useSelector(selectors.exhibitions.exhibitionsSearch)
  const ref = useRef<FormikProps<LoungeForumFormValues>>(null)
  const [shouldUpdateNoDuplicate, setShouldUpdateNoDuplicate] = useState<boolean>(false)
  const layoutLoungeProps = useLayoutLoungeConfigTemplateProps()
  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)

  useEffect(() => {
    if (loungeConfig?.forums && loungeConfig?.forums.length !== 0) {
      const link = router(routesPath.loungesForumsChoice)
      navigate(link)
    }
  }, [loungeConfig?.forums, loungeConfig?.forums?.length, navigate])

  useEffect(() => {
    config &&
      dispatch(
        actions.exhibitions.getExhibitionsRequest({
          q: '',
          first: 100,
          page: 1,
        })
      )
  }, [config, config?.exhibitionActiveApiId, dispatch])

  useEffect(() => {
    if (shouldUpdateNoDuplicate) {
      ref.current?.setFieldValue('noDuplicate', '')
    }
  }, [shouldUpdateNoDuplicate])

  const convertedData =
    lounges &&
    lounges.reduce(
      (
        acc: {
          radios: FormFieldRadioGroupProps
          collapsible: CollapsibleItemProps
        }[],
        exhibition
      ) => {
        const { id, name, seasonCode, dateStart, seasonYear } = exhibition
        const seasonFullYear = new Date(dateStart).getFullYear().toString()
        const season = `${seasonCode}${seasonYear}`
        const currentYear = new Date().getFullYear().toString()

        const existingYearIndex = acc.findIndex((item) => item.collapsible.title === seasonFullYear)
        const radioItem = {
          label: name as string,
          value: id as string,
          verticalLabel: season as string,
        }

        if (existingYearIndex !== -1) {
          acc[existingYearIndex].radios.options.push(radioItem)
        } else {
          acc.push({
            collapsible: {
              title: seasonFullYear,
              isCollapsibleOpen: seasonFullYear === currentYear,
            },
            radios: {
              name: 'radio',
              required: true,
              insideCollapsible: true,
              optionsFont: VariantRadioFont.SmallBold,
              options: [radioItem],
            },
          })
        }
        return acc
      },
      []
    )

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutLoungeProps,
        bottomActions: {
          ...layoutLoungeProps.bottomActions,
          next: {
            ...layoutLoungeProps.bottomActions?.next,
            onClick: () => {
              if (ref) ref.current?.submitForm()
            },
          },
          back: {
            ...layoutLoungeProps.bottomActions?.back,
            onClick: () => {
              const link = router(routesPath.loungesDatesSamples)
              navigate(link)
            },
          },
        },
      },
      title: 'Les forums',
      subTitle: "Souhaitez-vous dupliquer les forums et sous-forums d'un précédent salon?",
      radioParams: {
        name: 'noDuplicate',
        insideCollapsible: true,
        optionsFont: VariantRadioFont.SmallBold,
        options: [
          {
            label: 'NE RIEN DUPLIQUER',
            value: 'none',
            verticalLabel: "Ne pas importer les forums d'un ancien salon",
          },
        ],
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target
          if (value === 'none') {
            ref.current?.setFieldValue('radio', '')
            setShouldUpdateNoDuplicate(false)
          }
        },
      },
      formikForm: {
        innerRef: ref,
        initialValues: {
          noDuplicate: 'none',
          radio: '',
        } as LoungeForumFormValues,
        validationSchema: Yup.object().shape({
          noDuplicate: Yup.string(),
          radio: Yup.string(),
        }),
        onContextUpdate: (context) => {
          if (context.values.radio !== '' && !shouldUpdateNoDuplicate)
            setShouldUpdateNoDuplicate(true)
        },
        onSubmit: (values) => {
          if (values.noDuplicate !== '') {
            const updatedLoungeConfig = {
              ...loungeConfig,
              forums: loungeConfig?.forums ? loungeConfig.forums : [],
            }
            dispatch(
              actions.exhibitions.setExhibitionConfig(
                (updatedLoungeConfig as unknown) as Exhibition
              )
            )
            const link = router(routesPath.loungesForumsChoice)
            navigate(link)
          }
          if (values.radio !== '' && lounges) {
            const duplicatedLounges = lounges.find((item) => item.id === values.radio)
            const updatedLoungeConfig = {
              ...loungeConfig,
              forums: duplicatedLounges?.forums,
            }
            dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
            const link = router(routesPath.loungesForumsChoice)
            navigate(link)
          }
        },
        validateOnChange: true,
        validateOnMount: true,
      },
      multipleRadiosGroups:
        (convertedData &&
          convertedData.slice().sort((a, b) => {
            const yearA = parseInt(a.collapsible.title as string)
            const yearB = parseInt(b.collapsible.title as string)

            return yearB - yearA
          })) ||
        [],
    }
  }, [
    convertedData,
    dispatch,
    layoutLoungeProps,
    loungeConfig,
    lounges,
    navigate,
    shouldUpdateNoDuplicate,
  ])
}
