import React, { FC } from 'react'
import cx from 'classnames'

import { BottomActionsProps } from '../../components/BottomActions'
import { DefaultLayoutProps } from '../DefaultLayout'

import * as SC from './styled'

export type LayoutWithBottomActionsProps = DefaultLayoutProps & {
  className?: string
  header?: React.ReactNode
  bottomActions?: BottomActionsProps
  children?: React.ReactNode
}

const LayoutWithBottomActions: FC<LayoutWithBottomActionsProps> = ({
  children,
  className,
  bottomActions,
  ...defaultLayout
}) => {
  return (
    <SC.DefaultLayoutStyled className={cx(className)} {...defaultLayout} marginMainContent={true}>
      {children}
      <SC.Actions $minorPadding={!!bottomActions?.nextPlus} {...bottomActions} />
    </SC.DefaultLayoutStyled>
  )
}

export default LayoutWithBottomActions
