import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { FormFieldTextAreaProps } from '../../form/fields/FormFieldTextArea'
import { ActionButtonProps } from '../../ActionButton'
import { ActionButtonScheme, ActionButtonSize } from '../../ActionButton/types'
import { Icons } from '../../Icon'

import * as SC from './styled'

export type CollectionNoteFormValues = {
  note: string
}

export type CollectionNoteFormProps = {
  className?: string
  formikForm: FormikFormProps<CollectionNoteFormValues>
  fields: {
    note: {
      title?: string
      props: FormFieldTextAreaProps
    }
  }
  errors?: FormErrorsProps['errors']
  submit?: ActionButtonProps
  deleteButton?: ActionButtonProps
}

const CollectionNoteForm: FC<CollectionNoteFormProps> = ({
  className,
  formikForm,
  fields,
  errors,
  submit,
  deleteButton,
}) => {
  return (
    <SC.Container className={className}>
      <SC.Form {...formikForm}>
        <SC.Fieldset>
          {fields.note.title && <SC.Subtitle tag="h5" text={fields.note.title} />}
          <FormikField Component={SC.StyledTextarea} {...fields.note.props} />
        </SC.Fieldset>
        {errors && <SC.Errors errors={errors} />}
        {deleteButton && (
          <SC.Delete
            {...deleteButton}
            type="button"
            variantSize={ActionButtonSize.Small}
            variantScheme={ActionButtonScheme.Tertiary}
            rightIconProps={{ icon: Icons.trash, width: 11, height: 12 }}
          />
        )}
        {submit && (
          <SC.Submit
            {...submit}
            type="submit"
            variantSize={ActionButtonSize.Default}
            variantScheme={ActionButtonScheme.Primary}
            rightIconProps={{ icon: Icons.check, width: 15, height: 15 }}
          />
        )}
      </SC.Form>
    </SC.Container>
  )
}

export default CollectionNoteForm
