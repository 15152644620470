import React, { FC } from 'react'

import LayoutExponentSheet, { LayoutExponentSheetProps } from '../../layouts/LayoutExponentSheet'
import Note, { NoteProps } from '../../components/Note'
import NoteTag from '../../components/NoteTag'
import CollectionNoteForm, {
  CollectionNoteFormProps,
} from '../../components/forms/CollectionNoteForm'

import * as SC from './styled'

export type ExponentSheetInternalNoteTemplateProps = {
  layoutProps?: LayoutExponentSheetProps
  pending?: boolean
  notes?: NoteProps[]
  title?: string
  newTag?: boolean
  returnTag?: boolean
  edit: CollectionNoteFormProps
  editNote?: boolean
}

const ExponentSheetInternalNoteTemplate: FC<ExponentSheetInternalNoteTemplateProps> = ({
  layoutProps,
  pending,
  notes,
  title,
  newTag,
  returnTag,
  edit,
  editNote,
}) => {
  return (
    <LayoutExponentSheet {...layoutProps}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          {title && <SC.Title text={title} tag="h2" />}
          {(newTag || returnTag) && (
            <SC.NoteTagContainer>
              {newTag && <NoteTag text="Nouvel exposant" variant="new" />}
              {returnTag && <NoteTag text="Échantillons à retourner" variant="return" />}
            </SC.NoteTagContainer>
          )}
          {notes ? (
            notes.map((item, key) => <Note key={key} {...item} />)
          ) : (
            <SC.Title text="Aucune note interne n'a été postée." tag="h5" />
          )}

          {editNote && <CollectionNoteForm {...edit} />}
        </>
      )}
    </LayoutExponentSheet>
  )
}

export default ExponentSheetInternalNoteTemplate
