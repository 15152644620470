import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { FormikProps } from 'formik'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { Icons } from '../../components/Icon'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import { MailTemplate, MailTemplateType } from '../../graphql/generated/graphql'

import { ManageEmailFormValues, ManageEmailsTemplateProps } from '.'

export const useManageEmailsTemplateProps = (pageTitle?: string): ManageEmailsTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const mails = useSelector(selectors.mails.mailsByType)
  const mailsUpsert = useSelector(selectors.mails.mailTemplateUpsert)
  const { emailType } = useParams<{ emailType: string }>()
  const [mail, setMail] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const [language, setLanguage] = useState<'FR' | 'EN' | 'IT'>('FR')
  const ref = useRef<FormikProps<ManageEmailFormValues>>(null)

  const handleMailChange = (newMail: string) => {
    setMail(newMail)
  }

  const handleEditEmail = useCallback(
    (mails: MailTemplate, subjectForm: string) => {
      dispatch(
        actions.mails.mailTemplateUpsertRequest({
          input: {
            type:
              emailType === 'parcels'
                ? MailTemplateType.ParcelCreated
                : MailTemplateType.MarketplaceProductsImported,
            bodyFr: language === 'FR' ? mail : mails.bodyFr,
            bodyEn: language === 'EN' ? mail : mails.bodyEn,
            bodyIt: language === 'IT' ? mail : mails.bodyIt,
            subjectFr: language === 'FR' ? subjectForm : mails.subjectFr,
            subjectEn: language === 'EN' ? subjectForm : mails.subjectEn,
            subjectIt: language === 'IT' ? subjectForm : mails.subjectIt,
          },
        })
      )
    },
    [dispatch, emailType, language, mail]
  )

  useEffect(() => {
    if (emailType) {
      dispatch(
        actions.mails.mailTemplateByTypeRequest({
          type:
            emailType === 'parcels'
              ? MailTemplateType.ParcelCreated
              : MailTemplateType.MarketplaceProductsImported,
        })
      )
    }
    if (mailsUpsert && mailsUpsert.success) {
      const link = router(routesPath.paramsEmails)
      navigate(link)
    }
  }, [dispatch, emailType, mailsUpsert, mailsUpsert.success, navigate])

  useEffect(() => {
    if (mails) {
      if (language === 'FR') {
        setMail(mails.bodyFr as string)
        setSubject(mails.subjectFr as string)
      }
      if (language === 'EN') {
        setMail(mails.bodyEn as string)
        setSubject(mails.subjectEn as string)
      }
      if (language === 'IT') {
        setMail(mails.bodyIt as string)
        setSubject(mails.subjectIt as string)
      }
    }
  }, [dispatch, emailType, language, mails])

  useEffect(() => {
    if (mails) {
      if (language === 'FR')
        mail !== mails.bodyFr || subject !== mails.subjectFr
          ? setIsEdited(true)
          : setIsEdited(false)
      if (language === 'EN')
        mail !== mails.bodyEn || subject !== mails.subjectEn
          ? setIsEdited(true)
          : setIsEdited(false)
      if (language === 'IT')
        mail !== mails.bodyIt || subject !== mails.subjectIt
          ? setIsEdited(true)
          : setIsEdited(false)
      ref.current &&
        ref.current.setValues({
          subject: subject,
        })
    }
  }, [dispatch, language, mail, mails, subject])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        steps: {
          steps: [
            {
              isCurrent: language === 'FR',
              value: '1. Français',
              onClick: () => setLanguage('FR'),
            },
            {
              isCurrent: language === 'EN',

              value: '2. Anglais',
              onClick: () => setLanguage('EN'),
            },
            {
              isCurrent: language === 'IT',
              value: '3. Italien',
              onClick: () => setLanguage('IT'),
            },
          ],
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.paramsEmails)
              navigate(link)
            },
          },
          next: {
            text: 'Valider',
            disabled: !isEdited,
            onClick: () => ref.current?.submitForm(),
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
          },
        },
        title: pageTitle,
      },
      formikForm: {
        innerRef: ref,
        initialValues: {
          subject: subject,
        },
        validationSchema: Yup.object().shape({
          subject: Yup.string().required(),
        }),
        onContextUpdate(context) {
          setIsEdited(context.isValid)
        },
        onSubmit: (context) => {
          mails && handleEditEmail(mails, context.subject)
        },
        validateOnChange: true,
        validateOnMount: false,
      },
      fields: {
        subject: {
          name: 'subject',
          label: 'En-tête',
        },
        richTextEditor: {
          title: 'Éditer le texte :',
          initialValue: mail,
          onChange(newContent) {
            handleMailChange(newContent)
          },
        },
      },
    }),
    [handleEditEmail, isEdited, language, layoutProps, mail, mails, navigate, pageTitle, subject]
  )
}
