import { Icons } from '../../components/Icon'
import { sidebarNavMocks } from '../../components/SidebarNav/mocks'
import { layoutWithBottomActionsMocks } from '../../layouts/LayoutWithBottomActions/mocks'
import { bottomActionsMocks } from '../../components/BottomActions/mocks'
import { userFormMocks } from '../../components/forms/UserForm/mocks'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'

import { UsersManagementTemplateProps } from './index'

const basic: UsersManagementTemplateProps = {
  layoutProps: {
    ...layoutWithBottomActionsMocks.basic,
    bottomActions: {
      back: {
        ...bottomActionsMocks.basic.back,
        rounded: true,
      },
      next: {
        text: 'Valider',
        rightIconProps: {
          icon: Icons.check,
          width: 18,
          height: 18,
        },
      },
    },
    sidebarNav: {
      ...sidebarNavMocks.basic,
      menu: {
        links: [
          {
            text: 'Gestion colis',
          },
          {
            text: 'Séléction échantillons',
          },
          {
            text: 'Scan échantillon',
          },
          {
            text: 'Exposants',
          },
          {
            text: 'Salons',
          },
          {
            text: 'Paramètres',
            selected: true,
          },
          {
            text: 'Mon compte',
          },
        ],
      },
    },
  },
  title: 'Accès utilisateurs',
  subtitle: 'Nouvel utilisateur',
  form: userFormMocks.basic,
}

const edit: UsersManagementTemplateProps = {
  ...basic,
  layoutProps: {
    ...basic.layoutProps,
    bottomActions: {
      ...basic.layoutProps?.bottomActions,
      next: {
        text: 'Valider',
        rightIconProps: {
          icon: Icons.check,
          width: 18,
          height: 18,
        },
      },
    },
  },
  subtitle: 'Éditer le profil utilisateur',
  form: userFormMocks.prefilled,
  deleteButton: {
    text: 'Supprimer cet utilisateur',
    rightIconProps: {
      icon: Icons.trash,
      width: 16,
      height: 16,
    },
    variantScheme: ActionButtonScheme.Tertiary,
    variantSize: ActionButtonSize.Small,
  },
}

export const usersManagementTemplateMocks = {
  basic,
  edit,
}
