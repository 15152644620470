import styled, { css } from 'styled-components'

import type { FormLabelProps } from './index'

export const Label = styled.label<{ $error?: boolean } & Omit<FormLabelProps, 'error'>>`
  display: inline-block;
  ${(props) => props.theme.textStyles.titleH5};

  &[required]:after {
    content: ' *';
    color: ${(props) => props.theme.colors.red};
  }

  ${(props) =>
    props.$error &&
    css`
      color: ${(props) => props.theme.colors.red};
    `}
`
