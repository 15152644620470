import React, { useEffect } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { actions, selectors } from '../../../redux'
import ExhibitionSheetEditoTemplate from '../../../templates/ExhibitionSheetEdito'
import { useExhibitionSheetEditoTemplateProps } from '../../../templates/ExhibitionSheetEdito/useProps'

const ExhibitionSheetEdito: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const exhibition = useSelector(selectors.exhibitions.exhibitions)
  const editoCreate = useSelector(selectors.editos.editorialCreate)

  useEffect(() => {
    exhibitionId &&
      dispatch(
        actions.exhibitions.exhibitionByApiIdRequest({
          exhibitionApiId: exhibitionId,
        })
      )
  }, [dispatch, exhibitionId, editoCreate?.success])

  const templateProps = useExhibitionSheetEditoTemplateProps(exhibition)

  return (
    <>
      <ExhibitionSheetEditoTemplate {...templateProps} />
    </>
  )
}

export default ExhibitionSheetEdito
