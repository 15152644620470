import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectors } from '../../../redux'
import ExponentSheetArchiveTemplate from '../../../templates/ExponentSheetArchive'
import { useExponentSheetArchiveTemplateProps } from '../../../templates/ExponentSheetArchive/useProps'

const ExponentSheetArchive: React.FC<RouteProps> = () => {
  const collection = useSelector(selectors.exhibitors.collection)
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)

  const templateProps = useExponentSheetArchiveTemplateProps(exhibitor, collection)

  return (
    <>
      <ExponentSheetArchiveTemplate {...templateProps} />
    </>
  )
}

export default ExponentSheetArchive
