import { routesPath } from '../../router'

export const routesFabrics = [
  {
    location: 'category',
    href: routesPath.sampleSheetEditCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.sampleSheetEditProductDestination,
  },
  {
    location: 'pattern',
    href: routesPath.sampleSheetEditPattern,
  },
  {
    location: 'performanceCode',
    href: routesPath.sampleSheetEditPerformanceCode,
  },

  {
    location: 'certifications',
    href: routesPath.sampleSheetEditCertifications,
  },
  {
    location: 'material',
    href: routesPath.sampleSheetEditMaterial,
  },
  {
    location: 'color',
    href: routesPath.sampleSheetEditColor,
  },
  {
    location: 'shooting',
    href: routesPath.sampleSheetEditShooting,
  },
  {
    location: 'list',
    href: routesPath.sampleSheets,
  },
]

export const routesLeather = [
  {
    location: 'category',
    href: routesPath.sampleSheetEditCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.sampleSheetEditProductDestination,
  },
  {
    location: 'animal',
    href: routesPath.sampleSheetEditAnimal,
  },
  {
    location: 'tanningType',
    href: routesPath.sampleSheetEditTanningType,
  },
  {
    location: 'aspect',
    href: routesPath.sampleSheetEditAspect,
  },
  {
    location: 'performanceCode',
    href: routesPath.sampleSheetEditPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.sampleSheetEditCertifications,
  },
  {
    location: 'shooting',
    href: routesPath.sampleSheetEditShooting,
  },
  {
    location: 'list',
    href: routesPath.sampleSheets,
  },
]

export const routesAccessories = [
  {
    location: 'category',
    href: routesPath.sampleSheetEditCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.sampleSheetEditProductDestination,
  },
  {
    location: 'accessorieType',
    href: routesPath.sampleSheetEditAccessorieType,
  },
  {
    location: 'material',
    href: routesPath.sampleSheetEditMaterial,
  },
  {
    location: 'aspect',
    href: routesPath.sampleSheetEditAspect,
  },
  {
    location: 'performanceCode',
    href: routesPath.sampleSheetEditPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.sampleSheetEditCertifications,
  },
  {
    location: 'shooting',
    href: routesPath.sampleSheetEditShooting,
  },
  {
    location: 'list',
    href: routesPath.sampleSheets,
  },
]

export const routesManufacturing = [
  {
    location: 'category',
    href: routesPath.sampleSheetEditCategory,
  },
  {
    location: 'performanceCode',
    href: routesPath.sampleSheetEditPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.sampleSheetEditCertifications,
  },
  {
    location: 'manufacturingType',
    href: routesPath.sampleSheetEditManufacturingType,
  },
  {
    location: 'material',
    href: routesPath.sampleSheetEditMaterial,
  },
  {
    location: 'list',
    href: routesPath.sampleSheets,
  },
]

export const routesYarns = [
  {
    location: 'category',
    href: routesPath.sampleSheetEditCategory,
  },
  {
    location: 'productDestination',
    href: routesPath.sampleSheetEditProductDestination,
  },
  {
    location: 'unitValue',
    href: routesPath.sampleSheetEditUnitValue,
  },
  {
    location: 'numberEnd',
    href: routesPath.sampleSheetEditNumberEnd,
  },
  {
    location: 'aspect',
    href: routesPath.sampleSheetEditAspect,
  },
  {
    location: 'technologyActivity',
    href: routesPath.sampleSheetEditTechnologyActivity,
  },
  {
    location: 'performanceCode',
    href: routesPath.sampleSheetEditPerformanceCode,
  },
  {
    location: 'certifications',
    href: routesPath.sampleSheetEditCertifications,
  },
  {
    location: 'shooting',
    href: routesPath.sampleSheetEditShooting,
  },
  {
    location: 'color',
    href: routesPath.sampleSheetEditColor,
  },
  {
    location: 'material',
    href: routesPath.sampleSheetEditMaterial,
  },
  {
    location: 'list',
    href: routesPath.sampleSheets,
  },
]

export const routesDesign = [
  {
    location: 'category',
    href: routesPath.sampleSheetEditCategory,
  },
  {
    location: 'pattern',
    href: routesPath.sampleSheetEditPattern,
  },
  {
    location: 'patternType',
    href: routesPath.sampleSheetEditPatternType,
  },
  {
    location: 'list',
    href: routesPath.sampleSheets,
  },
]
