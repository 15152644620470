import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useDebounce } from 'usehooks-ts'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type TabsScrollableProps = {
  className?: string
  tabs: LinkProps[]
}

const TabsScrollable: FC<TabsScrollableProps> = ({ className, tabs }) => {
  const [isScrollable, setScrollable] = useState(false)
  const [currentStep, setCurrentStep] = useState<number | null>(null)
  const debouncedValue = useDebounce<boolean>(isScrollable, 500)
  const scrollRef = useRef<ScrollContainer>(null)

  // scroll to current
  useEffect(() => {
    if (currentStep !== null && scrollRef.current) {
      const currentElement = scrollRef.current.getElement().children[currentStep] as HTMLElement
      if (currentElement)
        currentElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
    }
  }, [currentStep])

  // get current index
  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.ariaCurrent)
    setCurrentStep(index !== -1 ? index : null)
  }, [tabs])

  // add grab cursor when overflow
  const addScrollCursor = useCallback(() => {
    const element = scrollRef.current?.getElement()

    if (!element || !element?.children) return

    const childrenWidth = Array.from(element.children).reduce(
      (total, element) => total + element.clientWidth,
      0
    )

    setScrollable(element.offsetWidth < childrenWidth)
  }, [])

  // listeners
  useEffect(() => {
    addScrollCursor()
    window && window.addEventListener('resize', addScrollCursor)
    return () => {
      window && window.removeEventListener('resize', addScrollCursor)
    }
  }, [addScrollCursor])

  return tabs.length > 0 ? (
    <SC.Container className={className}>
      <SC.Wrapper ref={scrollRef} $scrollable={debouncedValue}>
        {tabs.map((tab, idx) => (
          <SC.Tab key={`TabsScrollable-tab-${idx}`} {...tab} />
        ))}
      </SC.Wrapper>
    </SC.Container>
  ) : null
}

export default TabsScrollable
