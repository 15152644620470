import React, { FC } from 'react'

import { ColoredLinkBlockProps } from '../ColoredLinkBlock'
import { MenuLinkProps } from '../MenuLink'

import * as SC from './styled'

export type MenuProps = {
  className?: string
  links: Array<MenuLinkProps>
  coloredLinks: Array<ColoredLinkBlockProps>
  route?: string
}

const Menu: FC<MenuProps> = ({ className, links, coloredLinks, route }) => {
  return (
    <SC.Menu className={className}>
      <SC.List>
        {links.map((link, idx) => (
          <SC.Link
            key={`menu-link-${idx}`}
            removeBorder={idx === links.length - 1}
            {...link}
            selected={route === link.href}
          />
        ))}
      </SC.List>
      <SC.ListBlock>
        {coloredLinks.map((link, idx) => (
          <SC.ColoredLink
            key={`menu-colored-link-${idx}`}
            {...link}
            selected={route === link.href}
          />
        ))}
      </SC.ListBlock>
    </SC.Menu>
  )
}

export default Menu
