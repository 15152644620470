import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const flagItemUpdate = getMutationService({
  mutation: gql(`
    mutation flagItemUpdate(
      $flagItemId: ID!
      $name: String
      $miraklCategoryLabel: String
      $order: Int
    ) {
      flagItemUpdate(
        flagItemId: $flagItemId
        name: $name
        miraklCategoryLabel: $miraklCategoryLabel
        order: $order
      ) {
        id
        name
        miraklRef
        responseType
        order
        items {
          id
          name
          miraklCategoryLabel
          cascadingGroups
          order
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
