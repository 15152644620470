import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const mailTemplateByType = getQueryService({
  query: gql(`
	query mailTemplateByType($type: MailTemplateType!) {
		mailTemplateByType(type: $type) {
			id
			type
			subjectFr
			bodyFr
			subjectEn
			bodyEn
			subjectIt
			bodyIt
			createdAt
			updatedAt
		}
	}
`),
  transformer: (response) => {
    return response
  },
})
