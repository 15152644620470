import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Link from '../Link'
import { colors, textStyles } from '../../theme'

export type ColorVariant = 'yellow' | 'green' | 'grey' | 'purple'

export const ColoredLinkBlock = styled(Link)<{
  $colorVariant: ColorVariant
  $forceColor?: string
  $selected?: boolean
}>`
  ${() => textStyles.titleH2};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6rem;
  padding: 0 2rem;
  border: none;
  text-align: center;
  text-decoration: none;
  transition: 'transform 0.1s';
  ${({ $forceColor }) =>
    $forceColor &&
    css`
      color: ${$forceColor};
    `};

  ${(props) => {
    switch (props.$colorVariant) {
      case 'green':
        return css`
          background-color: ${colors.appleGreen};
          &:hover {
            border: 1px solid;
            border-color: ${colors.chateauGreen};
          }
          ${props.$selected &&
          css`
            border: 1px solid;
            border-color: ${colors.chateauGreen};
          `}
        `
      case 'yellow':
        return css`
          background-color: ${rgba(colors.selectiveTextYellowDark, 0.15)};
          &:hover {
            border: 1px solid;
            border-color: ${colors.selectiveTextYellow};
          }
          ${props.$selected &&
          css`
            border: 1px solid;
            border-color: ${colors.selectiveTextYellow};
          `}
        `
      case 'grey':
        return css`
          background-color: ${colors.grayLight};
          &:hover {
            border: 1px solid;
            border-color: ${colors.tundora};
          }
          ${props.$selected &&
          css`
            border: 1px solid;
            border-color: ${colors.tundora};
          `}
        `
      case 'purple':
        return css`
          background-color: ${rgba(colors.royalPurple, 0.15)};
          &:hover {
            border: 1px solid;
            border-color: ${colors.royalPurple};
          }
          ${props.$selected &&
          css`
            border: 1px solid;
            border-color: ${colors.royalPurple};
          `}
        `
    }
  }};
`
