import React, { FC, useEffect, useRef, useState } from 'react'
import { QrReader, QrReaderProps } from 'react-qr-reader'

import * as SC from './styled'

export type QrScannerProps = Omit<
  QrReaderProps,
  'constraints' | 'containerStyle' | 'videoContainerStyle' | 'videoStyle' | 'ViewFinder'
> & {
  className?: string
  text?: string
  onDetect?: (result: string) => void
  openError?: () => void
}

const QrScanner: FC<QrScannerProps> = ({ className, text, onDetect, openError, ...scanner }) => {
  const [pathHeight, setPathHeight] = useState<number>(0)

  const pathRef = useRef<SVGPathElement>(null)
  useEffect(() => {
    const handlePathHeightChange = () => {
      setPathHeight(pathRef?.current?.getBoundingClientRect().height ?? 0)
    }

    handlePathHeightChange()

    window.addEventListener('resize', handlePathHeightChange)

    return () => {
      window.removeEventListener('resize', handlePathHeightChange)
    }
  }, [])

  return (
    <SC.Container className={className}>
      <QrReader
        {...scanner}
        constraints={{ facingMode: 'environment' }}
        containerStyle={{ width: 'auto', height: '100vh' }}
        videoContainerStyle={{ height: '100vh', paddingTop: '0' }}
        videoStyle={{ objectFit: 'cover' }}
        ViewFinder={() => (
          <SC.SvgWrapper>
            <SC.Svg
              width="375"
              height="768"
              viewBox="0 0 375 768"
              fill="none"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M375 0H0V768H375V0ZM40 73C28.9543 73 20 81.9543 20 93V564C20 575.046 28.9543 584 40 584H335C346.046 584 355 575.046 355 564V93C355 81.9543 346.046 73 335 73H40Z"
                fill="#232323"
              />
            </SC.Svg>
            <SC.SvgTablet
              width="375"
              height="768"
              viewBox="0 0 375 768"
              preserveAspectRatio="none"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M375 0H0V768H375V0ZM120 133C112.954 133 107 138.954 107 145V623C107 629.046 112.954 635 120 635H255C262.046 635 268 629.046 268 623V145C268 138.954 262.046 133 255 133H120Z"
                fill="#232323"
              />
            </SC.SvgTablet>
            <SC.Text $pathHeight={pathHeight}>{text}</SC.Text>
          </SC.SvgWrapper>
        )}
        onResult={(result, error) => {
          if (result && onDetect) {
            onDetect(result.getText())
          }
          if (error) {
            if (error.message === 'Permission denied' && openError) openError()
          }
        }}
      />
    </SC.Container>
  )
}

export default QrScanner
