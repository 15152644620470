import * as Yup from 'yup'

import { Icons } from '../../components/Icon'
import { sidebarNavMocks } from '../../components/SidebarNav/mocks'
import { layoutWithBottomActionsMocks } from '../../layouts/LayoutWithBottomActions/mocks'

import { BoxesAddFormValues, BoxesAddTemplateProps } from './index'

const basic: BoxesAddTemplateProps = {
  layoutProps: {
    ...layoutWithBottomActionsMocks.basic,
    bottomActions: {
      back: {
        leftIconProps: {
          icon: Icons.arrowLeft,
          width: 18,
          height: 18,
        },
        rounded: true,
      },
      next: {
        text: 'Valider',
        rightIconProps: {
          icon: Icons.check,
          width: 18,
          height: 18,
        },
      },
    },
    sidebarNav: {
      ...sidebarNavMocks.basic,
      menu: {
        links: [
          {
            text: 'Gestion colis',
          },
          {
            text: 'Séléction échantillons',
          },
          {
            text: 'Scan échantillon',
          },
          {
            text: 'Exposants',
          },
          {
            text: 'Salons',
          },
          {
            text: 'Paramètres',
            selected: true,
          },
          {
            text: 'Mon compte',
          },
        ],
      },
    },
  },
  title: 'Caisses de rangement',
  subtitle: 'Nouvelle caisse de rangement',
  forum: {
    name: 'Aucun forum associé',
  },
  form: {
    formikForm: {
      initialValues: {
        name: '',
      } as BoxesAddFormValues,
      validationSchema: Yup.object().shape({
        name: Yup.string().required(),
      }),
      onSubmit: () => undefined,
      validateOnChange: true,
    },
    fields: {
      name: {
        name: 'name',
        label: 'Nom de la caisse',
        required: true,
      },
    },
  },
}

export const boxesAddTemplateMocks = {
  basic,
}
