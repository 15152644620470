import React, { FC } from 'react'

import { ForgottenPasswordFormProps } from '../../components/forms/ForgottenPasswordForm'
import LayoutNotConnected, { LayoutNotConnectedProps } from '../../layouts/LayoutNotConnected'

import * as SC from './styled'

export type ForgottenPasswordProps = {
  className?: string
  title: string
  description: string
  message?: string | undefined
  layout?: LayoutNotConnectedProps
  form: ForgottenPasswordFormProps
}

const ForgottenPassword: FC<ForgottenPasswordProps> = ({
  layout,
  title,
  description,
  form,
  message,
}) => {
  return (
    <LayoutNotConnected {...layout}>
      <SC.StyledContentWrapper maxWidth={'small'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Description>{description}</SC.Description>
        <SC.Form {...form} />
        {message && <SC.SuccessMessage>{message}</SC.SuccessMessage>}
      </SC.StyledContentWrapper>
    </LayoutNotConnected>
  )
}

export default ForgottenPassword
