import styled from 'styled-components'

import Heading from '../../components/Heading'
import { breakpoints } from '../../theme'
import Summary from '../../components/Summary'

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const Title = styled(Heading)`
  margin: 0;
`
export const StyledSummary = styled(Summary)``

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.cinnabar};
  ${({ theme }) => theme.textStyles.textItalic};
  padding: 12px;
  text-align: center;
  max-width: 400px;
`
