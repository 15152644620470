import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const userDelete = getMutationService({
  mutation: gql(`
   mutation userDelete($userId: ID!) {
      userDelete(userId: $userId) {
        id
        email
        firstName
        lastName
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
