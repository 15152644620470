import styled, { css } from 'styled-components'

import { breakpoints, colors, fonts } from '../../theme'
import Link from '../Link'

export const Container = styled.div`
  display: flex;
  gap: 0.4rem;
  overflow: hidden;
  overflow-x: auto;
  padding-block: 1.2rem;
  max-width: calc(100vw - var(--scrollbar-width, 0px));
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.up('tablet')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    width: fit-content;
    max-width: unset;
    padding-right: 7rem;
  }
`

export const Value = styled.span`
  text-transform: uppercase;
  ${fonts.GothamBold}
`

export const Resume = styled.span`
  ${fonts.RobotoLight}
  display: none;
  font-size: 1.4rem;
  max-width: 200px;
  white-space: initial;
  ${breakpoints.up('tablet')} {
    padding-top: 0.5rem;
    display: flex;
  }
`

export const Step = styled(Link)<{ $isCurrent: boolean }>`
  display: inline-block;
  font-size: 1rem;
  color: ${colors.gray};
  letter-spacing: 0.1rem;
  line-height: 1.1;
  padding: 0.45rem 0.6rem;
  border-radius: 0.4rem;
  white-space: nowrap;
  text-decoration: none;
  ${({ $isCurrent }) =>
    $isCurrent &&
    css`
      background-color: ${({ theme }) => theme.colors.pureBlack};
      color: ${({ theme }) => theme.colors.pureWhite};
    `};
`
