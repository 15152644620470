import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { layoutSampleDataMocks } from '../../layouts/LayoutSampleData/mocks'
import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../components/form/fields/FormFieldRadioGroup/types'
import { FormFieldSwitchSize } from '../../components/form/fields/FormFieldSwitch/types'
import { router } from '../../router'
import { actions, selectors } from '../../redux'
import { returnSampleDataSteps } from '../../relay/DataSample/SampleDataSteps'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import {
  Exhibition,
  ExhibitionForum,
  SampleFlagGroupInput,
  SampleStatus,
} from '../../graphql/generated/graphql'
import { sampleDataGetKeyToUpdate } from '../../relay/DataSample/SampleDataGetKeyToUpdate'
import { SampleDataAllInfo } from '../../types/sampleDataAllInfo'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { ModalVariant } from '../../components/Modal/types'

import { SampleDataFormValues, SampleDataTemplateProps } from '.'

export const useSampleDataForumTemplateProps = (
  title: string,
  forums?: Exhibition | null
): SampleDataTemplateProps => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [applyToAllCollection, setApplyToAllCollection] = useState<boolean>(false)
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const [forceRefresh, setForceRefresh] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<ExhibitionForum[]>(forums ? forums.forums : [])
  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()
  const sampleData = useSelector(selectors.samples.sampleData)
  const config = useSelector(selectors.configuration.configurationInfo)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const ref = useRef<FormikProps<SampleDataFormValues>>(null)
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const collection = useSelector(selectors.exhibitors.collection)
  const sample = useSelector(selectors.samples.sample)
  const refresh = useSelector(selectors.auth.authByToken)

  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)

  // Force loader to make reloading more smooth
  useEffect(() => {
    const timerId = setTimeout(() => {
      setForceRefresh(false)
    }, 500)

    return () => clearTimeout(timerId)
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  useEffect(() => {
    if (sample?.applyToCollectionUniverse) {
      setApplyToAllCollection(sample?.applyToCollectionUniverse)
      return
    }

    if (sampleDataAllInfo) {
      const filteredItems = sampleDataAllInfo[lastSegment]
      if (
        filteredItems &&
        typeof filteredItems === 'object' &&
        'applyToAllCollection' in filteredItems &&
        filteredItems.applyToAllCollection
      ) {
        setApplyToAllCollection(filteredItems.applyToAllCollection)
      } else {
        setApplyToAllCollection(false)
      }
    }
  }, [lastSegment, sampleDataAllInfo, search, sample?.applyToCollectionUniverse])

  useEffect(() => {
    if (forums) {
      let filteredItems = forums.forums
      if (search !== '') {
        filteredItems = filteredItems.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      }
      setFilteredItems(filteredItems)
    }
  }, [dispatch, forums, search])

  const setFlagsItems = (data: any) => {
    const flagGroups: SampleFlagGroupInput[] = []
    if (data) {
      for (const key in data) {
        if (data[key] && data[key].items) {
          if (Array.isArray(data[key].items)) {
            const items = data[key]
            flagGroups.push({
              groupId: data[key].id,
              applyToCollection: data[key].applyToAllCollection,
              flagItems: items.items.map((flagItem: any) => ({
                itemId: flagItem.id,
                value: flagItem.value ? flagItem.value : null,
              })),
            })
          }
        }
      }
      return flagGroups
    }
  }

  const updateSample = useCallback(
    (data: SampleDataAllInfo) => {
      if (sampleId) {
        dispatch(
          actions.samples.sampleUpdateRequest({
            sampleId: sampleId,
            input: {
              flagGroups: setFlagsItems(data),
              forumId: data.forum.id,
              forumApplyToCollection: data.forum.applyToAllCollection,
            },
          })
        )
      }
    },
    [sampleId, dispatch]
  )

  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, dispatch, sample])

  return useMemo(
    () => ({
      title: title,
      isPending: forceRefresh,
      layoutProps: {
        ...layoutSampleDataMocks.basic,
        steps: {
          steps: returnSampleDataSteps(sampleData, exposantId, sampleId),
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(sampleDataRoutes(sampleData, 'prev'), {
                exposantId,
                sampleId,
              })
              navigate(link)
            },
          },
          note:
            collection?.internalNotes && collection?.internalNotes.length !== 0
              ? {
                  rounded: true,
                  number: collection?.internalNotes
                    ? collection?.internalNotes.length.toString()
                    : '0',
                  onClick: () => {
                    setIsNoteOpen(true)
                  },
                }
              : undefined,
          next: {
            text: 'Suivant',
            disabled: !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
            rightIconProps: {
              icon: Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
          nextPlus: {
            text: 'Rafraichir',
            isPending: refresh.pending,
            disabled: refresh.pending,
            onClick: () => {
              dispatch(actions.auth.authByTokenRequest())
            },
            rightIconProps: {
              icon: Icons.link,
              width: 18,
              height: 18,
            },
          },
        },
        title: `${sampleData.exhibitorRef} #${sampleId}`,
        notes: {
          title: "Note interne sur l'exposant",
          notes:
            collection && collection.internalNotes && collection.internalNotes.length !== 0
              ? collection.internalNotes.map((note) => ({
                  text: note.body,
                  author: {
                    fullName:
                      collection?.internalNotes.length !== 0
                        ? `par ${note.user.lastName} ${note.user.firstName}`
                        : '',
                    date:
                      collection?.internalNotes.length !== 0
                        ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                        : '',
                  },
                }))
              : [],
          tags: {
            newTag: collection?.exhibitor?.isNew,
            returnTag: collection?.samplesToReturn !== 0,
          },
          onClose: () => setIsNoteOpen(false),
        },
        noteModal: {
          onClose: () => setIsNoteOpen(false),
          isOpen: isNoteOpen,
          variant: ModalVariant.Small,
        },
        marketplaceWarning:
          marketplaceDateDaysLeft && marketplaceDateHoursLeft
            ? {
                number: `J - ${marketplaceDateDaysLeft}`,
                text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
                redBg: true,
              }
            : undefined,
        ...layoutProps,
      },
      formikForm: {
        title: title,
        innerRef: ref,
        initialValues: {
          allCollection: false,
          radios: sampleData[lastSegment] ? sampleData[lastSegment] : '',
        } as SampleDataFormValues,
        validationSchema: Yup.object().shape({
          radios: Yup.string().required(),
        }),
        onSubmit: (values) => {
          const keyToUpdate: any = sampleDataGetKeyToUpdate(sampleData)
          const filteredItems = forums?.forums.filter((item) => item.name === values.radios) ?? []
          const updatedSampleDataAllInfo: SampleDataAllInfo = {
            ...sampleDataAllInfo,
            [keyToUpdate]: {
              id: filteredItems[0].id,
              applyToAllCollection: applyToAllCollection,
            },
          }
          dispatch(
            actions.samples.setSampleData({
              ...sampleData,
              [keyToUpdate]: values.radios,
            })
          )
          dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
          const link = router(sampleDataRoutes(sampleData, 'next'), {
            exposantId,
            sampleId,
          })
          updateSample(updatedSampleDataAllInfo)
          navigate(link)
        },
        onContextUpdate: (context) => {
          setIsValid(context.isValid)
        },
        validateOnChange: true,
        validateOnMount: true,
      },
      allCollection: {
        name: 'allCollection',
        inlineLabel: 'Appliquer à toute la collection',
        variantSize: FormFieldSwitchSize.Small,
        value: applyToAllCollection,
        onChange: (value) => {
          setApplyToAllCollection(value.target.value)
        },
      },
      search: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      radios: {
        name: 'radios',
        required: true,
        optionsFont: VariantRadioFont.SmallBold,
        optionsSize: VariantRadioSize.big,
        options:
          forums && filteredItems
            ? filteredItems.map((item: ExhibitionForum) => ({
                label: item.name,
                value: item.name,
                backgroundColor: getContrastColor(item.color as string),
                color: item.color as string,
                dotted: true,
              }))
            : [],
      },
    }),
    [
      applyToAllCollection,
      collection,
      dispatch,
      exposantId,
      filteredItems,
      forceRefresh,
      forums,
      isNoteOpen,
      isValid,
      lastSegment,
      layoutProps,
      marketplaceDateDaysLeft,
      marketplaceDateHoursLeft,
      navigate,
      refresh.pending,
      sampleData,
      sampleDataAllInfo,
      sampleId,
      search,
      title,
      updateSample,
    ]
  )
}
