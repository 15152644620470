import React, { FC } from 'react'
import { VictoryPie } from 'victory'

import * as SC from './styled'

export type SampleChartProps = {
  label?: string[]
  data: { title: string; number: number; color: string }[]
  dataBefore?: boolean
  subtitle?: string
}

const SampleChart: FC<SampleChartProps> = ({ data, label, dataBefore, subtitle }) => {
  const convertedData = data
    ? data.map((item) => ({ x: item.title, y: item.number, color: item.color }))
    : []

  return (
    <SC.Content>
      {dataBefore && (
        <SC.Section>
          {subtitle && <SC.Subtitle tag="h6" text={subtitle} />}
          <SC.DataContainer>
            {data &&
              data.map((item, key) => (
                <SC.Data key={key}>
                  <SC.Color style={{ backgroundColor: item.color }} />
                  <SC.DataContent>
                    <SC.Title>{item.title}</SC.Title>
                    <SC.Number>{item.number}</SC.Number>
                  </SC.DataContent>
                </SC.Data>
              ))}
          </SC.DataContainer>
        </SC.Section>
      )}
      <SC.Svg>
        <svg viewBox="0 0 400 400">
          <VictoryPie
            standalone={false}
            width={400}
            height={400}
            data={convertedData}
            innerRadius={140}
            theme={{
              pie: {
                padding: 0,
              },
            }}
            style={{
              data: {
                fill: (d) => d.datum.color,
              },
              labels: { display: 'none' },
            }}
          />
          <SC.Label textAnchor="middle" x={200} y={200} text={label} style={{ fontSize: 30 }} />
        </svg>
      </SC.Svg>
      {!dataBefore && (
        <SC.Section>
          {subtitle && <SC.Subtitle tag="h6" text={subtitle} />}
          <SC.DataContainer>
            {data &&
              data.map((item, key) => (
                <SC.Data key={key}>
                  <SC.Color style={{ backgroundColor: item.color }} />
                  <SC.DataContent>
                    <SC.Title>{item.title}</SC.Title>
                    <SC.Number>{item.number}</SC.Number>
                  </SC.DataContent>
                </SC.Data>
              ))}
          </SC.DataContainer>
        </SC.Section>
      )}
    </SC.Content>
  )
}

export default SampleChart
