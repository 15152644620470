import React, { FC } from 'react'

import LayoutSampleSheet, { LayoutSampleSheetProps } from '../../layouts/LayoutSampleSheet'
import LabelCard, { LabelCardProps } from '../../components/LabelCard'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type SampleSheetLabelTemplateProps = {
  layoutProps?: LayoutSampleSheetProps
  pending?: boolean
  title?: string
  labelCard?: LabelCardProps
  labelExport?: ActionButtonProps
  loungeExport?: ActionButtonProps
}

const SampleSheetLabelTemplate: FC<SampleSheetLabelTemplateProps> = ({
  layoutProps,
  pending,
  labelCard,
  labelExport,
  loungeExport,
  title,
}) => {
  return (
    <LayoutSampleSheet {...layoutProps}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          {title && <SC.Title tag="h2" text={title} />}

          <SC.Container>
            {labelCard && <LabelCard {...labelCard} />}
            {labelExport && <SC.Button {...labelExport} />}
            {loungeExport && <SC.Button {...loungeExport} />}
          </SC.Container>
        </>
      )}
    </LayoutSampleSheet>
  )
}

export default SampleSheetLabelTemplate
