import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { useExponentSheetTabsProps } from '../../relay/ExponentSheet/UseExponentSheetTabsProps'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { Collection, Exhibitor, ExhibitorContact } from '../../graphql/generated/graphql'
import { useLayoutExponentSheetTemplateProps } from '../../layouts/LayoutExponentSheet/useProps'
import { selectors } from '../../redux'
import { convertFiltersNameFirstLetter } from '../../components/Filters/utils'

import { ExponentSheetInfosTemplateProps } from '.'

export const useExponentSheetInfosTemplateProps = (
  exhibitor: Exhibitor | null,
  collection: Collection | null
): ExponentSheetInfosTemplateProps => {
  const { exposantId } = useParams<{ exposantId: string }>()
  const { apiId } = useParams<{ apiId: string }>()
  const user = useSelector(selectors.auth.user)
  const tabs = useExponentSheetTabsProps(exposantId, apiId, 'infos', user?.role)
  const [modeContact, setModeContact] = useState<ExhibitorContact | undefined>(undefined)
  const [marketplaceContact, setMarketplaceContact] = useState<ExhibitorContact | undefined>(
    undefined
  )

  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const layoutPropsExponent = useLayoutExponentSheetTemplateProps(exhibitor, collection)

  useEffect(() => {
    if (collection) {
      for (const contact of collection.exhibitor.contacts) {
        if (contact.isAccountTypeFashion && modeContact === undefined) {
          setModeContact(contact)
        }
        if (contact.isAccountTypeMarketplace && marketplaceContact === undefined) {
          setMarketplaceContact(contact)
        }
        if (modeContact && marketplaceContact) {
          break
        }
      }
    }
  }, [collection, marketplaceContact, modeContact])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...layoutPropsExponent,
        bottomActions: {
          ...layoutPropsExponent.bottomActions,
          back: undefined,
          next: undefined,
        },
        tabs: tabs,
      },
      pending: !collection,
      text: "Si certaines informations sont erronées, merci de contacter l'équipe exposants.",
      infoLounge: collection
        ? {
            title: 'À propos du salon',
            items: {
              infos: [
                { title: 'Numéro de stand', text: collection?.stand ? collection?.stand : 'N/A' },
              ],
            },
          }
        : undefined,
      infoMode: modeContact
        ? {
            title: 'Contact Mode',
            items: {
              infos: [
                {
                  title: 'Nom du contact',
                  text: `${convertFiltersNameFirstLetter(
                    modeContact.firstName
                  )} ${convertFiltersNameFirstLetter(modeContact.lastName)}`,
                },
                {
                  title: 'Langue parlée',
                  text: modeContact.language
                    ? convertFiltersNameFirstLetter(modeContact.language)
                    : 'anglais',
                },
                {
                  title: 'E-mail',
                  text: modeContact.email ? modeContact.email : 'N/A',
                  noUppercase: true,
                },
                {
                  title: 'Téléphone',
                  text: modeContact.phone1 ? modeContact.phone1 : 'N/A',
                },
              ],
            },
          }
        : undefined,
      infoMarketplace: marketplaceContact
        ? {
            title: 'Contact Marketplace',
            items: {
              infos: [
                {
                  title: 'Nom du contact',
                  text: `${marketplaceContact.firstName} ${marketplaceContact.lastName}`,
                },
                {
                  title: 'Langue parlée',
                  text: marketplaceContact.language ? marketplaceContact.language : 'anglais',
                },
                {
                  title: 'E-mail',
                  text: marketplaceContact.email ? marketplaceContact.email : 'N/A',
                  noUppercase: true,
                },
                {
                  title: 'Téléphone',
                  text: marketplaceContact.phone1 ? marketplaceContact.phone1 : 'N/A',
                },
              ],
            },
          }
        : undefined,
    }),
    [collection, layoutProps, layoutPropsExponent, marketplaceContact, modeContact, tabs]
  )
}
