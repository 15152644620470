import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { SearchBarProps } from '../../components/SearchBar'
import { ExponentListProps } from '../../components/ExponentList'
import { FiltersTagsProps } from '../../components/FiltersTags'
import SimpleLoader from '../../components/SimpleLoader'

import * as SC from './styled'

export type ExponentIndexTemplateProps = {
  title: string
  layoutProps?: LayoutWithBottomActionsProps
  searchBar?: SearchBarProps
  cards?: ExponentListProps
  filtersTags?: FiltersTagsProps
  pending?: boolean
}

const ExponentIndexTemplate: FC<ExponentIndexTemplateProps> = ({
  layoutProps,
  title,
  searchBar,
  cards,
  filtersTags,
  pending,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <SC.StyledContentWrapper maxWidth="default">
        <SC.Title tag={'h2'} text={title} />
        <SC.Search {...searchBar} />
        {filtersTags && <SC.StyledFiltersTags {...filtersTags} />}
        {cards && cards.cards && cards.cards?.length !== 0 && (
          <SC.ContentContainer>
            <SC.StyledExponentList {...cards} />
          </SC.ContentContainer>
        )}
        {cards && cards.cards && cards.cards?.length === 0 && !pending && (
          <SC.ContentContainer>Aucun exposant</SC.ContentContainer>
        )}
        {pending && (
          <SC.LoaderContainer>
            {' '}
            <SimpleLoader />
          </SC.LoaderContainer>
        )}
      </SC.StyledContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default ExponentIndexTemplate
