import React, { FC, useCallback, useRef } from 'react'

import LayoutSampleData, { LayoutSampleDataProps } from '../../layouts/LayoutSampleData'
import { SummaryProps } from '../../components/Summary'
import { CameraProps } from '../../components/Camera'
import { ModalFullScreenProps } from '../../components/ModalFullScreen'
import { ActionButtonProps } from '../../components/ActionButton'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { FormFieldInputProps } from '../../components/form/fields/FormFieldInput'
import { FormFieldCheckboxProps } from '../../components/form/fields/FormFieldCheckbox'
import { FormFieldInputNumberProps } from '../../components/form/fields/FormFieldInputNumber'
import { FormikFormProps } from '../../components/form/FormikForm'
import FormikField from '../../components/form/FormikField'
import {
  VariantCheckboxFont,
  VariantCheckboxSize,
} from '../../components/form/fields/FormFieldCheckbox/types'
import { ModalUploadProps } from '../../components/ModalUpload'
import { ModalProps } from '../../components/Modal'
import { NoteModaleProps } from '../../components/NoteModale'
import { SearchBarProps } from '../../components/SearchBar'
import { FormFieldSwitchProps } from '../../components/form/fields/FormFieldSwitch'

import * as SC from './styled'

export type SampleDataTraineeFormValues = {
  name?: string
  checkboxes?: {
    [key: string]: {
      checked?: boolean
      percentage?: number
    }
  }
}

export type SampleDataTraineeTemplateProps = {
  layoutProps?: LayoutSampleDataProps
  title?: string
  search?: SearchBarProps
  isPending: boolean
  allCollection?: FormFieldSwitchProps
  uploadHandler?: (file: string) => void
  handleRetake?: (index: number) => void
  handleRetakeUpload?: (index: number) => void
  // camera
  camera?: {
    cameraModal?: ModalFullScreenProps
    cameraProps?: CameraProps
    openCameraHandler?: () => void
    closeCameraHandler?: () => void
    currentImage?: string
    newImageButton?: ActionButtonProps
    retakeButton?: ActionButtonProps
    uploadButton?: ActionButtonProps
    uploadModal?: ModalUploadProps
  }
  // forms
  form?: {
    formikForm: FormikFormProps<SampleDataTraineeFormValues>
    name?: FormFieldInputProps
    labelRef?: string
    exhibitorRef?: string
    sampleId?: string
    checkboxesChecked?: string[]
    checkboxes?: {
      name: string
      checked?: FormFieldCheckboxProps
      percentage?: FormFieldInputNumberProps
    }[]
  }
  // summary
  images?: string[]
  summary?: SummaryProps
  pictureButton?: ActionButtonProps
  noteModal?: ModalProps
  notes?: NoteModaleProps
  onOpen?: (picture: string) => void
}

const SampleDataTraineeTemplate: FC<SampleDataTraineeTemplateProps> = ({
  layoutProps,
  title,
  search,
  isPending,
  camera,
  form,
  summary,
  allCollection,
  images,
  onOpen,
  uploadHandler,
  handleRetake,
  handleRetakeUpload,
}) => {
  const updatedData =
    form &&
    form.checkboxes &&
    form?.checkboxes.map((item) => {
      const isChecked = form.checkboxesChecked && form.checkboxesChecked.includes(item.name)
      return {
        ...item,
        isChecked: isChecked ? { checked: true } : { checked: false },
      }
    })
  const inputRef = useRef<HTMLInputElement>(null)
  const onChange = (e: any) => {
    const files = e.target.files
    const file = files[0]
    getBase64(file)
  }

  const onLoad = (fileString?: any) => {
    if (!fileString) return
    uploadHandler?.(fileString)
  }

  const getBase64 = (file: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      onLoad(reader.result)
    }
  }

  const handleButtonClick = useCallback(() => {
    if (!inputRef.current) return
    inputRef.current.click()
  }, [])

  return (
    <LayoutSampleData {...layoutProps} camera={camera}>
      {isPending ? (
        <SC.Loader />
      ) : (
        <SC.Content>
          {title && <SC.Title tag="h2" text={title} />}
          {search && <SC.Search {...search} />}
          {images &&
            onOpen &&
            images.map((image, key) => (
              <SC.PictureTrigger key={key}>
                <SC.Picture onClick={() => onOpen(image)}>
                  <SC.Image src={image} alt=" " />
                </SC.Picture>
                {camera?.retakeButton && (
                  <SC.RetakeButton
                    variantSize={ActionButtonSize.Small}
                    variantScheme={ActionButtonScheme.Tertiary}
                    rightIconProps={{
                      icon: Icons.camera,
                      width: 16,
                      height: 16,
                    }}
                    {...camera.retakeButton}
                    onClick={() => {
                      handleRetake && handleRetake(key)
                    }}
                  />
                )}
                {camera?.uploadButton && (
                  <SC.RetakeButton
                    variantSize={ActionButtonSize.Small}
                    variantScheme={ActionButtonScheme.Tertiary}
                    rightIconProps={{
                      icon: Icons.camera,
                      width: 16,
                      height: 16,
                    }}
                    {...camera.uploadButton}
                    onClick={() => {
                      handleButtonClick()
                      handleRetakeUpload && handleRetakeUpload(key)
                    }}
                  />
                )}
                <SC.Separator />
              </SC.PictureTrigger>
            ))}
          {summary && <SC.StyledSummary {...summary} />}
          {camera?.currentImage ? (
            <>
              <SC.Picture>
                <SC.Image src={camera.currentImage} alt=" " />
              </SC.Picture>
              {camera?.newImageButton && (
                <SC.RetakeButton
                  variantSize={ActionButtonSize.Small}
                  variantScheme={ActionButtonScheme.Tertiary}
                  rightIconProps={{
                    icon: Icons.camera,
                    width: 16,
                    height: 16,
                  }}
                  {...camera.newImageButton}
                />
              )}
              {camera?.uploadButton && (
                <SC.RetakeButton
                  variantSize={ActionButtonSize.Small}
                  variantScheme={ActionButtonScheme.Tertiary}
                  rightIconProps={{
                    icon: Icons.camera,
                    width: 16,
                    height: 16,
                  }}
                  {...camera.uploadButton}
                  onClick={handleButtonClick}
                />
              )}
              <SC.FormInput>
                <input
                  ref={inputRef}
                  type="file"
                  onChange={onChange}
                  accept="image/png, image/jpeg, image/gif, image/webp"
                />
              </SC.FormInput>
            </>
          ) : (
            camera?.openCameraHandler && (
              <>
                <SC.CameraButton type="button" onClick={camera.openCameraHandler}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 100">
                    <rect width="100" height="100" fill="#F2F2F2" rx="50" />
                    <path
                      stroke="#232323"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M61.5927 61.9565H39.8536c-.5766 0-1.1295-.2291-1.5372-.6368-.4077-.4077-.6367-.9606-.6367-1.5372V44.5652c0-.5766.229-1.1295.6367-1.5372.4077-.4077.9606-.6368 1.5372-.6368h4.3478l2.1739-3.2608h8.6957l2.1739 3.2608h4.3478c.5766 0 1.1295.2291 1.5372.6368.4077.4077.6367.9606.6367 1.5372v15.2173c0 .5766-.229 1.1295-.6367 1.5372-.4077.4077-.9606.6368-1.5372.6368Z"
                    />
                    <path
                      stroke="#232323"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M50.7232 56.5217c2.7014 0 4.8913-2.1899 4.8913-4.8913s-2.1899-4.8913-4.8913-4.8913-4.8913 2.1899-4.8913 4.8913 2.1899 4.8913 4.8913 4.8913Z"
                    />
                    <rect
                      width="17.3913"
                      height="17.3913"
                      x="57.9727"
                      y="52.1738"
                      fill="#232323"
                      rx="8.69565"
                    />
                    <path
                      stroke="#F9F9F9"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M71.4027 60.8693h-4.7346m0 0h-4.7345m4.7345 0v4.7345m0-4.7345v-4.7345"
                    />
                  </svg>
                </SC.CameraButton>
                {camera?.uploadButton && (
                  <SC.RetakeButton
                    variantSize={ActionButtonSize.Small}
                    variantScheme={ActionButtonScheme.Tertiary}
                    rightIconProps={{
                      icon: Icons.camera,
                      width: 16,
                      height: 16,
                    }}
                    {...camera.uploadButton}
                    onClick={handleButtonClick}
                  />
                )}
                <SC.FormInput>
                  <input
                    ref={inputRef}
                    type="file"
                    onChange={onChange}
                    accept="image/png, image/jpeg, image/gif, image/webp"
                  />
                </SC.FormInput>
              </>
            )
          )}
          {form && (
            <SC.Form {...form.formikForm}>
              {allCollection && (
                <FormikField
                  Component={SC.AllCollectionSwitch}
                  {...allCollection}
                  textValue={allCollection.value ? 'true' : 'false'}
                />
              )}
              {form.name && (
                <SC.RefContainer>
                  {form.labelRef && <SC.Label tag="h5" text={form.labelRef} />}
                  <SC.InputContainer>
                    <SC.ExhibitorName>{form.exhibitorRef}</SC.ExhibitorName>
                    <FormikField Component={SC.InputName} hasFloatingLabel={true} {...form.name} />
                    <SC.ExhibitorName>{form.sampleId}</SC.ExhibitorName>
                  </SC.InputContainer>
                </SC.RefContainer>
              )}
              {updatedData && updatedData.length > 0 && (
                <SC.Checkboxes>
                  {updatedData.map((check, index) => (
                    <SC.FieldSet key={`SampleDataTrainee-check-${index}`}>
                      <FormikField
                        Component={SC.StyledCheckbox}
                        {...check.checked}
                        checked={check.isChecked.checked}
                        variantSize={VariantCheckboxSize.big}
                        variantFont={VariantCheckboxFont.BoldCaps}
                      />
                      {check.percentage && (
                        <SC.Percentage>
                          <FormikField Component={SC.StyledInput} {...check.percentage} />
                          {` `}%
                        </SC.Percentage>
                      )}
                    </SC.FieldSet>
                  ))}
                </SC.Checkboxes>
              )}
            </SC.Form>
          )}
        </SC.Content>
      )}
    </LayoutSampleData>
  )
}

export default SampleDataTraineeTemplate
