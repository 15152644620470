import { createGlobalStyle } from 'styled-components'

import { fontsFaces } from './fonts'

const GlobalStyles = createGlobalStyle`
  ${fontsFaces}

  :root {
    --header-height: 5.4rem
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body,
  #root {
    margin: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background: ${(props) => props.theme.colors.pureWhite};
    color: ${(props) => props.theme.colors.mineShaft};
    ${({ theme }) => theme.textStyles.textBook};
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
`

export default GlobalStyles
