import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectors } from '../../redux'
import { Icons } from '../../components/Icon'
import { useDefaultLayoutTemplateProps } from '../DefaultLayout/useProps'
import { returnLoungeConfigSteps } from '../../relay/LoungeConfig/LoungeConfigSteps'

import { LayoutLoungeConfigProps } from '.'

export const useLayoutLoungeConfigTemplateProps = (): LayoutLoungeConfigProps => {
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)

  return useMemo(() => {
    return {
      ...layoutProps,
      bottomActions: {
        back: {
          leftIconProps: {
            icon: Icons.arrowLeft,
            width: 18,
            height: 18,
          },
          rounded: true,
        },
        next: {
          text: 'Suivant',
          rightIconProps: {
            icon: Icons.arrowRight,
            width: 18,
            height: 18,
          },
        },
      },
      title:
        //@ts-ignore
        loungeConfig && loungeConfig.edit && loungeConfig.edit === true
          ? "configuration d'un salon"
          : "configuration d'un nouveau salon",
      steps: { steps: returnLoungeConfigSteps(loungeConfig) },
    }
  }, [layoutProps, loungeConfig])
}
