import styled from 'styled-components'

import Heading from '../../components/Heading'
import { fonts, colors, breakpoints } from '../../theme'
import ActionButton from '../../components/ActionButton'
import StatutSelection from '../../components/StatutSelection'
import FormFieldRadioGroup from '../../components/form/fields/FormFieldRadioGroup'
import FormikForm from '../../components/form/FormikForm'
import BoxInfo from '../../components/BoxInfo'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
`

export const SubTitle = styled.div`
  ${fonts.GothamBold}
  color: ${colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
`

export const Text = styled.div`
  ${fonts.RobotoLight}
  color: ${colors.gray};
  font-size: 1.2rem;
  letter-spacing: 1px;
  margin-bottom: 2.2rem;
`

export const CTA = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
`

export const StatutSelect = styled(StatutSelection)`
  width: 100%;
  justify-content: space-between;
`
export const FormFields = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 1rem;
`

export const StyledRadiosGroup = styled(FormFieldRadioGroup)``

export const Form = styled(FormikForm)`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const StyledBoxInfo = styled(BoxInfo)`
  margin-bottom: 24px;
`
