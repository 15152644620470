import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleHistoryById = getQueryService({
  query: gql(`
    query sampleHistoryById($sampleId: ID!) {
        sampleHistoryById(sampleId: $sampleId) {
            label
            subLabel
            user
            date
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
