import styled from 'styled-components'

import Modal from '../Modal'
import ActionButton from '../ActionButton'
import Icon, { Icons } from '../Icon'
import Heading from '../Heading'
import Html from '../Html'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.4rem;
`

export const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Close = styled(Icon).attrs({ icon: Icons.close, width: 20, height: 20 })``

export const Title = styled(Heading)``

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textBook}
  & b,
  & strong {
    font-weight: 700;
  }
`

export const Form = styled.form`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`
export const StyledButton = styled(ActionButton)``
