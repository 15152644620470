import React, { FC } from 'react'

import LayoutNotConnected, { LayoutNotConnectedProps } from '../../layouts/LayoutNotConnected'
import { ResetPasswordFormProps } from '../../components/forms/ResetPasswordForm'

import * as SC from './styled'

export type ResetPasswordProps = {
  className?: string
  title: string
  layout?: LayoutNotConnectedProps
  form: ResetPasswordFormProps
}

const ResetPassword: FC<ResetPasswordProps> = ({ layout, title, form }) => {
  return (
    <LayoutNotConnected {...layout}>
      <SC.StyledContentWrapper maxWidth={'small'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Form {...form} />
      </SC.StyledContentWrapper>
    </LayoutNotConnected>
  )
}

export default ResetPassword
