import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleDataTemplate from '../../../templates/SampleData'
import { selectors } from '../../../redux'
import { useSampleDataTemplateProps } from '../../../templates/SampleData/useProps'

const ExponentSampleDataAspect: React.FC<RouteProps> = () => {
  const form = useSelector(selectors.samples.sampleFormByUniverse)
  const templateProps = useSampleDataTemplateProps(
    'Aspect',
    form.find(
      (item) =>
        item.miraklRef === 'yarn_aspect' ||
        item.miraklRef === 'aspects' ||
        item.miraklRef === 'aspect'
    )
  )
  return <SampleDataTemplate {...templateProps} />
}

export default ExponentSampleDataAspect
