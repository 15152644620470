import React, { FC, useState } from 'react'
import Collapsible, { CollapsibleProps } from 'react-collapsible'
import { Maybe } from 'graphql/jsutils/Maybe'

import { Icons } from '../Icon'

import * as SC from './styled'
import { VariantCollapsibleColoredFont } from './types'

export type VariantColored =
  | 'leather'
  | 'sourcing'
  | 'accessories'
  | 'inspiration'
  | 'design'
  | 'bursted'
  | 'default'

export type CollapsibleColoredItemProps = Omit<CollapsibleProps, 'trigger'> & {
  className?: string
  title?: string
  dotted?: boolean
  multipleTitle?: string[]
  onClick?: () => void
  itemNbr?: string
  children?: React.ReactNode
  operatorIcon?: boolean
  variantFont?: VariantCollapsibleColoredFont
  // for preestablished colors
  variant?: VariantColored
  // for custom colors
  backgroundColor?: Maybe<string>
  color?: string
}

const CollapsibleColoredItem: FC<CollapsibleColoredItemProps> = ({
  className,
  title,
  dotted,
  multipleTitle,
  onClick,
  children,
  variant = 'default',
  backgroundColor,
  color,
  operatorIcon,
  itemNbr,
  variantFont,
  ...collapsible
}) => {
  // Change to controlled
  const [isOpen, setIsOpen] = useState<boolean>(collapsible.open || false)

  return (
    <SC.CollapsibleItem
      className={className}
      $variant={variant}
      $backgroundColor={backgroundColor}
      $color={color}
    >
      <Collapsible
        {...collapsible}
        onTriggerOpening={() => setIsOpen(true)}
        onTriggerClosing={() => setIsOpen(false)}
        trigger={
          <SC.Head className="head" onClick={onClick}>
            <SC.HeaderTop $variant={variant}>
              <SC.TitleContainer>
                {title && (
                  <>
                    {dotted && <SC.StyledDot />}
                    <SC.Title $variantFont={variantFont}>{title}</SC.Title>
                  </>
                )}
                {multipleTitle &&
                  multipleTitle.map((item) => (
                    <>
                      <SC.StyledDot />
                      <SC.Title $variantFont={variantFont}>{item}</SC.Title>
                    </>
                  ))}
              </SC.TitleContainer>
              {!collapsible.triggerDisabled && (
                <>
                  {isOpen ? (
                    <SC.Minus
                      icon={operatorIcon ? Icons.minus : Icons.caretTop}
                      width={operatorIcon ? 14 : 10}
                      height={operatorIcon ? 14 : 10}
                    />
                  ) : (
                    <SC.Plus
                      icon={operatorIcon ? Icons.plus : Icons.caretDown}
                      width={operatorIcon ? 14 : 10}
                      height={operatorIcon ? 14 : 10}
                    />
                  )}
                </>
              )}
            </SC.HeaderTop>
            {itemNbr && !isOpen && itemNbr !== '0' ? (
              <SC.HeaderBottom>
                {itemNbr} sous-forum{itemNbr !== '1' && 's'}
              </SC.HeaderBottom>
            ) : itemNbr && !isOpen ? (
              <SC.HeaderBottom>Aucun sous-forum</SC.HeaderBottom>
            ) : null}
          </SC.Head>
        }
        easing={'ease'}
      >
        <SC.Content>{children}</SC.Content>
      </Collapsible>
    </SC.CollapsibleItem>
  )
}

export default CollapsibleColoredItem
