import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import { FiltersProps } from '../../components/Filters'
import { modalFullScreenMocks } from '../../components/ModalFullScreen/mocks'
import { Icons } from '../../components/Icon'
import {
  parseFiltersExhibitorsFromStore,
  parseFiltersSamplesFromStore,
} from '../../components/Filters/utils'
import { actions, selectors } from '../../redux'
import { FiltersFormValues, UniversesFormValues } from '../../components/forms/FiltersForm'
import { FilterTagProps } from '../../components/FilterTag'

export const useFiltersTemplateProps = ({
  tags,
  setTags,
  handleFiltersClick,
  openFilters,
  setUniverse,
  universe,
  isExponent,
  setFilters,
}: {
  tags: FilterTagProps[]
  setTags: React.Dispatch<React.SetStateAction<FilterTagProps[]>>
  handleFiltersClick: () => void
  openFilters: boolean
  setUniverse: Dispatch<SetStateAction<string | undefined>>
  universe: string | undefined
  isExponent: boolean
  setFilters?: React.Dispatch<React.SetStateAction<FiltersFormValues | undefined>>
}): FiltersProps => {
  const dispatch = useDispatch()
  const config = useSelector(selectors.configuration.configurationInfo)
  const filters = useSelector(selectors.samples.sampleFilters)
  const samplesCount = useSelector(selectors.samples.samplesCount)
  const samplesPaginator = useSelector(selectors.samples.samplesPaginator)
  const exhibitorsPaginator = useSelector(selectors.exhibitors.exhibitors)
  const universes = config?.dictionaries.find((item) => item.type && item.type === 'UNIVERSES')
  const filtersParsedSamples = filters && !isExponent && parseFiltersSamplesFromStore(filters)
  const filtersParsedExhibitors =
    config && isExponent && parseFiltersExhibitorsFromStore(config?.dictionaries)
  const [isValid, setIsValid] = useState<boolean>(false)
  const ref = useRef<FormikProps<UniversesFormValues>>(null)
  useEffect(() => {
    if (config)
      dispatch(
        actions.samples.sampleCountByUniverseRequest({
          exhibitionApiId: config?.exhibitionActiveApiId.toString(),
        })
      )
  }, [config, config?.exhibitionActiveApiId, dispatch])

  const totalReturn = useCallback((): string => {
    if (!isExponent) {
      const text =
        samplesPaginator && samplesPaginator?.total === 0
          ? `Pas de résultat`
          : samplesPaginator && samplesPaginator?.total === 1
          ? 'Voir le résultat'
          : `Voir les ${samplesPaginator?.total} résultats`
      return text
    }
    if (isExponent) {
      const text =
        exhibitorsPaginator && exhibitorsPaginator?.paginatorInfo.total === 0
          ? `Pas de résultat`
          : exhibitorsPaginator && exhibitorsPaginator?.paginatorInfo.total === 1
          ? 'Voir le résultat'
          : `Voir les ${exhibitorsPaginator?.paginatorInfo.total} résultats`
      return text
    } else return ''
  }, [isExponent, samplesPaginator, exhibitorsPaginator])

  return useMemo(
    () => ({
      ...modalFullScreenMocks.basic,
      backButtonProps: {
        text: 'Filtrer',
        open: !openFilters,
        onClick: () => {
          handleFiltersClick()
        },
      },
      title: 'Filtres',
      iconsColor: 'dark',
      isOpen: true,
      tags: tags.map((tag) => ({ text: tag.text, category: tag.category })),
      form: {
        universes: universes,
        universe: universes
          ? (universes?.items.find((item) => item.key === universe)?.value as string)
          : undefined,
        universeChoosen: universe ? true : false,
        formikFormUniverses: {
          innerRef: ref,
          initialValues: {
            universe: '',
          },
          validationSchema: Yup.object().shape({
            universe: Yup.string().required(),
          }),
          onSubmit: (values) => {
            setUniverse(values.universe)
          },
          onContextUpdate(context) {
            setIsValid(context.isValid)
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        formikForm: {
          initialValues: {
            Forum: [],
            Secteur: [],
            Countries: [],
          },
          onContextUpdate: (context) => {
            setFilters && setFilters(context.values)
            const newTags = Object.entries(context.values).flatMap(([category, values]) => {
              // Handle categories with single values
              if (!Array.isArray(values)) {
                return { text: values, category }
              }
              // Handle categories with multiple values (e.g., Secteur)
              return values.map((value) => ({ text: value, category }))
            })

            const newTagsWithUniverse = universe
              ? [
                  ...newTags,
                  {
                    text: universes?.items.find((item) => item.key === universe)?.value as string,
                    category: 'univers',
                  },
                ]
              : newTags

            // const tagsWithoutDuplicates = Array.from(new Set(newTagsWithUniverse))
            if (
              !isEqual(newTagsWithUniverse.length, tags.length) ||
              !isEqual(newTagsWithUniverse.sort(), tags.sort())
            ) {
              setTags([...newTagsWithUniverse])
            }
          },
          onSubmit: (values) => console.log('values', values),
          validateOnChange: true,
        },
        fields: isExponent
          ? filtersParsedExhibitors && filtersParsedExhibitors
          : filtersParsedSamples && filtersParsedSamples,
        resetButton: {
          text: 'Réinitialiser les filtres',
        },
        submitButton: {
          text: totalReturn(),
          onClick: handleFiltersClick,
          rightIconProps: { icon: Icons.arrowRight, width: 20 },
        },
        submitUniverseButton: {
          text: `Valider`,
          disabled: !isValid,
          onClick: () => ref.current && ref.current.submitForm(),
          rightIconProps: { icon: Icons.check, width: 20 },
        },
        cleanUniverseButton: {
          text: `Changer l'univers`,
          onClick: () => setUniverse(undefined),
          rightIconProps: { icon: Icons.arrowRight, width: 20 },
        },
        samplesCount: samplesCount,
        exhibitorFilters: isExponent,
      },
    }),
    [
      filtersParsedExhibitors,
      filtersParsedSamples,
      handleFiltersClick,
      isExponent,
      isValid,
      openFilters,
      samplesCount,
      setFilters,
      setTags,
      setUniverse,
      tags,
      totalReturn,
      universe,
      universes,
    ]
  )
}
