import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { FormikProps } from 'formik'
import { useParams } from 'react-router'

import { modalFullScreenMocks } from '../../components/ModalFullScreen/mocks'
import {
  findTagsFiltersInDictionaries,
  parseFiltersExhibitorsFromStore,
  parseFiltersSamplesExportFromStore,
} from '../../components/Filters/utils'
import { actions, selectors } from '../../redux'
import { FiltersExportProps } from '../../components/FiltersExport'
import { FiltersExportFormValues } from '../../components/forms/FiltersExportForm'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { ExhibitionForum } from '../../graphql/generated/graphql'
import { FiltersFormValues } from '../../components/forms/FiltersForm'
import { FilterTagProps } from '../../components/FilterTag'

export const useFiltersExportSamplesTemplateProps = ({
  handleFiltersClick,
  openFilters,
  isExponent,
  handleExportAllClick,
  exportAll,
  filters,
  tags,
  setTags,
  universe,
  forum,
  setSearchFilters,
  searchFilters,
}: {
  handleFiltersClick: () => void
  openFilters: boolean
  isExponent: boolean
  handleExportAllClick: React.Dispatch<React.SetStateAction<boolean>>
  exportAll: boolean
  filters: any
  tags: FilterTagProps[]
  setTags: React.Dispatch<React.SetStateAction<FilterTagProps[]>>
  universe: string | undefined
  forum: ExhibitionForum | undefined
  setSearchFilters: React.Dispatch<React.SetStateAction<FiltersFormValues | undefined>>
  searchFilters: FiltersFormValues | undefined
}): FiltersExportProps => {
  const sampleFilters = useSelector(selectors.samples.sampleFilters)
  const dispatch = useDispatch()
  const config = useSelector(selectors.configuration.configurationInfo)
  const samplesNbr = useSelector(selectors.samples.samplesNumber)
  const filtersParsedSamples =
    filters && !isExponent && config && parseFiltersSamplesExportFromStore(filters)
  const filtersParsedExhibitors =
    config && isExponent && parseFiltersExhibitorsFromStore(config?.dictionaries)
  const [newtags, setNewtags] = useState<FilterTagProps[]>([])
  const universes = config?.dictionaries.find((item) => item.type && item.type === 'UNIVERSES')
  const ref = useRef<FormikProps<FiltersExportFormValues>>(null)
  const { apiId } = useParams<{ apiId: string }>()

  const exportData = useCallback(() => {
    const data = sampleFilters || []
    const { countries, forums } = data
    const filterParsedCall = findTagsFiltersInDictionaries(tags, config?.dictionaries)
    const countriesInFilters: string[] = tags.map((country) => {
      if (countries && typeof countries === 'object') {
        const matchingKey = Object.keys(countries).find((key) => countries[key] === country)
        return matchingKey || ''
      }
      return ''
    })
    const pavilionsEbmsIds =
      filterParsedCall.find((item) => item.type === 'PAVILIONS')?.filters || []
    const forumsInFilters: string[] | undefined =
      searchFilters &&
      searchFilters.Forum &&
      searchFilters.Forum.map((forum) => {
        const matchingKey = Object.keys(forums).find((key) => forums[key] === forum.trim())
        return matchingKey || ''
      })
    const filteredForumsInFilters =
      forumsInFilters && forumsInFilters.filter((forum) => forum !== '')
    const filteredCountriesInFilters = countriesInFilters.filter((country) => country !== '')
    let forumsIds: number[] = []
    if (filteredForumsInFilters) {
      forumsIds = filteredForumsInFilters.map((forum) => {
        return parseFloat(forum)
      })
    }

    const findKeysForValues = (searchValues: FilterTagProps[]) => {
      const foundKeys: number[] = []
      //@ts-ignore
      Object.entries(data).forEach(([category, items]) => {
        if (
          category !== 'countries' &&
          category !== 'exhibitions' &&
          category !== 'pavilions' &&
          category !== 'univers' &&
          category !== 'secteur' &&
          category !== 'forums'
        ) {
          const itemsRecord: Record<string, unknown> = items as Record<string, unknown>
          Object.entries(itemsRecord).forEach(([itemId, itemName]) => {
            searchValues.forEach((value: FilterTagProps) => {
              if (value.text && value.text.includes(itemName as string)) {
                foundKeys.push(Number(itemId))
              }
            })
          })
        }
      })

      return foundKeys
    }

    dispatch(
      actions.samples.exportSamplesRequest({
        filters: {
          universesEbmsId: universe ? universe : '',
          pavilionsEbmsIds,
          forumsIds: forumsIds,
          countries: filteredCountriesInFilters,
          flagItems: findKeysForValues(tags),
        },
      })
    )
    handleFiltersClick()
  }, [
    sampleFilters,
    tags,
    config?.dictionaries,
    searchFilters,
    dispatch,
    universe,
    handleFiltersClick,
  ])

  useEffect(() => {
    if (apiId)
      dispatch(
        actions.samples.sampleCountByUniverseRequest({
          exhibitionApiId: apiId,
        })
      )
  }, [apiId, dispatch])

  useEffect(() => {
    if (universe && universe !== '') {
      dispatch(actions.samples.sampleSearchFiltersRequest({ universeEbmsId: universe }))
    }
  }, [dispatch, universe])

  useEffect(() => {
    const combinedTags = [...newtags]
    const uniqueTags = Array.from(new Set(combinedTags))
    setTags(uniqueTags)
  }, [newtags, setTags])

  useEffect(() => {
    if (exportAll)
      ref.current?.resetForm({
        values: {
          PAVILIONS: [],
          COUNTRIES: [],
          Forums: [],
        },
      })
  }, [exportAll])

  useEffect(() => {
    ref.current &&
      ref.current.setValues({
        PAVILIONS: [],
        COUNTRIES: [],
        Forum: [forum ? forum?.name : ''],
      })
  }, [forum, universes])

  return useMemo(
    () => ({
      ...modalFullScreenMocks.basic,
      backButtonProps: {
        text: 'Fermer',
        open: !openFilters,
        onClick: () => {
          handleFiltersClick()
        },
      },
      headerTitle: 'Export de statistiques',
      title: 'FILTRER MA LISTE',
      tags: tags.map((tag) => ({ text: tag.text, category: tag.category })),
      form: {
        resetButton: { text: 'Réinitialiser les filtres' },
        formikForm: {
          innerRef: ref,
          initialValues: {
            PAVILIONS: [],
            COUNTRIES: [],
            Forum: [],
          },
          onContextUpdate: (context) => {
            const newTags = Object.entries(context.values).flatMap(([category, values]) => {
              // Handle categories with single values
              if (!Array.isArray(values)) {
                return { text: values, category }
              }
              // Handle categories with multiple values (e.g., Secteur)
              return values.map((value) => ({ text: value, category }))
            })
            const newTagsWithUniverse = universe
              ? [
                  ...newTags,
                  {
                    text: universes?.items.find((item) => item.key === universe)?.value as string,
                    category: 'UNIVERSES',
                  },
                ]
              : newTags
            if (
              !isEqual(newTagsWithUniverse.length, tags.length) ||
              !isEqual(newTagsWithUniverse.sort(), tags.sort())
            ) {
              setNewtags([...newTagsWithUniverse])
              setSearchFilters && setSearchFilters(context.values)
            }
          },
          onSubmit: () => undefined,
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: isExponent
          ? filtersParsedExhibitors && filtersParsedExhibitors
          : filtersParsedSamples && filtersParsedSamples,
      },
      exhibitorsNbr: samplesNbr?.total.toString(),
      exportTitle: 'Exporter toutes les données de tous les échantillons',
      exportAll: exportAll,
      setExportAll: () => handleExportAllClick(!exportAll),
      resetButton: {
        text: 'Réinitialiser les filtres',
        onClick: () => {
          ref.current?.resetForm({
            values: {
              PAVILIONS: [],
              COUNTRIES: [],
              Forums: [],
            },
          })
          handleExportAllClick(true)
        },
        variantScheme: ActionButtonScheme.Tertiary,
        variantSize: ActionButtonSize.Small,
        rightIconProps: { icon: Icons.trash, height: 18, width: 18 },
      },
      exportButton: {
        text: 'Exporter les statistiques',
        rightIconProps: {
          icon: Icons.export,
          width: 18,
          height: 18,
        },
        onClick: () => {
          exportData()
        },
      },
      dataType: `échantillon${samplesNbr && samplesNbr?.total > 1 ? 's' : ''}`,
    }),
    [
      openFilters,
      tags,
      isExponent,
      filtersParsedExhibitors,
      filtersParsedSamples,
      samplesNbr,
      exportAll,
      handleFiltersClick,
      universe,
      universes?.items,
      setSearchFilters,
      handleExportAllClick,
      exportData,
    ]
  )
}
