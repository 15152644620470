import styled from 'styled-components'

import Icon from '../Icon'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`

export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.concrete};
`

export const Text = styled.div`
  background-color: ${({ theme }) => theme.textStyles.titleH4};
  text-transform: uppercase;
`
