import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleUpdateStatus = getMutationService({
  mutation: gql(`
    mutation sampleUpdateStatus($sampleId: ID!, $status: SampleStatus!) {
        sampleUpdateStatus(sampleId: $sampleId, status: $status) {
            id
            name
            status
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
