import styled, { css } from 'styled-components'
import Switch from 'react-switch'

import FormLabel from '../../FormLabel'

type InputStyleProps = {
  $isError?: boolean
  $isActive?: boolean
  $hasLabel?: boolean
  $isDisabled?: boolean
  $hasErrorMessage?: boolean
}

export const Container = styled.div<InputStyleProps>`
  display: flex;
  flex-flow: column;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      &,
      & * {
        cursor: not-allowed;
      }
    `}
`

export const Label = styled(FormLabel)`
  margin-bottom: 1rem;
`

export const Wrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
`
export const InlineLabel = styled.label<{ $isDisabled?: boolean }>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`

export const CenterIcon = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const SwitchStyled = styled(Switch)``

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.gray};
  text-align: right;
  margin-top: 0.6rem;
`
export const Error = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.6rem;
  text-align: right;
`
