import React, { useEffect, useRef, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { FormikProps } from 'formik'

import FirstLogin from '../../templates/FirstLogin'
import { useFirstLoginTemplateProps } from '../../templates/FirstLogin/useProps'
import { FirstLoginFormValues } from '../../components/forms/FirstLoginForm'

const FirstLoginPage: React.FC<RouteProps> = () => {
  const [emailSelected, setEmailSelected] = useState<string>('')
  const [codeSelected, setCodeSelected] = useState<string>('')
  const [key, setKey] = useState<number>(0)

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isValid, setIsValid] = useState<boolean>(false)

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [validPasswordLength, setValidPasswordLength] = useState<boolean>(false)
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [validPasswordUppercase, setValidPasswordUppercase] = useState<boolean>(false)
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [validPasswordSpecial, setValidPasswordSpecial] = useState<boolean>(false)
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [validPasswordNumber, setValidPasswordNumber] = useState<boolean>(false)

  const handleOnChange = (value: string) => {
    const hasNumber = /[0-9]/.test(value)
    const hasUppercase = /[A-Z]/.test(value)
    const hasSpecial = /[`!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/.test(value)
    const hasLength = value.replaceAll(' ', '').length >= 12

    setValidPasswordLength(hasLength)
    setValidPasswordNumber(hasNumber)
    setValidPasswordSpecial(hasSpecial)
    setValidPasswordUppercase(hasUppercase)
  }

  const ref = useRef<FormikProps<FirstLoginFormValues>>(null)

  useEffect(() => {
    if (ref.current) {
      handleOnChange(ref.current.values.password)
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      ref.current.validateForm()
    }
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const email = urlParams.get('email')

    if (urlParams && email && code) {
      handleEmailSelectedChange(email, code)
    }
  }, [])

  const handleEmailSelectedChange = (newEmail: string, code: string) => {
    setEmailSelected(newEmail)
    setCodeSelected(code)
    setKey((prevKey) => prevKey + 1)
  }

  const templateProps = useFirstLoginTemplateProps(emailSelected, codeSelected)

  return (
    <FirstLogin
      key={key}
      {...templateProps}
      firstLoginForm={{
        ...templateProps.firstLoginForm,
        formikForm: {
          ...templateProps.firstLoginForm.formikForm,
          onContextUpdate: (context) => {
            setIsValid(context.isValid)
          },
          innerRef: ref,
        },
        rules: {
          length: {
            isValid: validPasswordLength,
            rule: '12 caractères minimum',
          },
          uppercase: {
            isValid: validPasswordUppercase,
            rule: 'Majuscules',
          },
          number: {
            isValid: validPasswordNumber,
            rule: 'Chiffres',
          },
          specialCharacter: {
            isValid: validPasswordSpecial,
            rule: 'Caractères spéciaux',
          },
        },
        fields: {
          ...templateProps.firstLoginForm.fields,
          password: {
            ...templateProps.firstLoginForm.fields.password,
            onChangeCapture: (e: React.FormEvent<HTMLInputElement>) =>
              handleOnChange(e.currentTarget.value),
          },
        },
        submitButton: {
          ...templateProps.firstLoginForm.submitButton,
          disabled: !isValid,
        },
      }}
    />
  )
}

export default FirstLoginPage
