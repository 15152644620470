import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { FormFieldTextAreaProps } from '../../form/fields/FormFieldTextArea'
import { FormFieldScoreProps } from '../../form/fields/FormFieldScore'
import { FormFieldSwitchProps } from '../../form/fields/FormFieldSwitch'
import { FormFieldInputNumberProps } from '../../form/fields/FormFieldInputNumber'

import * as SC from './styled'

export type CollectionCommentFormValues = {
  comment: string
  note: string
  return?: boolean
  samplesToReturn?: number
}

export type CollectionCommentFormProps = {
  className?: string
  title?: string
  formikForm: FormikFormProps<CollectionCommentFormValues>
  displayReturn?: boolean
  fields: {
    comment: {
      title?: string
      props: FormFieldTextAreaProps
    }
    note: {
      title?: string
      props: FormFieldScoreProps
    }
    return: {
      props: FormFieldSwitchProps
    }
    samplesToReturn?: {
      inlineLabel?: string
      props: FormFieldInputNumberProps
    }
  }
  errors?: FormErrorsProps['errors']
}

const CollectionCommentForm: FC<CollectionCommentFormProps> = ({
  className,
  title,
  formikForm,
  fields,
  errors,
  displayReturn,
}) => {
  return (
    <SC.Container className={className}>
      <SC.Form {...formikForm}>
        {title && <SC.Title tag="h1" text={title} />}
        <SC.Fieldset>
          {fields.comment.title && <SC.Subtitle tag="h5" text={fields.comment.title} />}
          <FormikField Component={SC.StyledTextarea} {...fields.comment.props} />
        </SC.Fieldset>
        <SC.Fieldset>
          {fields.note.title && (
            <SC.SubtitleContainer>
              <SC.Subtitle tag="h5" text={fields.note.title} />
              <SC.Required>*</SC.Required>
            </SC.SubtitleContainer>
          )}
          <FormikField
            Component={SC.StyledScore}
            value={formikForm.initialValues?.note}
            {...fields.note.props}
          />
        </SC.Fieldset>
        <SC.Return>
          <SC.Fieldset>
            <FormikField Component={SC.StyledSwitch} {...fields.return.props} />
          </SC.Fieldset>
          {fields.samplesToReturn && displayReturn && (
            <SC.Fieldset>
              <SC.NumberControl>
                <FormikField Component={SC.StyledNumber} {...fields.samplesToReturn.props} />
                {fields.samplesToReturn.inlineLabel && (
                  <SC.NumberLabel
                    htmlFor={fields.samplesToReturn.props.id && fields.samplesToReturn.props.id}
                  >
                    {fields.samplesToReturn.inlineLabel}
                  </SC.NumberLabel>
                )}
              </SC.NumberControl>
            </SC.Fieldset>
          )}
        </SC.Return>
        {errors && <SC.Errors errors={errors} />}
      </SC.Form>
    </SC.Container>
  )
}

export default CollectionCommentForm
