import React, { FC, InputHTMLAttributes } from 'react'

import Dot from '../../../Dot'
import { VariantColored } from '../../../CollapsibleColored'
import Icon, { IconProps } from '../../../Icon'

import * as SC from './styled'
import { VariantCheckboxFont, VariantCheckboxSize } from './types'

export type FormFieldCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & {
  className?: string
  name: string
  checked?: boolean
  variant?: VariantColored
  value?: string
  label?: string
  required?: boolean
  disabled?: boolean
  error?: string
  hasError?: boolean
  help?: string
  display?: SC.Display
  isGroupChild?: boolean
  forceBackgroundColor?: string
  color?: string
  exponent?: string
  icon?: IconProps
  variantSize?: VariantCheckboxSize
  variantFont?: VariantCheckboxFont
}

const FormFieldCheckbox: FC<FormFieldCheckboxProps> = ({
  className,
  label,
  error,
  hasError,
  help,
  isGroupChild,
  forceBackgroundColor,
  color,
  display = 'default',
  exponent,
  variant,
  checked,
  icon,
  variantSize = VariantCheckboxSize.default,
  variantFont = VariantCheckboxFont.Default,
  ...inputProps
}) => {
  const formattedLabel = label && label.charAt(0).toUpperCase() + label.substring(1).toLowerCase()
  return (
    <SC.Container
      className={className}
      $isDisabled={!!inputProps.disabled}
      $size={variantSize}
      $variantFont={variantFont}
    >
      <SC.Label
        $isDisabled={!!inputProps.disabled}
        $isError={isGroupChild ? false : !!error || hasError}
        $display={display}
        $variant={variant}
        $checked={true}
        $forceBackgroundColor={forceBackgroundColor}
        $color={color}
        $size={variantSize}
      >
        {display === 'default' && (
          <>
            <SC.LabelText
              $required={!!inputProps.required}
              $isError={isGroupChild ? false : !!error || hasError}
            >
              {icon && (
                <SC.StyledIcon>
                  <Icon {...icon} />
                </SC.StyledIcon>
              )}
              {formattedLabel}
            </SC.LabelText>
            <SC.Checkbox
              {...inputProps}
              type="checkbox"
              checked={checked}
              $isError={isGroupChild ? false : !!error || hasError}
            />
          </>
        )}
        {display === 'simple' && (
          <>
            <SC.Checkbox
              {...inputProps}
              type="checkbox"
              checked={checked}
              $isError={isGroupChild ? false : !!error || hasError}
            />
            <SC.LabelText
              $required={!!inputProps.required}
              $isError={isGroupChild ? false : !!error || hasError}
            >
              {icon && (
                <SC.StyledIcon>
                  <Icon {...icon} />
                </SC.StyledIcon>
              )}
              {formattedLabel}
            </SC.LabelText>
            <SC.Exponent>{exponent}</SC.Exponent>
          </>
        )}
        {display === 'withDot' && (
          <>
            <SC.LabelHeadContainer>
              <Dot variant={variant} />
              <SC.LabelText
                $required={!!inputProps.required}
                $isError={isGroupChild ? false : !!error || hasError}
              >
                {icon && (
                  <SC.StyledIcon>
                    <Icon {...icon} />
                  </SC.StyledIcon>
                )}
                {formattedLabel}
              </SC.LabelText>
            </SC.LabelHeadContainer>
            <SC.Checkbox
              {...inputProps}
              type="checkbox"
              checked={checked}
              $isError={isGroupChild ? false : !!error || hasError}
            />
          </>
        )}
      </SC.Label>
      {!error && help && <SC.Help>{help}</SC.Help>}
      {error && !isGroupChild && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldCheckbox
