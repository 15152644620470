import { MenuProps } from './index'

const basic: MenuProps = {
  links: [
    {
      text: 'Exposants',
      href: '/exposants',
    },
    {
      text: 'Salons',
      href: '/salons',
    },
    {
      text: 'Paramètres',
      href: '/parametres',
    },
    {
      text: 'Mon compte',
      href: '/mon-compte',
    },
  ],
  coloredLinks: [
    {
      text: 'Gestion colis',
      colorVariant: 'yellow',
      href: 'exposants/redirect',
    },
    {
      text: 'Sélection échantillons',
      colorVariant: 'purple',
      href: '/selection-echantillons',
    },
    {
      text: 'Scan échantillons',
      colorVariant: 'green',
      href: '/scan-echantillon',
    },
  ],
}

export const menuMocks = {
  basic,
}
