import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { router, routesPath } from '../../router'
import { TabsScrollableProps } from '../../components/TabsScrollable'
import { UserRole } from '../../graphql/generated/graphql'

export const useSampleSheetTabsProps = (
  sampleId: string | undefined,
  currentTab: 'data' | 'shooting' | 'collection' | 'edito' | 'history' | 'label',
  userRole?: UserRole,
  onReload?: () => void
): TabsScrollableProps => {
  const navigate = useNavigate()

  const tabs = useMemo(() => {
    const links = [
      {
        text: 'Données échantillon',
        onClick: () => {
          const link = router(routesPath.sampleSheetData, {
            sampleId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'data' ? true : false,
      },
      {
        text: 'Shooting',
        onClick: () => {
          const link = router(routesPath.sampleSheetShooting, {
            sampleId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'shooting' ? true : false,
      },
      {
        text: 'Collection',
        onClick: () => {
          const link = router(routesPath.sampleSheetCollection, {
            sampleId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'collection' ? true : false,
      },
      {
        text: 'Historique',
        onClick: () => {
          const link = router(routesPath.sampleSheetHistory, {
            sampleId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'history' ? true : false,
      },
      {
        text: 'Edito',
        onClick: () => {
          const link = router(routesPath.sampleSheetEdito, {
            sampleId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'edito' ? true : false,
      },

      {
        text: 'Etiquette',
        onClick: () => {
          const link = router(routesPath.sampleSheetLabel, {
            sampleId,
          })
          onReload && onReload()
          navigate(link)
        },
        ariaCurrent: currentTab === 'label' ? true : false,
      },
    ]

    const filteredLinks = links.filter((link) => {
      if (
        link.text === 'Historique' &&
        !['SUPER_ADMIN', 'ADMIN', 'MODE_PV', 'MARKETPLACE'].includes(userRole as string)
      ) {
        return false
      }

      return true
    })

    return filteredLinks
  }, [currentTab, navigate, onReload, sampleId, userRole])

  return { tabs }
}
