import styled, { css } from 'styled-components'

import ImageColumns from '../ImageColumns'
import Icon from '../Icon'
import Heading from '../Heading'

export const Card = styled.div<{ $selected?: boolean; $isSelectable: boolean }>`
  max-width: 35rem;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 1rem;
  box-shadow: 2px 2px 34px 0px #00000026;
  position: relative;
  ${({ $isSelectable }) =>
    $isSelectable &&
    css`
      cursor: pointer;
      &:hover:hover {
        transition: transform 200ms ease-in, background-color 150ms ease-in, color 150ms ease-in;
        transform: scale(1.01);
      }
    `};

  ${({ $selected }) =>
    $selected &&
    css`
      border: 1.5px solid ${({ theme }) => theme.colors.silver};
    `};
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 1.2rem;
  border-bottom: 1.5px solid;
  border-bottom-color: ${({ theme }) => theme.colors.mineShaft};
  padding-bottom: 1.2rem;
`

export const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.2rem;
  flex-direction: column;
`

export const Title = styled(Heading)`
  margin-top: 0px;
`

export const Category = styled(Heading)`
  color: ${({ theme }) => theme.colors.gray};
`

export const BottomTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
`

export const Image = styled.img<{ isFullHeight?: boolean }>`
  max-width: 100%;
  height: ${({ isFullHeight }) => (isFullHeight ? '100%' : '50%')};
  object-fit: cover;
`

export const SelectedIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const LogoWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20rem;
  background-color: ${({ theme }) => theme.colors.gray};
  height: 30px;
  width: 30px;
  top: -20px;
  right: -20px;
  margin: 1rem;
  z-index: 1;
`

export const BlockImage = styled(ImageColumns)`
  flex: 1;
`
