import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import { FormSubmitButtonProps } from '../../form/FormSubmitButton'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { Icons } from '../../Icon'
import { ActionButtonProps } from '../../ActionButton'
import { ActionButtonScheme, ActionButtonSize } from '../../ActionButton/types'

import * as SC from './styled'

export type AccountFormValues = {
  password: string
  newPassword: string
  newPasswordConfirm: string
}

export type AccountFormProps = {
  className?: string
  state: 'update' | 'preview'
  formikForm: FormikFormProps<AccountFormValues>
  fields: {
    password: FormFieldInputProps
    newPassword: FormFieldInputProps
    newPasswordConfirm: FormFieldInputProps
  }
  rules: {
    length: { rule: string; isValid: boolean }
    specialCharacter: { rule: string; isValid: boolean }
    uppercase: { rule: string; isValid: boolean }
    number: { rule: string; isValid: boolean }
  }
  info: {
    email: {
      title: string
      value: string | undefined
    }
    role: {
      title: string
      value: string | undefined
    }
  }
  errors?: FormErrorsProps['errors']
  previewButton?: ActionButtonProps
  submitButton: FormSubmitButtonProps
}

const AccountForm: FC<AccountFormProps> = ({
  className,
  formikForm,
  fields,
  state,
  info,
  rules,
  previewButton,
  submitButton,
  errors,
}) => {
  return (
    <SC.Form className={className} {...formikForm}>
      {info.role.value && (
        <SC.Group>
          <SC.Title text={info.role.title} />
          <SC.Value text={info.role.value} />
        </SC.Group>
      )}
      {info.email.value && (
        <SC.Group>
          <SC.Title text={info.email.title} />
          <SC.Value text={info.email.value} />
        </SC.Group>
      )}
      <SC.Fields>
        {state === 'update' && (
          <>
            <span>
              <FormikField
                {...fields.password}
                type="password"
                hasFloatingLabel={true}
                Component={SC.FieldPassword}
              />
              {errors && <SC.Errors errors={errors} />}
            </span>
            <FormikField
              {...fields.newPassword}
              type="password"
              hasFloatingLabel={true}
              Component={SC.FieldPassword}
            />
            <FormikField
              {...fields.newPasswordConfirm}
              type="password"
              hasFloatingLabel={true}
              Component={SC.FieldPassword}
            />
          </>
        )}
      </SC.Fields>
      {state === 'update' && (
        <>
          <SC.RulesList>
            {Object.keys(rules).map((key, idx) => (
              <SC.Rule key={idx} $isValid={rules[key as keyof typeof rules].isValid}>
                {rules[key as keyof typeof rules].rule}
                <SC.Check
                  $isValid={rules[key as keyof typeof rules].isValid}
                  icon={Icons.check}
                  width={10}
                  height={10}
                />
              </SC.Rule>
            ))}
          </SC.RulesList>
          <SC.SubmitButton
            {...submitButton}
            variantSize={ActionButtonSize.Small}
            variantScheme={ActionButtonScheme.Tertiary}
            rightIconProps={{ icon: Icons.check, width: 16, height: 16 }}
          />
        </>
      )}
      {state === 'preview' && previewButton?.text && (
        <SC.Button
          {...previewButton}
          variantSize={ActionButtonSize.Small}
          variantScheme={ActionButtonScheme.Tertiary}
          rightIconProps={{ icon: Icons.pencil, width: 16, height: 16 }}
        />
      )}
    </SC.Form>
  )
}

export default AccountForm
