import React from 'react'
import { RouteProps } from 'react-router-dom'

import SampleScanTemplate from '../../templates/SampleScan'
import { useSampleScanProps } from '../../templates/SampleScan/useProps'

const SampleScan: React.FC<RouteProps> = () => {
  const templateProps = useSampleScanProps()
  return <SampleScanTemplate {...templateProps} />
}

export default SampleScan
