import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'

type InputStyleProps = {
  $isError?: boolean
  $isActive?: boolean
  $hasLabel?: boolean
  $hasFloatingLabel?: boolean
  $isDisabled?: boolean
  $hasErrorMessage?: boolean
}

export const Container = styled.div<InputStyleProps>`
  display: flex;
  flex-flow: column;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      &,
      & * {
        cursor: not-allowed;
      }
    `}
`
export const Wrapper = styled.label``

export const InputContainer = styled.div<InputStyleProps>`
  background-color: ${({ theme }) => theme.colors.concrete};
  border-radius: 0.4rem;
  position: relative;
  padding: 1rem;
  width: 100%;

  ${({ $isError, theme }) =>
    $isError === true &&
    css`
      border-color: ${theme.colors.error};
    `}
`

export const Label = styled(FormLabel)<{
  $active?: boolean
  $hasFloatingLabel?: boolean
}>`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.gray};
  transition: all 0.2s ease;

  ${(props) =>
    props.$hasFloatingLabel &&
    css`
      ${({ theme }) => theme.textStyles.titleH5}
      position: absolute;
      margin: auto;
      bottom: 50%;
      left: 1rem;
    `}

  ${(props) =>
    props.$active &&
    css`
      left: 0;
      bottom: calc(100% + 0.5rem);
    `}
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: relative;
`

export const inputStyle = css<InputStyleProps>`
  ${({ theme }) => theme.textStyles.titleH5};
  line-height: 1;
  width: 100%;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.pureBlack};
  background: transparent;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      pointer-events: none;
    `}

  &::placeholder {
    opacity: 0.5;
  }

  // readonly
  &[readonly] {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &[type='password'] {
    letter-spacing: 3px;
  }
`

export const Input = styled.textarea<InputStyleProps>`
  ${inputStyle}
  resize: vertical;
  min-height: 100px;
`

export const HelpMessage = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.gray};
  text-align: right;
  margin-top: 0.6rem;
`
export const ErrorMessage = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.6rem;
  text-align: right;
`
