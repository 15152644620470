import React, { FC } from 'react'
import cx from 'classnames'

import { Icons } from '../Icon'
import Link, { LinkProps } from '../Link'
import Burger, { BurgerProps } from '../Burger'
import { SearchButtonProps } from '../SearchButton'
import { MenuProps } from '../Menu'

import * as SC from './styled'

export type MobileNavProps = {
  className?: string
  logoLink: LinkProps
  burger?: BurgerProps
  search?: boolean
  menu: MenuProps & { isOpen: boolean }
  searchButton?: SearchButtonProps
  variant?: 'default' | 'dark'
}

const MobileNav: FC<MobileNavProps> = ({
  className,
  logoLink,
  burger,
  menu,
  searchButton,
  search,
  variant,
}) => {
  return (
    <SC.MobileNav className={cx(className)}>
      <SC.MenuStyled $isOpen={menu.isOpen} {...menu} />
      <SC.Nav $variant={variant}>
        <Link {...logoLink}>
          <SC.Logo icon={Icons.logo} />
        </Link>
        <SC.Actions>
          {search && <SC.Search {...searchButton} href="/recherche" />}
          {burger && <Burger {...burger} />}
        </SC.Actions>
      </SC.Nav>
    </SC.MobileNav>
  )
}

export default MobileNav
