import styled from 'styled-components'

export const Tag = styled.div`
  display: inline-block;
  ${({ theme }) => theme.textStyles.textBold};
  color: ${({ theme }) => theme.colors.tundora};
  background-color: ${({ theme }) => theme.colors.silver};
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.3rem 0.6rem;
  border-radius: 0.4rem;
`
