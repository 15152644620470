import React from 'react'
import { RouteProps } from 'react-router-dom'

import LoungeConfigTemplate from '../../../templates/LoungeConfig'
import { useLoungeInfosTemplateProps } from '../../../templates/LoungeConfig/useProps'

const LoungesInfo: React.FC<RouteProps> = () => {
  const templateProps = useLoungeInfosTemplateProps()

  return <LoungeConfigTemplate {...templateProps} />
}

export default LoungesInfo
