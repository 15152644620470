import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitionCreate = getMutationService({
  mutation: gql(` 
    mutation exhibitionCreate(
        $input: ExhibitionUpsertInput!
        $forums: [ExhibitionCreateForumInput!]!
    ) {
        exhibitionCreate(input: $input, forums: $forums) {
            id
            apiId
            name
            shootingFolder
            seasonCode
            seasonYear
            dateStart
            dateEnd
            dateSampleStart
            dateSampleEnd
            isActive
            forums {
                id
                name
                color
                subForums {
                    id
                    name
                }
            }
            boxes {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
