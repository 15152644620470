import React, { FC } from 'react'

import LayoutExhibitionSheet, {
  LayoutExhibitionSheetProps,
} from '../../layouts/LayoutExhibitionSheet'
import { FormFieldCheckboxGroupProps } from '../../components/form/fields/FormFieldCheckboxGroup'
import { FormikFormProps } from '../../components/form/FormikForm'
import { FiltersExportFormValues } from '../../components/forms/FiltersExportForm'
import { VariantCollapsibleColoredFont } from '../../components/CollapsibleColored/types'

import * as SC from './styled'

export type ExhibitionSheetExhibitorsTemplateProps = {
  layoutProps?: LayoutExhibitionSheetProps
  formikForm: FormikFormProps<FiltersExportFormValues>
  UNIVERSES: FormFieldCheckboxGroupProps
  exhibitorsNbr?: string
  exhibitorsNbrtext?: string
  universTitle?: string
  universMultipleTitle?: string[]
}

const ExhibitionSheetExhibitorsTemplate: FC<ExhibitionSheetExhibitorsTemplateProps> = ({
  layoutProps,
  exhibitorsNbr,
  exhibitorsNbrtext,
  UNIVERSES,
  formikForm,
  universTitle,
  universMultipleTitle,
}) => {
  return (
    <LayoutExhibitionSheet {...layoutProps}>
      <SC.StyledContentWrapper>
        <SC.StyledFormikForm {...formikForm}>
          <SC.Collapsible
            title={universTitle ? universTitle : undefined}
            multipleTitle={universMultipleTitle ? universMultipleTitle : undefined}
            variant="bursted"
            variantFont={VariantCollapsibleColoredFont.DefaultBold}
            dotted
          >
            <SC.CheckboxGroup {...UNIVERSES} />
          </SC.Collapsible>
        </SC.StyledFormikForm>
        <SC.Infos>
          <SC.ExhibitorNbr>{exhibitorsNbr}</SC.ExhibitorNbr> <SC.Text>{exhibitorsNbrtext}</SC.Text>
        </SC.Infos>
      </SC.StyledContentWrapper>
    </LayoutExhibitionSheet>
  )
}

export default ExhibitionSheetExhibitorsTemplate
