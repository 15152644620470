import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FormikProps } from 'formik'
import { useNavigate } from 'react-router'

import { actions, selectors } from '../../redux'
import { forgottenPasswordFormMocks } from '../../components/forms/ForgottenPasswordForm/mocks'
import { LayoutNotConnectedMocks } from '../../layouts/LayoutNotConnected/mocks'
import { ForgottenPasswordFormValues } from '../../components/forms/ForgottenPasswordForm'
import { Icons } from '../../components/Icon'
import { router, routesPath } from '../../router'

import { ForgottenPasswordProps } from '.'

export const useForgottenPasswordTemplateProps = (): ForgottenPasswordProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const message = useSelector(selectors.auth.successMessage)
  const [isValid, setIsValid] = useState<boolean>(false)
  const ref = useRef<FormikProps<ForgottenPasswordFormValues>>(null)
  const forgottenPassword = useSelector(selectors.auth.authSendForgotPasswordNotification)

  useEffect(() => {
    if (isValid) dispatch(actions.auth.resetAuthForgottenPasswordNotification())
  }, [dispatch, isValid])

  return useMemo(
    () => ({
      layout: {
        ...LayoutNotConnectedMocks.basic,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.login)
              navigate(link)
            },
          },
        },
      },
      title: 'Mot de passe oublié',
      description:
        'Veuillez saisir votre adresse e-mail. Vous recevrez un lien par e-mail pour créer votre nouveau mot de passe.',
      message: message,
      form: {
        ...forgottenPasswordFormMocks.basic,
        formikForm: {
          innerRef: ref,
          ...forgottenPasswordFormMocks.basic.formikForm,
          onSubmit: (values) => {
            dispatch(
              actions.auth.authSendForgotPasswordNotificationRequest({
                email: values.email,
              })
            )
          },
          onContextUpdate: (context) => {
            setIsValid(context.isValid)
          },
        },
        errors: isValid ? forgottenPassword.errors : null,
        fields: {
          email: {
            name: 'email',
            label: 'E-mail',
            required: true,
            onChange: () => {
              if (message !== '') dispatch(actions.auth.setSuccessMessage(''))
            },
          },
        },
        submitButton: {
          disabled: !isValid,
          isPending: forgottenPassword.pending,
          text: 'Réinitialiser mon mot de passe',
          pendingText: 'Chargement...',
        },
      },
    }),
    [dispatch, forgottenPassword.errors, forgottenPassword.pending, isValid, message, navigate]
  )
}
