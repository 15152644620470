import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import { TabsScrollableProps } from '../../components/TabsScrollable'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'

import * as SC from './styled'

export type LayoutExhibitionSheetProps = LayoutWithBottomActionsProps & {
  title?: string
  season?: string
  dates?: string
  children?: React.ReactNode
  tabs?: TabsScrollableProps
  deleteModal?: ModalDeleteProps
  toastModal?: ModalToastProps
}

const LayoutExhibitionSheet: FC<LayoutExhibitionSheetProps> = ({
  title,
  season,
  tabs,
  dates,
  children,
  deleteModal,
  toastModal,
  ...layoutProps
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={
        <>
          {toastModal && <ModalToast {...toastModal} />}
          {deleteModal && <ModalDelete {...deleteModal} />}
        </>
      }
    >
      <SC.TopContent maxWidth="default">
        <SC.TopHeader>
          {title && <SC.Title tag={'h2'} text={title} />}
          {season && <SC.Season tag={'h6'} text={season} />}
        </SC.TopHeader>
        {dates && <SC.Dates>{dates}</SC.Dates>}
      </SC.TopContent>
      {tabs ? <SC.StyledTabs {...tabs} /> : <SC.Separator />}
      <SC.BottomContent maxWidth="default">{children}</SC.BottomContent>
    </LayoutWithBottomActions>
  )
}

export default LayoutExhibitionSheet
