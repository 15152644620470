import React from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import MultipleEditionDataSelection, {
  MultipleEditionDataSelectionTemplateFormValues,
} from '../../../templates/MultipleEditionDataSelection'
import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../../components/form/fields/FormFieldRadioGroup/types'
import { SampleStatus, UserRole } from '../../../graphql/generated/graphql'
import { actions, selectors } from '../../../redux'
import { router, routesPath } from '../../../router'
import { useStatutSelectionProps } from '../../../templates/MultipleEditionDataSelection/useProps'
import { colors } from '../../../theme'
import { Icons } from '../../../components/Icon'

const MultipleEditionStatutSelection: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectors.auth.user)
  const sampleStatus = useSelector(selectors.multipleEdition.sampleStatus)

  const onSubmitForm = (values: MultipleEditionDataSelectionTemplateFormValues) => {
    dispatch(actions.multipleEdition.setSampleStatus(values.radios as SampleStatus))
    const link = router(routesPath.multipleEditionScan)
    navigate(link)
  }

  const templateProps = useStatutSelectionProps(onSubmitForm)

  return (
    <MultipleEditionDataSelection
      {...templateProps}
      layoutProps={{
        ...templateProps.layoutProps,
        bottomActions: {
          ...templateProps.layoutProps?.bottomActions,
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.multipleEdition)
              navigate(link)
            },
          },
        },
      }}
      subTitle="STATUT SÉLECTION"
      text="Quel statut souhaitez-vous appliquer aux échantillons ?"
      radios={{
        name: 'radios',
        required: true,
        optionsFont: VariantRadioFont.SmallBold,
        optionsSize: VariantRadioSize.big,
        options:
          user?.role !== UserRole.Shooting
            ? [
                {
                  label: 'Présélectionné',
                  value: 'SELECTED',
                  dotted: true,
                  backgroundColor: colors.eggSour,
                  color: colors.selectiveTextYellow,
                },
                {
                  label: 'Ok shooting',
                  value: 'SELECTED_OK_SHOOTING',
                  dotted: true,
                  backgroundColor: colors.eggSour,
                  color: colors.selectiveTextYellow,
                },
                {
                  label: 'Validé forum',
                  value: 'VALIDATED',
                  dotted: true,
                  backgroundColor: colors.appleGreen,
                  color: colors.chateauGreen,
                },
                {
                  label: 'Retiré du forum',
                  value: 'DELETED',
                  dotted: true,
                  backgroundColor: colors.redLight,
                  color: colors.cinnabar,
                },
                {
                  label: 'Selection validée',
                  secondLabel: 'Ok étiquette',
                  value: 'VALIDATED_LABELLED',
                  dotted: true,
                  backgroundColor: colors.appleGreen,
                  color: colors.chateauGreen,
                },
                {
                  label: 'Selection validée',
                  secondLabel: 'Ok vérif',
                  value: 'VALIDATED_CHECKED',
                  dotted: true,
                  backgroundColor: colors.appleGreen,
                  color: colors.chateauGreen,
                },
              ]
            : [
                {
                  label: 'Ok shooting',
                  value: 'SELECTED_OK_SHOOTING',
                  dotted: true,
                  backgroundColor: colors.eggSour,
                  color: colors.selectiveTextYellow,
                },
              ],
      }}
      formikForm={{
        ...templateProps.formikForm,
        initialValues: {
          radios: sampleStatus,
        } as MultipleEditionDataSelectionTemplateFormValues,
      }}
    />
  )
}

export default MultipleEditionStatutSelection
