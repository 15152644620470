import { PerformanceCodeType } from './performanceCode'

export const frenchToEnglishMapping: { [key: string]: PerformanceCodeType } = {
  Respirant: 'breathing',
  'Bi-stretch': 'biStretch',
  'Easy-care': 'climatic',
  Climatique: 'climatic',
  'Eco-Polymères biosourcés': 'biopolymers',
  'Imper-respirant': 'breathableWaterproof',
  'Résistance au chlore': 'chlorineResistant',
  'Anti-UV': 'antiUv',
  Antibactérien: 'antibacterial',
  'Down proof': 'downProof',
  'Séchage rapide': 'downProof',
  fastDryingFabric: 'fastDryingFabric',
  'Haute résistance': 'hightResistance',
  'Eco-Finissage à impact chimique réduit': 'lowChemicalImpactFinishing',
  'Exempt de métaux': 'metalFree',
  'Mono-stretch': 'monoStretch',
  'Multi-couches': 'multiLayers',
  'Naturellement stretch': 'naturallyStretch',
  'Eco-Matière biologique': 'organic',
  'Eco-Matière recyclée': 'recycled',
  Réfléchissant: 'reflecting',
  Déperlant: 'repellent',
  'Tannage végétal': 'tannageVegetal',
  'Eco-Traçabilité': 'traceability',
  'Poids ultra léger': 'ultraLightweight',
  Lavable: 'washable',
  'Eco-Waterless': 'waterless',
  Imperméable: 'waterproof',
  'Wind-Proof': 'windProof',
  Aucun: 'close',
}
