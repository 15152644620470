import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const collectionsByExhibitor = getQueryService({
  query: gql(`
    query collectionsByExhibitor($exhibitorEbmsId: ID!) {
        collectionsByExhibitor(exhibitorEbmsId: $exhibitorEbmsId) {
            id
            exhibition {
                apiId
                seasonCode
                seasonYear
                name
                dateStart
                dateEnd
            }
        }
    }
`),
  transformer: (response) => {
    return response
  },
})
