import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { Editorial, ExhibitionForum, SampleStatus } from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

//
// Initial state
//

export type MultipleEditionState = {
  sampleStatus: SampleStatus | undefined
  forum: ExhibitionForum | undefined
  subForum: ExhibitionForum | undefined
  mkpForum: string | undefined
  edito: Editorial[] | undefined
  // services
}

const initialState: MultipleEditionState = {
  sampleStatus: undefined,
  forum: undefined,
  subForum: undefined,
  mkpForum: undefined,
  edito: undefined,
  // services
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'multipleEdition',
  initialState,
  reducers: {
    resetMultipleEdition: () => initialState,
    setSampleStatus: (state, { payload }: actionTypes.setSampleStatus) => {
      state.sampleStatus = payload
    },
    setForum: (state, { payload }: actionTypes.setForum) => {
      state.forum = payload
    },
    setSubForum: (state, { payload }: actionTypes.setSubForum) => {
      state.subForum = payload
    },
    setMkpForum: (state, { payload }: actionTypes.setMkpForum) => {
      state.mkpForum = payload
    },
    setEdito: (state, { payload }: actionTypes.setEdito) => {
      state.edito = payload
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const sampleStatus = (state: RootState) => root(state).sampleStatus
const forum = (state: RootState) => root(state).forum
const subForum = (state: RootState) => root(state).subForum
const mkpForum = (state: RootState) => root(state).mkpForum
const edito = (state: RootState) => root(state).edito

export const selectors = {
  sampleStatus,
  forum,
  subForum,
  mkpForum,
  edito,
}
