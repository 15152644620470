import { bottomActionsMocks } from '../../components/BottomActions/mocks'
import { defaultLayoutMocks } from '../DefaultLayout/mocks'

import { LayoutWithBottomActionsProps } from './index'

const basic: LayoutWithBottomActionsProps = {
  ...defaultLayoutMocks.basic,
  bottomActions: {
    ...bottomActionsMocks.basic,
    back: {
      ...bottomActionsMocks.basic.back,
      rounded: true,
    },
  },
}

export const layoutWithBottomActionsMocks = {
  basic,
}
