import React, { FC, ImgHTMLAttributes } from 'react'

import { IconProps } from '../Icon'
import { FormFieldSwitchProps } from '../form/fields/FormFieldSwitch'
import SimpleSlider from '../SimpleSlider'

import * as SC from './styled'

export type SummaryProps = {
  className?: string
  items?: {
    title?: string
    content?: {
      text?: string
      image?: ImgHTMLAttributes<HTMLImageElement>[]
      icon?: IconProps
    }[]
    allCollection?: FormFieldSwitchProps
  }[]
  bottomNote?: {
    enteredBy?: string
    date?: string
  }
}

const Summary: FC<SummaryProps> = ({ className, items, bottomNote }) => {
  return items && items.length > 0 ? (
    <SC.Container>
      <SC.List className={className}>
        {items.map((item, index) => (
          <SC.Item
            key={`SampleDataSummary-item-${index}`}
            $withIcon={
              item?.content?.find((content) => typeof content.icon !== undefined) !== undefined
                ? true
                : false
            }
          >
            {item.title && <SC.ItemTitle>{item.title}</SC.ItemTitle>}
            {item.content &&
              item.content.length > 0 &&
              item.content.map((content, i) => (
                <SC.ItemContent
                  key={`SampleDataSummary-item-${index}-${i}`}
                  $withIcon={content.icon ? true : false}
                >
                  {content.icon && <SC.ItemIcon {...content.icon} />}
                  {content.text && <SC.ItemText>{content.text}</SC.ItemText>}
                  {content.image && (
                    <SC.SliderContainer>
                      <SimpleSlider image={content.image && content.image.map((img) => img)} />
                    </SC.SliderContainer>
                  )}
                </SC.ItemContent>
              ))}

            {item.allCollection && <SC.AllCollectionSwitch {...item.allCollection} />}
          </SC.Item>
        ))}
      </SC.List>
      {(bottomNote?.enteredBy || bottomNote?.date) && (
        <SC.BottomNote>
          {bottomNote?.enteredBy && <span>{bottomNote?.enteredBy}</span>}
          {bottomNote?.date && <time>{bottomNote?.date}</time>}
        </SC.BottomNote>
      )}
    </SC.Container>
  ) : null
}

export default Summary
