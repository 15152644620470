import React, { useEffect, useState } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

import UsersManagementTemplate from '../../templates/UsersManagement'
import { usersManagementTemplateMocks } from '../../templates/UsersManagement/mocks'
import { UserFormValues } from '../../components/forms/UserForm'
import FormFieldRadioGroup from '../../components/form/fields/FormFieldRadioGroup'
import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { InputMaybe, UserRole } from '../../graphql/generated/graphql'
import { router, routesPath } from '../../router'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'

const ParamsUserManage: React.FC<RouteProps> = () => {
  const useUpdateUser = useSelector(selectors.user.updateUser)
  const user = useSelector(selectors.user.user)
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isValid, setIsValid] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [newUser, setNewUser] = useState<UserFormValues | undefined>(undefined)
  const userDelete = useSelector(selectors.user.userDelete)

  useEffect(() => {
    if (useUpdateUser.success || userDelete.success) {
      const link = router(routesPath.paramsUsers)
      navigate(link)
    }
  }, [dispatch, navigate, useUpdateUser.success, userDelete.success])

  return (
    <UsersManagementTemplate
      layoutProps={{
        ...layoutProps,
        bottomActions: {
          ...usersManagementTemplateMocks.basic.layoutProps?.bottomActions,
          back: {
            ...usersManagementTemplateMocks.basic.layoutProps?.bottomActions?.back,
            href: '/parametres/acces-utilisateurs',
          },
          next: {
            text: 'Valider',
            type: 'submit',
            disabled: !isValid,
            onClick: () => {
              user &&
                dispatch(
                  actions.user.updateUserRequest({
                    userId: user.id,
                    input: {
                      firstName: newUser?.firstName,
                      lastName: newUser?.lastName,
                      role: newUser?.role as InputMaybe<UserRole> | undefined,
                      email: newUser?.email,
                    },
                  })
                )
            },
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
          },
        },
      }}
      title="Accès utilisateurs"
      subtitle="éditer le profil utilisateur"
      form={{
        legendIds: 'Identifiants de connexion',
        formikForm: {
          initialValues: {
            lastName: user?.lastName,
            firstName: user?.firstName,
            email: user?.email,
            role: user?.role,
          } as UserFormValues,
          validationSchema: Yup.object().shape({
            lastName: Yup.string().required(),
            firstName: Yup.string().required(),
            email: Yup.string().required().email(),
            role: Yup.string().required(),
          }),
          onContextUpdate: (context) => {
            setNewUser(context.values)
            setIsValid(context.isValid)
          },
          onSubmit: () => undefined,
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          lastName: {
            name: 'lastName',
            label: 'Nom de famille',
            required: true,
          },
          firstName: {
            name: 'firstName',
            label: 'Prénom',
            required: true,
          },
          email: {
            name: 'email',
            label: 'E-mail',
            required: true,
          },
          role: {
            name: 'role',
            required: true,
            Component: FormFieldRadioGroup,
            label: 'Son rôle utilisateur',
            options: [
              {
                label: 'DSI - Super admin',
                value: 'SUPER_ADMIN',
              },
              {
                label: 'Équipe marketplace',
                value: 'MARKETPLACE',
              },
              {
                label: 'Équipe mode PV',
                value: 'MODE_PV',
              },
              {
                label: 'Équipe shooting',
                value: 'SHOOTING',
              },
              {
                label: 'Expert mode',
                value: 'FASHION_EXPERT',
              },
              {
                label: 'Opérateur de saisie',
                value: 'INPUT_OPERATOR',
              },
              {
                label: 'REX',
                value: 'REX',
              },
              {
                label: 'Stagiaire',
                value: 'INTERN',
              },
            ],
          },
        },
      }}
      deleteModal={{
        text: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
        cancelButton: {
          text: 'Annuler',
          onClick: () => setIsModalOpen(false),
        },
        deleteButton: {
          text: 'Oui, supprimer',
          onClick: () => {
            user && dispatch(actions.user.userDeleteRequest({ userId: user?.id }))
            setIsModalOpen(false)
          },
        },
        isOpen: isModalOpen,
      }}
      deleteButton={{
        text: 'Supprimer cet utilisateur',
        onClick: () => setIsModalOpen(true),
        rightIconProps: {
          icon: Icons.trash,
          width: 12,
          height: 12,
        },
      }}
    />
  )
}

export default ParamsUserManage
