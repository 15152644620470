import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'

import { Icons } from '../../components/Icon'
import { Exhibition } from '../../graphql/generated/graphql'
import { router, routesPath } from '../../router'

import { LayoutExhibitionSheetProps } from '.'

export const useLayoutExhibitionSheetTemplateProps = (
  exhibition: Exhibition | null
): LayoutExhibitionSheetProps => {
  const navigate = useNavigate()
  const formattedStartDate = dayjs(exhibition?.dateStart).format('DD')
  const formattedEndDate = dayjs(exhibition?.dateEnd).format('DD MMMM')

  const isSameMonth = dayjs(exhibition?.dateStart).isSame(dayjs(exhibition?.dateEnd), 'month')

  const dateRange = isSameMonth
    ? `${formattedStartDate} - ${formattedEndDate}`
    : `${formattedStartDate} ${dayjs(exhibition?.dateStart).format(
        'DD MMMM'
      )} - ${formattedEndDate}`
  return useMemo(() => {
    return {
      bottomActions: {
        back: {
          leftIconProps: {
            icon: Icons.arrowLeft,
            width: 18,
            height: 18,
          },
          rounded: true,
          onClick: () => {
            const link = router(routesPath.lounges, { redirect: 'recherche' })
            navigate(link)
          },
        },
      },
      title: exhibition?.name || '',
      season: `${exhibition?.seasonCode}${exhibition?.seasonYear}` || '',
      dates: dateRange,
    }
  }, [dateRange, exhibition?.name, exhibition?.seasonCode, exhibition?.seasonYear, navigate])
}
