import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import {
  Collection,
  CollectionUpdateInput,
  Exhibitor,
  ExhibitorPaginator,
  ExhibitorsExportInput,
  InputMaybe,
  InternalNoteCommentableType,
} from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const exhibitorsAutocompleteService = getCustomService<
  'exhibitorsAutocomplete',
  null,
  { q: string }
>('exhibitorsAutocomplete')

export const exhibitorsService = getCustomService<
  'exhibitorsService',
  null,
  {
    q: string
    universesEbmsIds: string[]
    pavilionsEbmsIds: string[]
    countries: string[]
    exhibitionsApiIds: string[]
    first: number
    page: number
  }
>('exhibitorsService')

export const exportExhibitorsService = getCustomService<
  'exportExhibitors',
  null,
  { filters: ExhibitorsExportInput }
>('exportExhibitors')

export const exhibitorsCollectionService = getCustomService<
  'exhibitorsCollectionService',
  null,
  {
    exhibitorEbmsId: string
    exhibitionApiId: InputMaybe<string> | undefined
  }
>('exhibitorsCollectionService')

export const collectionsByExhibitorService = getCustomService<
  'collectionsByExhibitor',
  null,
  {
    exhibitorEbmsId: string
  }
>('collectionsByExhibitor')

export const collectionParcelCreateService = getCustomService<
  'collectionParcelCreate',
  Collection,
  {
    collectionId: string
    samplesTotal: number
    samplesDistinct: number
  }
>('collectionParcelCreate')

export const internalNoteCreateService = getCustomService<
  'internalNoteCreate',
  null,
  {
    commentableType: InternalNoteCommentableType
    commentableId: string
    body: string
  }
>('internalNoteCreate')

export const internalNoteDeleteService = getCustomService<
  'internalNoteDelete',
  null,
  {
    internalNoteId: string
  }
>('internalNoteDelete')

export const internalNoteUpdateService = getCustomService<
  'internalNoteUpdate',
  null,
  {
    internalNoteId: string
    body: string
  }
>('internalNoteUpdate')

export const collectionParcelUpdateService = getCustomService<
  'collectionParcelUpdate',
  null,
  {
    parcelId: string
    samplesTotal: number
    samplesDistinct: number
  }
>('collectionParcelUpdate')

export const collectionParcelDeleteService = getCustomService<
  'collectionParcelDelete',
  null,
  {
    parcelId: string
  }
>('collectionParcelDelete')

export const collectionUpdateService = getCustomService<
  'collectionUpdate',
  null,
  {
    collectionId: string
    input: CollectionUpdateInput
  }
>('collectionUpdate')

//
// Initial state
//

export type ExhibitorsState = {
  successMessage: string | undefined
  exhibitors: any | null
  exhibitorsPaginator: ExhibitorPaginator | null
  exhibitor: Exhibitor | null
  collection: Collection | null
  collectionExhibitions: Collection[] | null
  collectionExhibitionsPending: boolean
  // services
  exhibitorsAutocomplete: typeof exhibitorsAutocompleteService.state
  exhibitorsService: typeof exhibitorsService.state
  exportExhibitors: typeof exportExhibitorsService.state
  exhibitorsCollection: typeof exhibitorsCollectionService.state
  collectionParcelCreate: typeof collectionParcelCreateService.state
  collectionParcelUpdate: typeof collectionParcelUpdateService.state
  collectionParcelDelete: typeof collectionParcelDeleteService.state
  collectionUpdate: typeof collectionUpdateService.state
  internalNoteCreate: typeof internalNoteCreateService.state
  internalNoteDelete: typeof internalNoteDeleteService.state
  internalNoteUpdate: typeof internalNoteUpdateService.state
  collectionsByExhibitor: typeof collectionsByExhibitorService.state
}

const initialState: ExhibitorsState = {
  successMessage: undefined,
  exhibitors: null,
  exhibitorsPaginator: null,
  exhibitor: null,
  collection: null,
  collectionExhibitions: null,
  collectionExhibitionsPending: false,
  // services
  exhibitorsAutocomplete: exhibitorsAutocompleteService.state,
  exhibitorsService: exhibitorsService.state,
  exhibitorsCollection: exhibitorsCollectionService.state,
  collectionParcelCreate: collectionParcelCreateService.state,
  collectionParcelUpdate: collectionParcelUpdateService.state,
  collectionParcelDelete: collectionParcelDeleteService.state,
  collectionUpdate: collectionUpdateService.state,
  internalNoteCreate: internalNoteCreateService.state,
  internalNoteDelete: internalNoteDeleteService.state,
  internalNoteUpdate: internalNoteUpdateService.state,
  collectionsByExhibitor: collectionsByExhibitorService.state,
  exportExhibitors: exportExhibitorsService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'exhibitors',
  initialState,
  reducers: {
    resetExhibitors: () => initialState,
    setCollectionExhibitionsPending: (
      state,
      { payload }: actionTypes.setCollectionExhibitionsPending
    ) => {
      state.collectionExhibitionsPending = payload
    },
    setExhibitors: (state, { payload }: actionTypes.setExhibitors) => {
      state.exhibitors = payload
    },
    setExhibitorsPaginator: (state, { payload }: actionTypes.setExhibitorsPaginator) => {
      state.exhibitorsPaginator = payload
    },
    setCollection: (state, { payload }: actionTypes.setCollection) => {
      state.collection = payload
    },
    setCollectionExhibitions: (state, { payload }: actionTypes.setCollectionExhibitions) => {
      state.collectionExhibitions = payload
    },
    setSuccessMessage: (state, action: actionTypes.setSuccessMessage) => {
      state.successMessage = action.payload
    },
    setExhibitor: (state, { payload }: actionTypes.setExhibitor) => {
      state.exhibitor = payload
    },
    resetExportExhibitors: (state) => {
      state.exportExhibitors = initialState.exportExhibitors
    },
    resetCreateParcel: (state) => {
      state.collectionParcelCreate = initialState.collectionParcelCreate
    },
    resetUpdateParcel: (state) => {
      state.collectionParcelUpdate = initialState.collectionParcelUpdate
    },
    resetDeleteParcel: (state) => {
      state.collectionParcelDelete = initialState.collectionParcelDelete
    },
    resetUpdateCollection: (state) => {
      state.collectionUpdate = initialState.collectionUpdate
    },
    resetInternalNoteCreate: (state) => {
      state.internalNoteCreate = initialState.internalNoteCreate
    },
    resetInternalNoteDelete: (state) => {
      state.internalNoteDelete = initialState.internalNoteDelete
    },
    resetInternalNoteUpdate: (state) => {
      state.internalNoteUpdate = initialState.internalNoteUpdate
    },
    ...exhibitorsAutocompleteService.reducers,
    ...exhibitorsService.reducers,
    ...exhibitorsCollectionService.reducers,
    ...collectionParcelCreateService.reducers,
    ...collectionParcelUpdateService.reducers,
    ...collectionParcelDeleteService.reducers,
    ...collectionUpdateService.reducers,
    ...internalNoteCreateService.reducers,
    ...internalNoteDeleteService.reducers,
    ...internalNoteUpdateService.reducers,
    ...collectionsByExhibitorService.reducers,
    ...exportExhibitorsService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const exhibitors = (state: RootState) => root(state).exhibitors
const exportExhibitors = (state: RootState) => root(state).exportExhibitors
const exhibitorsPaginator = (state: RootState) => root(state).exhibitors
const exhibitor = (state: RootState) => root(state).exhibitor
const collection = (state: RootState) => root(state).collection
const collectionExhibitions = (state: RootState) => root(state).collectionExhibitions
const exhibitorsAutocomplete = (state: RootState) => root(state).exhibitorsAutocomplete
const exhibitorsSearch = (state: RootState) => root(state).exhibitorsService
const exhibitorsCollection = (state: RootState) => root(state).exhibitorsCollection
const collectionParcelCreate = (state: RootState) => root(state).collectionParcelCreate
const collectionParcelUpdate = (state: RootState) => root(state).collectionParcelUpdate
const collectionParcelDelete = (state: RootState) => root(state).collectionParcelDelete
const collectionUpdate = (state: RootState) => root(state).collectionUpdate
const successMessage = (state: RootState) => root(state).successMessage
const internalNoteCreate = (state: RootState) => root(state).internalNoteCreate
const internalNoteDelete = (state: RootState) => root(state).internalNoteDelete
const internalNoteUpdate = (state: RootState) => root(state).internalNoteUpdate
const collectionsByExhibitor = (state: RootState) => root(state).collectionsByExhibitor

const collectionExhibitionsPending = (state: RootState) => root(state).collectionExhibitionsPending

export const selectors = {
  exhibitors,
  exportExhibitors,
  exhibitorsPaginator,
  exhibitor,
  collection,
  collectionExhibitions,
  exhibitorsAutocomplete,
  exhibitorsSearch,
  exhibitorsCollection,
  collectionParcelCreate,
  collectionParcelUpdate,
  collectionParcelDelete,
  collectionUpdate,
  successMessage,
  internalNoteCreate,
  internalNoteDelete,
  internalNoteUpdate,
  collectionsByExhibitor,
  collectionExhibitionsPending,
}
