import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormErrors from '../../form/FormErrors'
import FormFieldCheckboxGroup from '../../form/fields/FormFieldCheckboxGroup'
import { breakpoints } from '../../../theme'

export const Form = styled(FormikForm)``

export const FieldCheckboxGroup = styled(FormFieldCheckboxGroup)`
  & > div {
    display: flex;
    flex-flow: column;
    gap: 0.8rem;
  }
  & label {
    flex-flow: row-reverse;
    padding: 0;
  }
  ${breakpoints.up('desktop')} {
    & > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: auto;
      grid-auto-flow: dense;
      gap: 0.8rem 4rem;
      direction: rtl;
    }
    & label {
      white-space: nowrap;
    }
  }
`

export const Errors = styled(FormErrors)``
