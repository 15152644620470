import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const authResetPassword = getMutationService({
  mutation: gql(`
	mutation authResetPassword(
	$code: String!
	$email: String!
	$password: String!
    ) {
        authResetPassword(code: $code, email: $email, password: $password) {
            user {
              id
              type
              role
              firstName
              lastName
              email
              lastLoginAt
              externalAccessFrom
              externalAccessTo
              createdAt
              updatedAt
              type
            }
            token {
                expiresIn
                tokenType
                accessToken
            }
        }
    }
	  `),
  transformer: (response) => {
    return response
  },
})
