import React, { FC } from 'react'

import LayoutNotConnected, { LayoutNotConnectedProps } from '../../layouts/LayoutNotConnected'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type $variant =
  | 'default'
  | 'validate'
  | 'delete'
  | 'leather'
  | 'sourcing'
  | 'accessories'
  | 'inspiration'
  | 'design'
  | 'forumSelect'

export type LoginChoiceTemplateProps = {
  layout?: LayoutNotConnectedProps
  title: string
  setChoicePv: () => void
  setChoiceExt: () => void
  pvButton: ActionButtonProps
  extButton: ActionButtonProps
}

const LoginChoice: FC<LoginChoiceTemplateProps> = ({
  layout,
  title,
  setChoicePv,
  setChoiceExt,
  pvButton,
  extButton,
}) => {
  return (
    <LayoutNotConnected {...layout}>
      <SC.StyledContentWrapper maxWidth={'small'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.CTAListContainer>
          <SC.CTA {...pvButton} onClick={setChoicePv} />
          <SC.CTA {...extButton} onClick={setChoiceExt} />
        </SC.CTAListContainer>
      </SC.StyledContentWrapper>
    </LayoutNotConnected>
  )
}

export default LoginChoice
