import styled from 'styled-components'

import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import Steps from '../../components/Steps'
import NumberedTag from '../../components/NumberedTag'
import { Picture } from '../../templates/SampleDataTrainee/styled'

export const ContentWrapper = styled.div`
  padding-inline: 2rem;
  max-width: 100vw;
  ${breakpoints.up('tablet')} {
    padding-inline: 4rem;
  }
`
export const ModalPicture = styled(Picture)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  & img {
    max-width: min(90vw, 60rem);
    pointer-events: all;
  }
`

export const TopContent = styled(ContentWrapper)`
  padding-block: 1.2rem;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem 1.2rem;
  }
`

export const MarketplaceWarning = styled(NumberedTag)`
  margin-top: 12px;
  margin-bottom: 12px;
`

export const Title = styled(Heading)`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${breakpoints.up('tablet')} {
  }
`
export const Wrapper = styled.div`
  ${breakpoints.up('tablet')} {
    display: grid;
    grid-template-columns: minmax(min-content, 20rem) auto;
    grid-gap: 4rem;
    padding-block: 2.4rem;
    padding-inline: 4rem;
  }
  ${breakpoints.up('large')} {
    grid-gap: 8rem;
  }
`
export const StyledSteps = styled(Steps)`
  padding-inline: 2rem;
  ${breakpoints.up('tablet')} {
    padding-inline: 0;
    padding-block: 0;
    width: 100%;
    min-width: min-content;
    max-width: 20rem;
  }
`
export const Content = styled.div`
  padding-block: 1.2rem;
  padding-inline: 2rem;
  max-width: 100vw;
  ${breakpoints.up('tablet')} {
    max-width: 52rem;
    padding-block: 0;
    padding-inline: 0;
  }
`

export const Separator = styled.hr`
  align-self: stretch;
  border: 0;
  margin: 0;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.tundora};
  ${breakpoints.up('tablet')} {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`
