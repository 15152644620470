import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.RobotoExtraLight}
    font-size: 3.2rem;
    line-height: 1.2;
  `,
  titleH2: `
    ${fonts.RobotoExtraLight}
    font-size: 2.8rem;
    line-height: 1.2;
  `,
  titleH3: `
    ${fonts.RobotoLight}
    font-size: 1.7rem;
    line-height: 1.2;
  `,
  titleH4: `
    ${fonts.GothamMedium}
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 1.2;
  `,
  titleH5: `
    ${fonts.RobotoLight}
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 1.2;
  `,
  titleH6: `
    ${fonts.GothamBold}
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    line-height: 1.6;
  `,
  titleH7: `
    ${fonts.GothamMedium}
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    line-height: 1.1;
  `,
  titleH8: `
    ${fonts.GothamBold}
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.1;
  `,
  textBold: `
    ${fonts.GothamBold}
    font-size: 1.4rem;
    line-height: 1.2;
  `,
  textBook: `
    ${fonts.GothamBook}
    font-size: 1.4rem;
    line-height: 1.2;
  `,
  textLarge: `
    ${fonts.RobotoLight}
    font-size: 1.7rem;
    letter-spacing: 0.5px;
    line-height: 1.2;
  `,
  textItalic: `
    ${fonts.RobotoLightItalic}
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 1.2;
  `,
}

export default textStyles
