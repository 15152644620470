import React, { FC } from 'react'

import * as SC from './styled'

export type NumberedTagProps = {
  className?: string
  number?: string | number
  text?: string
  redBg?: boolean
}

const NumberedTag: FC<NumberedTagProps> = ({ className, number, text, redBg }) => {
  return text || typeof number !== 'undefined' ? (
    <SC.Container className={className} $redBg={redBg}>
      {number && <SC.Number>{number}</SC.Number>}
      {text && <SC.Text>{text}</SC.Text>}
    </SC.Container>
  ) : null
}

export default NumberedTag
