import styled from 'styled-components'

import { breakpoints } from '../../theme'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  //position: relative;
  & .camera {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.titleH2};
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Svg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${breakpoints.up('tablet')} {
    display: none;
  }
`
export const SvgTablet = styled.svg`
  display: none;
  ${breakpoints.up('tablet')} {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    fill: #232323;
  }
`

export const Button = styled.svg`
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
`
