import React, { FC } from 'react'

import { ModalProps } from '../Modal'
import { IconProps } from '../Icon'
import { ModalVariant } from '../Modal/types'

import * as SC from './styled'

export type ModalToastProps = ModalProps & {
  className?: string
  text: string
  icon?: IconProps
}

const ModalToast: FC<ModalToastProps> = ({ className, text, icon, ...modal }) => {
  return (
    <SC.Container className={className} {...modal} variant={ModalVariant.Toast}>
      <SC.Content>
        {icon && <SC.StyledIcon {...icon} />}
        <SC.Text text={text} />
      </SC.Content>
    </SC.Container>
  )
}

export default ModalToast
