import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import FormikField, { FormikFieldProps } from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { VariantRadioFont, VariantRadioSize } from '../../form/fields/FormFieldRadioGroup/types'

import * as SC from './styled'

export type UserFormValues = {
  firstName: string
  lastName: string
  email: string
  role: string
}

export type UserFormProps = {
  className?: string
  legendIds?: string
  formikForm: FormikFormProps<UserFormValues>
  fields: {
    firstName: FormFieldInputProps
    lastName: FormFieldInputProps
    email: FormFieldInputProps
    role: FormikFieldProps
  }
  errors?: FormErrorsProps['errors']
}

const UserForm: FC<UserFormProps> = ({ className, legendIds, formikForm, fields, errors }) => {
  return (
    <SC.Container className={className}>
      <SC.Form {...formikForm}>
        <SC.Fieldset>
          {legendIds && <SC.Legend>{legendIds}</SC.Legend>}
          <FormikField
            {...fields.lastName}
            type="text"
            hasFloatingLabel={true}
            Component={SC.FieldText}
          />
          <FormikField
            {...fields.firstName}
            type="text"
            hasFloatingLabel={true}
            Component={SC.FieldText}
          />
          <FormikField
            {...fields.email}
            type="email"
            hasFloatingLabel={true}
            Component={SC.FieldText}
            error="hey"
          />
        </SC.Fieldset>
        <SC.Fieldset>
          <FormikField
            {...fields.role}
            Component={SC.Radios}
            optionsSize={VariantRadioSize.big}
            optionsFont={VariantRadioFont.SmallBold}
          />
        </SC.Fieldset>
        {errors && <SC.Errors errors={errors} />}
      </SC.Form>
    </SC.Container>
  )
}

export default UserForm
