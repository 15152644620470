import styled from 'styled-components'

import { breakpoints, textStyles } from '../../theme'
import Divider from '../../components/Divider'
import ContentWrapper from '../../components/ContentWrapper'
import CollapsibleItem from '../../components/CollapsibleItem'
import FormikForm from '../../components/form/FormikForm'
import FormFieldRadioGroup from '../../components/form/fields/FormFieldRadioGroup'
import FormikField from '../../components/form/FormikField'
import SampleChart from '../../components/SampleChart'
import Heading from '../../components/Heading'

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    margin-bottom: 7rem;
  }
`

export const Radio = styled.label`
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.concrete};
  margin-left: 3.5rem;
  padding: 1.2rem;
  justify-content: space-between;
  cursor: pointer;
`

export const Collapsible = styled(CollapsibleItem)`
  border-radius: 0.4rem;
  margin-left: 3.5rem;
`

export const CollapsibleTop = styled(CollapsibleItem)`
  border-radius: 0.4rem;
`

export const StyledDivider = styled(Divider)``

export const ResumeSubForum = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.6rem;
  ${breakpoints.up('tablet')} {
    padding: 1.2rem 0;
    margin-top: 12px;
  }
`

export const SubForum = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.tundora};
  ${({ theme }) => theme.textStyles.titleH6}
`

export const Form = styled(FormikForm)`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`

export const StyledRadiosGroup = styled(FormFieldRadioGroup)``

export const StyledFormikField = styled(FormikField)`
  margin-left: 3.5rem;
  padding: 6px 0px;
`

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.colors.concrete};
  width: 100%;
  gap: 12px;
  border-radius: 4px;
  ${breakpoints.up('tablet')} {
    flex-direction: column;
  }
`

export const InfosContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  width: 100%;

  ${breakpoints.down('tablet')} {
    grid-template-columns: 1fr 1fr;
  }
`

export const ExhibitorNbr = styled.span`
  ${textStyles.titleH4}
  ${breakpoints.up('tablet')} {
    ${textStyles.titleH2}
  }
`

export const Text = styled.span`
  ${textStyles.titleH5}
  ${breakpoints.up('tablet')} {
    ${textStyles.titleH6}
  }
`

export const Chart = styled(SampleChart)``

export const Section = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  margin: 0;
  margin-bottom: 42px;
`

export const Title = styled(Heading)`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.gray};
  ${breakpoints.up('tablet')} {
  }
`

export const Subtitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.tundora};
  text-transform: uppercase;
`
