import styled from 'styled-components'

import Heading from '../../components/Heading'
import ActionButton from '../../components/ActionButton'
import ContentWrapper from '../../components/ContentWrapper'
import { breakpoints } from '../../theme'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const StyledContentWrapper = styled(ContentWrapper)`
  max-width: 37rem;
  margin-top: 5.6rem;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
  ${breakpoints.up('tablet')} {
    text-align: center;
  }
`

export const CTAListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin: 1rem 0;
`

export const CTA = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.pureWhite}; ;
`
