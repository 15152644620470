import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Tab = styled.span<{ $selected: boolean }>`
  ${({ theme }) => theme.textStyles.titleH6};
  flex: 1;
  padding-bottom: 0.7rem;
  color: ${({ theme }) => theme.colors.silver};
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.silver};
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;

  ${({ $selected, theme }) =>
    $selected &&
    css`
      color: ${theme.colors.mineShaft};
      border-color: ${theme.colors.mineShaft};
    `};
`
