import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleCreate = getMutationService({
  mutation: gql(`
    mutation sampleCreate($input: SampleCreateInput!) {
      sampleCreate(input: $input) {
        id
        collectionId
        universeEbmsId
        labels {
            id
            user {
                id
                email
            }
            url
            path
            order
        }
        name
        qrCode
        status
        box {
          id
          name
        }
        forum {
          id
          name
        }
        subForum {
          id
          name
        }
        mkpForum
        flagItems {
          id
          flagGroupId
          name
          cascadingGroups
        }
        createdAt
        updatedAt
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
