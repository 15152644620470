import React, { FC, ImgHTMLAttributes } from 'react'

import { ActionButtonProps } from '../ActionButton'
import { ActionButtonScheme } from '../ActionButton/types'
import { SampleStatus } from '../../graphql/generated/graphql'

import * as SC from './styled'

export type SampleCardProps = {
  className?: string
  status: { type: SampleStatus; value: string }
  forumColor?: string
  forum?: string
  subForum?: string
  mkpForum?: string
  okMkp: boolean
  noMkp: boolean
  title: string
  number?: string
  category?: string
  image?: ImgHTMLAttributes<HTMLImageElement>
  selected?: boolean
  selectMode?: boolean
  isDelete?: boolean
  disabled?: boolean
  editButton?: ActionButtonProps
  marketplaceWarning?: {
    daysLeft: string
    text: string
  }
  onDelete?: () => void
  onClick?: () => void
}

const SampleCard: FC<SampleCardProps> = ({
  className,
  status,
  forum,
  forumColor,
  subForum,
  mkpForum,
  okMkp,
  noMkp,
  number,
  title,
  image,
  category,
  selected,
  selectMode,
  editButton,
  isDelete,
  disabled,
  marketplaceWarning,
  onDelete,
  onClick,
}) => {
  return (
    <SC.Card className={className} $selected={disabled}>
      {onDelete && (
        <SC.DeleteButton onClick={onDelete}>
          <SC.DeleteIcon />
        </SC.DeleteButton>
      )}
      {selectMode && (
        <SC.DeleteButton onClick={onClick}>
          <SC.SelectModeIcon />
        </SC.DeleteButton>
      )}
      {selected && selectMode && (
        <SC.DeleteButton onClick={onClick}>
          <SC.SelectIcon />
        </SC.DeleteButton>
      )}
      <SC.Top onClick={onClick}>
        {isDelete ? (
          <SC.Status $status={SampleStatus.Deleted}>
            Supprimé
            <SC.Dot />
          </SC.Status>
        ) : (
          <SC.Status $status={status.type}>
            {status.value}
            <SC.Dot />
          </SC.Status>
        )}

        {forum && (
          <SC.Forum $color={forumColor}>
            <>
              <SC.ForumSubContainer>
                {forum}
                <SC.Dot />
              </SC.ForumSubContainer>
              <SC.ForumSubContainer>
                {subForum}
                <SC.Dot />
              </SC.ForumSubContainer>
            </>
          </SC.Forum>
        )}
        {mkpForum && (
          <SC.MkpForum $okMkp={okMkp} $noMkp={noMkp}>
            {mkpForum}
            <SC.Dot />
          </SC.MkpForum>
        )}
      </SC.Top>
      <SC.Bottom>
        {image && (
          <SC.BlockImage onClick={onClick}>
            <SC.Image {...image} />
          </SC.BlockImage>
        )}
        <SC.BlockContent>
          <div onClick={onClick}>
            <SC.Description>
              <SC.Number>#{number}</SC.Number>
              {title !== 'ECHANTILLON' && <SC.Name>{title}</SC.Name>}
            </SC.Description>
            {category && <SC.Category>{category}</SC.Category>}
          </div>
          {editButton && <SC.EditButton variantScheme={ActionButtonScheme.Link} {...editButton} />}
        </SC.BlockContent>
      </SC.Bottom>
      {marketplaceWarning && (
        <SC.MarketplaceWarning>
          <SC.DaysLeftMarketplace>J - {marketplaceWarning.daysLeft}</SC.DaysLeftMarketplace>{' '}
          <SC.TextLeftMarketplace>{marketplaceWarning.text}</SC.TextLeftMarketplace>
        </SC.MarketplaceWarning>
      )}
    </SC.Card>
  )
}

export default SampleCard
