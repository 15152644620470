import styled from 'styled-components'

import SearchBar from '../SearchBar'
import MenuDesktop from '../MenuDesktop'
import Icon, { Icons } from '../Icon'
import { breakpoints, colors } from '../../theme'
import ActionButton from '../ActionButton'
import Link from '../Link'

export const SidebarNav = styled.aside`
  background-color: ${({ theme }) => theme.colors.tundora};
  max-width: 32rem;
  min-height: 100vh;
  padding: 4.2rem 2rem 2rem;
  ${breakpoints.up('tablet')} {
    width: 32rem;
  }
`

export const LinkHome = styled(Link)`
  margin-bottom: 5.4rem;
  cursor: pointer;
`

export const Logo = styled(Icon).attrs({
  icon: Icons.logo,
  width: 160,
  height: 18,
  color: colors.pureWhite,
})``

export const Search = styled(SearchBar)`
  margin-bottom: 4.8rem;
`

export const Menu = styled(MenuDesktop)``

export const CTA = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 6rem;
  text-decoration: none;
`
