import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { TabsScrollableProps } from '../../components/TabsScrollable'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import SimpleCard, { SimpleCardProps } from '../../components/SimpleCard'
import SampleResumeInfo, { SampleResumeInfoProps } from '../../components/SampleResumeInfo'
import Warning from '../../components/Warning'
import { NumberedTagProps } from '../../components/NumberedTag'
import Modal, { ModalProps } from '../../components/Modal'
import NoteModal, { NoteModaleProps } from '../../components/NoteModale'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'
import { LoungeCardProps } from '../../components/LoungeCard'
import { ActionButtonProps } from '../../components/ActionButton'
import { ActionButtonSize } from '../../components/ActionButton/types'
import Icon, { IconProps } from '../../components/Icon'

import * as SC from './styled'

export type LayoutSampleSheetProps = LayoutWithBottomActionsProps & {
  sampleId?: string
  copyPastBtn?: IconProps
  title?: string
  exponentInfos?: SimpleCardProps
  sampleInfos?: SampleResumeInfoProps
  samplecategory?: string
  warnings?: string[]
  noteModal?: ModalProps
  notes?: NoteModaleProps
  numbered?: NumberedTagProps
  tabs?: TabsScrollableProps
  deleteModal?: ModalDeleteProps
  modalToast?: ModalToastProps
  children?: React.ReactNode
  isArchived?: LoungeCardProps
  rightButton?: ActionButtonProps
  copyPastOnClick?: () => void
}

const LayoutSampleSheet: FC<LayoutSampleSheetProps> = ({
  sampleId,
  copyPastBtn,
  title,
  sampleInfos,
  exponentInfos,
  samplecategory,
  warnings,
  numbered,
  tabs,
  deleteModal,
  notes,
  noteModal,
  modalToast,
  children,
  isArchived,
  rightButton,
  copyPastOnClick,
  ...layoutProps
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={
        <>
          {deleteModal && <ModalDelete {...deleteModal} />}
          {noteModal && (
            <Modal {...noteModal}>
              <NoteModal {...notes} />
            </Modal>
          )}
          {modalToast && <ModalToast {...modalToast} />}
        </>
      }
    >
      {isArchived && <SC.StyledLoungeCard {...isArchived} />}
      {sampleInfos && <SampleResumeInfo {...sampleInfos} />}
      <SC.Top>
        <SC.TopContent maxWidth="default">
          <SC.IdContainer>
            <SC.SampleId>#{sampleId}</SC.SampleId>
            {copyPastBtn && (
              <SC.CopyPastBtn onClick={copyPastOnClick}>
                <Icon {...copyPastBtn} />
              </SC.CopyPastBtn>
            )}
          </SC.IdContainer>
          {title && <SC.Title text={title} tag="h2" />}
          <SimpleCard {...exponentInfos} />
          {samplecategory && <SC.Samplecategory>{samplecategory}</SC.Samplecategory>}
          {warnings &&
            warnings.map((item: string, key: number) => <Warning key={key} label={item} />)}
          {numbered && <SC.StyledNumberedTag {...numbered} />}
        </SC.TopContent>
        {rightButton && <SC.Button variantSize={ActionButtonSize.Large} {...rightButton} />}
      </SC.Top>
      {tabs ? <SC.StyledTabs {...tabs} /> : <SC.Separator />}
      <SC.BottomContent maxWidth="default">{children}</SC.BottomContent>
    </LayoutWithBottomActions>
  )
}

export default LayoutSampleSheet
