import { useMemo } from 'react'

import { Icons } from '../../components/Icon'
import { ForumBox } from '../../graphql/generated/graphql'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'

import { BoxesListTemplateProps } from '.'

export const useBoxesListTemplateProps = (
  search: string,
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void,
  boxes: ForumBox[] | null
): BoxesListTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('params')

  const sortedBoxes = boxes
    ? [...boxes].sort((a, b) => {
        const nameA = a ? a.name || '' : ''
        const nameB = b ? b.name || '' : ''
        return nameA.localeCompare(nameB)
      })
    : []

  const sortSubForumBoxes = (boxes: ForumBox[] | null) => {
    if (!boxes) return []

    return boxes.map((box) => ({
      ...box,
      subForumBoxes: box.subForumBoxes
        ? [...box.subForumBoxes].sort((a, b) => {
            const nameA = a ? a.name || '' : ''
            const nameB = b ? b.name || '' : ''
            return nameA.localeCompare(nameB)
          })
        : [],
    }))
  }

  const sortedBoxesWithSortedSubForumBoxes = sortSubForumBoxes(sortedBoxes)

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            href: '/parametres',
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
          },
          next: {
            text: 'Ajouter une caisse',
            href: '/parametres/caisses-de-rangement/creer-caisses-de-rangement',
            rightIconProps: {
              icon: Icons.plus,
              width: 18,
              height: 18,
            },
          },
        },
      },
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      title: 'Caisses de rangement',
      subtitle: 'Caisses forums',
      text:
        'Ces caisses sont créées automatiquement car associées aux forums et sous-forums du salon.',
      forums: sortedBoxesWithSortedSubForumBoxes || [],
    }),
    [handleSearchChange, layoutProps, search, sortedBoxesWithSortedSubForumBoxes]
  )
}
