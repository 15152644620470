import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Icons } from '../../components/Icon'
import { bottomActionsMocks } from '../../components/BottomActions/mocks'
import { actions } from '../../redux'
import { User } from '../../graphql/generated/graphql'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'

import { UsersAccessTemplateProps } from '.'

export const useParamsUsersTemplateProps = (
  search: string,
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void,
  users: any
): UsersAccessTemplateProps => {
  const dispatch = useDispatch()
  const layoutProps = useDefaultLayoutTemplateProps('params')

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            ...bottomActionsMocks.basic.back,
            rounded: true,
            href: '/parametres',
          },
          next: {
            text: 'Ajouter un utilisateur',
            href: '/parametres/creer-utilisateur',
            rightIconProps: {
              icon: Icons.plus,
              width: 18,
              height: 18,
            },
          },
        },
      },
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      title: 'Accès utilisateurs',
      userList:
        users &&
        users.map((user: User) => ({
          ...user,
          link: { href: '/parametres/gestion-utilisateur' },
          onClick: () => dispatch(actions.user.setUser({ ...user })),
        })),
      emptyString: 'Pas de résultats pour cette recherche',
    }),
    [dispatch, handleSearchChange, layoutProps, search, users]
  )
}
