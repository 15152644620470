import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { firstLoginFormMocks } from '../../components/forms/FirstLoginForm/mocks'
import { actions, selectors } from '../../redux'

import { FirstLoginTemplateProps } from '.'
import { firstLoginTemplateMocks } from './mocks'

export const useFirstLoginTemplateProps = (
  selectedEmail: string,
  code: string
): FirstLoginTemplateProps => {
  const dispatch = useDispatch()
  const firstLogin = useSelector(selectors.auth.authFirstLogin)

  return useMemo(
    () => ({
      ...firstLoginTemplateMocks.basic,
      firstLoginForm: {
        ...firstLoginFormMocks.basic,
        formikForm: {
          ...firstLoginFormMocks.basic.formikForm,
          initialValues: {
            ...firstLoginFormMocks.basic.formikForm.initialValues,
            email: selectedEmail,
          },
          onSubmit: (values) => {
            dispatch(
              actions.auth.authFirstLoginRequest({
                code: code,
                email: values.email,
                password: values.password,
              })
            )
          },
          errors: firstLogin.errors,
          submitButton: {
            isPending: firstLogin.pending,
            isSuccess: firstLogin.success,
            text: 'Se connecter',
            pendingText: 'Pending...',
            successText: 'Success !',
          },
        },
      },
    }),
    [selectedEmail, firstLogin.errors, firstLogin.pending, firstLogin.success, dispatch, code]
  )
}
