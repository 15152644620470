import styled, { css } from 'styled-components'

import MobileNav from '../../components/MobileNav'
import SidebarNav from '../../components/SidebarNav'

export const Container = styled.div`
  display: flex;
  min-height: 100svh;
  background-color: white;
  padding-top: var(--header-height);
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    padding-top: unset;
    display: grid;
    // bof
    grid-template-columns: 32rem calc(100% - 32rem);
  }
  @supports not (min-height: 100svh) {
    min-height: 100vh;
  }
`

export const Sidebar = styled(SidebarNav)`
  display: none;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    position: sticky;
    top: 0;
    display: block;
  }
`

export const MobileNavigation = styled(MobileNav)`
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    display: none;
  }
`

export const MainContent = styled.div<{
  $marginMainContent?: boolean
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ $marginMainContent }) =>
    $marginMainContent &&
    css`
      padding-bottom: 9rem;
    `};

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    overflow: clip;
    @supports not (overflow: clip) {
      overflow: hidden;
    }
  }
`
