import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { useExponentSheetTabsProps } from '../../relay/ExponentSheet/UseExponentSheetTabsProps'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { Collection, Exhibitor } from '../../graphql/generated/graphql'
import { useLayoutExponentSheetTemplateProps } from '../../layouts/LayoutExponentSheet/useProps'
import { actions, selectors } from '../../redux'
import { LoungeList } from '../Lounges'
import { router, routesPath } from '../../router'
import { LoungeCardProps } from '../../components/LoungeCard'

import { ExponentSheetArchiveTemplateProps } from '.'

export const useExponentSheetArchiveTemplateProps = (
  exhibitor: Exhibitor | null,
  collection: Collection | null
): ExponentSheetArchiveTemplateProps => {
  const dispatch = useDispatch()
  const { exposantId } = useParams<{ exposantId: string }>()
  const { apiId } = useParams<{ apiId: string }>()
  const user = useSelector(selectors.auth.user)
  const navigate = useNavigate()
  const config = useSelector(selectors.configuration.configurationInfo)
  const collectionByExhibitors = useSelector(selectors.exhibitors.collectionsByExhibitor)

  const tabs = useExponentSheetTabsProps(exposantId, apiId, 'archive', user?.role)
  const exhibitions = useSelector(selectors.exhibitors.collectionExhibitions)
  const currentYear = new Date().getFullYear().toString()

  useEffect(() => {
    exposantId &&
      dispatch(actions.exhibitors.collectionsByExhibitorRequest({ exhibitorEbmsId: exposantId }))
  }, [dispatch, exposantId])

  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const layoutPropsExponent = useLayoutExponentSheetTemplateProps(exhibitor, collection)

  const sortedData: LoungeList[] | null =
    exhibitions &&
    exhibitions
      .reduce((acc: LoungeList[], item) => {
        const { apiId, name, seasonCode, dateStart, seasonYear } = item.exhibition
        const seasonFullYear = new Date(dateStart).getFullYear().toString()
        const season = `${seasonCode}${seasonYear}`
        const existingYearIndex = acc.findIndex((item) => item.year === seasonFullYear)
        const dateEnd = new Date(item.exhibition.dateEnd)
        const currentDate = new Date()
        const oneYearAndHalf = 1000 * 60 * 60 * 24 * 540
        const loungeItem: LoungeCardProps = {
          title: name,
          text: `Exhibition ID ${apiId}`,
          season: season,
          active: config && apiId === config.exhibitionActiveApiId.toString() ? true : false,
          onClick:
            dateEnd.getTime() > currentDate.getTime() + oneYearAndHalf
              ? () => {
                  const link = router(routesPath.exhibitionSheetInfos, {
                    exhibitionId: item.exhibition.apiId as string,
                  })
                  navigate(link)
                }
              : () => {
                  const link = router(routesPath.exponentSheet, {
                    exposantId: exposantId,
                    apiId: item.exhibition.apiId as string,
                  })
                  navigate(link)
                },
        }

        if (config && apiId === config.exhibitionActiveApiId.toString()) {
          acc.unshift({
            year: `En cours`,
            isCollapsibleOpen: true,
            noIcon: true,
            lounge: [loungeItem],
          })
        } else if (existingYearIndex !== -1) {
          acc[existingYearIndex].lounge.push(loungeItem)
        } else {
          acc.push({
            year: seasonFullYear,
            isCollapsibleOpen: seasonFullYear === currentYear,
            lounge: [loungeItem],
          })
        }

        return acc
      }, [])
      .sort((a, b) => {
        if (a.year === 'En cours') {
          return -1 // Place 'En cours' at the top
        } else if (b.year === 'En cours') {
          return 1
        } else {
          return parseInt(b.year, 10) - parseInt(a.year, 10) // Sort by year
        }
      })

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...layoutPropsExponent,
        bottomActions: {
          ...layoutPropsExponent.bottomActions,
          back: undefined,
          next: undefined,
        },
        tabs: tabs,
      },
      pending: collectionByExhibitors.pending,
      title: 'Historique des salons',
      loungeList: sortedData || [],
    }),
    [collectionByExhibitors.pending, layoutProps, layoutPropsExponent, sortedData, tabs]
  )
}
