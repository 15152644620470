export enum ActionButtonSize {
  Default = 'Default',
  Small = 'Small',
  Large = 'Large',
}

export enum ActionButtonScheme {
  // black buttons, white with black border on hover
  Primary = 'Primary',
  // primary inverted
  Secondary = 'Secondary',
  // little gray buttons
  Tertiary = 'Tertiary',
  // white unbordered, white with black border on hover
  White = 'White',
  // like secondary but transparent
  Transparent = 'Transparent',
  // no style but the icons
  Link = 'Link',
  // richText button style
  RichText = 'RichText',
}
