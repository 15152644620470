import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import { FormFieldCheckboxGroupProps } from '../../form/fields/FormFieldCheckboxGroup'
import { getContrastColor } from '../../../relay/Forums/getContrastColor'

import * as SC from './styled'

export type LoungeSubForumFormValues = {
  [forumName: string]: string[]
}

export type LoungeSubForumFormProps = {
  formikForm: FormikFormProps<LoungeSubForumFormValues>
  fields: {
    forums: FormFieldCheckboxGroupProps[]
  }
}

const LoungeSubForumForm: FC<LoungeSubForumFormProps> = ({ formikForm, fields }) => {
  return (
    <SC.StyledFormikForm {...formikForm}>
      {fields.forums.map((forum, key) => (
        <SC.Collapsible
          key={key}
          title={forum.name}
          backgroundColor={getContrastColor(forum.backgroundColor as string)}
          color={forum.backgroundColor}
          itemNbr={forum.options.length.toString()}
          triggerDisabled={forum.options.length === 0}
        >
          <SC.CheckboxGroup options={forum.options} name={forum.name} />
        </SC.Collapsible>
      ))}
    </SC.StyledFormikForm>
  )
}

export default LoungeSubForumForm
