import React, { FC, useEffect } from 'react'
import tinycolor from 'tinycolor2'

import { SelectOption } from '../forms/SeasonSelectForm'
import { getContrastColor } from '../../relay/Forums/getContrastColor'

import * as SC from './styled'

export type ColorPickerProps = {
  className?: string
  selectOptions?: SelectOption[]
  title: string
  background: string
  selectOption: string
  handleBackgroundInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectOptionInputChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleBackgroundChange?: (color: string) => void
}

const ColorPicker: FC<ColorPickerProps> = ({
  className,
  selectOptions,
  title,
  background,
  selectOption,
  handleBackgroundInputChange,
  handleSelectOptionInputChange,
  handleBackgroundChange,
}) => {
  useEffect(() => {
    if (handleBackgroundChange) {
      if (selectOption === 'Rgb') {
        const rgbColor = tinycolor(background).toRgbString()
        handleBackgroundChange(rgbColor)
      }
      if (selectOption === 'Hex') {
        const hexColor = tinycolor(background).toHexString()
        handleBackgroundChange(hexColor)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOption])

  return (
    <SC.ColorPickerContainer className={className}>
      <SC.Title>{title}</SC.Title>
      <SC.PickerContainer>
        <SC.PickerPreview
          $backgroundColor={background}
          $backgroundLightColor={getContrastColor(background)}
          color={background}
          onChangeComplete={(newColor) =>
            handleBackgroundChange && handleBackgroundChange(newColor.hex)
          }
        />
        <SC.Picker
          color={background}
          onChangeComplete={(newColor) =>
            handleBackgroundChange && handleBackgroundChange(newColor.hex)
          }
        />
      </SC.PickerContainer>
      <SC.FieldsContainer>
        {selectOptions && (
          <SC.FieldSelect
            name="colorChange"
            options={selectOptions}
            onChange={handleSelectOptionInputChange}
          />
        )}
        <SC.HexInput onChange={handleBackgroundInputChange} value={background} />
      </SC.FieldsContainer>
    </SC.ColorPickerContainer>
  )
}

export default ColorPicker
