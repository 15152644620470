import React, { FC } from 'react'

import LayoutLoungeConfig, { LayoutLoungeConfigProps } from '../../layouts/LayoutLoungeConfig'
import { FormFieldRadioGroupProps } from '../../components/form/fields/FormFieldRadioGroup'
import FormikField from '../../components/form/FormikField'
import { FormikFormProps } from '../../components/form/FormikForm'
import { CollapsibleItemProps } from '../../components/CollapsibleItem'

import * as SC from './styled'

export type LoungeForumFormValues = {
  noDuplicate: string
  radio: string
}

export type LoungesForumTemplateProps = {
  layoutProps?: LayoutLoungeConfigProps
  title: string
  subTitle?: string
  formikForm: FormikFormProps<LoungeForumFormValues>
  multipleRadiosGroups?: {
    radios: FormFieldRadioGroupProps
    collapsible: CollapsibleItemProps
  }[]
  radioParams?: FormFieldRadioGroupProps
}

const LoungesForumTemplate: FC<LoungesForumTemplateProps> = ({
  layoutProps,
  title,
  multipleRadiosGroups,
  subTitle,
  radioParams,
  formikForm,
}) => {
  return (
    <LayoutLoungeConfig {...layoutProps}>
      <SC.Title tag={'h2'} text={title} />
      <SC.Form {...formikForm}>
        <SC.SubTitle>{subTitle}</SC.SubTitle>
        <FormikField Component={SC.StyledRadiosGroup} {...radioParams} />
        <SC.FormFields>
          {multipleRadiosGroups && multipleRadiosGroups.length > 0 && (
            <SC.Collapsibles>
              {multipleRadiosGroups.map((group, index) => {
                return (
                  <SC.StyledCollapsible
                    key={`multipleRadiosGroups-${index}`}
                    title={group.collapsible.title ? group.collapsible.title : undefined}
                    isCollapsibleOpen={group.collapsible.isCollapsibleOpen}
                    {...(group.collapsible.onClick && { onClick: group.collapsible.onClick })}
                  >
                    <FormikField Component={SC.StyledRadiosGroup} {...group.radios} />
                  </SC.StyledCollapsible>
                )
              })}
            </SC.Collapsibles>
          )}
        </SC.FormFields>
      </SC.Form>
    </LayoutLoungeConfig>
  )
}

export default LoungesForumTemplate
