import styled from 'styled-components'

import ActionButton from '../ActionButton'

export const Container = styled.div<{ $withContent?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.2rem;
  padding-inline: ${({ $withContent }) => ($withContent ? '2rem' : '1.5rem')};
  padding-block: ${({ $withContent }) => ($withContent ? '1.2rem' : '0.85rem')};
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.redLight};
  color: ${({ theme }) => theme.colors.cinnabar};
`

export const Label = styled.p`
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;
  align-items: center;
  & span {
    ${({ theme }) => theme.textStyles.titleH6}
  }
  & svg {
    flex: 0 0 auto;
  }
`
export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textBook}
  color: ${({ theme }) => theme.colors.mineShaft};
`
export const StyledActionButton = styled(ActionButton)`
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
`
