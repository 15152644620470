import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import BoxesListTemplate from '../../templates/BoxesList'
import { useBoxesListTemplateProps } from '../../templates/BoxesList/useProps'
import { actions, selectors } from '../../redux'
import ModalToast from '../../components/ModalToast'
import { Icons } from '../../components/Icon'

const ParamsBoxes: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const boxes = useSelector(selectors.boxes.forumBoxes)
  const boxesCreate = useSelector(selectors.boxes.createBoxes)
  const config = useSelector(selectors.configuration.configurationInfo)

  const [search, setSearch] = useState<string>('')
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (boxesCreate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.boxes.resetCreateBoxes())
      }, 2000)
    }
  }, [dispatch, boxesCreate.success])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  useEffect(() => {
    config &&
      dispatch(
        actions.boxes.getForumBoxesRequest({
          exhibitionApiId: config.exhibitionActiveApiId.toString(),
          q: search,
        })
      )
  }, [config, config?.exhibitionActiveApiId, dispatch, search])

  const templateProps = useBoxesListTemplateProps(search, handleSearchChange, boxes)

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text="La nouvelle caisse de rangement a bien été enregistrée."
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}
      <BoxesListTemplate {...templateProps} />
    </>
  )
}

export default ParamsBoxes
