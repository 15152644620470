import React, { FC } from 'react'

import { LinkProps } from '../Link'
import { getUserRoleDisplayName } from '../../relay/Params/getUserRoleDisplayName'

import * as SC from './styled'

export type UserCardProps = {
  className?: string
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  role?: string
  link?: LinkProps
  onClick?: () => void
}

const UserCard: FC<UserCardProps> = ({
  className,
  id,
  firstName,
  lastName,
  email,
  role,
  link,
  onClick,
}) => {
  const content = () => {
    return (
      <span onClick={onClick}>
        {(firstName || lastName) && (
          <SC.Name>
            {lastName} {firstName}
          </SC.Name>
        )}
        {email && <SC.Email>{email}</SC.Email>}
        {role && <SC.Role>{getUserRoleDisplayName(role)}</SC.Role>}
      </span>
    )
  }
  return link ? (
    <SC.CardLink className={className} {...link} id={id}>
      {content()}
    </SC.CardLink>
  ) : (
    <SC.Card className={className} id={id}>
      {content()}
    </SC.Card>
  )
}

export default UserCard
