import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const mailTemplateUpsert = getMutationService({
  mutation: gql(`
    mutation mailTemplateUpsert($input: MailTemplateUpsertInput!) {
      mailTemplateUpsert(input: $input) {
        id
        type
        subjectFr
        bodyFr
        subjectEn
        bodyEn
        subjectIt
        bodyIt
        createdAt
        updatedAt
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
