import React, { FC, useState } from 'react'

import { FormFieldInputProps } from '../FormFieldInput'

import * as SC from './styled'

export type FormFieldInputPasswordProps = FormFieldInputProps

const FormFieldInputPassword: FC<FormFieldInputPasswordProps> = ({ ...extraProps }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  return (
    <SC.Input
      {...extraProps}
      type={isVisible ? 'text' : 'password'}
      inputProps={extraProps}
      rightAdornment={
        <SC.Toggler onClick={() => setIsVisible(!isVisible)} type="button">
          {isVisible ? <SC.EyeOpen /> : <SC.EyeClose />}
        </SC.Toggler>
      }
    />
  )
}

export default FormFieldInputPassword
