import React from 'react'
import { RouteProps } from 'react-router-dom'

import SampleSheetShootingTemplate from '../../../templates/SampleSheetShooting'
import { useSampleSheetShootingTemplateProps } from '../../../templates/SampleSheetShooting/useProps'

const SampleSheetShooting: React.FC<RouteProps> = () => {
  const templateProps = useSampleSheetShootingTemplateProps()

  return (
    <>
      <SampleSheetShootingTemplate {...templateProps} />
    </>
  )
}

export default SampleSheetShooting
