import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exportRedPoints = getQueryService({
  query: gql(`
    query exportRedPoints{
      exportRedPoints
    }
`),
  transformer: (response) => {
    return response
  },
})
