import React, { FC, useCallback, useRef } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'

import * as SC from './styled'

export type ImportExhibitorTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title: string
  text: string
  filename: string | null
  toastModal?: ModalToastProps
  onFileSelect?: (file: File) => void
}

const ImportExhibitorTemplate: FC<ImportExhibitorTemplateProps> = ({
  layoutProps,
  title,
  text,
  toastModal,
  filename,
  onFileSelect,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files || files.length === 0) return
    const file = files[0]
    onFileSelect?.(file)
  }

  const handleButtonClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={<>{toastModal && <ModalToast {...toastModal} />}</>}
    >
      <ContentWrapper maxWidth={'default'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Text dangerouslySetInnerHTML={{ __html: text }} />
        {filename && <SC.TextFile dangerouslySetInnerHTML={{ __html: filename }} />}
        <SC.FormInput>
          <input
            ref={inputRef}
            type="file"
            onChange={onChange}
            style={{ display: 'none' }}
            accept=".csv"
          />
        </SC.FormInput>
        <SC.UploadButton
          variantSize={ActionButtonSize.Large}
          variantScheme={ActionButtonScheme.Primary}
          rightIconProps={{
            icon: Icons.download,
            width: 16,
            height: 16,
          }}
          text="Upload csv"
          // {...camera.uploadButton}

          onClick={handleButtonClick}
        />
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default ImportExhibitorTemplate
