import styled from 'styled-components'

import Heading from '../../components/Heading'
import SearchBar from '../../components/SearchBar'
import FiltersInline from '../../components/FiltersInline'
import UserCard from '../../components/UserCard'
import AlphabeticSort from '../../components/AlphabeticSort'
import { breakpoints } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'

export const Wrapper = styled(ContentWrapper)`
  padding-block: 2.4rem;
  ${breakpoints.up('large')} {
    padding-block: 4.8rem 2.4rem;
  }
`
export const Title = styled(Heading)`
  margin: 0;
`
export const Search = styled(SearchBar)`
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
`
export const Filters = styled(FiltersInline)`
  &:not(:first-child) {
    margin-top: 0.8rem;
  }
`
export const Content = styled.div`
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('large')} {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;
  }
`
export const Users = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  ${breakpoints.up('large')} {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const User = styled(UserCard)``
export const Empty = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  text-align: center;
  grid-column: 1 / -1;
`
export const StyledAlphabeticSort = styled(AlphabeticSort)`
  display: none;
  ${breakpoints.up('large')} {
    position: sticky;
    top: 1.2rem;
    display: block;
    margin-left: auto;
  }
`
