import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import BoxesAddTemplate, { BoxesAddFormValues } from '../../templates/BoxesAdd'
import { Icons } from '../../components/Icon'
import { layoutWithBottomActionsMocks } from '../../layouts/LayoutWithBottomActions/mocks'
import { actions, selectors } from '../../redux'

const ParamsAddBoxes: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isValid, setIsValid] = useState<boolean>(false)
  const [newBoxe, setNewBoxe] = useState<BoxesAddFormValues | undefined>(undefined)

  const configuration = useSelector(selectors.configuration.configurationInfo)
  const boxesCreate = useSelector(selectors.boxes.createBoxes)

  useEffect(() => {
    if (boxesCreate.success) navigate('/parametres/caisses-de-rangement')
  }, [boxesCreate.success, dispatch, navigate])

  return (
    <BoxesAddTemplate
      layoutProps={{
        ...layoutWithBottomActionsMocks.basic,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            href: '/parametres/caisses-de-rangement',
            rounded: true,
          },
          next: {
            text: 'Valider',
            disabled: !isValid,
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
            onClick: () => {
              if (newBoxe && configuration) {
                dispatch(
                  actions.boxes.createBoxesRequest({
                    exhibitionApiId: configuration?.exhibitionActiveApiId.toString(),
                    input: {
                      name: newBoxe?.name,
                    },
                  })
                )
              }
            },
          },
        },
        sidebarNav: {
          menu: {
            links: [
              {
                text: 'Gestion colis',
              },
              {
                text: 'Séléction échantillons',
              },
              {
                text: 'Scan échantillon',
              },
              {
                text: 'Exposants',
              },
              {
                text: 'Salons',
              },
              {
                text: 'Paramètres',
                selected: true,
              },
              {
                text: 'Mon compte',
              },
            ],
          },
        },
      }}
      title="Caisses de rangement"
      subtitle="Nouvelle caisse de rangement"
      forum={{
        name: 'Aucun forum associé',
      }}
      form={{
        formikForm: {
          initialValues: {
            name: '',
          } as BoxesAddFormValues,
          validationSchema: Yup.object().shape({
            name: Yup.string().required(),
          }),
          onContextUpdate: (context) => {
            setNewBoxe(context.values)
            setIsValid(context.isValid)
          },
          onSubmit: () => undefined,
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          name: {
            name: 'name',
            label: 'Nom de la caisse',
            required: true,
          },
        },
      }}
    />
  )
}

export default ParamsAddBoxes
