import * as Yup from 'yup'

import { ForgottenPasswordFormProps } from './index'

const basic: ForgottenPasswordFormProps = {
  formikForm: {
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required().email(),
    }),
    onSubmit: () => undefined,
    validateOnChange: true,
    validateOnMount: true,
  },
  fields: {
    email: {
      name: 'email',
      label: 'E-mail',
      required: true,
    },
  },
  submitButton: {
    text: 'Réinitialiser mon mot de passe',
  },
}

const basicDisabled = {
  ...basic,
  submitButton: {
    text: 'Réinitialiser mon mot de passe',
    disabled: true,
  },
}

export const forgottenPasswordFormMocks = {
  basicDisabled,
  basic,
}
