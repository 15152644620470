import styled from 'styled-components'

import Modal from '../Modal'
import Html from '../Html'
import Icon from '../Icon'

export const Container = styled(Modal)``

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 2.4rem;
  gap: 2.4rem;
  &::after {
    content: '';
    position: absolute;
    bottom: -0.2rem;
    left: 50%;
    display: block;
    width: 100%;
    max-width: 5rem;
    height: 0.2rem;
    margin-top: 2.4rem;
    margin-inline: auto;
    border-radius: 0.2rem;
    background: ${({ theme }) => theme.colors.grayLight};
  }
`
export const StyledIcon = styled(Icon)`
  flex: 0 0 auto;
`

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textBold}
`
