import { useEffect } from 'react'

function useGetScrollbarWidth() {
  const applyScrollbarWidth = () => {
    if (!window || !document) return

    document.documentElement.style.setProperty(
      '--scrollbar-width',
      window.innerWidth - document.documentElement.clientWidth + 'px'
    )
  }

  useEffect(() => {
    window.addEventListener('resize', applyScrollbarWidth, false)
    // recalculate on dom load
    document.addEventListener('DOMContentLoaded', applyScrollbarWidth, false)
    // recalculate on load (assets loaded as well)
    window.addEventListener('load', applyScrollbarWidth)
    return () => {
      window.removeEventListener('resize', applyScrollbarWidth)
      window.removeEventListener('DOMContentLoaded', applyScrollbarWidth)
      window.removeEventListener('load', applyScrollbarWidth)
    }
  }, [])
}

export default useGetScrollbarWidth
