import React, { FC } from 'react'

import Dot from '../Dot'

import * as SC from './styled'

export type SimpleRadioProps = {
  className?: string
  label?: string
  secondLabel?: string
  radioName?: string
  verticalSecondText?: boolean
  onSelect?: () => void
  variant?:
    | 'default'
    | 'validate'
    | 'delete'
    | 'leather'
    | 'sourcing'
    | 'accessories'
    | 'inspiration'
    | 'design'
    | 'bursted'
    | 'forumSelect'
}

const SimpleRadio: FC<SimpleRadioProps> = ({
  className,
  label,
  secondLabel,
  radioName,
  variant,
  verticalSecondText,
  onSelect,
  ...rest
}) => {
  return (
    <SC.RadioContainer className={className} onClick={onSelect}>
      <SC.TextContainer className="text-content" $verticalSecondText={verticalSecondText}>
        {label}
        <SC.SecondLabel $verticalSecondText={verticalSecondText}>
          {secondLabel && !verticalSecondText && <Dot variant={variant} />}
          {secondLabel}
        </SC.SecondLabel>
      </SC.TextContainer>
      {radioName && <input type="radio" {...rest} name={radioName} />}
    </SC.RadioContainer>
  )
}

export default SimpleRadio
