import React, { useEffect, useState } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'

import ExponentSheetCollectionTemplate from '../../../templates/ExponentSheetCollection'
import { actions, selectors } from '../../../redux'
import { useExponentSheetCollectionTemplateProps } from '../../../templates/ExponentSheetCollection/useProps'
import ModalToast from '../../../components/ModalToast'
import { Icons } from '../../../components/Icon'

const ExponentCollection: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)
  const collection = useSelector(selectors.exhibitors.collection)
  const sampleUpdate = useSelector(selectors.samples.sampleUpdate)

  const collectionUpdate = useSelector(selectors.exhibitors.collectionUpdate)
  const sampleCreate = useSelector(selectors.samples.sampleCreate)
  const { exposantId } = useParams<{ exposantId: string }>()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (collectionUpdate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitors.resetUpdateCollection())
      }, 2000)
    }
    if (sampleCreate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.samples.resetSampleCreate())
      }, 2000)
    }
    if (sampleUpdate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.samples.resetSampleUpdate())
      }, 2000)
    }
  }, [
    collectionUpdate.success,
    dispatch,
    exhibitor,
    exposantId,
    sampleCreate.success,
    sampleUpdate.success,
  ])

  useEffect(() => {
    if (collection) {
      dispatch(
        actions.samples.getSampleFormByUniverseRequest({
          collectionId: collection?.id,
          universeEbmsId: exhibitor?.universe.ebmsId,
        })
      )
    }
  }, [collection, dispatch, exhibitor, exhibitor?.universe.ebmsId])

  const templateProps = useExponentSheetCollectionTemplateProps(
    exhibitor,
    collectionUpdate,
    collection,
    sampleCreate
  )

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text={
            collectionUpdate.success
              ? i18next.t('exponent.collection.collectionUpdate.success', {
                  exhibitorNname: exhibitor?.name,
                })
              : sampleCreate.success
              ? i18next.t('exponent.collection.sampleCreate.success')
              : sampleUpdate.success
              ? i18next.t('exponent.collection.sampleUpdate.success')
              : ''
          }
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}{' '}
      <ExponentSheetCollectionTemplate {...templateProps} />
    </>
  )
}

export default ExponentCollection
