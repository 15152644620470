import React, { FC } from 'react'

import { FilterTagProps } from '../FilterTag'
import { FiltersFormProps } from '../forms/FiltersForm'
import { ModalFullScreenProps } from '../ModalFullScreen'
import { FilterButtonProps } from '../FilterButton'

import * as SC from './styled'

export type FiltersProps = ModalFullScreenProps & {
  className?: string
  tags: Array<FilterTagProps>
  form: FiltersFormProps
  backButtonProps: FilterButtonProps
  title?: string
}

const Filters: FC<FiltersProps> = ({ className, backButtonProps, tags, form, ...modal }) => {
  return (
    <SC.Container className={className} {...modal}>
      <SC.Content>
        <SC.BackButton {...backButtonProps} />
        {tags.length > 0 && (
          <SC.Tags>
            {tags.map((tag, idx) => (
              <SC.Tag key={`filter-tag-${idx}`} {...tag} />
            ))}
          </SC.Tags>
        )}
        <SC.Form {...form} />
      </SC.Content>
    </SC.Container>
  )
}

export default Filters
