import React, { useEffect, useState } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import LoungesTemplate from '../../../templates/Lounges'
import { useLoungesTemplateProps } from '../../../templates/Lounges/useProps'
import { actions, selectors } from '../../../redux'
import ModalToast from '../../../components/ModalToast'
import { Icons } from '../../../components/Icon'

const Lounges: React.FC<RouteProps> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const templateProps = useLoungesTemplateProps()
  const createExhibition = useSelector(selectors.exhibitions.createExhibition)
  const updateExhibition = useSelector(selectors.exhibitions.updateExhibition)

  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (createExhibition && createExhibition.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitions.resetCreateExhibition())
      }, 2000)
    }
    if (updateExhibition && updateExhibition.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitions.resetUpdateExhibition())
      }, 2000)
    }
  }, [
    createExhibition,
    createExhibition?.success,
    dispatch,
    navigate,
    updateExhibition,
    updateExhibition?.success,
  ])

  useEffect(() => {
    if (!createExhibition.success && !updateExhibition.success) {
      dispatch(actions.exhibitions.resetExhibitionConfig())
    }
  }, [createExhibition.success, dispatch, updateExhibition.success])

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text={`Le salon ${loungeConfig?.name} a bien été enregistré.`}
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}{' '}
      <LoungesTemplate {...templateProps} />
    </>
  )
}

export default Lounges
