import React, { FC } from 'react'

import LayoutExponentSheet, { LayoutExponentSheetProps } from '../../layouts/LayoutExponentSheet'
import { InfosDisplayProps } from '../../components/InfosDisplay'

import * as SC from './styled'

export type ExponentSheetInfosTemplateProps = {
  layoutProps?: LayoutExponentSheetProps
  pending?: boolean
  infoLounge?: {
    title: string
    items: InfosDisplayProps
  }
  infoMode?: {
    title: string
    items: InfosDisplayProps
  }
  infoMarketplace?: {
    title: string
    items: InfosDisplayProps
  }
  text?: string
}

const ExponentSheetInfosTemplate: FC<ExponentSheetInfosTemplateProps> = ({
  layoutProps,
  pending,
  infoLounge,
  infoMode,
  infoMarketplace,
  text,
}) => {
  return (
    <LayoutExponentSheet {...layoutProps}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          {infoLounge && (
            <SC.Section>
              <SC.Title tag="h2" text={infoLounge.title} />
              <SC.Infos {...infoLounge.items} />
            </SC.Section>
          )}
          {infoMode && (
            <SC.Section>
              <SC.Title tag="h2" text={infoMode.title} />
              <SC.Infos {...infoMode.items} />
            </SC.Section>
          )}
          {infoMarketplace && (
            <SC.Section>
              <SC.Title tag="h2" text={infoMarketplace.title} />
              <SC.Infos {...infoMarketplace.items} />
            </SC.Section>
          )}
          {text && <SC.Text>{text}</SC.Text>}
        </>
      )}
    </LayoutExponentSheet>
  )
}

export default ExponentSheetInfosTemplate
