import React, { FC, useEffect } from 'react'

import { useNoOverflow } from '../../hooks/useNoOverflow'
import useLastFocus from '../../hooks/useLastFocus'

import * as SC from './styled'
import { ModalVariant } from './types'

export type ModalProps = {
  className?: string
  styleContent?: React.CSSProperties
  isOpen?: boolean
  onClose?: () => void
  children?: React.ReactNode
  variant?: ModalVariant
  withRightSidebar?: boolean
}

const Modal: FC<ModalProps> = ({
  className,
  styleContent,
  isOpen = false,
  onClose,
  children,
  variant,
  withRightSidebar = true,
}) => {
  useNoOverflow(isOpen)
  useLastFocus(isOpen)
  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClose?.()
      }
    }
    if (isOpen) window.addEventListener('keyup', handleKey)

    return () => {
      window.removeEventListener('keyup', handleKey)
    }
  }, [isOpen, onClose])

  return (
    <SC.Container
      className={className}
      $isOpen={isOpen}
      aria-hidden={!isOpen}
      $variant={variant}
      $withRightSidebar={withRightSidebar}
    >
      <SC.Content style={styleContent} $variant={variant}>
        {children}
      </SC.Content>
      <SC.Background onClick={onClose} />
    </SC.Container>
  )
}

export default Modal
