import styled from 'styled-components'

import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import SampleCard from '../../components/SampleCard'
import ParcelCard from '../../components/ParcelCard'
import Warning from '../../components/Warning'
import NumberedTag from '../../components/NumberedTag'
import SampleChart from '../../components/SampleChart'
import SampleSelectForm from '../../components/forms/SampleSelectForm'
import SimpleLoader from '../../components/SimpleLoader'

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`

export const Loader = styled(SimpleLoader)`
  margin: auto;
`

export const Section = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  margin: 0;
`

export const Title = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
  ${breakpoints.up('tablet')} {
  }
`

export const StyledSampleSelectForm = styled(SampleSelectForm)`
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }
`

export const Chart = styled(SampleChart)``

export const Samples = styled.div`
  display: flex;
  flex-flow: column;
  gap: 2.4rem;
  align-items: stretch;
  padding-block: 1.2rem;
  ${breakpoints.up('tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: unset;
  }
`

export const Sample = styled(SampleCard)``

export const Parcels = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1.2rem;
  align-items: stretch;
  ${breakpoints.up('tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: unset;
  }
`

export const Parcel = styled(ParcelCard)`
  ${breakpoints.up('tablet')} {
    &:only-child {
      grid-column: 1 / span 2;
    }
  }
`

export const StyledWarning = styled(Warning)``

export const StyledNumberedTag = styled(NumberedTag)``
