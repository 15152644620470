import styled, { css } from 'styled-components'

import Link from '../Link'
import { breakpoints } from '../../theme'

const stylesCards = css`
  display: flex;
  flex-flow: column;
  gap: 0.4rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.tundora};
  background-color: ${({ theme }) => theme.colors.concrete};
  text-decoration: none;
  ${breakpoints.up('laptop')} {
    padding: 2.4rem;
  }
`
const stylesOverflow = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CardLink = styled(Link)`
  ${stylesCards}
  transition: all 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.mineShaft};
    background: ${({ theme }) => theme.colors.grayLight};
  }
`
export const Card = styled.div`
  ${stylesCards}
`

export const Name = styled.h3`
  ${stylesOverflow}
  ${({ theme }) => theme.textStyles.titleH4};
`

export const Email = styled.p`
  ${stylesOverflow}
  ${({ theme }) => theme.textStyles.titleH5};
`

export const Role = styled.p`
  ${stylesOverflow}
  ${({ theme }) => theme.textStyles.titleH6};
  &:not(:first-child) {
    margin-top: 0.8rem;
  }
`
