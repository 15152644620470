import React from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleSheetsEditTemplate from '../../../templates/SampleSheetsEdit'
import { useSampleSheetEditTemplateProps } from '../../../templates/SampleSheetsEdit/useProps'
import { selectors } from '../../../redux'

const SampleSheetEditUnitValue: React.FC<RouteProps> = () => {
  const { routeType } = useParams<{ routeType: string }>()

  const form = useSelector(selectors.samples.flagGroupsByUniverse)
  const templateProps = useSampleSheetEditTemplateProps(
    routeType,
    'Unit value',
    form ? form.find((item) => item.miraklRef === 'yarn_unit_value') : undefined
  )
  return <SampleSheetsEditTemplate {...templateProps} />
}

export default SampleSheetEditUnitValue
