import React from 'react'
import { RouteProps, useParams } from 'react-router-dom'

import ManageEmailsTemplate from '../../templates/ManageEmails'
import { useManageEmailsTemplateProps } from '../../templates/ManageEmails/useProps'

const ManageEmails: React.FC<RouteProps> = () => {
  const { emailType } = useParams<{ emailType: string }>()

  const templateProps = useManageEmailsTemplateProps(
    emailType === 'marketplace' ? 'Mise en ligne Marketplace' : 'Réception colis'
  )

  return (
    <>
      <ManageEmailsTemplate {...templateProps} />
    </>
  )
}

export default ManageEmails
