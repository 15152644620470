import React, { FC } from 'react'

import * as SC from './styled'

export type SampleHistoryProps = {
  className?: string
  history?: {
    title: string
    text: string
    user: string
    date: string
    variant?: 'default' | 'selected' | 'validated' | 'deleted'
  }[]
}

const SampleHistory: FC<SampleHistoryProps> = ({ className, history }) => {
  return (
    <SC.Container className={className}>
      <SC.HistoryContainer>
        {history &&
          history.map((item, key) => (
            <SC.History key={key}>
              <SC.StyledDot variant="forumSelect" />
              <SC.Test />
              <SC.HistoryHeader>
                <SC.Title $variant={item.variant}>{item.title}</SC.Title>
                <SC.Date>{item.date}</SC.Date>
              </SC.HistoryHeader>
              <SC.HistoryBottom>
                <SC.Text dangerouslySetInnerHTML={{ __html: item.text }} />
                <SC.User>{item.user}</SC.User>
              </SC.HistoryBottom>
            </SC.History>
          ))}
      </SC.HistoryContainer>
    </SC.Container>
  )
}

export default SampleHistory
