export enum VariantRadioSize {
  default = 'default',
  big = 'big',
}

export enum VariantRadioFont {
  Default = 'Default',
  SmallBold = 'SmallBold',
  Bold = 'Bold',
  BoldCaps = 'BoldCaps',
}
