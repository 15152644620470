import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const authSendForgotPasswordNotification = getMutationService({
  mutation: gql(`
	mutation authSendForgotPasswordNotification($email: String!) {
		authSendForgotPasswordNotification(email: $email)
	}
	  `),
  transformer: (response) => {
    return response
  },
})
