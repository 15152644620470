import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { QrScannerProps } from '../../components/QrScanner'
import { SampleCardProps } from '../../components/SampleCard'
import { BoxInfoProps } from '../../components/BoxInfo'
import ModalUpload, { ModalUploadProps } from '../../components/ModalUpload'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import EditoCard, { EditoCardProps } from '../../components/EditoCard'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'

import * as SC from './styled'

export type ReasonProps = {
  text: string
}

export type MultipleScanTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  subTitle?: string
  text?: string
  forumSub?: string
  subForumSub?: string
  scannerProps?: QrScannerProps
  cardList?: SampleCardProps[]
  boxInfo?: BoxInfoProps
  pending?: boolean
  editos?: EditoCardProps[]
  uploadModal?: ModalUploadProps
  deleteModal?: ModalDeleteProps
  toastModal?: ModalToastProps
}

const MultipleScanTemplate: FC<MultipleScanTemplateProps> = ({
  layoutProps,
  scannerProps,
  cardList,
  subTitle,
  text,
  boxInfo,
  pending,
  editos,
  uploadModal,
  deleteModal,
  toastModal,
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={
        <>
          {toastModal && <ModalToast {...toastModal} />}
          {uploadModal && <ModalUpload {...uploadModal} />}
          {deleteModal && <ModalDelete {...deleteModal} />}
        </>
      }
    >
      <SC.TemplateContainer>
        <SC.Scanner {...scannerProps} />
        <SC.Content>
          {pending && <SC.Loader />}
          {cardList && cardList.length > 0 ? (
            <SC.CardContainer>
              {Object.keys(cardList).map((key, idx) => (
                <SC.Card key={key} {...cardList[idx]} />
              ))}
            </SC.CardContainer>
          ) : (
            <SC.CardContainer>
              <SC.Text>Commencez à scanner les QR codes</SC.Text>
            </SC.CardContainer>
          )}
          <SC.SubTitle>{subTitle} </SC.SubTitle>
          <SC.Text>{text}</SC.Text>
          {boxInfo && <SC.StyledBoxInfo {...boxInfo} />}
          {editos && editos.map((edito, key) => <EditoCard key={key} {...edito} />)}
        </SC.Content>
      </SC.TemplateContainer>
    </LayoutWithBottomActions>
  )
}

export default MultipleScanTemplate
