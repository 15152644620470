import styled from 'styled-components'

import { breakpoints } from '../../theme'

export type MaxWidth = 'default' | 'small'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${breakpoints.up('tablet')} {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
  }
`
