import { ModalDeleteProps } from './index'

const basic: ModalDeleteProps = {
  text: 'Êtes-vous sûr de vouloir supprimer cet échantillon de votre sélection ?',
  cancelButton: {
    text: 'Annuler',
  },
  deleteButton: {
    text: 'Oui, supprimer',
  },
  isOpen: true,
}

const user = {
  ...basic,
  text: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
}

const parcel = {
  ...basic,
  text:
    '<p>Attention, un e-mail a été envoyé à l’exposant lui confirmant la bonne réception de son colis.</p>  <p><strong>Êtes-vous sûr de vouloir supprimer ce colis&nbsp;?</strong></p>',
}

export const modalDeleteMocks = {
  basic,
  user,
  parcel,
}
