import { ImgHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutExhibitionSheetTemplateProps } from '../../layouts/LayoutExhibitionSheet/useProps'
import { useExhibitionSheetTabsProps } from '../../relay/ExhibitionSheet/UseExponentSheetTabsProps'
import { Editorial, Exhibition, UserRole } from '../../graphql/generated/graphql'
import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { EditoCardProps } from '../../components/EditoCard'
import { editos } from '../../graphql/services'

import { EditoEditFormValues, ExhibitionSheetEditoTemplateProps } from '.'

export const useExhibitionSheetEditoTemplateProps = (
  exhibition: Exhibition | null
): ExhibitionSheetEditoTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const exhibitionLayoutProps = useLayoutExhibitionSheetTemplateProps(exhibition)
  const layoutProps = useDefaultLayoutTemplateProps('lounges')
  const [editMode, setEditMode] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isPending, setIsPending] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<Editorial[]>(
    exhibition ? (exhibition.editorials as Editorial[]) : []
  )
  const [parsedEditos, setParsedEditos] = useState<EditoCardProps[] | undefined>(undefined)
  const editoCreate = useSelector(selectors.editos.editorialCreate)
  const editoDelete = useSelector(selectors.editos.editorialDelete)
  const user = useSelector(selectors.auth.user)
  const tabs = useExhibitionSheetTabsProps(exhibitionId, 'edito', user?.role)

  const ref = useRef<FormikProps<EditoEditFormValues>>(null)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  useEffect(() => {
    if (editoCreate && editoCreate.success) {
      setEditMode(false)
      setTimeout(() => {
        dispatch(actions.editos.editorialCreateReset())
        setIsPending(false)
      }, 2000)
    }
    if (editoDelete && editoDelete.success) {
      setTimeout(() => {
        dispatch(actions.editos.resetEditorialDelete())
      }, 2000)
    }
  }, [dispatch, editoCreate, editoDelete])

  useEffect(() => {
    if (exhibition && exhibition.editorials) {
      let filteredItems = exhibition.editorials
      if (search !== '') {
        filteredItems = filteredItems.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      }
      setFilteredItems(filteredItems)
    }
  }, [exhibition, search])

  useEffect(() => {
    if (editos && editos) {
      const parseEditosCards = () => {
        const newCards: EditoCardProps[] = []
        if (exhibition && exhibition.editorials && filteredItems) {
          filteredItems.forEach((edito: Editorial) => {
            const photos: ImgHTMLAttributes<HTMLImageElement>[] = []
            edito &&
              edito.samples &&
              edito.samples.forEach((sample) => {
                if (sample.photos) {
                  sample.photos.forEach((photo) => {
                    photos.push({ src: photo.url, alt: photo.id })
                  })
                }
              })
            const card: EditoCardProps = {
              title: edito.name,
              text: edito.description,
              images: photos,
              itemNbr: edito.samples.length.toString(),
              itemTitle: 'Echantillon' + (edito.samples.length <= 1 ? '' : 's'),
              onClick:
                user?.role === UserRole.SuperAdmin ||
                user?.role === UserRole.Admin ||
                user?.role === UserRole.Marketplace ||
                user?.role === UserRole.Rex ||
                user?.role === UserRole.ModePv
                  ? () => {
                      const link = router(routesPath.editoSheet, {
                        editoId: edito.id,
                        apiId: exhibitionId,
                      })
                      navigate(link)
                    }
                  : undefined,
            }

            newCards.push(card)
          })

          newCards.sort((a, b) => {
            if (a.title && b.title) {
              return a.title.localeCompare(b.title)
            }
            return 0
          })

          return newCards
        } else {
          return []
        }
      }
      setParsedEditos(parseEditosCards)
    }
  }, [exhibition, exhibitionId, filteredItems, navigate, user?.role])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...exhibitionLayoutProps,
        bottomActions: {
          next:
            (user?.role === UserRole.SuperAdmin ||
              user?.role === UserRole.Admin ||
              user?.role === UserRole.ModePv) &&
            !editMode
              ? {
                  text: 'Créer un édito',
                  rightIconProps: {
                    icon: Icons.plus,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => setEditMode(true),
                }
              : user?.role === UserRole.SuperAdmin ||
                user?.role === UserRole.Admin ||
                user?.role === UserRole.ModePv
              ? {
                  text: 'Valider',
                  rightIconProps: {
                    icon: Icons.check,
                    width: 18,
                    height: 18,
                  },
                  disabled: !isValid || isPending,
                  isPending: isPending,
                  onClick: () => {
                    setIsPending(true)
                    ref.current?.submitForm()
                  },
                }
              : undefined,
          back: editMode
            ? {
                leftIconProps: {
                  icon: Icons.arrowLeft,
                  width: 18,
                  height: 18,
                },
                onClick: () => setEditMode(false),
              }
            : undefined,
        },
        toastModal:
          editoCreate && editoCreate.success
            ? {
                text: "L'édito a bien été créé.",
                isOpen: true,
                icon: {
                  icon: Icons.check,
                  width: 16,
                  height: 16,
                },
              }
            : editoDelete && editoDelete.success
            ? {
                text: "L'édito a bien été supprimé.",
                isOpen: true,
                icon: {
                  icon: Icons.check,
                  width: 16,
                  height: 16,
                },
              }
            : undefined,
        tabs: !editMode ? tabs : undefined,
      },
      layoutPropsEdito: {
        ...layoutProps,
        bottomActions: {
          next: {
            text: 'Valider',
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
            disabled: !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
          },
        },
      },
      editMode: editMode,
      emptyMessage: "Pas encore d'édito créé pour ce salon",
      editForm: {
        title: 'Nouvel édito',
        formikForm: {
          innerRef: ref,
          initialValues: {
            title: '',
            description: '',
          } as EditoEditFormValues,
          validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            description: Yup.string()
              .required('La description est requise')
              .test('maxCharCount', ' 255 caractères maximum', (value) => {
                if (value) {
                  return value.length <= 255
                }
                return true
              }),
          }),
          onContextUpdate(context) {
            setIsValid(context.isValid)
          },
          onSubmit: (values) => {
            exhibitionId &&
              dispatch(
                actions.editos.editorialCreateRequest({
                  exhibitionApiId: exhibitionId,
                  input: { name: values.title, description: values.description },
                })
              )
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          title: {
            label: 'Titre',
            props: {
              label: undefined,
              name: 'title',
              placeholder: 'Titre',
              required: true,
            },
          },
          description: {
            label: 'Description édito',
            props: {
              label: undefined,
              placeholder: 'Description édito',
              name: 'description',
              required: true,
            },
          },
        },
      },
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      editoList: parsedEditos ? parsedEditos : undefined,
    }),
    [
      dispatch,
      editMode,
      editoCreate,
      editoDelete,
      exhibitionId,
      exhibitionLayoutProps,
      isPending,
      isValid,
      layoutProps,
      parsedEditos,
      search,
      tabs,
      user?.role,
    ]
  )
}
