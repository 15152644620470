import React, { FC } from 'react'

import { ActionButtonProps } from '../../components/ActionButton'
import { DefaultLayoutProps } from '../../layouts/DefaultLayout'
import LayoutWithBottomActions from '../../layouts/LayoutWithBottomActions'

import * as SC from './styled'

export type ReasonProps = {
  text: string
}

export type NoAuthorizedTemplateProps = {
  layoutPtops?: DefaultLayoutProps
  title?: string
  text?: string
  cta?: ActionButtonProps
}

const NoAuthorizedTemplate: FC<NoAuthorizedTemplateProps> = ({ layoutPtops, title, text, cta }) => {
  return (
    <LayoutWithBottomActions {...layoutPtops}>
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <SC.Cta {...cta} />
      </SC.Content>
    </LayoutWithBottomActions>
  )
}

export default NoAuthorizedTemplate
