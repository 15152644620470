import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import i18next from 'i18next'

import { actions, selectors } from '../../redux'
import { Icons } from '../../components/Icon'
import { ParcelCardProps } from '../../components/ParcelCard'
import { Exhibitor, Parcel, UserRole } from '../../graphql/generated/graphql'
import { ParcelEditFormValues } from '../../components/forms/ParcelEditForm'
import { useExponentSheetTabsProps } from '../../relay/ExponentSheet/UseExponentSheetTabsProps'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutExponentSheetTemplateProps } from '../../layouts/LayoutExponentSheet/useProps'

import { ExponentSheetParcelsTemplateProps } from '.'

export const useExponentSheetParcelsTemplateProps = (
  exhibitor: Exhibitor | null,
  setIsModalDeleteVisible: React.Dispatch<React.SetStateAction<boolean>>,
  parcel: Parcel | undefined,
  setParcel: React.Dispatch<React.SetStateAction<Parcel | undefined>>
): ExponentSheetParcelsTemplateProps => {
  const dispatch = useDispatch()
  const { exposantId } = useParams<{ exposantId: string }>()
  const { apiId } = useParams<{ apiId: string }>()
  const collection = useSelector(selectors.exhibitors.collection)
  const user = useSelector(selectors.auth.user)
  const [updateParcel, setUpdateParcel] = useState<ParcelEditFormValues | undefined>(undefined)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [add, setAdd] = useState<boolean>(false)
  const [list, setList] = useState<boolean>(true)
  const [edit, setEdit] = useState<boolean>(false)
  const layoutProps = useDefaultLayoutTemplateProps('parcels')
  const layoutPropsExponent = useLayoutExponentSheetTemplateProps(exhibitor, collection)
  const collectionParcelCreate = useSelector(selectors.exhibitors.collectionParcelCreate)
  const collectionParcelUpdate = useSelector(selectors.exhibitors.collectionParcelUpdate)
  const collectionParcelDelete = useSelector(selectors.exhibitors.collectionParcelDelete)

  const isArchived = collection?.isArchived

  const resetOnReload = () => {
    setEdit(false)
    setList(true)
  }

  const tabs = useExponentSheetTabsProps(exposantId, apiId, 'colis', user?.role, resetOnReload)

  const handleChooseMode = useCallback((selectedMode: 'list' | 'add' | 'edit') => {
    setAdd(selectedMode === 'add')
    setList(selectedMode === 'list')
    setEdit(selectedMode === 'edit')
  }, [])

  useEffect(() => {
    handleChooseMode('list')
  }, [collection, handleChooseMode])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...layoutPropsExponent,
        bottomActions: {
          ...layoutPropsExponent.bottomActions,
          back:
            add || edit
              ? {
                  leftIconProps: {
                    icon: Icons.arrowLeft,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => {
                    handleChooseMode('list')
                  },
                  rounded: true,
                }
              : undefined,
          next:
            user?.role !== UserRole.Shooting &&
            user?.role !== UserRole.Marketplace &&
            user?.role !== UserRole.Rex
              ? add || edit
                ? {
                    text: 'Valider',
                    isPending:
                      collectionParcelCreate.pending ||
                      collectionParcelUpdate.pending ||
                      collectionParcelDelete.pending,
                    onClick: () => {
                      if (collection && add) {
                        dispatch(
                          actions.exhibitors.collectionParcelCreateRequest({
                            collectionId: collection?.id,
                            samplesTotal: 0,
                            samplesDistinct: 0,
                          })
                        )
                      }
                      if (edit && updateParcel && parcel) {
                        dispatch(
                          actions.exhibitors.collectionParcelUpdateRequest({
                            parcelId: parcel?.id,
                            samplesTotal: Number(updateParcel.total),
                            samplesDistinct: Number(updateParcel?.distincts),
                          })
                        )
                      }
                    },
                    rightIconProps: {
                      icon: Icons.check,
                      width: 18,
                      height: 18,
                    },
                    disabled: edit
                      ? isValid
                      : collectionParcelCreate.pending ||
                        collectionParcelUpdate.pending ||
                        collectionParcelDelete.pending,
                  }
                : undefined
              : undefined,
        },
        tabs: tabs,
      },
      pending: !collection,
      parcels: {
        list: list
          ? {
              addButton:
                !isArchived &&
                user?.role !== UserRole.Shooting &&
                user?.role !== UserRole.Marketplace &&
                user?.role !== UserRole.Rex
                  ? {
                      text: i18next.t('exponent.parcels.addParcel'),
                      rightIconProps: {
                        icon: Icons.plus,
                        width: 18,
                        height: 18,
                      },
                      onClick: () => {
                        handleChooseMode('add')
                      },
                    }
                  : undefined,
              empty: i18next.t('exponent.parcels.noParcel'),
              title: collection?.parcels.length === 1 ? 'Colis réceptionné' : 'Colis réceptionnés',
              parcels: collection?.parcels.map((parcel) => ({
                ...parcel,
                edit:
                  !isArchived &&
                  user?.role !== UserRole.Shooting &&
                  user?.role !== UserRole.Marketplace &&
                  user?.role !== UserRole.Rex
                    ? {
                        text: i18next.t('exponent.parcels.editParcel'),
                        onClick: () => {
                          handleChooseMode('edit')
                          setParcel(parcel)
                        },
                      }
                    : undefined,
              })) as ParcelCardProps[] | undefined,
            }
          : undefined,
        add: add
          ? {
              title: i18next.t('exponent.parcels.addParcelBtn'),
              text: i18next.t('exponent.parcels.addParcelText'),
              card: {
                topInfo: {
                  tag: collection?.exhibitor.name,
                  name: collection?.exhibitor.country,
                  universe: collection?.exhibitor.universe.name,
                  pavilion: collection?.exhibitor.pavilion.name,
                },
              },
              received: `Réceptionné par ${user?.lastName} ${user?.firstName}`,
              date: dayjs().format('DD.MM.YYYY'),
            }
          : undefined,
        edit: edit
          ? {
              form: {
                formikForm: {
                  initialValues: {
                    total: parcel && parcel.samplesTotal !== 0 ? parcel.samplesTotal : undefined,
                    distincts:
                      parcel && parcel.samplesTotal !== 0 ? parcel.samplesDistinct : undefined,
                  } as ParcelEditFormValues,
                  validationSchema: Yup.object().shape({
                    total: Yup.number().integer().min(1, 'Au moins 1 échantillon').required(),
                    distincts: Yup.number()
                      .integer()
                      .min(1, 'Au moins 1 échantillon distinct')
                      .required()
                      .max(
                        Yup.ref('total'),
                        "Le nombre doit être égal ou inférieur au nombre d'échantillons total"
                      ),
                  }),
                  onSubmit: (values) => console.log(values),
                  onContextUpdate: (context) => {
                    setUpdateParcel(context.values)
                    setIsValid(!context.isValid)
                  },
                  validateOnChange: true,
                  validateOnMount: true,
                },
                fields: [
                  {
                    title: i18next.t('exponent.parcels.totalParcelsTitle'),
                    text: i18next.t('exponent.parcels.totalParcelsText'),
                    props: {
                      name: 'total',
                      required: true,
                      hasFloatingLabel: false,
                      placeholder: '0',
                    },
                  },
                  {
                    title: i18next.t('exponent.parcels.diffParcelsTitle'),
                    text: i18next.t('exponent.parcels.diffParcelsText'),
                    props: {
                      name: 'distincts',
                      required: true,
                      hasFloatingLabel: false,
                      placeholder: '0',
                    },
                  },
                ],
              },
              deleteButton: {
                text: i18next.t('exponent.parcels.deleteParcel'),
                onClick: () => setIsModalDeleteVisible(true),
                rightIconProps: {
                  icon: Icons.trash,
                  width: 12,
                  height: 12,
                },
              },
            }
          : undefined,
      },
    }),
    [
      add,
      collection,
      collectionParcelCreate.pending,
      collectionParcelDelete.pending,
      collectionParcelUpdate.pending,
      dispatch,
      edit,
      handleChooseMode,
      isArchived,
      isValid,
      layoutProps,
      layoutPropsExponent,
      list,
      parcel,
      setIsModalDeleteVisible,
      setParcel,
      tabs,
      updateParcel,
      user?.firstName,
      user?.lastName,
      user?.role,
    ]
  )
}
