import styled, { css } from 'styled-components'

import SimpleRadio from '../SimpleRadio'
import SimpleCheckBox from '../SimpleCheckBox'
import Icon from '../Icon'

export const Label = styled.span`
  ${({ theme }) => theme.textStyles.textBold}
  display: flex;
  align-items: center;
  min-height: 2.4rem;
`

export const Radio = styled(SimpleRadio)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const CheckBox = styled(SimpleCheckBox)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.1rem;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  font-size: 1.2rem;
`

export const StyledIcon = styled(Icon)``

const defaultSSelected = css`
  border: 1px solid ${(props) => props.theme.colors.selectiveTextYellow};
`

const validateVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.chateauGreen};
`

const deleteVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.cinnabar};
`

const leatherVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.roseOfSharon};
`

const sourcingVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.danube};
`

const accessoriesVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.pizza};
`

const inspirationVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.royalPurple};
`

const designVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.fuchsiaPink};
`

const forumSelectVariantSelected = css`
  border: 1px solid ${(props) => props.theme.colors.tundora};
`

const defaultS = css`
  color: ${(props) => props.theme.colors.selectiveTextYellow};
  background-color: ${(props) => props.theme.colors.selectiveYellow};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.selectiveTextYellow};
  }
`

const validateVariant = css`
  color: ${(props) => props.theme.colors.chateauGreen};
  background-color: ${(props) => props.theme.colors.appleGreen};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.chateauGreen};
  }
`

const deleteVariant = css`
  color: ${(props) => props.theme.colors.cinnabar};
  background-color: ${(props) => props.theme.colors.redLight};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.cinnabar};
  }
`

const leatherVariant = css`
  color: ${(props) => props.theme.colors.roseOfSharon};
  background-color: ${(props) => props.theme.colors.janna};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.roseOfSharon};
  }
`

const sourcingVariant = css`
  color: ${(props) => props.theme.colors.danube};
  background-color: ${(props) => props.theme.colors.linkWater};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.danube};
  }
`

const accessoriesVariant = css`
  color: ${(props) => props.theme.colors.pizza};
  background-color: ${(props) => props.theme.colors.albescentWhite};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.pizza};
  }
`

const inspirationVariant = css`
  color: ${(props) => props.theme.colors.royalPurple};
  background-color: ${(props) => props.theme.colors.whiteLilac};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.royalPurple};
  }
`

const designVariant = css`
  color: ${(props) => props.theme.colors.fuchsiaPink};
  background-color: ${(props) => props.theme.colors.amour};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.fuchsiaPink};
  }
`

const forumSelectVariant = css`
  color: ${(props) => props.theme.colors.tundora};
  background-color: ${(props) => props.theme.colors.concrete};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.tundora};
  }
`

export const StatutSelection = styled(Label)<{
  variant?:
    | 'default'
    | 'validate'
    | 'delete'
    | 'leather'
    | 'sourcing'
    | 'accessories'
    | 'inspiration'
    | 'design'
    | 'bursted'
    | 'forumSelect'
  $selected: boolean | null | undefined
  $checkBoxStyle: boolean
  $backgroundColor: string | undefined
  $color: string | undefined
}>`
  ${({ theme }) => theme.textStyles.textBold}
  padding: 1.3rem 2rem;
  display: inline-flex;
  align-items: center;
  gap: 1.8rem;
  font-size: 1.1rem;
  border-radius: 0.36rem;
  cursor: pointer;
  width: 100%;
  transition: border-color 0.5s, background-color 0.5s, color 0.5s;
  ${(props) => {
    switch (props.variant) {
      case 'default':
        return css`
          ${defaultS}
          ${props.$selected
            ? css`
                ${defaultSSelected}
              `
            : ''}
        `
      case 'validate':
        return css`
          ${validateVariant}
          ${props.$selected
            ? css`
                ${validateVariantSelected}
              `
            : ''}
        `
      case 'delete':
        return css`
          ${deleteVariant}
          ${props.$selected
            ? css`
                ${deleteVariantSelected}
              `
            : ''}
        `
      case 'leather':
        return css`
          ${leatherVariant}
          ${props.$selected
            ? css`
                ${leatherVariantSelected}
              `
            : ''}
        `
      case 'sourcing':
        return css`
          ${sourcingVariant}
          ${props.$selected
            ? css`
                ${sourcingVariantSelected}
              `
            : ''}
        `
      case 'accessories':
        return css`
          ${accessoriesVariant}
          ${props.$selected
            ? css`
                ${accessoriesVariantSelected}
              `
            : ''}
        `
      case 'inspiration':
        return css`
          ${inspirationVariant}
          ${props.$selected
            ? css`
                ${inspirationVariantSelected}
              `
            : ''}
        `
      case 'design':
        return css`
          ${designVariant}
          ${props.$selected
            ? css`
                ${designVariantSelected}
              `
            : ''}
        `
      case 'forumSelect':
        return css`
          ${forumSelectVariant}
          ${props.$selected
            ? css`
                ${forumSelectVariantSelected}
              `
            : ''}
        `
    }
  }}
  ${({ $backgroundColor, $color }) =>
    $backgroundColor &&
    css`
      color: var(--color, ${$color});
      background-color: var(--background-color, ${$backgroundColor});
      border-color: var(--border-color, ${$color});
    `}
  ${({ $checkBoxStyle }) =>
    $checkBoxStyle &&
    css`
      padding: 1.2rem 1.6rem;
    `};
`
