import styled from 'styled-components'

import Heading from '../../components/Heading'
import ActionButton from '../../components/ActionButton'
import ContentWrapper from '../../components/ContentWrapper'
import { breakpoints } from '../../theme'

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.4rem;
  padding-block: 2.4rem;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem;
  }
`

export const Title = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.mineShaft};
`
export const Subtitle = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.4rem;
`

export const StyledActionButton = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`
