import React, { FC, useRef, useState } from 'react'
import { FormikProps } from 'formik'

import { FormikFormProps } from '../../form/FormikForm'
import { FormSubmitButtonProps } from '../../form/FormSubmitButton'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { FormFieldCheckboxGroupProps } from '../../form/fields/FormFieldCheckboxGroup'
import { LinkProps } from '../../Link'
import { ConfigurationDictionary, SampleCountByUniverse } from '../../../graphql/generated/graphql'
import { ActionButtonSize } from '../../ActionButton/types'
import { ActionButtonProps } from '../../ActionButton'
import { OptionRadio } from '../../form/fields/FormFieldRadioGroup/styled'
import { colors } from '../../../theme'

import * as SC from './styled'

export type FiltersFormValues = {
  [key: string]: Array<string>
}

export type UniversesFormValues = {
  universe: string
}

export type FiltersFormProps = {
  className?: string
  formikForm: FormikFormProps<FiltersFormValues>
  formikFormUniverses: FormikFormProps<UniversesFormValues>
  fields: Array<FormFieldCheckboxGroupProps>
  errors?: FormErrorsProps['errors']
  submitButton: FormSubmitButtonProps
  submitUniverseButton?: FormSubmitButtonProps
  resetButton: LinkProps
  cleanUniverseButton?: ActionButtonProps
  universe?: string
  universes?: ConfigurationDictionary
  universeChoosen?: boolean
  samplesCount?: SampleCountByUniverse[]
  exhibitorFilters?: boolean
}

const FiltersForm: FC<FiltersFormProps> = ({
  className,
  formikForm,
  formikFormUniverses,
  fields,
  submitButton,
  resetButton,
  cleanUniverseButton,
  universe,
  universes,
  universeChoosen,
  submitUniverseButton,
  samplesCount,
  exhibitorFilters,
  errors,
}) => {
  const $formik = useRef<FormikProps<FiltersFormValues>>(null)
  const [key, setKey] = useState(0)

  const handleReset = () => {
    $formik.current?.resetForm({ values: formikForm.initialValues })
    setKey((prevKey) => prevKey + 1)
  }

  return (
    <SC.Form className={className} {...formikForm} innerRef={$formik}>
      {!exhibitorFilters && (
        <>
          <SC.Title tag="h3" text="Univers" />
          {!universe ? (
            <SC.SubTitle>
              Veuillez sélectionner un univers pour afficher les filtres correspondants.
            </SC.SubTitle>
          ) : (
            <>
              <SC.Radio>
                <OptionRadio type="radio" name={universe} checked={true} />
                {universe}
              </SC.Radio>
              <SC.SubmitUniverseButton
                {...cleanUniverseButton}
                variantSize={ActionButtonSize.Small}
                type="button"
              />
            </>
          )}
        </>
      )}
      {universeChoosen && !exhibitorFilters ? (
        <>
          <SC.Fields>
            {fields &&
              fields.map((field, idx) => (
                <SC.Collapsible key={idx} title={field.label ?? ''} operatorIcon>
                  <FormikField
                    key={`${idx}-${key}`}
                    Component={SC.FieldCheckboxGroup}
                    display={'default'}
                    {...field}
                  />
                </SC.Collapsible>
              ))}
          </SC.Fields>
          {errors && <SC.Errors errors={errors} />}
          <SC.ButtonContainer>
            <SC.SubmitButton {...submitButton} type="button" />
            <SC.ResetButton {...resetButton} type="button" onClick={handleReset}>
              {resetButton.text}
            </SC.ResetButton>
          </SC.ButtonContainer>
        </>
      ) : (
        !universeChoosen &&
        !exhibitorFilters && (
          <SC.Form className={className} {...formikFormUniverses}>
            <FormikField
              Component={SC.StyledRadiosGroup}
              name="universe"
              optionBefore={true}
              options={universes?.items.map((universe) => ({
                label: universe.value,
                value: universe.key,
                count: samplesCount?.find((item) => item.ebmsId === universe.key)?.count,
                backgroundColor: colors.pureWhite,
              }))}
            />
            <SC.SubmitUniverseButton
              {...submitUniverseButton}
              variantSize={ActionButtonSize.Small}
              type="button"
            />
          </SC.Form>
        )
      )}
      {exhibitorFilters && (
        <>
          <SC.Fields>
            {fields &&
              fields.map((field, idx) => (
                <SC.Collapsible key={idx} title={field.label ?? ''} operatorIcon>
                  <FormikField
                    key={`${idx}-${key}`}
                    Component={SC.FieldCheckboxGroup}
                    display={'default'}
                    {...field}
                  />
                </SC.Collapsible>
              ))}
          </SC.Fields>
          {errors && <SC.Errors errors={errors} />}
          <SC.ButtonContainer>
            <SC.SubmitButton {...submitButton} type="button" />
            <SC.ResetButton {...resetButton} type="button" onClick={handleReset}>
              {resetButton.text}
            </SC.ResetButton>
          </SC.ButtonContainer>
        </>
      )}
    </SC.Form>
  )
}

export default FiltersForm
