import { Icons } from '../Icon'
import { menuDesktopMocks } from '../MenuDesktop/mocks'

import { SidebarNavProps } from './index'

const basic: SidebarNavProps = {
  iconProps: {
    icon: Icons.search,
    width: 20,
  },
  secondIconProps: {
    icon: Icons.arrowRight,
    width: 20,
  },
  search: false,
  menu: menuDesktopMocks.basic,
}

export const sidebarNavMocks = {
  basic,
}
