import React, { FC } from 'react'

import LayoutSampleData, { LayoutSampleDataProps } from '../../layouts/LayoutSampleData'
import { FormikFormProps } from '../../components/form/FormikForm'
import { FormFieldSwitchProps } from '../../components/form/fields/FormFieldSwitch'
import { FormFieldCheckboxGroupProps } from '../../components/form/fields/FormFieldCheckboxGroup'
import { FormFieldRadioGroupProps } from '../../components/form/fields/FormFieldRadioGroup'
import { FormErrorsProps } from '../../components/form/FormErrors'
import FormikField from '../../components/form/FormikField'
import { SearchBarProps } from '../../components/SearchBar'
import { AlphabeticSortProps } from '../../components/AlphabeticSort'
import { BoxInfoProps } from '../../components/BoxInfo'
import { ActionButtonProps } from '../../components/ActionButton'
import { CollapsibleColoredItemProps } from '../../components/CollapsibleColored'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'

import * as SC from './styled'

export type SampleDataFormValues = {
  allCollection?: boolean
  radios?: string
  checkboxes?: string[] | string
}

export type SampleDataTemplateProps = {
  layoutProps?: LayoutSampleDataProps
  title?: string
  search?: SearchBarProps
  isPending: boolean
  alphabetical?: AlphabeticSortProps
  formikForm: FormikFormProps<SampleDataFormValues>
  allCollection: FormFieldSwitchProps
  forumInfo?: BoxInfoProps
  boxInfo?: BoxInfoProps
  editButton?: ActionButtonProps
  checkboxes?: FormFieldCheckboxGroupProps
  radios?: FormFieldRadioGroupProps
  multipleRadiosGroups?: {
    radios: FormFieldRadioGroupProps
    collapsible: CollapsibleColoredItemProps
  }[]
  errors?: FormErrorsProps['errors']
}

const SampleDataTemplate: FC<SampleDataTemplateProps> = ({
  layoutProps,
  title,
  search,
  isPending,
  alphabetical,
  formikForm,
  allCollection,
  forumInfo,
  boxInfo,
  editButton,
  checkboxes,
  radios,
  multipleRadiosGroups,
  errors,
}) => {
  return (
    <LayoutSampleData {...layoutProps}>
      {isPending ? (
        <SC.Loader />
      ) : (
        <SC.Content>
          {title && <SC.Title tag="h2" text={title} />}
          {forumInfo && <SC.StyledBoxInfo {...forumInfo} />}
          {search && <SC.Search {...search} />}
          <SC.Form {...formikForm}>
            {allCollection && (
              <FormikField
                Component={SC.AllCollectionSwitch}
                {...allCollection}
                textValue={allCollection.value ? 'true' : 'false'}
              />
            )}
            {boxInfo && <SC.StyledBoxInfo {...boxInfo} />}
            {editButton && (
              <SC.EditButton
                variantScheme={ActionButtonScheme.Tertiary}
                variantSize={ActionButtonSize.Small}
                rightIconProps={{
                  icon: Icons.pencil,
                  width: 16,
                  height: 16,
                }}
                {...editButton}
              />
            )}
            <SC.FormWrapper>
              <SC.FormFields>
                {radios && <FormikField Component={SC.StyledRadiosGroup} {...radios} />}
                {checkboxes && <FormikField Component={SC.StyledCheckboxesGroup} {...checkboxes} />}
                {multipleRadiosGroups && multipleRadiosGroups.length > 0 && (
                  <SC.Collapsibles>
                    {multipleRadiosGroups.map((group, index) => (
                      <SC.StyledCollapsible
                        key={`multipleRadiosGroups-${index}`}
                        title={group.collapsible.title}
                        variant={group.collapsible.variant}
                        variantFont={group.collapsible.variantFont}
                        backgroundColor={group.collapsible.backgroundColor}
                        color={group.collapsible.color}
                        {...(group.collapsible.onClick && { onClick: group.collapsible.onClick })}
                      >
                        <FormikField Component={SC.StyledRadiosGroup} {...group.radios} />
                      </SC.StyledCollapsible>
                    ))}
                  </SC.Collapsibles>
                )}
              </SC.FormFields>
              {alphabetical && <SC.StyledAlphabeticSort {...alphabetical} />}
            </SC.FormWrapper>
            {errors && <SC.Errors errors={errors} />}
          </SC.Form>
        </SC.Content>
      )}
    </LayoutSampleData>
  )
}

export default SampleDataTemplate
