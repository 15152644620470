import * as Yup from 'yup'

import FormFieldRadioGroup from '../../form/fields/FormFieldRadioGroup'

import { UserFormProps, UserFormValues } from './index'

const basic: UserFormProps = {
  legendIds: 'Identifiants de connexion',
  formikForm: {
    initialValues: {
      lastName: '',
      firstName: '',
      email: '',
      role: '',
    } as UserFormValues,
    validationSchema: Yup.object().shape({
      lastName: Yup.string().required(),
      firstName: Yup.string().required(),
      email: Yup.string().required().email(),
      role: Yup.string().required(),
    }),
    onSubmit: () => undefined,
    validateOnChange: true,
  },
  fields: {
    lastName: {
      name: 'lastName',
      label: 'Nom de famille',
      required: true,
    },
    firstName: {
      name: 'firstName',
      label: 'Prénom',
      required: true,
    },
    email: {
      name: 'email',
      label: 'E-mail',
      required: true,
    },
    role: {
      name: 'role',
      required: true,
      Component: FormFieldRadioGroup,
      label: 'Son rôle utilisateur',
      options: [
        {
          label: 'DSI - Super admin',
          value: 'dsi',
        },
        {
          label: 'Équipe mode PV',
          value: 'team-pv',
        },
        {
          label: 'Équipe marketplace',
          value: 'team-marketplace',
        },
        {
          label: 'REX',
          value: 'rex',
        },
        {
          label: 'Expert mode',
          value: 'expert-mode',
        },
        {
          label: 'Opérateur de saisie',
          value: 'operator',
        },
        {
          label: 'Équipe shooting',
          value: 'team-shooting',
        },
        {
          label: 'Stagiaire',
          value: 'trainee',
        },
      ],
    },
  },
}

const basicValidated: UserFormProps = {
  ...basic,
}

const prefilled: UserFormProps = {
  ...basic,
  formikForm: {
    ...basic.formikForm,
    initialValues: {
      lastName: 'Lorem',
      firstName: 'Ipsum',
      email: 'machin@bidule.com',
      role: 'team-pv',
    },
  },
}

export const userFormMocks = {
  basic,
  basicValidated,
  prefilled,
}
