import React, { FC } from 'react'

import SeasonSelect, { SeasonSelectFormProps } from '../../components/forms/SeasonSelectForm'
import LayoutLoungeConfig, { LayoutLoungeConfigProps } from '../../layouts/LayoutLoungeConfig'

import * as SC from './styled'

export type LoungeConfigTemplateProps = {
  layoutProps?: LayoutLoungeConfigProps
  pageTitle?: string
  title: string
  seasonSelectForm?: SeasonSelectFormProps
}

const LoungeConfigTemplate: FC<LoungeConfigTemplateProps> = ({
  layoutProps,
  title,
  seasonSelectForm,
}) => {
  return (
    <LayoutLoungeConfig {...layoutProps}>
      <SC.Title tag={'h2'} text={title} />
      {seasonSelectForm && <SeasonSelect {...seasonSelectForm} />}
    </LayoutLoungeConfig>
  )
}

export default LoungeConfigTemplate
