import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormSubmitButton from '../../form/FormSubmitButton'
import FormFieldText from '../../form/fields/FormFieldInput'
import FormErrors from '../../form/FormErrors'
import Icon from '../../Icon'
import FormFieldInputPassword from '../../form/fields/FormFieldInputPassword'
import Link from '../../Link'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  margin-bottom: 0.6rem;
`

export const FieldText = styled(FormFieldText)`
  width: 100%;
`

export const FieldPassword = styled(FormFieldInputPassword)`
  width: 100%;
`

export const ForgottenPassword = styled(Link)`
  color: ${({ theme }) => theme.colors.gray};
`

export const EyeIcon = styled(Icon)``

export const Errors = styled(FormErrors)``

export const SubmitButton = styled(FormSubmitButton)`
  align-self: flex-end;
  margin-top: 2.4rem;
`
