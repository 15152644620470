import { routesPath } from '../../router'
import { SampleData } from '../../types/sampleData'

import {
  routesAccessories,
  routesAccessoriesMarketplace,
  routesDesign,
  routesDesignMarketplace,
  routesFabrics,
  routesFabricsMarketplace,
  routesLeather,
  routesLeatherMarketplace,
  routesLeatherSpecial,
  routesLeatherSpecialMarketplace,
  routesManufacturing,
  routesManufacturingLeather,
  routesManufacturingLeatherMarketplace,
  routesManufacturingMarketplace,
  routesTrainee,
  routesTraineeShort,
  routesTraineeShortDesign,
  routesTraineeShortManuf,
  routesYarns,
  routesYarnsMarketplace,
} from './RoutesSampleData'

const findNextIndex = (
  routes: {
    location: string
    href: string
  }[],
  lastSegment: string,
  direction: 'prev' | 'next',
  page: string | undefined
) => {
  const currentIndex = routes.findIndex((route) => route.location === lastSegment)

  let nextIndex
  if (currentIndex !== -1) {
    if (direction === 'prev') {
      if (currentIndex === 0 && (page === 'exponent' || !page)) {
        return routesPath.exponentSheet
      }
      if (currentIndex === 0 && page === 'sample') {
        return routesPath.sampleSheetData
      }
      nextIndex = currentIndex - 1
    } else if (direction === 'next') {
      nextIndex = currentIndex + 1
    }
    if ((nextIndex && nextIndex >= 0 && nextIndex <= routes.length) || nextIndex === 0) {
      const nextLocation = routes[nextIndex].href
      return nextLocation
    }
  }

  return null
}

export const sampleDataRoutes = (
  sampleData: SampleData | undefined,
  direction: 'prev' | 'next'
): string => {
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  const isSaisieDonneesEchantillon = currentURL.includes('saisie-donnees-echantillon')
  if (sampleData && sampleData.routeType === 'Fabrics') {
    if (!isSaisieDonneesEchantillon) {
      return routesFabrics[0].href
    } else {
      return findNextIndex(routesFabrics, lastSegment, direction, sampleData.page)!
    }
  }

  if (sampleData && sampleData.routeType === 'FabricsMarketplace') {
    if (!isSaisieDonneesEchantillon) {
      return routesFabricsMarketplace[0].href
    } else {
      return findNextIndex(routesFabricsMarketplace, lastSegment, direction, sampleData.page)!
    }
  }

  if (sampleData && sampleData.routeType === 'Accessories') {
    if (!isSaisieDonneesEchantillon) return routesAccessories[0].href
    else return findNextIndex(routesAccessories, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'AccessoriesMarketplace') {
    if (!isSaisieDonneesEchantillon) return routesAccessoriesMarketplace[0].href
    else
      return findNextIndex(routesAccessoriesMarketplace, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'Leather') {
    if (!isSaisieDonneesEchantillon) return routesLeather[0].href
    else {
      return findNextIndex(routesLeather, lastSegment, direction, sampleData.page)!
    }
  }

  if (sampleData && sampleData.routeType === 'LeatherMarketplace') {
    if (!isSaisieDonneesEchantillon) return routesLeatherMarketplace[0].href
    else {
      return findNextIndex(routesLeatherMarketplace, lastSegment, direction, sampleData.page)!
    }
  }

  if (sampleData && sampleData.routeType === 'LeatherSpecial') {
    if (!isSaisieDonneesEchantillon) return routesLeatherSpecial[0].href
    else {
      return findNextIndex(routesLeatherSpecial, lastSegment, direction, sampleData.page)!
    }
  }

  if (sampleData && sampleData.routeType === 'LeatherSpecialMarketplace') {
    if (!isSaisieDonneesEchantillon) return routesLeatherSpecialMarketplace[0].href
    else {
      return findNextIndex(
        routesLeatherSpecialMarketplace,
        lastSegment,
        direction,
        sampleData.page
      )!
    }
  }

  if (sampleData && sampleData.routeType === 'Manufacturing') {
    if (!isSaisieDonneesEchantillon) return routesManufacturing[0].href
    else return findNextIndex(routesManufacturing, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'ManufacturingMarketplace') {
    if (!isSaisieDonneesEchantillon) return routesManufacturingMarketplace[0].href
    else
      return findNextIndex(routesManufacturingMarketplace, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'Manufacturing Leather') {
    if (!isSaisieDonneesEchantillon) return routesManufacturingLeather[0].href
    else return findNextIndex(routesManufacturingLeather, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'Manufacturing LeatherMarketplace') {
    if (!isSaisieDonneesEchantillon) return routesManufacturingLeatherMarketplace[0].href
    else
      return findNextIndex(
        routesManufacturingLeatherMarketplace,
        lastSegment,
        direction,
        sampleData.page
      )!
  }

  if (sampleData && sampleData.routeType === 'Yarns') {
    if (!isSaisieDonneesEchantillon) return routesYarns[0].href
    else return findNextIndex(routesYarns, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'YarnsMarketplace') {
    if (!isSaisieDonneesEchantillon) return routesYarnsMarketplace[0].href
    else return findNextIndex(routesYarnsMarketplace, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'Designs') {
    if (!isSaisieDonneesEchantillon) return routesDesign[0].href
    else return findNextIndex(routesDesign, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'DesignsMarketplace') {
    if (!isSaisieDonneesEchantillon) return routesDesignMarketplace[0].href
    else return findNextIndex(routesDesignMarketplace, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'Trainee') {
    if (!isSaisieDonneesEchantillon) return routesTrainee[0].href
    else return findNextIndex(routesTrainee, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'TraineeManuf') {
    if (!isSaisieDonneesEchantillon) return routesTraineeShortManuf[0].href
    else return findNextIndex(routesTraineeShortManuf, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'TraineeDesign') {
    if (!isSaisieDonneesEchantillon) return routesTraineeShortDesign[0].href
    else return findNextIndex(routesTraineeShortDesign, lastSegment, direction, sampleData.page)!
  }

  if (sampleData && sampleData.routeType === 'TraineeShort') {
    if (!isSaisieDonneesEchantillon) return routesTraineeShort[0].href
    else return findNextIndex(routesTraineeShort, lastSegment, direction, sampleData.page)!
  }

  return ''
}
