import styled, { css } from 'styled-components'

import { fonts } from '../../theme'

export const Card = styled.div<{ $active?: boolean }>`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.concrete};
  border-radius: 0.4rem;
  cursor: pointer;
  &:hover {
    transition: transform 200ms ease-in, background-color 150ms ease-in, color 150ms ease-in;
    transform: scale(1.01);
  }
  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.colors.tundora};

      /* Appliquer le style aux éléments Title et Season lorsque $active est vrai */
      ${Title}, ${Season}, ${Text} {
        color: ${({ theme }) => theme.colors.pureWhite};
      }
    `}
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.mineShaft};
  ${fonts.GothamBold};
  letter-spacing: 0.5px;
  font-size: 1.2rem;
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  ${fonts.RobotoExtraLight};
  letter-spacing: 0.5px;
  font-size: 1.2rem;
`

export const Season = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  ${fonts.GothamBold};
  letter-spacing: 0.5px;
  font-size: 1.2rem;
`

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
`
