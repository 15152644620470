import styled, { css } from 'styled-components'

import breakpoints from '../../theme/breakpoints'
import ActionButton from '../ActionButton'

export const Container = styled.article`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  padding: 2rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.tundora};
  background: ${({ theme }) => theme.colors.concrete};
  ${breakpoints.up('desktop')} {
  }
`

export const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  gap: 1.2rem;
  align-items: center;
`
export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH4}
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mineShaft};
`
export const Date = styled.time`
  ${({ theme }) => theme.textStyles.titleH6}
  margin-left: auto;
  color: ${({ theme }) => theme.colors.gray};
`
export const Contents = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.6rem;
  margin: 0;
  padding: 0;
  & li {
    display: flex;
    gap: 0.8rem;
  }
  & strong {
    ${({ theme }) => theme.textStyles.titleH4}
  }
  & span {
    ${({ theme }) => theme.textStyles.titleH5}
  }
`
export const Footer = styled.footer<{ $withSeparator?: boolean }>`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${({ $withSeparator, theme }) =>
    $withSeparator === true &&
    css`
      border-top: 0.15rem solid ${theme.colors.tundora};
      padding-top: 1.2rem;
    `}
`
export const Received = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
`
export const Edit = styled(ActionButton)`
  align-self: flex-end;
`
