import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleUpdate = getMutationService({
  mutation: gql(`
    mutation sampleUpdate($sampleId: ID!, $input: SampleUpdateInput!) {
      sampleUpdate(sampleId: $sampleId, input: $input) {
        id
        collectionId
        universeEbmsId
        labels {
            id
            user {
                id
                email
            }
            url
            path
            order
        }
        name
        collectionRef
        qrCode
        applyToCollectionUniverse
        status
        boxApplyToCollection
        box {
          id
          name
        }
        forumApplyToCollection
        forum {
          id
          name
        }
        subForumApplyToCollection
        subForum {
          id
          name
        }
        mkpForum
        flagItems {
          id
          flagGroupId
          name
          pivot {
            value
            applyToCollection
          }
        }
        createdAt
        updatedAt
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
