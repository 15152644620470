import styled from 'styled-components'
import { DayPicker } from 'react-day-picker'

import { fonts } from '../../theme'

export const Picker = styled(DayPicker)`
  margin: 0;
  display: flex;
  justify-content: center;
  & .rdp-months {
    display: flex;
    & .rdp-month {
      & .rdp-caption {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.8rem;

        & .rdp-caption_dropdowns {
          position: relative;
          display: inline-flex;

          & .rdp-dropdown_month,
          & .rdp-dropdown_year {
            position: relative;
            display: inline-flex;
            align-items: center;
          }
          select {
            appearance: none;
            position: absolute;
            z-index: 2;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            cursor: inherit;
            opacity: 0;
            border: none;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
          }

          & .rdp-caption_label {
            color: ${({ theme }) => theme.colors.pureBlack};
            position: relative;
            z-index: 1;
            display: inline-flex;
            align-items: center;
            margin: 0;
            padding: 0 0.25em;
            white-space: nowrap;
            color: currentColor;
            border: 0;
            border: 2px solid transparent;

            & svg {
              margin-left: 0.8rem;
            }
          }

          & .rdp-vhidden {
            display: none;
          }
        }

        & .rdp-nav button {
          border: 0;
          padding: 0.4rem;
          width: 2.4rem;
          height: 2.4rem;
          cursor: pointer;
        }
      }
    }
  }

  & .rdp-table {
    max-width: 100%;
  }

  table {
    box-shadow: 4px 4px 34px rgba(0, 0, 0, 0.12);
    border-radius: 1.5rem;
    thead tr th {
      color: ${({ theme }) => theme.colors.pureWhite};
    }
    thead tr th::first-letter {
      color: ${({ theme }) => theme.colors.tundora};
    }
    tbody tr td {
      width: 5rem;
      padding: 1px;
      button {
        color: ${({ theme }) => theme.colors.mineShaft};
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  & .rdp-day_range_start,
  & .rdp-day_range_end {
    ${fonts.GothamBold}
    background-color: ${({ theme }) => theme.colors.tundora};
    color: ${({ theme }) => theme.colors.pureWhite};
    border-radius: 10rem;
    width: 100%;
    max-width: none;
  }

  & .rdp-cell .rdp-day_range_middle {
    ${fonts.GothamBold}
    background-color: ${({ theme }) => theme.colors.tundora};
    color: ${({ theme }) => theme.colors.pureWhite};
    width: 100%;
    max-width: none;
  }
`

export const TitleDate = styled.div`
  ${fonts.GothamMedium}
  font-size: 1.3rem;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 3.8rem;
  padding-inline: 2rem;
  text-transform: capitalize;
`

export const Button = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.tundora};
  transition: all 200ms ease-in;
  cursor: pointer;
  &:not(:disabled):hover {
    color: ${({ theme }) => theme.colors.mineShaft};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.silver};
  }
`
