import styled, { css } from 'styled-components'

import { breakpoints, fonts } from '../../theme'

export const RadioContainer = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2rem;
  gap: 2rem;
  cursor: pointer;
  margin-right: 12px;

  input[type='radio'] {
    appearance: none;
    border-radius: 2rem;
    border: 2px solid #ccc;
    width: 19px;
    height: 16px;
    margin-right: 8px;
    transition: border-color 0.2s ease-in-out;
    position: relative;
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      width: 17px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #333;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:checked:before {
      opacity: 1;
    }

    &:focus {
      outline: none;
      border-color: #666;
    }
  }

  input[type='radio']:checked {
    border-color: ${({ theme }) => theme.colors.tundora};
  }
`

export const SecondLabel = styled.div<{ $verticalSecondText?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  ${({ $verticalSecondText }) =>
    $verticalSecondText &&
    css`
      ${fonts.RobotoExtraLight}
      color: ${({ theme }) => theme.colors.tundora};
    `};
`

export const TextContainer = styled.div<{ $verticalSecondText?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.1rem;
  ${breakpoints.up('tablet')} {
    justify-content: space-between;
  }
  ${({ $verticalSecondText }) =>
    $verticalSecondText &&
    css`
      flex-direction: column;
      align-items: flex-start;
      ${fonts.GothamBold}
      gap: 0.5rem;
    `};
`
