import React from 'react'
import { RouteProps } from 'react-router-dom'

import SampleSheetsTemplate from '../../templates/SampleSheets'
import { useSampleSheetsTemplateProps } from '../../templates/SampleSheets/useProps'

const SampleSheets: React.FC<RouteProps> = () => {
  const templateProps = useSampleSheetsTemplateProps()
  return <SampleSheetsTemplate {...templateProps} />
}

export default SampleSheets
