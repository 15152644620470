import styled from 'styled-components'

import Heading from '../../components/Heading'
import { breakpoints } from '../../theme'
import ConfigSamples from '../../components/ConfigSamples'
import FormFieldText from '../../components/form/fields/FormFieldInput'
import FormikForm from '../../components/form/FormikForm'

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const SubjectField = styled(FormFieldText)`
  width: 100%;
  margin: 3rem 0;
`
export const Title = styled(Heading)`
  display: none;
  margin: 0;
  ${breakpoints.up('tablet')} {
    display: block;
  }
`
export const StyledConfigSamples = styled(ConfigSamples)``
export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`
