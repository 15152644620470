import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import { IconProps, Icons } from '../../components/Icon'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { ActionButtonScheme } from '../../components/ActionButton/types'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'

import * as SC from './styled'

export type ParamsTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title: string
  iconProps: IconProps
  exportCta?: {
    exportText: string
    onClick: () => void
  }
  inputList: { text: string; href?: string }[] | undefined
  toastModal?: ModalToastProps
}

const ParamsTemplate: FC<ParamsTemplateProps> = ({
  layoutProps,
  title,
  inputList,
  iconProps,
  exportCta,
  toastModal,
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={<>{toastModal && <ModalToast {...toastModal} />}</>}
    >
      <ContentWrapper maxWidth={'default'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.CTAListContainer>
          {inputList &&
            inputList.map((item, idx) => {
              return (
                <SC.CTA
                  key={idx}
                  text={item.text}
                  rightIconProps={{ ...iconProps, icon: Icons.arrowRight }}
                  variantScheme={ActionButtonScheme.Secondary}
                  href={item.href}
                />
              )
            })}
          {exportCta && (
            <SC.CTA
              text={exportCta.exportText}
              rightIconProps={{ ...iconProps, icon: Icons.export }}
              variantScheme={ActionButtonScheme.Secondary}
              onClick={exportCta.onClick}
            />
          )}
        </SC.CTAListContainer>
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default ParamsTemplate
