import React, { FC, useEffect, useState } from 'react'
import cx from 'classnames'

import { MobileNavProps } from '../../components/MobileNav'
import { SidebarNavProps } from '../../components/SidebarNav'
import { burgerMocks } from '../../components/Burger/mocks'
import useGetScrollbarWidth from '../../hooks/useGetScrollbarWidth'

import * as SC from './styled'

export type DefaultLayoutProps = {
  className?: string
  mobileNav?: MobileNavProps
  sidebarNav?: SidebarNavProps
  children?: React.ReactNode
  modals?: React.ReactNode
  marginMainContent?: boolean
}

const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  modals,
  className,
  mobileNav,
  sidebarNav,
  marginMainContent,
}) => {
  const [route, setRoute] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  useEffect(() => {
    setRoute(window.location.pathname)
  }, [])

  useGetScrollbarWidth()

  return (
    <SC.Container className={cx(className)}>
      {modals}
      {mobileNav && (
        <SC.MobileNavigation
          {...mobileNav}
          menu={{ ...mobileNav.menu, isOpen: isOpen, route: route }}
          burger={{
            ...burgerMocks.basic,
            onClick() {
              setIsOpen((prevIsOpen) => !prevIsOpen)
            },
            isOpen: isOpen,
          }}
        />
      )}
      {sidebarNav && <SC.Sidebar {...sidebarNav} />}
      <SC.MainContent $marginMainContent={marginMainContent}> {children}</SC.MainContent>
    </SC.Container>
  )
}

export default DefaultLayout
