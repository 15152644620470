import styled, { css } from 'styled-components'

import Dot from '../Dot'

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  color: ${({ theme }) => theme.colors.gray};
  & > :first-child {
    background-color: ${({ theme }) => theme.colors.tundora};
    color: ${({ theme }) => theme.colors.pureWhite};
  }
`

export const History = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  gap: 6px;
  border-radius: 5px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.pureWhite};
`

export const HistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const HistoryBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Title = styled.div<{
  $variant?: 'default' | 'selected' | 'validated' | 'deleted'
}>`
  ${({ theme }) => theme.textStyles.titleH6};
  ${(props) => {
    switch (props.$variant) {
      case 'default':
        return css`
          color: ${({ theme }) => theme.colors.gray};
        `
      case 'validated':
        return css`
          color: ${({ theme }) => theme.colors.chateauGreen};
        `
      case 'selected':
        return css`
          color: ${({ theme }) => theme.colors.selectiveTextYellow};
        `
      case 'deleted':
        return css`
          color: ${({ theme }) => theme.colors.cinnabar};
        `
    }
  }}
`

export const Date = styled.div`
  ${({ theme }) => theme.textStyles.textBold};
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.titleH6};
`

export const User = styled.div`
  ${({ theme }) => theme.fonts.RobotoLightItalic};
  font-size: 14px;
`

export const StyledDot = styled(Dot)`
  position: absolute;
  top: 9px;
  left: -19.4px;
  z-index: 1;
`

export const Test = styled.div`
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray};
  top: 9px;
  left: -17px;
`
