import styled from 'styled-components'

import { breakpoints } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'
import EditoCard from '../../components/EditoCard'
import SearchBar from '../../components/SearchBar'
import ActionButton from '../../components/ActionButton'
import FormikForm from '../../components/form/FormikForm'
import FormFieldTextArea from '../../components/form/fields/FormFieldTextArea'
import Heading from '../../components/Heading'
import FormErrors from '../../components/form/FormErrors'
import { FieldText } from '../BoxesAdd/styled'

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0;
  ${breakpoints.up('tablet')} {
    margin-bottom: 7rem;
  }
`

export const EditoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
  height: fit-content;
  ${breakpoints.up('tablet')} {
    justify-content: space-between;
    gap: 2.2rem;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

export const Edito = styled(EditoCard)`
  break-inside: avoid;
  margin-bottom: 2rem;
  width: 100%;
`

export const Search = styled(SearchBar)`
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`

export const EditButton = styled(ActionButton)`
  width: fit-content;
  margin: auto;
`

export const Empty = styled.div`
  margin: auto;
  margin-top: 24px;
  ${({ theme }) => theme.textStyles.textItalic}
`

export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
  ${breakpoints.up('tablet')} {
    gap: 3.6rem;
  }
`

export const StyledTextarea = styled(FormFieldTextArea)``
export const StyledTextInput = styled(FieldText)``

export const Title = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.tundora};
`

export const Fieldset = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
`
export const Errors = styled(FormErrors)``
