import styled from 'styled-components'

import FilterTag from '../FilterTag'
import FiltersForm from '../forms/FiltersForm'
import ModalFullScreen from '../ModalFullScreen'
import FilterButton from '../FilterButton'
import { breakpoints } from '../../theme'

export const Container = styled(ModalFullScreen)`
  cursor: pointer;
`

export const Content = styled.div`
  padding: 2rem 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  z-index: 10;
  padding-bottom: 9rem;
  padding-top: 8rem;
  ${breakpoints.up('tablet')} {
    padding: 4rem 2rem;
    width: 40%;
    margin-left: auto;
    overflow-y: auto;
    height: 100%;
  }
`

export const Title = styled.p`
  ${({ theme }) => theme.textStyles.titleH2};
  margin-bottom: 1.2rem;
  display: none;
  ${breakpoints.up('tablet')} {
    display: flex;
  }
`

export const Tags = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
`
export const Tag = styled(FilterTag)``

export const Form = styled(FiltersForm)``

export const BackButton = styled(FilterButton)`
  width: fit-content;
  margin-bottom: 12px;
`
