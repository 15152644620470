import { filtersMocks } from '../Filters/mocks'

import { FiltersTagsProps } from './index'

const basic: FiltersTagsProps = {
  button: {
    text: 'Filtrer',
  },
  filters: { ...filtersMocks.basic, isOpen: false },
  tags: [
    { text: 'Italie' },
    { text: 'France' },
    { text: 'Italie' },
    { text: 'Italie' },
    { text: 'Italie' },
    { text: 'Italie' },
    { text: 'Italie' },
    { text: 'Italie' },
  ],
}

export const filtersTagsMocks = {
  basic,
}
