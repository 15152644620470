import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const flagItemDelete = getMutationService({
  mutation: gql(`
    mutation flagItemDelete($flagItemId: ID!) {
        flagItemDelete(flagItemId: $flagItemId) {
          id
          name
          miraklRef
          responseType
          order
          items {
            id
            name
            cascadingGroups
            order
          }
        }
      }
    `),
  transformer: (response) => {
    return response
  },
})
