import styled from 'styled-components'

import Icon, { Icons } from '../../components/Icon'
import BottomActions from '../../components/BottomActions'

export const Container = styled.div`
  min-height: 100vh;
  background-color: white;
`

export const Header = styled.header`
  padding: 1.2rem 2rem;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    background-color: ${({ theme }) => theme.colors.tundora};
    padding: 3.2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.pureWhite};
  }
`
export const Logo = styled(Icon).attrs({ icon: Icons.logo, width: 165, height: 30 })``

export const MainContent = styled.div`
  flex: 1;
`

export const Actions = styled(BottomActions)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 2rem 2rem;
  box-sizing: border-box;
`
