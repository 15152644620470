import React, { FC } from 'react'

import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import { FormikFormProps } from '../../form/FormikForm'
import { ColorPickerProps } from '../../ColorPicker'
import { ActionButtonProps } from '../../ActionButton'
import { ActionButtonSize, ActionButtonScheme } from '../../ActionButton/types'
import { Icons } from '../../Icon'

import * as SC from './styled'

export type LoungeNewForumFormValues = {
  forumName: string
}

export type LoungeNewForumFormProps = {
  formikForm: FormikFormProps<LoungeNewForumFormValues>
  textFieldTitle: string
  fields: {
    forumName: FormFieldInputProps
  }
  colorPickerProps: ColorPickerProps
  edition?: boolean
  deleteButtonProps?: ActionButtonProps
}

const LoungeNewForumForm: FC<LoungeNewForumFormProps> = ({
  fields,
  formikForm,
  colorPickerProps,
  textFieldTitle,
  edition,
  deleteButtonProps,
}) => {
  return (
    <SC.Form {...formikForm}>
      <SC.Title>{textFieldTitle}</SC.Title>
      <SC.TextField
        {...fields.forumName}
        type="text"
        hasFloatingLabel={false}
        Component={SC.FieldText}
        disabled={edition}
      />
      <SC.StyledColorPicker {...colorPickerProps} />
      {deleteButtonProps && (
        <SC.DeleteButton
          rightIconProps={{ icon: Icons.trash, height: 12, width: 12 }}
          variantSize={ActionButtonSize.Small}
          variantScheme={ActionButtonScheme.Tertiary}
          type="button"
          {...deleteButtonProps}
        />
      )}
    </SC.Form>
  )
}

export default LoungeNewForumForm
