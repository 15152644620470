import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleSearchFilters = getQueryService({
  query: gql(`
    query sampleSearchFilters($universeEbmsId: ID!) {
        sampleSearchFilters(universeEbmsId: $universeEbmsId)
    }
    `),
  transformer: (response) => {
    return response
  },
})
