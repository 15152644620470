import styled from 'styled-components'

import { breakpoints, textStyles } from '../../theme'
import InfosDisplay from '../../components/InfosDisplay'
import Heading from '../../components/Heading'
import CollapsibleItem from '../../components/CollapsibleItem'
import LoungeCard from '../../components/LoungeCard'
import SimpleLoader from '../../components/SimpleLoader'

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`

export const Loader = styled(SimpleLoader)`
  margin-left: 49%;
`

export const Section = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  margin: 0;
`

export const Infos = styled(InfosDisplay)``

export const Title = styled(Heading)`
  margin-top: 0;
`

export const Text = styled.div`
  ${textStyles.titleH5}
`

export const Collapsible = styled(CollapsibleItem)``

export const StyledLoungeCard = styled(LoungeCard)`
  margin-bottom: 1rem;
`
