import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.4rem;
`

export const Letter = styled.p<{ $isCurrent?: boolean }>`
  ${({ theme }) => theme.textStyles.textBook};
  color: ${({ theme }) => theme.colors.mineShaft};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 1.2rem;
  min-height: 1.3rem;
  border-radius: 0.1rem;
  font-size: 1rem;
  ${({ $isCurrent, theme }) =>
    $isCurrent &&
    css`
      background-color: ${theme.colors.concrete};
    `};
`
