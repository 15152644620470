import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const authCheckEmailExists = getQueryService({
  query: gql(`
    query authCheckEmailExists($email: String!) {
        authCheckEmailExists(email: $email)
    }
`),
  transformer: (response) => {
    return response
  },
})
