export function convertFiltersName(input: string): string {
  switch (input) {
    case 'FI__accessory_types':
      return "Type d'accessoire"
    case 'FI__aspects':
      return 'Aspect'
    case 'FI__categories':
      return 'Catégorie'
    case 'FI__patterns':
      return 'Motif'
    case 'FI__pattern_types':
      return 'Type de motif'
    case 'countries':
      return "Pays de l'exposant"
    case 'exhibitions':
      return 'Salon'
    case 'mkp_forums':
      return 'Présélection PV'
    case 'forums':
      return 'Forum'
    case 'pavilions':
      return 'Secteur'
    case 'FI__product_destinations':
      return 'Destination produit'
    case 'FI__performance_codes':
      return 'Codes de performance'
    case 'FI__materials':
      return 'Composition'
    case 'FI__certifications':
      return 'Certifications'
    case 'FI__number_ends':
      return 'Number end'
    case 'FI__unit_values':
      return 'Unit value'
    case 'FI__technology_activities':
      return 'Technology activity'
    case 'FI__animals':
      return 'Animal'
    case 'FI__tanning_types':
      return 'Type de tannage'
    case 'FI__manufacturing_types':
      return 'Type de produit'
    case 'FI__color':
      return 'Couleur'
    default:
      return input
  }
}
