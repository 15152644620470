import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import UserForm, { UserFormProps } from '../../components/forms/UserForm'
import { ActionButtonProps } from '../../components/ActionButton'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'

import * as SC from './styled'

export type UsersManagementTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title?: string
  subtitle?: string
  form?: UserFormProps
  deleteButton?: ActionButtonProps
  deleteModal?: ModalDeleteProps
}

const UsersManagementTemplate: FC<UsersManagementTemplateProps> = ({
  layoutProps,
  title,
  subtitle,
  form,
  deleteButton,
  deleteModal,
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={deleteModal && <ModalDelete {...deleteModal} />}
    >
      <SC.Wrapper maxWidth={'default'}>
        {title && <SC.Title tag={'h2'} text={title} />}
        {subtitle && <SC.Subtitle tag={'h3'} text={subtitle} />}
        <SC.Content>
          {form && <UserForm {...form} />}
          {deleteButton && (
            <SC.DeleteButton
              variantSize={ActionButtonSize.Small}
              variantScheme={ActionButtonScheme.Tertiary}
              {...deleteButton}
            />
          )}
        </SC.Content>
      </SC.Wrapper>
    </LayoutWithBottomActions>
  )
}

export default UsersManagementTemplate
