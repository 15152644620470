import styled from 'styled-components'

import SearchBar from '../../components/SearchBar'
import Tabs from '../../components/Tabs'
import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import SampleCard from '../../components/SampleCard'
import FiltersTags from '../../components/FiltersTags'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Search = styled(SearchBar)`
  margin-bottom: 2.2rem;
  ${breakpoints.up('tablet')} {
    margin-top: 2.2rem;
    margin-bottom: 0rem;
  }
`

export const Title = styled(Heading)`
  display: none;
  margin-bottom: 0.8rem;
  ${breakpoints.up('tablet')} {
    display: flex;
  }
`

export const StyledTabsBottom = styled(Tabs)`
  margin-top: 24px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 1.5rem;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
  height: fit-content;
  ${breakpoints.up('tablet')} {
    justify-content: space-between;
    gap: 2.2rem;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

export const Card = styled(SampleCard)`
  width: 100%;
  ${breakpoints.up('tablet')} {
    max-width: 344px;
  }
`

export const Filter = styled(FiltersTags)`
  padding-top: 1.5rem;
`
export const LoaderContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
