import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { router, routesPath } from '../../router'

import { ImportDelayTemplateProps } from '.'

export const useImportDelayTemplateProps = (): ImportDelayTemplateProps => {
  const configurationUpdate = useSelector(selectors.configuration.configurationUpdate)
  const config = useSelector(selectors.configuration.configurationInfo)
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const [currentDelay, setCurrentDelay] = useState<number>(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (config) {
      setCurrentDelay(config.noDaysBeforeSendingToMarketplace)
    }
  }, [config])

  const handleDelayChange = (value: number) => {
    if (value >= 0) {
      setCurrentDelay(value)
    }
  }

  useEffect(() => {
    if (configurationUpdate && configurationUpdate?.success) {
      const link = router(routesPath.params)
      navigate(link)
    }
  }, [configurationUpdate, dispatch, navigate])

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.params)
              navigate(link)
            },
          },
          next: {
            text: 'Valider',
            isPending: configurationUpdate?.pending,
            disabled: configurationUpdate?.pending,
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
            onClick: () => {
              dispatch(
                actions.configuration.configurationUpdateRequest({
                  noDaysBeforeSendingToMarketplace: currentDelay,
                })
              )
            },
          },
        },
      },
      title: 'Délai import Marketplace',
      text: `Veuillez indiquer le délai en nombre de <strong>jours</strong> entre l'application du statut "OK Shooting" à l'échantillon et le lancement de l'import sur la Marketplace.</br> Pour lancer les imports automatiquement et sans délai, insérez le chiffre 0.`,
      delay: currentDelay,
      handleDelayChange: handleDelayChange,
    }
  }, [configurationUpdate?.pending, currentDelay, dispatch, layoutProps, navigate])
}
