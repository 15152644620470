import React from 'react'
import { RouteProps } from 'react-router-dom'

import SampleDataTraineeTemplate from '../../../templates/SampleDataTrainee'
import { useSampleDataLabelTemplateProps } from '../../../templates/SampleData/usePropsSampleDataLabel'

const ExponentSampleDataLabel: React.FC<RouteProps> = () => {
  //const form = useSelector(selectors.samples.sampleFormByUniverse)
  const templateProps = useSampleDataLabelTemplateProps("Prendre en photo l'etiquette exposant")
  return <SampleDataTraineeTemplate {...templateProps} />
}

export default ExponentSampleDataLabel
