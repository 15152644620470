import styled from 'styled-components'

import Heading from '../../components/Heading'
import { fonts, colors } from '../../theme'
import ActionButton from '../../components/ActionButton'
import StatutSelection from '../../components/StatutSelection'
import SearchBar from '../../components/SearchBar'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
`

export const ForumSelectedContainer = styled.div`
  margin-bottom: 2.2rem;
`

export const Search = styled(SearchBar)`
  margin: 2.2rem 0;
`

export const SubTitle = styled.div`
  ${fonts.GothamBold}
  color: ${colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
`

export const Text = styled.div`
  ${fonts.RobotoLight}
  color: ${colors.gray};
  font-size: 1.2rem;
  letter-spacing: 1px;
  margin-bottom: 2.2rem;
`

export const CTAListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin: 1rem 0;
`

export const CTA = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
`

export const StatutSelect = styled(StatutSelection)`
  width: 100%;
  justify-content: space-between;
`
