import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitionDeleteForum = getMutationService({
  mutation: gql(`
    mutation exhibitionDeleteForum($forumId: ID!) {
        exhibitionDeleteForum(forumId: $forumId) {
            id
            apiId
            name
            seasonCode
            seasonYear
            dateStart
            dateEnd
            dateSampleStart
            dateSampleEnd
            isActive
            forums {
                id
                name
                color
                subForums {
                    id
                    name
                }
            }
            boxes {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
