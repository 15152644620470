import styled from 'styled-components'

import FormFieldInput from '../form/fields/FormFieldInput'
import SearchButton from '../SearchButton'

export const SearchBar = styled.div``

export const Search = styled(SearchButton)`
  color: ${({ theme }) => theme.colors.gray};
`

export const Input = styled(FormFieldInput)``
