import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import { IconProps } from '../../components/Icon'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { ActionButtonScheme } from '../../components/ActionButton/types'

import * as SC from './styled'

export type $variant =
  | 'default'
  | 'validate'
  | 'delete'
  | 'leather'
  | 'sourcing'
  | 'accessories'
  | 'inspiration'
  | 'design'
  | 'forumSelect'

export type MultipleEditionTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title: string
  subTitle?: string
  text: string
  iconProps: IconProps
  inputList: {
    text: string
    secondText?: string
    href: string
    variant?: $variant
  }[]
}

const MultipleEdition: FC<MultipleEditionTemplateProps> = ({
  layoutProps,
  text,
  title,
  subTitle,
  inputList,
  iconProps,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.SubTitle>{subTitle} </SC.SubTitle>
        <SC.Text>{text}</SC.Text>
        <SC.CTAListContainer>
          {Object.keys(inputList).map((key, idx) => {
            return (
              <SC.CTA
                key={idx}
                variantScheme={ActionButtonScheme.Secondary}
                text={inputList[idx].text}
                rightIconProps={iconProps}
                href={inputList[idx].href}
              />
            )
          })}
        </SC.CTAListContainer>
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default MultipleEdition
