import React, { useEffect } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../../components/form/fields/FormFieldRadioGroup/types'
import MultipleEditionDataSelection, {
  MultipleEditionDataSelectionTemplateFormValues,
} from '../../../templates/MultipleEditionDataSelection'
import { useStatutSelectionProps } from '../../../templates/MultipleEditionDataSelection/useProps'
import { ExhibitionForum } from '../../../graphql/generated/graphql'
import { actions, selectors } from '../../../redux'
import { router, routesPath } from '../../../router'
import { Icons } from '../../../components/Icon'
import { getContrastColor } from '../../../relay/Forums/getContrastColor'

const MultipleEditionSubForum: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const forumSelected = useSelector(selectors.multipleEdition.forum)
  const sampleSubForum = useSelector(selectors.multipleEdition.subForum)

  const onSubmitForm = (values: MultipleEditionDataSelectionTemplateFormValues) => {
    const subForum = forumSelected?.subForums.find((item) => item.name === values.radios)
    if (subForum) {
      dispatch(actions.multipleEdition.setSubForum(subForum))
      const link = router(routesPath.multipleEditionScan)
      navigate(link)
    }
  }

  useEffect(() => {
    if (!forumSelected) {
      const link = router(routesPath.multipleEditionForum)
      navigate(link)
    }
  }, [dispatch, forumSelected, navigate])

  const templateProps = useStatutSelectionProps(onSubmitForm)

  return (
    <MultipleEditionDataSelection
      {...templateProps}
      layoutProps={{
        ...templateProps.layoutProps,
        bottomActions: {
          ...templateProps.layoutProps?.bottomActions,
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.multipleEditionForum)
              navigate(link)
            },
          },
        },
      }}
      subTitle="EMPLACEMENT SOUS-FORUM"
      text="À quel sous-forum souhaitez-vous ajouter les échantillons ?"
      forumSelected={
        forumSelected
          ? {
              text: forumSelected?.name,
              backgroundColor: getContrastColor(forumSelected.color as string),
              color: forumSelected.color as string,
            }
          : undefined
      }
      radios={{
        name: 'radios',
        required: true,
        optionsFont: VariantRadioFont.SmallBold,
        optionsSize: VariantRadioSize.big,
        options:
          forumSelected && forumSelected.subForums
            ? forumSelected.subForums.map((item: ExhibitionForum) => ({
                label: item.name,
                value: item.name,
                dotted: true,
              }))
            : [],
      }}
      formikForm={{
        ...templateProps.formikForm,
        initialValues: {
          radios: sampleSubForum?.name,
        } as MultipleEditionDataSelectionTemplateFormValues,
      }}
    />
  )
}

export default MultipleEditionSubForum
