import tinycolor from 'tinycolor2'

export const getContrastColor = (color: string) => {
  const contrastThreshold = 1500
  const tinycolorObject = tinycolor(color)
  const luminance = tinycolorObject.getLuminance()
  const isLight = luminance > 1
  const direction = isLight ? -1 : 1
  const adjustedColor = tinycolorObject.lighten((direction * contrastThreshold) / 35)
  return adjustedColor.toString()
}
