import React, { FC } from 'react'

import * as SC from './styled'

export type FilterTagProps = {
  className?: string
  text: string
  category?: string
}

const FilterTag: FC<FilterTagProps> = ({ className, text, category }) => {
  return text ? (
    <SC.Tag title={category} className={className}>
      {text}
    </SC.Tag>
  ) : null
}

export default FilterTag
