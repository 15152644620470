import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Icons } from '../../components/Icon'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { UserRole } from '../../graphql/generated/graphql'
import { selectors } from '../../redux'

import { MultipleEditionTemplateProps } from '.'

export const useMultipleEditionTemplateProps = (): MultipleEditionTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('scan')
  const user = useSelector(selectors.auth.user)

  return useMemo(
    () => ({
      title: 'Edition multiple',
      text: 'Quelle modification souhaitez-vous apporter aux échantillons ?',
      layoutProps: {
        ...layoutProps,
        bottomActions: {},
      },
      iconProps: {
        icon: Icons.arrowRight,
        width: 20,
      },
      inputList:
        user?.role !== UserRole.Shooting
          ? [
              { text: 'Statut sélection', href: '/edition-multiple/statut-selection' },
              { text: 'Emplacement forum', href: '/edition-multiple/emplacement-forum' },
              { text: 'Edito', href: '/edition-multiple/edito' },
              { text: 'Présélection PV', href: '/edition-multiple/mkp-forum' },
            ]
          : [{ text: 'Statut sélection', href: '/edition-multiple/statut-selection' }],
    }),
    [layoutProps, user?.role]
  )
}
