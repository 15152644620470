import { all, put, takeEvery } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'

export default class MailsSagas {
  static *onMailTemplateByType({
    payload,
  }: ReturnType<typeof actions.mails.mailTemplateByTypeRequest>) {
    const rs = yield* ApiSagas.call(services.mails.queries.mailTemplateByType, payload)
    if (rs.errors) {
      yield put(actions.mails.mailTemplateUpsertError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.mails.setMailsByType(rs.data.mailTemplateByType))
      yield put(actions.mails.mailTemplateByTypeSuccess())
    }
  }

  static *onMailTemplateUpsert({
    payload,
  }: ReturnType<typeof actions.mails.mailTemplateUpsertRequest>) {
    const rs = yield* ApiSagas.call(services.mails.mutations.mailTemplateUpsert, payload)
    if (rs.errors) {
      yield put(actions.mails.mailTemplateUpsertError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.mails.mailTemplateUpsertSuccess())
    }
  }

  static *listeners() {
    yield all([
      takeEvery(actions.mails.mailTemplateByTypeRequest, this.onMailTemplateByType),
      takeEvery(actions.mails.mailTemplateUpsertRequest, this.onMailTemplateUpsert),
    ])
  }
}
