import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { filtersMocks } from '../../components/Filters/mocks'
import { filtersTagsMocks } from '../../components/FiltersTags/mocks'
import { Sample, SampleStatus } from '../../graphql/generated/graphql'
import { SampleCardProps } from '../../components/SampleCard'
import { actions, selectors } from '../../redux'
import { sampleStatusFrenchMapping } from '../../types/sampleStatusFrenchMapping'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { convertMkpToString } from '../../relay/SampleSheet/ConvertMkpToString'
import { FilterTagProps } from '../../components/FilterTag'

import { SampleSearchTemplateProps } from '.'

export const useSamplesSearchProps = ({
  selectedTab,
  handleTabClick,
  handleFiltersClick,
  tags,
  search,
  handleSearchChange,
  samples,
  currentPage,
  allSamples,
  setAllSamples,
}: {
  selectedTab: boolean
  handleTabClick: (bool: boolean) => void
  handleFiltersClick: () => void
  tags: FilterTagProps[]
  search: string
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void
  samples: Sample[] | undefined
  currentPage: number
  allSamples: Sample[]
  setAllSamples: React.Dispatch<React.SetStateAction<Sample[]>>
}): SampleSearchTemplateProps => {
  const [parsedSamples, setParsedSamples] = useState<SampleCardProps[] | undefined>(undefined)
  const [pending, setPending] = useState<boolean>(true)
  const layoutProps = useDefaultLayoutTemplateProps(undefined, true)
  const config = useSelector(selectors.configuration.configurationInfo)
  const samplesQuery = useSelector(selectors.samples.getSamples)
  const samplesPaginator = useSelector(selectors.samples.samplesPaginator)

  const dispatch = useDispatch()
  // Parse samples list
  useEffect(() => {
    const parseSamplesCards = (samples: Sample[] | undefined, appendCards: boolean) => {
      const newCards: SampleCardProps[] = []
      config &&
        samples?.forEach((sample: Sample) => {
          const category =
            sample.flagItems && sample.flagItems.find((item) => item.group.miraklRef === 'category')
          let differenceInDays = -1
          if (sample.status === SampleStatus.SelectedOkShooting) {
            const dateString = sample.statusUpdatedAt
            const dateObject = dayjs(dateString)
            const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
            const dateNow = dayjs()
            differenceInDays = dateInXDays.diff(dateNow, 'day')
          }
          const card: SampleCardProps = {
            title: sample.name,
            category: category && category?.name,
            status: {
              type:
                sample.status === SampleStatus.ValidatedOkMarketplace
                  ? SampleStatus.SelectedOkShooting
                  : sample.status,
              value:
                sample.status === SampleStatus.ValidatedOkMarketplace
                  ? (sampleStatusFrenchMapping[SampleStatus.SelectedOkShooting] as string)
                  : (sampleStatusFrenchMapping[sample.status] as string),
            },
            forumColor: sample.forum?.color ? sample.forum?.color : '',
            forum: sample.forum?.name,
            subForum: sample.subForum?.name,
            mkpForum: sample.mkpForum ? convertMkpToString(sample.mkpForum) : undefined,
            number: sample.id,
            isDelete: sample.isDeleted,
            okMkp: sample.marketplaceImported,
            noMkp: sample.marketplaceImportError,
            //@ts-ignore
            updatedAt: sample.updatedAt,
            image:
              sample.photos && sample.photos[0]
                ? {
                    src: sample.photos[0].url,
                    alt: sample.photos[0].id,
                  }
                : undefined,
            marketplaceWarning:
              differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0
                ? {
                    daysLeft: differenceInDays.toString(),
                    text: 'Avant dépôt sur la Marketplace',
                  }
                : undefined,
            onClick: () => dispatch(actions.samples.setSample(sample)),
          }
          newCards.push(card)
        })

      newCards.sort((a, b) => {
        //@ts-ignore
        if (a.updatedAt && b.updatedAt) {
          //@ts-ignore
          const dateA = new Date(a.updatedAt)
          //@ts-ignore
          const dateB = new Date(b.updatedAt)
          //@ts-ignore
          return dateB - dateA
        } else {
          return 0
        }
      })

      setParsedSamples((prevCards) => {
        if (!appendCards) {
          const uniqueCards = newCards.filter((newCard) =>
            prevCards?.every(
              (prevCard) =>
                prevCard.number !== undefined &&
                newCard.number !== undefined &&
                prevCard.number !== newCard.number
            )
          )
          return prevCards ? [...prevCards, ...uniqueCards] : uniqueCards
        } else {
          return newCards
        }
      })
    }

    if (samples && currentPage === 1) {
      parseSamplesCards(samples, true)
    } else if (samples) {
      parseSamplesCards(samples, false)
    }
  }, [config, config?.noDaysBeforeSendingToMarketplace, currentPage, dispatch, samples])

  useEffect(() => {
    const newSamples = samples || []
    const existingKeys = allSamples.map((sample) => sample.id)

    newSamples.forEach((sample: Sample) => {
      if (!existingKeys.includes(sample.id)) {
        setAllSamples((prevSamples) => [...prevSamples, sample])
      }
    })
  }, [allSamples, samples, setAllSamples])

  useEffect(() => {
    const updatePendingState = () => {
      if (
        samplesPaginator &&
        samplesQuery.pending &&
        !pending &&
        samplesPaginator?.currentPage < samplesPaginator?.lastPage
      ) {
        setPending(true)
      }
      if (!samplesQuery.pending && pending) {
        setPending(false)
      }
    }
    const intervalId = setInterval(updatePendingState, 200)
    return () => clearInterval(intervalId)
  }, [pending, samples, samplesPaginator, samplesQuery.pending])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
      },
      title: 'Recherche',
      noSample: "Il n'y a aucun échantillon correspondant à votre recherche",
      tabs: {
        tabs: [
          {
            name: 'Echantillons',
            selected: selectedTab,
            onClick: () => handleTabClick(true),
          },
          {
            name: 'Exposants',
            selected: !selectedTab,
            onClick: () => handleTabClick(false),
          },
        ],
      },
      backButtonProps: {
        open: false,
        onClick: handleFiltersClick,
      },
      cards: parsedSamples,
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      filtersTags: {
        text: 'Filtrer',
        filters: { ...filtersMocks.basic, isOpen: false },
        tags: tags.map((tag) => ({ text: tag.text, category: tag.category })),
        button: { ...filtersTagsMocks.basic.button, onClick: handleFiltersClick },
      },
      pending: pending || samples === null,
      errors: null,
    }),
    [
      handleFiltersClick,
      handleSearchChange,
      handleTabClick,
      layoutProps,
      parsedSamples,
      pending,
      samples,
      search,
      selectedTab,
      tags,
    ]
  )
}
