import styled from 'styled-components'

import ForgottenPasswordForm from '../../components/forms/ForgottenPasswordForm'
import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    text-align: center;
  }
`

export const StyledContentWrapper = styled(ContentWrapper)`
  max-width: 37rem;
  margin-top: 5.6rem;
`

export const Description = styled.p`
  ${({ theme }) => theme.textStyles.titleH7};
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 3.4rem;
`

export const SuccessMessage = styled.p`
  ${({ theme }) => theme.textStyles.titleH7};
  color: ${({ theme }) => theme.colors.chateauGreen};
  margin-top: 2.4rem;
  text-align: right;
`

export const Form = styled(ForgottenPasswordForm)`
  margin: 0 auto;
  max-width: 900px;
`
