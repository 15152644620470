import { generatePath } from 'react-router-dom'
import { Params } from 'react-router'

export const router = <S extends string>(path: S, params?: Params<S>) => {
  return generatePath(path, params as any)
}

export const routesPath = {
  home: '/',
  login: '/connexion',
  firstLogin: '/premiere-connexion',
  resetPassword: '/reinitialiser-mon-mot-de-passe',
  forgottenPassword: '/mot-de-passe-oublie',
  myAccount: '/mon-compte',
  sampleScan: '/scan-echantillon',
  multipleEdition: '/edition-multiple',
  multipleEditionScan: '/edition-multiple/scan-multiple',
  multipleEditionSubForum: '/edition-multiple/emplacement-sous-forum',
  multipleEditionForum: '/edition-multiple/emplacement-forum',
  multipleEditionStatut: '/edition-multiple/statut-selection',
  multipleEditionMkpForum: '/edition-multiple/mkp-forum',
  multipleEditionEdito: '/edition-multiple/edito',
  exponent: '/exposants/:redirect',
  exponentSheet: '/exposants/fiche-exposant/:exposantId/:apiId',
  exponentSheetParcels: '/exposants/fiche-exposant/:exposantId/:apiId/colis/',
  exponentSheetInfos: '/exposants/fiche-exposant/:exposantId/:apiId/informations-generales/',
  exponentSheetInternalNote: '/exposants/fiche-exposant/:exposantId/:apiId/note-interne/',
  exponentSheetArchive: '/exposants/fiche-exposant/:exposantId/:apiId/archive/',
  exhibitionSheetInfos: '/exposants/fiche-salon/:exhibitionId/informations-generales',
  exhibitionSheetSamples: '/exposants/fiche-salon/:exhibitionId/echantillons',
  exhibitionSheetExhibitor: '/exposants/fiche-salon/:exhibitionId/exposants',
  exhibitionSheetEdito: '/exposants/fiche-salon/:exhibitionId/editos',
  editoSheet: '/edito/:editoId/:apiId',
  sampleSheetData: '/echantillon/fiche-echantillon/:sampleId',
  sampleSheetCollection: '/echantillon/fiche-echantillon/:sampleId/collection',
  sampleSheetEdito: '/echantillon/fiche-echantillon/:sampleId/edito',
  sampleSheetShooting: '/echantillon/fiche-echantillon/:sampleId/shooting',
  sampleSheetHistory: '/echantillon/fiche-echantillon/:sampleId/history',
  sampleSheetLabel: '/echantillon/fiche-echantillon/:sampleId/label',
  // Routes SampleData
  exponentSampleDataMkpForum:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/mkpForum',
  exponentSampleDataForum:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/forum',
  exponentSampleDataSubForum:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/subForum',
  exponentSampleDataCategory:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/category',
  exponentSampleDataBoxes:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/boxe',
  exponentSampleDataPerformanceCode:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/performanceCode',
  exponentSampleDataPattern:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/pattern',
  exponentSampleDataShooting:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/shooting',
  exponentSampleDataProductDestination:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/productDestination',
  exponentSampleDataSummary:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/summary',
  exponentSampleDataTechnologyActivity:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/technologyActivity',
  exponentSampleDataAspect:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/aspect',
  exponentSampleDataNumberEnd:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/numberEnd',
  exponentSampleDataUnitValue:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/unitValue',
  exponentSampleDataCertifications:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/certifications',
  exponentSampleDataPatternType:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/patternType',
  exponentSampleDataTanningType:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/tanningType',
  exponentSampleDataAnimal:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/animal',
  exponentSampleDataLabel:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/label',
  exponentSampleDataAccessorieType:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/accessorieType',
  exponentSampleDataExhibitorRef:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/exhibitorRef',
  exponentSampleDataSmartCreationChoice:
    '/exposants/fiche-exposant/:exposantId/:sampleId/smartCreationChoice',
  exponentSampleDataLeatherChoice: '/exposants/fiche-exposant/:exposantId/:sampleId/leatherChoice',
  exponentSampleDataColor:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/color',
  exponentSampleDataMaterial:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/material',
  exponentSampleDataManufacturingType:
    '/exposants/fiche-exposant/:exposantId/:sampleId/saisie-donnees-echantillon/manufacturingType',
  // End SampleData routes
  lounges: '/salons/:redirect',
  loungesInfos: '/salons/configuration/salon',
  loungesDates: '/salons/configuration/dates',
  loungesDatesSamples: '/salons/configuration/dates-echantillons',
  loungesForums: '/salons/configuration/forums',
  loungesForumsChoice: '/salons/configuration/choix-forums',
  loungesSubForumsChoice: '/salons/configuration/choix-sous-forums',
  loungeResume: '/salons/configuration/resume',
  params: '/parametres',
  paramsBoxes: '/parametres/caisses-de-rangement',
  paramsAddBoxes: '/parametres/caisses-de-rangement/creer-caisses-de-rangement',
  paramsImportDelay: '/parametres/delai-import-marketplace',
  paramsImportExhibitorEbms: '/parametres/import-exhibitor-ebms',
  paramsUsers: '/parametres/acces-utilisateurs',
  paramsUserCreate: '/parametres/creer-utilisateur',
  paramsUserManage: '/parametres/gestion-utilisateur',
  paramsEmails: '/parametres/emails-automatiques',
  manageEmails: '/parametres/emails-automatiques/edition/:emailType',
  sampleSheets: '/parametres/edition-echantillon',
  sampleSheetEditAccessorieType: '/echantillon/edition-echantillon/:routeType/accessorieType',
  sampleSheetEditAnimal: '/echantillon/edition-echantillon/:routeType/animal',
  sampleSheetEditAspect: '/echantillon/edition-echantillon/:routeType/aspect',
  sampleSheetEditCategory: '/echantillon/edition-echantillon/:routeType/category',
  sampleSheetEditCertifications: '/echantillon/edition-echantillon/:routeType/certifications',
  sampleSheetEditColor: '/echantillon/edition-echantillon/:routeType/color',
  sampleSheetEditComposition: '/echantillon/edition-echantillon/:routeType/composition',
  sampleSheetEditManufacturingType: '/echantillon/edition-echantillon/:routeType/manufacturingType',
  sampleSheetEditMaterial: '/echantillon/edition-echantillon/:routeType/material',
  sampleSheetEditNumberEnd: '/echantillon/edition-echantillon/:routeType/numberEnd',
  sampleSheetEditPattern: '/echantillon/edition-echantillon/:routeType/pattern',
  sampleSheetEditPatternType: '/echantillon/edition-echantillon/:routeType/patternType',
  sampleSheetEditPerformanceCode: '/echantillon/edition-echantillon/:routeType/performanceCode',
  sampleSheetEditProductDestination:
    '/echantillon/edition-echantillon/:routeType/productDestination',
  sampleSheetEditShooting: '/echantillon/edition-echantillon/:routeType/shooting',
  sampleSheetEditTanningType: '/echantillon/edition-echantillon/:routeType/tanningType',
  sampleSheetEditTechnologyActivity:
    '/echantillon/edition-echantillon/:routeType/technologyActivity',
  sampleSheetEditUnitValue: '/echantillon/edition-echantillon/:routeType/unitValue',
  boxes: '/gestion-colis',
  search: '/recherche',
}
