import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exportSamples = getQueryService({
  query: gql(`
    query exportSamples($filters: SamplesExportInput!) {
      exportSamples(filters: $filters)
    }
`),
  transformer: (response) => {
    return response
  },
})
