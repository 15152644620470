import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormErrors from '../../form/FormErrors'
import Heading from '../../Heading'
import FormFieldTextArea from '../../form/fields/FormFieldTextArea'
import FormFieldScore from '../../form/fields/FormFieldScore'
import FormFieldSwitch from '../../form/fields/FormFieldSwitch'
import FormFieldInputNumber from '../../form/fields/FormFieldInputNumber'
import { breakpoints } from '../../../theme'

export const Container = styled.div``
export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
  ${breakpoints.up('tablet')} {
    gap: 3.6rem;
  }
`
export const Return = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
`
export const Fieldset = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
`
export const Title = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.tundora};
`
export const Subtitle = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
`
export const Text = styled.p`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.gray};
`
export const StyledTextarea = styled(FormFieldTextArea)``
export const StyledScore = styled(FormFieldScore)`
  align-self: center;
  ${breakpoints.up('tablet')} {
    align-self: flex-start;
  }
`
export const StyledSwitch = styled(FormFieldSwitch)`
  & label {
    ${({ theme }) => theme.textStyles.textBook}
    color: ${({ theme }) => theme.colors.tundora};
  }
  padding: 0.4rem;
  border-radius: 0.4rem;
  &.is-on {
    background: ${({ theme }) => theme.colors.blueChalk};
  }
`

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`
export const Required = styled.span`
  color: ${({ theme }) => theme.colors.cinnabar};
  font-size: 16px;
`
export const NumberControl = styled.div`
  ${({ theme }) => theme.textStyles.textBook}
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
`
export const StyledNumber = styled(FormFieldInputNumber)`
  flex: 0 0 auto;
  width: 6rem;
  & input {
    ${({ theme }) => theme.textStyles.titleH4}
  }
`
export const NumberLabel = styled.label`
  ${({ theme }) => theme.textStyles.titleH5}
`
export const Errors = styled(FormErrors)``
