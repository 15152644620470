import React from 'react'
import { RouteProps } from 'react-router-dom'

import SampleDataSmartTemplate from '../../../templates/SampleDataSmart'
import { useSampleDataSmartTemplateProps } from '../../../templates/SampleDataSmart/useProps'

const ExponentSampleDataSmartCreationChoice: React.FC<RouteProps> = () => {
  const templateProps = useSampleDataSmartTemplateProps()
  return <SampleDataSmartTemplate {...templateProps} />
}

export default ExponentSampleDataSmartCreationChoice
