import React from 'react'
import { RouteProps } from 'react-router-dom'

import { usePropsSampleDataExhibitorRefTemplateProps } from '../../../templates/SampleData/usePropsExhibitorRef'
import SampleDataTraineeTemplate from '../../../templates/SampleDataTrainee'

const ExponentSampleDataExhibitorRef: React.FC<RouteProps> = () => {
  const templateProps = usePropsSampleDataExhibitorRefTemplateProps('Référence exposant')
  return <SampleDataTraineeTemplate {...templateProps} />
}

export default ExponentSampleDataExhibitorRef
