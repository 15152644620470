import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleFormByUniverse = getQueryService({
  query: gql(`
    query sampleFormByUniverse(
      $collectionId: ID!
      $universeEbmsId: ID
      $sampleId: ID
    ) {
      sampleFormByUniverse(
        collectionId: $collectionId
        # If universeEbmsId is not specified take universe from collection exhibitor
        universeEbmsId: $universeEbmsId
        # If sampleId is not given ; applyToCollection and checked will always be 'false'
        # otherwise it will be fetched from sample stored data
        sampleId: $sampleId
      ) {
        id
        name
        miraklRef
        responseType
        applyToCollection
        items {
          id
          name
          checked
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
