import { all, put, takeEvery } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'
import { User } from '../../graphql/generated/graphql'

export default class UserSagas {
  static *onInit() {
    yield put(actions.user.resetUser())
  }

  static *onUpdatePassword({ payload }: ReturnType<typeof actions.user.passwordUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.user.mutations.userUpdatePassword, payload)

    if (rs.errors) {
      yield put(actions.user.setSuccessMessage(undefined))
      yield put(actions.user.passwordUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.user.setSuccessMessage('Le mot de passe a bien été modifié'))
      yield put(actions.user.passwordUpdateSuccess())
    }
  }

  static *onUpdateUser({ payload }: ReturnType<typeof actions.user.updateUserRequest>) {
    const rs = yield* ApiSagas.call(services.user.mutations.updateUser, payload)

    if (rs.errors) {
      yield put(actions.user.updateUserError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.user.updateUserSuccess(rs.data.userUpdate as any))
    }
  }

  static *onCreateUser({ payload }: ReturnType<typeof actions.user.createUserRequest>) {
    const rs = yield* ApiSagas.call(services.user.mutations.createUser, payload)

    if (rs.errors) {
      yield put(actions.user.createUserError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.user.createUserSuccess())
    }
  }

  static *onUserDelete({ payload }: ReturnType<typeof actions.user.userDeleteRequest>) {
    const rs = yield* ApiSagas.call(services.user.mutations.userDelete, payload)

    if (rs.errors) {
      yield put(actions.user.userDeleteError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.user.userDeleteSuccess(rs.data.userDelete as User))
    }
  }

  static *onGetUsers({ payload }: ReturnType<typeof actions.user.getUsersRequest>) {
    const rs = yield* ApiSagas.call(services.user.queries.getUsers, payload)
    if (rs.errors) {
      yield put(actions.user.getUsersError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.user.setUsers(rs.data.users.data))
      yield put(actions.user.getUsersSuccess())
    }
  }

  static *listeners() {
    yield all([takeEvery(actions.user.passwordUpdateRequest, this.onUpdatePassword)])
    yield all([takeEvery(actions.user.getUsersRequest, this.onGetUsers)])
    yield all([takeEvery(actions.user.createUserRequest, this.onCreateUser)])
    yield all([takeEvery(actions.user.updateUserRequest, this.onUpdateUser)])
    yield all([takeEvery(actions.user.userDeleteRequest, this.onUserDelete)])
  }
}
