import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import * as SC from './styled'

export type ModalFullScreenProps = {
  className?: string
  isOpen: boolean
  children?: React.ReactNode
  onClose: () => void
  iconsColor?: 'dark' | 'white'
  removeChildrenSpacing?: boolean
  withRightSidebar?: boolean
}

const ModalFullScreen: FC<ModalFullScreenProps> = ({
  className,
  isOpen,
  onClose,
  children,
  removeChildrenSpacing = false,
  iconsColor = 'white',
  withRightSidebar = true,
}) => {
  const theme = useTheme()
  const color = iconsColor === 'dark' ? theme.colors.pureBlack : theme.colors.pureWhite

  return (
    <SC.Container className={className} $isOpen={isOpen} $withRightSidebar={withRightSidebar}>
      <SC.Static>
        <SC.LogoIcon color={color} />
        <SC.CloseButton onClick={onClose}>
          <SC.CloseIcon color={color} />
        </SC.CloseButton>
      </SC.Static>
      <SC.Children $removeSpacing={removeChildrenSpacing}>{children}</SC.Children>
    </SC.Container>
  )
}

export default ModalFullScreen
