import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleAttachShootings = getMutationService({
  mutation: gql(`
    mutation sampleAttachShootings($sampleId: ID!, $uploadPaths: [String!]!) {
      sampleAttachShootings(sampleId: $sampleId, uploadPaths: $uploadPaths) {
            id
            name
            photos {
                id
                user {
                    id
                    email
                }
                url
                path
                order
            }
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
