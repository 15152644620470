import { BottomActionsProps } from './index'

const basic: BottomActionsProps = {
  back: {
    rounded: true,
  },
  note: {
    rounded: true,
    number: '1',
  },
  next: {
    text: 'Suivant',
  },
}

export const bottomActionsMocks = {
  basic,
}
