import React from 'react'
import { RouteProps } from 'react-router-dom'

import LoungesForumChoiceTemplate from '../../../templates/LoungesForumChoice'
import { useLoungeForumsChoiceTemplateProps } from '../../../templates/LoungesForumChoice/useProps'

const LoungesForumsChoice: React.FC<RouteProps> = () => {
  const templateProps = useLoungeForumsChoiceTemplateProps()

  return <LoungesForumChoiceTemplate {...templateProps} />
}

export default LoungesForumsChoice
