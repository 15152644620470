import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { actions, selectors } from '../../redux'
import { AccountFormValues } from '../../components/forms/AccountForm'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { removeAuthCookie } from '../../helpers/CookieHelpers'
import { UserType } from '../../graphql/generated/graphql'

import { AccountTemplateProps } from '.'
import { accountTemplateMocks } from './mocks'

export const useAccountTemplateProps = (): AccountTemplateProps => {
  const dispatch = useDispatch()
  const layoutProps = useDefaultLayoutTemplateProps('account')

  const user = useSelector(selectors.auth.user)
  const config = useSelector(selectors.configuration.configurationInfo)
  const passwordUpdate = useSelector(selectors.user.passwordUpdate)

  const roles = config?.dictionaries.find((item) => item.type === 'USER_ROLES')?.items

  return useMemo(
    () => ({
      ...accountTemplateMocks.basic,
      layout: { ...layoutProps },
      form: {
        state: 'preview',
        formikForm: {
          initialValues: {
            password: '',
            newPassword: '',
            newPasswordConfirm: '',
          },
          validationSchema: Yup.object().shape({
            password: Yup.string()
              .required()
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?-_@#$%^&*])(?=.{12,})/,
                'Mot de passe incorrect'
              ),
            newPassword: Yup.string()
              .required()
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?-_@#$%^&*])(?=.{12,})/,
                'Mot de passe incorrect'
              ),
            newPasswordConfirm: Yup.string()
              .oneOf(
                [Yup.ref('newPassword')],
                'Les deux mots de passe saisis doivent être identiques.'
              )
              .required()
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?-_@#$%^&*])(?=.{12,})/,
                'Mot de passe incorrect'
              )
              .test(
                'passwords-match',
                'Les deux mots de passe saisis doivent être identiques.',
                function (value) {
                  return value === this.parent.newPassword
                }
              ),
          }),
          onSubmit: (values: AccountFormValues) => {
            dispatch(
              actions.user.passwordUpdateRequest({
                oldPassword: values.password,
                newPassword: values.newPassword,
              })
            )
          },
          validateOnChange: true,
        },
        rules: {
          length: {
            isValid: true,
            rule: '12 caractères minimum',
          },
          uppercase: {
            isValid: false,
            rule: 'Majuscules',
          },
          number: {
            isValid: false,
            rule: 'Chiffre',
          },
          specialCharacter: {
            isValid: false,
            rule: 'Caractères spéciaux',
          },
        },
        errors: passwordUpdate.errors,
        submitButton: {
          isPending: passwordUpdate.pending,
          text: 'Valider le nouveau mot de passe',
          pendingText: 'Chargement...',
        },
        fields: {
          password: {
            name: 'password',
            label: 'Mot de passe',
            required: true,
          },
          newPassword: {
            name: 'newPassword',
            label: 'Nouveau mot de passe',
            required: true,
          },
          newPasswordConfirm: {
            name: 'newPasswordConfirm',
            label: 'Confirmer le nouveau mot de passe',
            required: true,
          },
        },
        previewButton:
          user && user.type === UserType.External
            ? {
                text: 'Modifier mon mot de passe',
              }
            : undefined,
        info: {
          email: {
            title: 'Email',
            value: user?.email,
          },
          role: {
            title: 'Mon rôle',
            value: roles && (roles.find((role) => user?.role === role.key)?.value as string),
          },
        },
      },
      logout: {
        ...accountTemplateMocks.basic.logout,
        onClick: () => {
          dispatch(actions.auth.setToken(''))
          dispatch(actions.auth.setUser(null))
          removeAuthCookie()
        },
      },
    }),
    [dispatch, layoutProps, passwordUpdate.errors, passwordUpdate.pending, roles, user]
  )
}
