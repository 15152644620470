import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { FormFieldCheckboxGroupProps } from '../../form/fields/FormFieldCheckboxGroup'

import * as SC from './styled'

export type FiltersSmallValues = {
  [key: string]: Array<string>
}

export type FiltersSmallProps = {
  className?: string
  formikForm: FormikFormProps<FiltersSmallValues>
  field: FormFieldCheckboxGroupProps
  errors?: FormErrorsProps['errors']
}

const FiltersSmall: FC<FiltersSmallProps> = ({ className, formikForm, field, errors }) => {
  return (
    <SC.Form className={className} {...formikForm}>
      <FormikField Component={SC.FieldCheckboxGroup} display={'default'} {...field} />
      {errors && <SC.Errors errors={errors} />}
    </SC.Form>
  )
}

export default FiltersSmall
