import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { Configuration } from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const getConfigurationInfoService = getCustomService<'getConfigurationInfo', null>(
  'getConfigurationInfo'
)

export const exportRedPointsService = getCustomService<'exportRedPoints', null>('exportRedPoints')

export const importExhibitorEbmsService = getCustomService<
  'importExhibitorEbms',
  null,
  { file: File }
>('importExhibitorEbms')

export const uploadService = getCustomService<
  'upload',
  null,
  { sampleId: string; file: File; sampleLabelId?: string }
>('upload')

export const configurationUpdateService = getCustomService<
  'configurationUpdate',
  null,
  { noDaysBeforeSendingToMarketplace: number }
>('configurationUpdate')

//
// Initial state
//

export type ConfigurationState = {
  configurationInfo: Configuration | null
  // services
  getConfigurationInfo: typeof getConfigurationInfoService.state
  exportRedPoints: typeof exportRedPointsService.state
  upload: typeof uploadService.state
  configurationUpdate: typeof configurationUpdateService.state
  importExhibitorEbms: typeof importExhibitorEbmsService.state
}

const initialState: ConfigurationState = {
  configurationInfo: null,
  // services
  getConfigurationInfo: getConfigurationInfoService.state,
  exportRedPoints: exportRedPointsService.state,
  upload: uploadService.state,
  configurationUpdate: configurationUpdateService.state,
  importExhibitorEbms: importExhibitorEbmsService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    resetConfiguration: () => initialState,
    setConfiguration: (state, { payload }: actionTypes.setConfiguration) => {
      state.configurationInfo = payload
    },
    resetExportRedPoints: (state) => {
      state.exportRedPoints = initialState.exportRedPoints
    },
    resetUpload: (state) => {
      state.upload = initialState.upload
    },
    resetImportExhibitorEbms: (state) => {
      state.upload = initialState.upload
      state.importExhibitorEbms = initialState.importExhibitorEbms
    },
    resetConfigurationUpdate: (state) => {
      state.configurationUpdate = initialState.configurationUpdate
    },
    ...getConfigurationInfoService.reducers,
    ...exportRedPointsService.reducers,
    ...uploadService.reducers,
    ...configurationUpdateService.reducers,
    ...importExhibitorEbmsService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const configurationInfo = (state: RootState) => root(state).configurationInfo
const getConfigurationInfo = (state: RootState) => root(state).getConfigurationInfo
const exportRedPoints = (state: RootState) => root(state).exportRedPoints
const upload = (state: RootState) => root(state).upload
const configurationUpdate = (state: RootState) => root(state).configurationUpdate
const importExhibitorEbms = (state: RootState) => root(state).importExhibitorEbms

export const selectors = {
  configurationInfo,
  getConfigurationInfo,
  exportRedPoints,
  upload,
  configurationUpdate,
  importExhibitorEbms,
}
