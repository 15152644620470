import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitionUpdateForum = getMutationService({
  mutation: gql(`
    mutation exhibitionUpdateForum($forumId: ID!, $input: ExhibitionUpdateForumInput!) {
        exhibitionUpdateForum(forumId: $forumId, input: $input) {
            id
            apiId
            name
            seasonCode
            seasonYear
            dateStart
            dateEnd
            dateSampleStart
            dateSampleEnd
            isActive
            forums {
                id
                name
                color
                subForums {
                    id
                    name
                }
            }
            boxes {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
