import styled, { css } from 'styled-components'

import { colors, textStyles } from '../../theme'

export type Variant = keyof typeof textStyles
export type Color = keyof typeof colors

export const Paragraph = styled.p<{ $variant: Variant; $color: Color; $removeMargins?: boolean }>`
  ${({ theme, $variant }) => theme.textStyles[$variant]};
  color: ${({ theme, $color }) => theme.colors[$color]};
  margin-bottom: 1.2rem;

  ${({ $removeMargins }) =>
    $removeMargins &&
    css`
      margin-bottom: 0;
    `};
`
