import React, { FC } from 'react'

import CollectionComment, { CollectionCommentProps } from '../../components/CollectionComment'
import { WarningProps } from '../../components/Warning'
import { NumberedTagProps } from '../../components/NumberedTag'
import { SampleCardProps } from '../../components/SampleCard'
import { SampleChartProps } from '../../components/SampleChart'
import { SampleSelectFormProps } from '../../components/forms/SampleSelectForm'
import LayoutSampleSheet, { LayoutSampleSheetProps } from '../../layouts/LayoutSampleSheet'

import * as SC from './styled'

export type SampleSheetCollectionTemplateProps = {
  layoutProps?: LayoutSampleSheetProps
  pending?: boolean
  comment?: CollectionCommentProps
  statistics?: {
    title?: string
    chart?: SampleChartProps
  }
  samples?: {
    title?: string
    warning?: WarningProps
    numbered?: NumberedTagProps
    list?: SampleCardProps[]
  }
  edit?: SampleSelectFormProps
}

const SampleSheetCollectionTemplate: FC<SampleSheetCollectionTemplateProps> = ({
  layoutProps,
  pending,
  comment,
  statistics,
  samples,
  edit,
}) => {
  return (
    <LayoutSampleSheet {...layoutProps}>
      <SC.Content>
        {pending ? (
          <SC.Loader />
        ) : (
          <>
            {edit && <SC.StyledSampleSelectForm {...edit} />}
            {statistics && (
              <SC.Section>
                {statistics.title && <SC.Title tag="h5" text={statistics.title} />}
                {statistics.chart && <SC.Chart {...statistics.chart} />}
              </SC.Section>
            )}
            <CollectionComment {...comment} />
            {samples && (
              <SC.Section>
                {samples.title && <SC.Title tag="h5" text={samples.title} />}
                {samples.numbered && <SC.StyledNumberedTag {...samples.numbered} />}
                {samples.warning && <SC.StyledWarning {...samples.warning} />}
                {samples.list && samples.list.length > 0 && (
                  <SC.Samples>
                    {samples.list.map((sample, index) => (
                      <SC.Sample key={`Sample-${index}`} {...sample} />
                    ))}
                  </SC.Samples>
                )}
              </SC.Section>
            )}
          </>
        )}
      </SC.Content>
    </LayoutSampleSheet>
  )
}

export default SampleSheetCollectionTemplate
