import React, { FC, ImgHTMLAttributes } from 'react'
import { Maybe } from 'graphql/jsutils/Maybe'

import Paragraph from '../Paragraph'
import { Icons } from '../Icon'

import * as SC from './styled'

export type EditoCardProps = {
  className?: string
  title?: string
  text?: Maybe<string> | undefined
  itemNbr?: string
  itemTitle?: string
  selected?: boolean
  images?: ImgHTMLAttributes<HTMLImageElement>[]
  onClick?: () => void
}

const EditoCard: FC<EditoCardProps> = ({
  className,
  title,
  text,
  itemNbr,
  itemTitle,
  selected,
  images,
  onClick,
}) => {
  return (
    <SC.Card className={className} $selected={selected} onClick={onClick} $isSelectable={!!onClick}>
      <SC.Top>
        {title && <SC.Title text={title} tag="h2" />}
        {text && <SC.Category text={text} tag="h5" />}
      </SC.Top>
      <SC.Bottom>
        {itemNbr && (
          <SC.BottomTextContainer>
            <Paragraph text={itemNbr} variant={'titleH4'} removeMargins />
            <Paragraph text={itemTitle} variant={'titleH5'} color={'tundora'} removeMargins />
          </SC.BottomTextContainer>
        )}
        {images && images.length !== 0 && <SC.BlockImage images={images} />}
      </SC.Bottom>
      {selected && (
        <SC.LogoWrapper>
          <SC.SelectedIcon icon={Icons.check} width={18} height={18} />
        </SC.LogoWrapper>
      )}
    </SC.Card>
  )
}

export default EditoCard
