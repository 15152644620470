import React from 'react'
import { RouteProps } from 'react-router-dom'

import { useForgottenPasswordTemplateProps } from '../../templates/ForgottenPassword/useProps'
import ForgottenPassword from '../../templates/ForgottenPassword'

const ForgottenPasswordPage: React.FC<RouteProps> = () => {
  const templateProps = useForgottenPasswordTemplateProps()

  return <ForgottenPassword {...templateProps} />
}

export default ForgottenPasswordPage
