import React from 'react'
import { RouteProps } from 'react-router-dom'

import ParamsTemplate from '../../templates/Params'
import { useParamsTemplateProps } from '../../templates/Params/useProps'

const Params: React.FC<RouteProps> = () => {
  const templateProps = useParamsTemplateProps()

  return <ParamsTemplate {...templateProps} />
}

export default Params
