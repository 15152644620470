import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RouteType } from '../types/routes'
import Page404 from '../pages/Page404'
import { selectors } from '../redux'
import { UserRole } from '../graphql/generated/graphql'
import HomePage from '../pages/Home'
import PageNoAuthorized from '../pages/NoAuthorized'

import { defaultLayoutRoutes } from './routes'

const childRoutes = (routes: Array<RouteType>, role: UserRole | undefined) =>
  routes.map(({ component: Component, guard, children, path }, index: number) => {
    const Guard = guard || React.Fragment

    return children ? (
      children.map((element, index: number) => {
        const ChildGuard = element.guard || React.Fragment
        const ElementComponent = element.component || React.Fragment

        if (role && element.role && !element.role.includes(role)) {
          return <Route key={index} path={element.path} element={<HomePage />} />
        }

        return (
          <Route
            key={index}
            path={element.path}
            element={
              <Guard>
                <ChildGuard>
                  <ElementComponent />
                </ChildGuard>
              </Guard>
            }
          />
        )
      })
    ) : Component ? (
      role && !role.includes(UserRole.SuperAdmin) ? (
        <Route key={index} path={path} element={<HomePage />} />
      ) : (
        <Route
          key={index}
          path={path}
          element={
            <Guard>
              <Component />
            </Guard>
          }
        />
      )
    ) : null
  })

const RoutesComponents = () => {
  const user = useSelector(selectors.auth.user)

  return (
    <Router>
      <Routes>
        {childRoutes(defaultLayoutRoutes, user?.role)}
        <Route path="/unauthorized" element={<PageNoAuthorized />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  )
}

export default RoutesComponents
