import styled from 'styled-components'

import Heading from '../../components/Heading'
import CollapsibleItem from '../../components/CollapsibleItem'
import Steps from '../../components/Steps'
import Divider from '../../components/Divider'
import { breakpoints, fonts } from '../../theme'
import SimpleRadio from '../../components/SimpleRadio'
import SeasonSelect from '../../components/forms/SeasonSelectForm'
import ContentWrapper from '../../components/ContentWrapper'
import ContentWithSteps from '../../components/ContentWithSteps'

export const PageTitle = styled.h2`
  ${fonts.GothamBold};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 0.7rem 2rem 0 2rem;
  ${breakpoints.up('tablet')} {
    padding-top: 4.4rem;
  }
`

export const Content = styled(ContentWithSteps)``

export const Title = styled(Heading)`
  margin: 2rem 0;
`
export const RadioContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.concrete};
  padding: 1.4rem 1rem;
  margin: 1rem 0 1rem 2.7rem;
`

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const Radio = styled(SimpleRadio)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .text-content {
    text-transform: uppercase;
    ${fonts.GothamBold};
  }
`

export const Select = styled(SeasonSelect)

export const Collapsible = styled(CollapsibleItem)`
  padding: 1rem 0;
  border-top: none;
`

export const StyledSteps = styled(Steps)``

export const StyledDivider = styled(Divider)``
