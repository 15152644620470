import { put } from 'redux-saga/effects'

import { actions } from '..'
import { services } from '../../graphql'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../../helpers/GraphqlHelpers'

export default class MultipleEditionSagas {
  static *onSamplesCreate({ payload }: ReturnType<typeof actions.samples.sampleCreateRequest>) {
    const { samples } = payload
    const promises = samples.map((sample) =>
      ApiSagas.call(services.samples.mutations.sampleCreate, sample)
    )

    const results: ApiResponse<typeof services.samples.mutations.sampleCreate>[] = []

    for (let i = 0; i < promises.length; i++) {
      results.push(yield* promises[i] as any)
      const rs = results[i]
      if (rs.errors) {
        yield put(actions.samples.sampleCreateError(rs.errors))
      } else if (rs.data) {
        yield put(actions.samples.sampleCreateSuccess())
      }
    }
  }

  static *listeners() {
    //yield all([takeEvery(actions.samples.sampleCreateRequest, this.onSamplesCreate)])
  }
}
