import React, { FC } from 'react'

import NoteTag from '../NoteTag'
import { NoteProps } from '../Note'
import { ModalProps } from '../Modal'

import * as SC from './styled'

export type NoteModaleProps = ModalProps & {
  className?: string
  title?: string
  notes?: NoteProps[]
  tags?: {
    newTag?: boolean
    returnTag?: boolean
  }
  onClose?: () => void
}

const NoteModal: FC<NoteModaleProps> = ({ className, title, tags, notes, onClose }) => {
  return (
    <SC.Note className={className}>
      <SC.Head>
        {title && <SC.Title text={title} tag={'h3'} />}
        <SC.Button onClick={onClose}>
          <SC.Close />
        </SC.Button>
      </SC.Head>
      {tags && (
        <SC.NoteTagContainer>
          {tags.newTag && <NoteTag text="Nouvel exposant" variant="new" />}
          {tags.returnTag && <NoteTag text="Échantillons à retourner" variant="return" />}
        </SC.NoteTagContainer>
      )}
      {notes &&
        notes.map((item, key) => (
          <SC.NoteContainer key={key}>
            <SC.Text variant={'titleH5'} color={'gray'} text={item.text} />
            <SC.Bottom>
              {item.author && (
                <SC.Author>
                  <SC.Name text={item.author.fullName} />
                  <SC.Date text={item.author.date} />
                </SC.Author>
              )}
            </SC.Bottom>
          </SC.NoteContainer>
        ))}
    </SC.Note>
  )
}

export default NoteModal
