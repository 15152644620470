import React, { FC } from 'react'

import * as SC from './styled'

export type FormErrorType = {
  label?: string
}

export type FormErrorsProps = {
  className?: string
  errors?: FormErrorType[] | null
}

const FormErrors: FC<FormErrorsProps> = (props) => {
  const { className, errors } = props

  return (
    <SC.Messages className={className}>
      {errors && errors?.map((error) => <SC.Message key={error.label}>{error.label}</SC.Message>)}
    </SC.Messages>
  )
}

export default FormErrors
