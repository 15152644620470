import styled, { css } from 'styled-components'

import { breakpoints } from '../../theme'
import Dot from '../Dot'
import Icon from '../Icon'

export const Card = styled.div<{ $selected?: boolean; $isSelectable: boolean }>`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.concrete};
  border-radius: 0.4rem;
  transition: all 200ms ease-in;

  ${({ $isSelectable }) =>
    $isSelectable &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.colors.grayLight};
      }
    `};

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) => theme.colors.grayLight};
    `};
  ${breakpoints.up('tablet')} {
    padding: 2.4rem;
  }
`

export const Top = styled.div<{ $hasBottom: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $hasBottom }) =>
    $hasBottom &&
    css`
      margin-bottom: 1.2rem;
      box-shadow: 0 1.5px 0 0 ${({ theme }) => theme.colors.mineShaft};
      padding-bottom: 1.2rem;
    `};
`

export const NewIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.eletricViolet};
  background-color: ${({ theme }) => theme.colors.blueChalk};
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`

export const Tag = styled.span`
  ${({ theme }) => theme.textStyles.titleH4};
  color: ${({ theme }) => theme.colors.tundora};
  text-transform: uppercase;
`

export const Name = styled.span`
  ${({ theme }) => theme.textStyles.titleH6};
  color: ${({ theme }) => theme.colors.gray};
  text-transform: uppercase;
`

export const Category = styled.span`
  ${({ theme }) => theme.textStyles.titleH5};
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledDot = styled(Dot)`
  height: 4px;
  width: 4px;
`
