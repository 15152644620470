import React, { FC } from 'react'

import DefaultLayout, { DefaultLayoutProps } from '../../layouts/DefaultLayout'
import ContentWrapper from '../../components/ContentWrapper'
import { AccountFormProps } from '../../components/forms/AccountForm'
import { ActionButtonProps } from '../../components/ActionButton'
import { Icons } from '../../components/Icon'
import Divider from '../../components/Divider'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'

import * as SC from './styled'

export type AccountTemplateProps = {
  className?: string
  title: string
  layout?: DefaultLayoutProps
  form: AccountFormProps
  logout: ActionButtonProps
  message?: string
}

const AccountTemplate: FC<AccountTemplateProps> = ({ layout, title, form, logout, message }) => {
  return (
    <DefaultLayout {...layout}>
      <ContentWrapper>
        <SC.Title tag={'h1'} text={title} />
      </ContentWrapper>
      <Divider />
      <SC.StyledContentWrapper maxWidth={'small'}>
        <SC.Form {...form} />
        {message && <SC.SuccessMessage>{message}</SC.SuccessMessage>}
        <SC.Centered>
          <SC.Logout
            {...logout}
            variantSize={ActionButtonSize.Small}
            variantScheme={ActionButtonScheme.Tertiary}
            rightIconProps={{ icon: Icons.logout, height: 11, width: 11 }}
          />
        </SC.Centered>
      </SC.StyledContentWrapper>
    </DefaultLayout>
  )
}

export default AccountTemplate
