import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { DateRange } from 'react-day-picker'
import dayjs from 'dayjs'

import { actions, selectors } from '../../redux'
import { useLayoutLoungeConfigTemplateProps } from '../../layouts/LayoutLoungeConfig/useProps'
import { router, routesPath } from '../../router'
import { Exhibition } from '../../graphql/generated/graphql'

import { LoungeDatesTemplateProps } from '.'

export const useLoungeDatesSamplesTemplateProps = (): LoungeDatesTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [range, setRange] = useState<DateRange | undefined>(undefined)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [currentMonth, setCurrentMonth] = useState<{ year: number; month: number } | undefined>(
    undefined
  )

  const lounges = useSelector(selectors.exhibitions.exhibitionsSearch)
  const layoutLoungeProps = useLayoutLoungeConfigTemplateProps()
  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)

  useEffect(() => {
    if (loungeConfig) {
      const fromDate = loungeConfig.dateSampleStart
        ? new Date(loungeConfig.dateSampleStart)
        : dayjs().toDate()
      const toDate = loungeConfig.dateSampleEnd
        ? new Date(loungeConfig.dateSampleEnd)
        : dayjs().toDate()
      const dateRangeObject = {
        from: fromDate,
        to: toDate,
      }

      setRange(dateRangeObject)
    }
  }, [loungeConfig])

  const doDateRangesOverlap = (range1: any, range2: any) => {
    const startDate1 = new Date(range1.from)
    const endDate1 = new Date(range1.to)
    const startDate2 = new Date(range2.from)
    const endDate2 = new Date(range2.to)

    return startDate1 <= endDate2 && endDate1 >= startDate2
  }

  // Function to check if the given date range overlaps with any of the items' date ranges
  const doesRangeOverlapWithItems = (rangeToCheck: DateRange, items: Exhibition[]) => {
    for (const item of items) {
      if (item.apiId === loungeConfig?.apiId) return false
      else {
        if (!item.dateSampleStart || !item.dateSampleEnd) {
          continue // Skip items without valid dateStart or dateEnd properties
        }

        const itemDateRange = {
          from: item.dateSampleStart,
          to: item.dateSampleEnd,
        }

        if (doDateRangesOverlap(rangeToCheck, itemDateRange)) {
          return true
        }
      }
    }
    return false
  }

  const isOverlap = range && lounges && doesRangeOverlapWithItems(range, lounges)

  useEffect(() => {
    if (range && range.from) {
      const month = range.from.getUTCMonth()
      const year = range.from.getUTCFullYear()

      setCurrentMonth({ year: year, month: month })
    }
  }, [loungeConfig, range])

  useEffect(() => {
    if (isOverlap || !range) {
      setIsValid(false)
    }
    if (!isOverlap && range) {
      setIsValid(true)
    }
  }, [isOverlap, loungeConfig, range])

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutLoungeProps,
        bottomActions: {
          ...layoutLoungeProps.bottomActions,
          next: {
            ...layoutLoungeProps.bottomActions?.next,
            disabled: !isValid,
            onClick: () => {
              if (range) {
                const updatedLoungeConfig = {
                  ...loungeConfig,
                  dateSampleStart: dayjs(range.from).format('YYYY-MM-DD HH:mm:ss'),
                  dateSampleEnd: dayjs(range.to).format('YYYY-MM-DD HH:mm:ss'),
                }
                dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
                const link = router(routesPath.loungesForums)
                navigate(link)
              }
            },
          },
          back: {
            ...layoutLoungeProps.bottomActions?.back,
            onClick: () => {
              const link = router(routesPath.loungesDates)
              navigate(link)
            },
          },
        },
      },
      title: 'La période de sélection des échantillons',
      loungeCard: {
        title: loungeConfig?.name,
        season: `${loungeConfig?.seasonCode}${loungeConfig?.seasonYear}`,
      },
      error: isOverlap
        ? "Les dates renseignées sont en conflit avec la période de sélection d'un autre salon"
        : undefined,
      calendar: {
        range: range,
        setRange: setRange,
        currentMonth: currentMonth,
      },
    }
  }, [currentMonth, dispatch, isOverlap, isValid, layoutLoungeProps, loungeConfig, navigate, range])
}
