import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { SearchBarProps } from '../../components/SearchBar'
import { TabsProps } from '../../components/Tabs'
import { SampleCardProps } from '../../components/SampleCard'
import { FiltersTagsProps } from '../../components/FiltersTags'
import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import SimpleLoader from '../../components/SimpleLoader'

import * as SC from './styled'

export type SampleSearchTemplateProps = {
  title: string
  noSample: string
  layoutProps?: LayoutWithBottomActionsProps
  searchBar?: SearchBarProps
  tabs: TabsProps
  cards?: SampleCardProps[]
  filtersTags: FiltersTagsProps
  pending?: boolean
  errors: GraphqlErrors | null
}

const SampleSearchTemplate: FC<SampleSearchTemplateProps> = ({
  layoutProps,
  title,
  noSample,
  searchBar,
  tabs,
  cards,
  filtersTags,
  pending,
  errors,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Search {...searchBar} />
        <SC.StyledTabsBottom {...tabs} />
        <SC.Filter {...filtersTags} />
        <SC.ContentContainer>
          {cards?.length !== 0 ? (
            <SC.CardContainer>
              {cards && Object.keys(cards).map((key, idx) => <SC.Card key={key} {...cards[idx]} />)}
            </SC.CardContainer>
          ) : (
            <SC.CardContainer>{noSample}</SC.CardContainer>
          )}
          {errors && <span>{errors.map((error) => error.label).join(', ')}</span>}
        </SC.ContentContainer>
      </ContentWrapper>
      {pending && (
        <SC.LoaderContainer>
          {' '}
          <SimpleLoader />
        </SC.LoaderContainer>
      )}
    </LayoutWithBottomActions>
  )
}

export default SampleSearchTemplate
