import React, { useEffect, useState } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'

import { actions, selectors } from '../../../redux'
import SampleSheetLabelTemplate from '../../../templates/SampleSheetLabel'
import { useSampleSheetLabelTemplateProps } from '../../../templates/SampleSheetLabel/useProps'
import ModalToast from '../../../components/ModalToast'
import { Icons } from '../../../components/Icon'

const SampleSheetLabel: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { sampleId } = useParams<{ sampleId: string }>()
  const sampleUpdate = useSelector(selectors.samples.sampleUpdate)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  useEffect(() => {
    sampleId && dispatch(actions.samples.getSamplePdfRequest({ sampleId }))
  }, [dispatch, sampleId])

  useEffect(() => {
    if (sampleUpdate.success && sampleId) {
      setIsModalVisible(true)
      dispatch(actions.samples.getSampleByIdRequest({ sampleId }))
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.samples.resetSampleUpdate())
      }, 2000)
    }
  }, [dispatch, sampleId, sampleUpdate.success])

  const templateProps = useSampleSheetLabelTemplateProps()

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text={sampleUpdate.success ? i18next.t('exponent.collection.sampleUpdate.success') : ''}
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}{' '}
      <SampleSheetLabelTemplate {...templateProps} />
    </>
  )
}

export default SampleSheetLabel
