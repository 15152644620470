import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormErrors from '../../form/FormErrors'
import Heading from '../../Heading'
import FormFieldTextArea from '../../form/fields/FormFieldTextArea'
import { breakpoints } from '../../../theme'
import ActionButton from '../../ActionButton'

export const Container = styled.div`
  ${breakpoints.up('tablet')} {
    min-width: 350px;
  }
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
  ${breakpoints.up('tablet')} {
    gap: 3.6rem;
  }
`
export const Fieldset = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
`
export const Subtitle = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
`
export const Text = styled.p`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.gray};
`
export const StyledTextarea = styled(FormFieldTextArea)``

export const Errors = styled(FormErrors)``

export const Submit = styled(ActionButton)`
  ${({ theme }) => theme.textStyles.titleH7};
  width: fit-content;
  margin-left: auto;
`

export const Delete = styled(ActionButton)`
  ${({ theme }) => theme.textStyles.titleH7};
  width: fit-content;
  margin: auto;
`
