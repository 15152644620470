import styled from 'styled-components'

import Heading from '../../components/Heading'
import Steps from '../../components/Steps'
import Divider from '../../components/Divider'
import { breakpoints, fonts } from '../../theme'
import LoungeCard from '../../components/LoungeCard'
import CustomCalendar from '../../components/Calendar'
import ContentWrapper from '../../components/ContentWrapper'
import ContentWithSteps from '../../components/ContentWithSteps'

export const PageTitle = styled.h2`
  ${fonts.GothamBold};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-top: 0.7rem;
  ${breakpoints.up('tablet')} {
    padding-top: 4.4rem;
  }
`

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const Content = styled(ContentWithSteps)``

export const Title = styled(Heading)`
  margin-bottom: 1rem;
`

export const StyledLoungeCard = styled(LoungeCard)`
  margin-bottom: 1rem;
`

export const StyledSteps = styled(Steps)``

export const Calendar = styled(CustomCalendar)`
  & .rdp {
    margin: 0;
  }
`

export const StyledDivider = styled(Divider)``

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.cinnabar};
  ${({ theme }) => theme.textStyles.textItalic};
  padding: 12px;
  margin: auto;
  text-align: center;
  max-width: 400px;
`
