import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { actions, selectors } from '../../redux'
import { Sample, SampleStatus, UserRole } from '../../graphql/generated/graphql'
import { sampleStatusFrenchMapping } from '../../types/sampleStatusFrenchMapping'
import { SampleCardProps } from '../../components/SampleCard'
import { BoxInfoProps } from '../../components/BoxInfo'
import { Icons } from '../../components/Icon'
import { router, routesPath } from '../../router'
import { convertMkpToEnum } from '../../relay/SampleSheet/ConvertMkpToEnum'
import { convertMkpToString } from '../../relay/SampleSheet/ConvertMkpToString'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { ActionButtonScheme } from '../../components/ActionButton/types'

import { MultipleScanTemplateProps } from '.'

export const useMultipleEditionScanTemplateProps = (): MultipleScanTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('scan')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [idArr, setIdArr] = useState<string[]>([])
  const [scannedLabel, setScannedLabel] = useState<string | undefined>(undefined)
  const [sampleList, setSampleList] = useState<Sample[]>([])
  const [subTitle, setSubTitle] = useState<string>('')
  const [sampleName, setSampleName] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [boxInfo, setBoxInfo] = useState<BoxInfoProps | undefined>(undefined)
  const [openUpload, setOpenUpload] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [selectedSampleId, setSelectedSampleId] = useState<string>('')
  const user = useSelector(selectors.auth.user)
  const sample = useSelector(selectors.samples.sample)
  const forum = useSelector(selectors.multipleEdition.forum)
  const subForum = useSelector(selectors.multipleEdition.subForum)
  const mkpForum = useSelector(selectors.multipleEdition.mkpForum)
  const editos = useSelector(selectors.multipleEdition.edito)
  const sampleStatus = useSelector(selectors.multipleEdition.sampleStatus)
  const sampleUpdateMany = useSelector(selectors.samples.sampleUpdateMany)
  const editorialAttachSamples = useSelector(selectors.editos.editorialAttachSamples)
  const sampleById = useSelector(selectors.samples.getSampleById)

  useEffect(() => {
    if (scannedLabel) {
      const labelId = extractIdFromUrl(scannedLabel)
      if (!idArr.includes(labelId)) {
        setIdArr((prevState) => [...prevState, labelId])
        dispatch(actions.samples.getSampleByIdRequest({ sampleId: labelId }))
      }
    }
  }, [dispatch, idArr, scannedLabel])

  const lastSample = sampleList[sampleList.length - 1]
  const selectedCount =
    (lastSample &&
      lastSample.collection.samplesStats &&
      (lastSample.collection.samplesStats.find((stat) => stat.status === SampleStatus.Selected)
        ?.count ?? 0) +
        (lastSample.collection.samplesStats.find(
          (stat) => stat.status === SampleStatus.ValidatedOkMarketplace
        )?.count ?? 0) +
        (lastSample.collection.samplesStats.find(
          (stat) => stat.status === SampleStatus.SelectedOkShooting
        )?.count ?? 0)) ||
    0

  const warningDeletedSample =
    (user?.role === UserRole.ModePv ||
      user?.role === UserRole.SuperAdmin ||
      user?.role === UserRole.Admin) &&
    sampleStatus === SampleStatus.Deleted &&
    selectedCount <= 5

  useEffect(() => {
    if (sample) {
      setSampleName(sample.name)
      const isSampleInArray = sampleList.some(
        (obj) => obj.id === sample.id && obj.name === sample.name
      )
      if (!isSampleInArray) {
        setSampleList((prevState) => [...prevState, sample])
        setOpenToast(true)
        setTimeout(() => {
          setOpenToast(false)
        }, 2000)
        dispatch(actions.samples.setSample(undefined))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sample])

  const extractIdFromUrl = (url: string): string => {
    const parts = url.split('/')
    const id = parts[parts.length - 1]
    return id
  }
  const handleDeleteSample = (sampleId: string) => {
    setScannedLabel('')
    setSampleList((prevList) => prevList.filter((sample) => sample.id !== sampleId))
    setIdArr((prevList) => prevList.filter((id) => id !== sampleId))
  }

  useEffect(() => {
    if (
      (sampleUpdateMany && sampleUpdateMany.success) ||
      (editorialAttachSamples && editorialAttachSamples.success)
    ) {
      setSampleList([])
    }
  }, [dispatch, editorialAttachSamples, idArr, navigate, sampleUpdateMany, scannedLabel])

  const updateSamples = useCallback(() => {
    if (sampleList && !editos) {
      const sampleIds: string[] = sampleList.map((sample) => sample.id)
      dispatch(
        actions.samples.sampleUpdateManyRequest({
          sampleIds,
          input: {
            forumId: forum ? forum.id : null,
            subForumId: subForum ? subForum.id : null,
            status: sampleStatus ? sampleStatus : null,
            mkpForum: mkpForum ? convertMkpToEnum(mkpForum) : undefined,
          },
        })
      )
    }
    if (sampleList && editos) {
      const sampleIds: string[] = sampleList.map((sample) => sample.id)
      editos.forEach((edito) => {
        dispatch(
          actions.editos.editorialAttachSamplesRequest({
            editorialId: edito.id,
            samplesIds: sampleIds,
          })
        )
      })
    }
  }, [sampleList, editos, dispatch, forum, subForum, sampleStatus, mkpForum])

  useEffect(() => {
    if (forum && subForum) {
      setText(
        ' Vous êtes sur le point d’appliquer le forum et le sous-forum suivants aux échantillons scannés :'
      )
      setSubTitle('emplacement forum')
      setBoxInfo({
        text: forum.name,
        secondText: subForum.name,
        backgroundColor: getContrastColor(forum.color as string),
        color: forum.color as string,
      })
    }
    if (mkpForum) {
      setText('Vous êtes sur le point de diffuser les échantillons scannés sur :')
      setSubTitle('Présélection PV')
      setBoxInfo({
        text: mkpForum,
        firstdot: true,
      })
    }
    if (sampleStatus) {
      setText('Vous êtes sur le point d’appliquer le statut suivant aux échantillons scannés :')
      setSubTitle('STATUT SÉLECTION')
      setBoxInfo({
        text: sampleStatusFrenchMapping[sampleStatus],
        firstdot: true,
        variant: ['VALIDATED', 'VALIDATED_CHECKED', 'VALIDATED_LABELLED'].includes(sampleStatus)
          ? 'validate'
          : ['SELECTED_OK_SHOOTING', 'SELECTED'].includes(sampleStatus)
          ? 'default'
          : sampleStatus === 'DELETED'
          ? 'delete'
          : undefined,
      })
    }
    if (editos) {
      setText(
        editos.length <= 1
          ? "Vous êtes sur le point d'appliquer l'édito suivant aux échantillons scannés"
          : "Vous êtes sur le point d'appliquer les éditos suivants aux échantillons scannés"
      )
      setSubTitle('EDITO')
    }
  }, [editos, forum, mkpForum, sample, sampleStatus, subForum])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          next: {
            isPending: sampleUpdateMany.pending,
            text: 'Appliquer les changements',
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
            disabled: sampleList.length === 0 || sampleUpdateMany.pending,
            onClick: () => {
              warningDeletedSample ? setOpenDelete(true) : updateSamples()
            },
          },
          nextPlus: {
            text: 'Terminer le scan',
            rightIconProps: undefined,
            variantScheme: ActionButtonScheme.Primary,
            onClick: () => {
              setSampleList([])
              const link = router(routesPath.multipleEdition)
              navigate(link)
            },
          },
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              if (subForum) {
                const link = router(routesPath.multipleEditionSubForum)
                navigate(link)
              }
              if (editos) {
                const link = router(routesPath.multipleEditionEdito)
                navigate(link)
              }
              if (mkpForum) {
                const link = router(routesPath.multipleEditionMkpForum)
                navigate(link)
              }
              if (sampleStatus) {
                const link = router(routesPath.multipleEditionStatut)
                navigate(link)
              }
            },
          },
        },
      },
      subTitle,
      text,
      boxInfo,
      pending: sampleById.pending,
      scannerProps: {
        text: 'Scannez le QR code de l’echantillon',
        onDetect: (result: string) => {
          setScannedLabel(result)
        },
        openError: () => setOpenUpload(true),
      },
      toastModal: {
        text: `L'échantillon ${sampleName} a bien été scanné.`,
        isOpen: openToast,
        icon: {
          icon: Icons.check,
          width: 16,
          height: 16,
        },
      },
      uploadModal: {
        title: 'Caméra indisponible',
        text:
          'Le scan est indisponible sans caméra. Veuillez vérifier dans les paramètres de votre appareil pour l’activer.',
        button: {
          text: 'Retour',
          onClick: () => {
            const link = router(routesPath.home)
            navigate(link)
          },
        },
        uploadHandler: undefined,
        isOpen: openUpload,
        onClose: () => {
          const link = router(routesPath.home)
          navigate(link)
        },
      },
      deleteModal:
        warningDeletedSample && !deleteMode
          ? {
              text: 'Souhaitez-vous tout de même retirer cet échantillon du forum ?',
              warningText: `Attention, le nombre d'échantillons présélectionnés de l'exposant atteindra le seuil limite.`,
              isOpen: openDelete,
              cancelButton: {
                text: 'Annuler',
                onClick: () => setOpenDelete(false),
              },
              deleteButton: {
                text: 'Oui',
                rightIconProps: { icon: Icons.trash, width: 18 },
                onClick: () => {
                  updateSamples()
                  setOpenDelete(false)
                },
              },
            }
          : {
              text: 'Êtes-vous sûr de vouloir supprimer cet échantillon de votre sélection ?',
              isOpen: openDelete,
              cancelButton: {
                text: 'Annuler',
                onClick: () => {
                  setDeleteMode(false)
                  setOpenDelete(false)
                },
              },
              deleteButton: {
                text: 'Oui, supprimer',
                rightIconProps: { icon: Icons.trash, width: 18 },
                onClick: () => {
                  handleDeleteSample(selectedSampleId)
                  setOpenDelete(false)
                },
              },
            },
      cardList: sampleList?.map((sample) => {
        const category =
          sample.flagItems && sample.flagItems.find((item) => item.flagGroupId === '14')
        return {
          title: sample.name,
          category: category && category?.name,
          status: {
            type:
              sample.status === SampleStatus.ValidatedOkMarketplace
                ? SampleStatus.SelectedOkShooting
                : sample.status,
            value:
              sample.status === SampleStatus.ValidatedOkMarketplace
                ? (sampleStatusFrenchMapping[SampleStatus.SelectedOkShooting] as string)
                : (sampleStatusFrenchMapping[sample.status] as string),
          },
          forumColor: sample.forum?.color,
          forum: sample.forum?.name,
          subForum: sample.subForum?.name,
          mkpForum: sample.mkpForum ? convertMkpToString(sample.mkpForum) : undefined,
          okMkp: sample.marketplaceImported,
          noMkp: sample.marketplaceImportError,
          number: sample.id,
          isDelete: sample.isDeleted,
          onDelete: () => {
            setDeleteMode(true)
            setSelectedSampleId(sample.id)
            setOpenDelete(true)
          },
        }
      }) as SampleCardProps[] | undefined,
      editos: editos
        ? editos.map((item) => ({
            title: item.name,
            text: item.description,
          }))
        : undefined,
    }),
    [
      boxInfo,
      deleteMode,
      editos,
      layoutProps,
      mkpForum,
      navigate,
      openDelete,
      openToast,
      openUpload,
      sampleById.pending,
      sampleList,
      sampleName,
      sampleStatus,
      sampleUpdateMany.pending,
      selectedSampleId,
      subForum,
      subTitle,
      text,
      updateSamples,
      warningDeletedSample,
    ]
  )
}
