import styled from 'styled-components'

import Heading from '../../components/Heading'
import CollapsibleItem from '../../components/CollapsibleItem'
import LoungeCard from '../../components/LoungeCard'
import { breakpoints, fonts } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'
import Divider from '../../components/Divider'
import Steps from '../../components/Steps'
import SimpleRadio from '../../components/SimpleRadio'
import ContentWithSteps from '../../components/ContentWithSteps'
import FormFieldRadioGroup from '../../components/form/fields/FormFieldRadioGroup'
import FormikForm from '../../components/form/FormikForm'

export const Content = styled(ContentWithSteps)``

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const StyledSteps = styled(Steps)``
export const StyledRadiosGroup = styled(FormFieldRadioGroup)``

export const PageTitle = styled.h2`
  ${fonts.GothamBold};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-top: 0.7rem;
  ${breakpoints.up('tablet')} {
    padding-top: 4.4rem;
  }
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
  ${breakpoints.up('tablet')} {
    margin-top: 1rem;
  }
`

export const SubTitle = styled.div`
  font-size: 1.7rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.tundora};
`

export const Collapsible = styled(CollapsibleItem)``

export const StyledLoungeCard = styled(LoungeCard)`
  margin-bottom: 1rem;
`

export const StyledDivider = styled(Divider)``

export const Radio = styled(SimpleRadio)`
  background-color: ${({ theme }) => theme.colors.concrete};
  padding: 1rem;
  margin: 2.4rem 0 1.4rem 0;
`

export const FormFields = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const Collapsibles = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.8rem;
`

export const StyledCollapsible = styled(CollapsibleItem)`
  & ${StyledRadiosGroup} {
    margin-top: 1.2rem;
  }
  & ${StyledRadiosGroup} label {
    //padding-right: 0;
  }
`

export const Form = styled(FormikForm)`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
