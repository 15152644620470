import React, { FC } from 'react'

import { SubForumList, SubForum } from '../LoungeSubForumEdit'
import Dot from '../../components/Dot'
import LayoutLoungeConfig, { LayoutLoungeConfigProps } from '../../layouts/LayoutLoungeConfig'

import * as SC from './styled'

export type LoungesResumeTemplateProps = {
  layoutProps?: LayoutLoungeConfigProps
  title?: string
  resume?: { title: string; text: string }[]
  checkBoxTitle?: string
  subForumList?: SubForumList[]
  error?: string
}

const LoungesResumeTemplate: FC<LoungesResumeTemplateProps> = ({
  layoutProps,
  title,
  resume,
  checkBoxTitle,
  subForumList,
  error,
}) => {
  return (
    <LayoutLoungeConfig {...layoutProps}>
      <SC.Content>
        {title && <SC.Title tag={'h2'} text={title} />}
        {resume &&
          resume.map((item, key) => {
            return (
              <SC.Resume key={key}>
                <SC.ResumeTitle>{item.title}</SC.ResumeTitle>
                <SC.ResumeText>{item.text}</SC.ResumeText>
              </SC.Resume>
            )
          })}
        <SC.ResumeSubForum>
          <SC.ResumeTitle>{checkBoxTitle}</SC.ResumeTitle>
          {subForumList &&
            subForumList.map((item: SubForumList, key: number) => (
              <SC.Collapsible
                key={key}
                {...item}
                title={item.name}
                backgroundColor={item.backgroundColor}
                itemNbr={item.subForums ? item.subForums.length.toString() : 'Aucun '}
              >
                {item.subForums &&
                  item.subForums.map((subItem: SubForum, key: number) => (
                    <SC.SubForum key={key}>
                      <Dot />
                      {subItem.name}
                    </SC.SubForum>
                  ))}
              </SC.Collapsible>
            ))}
        </SC.ResumeSubForum>
        {error && <SC.Error>{error}</SC.Error>}
      </SC.Content>
    </LayoutLoungeConfig>
  )
}

export default LoungesResumeTemplate
