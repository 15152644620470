import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { ActionButtonScheme } from '../../components/ActionButton/types'
import { filtersMocks } from '../../components/Filters/mocks'
import { filtersTagsMocks } from '../../components/FiltersTags/mocks'
import { SampleCardProps } from '../../components/SampleCard'
import { sampleStatusFrenchMapping } from '../../types/sampleStatusFrenchMapping'
import { convertMkpToString } from '../../relay/SampleSheet/ConvertMkpToString'
import { Sample, SampleStatus, UserRole } from '../../graphql/generated/graphql'
import { FiltersFormValues } from '../../components/forms/FiltersForm'
import { findTagsFiltersInDictionaries } from '../../components/Filters/utils'
import { FilterTagProps } from '../../components/FilterTag'

import { EditoEditSheetFormValues, EditoSheetTemplateProps } from '.'

export const useEditoSheetTemplateProps = (
  tags: FilterTagProps[],
  handleFiltersClick: () => void,
  universe: string | undefined,
  currentPage: number,
  filters: FiltersFormValues | undefined
): EditoSheetTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { editoId } = useParams<{ editoId: string }>()
  const { apiId } = useParams<{ apiId: string }>()
  const layoutProps = useDefaultLayoutTemplateProps(undefined)
  const user = useSelector(selectors.auth.user)

  const [editMode, setEditMode] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [searchMode, setSearchMode] = useState<boolean>(false)
  const [deleteMode, setDeleteMode] = useState<boolean>(false)
  const [deleteEditoMode, setDeleteEditoMode] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalToastVisible, setModalToastVisible] = useState<boolean>(false)
  const [parsedSamples, setParsedSamples] = useState<SampleCardProps[] | undefined>(undefined)
  const [selectedSamples, setSelectedSamples] = useState<string[]>([])
  const [selectedDeleteSamples, setSelectedDeleteSamples] = useState<string[]>([])

  const edito = useSelector(selectors.editos.editorial)
  const samples = useSelector(selectors.samples.samples)
  const config = useSelector(selectors.configuration.configurationInfo)
  const sampleFilters = useSelector(selectors.samples.sampleFilters)
  const attachSamples = useSelector(selectors.editos.editorialAttachSamples)
  const exportEdito = useSelector(selectors.editos.exportEditorials)
  const detachSamples = useSelector(selectors.editos.editorialDetachSamples)
  const updateEdito = useSelector(selectors.editos.editorialUpdate)
  const deleteEdito = useSelector(selectors.editos.editorialDelete)
  const ref = useRef<FormikProps<EditoEditSheetFormValues>>(null)

  useEffect(() => {
    editoId &&
      dispatch(
        actions.editos.editorialByIdRequest({
          editorialId: editoId,
        })
      )
  }, [dispatch, editoId, attachSamples.success, detachSamples.success, updateEdito.success])

  useEffect(() => {
    if (attachSamples && attachSamples.success) {
      setModalToastVisible(true)
      setSearchMode(false)
      setTimeout(() => {
        setModalToastVisible(false)
        dispatch(actions.editos.resetEditorialAttachSamples())
        setSelectedSamples([])
      }, 2000)
    }
    if (detachSamples && detachSamples.success) {
      setModalToastVisible(true)
      setDeleteMode(false)
      setTimeout(() => {
        setSelectedDeleteSamples([])
        setModalToastVisible(false)
        dispatch(actions.editos.resetEditorialDetachSamples())
      }, 2000)
    }
    if (updateEdito && updateEdito.success) {
      setModalToastVisible(true)
      setEditMode(false)
      setTimeout(() => {
        setModalToastVisible(false)
        dispatch(actions.editos.resetEditorialUpdate())
      }, 2000)
    }
    if (exportEdito && (exportEdito.success || exportEdito.errors)) {
      setModalToastVisible(true)
      setTimeout(() => {
        setModalToastVisible(false)
        dispatch(actions.editos.resetExportEditorials())
      }, 2000)
    }
    if (deleteEdito && deleteEdito.success) {
      const link = router(routesPath.exhibitionSheetEdito, {
        exhibitionId: edito?.exhibition.apiId as string,
      })
      navigate(link)
    }
  }, [
    attachSamples,
    deleteEdito,
    detachSamples,
    dispatch,
    edito?.exhibition.apiId,
    editoId,
    exportEdito,
    navigate,
    updateEdito,
  ])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  const searchAndFilterSamples = useCallback(() => {
    if (sampleFilters) {
      const data = sampleFilters || []
      const { forums } = data
      const filterParsedCall = findTagsFiltersInDictionaries(tags, config?.dictionaries)
      const countriesInFilters: string[] =
        filterParsedCall.find((item) => item.type === 'COUNTRIES')?.filters || []
      const pavilionsEbmsIds =
        filterParsedCall.find((item) => item.type === 'PAVILIONS')?.filters || []
      const forumsInFilters: string[] | undefined =
        filters &&
        filters.Forum &&
        filters.Forum.map((forum) => {
          const matchingKey = Object.keys(forums).find((key) => forums[key] === forum.trim())
          return matchingKey || ''
        })
      const filteredForumsInFilters =
        forumsInFilters && forumsInFilters.filter((forum) => forum !== '')
      const filteredCountriesInFilters = countriesInFilters.filter((country) => country !== '')
      const findKeysForValues = (searchValues: FilterTagProps[]) => {
        const foundKeys: string[] = []
        //@ts-ignore
        Object.entries(data).forEach(([category, items]) => {
          if (
            category !== 'countries' &&
            category !== 'exhibitions' &&
            category !== 'pavilions' &&
            category !== 'univers' &&
            category !== 'secteur' &&
            category !== 'forums'
          ) {
            const itemsRecord: Record<string, unknown> = items as Record<string, unknown>
            Object.entries(itemsRecord).forEach(([itemId, itemName]) => {
              searchValues.forEach((value: FilterTagProps) => {
                if (value.text && value.text.includes(itemName as string)) {
                  foundKeys.push(itemId)
                }
              })
            })
          }
        })

        return foundKeys
      }
      apiId &&
        dispatch(
          actions.samples.getSamplesRequest({
            q: search,
            page: currentPage,
            universesEbmsIds: universe ? [universe] : undefined,
            countries: filteredCountriesInFilters,
            pavilionsEbmsIds,
            exhibitionsApiIds: [apiId],
            forumsIds: filteredForumsInFilters,
            flagItems: findKeysForValues(tags),
            first: 10,
          })
        )
    }
  }, [
    apiId,
    config?.dictionaries,
    currentPage,
    dispatch,
    filters,
    sampleFilters,
    search,
    tags,
    universe,
  ])

  useEffect(() => {
    const parseSamplesCards = (samples: Sample[] | undefined, appendCards: boolean) => {
      const newCards: SampleCardProps[] = []
      samples?.forEach((sample: Sample) => {
        const category =
          sample.flagItems && sample.flagItems.find((item) => item.group.miraklRef === 'category')
        const card: SampleCardProps = {
          title: sample.name,
          category: category && category?.name,
          selected: selectedSamples.includes(sample.id),
          status: {
            type:
              sample.status === SampleStatus.ValidatedOkMarketplace
                ? SampleStatus.SelectedOkShooting
                : sample.status,
            value:
              sample.status === SampleStatus.ValidatedOkMarketplace
                ? (sampleStatusFrenchMapping[SampleStatus.SelectedOkShooting] as string)
                : (sampleStatusFrenchMapping[sample.status] as string),
          },
          forumColor: sample.forum?.color ? sample.forum?.color : '',
          forum: sample.forum?.name,
          subForum: sample.subForum?.name,
          mkpForum: sample.mkpForum ? convertMkpToString(sample.mkpForum) : undefined,
          number: sample.id,
          isDelete: sample.isDeleted,
          okMkp: sample.marketplaceImported,
          noMkp: sample.marketplaceImportError,
          image:
            sample.photos && sample.photos[0]
              ? {
                  src: sample.photos[0].url,
                  alt: sample.photos[0].id,
                }
              : undefined,

          onClick: () => {
            setSelectedSamples((prevSelectedSamples) => {
              if (prevSelectedSamples.includes(sample.id)) {
                return prevSelectedSamples.filter((id) => id !== sample.id)
              } else {
                return [...prevSelectedSamples, sample.id]
              }
            })
          },
        }
        newCards.push(card)
      })

      setParsedSamples((prevCards) => {
        if (!appendCards) {
          const uniqueCards = newCards.filter((newCard) =>
            prevCards?.every(
              (prevCard) =>
                prevCard.number !== undefined &&
                newCard.number !== undefined &&
                prevCard.number !== newCard.number
            )
          )
          return prevCards ? [...prevCards, ...uniqueCards] : uniqueCards
        } else {
          return newCards
        }
      })
    }

    if (samples && currentPage === 1) {
      parseSamplesCards(samples, true)
    } else if (samples) {
      parseSamplesCards(samples, false)
    }
  }, [currentPage, dispatch, samples, selectedSamples])

  useEffect(() => {
    searchAndFilterSamples()
  }, [search, searchAndFilterSamples])

  const returnDeleteMessage = useCallback(() => {
    const plural: boolean = selectedDeleteSamples.length > 1
    if (deleteMode) {
      return plural
        ? `Les échantillons sélectionnés vont être définitivement supprimés de l'édito ${edito?.name}.`
        : `L'échantillon sélectionné va être définitivement supprimé de l'édito ${edito?.name}.`
    }
    if (deleteEditoMode) {
      return `L'edito ${edito?.name} va être définitivement supprimé.`
    }
    if (edito && !deleteMode && !deleteEditoMode) {
      return `${selectedSamples.length} ${
        selectedSamples.length === 1 ? 'nouvel' : 'nouveaux'
      } échantillon${selectedSamples.length === 1 ? '' : 's'} ${
        selectedSamples.length === 1 ? 'va' : 'vont'
      } être ajouté${selectedSamples.length === 1 ? '' : 's'} à l'édito: ${edito?.name}.`
    } else return ''
  }, [selectedDeleteSamples.length, deleteMode, deleteEditoMode, edito, selectedSamples.length])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        title: edito?.name || '',
        subTitle: edito?.description || '',
        bottomActions: {
          next:
            user?.role === UserRole.Admin ||
            user?.role === UserRole.SuperAdmin ||
            user?.role === UserRole.Marketplace ||
            user?.role === UserRole.ModePv
              ? searchMode &&
                (user?.role === UserRole.Admin ||
                  user?.role === UserRole.SuperAdmin ||
                  user?.role === UserRole.ModePv)
                ? {
                    text: "Ajouter à l'édito",
                    rightIconProps: {
                      icon: Icons.plus,
                      width: 18,
                      height: 18,
                    },
                    onClick: () => {
                      setModalVisible(true)
                    },
                    disabled: selectedSamples.length === 0,
                  }
                : editMode &&
                  (user?.role === UserRole.Admin ||
                    user?.role === UserRole.SuperAdmin ||
                    user?.role === UserRole.ModePv)
                ? {
                    text: 'Valider',
                    rightIconProps: {
                      icon: Icons.check,
                      width: 18,
                      height: 18,
                    },
                    onClick: () => {
                      ref.current?.submitForm()
                    },
                  }
                : {
                    text: 'Exporter la liste',
                    rightIconProps: {
                      icon: Icons.export,
                      width: 18,
                      height: 18,
                    },
                    onClick: () => {
                      config &&
                        editoId &&
                        dispatch(
                          actions.editos.exportEditorialsRequest({
                            editorialId: String(editoId),
                          })
                        )
                    },
                  }
              : undefined,
          back: searchMode
            ? {
                leftIconProps: {
                  icon: Icons.arrowLeft,
                  width: 18,
                  height: 18,
                },
                onClick: () => {
                  setSearchMode(false)
                  setSelectedSamples([])
                },
              }
            : deleteMode
            ? {
                leftIconProps: {
                  icon: Icons.arrowLeft,
                  width: 18,
                  height: 18,
                },
                onClick: () => {
                  setDeleteMode(false)
                  setSelectedDeleteSamples([])
                },
              }
            : {
                leftIconProps: {
                  icon: Icons.arrowLeft,
                  width: 18,
                  height: 18,
                },
                onClick: () => {
                  if (editMode) setEditMode(false)
                  else {
                    const link = router(routesPath.exhibitionSheetEdito, {
                      exhibitionId: edito?.exhibition.apiId as string,
                    })
                    navigate(link)
                  }
                },
              },
        },
        toastModal: {
          text:
            attachSamples && attachSamples.success
              ? `L${selectedSamples.length === 1 ? "'" : 'es '}échantillon${
                  selectedSamples.length === 1 ? '' : 's'
                } ${selectedSamples.length === 1 ? 'a' : 'ont'} bien été ajouté${
                  selectedSamples.length === 1 ? '' : 's'
                } à l'édito.`
              : detachSamples && detachSamples.success
              ? selectedDeleteSamples.length > 1
                ? "Les échantillons ont bien été supprimés de l'édito."
                : "L'échantillon a bien été supprimé de l'édito."
              : exportEdito && (exportEdito.success || exportEdito.errors)
              ? "L'export de la liste a bien été envoyé à l'e-mail lié à ce compte."
              : "L'édito a bien été édité.",
          isOpen: modalToastVisible,
          icon: {
            icon: Icons.check,
            width: 16,
            height: 16,
          },
        },
        deleteModal: {
          isOpen: modalVisible,
          text: returnDeleteMessage(),
          cancelButton: {
            text: 'Annuler',
            onClick: () => setModalVisible(false),
            rightIconProps: undefined,
          },
          deleteButton: {
            text: deleteMode || deleteEditoMode ? 'Oui, supprimer' : "Oui, ajouter à l'édito",
            onClick: () => {
              if (edito && !deleteMode && !deleteEditoMode) {
                dispatch(
                  actions.editos.editorialAttachSamplesRequest({
                    editorialId: edito?.id,
                    samplesIds: selectedSamples,
                  })
                )
              }
              if (edito && deleteMode && !deleteEditoMode) {
                dispatch(
                  actions.editos.editorialDetachSamplesRequest({
                    editorialId: edito?.id,
                    samplesIds: selectedDeleteSamples,
                  })
                )
              }
              if (edito && deleteEditoMode && !deleteMode) {
                dispatch(
                  actions.editos.editorialDeleteRequest({
                    editorialId: edito?.id,
                  })
                )
              }

              setModalVisible(false)
            },
            rightIconProps: {
              icon: deleteMode || deleteEditoMode ? Icons.trash : Icons.plus,
              height: 18,
              width: 18,
            },
          },
        },
        editButton:
          !searchMode &&
          !editMode &&
          user?.role !== UserRole.Rex &&
          user?.role !== UserRole.Marketplace
            ? {
                text: "Éditer les informations de l'édito",
                onClick: () => setEditMode(true),
                variantScheme: ActionButtonScheme.Tertiary,
              }
            : undefined,
      },
      subTitle: 'Ajouter des échantillons',
      searchButton: {
        text: 'Rechercher',
        variantScheme: ActionButtonScheme.Secondary,
        rightIconProps: { icon: Icons.search, height: 18, width: 18 },
        onClick: () => setSearchMode(true),
      },
      scanButton: {
        text: 'Scan',
        variantScheme: ActionButtonScheme.Secondary,
        rightIconProps: { icon: Icons.scan, height: 18, width: 18 },
        onClick: () => {
          const link = router(routesPath.multipleEditionEdito)
          navigate(link)
        },
      },
      userAdmin:
        user?.role === UserRole.Admin ||
        user?.role === UserRole.SuperAdmin ||
        user?.role === UserRole.ModePv,
      searchBar:
        user?.role === UserRole.Admin ||
        user?.role === UserRole.SuperAdmin ||
        user?.role === UserRole.ModePv
          ? searchMode
            ? {
                placeholder: 'Recherche',
                value: search,
                onChange: handleSearchChange,
              }
            : undefined
          : undefined,
      filtersTags: {
        text: 'Filtrer',
        filters: { ...filtersMocks.basic, isOpen: false },
        tags: tags.map((tag) => ({ text: tag.text, category: tag.category })),
        button: { ...filtersTagsMocks.basic.button, onClick: handleFiltersClick },
      },
      cards:
        parsedSamples &&
        (parsedSamples
          .map((sample) => ({
            ...sample,
            selected: selectedSamples.includes(sample.number as string),
          }))
          .filter((sample) => {
            if (edito) {
              const filterIds = edito?.samples.map((filter) => filter.id)
              return !filterIds.includes(sample.number as string)
            }
            return true // Si edito est falsy, ne pas appliquer le filtre
          }) as SampleCardProps[]),
      selectedCards:
        edito && edito.samples && edito.samples.length > 0
          ? edito.samples.map((sample: Sample) => {
              const category =
                sample.flagItems &&
                sample.flagItems.find((item) => item.group.miraklRef === 'category')
              return {
                title: sample.name,
                category: category && category?.name,
                status: { type: sample.status, value: sampleStatusFrenchMapping[sample.status] },
                forumColor: sample.forum?.color ? sample.forum?.color : '',
                forum: sample.forum?.name,
                subForum: sample.subForum?.name,
                mkpForum: sample.mkpForum ? convertMkpToString(sample.mkpForum) : undefined,
                number: sample.id,
                selectMode: deleteMode && !selectedDeleteSamples.includes(sample.id),
                okMkp: sample.marketplaceImported,
                noMkp: sample.marketplaceImportError,
                onDelete:
                  deleteMode && selectedDeleteSamples.includes(sample.id)
                    ? () => console.log(sample.id)
                    : undefined,
                onClick: deleteMode
                  ? () => {
                      if (selectedDeleteSamples.includes(sample.id)) {
                        setSelectedDeleteSamples(
                          selectedDeleteSamples.filter((id) => id !== sample.id)
                        )
                      } else {
                        setSelectedDeleteSamples([...selectedDeleteSamples, sample.id])
                      }
                    }
                  : () => {
                      const link = router(routesPath.sampleSheetData, {
                        sampleId: sample.id,
                      })
                      navigate(link)
                    },
                image:
                  sample.photos && sample.photos[0]
                    ? {
                        src: sample.photos[0].url,
                        alt: sample.photos[0].id,
                      }
                    : undefined,
              }
            })
          : [],
      deleteButton:
        user?.role === UserRole.Admin ||
        user?.role === UserRole.SuperAdmin ||
        user?.role === UserRole.ModePv
          ? {
              text: "Supprimer des échantillons de l'édito",
              onClick: () => (!deleteMode ? setDeleteMode(true) : setModalVisible(true)),
              variantScheme: deleteMode ? ActionButtonScheme.Primary : ActionButtonScheme.Tertiary,
            }
          : undefined,
      deleteEditoButton: {
        text: "Supprimer l'édito",
        onClick: () => {
          setDeleteEditoMode(true)
          setModalVisible(true)
        },
        variantScheme: deleteMode ? ActionButtonScheme.Primary : ActionButtonScheme.Tertiary,
      },
      editMode: editMode,
      editForm: {
        title: 'Modifier un edito',
        formikForm: {
          innerRef: ref,
          initialValues: {
            title: edito?.name,
            description: edito?.description,
          } as EditoEditSheetFormValues,
          validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            description: Yup.string()
              .required('La description est requise')
              .test('maxCharCount', ' 255 caractères maximum', (value) => {
                if (value) {
                  return value.length <= 255
                }
                return true
              }),
          }),
          onSubmit: (values) => {
            edito &&
              dispatch(
                actions.editos.editorialUpdateRequest({
                  editorialId: edito.id,
                  input: { name: values.title, description: values.description },
                })
              )
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          title: {
            label: 'Titre',
            props: {
              label: undefined,
              name: 'title',
              placeholder: 'Titre',
              required: true,
            },
          },
          description: {
            label: 'Description édito',
            props: {
              label: undefined,
              placeholder: 'Description édito',
              name: 'description',
              required: true,
            },
          },
        },
      },
    }),
    [
      attachSamples,
      config,
      deleteEditoMode,
      deleteMode,
      detachSamples,
      dispatch,
      editMode,
      edito,
      editoId,
      exportEdito,
      handleFiltersClick,
      layoutProps,
      modalToastVisible,
      modalVisible,
      navigate,
      parsedSamples,
      returnDeleteMessage,
      search,
      searchMode,
      selectedDeleteSamples,
      selectedSamples,
      tags,
      user?.role,
    ]
  )
}
