import React, { FC } from 'react'

import DefaultLayout, { DefaultLayoutProps } from '../../layouts/DefaultLayout'
import { ColoredLinkBlockProps } from '../../components/ColoredLinkBlock'

import * as SC from './styled'

export type HomeTemplateProps = {
  className?: string
  layout?: DefaultLayoutProps
  title: string
  blocks: Array<ColoredLinkBlockProps>
}

const HomeTemplate: FC<HomeTemplateProps> = ({ layout, title, blocks }) => {
  return (
    <DefaultLayout {...layout}>
      <SC.Content>
        <SC.Title text={title} />
        <SC.List>
          {blocks.map((block, idx) => (
            <SC.Block key={`block-${idx}`} {...block} href={block.href} />
          ))}
        </SC.List>
      </SC.Content>
    </DefaultLayout>
  )
}

export default HomeTemplate
