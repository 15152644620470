import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { actions } from '../../../redux'
import MultipleEdition from '../../../templates/MultipleEdition'
import { useMultipleEditionTemplateProps } from '../../../templates/MultipleEdition/useProps'

const MultipleEditionMenu: React.FC<RouteProps> = () => {
  const templateProps = useMultipleEditionTemplateProps()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.multipleEdition.resetMultipleEdition())
  }, [dispatch])

  return (
    <>
      <MultipleEdition {...templateProps} />
    </>
  )
}

export default MultipleEditionMenu
