import { Icons } from '../../Icon'

import { FiltersFormProps } from './index'

const basic: FiltersFormProps = {
  formikForm: {
    initialValues: {
      univers: [],
      sectors: [],
      countries: [],
    },
    onSubmit: (values) => console.log('values', values),
    validateOnChange: true,
  },
  formikFormUniverses: {
    initialValues: {
      universe: '',
    },
    onSubmit: (values) => console.log('values', values),
    validateOnChange: true,
  },
  fields: [
    {
      name: 'univers',
      label: 'Univers',
      required: true,
      displayLabel: false,
      options: [
        {
          label: 'Fabrics',
          value: 'fabrics',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Leather',
          value: 'leather',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Accessoires',
          value: 'accessoire',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Yarns',
          value: 'yarns',
          exponent: '4028',
          display: 'simple',
        },
      ],
    },
    {
      name: 'sectors',
      label: 'Secteur',
      required: true,
      displayLabel: false,
      options: [
        {
          label: 'Fabrics',
          value: 'fabrics',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Leather',
          value: 'leather',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Accessoires',
          value: 'accessoire',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Yarns',
          value: 'yarns',
          exponent: '4028',
          display: 'simple',
        },
      ],
    },
    {
      name: 'countries',
      label: 'Pays de l’exposant',
      required: true,
      displayLabel: false,
      options: [
        {
          label: 'Inde',
          value: 'inde',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Indonésie',
          value: 'indonesie',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Italie',
          value: 'italia',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Yarns',
          value: 'yarns',
          exponent: '4028',
          display: 'simple',
        },
        {
          label: 'Japon',
          value: 'japan',
          exponent: '4028',
          display: 'simple',
        },
      ],
    },
  ],
  resetButton: {
    text: 'Réinitialiser les filtres',
    onClick: () => console.log('reset'),
  },
  submitButton: {
    text: 'Voir les 3 résultats',
    rightIconProps: { icon: Icons.arrowRight, width: 20 },
  },
}

export const filtersFormMocks = {
  basic,
}
