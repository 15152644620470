import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const userUpdatePassword = getMutationService({
  mutation: gql(`
    mutation userUpdatePassword($oldPassword: String!, $newPassword: String!) {
      userUpdatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
        id
        type
        role
        email
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
