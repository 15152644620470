import React, { FC } from 'react'

import { IconProps } from '../Icon'
import { LinkProps } from '../Link'

import * as SC from './styled'
import { ActionButtonScheme, ActionButtonSize } from './types'

export type ActionButtonProps = LinkProps & {
  text?: string
  leftIconProps?: IconProps
  rightIconProps?: IconProps
  variantSize?: ActionButtonSize
  variantScheme?: ActionButtonScheme
  rounded?: boolean
  disabled?: boolean
  isPending?: boolean
  children?: React.ReactNode
}

const ActionButton: FC<ActionButtonProps> = ({
  text,
  children,
  leftIconProps,
  rightIconProps,
  variantSize = ActionButtonSize.Default,
  variantScheme = ActionButtonScheme.Primary,
  rounded,
  isPending,
  ...linkProps
}) => {
  const reallyRounded =
    rounded === true ? true : !children && !text && (leftIconProps || rightIconProps) ? true : false

  return (
    <SC.ActionButton
      $size={variantSize}
      $scheme={variantScheme}
      $rounded={reallyRounded}
      $isPending={isPending}
      {...linkProps}
    >
      {leftIconProps && <SC.ActionIcon {...leftIconProps} />}
      {(children || text) && (
        <SC.Label $isTextOnly={!children && text ? true : false}>{children || text}</SC.Label>
      )}
      {rightIconProps && <SC.ActionIcon {...rightIconProps} />}
    </SC.ActionButton>
  )
}

export default ActionButton
