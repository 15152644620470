import { SampleStatus } from '../graphql/generated/graphql'

export const sampleStatusFrenchMapping = {
  [SampleStatus.Deleted]: 'Retiré du forum',
  [SampleStatus.Selected]: 'Présélectionné',
  [SampleStatus.SelectedOkShooting]: 'Ok shooting',
  [SampleStatus.Validated]: 'Validé forum',
  [SampleStatus.ValidatedChecked]: 'Sélection validée - Ok vérif',
  [SampleStatus.ValidatedLabelled]: 'Sélection validée - Ok étiquette',
  [SampleStatus.ValidatedOkMarketplace]: 'Ok Marketplace',
}
