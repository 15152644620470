import React, { FC } from 'react'

import * as SC from './styled'

export type DividerProps = {
  className?: string
}

const Divider: FC<DividerProps> = ({ className }) => {
  return <SC.Divider className={className} />
}

export default Divider
