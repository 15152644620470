import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const internalNoteCreate = getMutationService({
  mutation: gql(`
    mutation internalNoteCreate(
      $commentableType: InternalNoteCommentableType!
      $commentableId: ID!
      $body: String!
    ) {
      internalNoteCreate(
        commentableType: $commentableType
        commentableId: $commentableId
        body: $body
      ) {
        id
        user {
          id
          email
        }
        body
        writtenAt
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
