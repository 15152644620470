import React, { FC, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'

import * as SC from './styled'

export type RichTextEditorProps = {
  className?: string
  title?: string
  initialValue: any
  onChange?: (newContent: string) => void
}

const RichTextEditor: FC<RichTextEditorProps> = ({ className, title, initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue)
  const quillRef = useRef<ReactQuill | null>(null)
  const handleChange = (content: any) => {
    setValue(content)
    onChange && onChange(content)
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <SC.Content className={className}>
      {title && <SC.Title text={title} tag="h5" />}
      <SC.RichTextHeader>
        <SC.Logo />
      </SC.RichTextHeader>
      <SC.StyledReactQuill
        value={value}
        ref={quillRef}
        onChange={handleChange}
        formats={['bold', 'italic', 'underline', 'link', 'list', 'bullet']}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'link'], // Options de format
            [{ list: 'bullet' }, { list: 'ordered' }], // Boutons pour les puces et les listes
            ['clean'], // Bouton pour supprimer le format (enlever un lien)
          ],
        }}
      />
      <SC.RichTextFooter>©Première Vision - Tous droits réservés</SC.RichTextFooter>
    </SC.Content>
  )
}

export default RichTextEditor
