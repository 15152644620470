import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { StepsProps } from '../../components/Steps'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'

import * as SC from './styled'

export type LayoutLoungeConfigProps = LayoutWithBottomActionsProps & {
  title?: string
  steps?: StepsProps
  children?: React.ReactNode
  deleteModal?: ModalDeleteProps
}

const LayoutLoungeConfig: FC<LayoutLoungeConfigProps> = ({
  title,
  steps,
  children,
  deleteModal,
  ...layoutProps
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={<>{deleteModal && <ModalDelete {...deleteModal} />}</>}
    >
      <SC.TopContent>{title && <SC.Title tag={'h4'} text={title} />}</SC.TopContent>
      <SC.Separator />
      <SC.Wrapper>
        {steps ? <SC.StyledSteps {...steps} /> : <SC.Separator />}
        {children && <SC.Content>{children}</SC.Content>}
      </SC.Wrapper>
    </LayoutWithBottomActions>
  )
}

export default LayoutLoungeConfig
