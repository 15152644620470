import styled from 'styled-components'

import SearchBar from '../../components/SearchBar'
import Tabs from '../../components/Tabs'
import AlphabeticSort from '../../components/AlphabeticSort'
import SimpleCard from '../../components/SimpleCard'
import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import ExponentList from '../../components/ExponentList'
import FiltersTags from '../../components/FiltersTags'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Search = styled(SearchBar)`
  margin-bottom: 2.2rem;
  ${breakpoints.up('tablet')} {
    margin-top: 2.2rem;
    margin-bottom: 0rem;
  }
`

export const LoaderContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled(Heading)`
  display: none;
  margin-bottom: 0.8rem;
  ${breakpoints.up('tablet')} {
    display: flex;
  }
`

export const StyledTabsBottom = styled(Tabs)`
  margin-top: 24px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding-top: 1.5rem;
`

export const StyledExponentList = styled(ExponentList)``

export const ExponentCard = styled(SimpleCard)`
  width: 100%;
  ${breakpoints.up('tablet')} {
    max-width: 344px;
  }
`

export const StyledFilterTags = styled(FiltersTags)`
  padding-top: 1.5rem;
  width: 100%;
`

export const StyledAlphabeticSort = styled(AlphabeticSort)`
  position: sticky;
  top: 10px;
  margin-left: auto;
  height: 90vh;
`
