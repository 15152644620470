import styled from 'styled-components'

import { breakpoints } from '../../theme'
import EditoCard from '../../components/EditoCard'
import SimpleLoader from '../../components/SimpleLoader'

export const EditoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 0;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const Loader = styled(SimpleLoader)`
  margin-left: 50%;
`

export const Edito = styled(EditoCard)``
