import React, { useState } from 'react'
import { RouteProps, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useSampleDataSummaryTemplateProps } from '../../../templates/SampleDataSummary/useProps'
import SampleDataSummaryTemplate from '../../../templates/SampleDataSummary'
import { actions, selectors } from '../../../redux'
import { router, routesPath } from '../../../router'
import { InitialSampleData } from '../../../types/sampleData'
import { InitialSampleDataAllInfo } from '../../../types/sampleDataAllInfo'
import { Icons } from '../../../components/Icon'

const ExponentSampleDataSummary: React.FC<RouteProps> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sampleData = useSelector(selectors.samples.sampleData)
  const config = useSelector(selectors.configuration.configurationInfo)
  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const templateProps = useSampleDataSummaryTemplateProps(setIsModalOpen)

  return (
    <SampleDataSummaryTemplate
      {...templateProps}
      layoutProps={{
        ...templateProps.layoutProps,
        redirectModal: {
          text: `La saisie data de l'échantillon ${sampleData.exhibitorRef} a bien été enregistrée.`,
          lineBreak: true,
          secondText: `Souhaitez-vous imprimer l'étiquette QR code ?`,
          cancelButton: {
            text: 'Annuler',
            onClick: () => {
              if (sampleData.page === 'sample') {
                const link = router(routesPath.sampleSheetData, {
                  sampleId,
                })
                dispatch(actions.samples.setSampleData(InitialSampleData))
                dispatch(actions.samples.setSampleDataAllInfo(InitialSampleDataAllInfo))
                navigate(link)
              }
              if (sampleData.page === 'exponent' || sampleData.page === undefined) {
                const link = router(routesPath.exponentSheet, {
                  exposantId,
                  apiId: config?.exhibitionActiveApiId.toString(),
                })
                dispatch(actions.samples.setSampleData(InitialSampleData))
                dispatch(actions.samples.setSampleDataAllInfo(InitialSampleDataAllInfo))
                navigate(link)
              }
            },
          },
          deleteButton: {
            text: 'Oui',
            rightIconProps: { icon: Icons.check },
            onClick: () => {
              const link = router(routesPath.sampleSheetLabel, {
                sampleId,
              })
              dispatch(actions.samples.setSampleData(InitialSampleData))
              dispatch(actions.samples.setSampleDataAllInfo(InitialSampleDataAllInfo))
              navigate(link)
            },
          },
          isOpen: isModalOpen,
        },
      }}
    />
  )
}

export default ExponentSampleDataSummary
