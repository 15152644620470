import React, { useEffect } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../../components/form/fields/FormFieldRadioGroup/types'
import { ExhibitionForum } from '../../../graphql/generated/graphql'
import { selectors, actions } from '../../../redux'
import { router, routesPath } from '../../../router'
import MultipleEditionDataSelection, {
  MultipleEditionDataSelectionTemplateFormValues,
} from '../../../templates/MultipleEditionDataSelection'
import { useStatutSelectionProps } from '../../../templates/MultipleEditionDataSelection/useProps'
import { Icons } from '../../../components/Icon'
import { getContrastColor } from '../../../relay/Forums/getContrastColor'

const MultipleEditionForum: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const configuration = useSelector(selectors.configuration.configurationInfo)
  const forums = useSelector(selectors.exhibitions.exhibitions)
  const sampleForum = useSelector(selectors.multipleEdition.forum)

  const onSubmitForm = (values: MultipleEditionDataSelectionTemplateFormValues) => {
    const forum = forums?.forums.find((item) => item.name === values.radios)
    if (forum) {
      dispatch(actions.multipleEdition.setForum(forum))
      const link = router(routesPath.multipleEditionSubForum)
      navigate(link)
    }
  }

  useEffect(() => {
    configuration &&
      dispatch(
        actions.exhibitions.exhibitionByApiIdRequest({
          exhibitionApiId: configuration?.exhibitionActiveApiId.toString(),
        })
      )
  }, [configuration, configuration?.exhibitionActiveApiId, dispatch])

  const templateProps = useStatutSelectionProps(onSubmitForm)

  return (
    <MultipleEditionDataSelection
      {...templateProps}
      layoutProps={{
        ...templateProps.layoutProps,
        bottomActions: {
          ...templateProps.layoutProps?.bottomActions,
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.multipleEdition)
              navigate(link)
            },
          },
          next: {
            ...templateProps.layoutProps?.bottomActions?.next,
            text: 'Suivant',
            rightIconProps: {
              icon: Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
        },
      }}
      subTitle="EMPLACEMENT FORUM"
      text="À quel forum souhaitez-vous ajouter les échantillons ?"
      radios={{
        name: 'radios',
        required: true,
        optionsFont: VariantRadioFont.SmallBold,
        optionsSize: VariantRadioSize.big,
        options:
          forums && forums.forums
            ? forums.forums.map((item: ExhibitionForum) => ({
                label: item.name,
                value: item.name,
                backgroundColor: getContrastColor(item.color as string),
                color: item.color as string,
                dotted: true,
              }))
            : [],
      }}
      formikForm={{
        ...templateProps.formikForm,
        initialValues: {
          radios: sampleForum?.name,
        } as MultipleEditionDataSelectionTemplateFormValues,
      }}
    />
  )
}

export default MultipleEditionForum
