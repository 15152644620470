import React, { useEffect, useState } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SampleSearchTemplate from '../../templates/SampleSearch'
import ExponentSearchTemplate from '../../templates/ExponentSearch'
import { actions, selectors } from '../../redux'
import { SimpleCardProps } from '../../components/SimpleCard'
import { Exhibitor, Sample } from '../../graphql/generated/graphql'
import Filters from '../../components/Filters'
import { useExponentSearchProps } from '../../templates/ExponentSearch/useProps'
import { findTagsFiltersInDictionaries } from '../../components/Filters/utils'
import { useFiltersTemplateProps } from '../../templates/Filters/useProps'
import { useSamplesSearchProps } from '../../templates/SampleSearch/useProps'
import { router, routesPath } from '../../router'
import { FiltersFormValues } from '../../components/forms/FiltersForm'
import { FilterTagProps } from '../../components/FilterTag'

const Search: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const exhibitors = useSelector(selectors.exhibitors.exhibitors)
  const exhibitorsSearch = useSelector(selectors.exhibitors.exhibitorsSearch)
  const config = useSelector(selectors.configuration.configurationInfo)

  const samples = useSelector(selectors.samples.samples)
  const samplesSearch = useSelector(selectors.samples.getSamples)

  const [selectedTab, setSelectedTab] = useState<boolean>(false)
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [parsedExhibitors, setParsedExhibitors] = useState<SimpleCardProps[] | undefined>(undefined)
  const [allExhibitors, setAllExhibitors] = useState<Exhibitor[]>([])
  const [allSamples, setAllSamples] = useState<Sample[]>([])
  const [search, setSearch] = useState<string>('')
  const [filters, setFilters] = useState<FiltersFormValues | undefined>(undefined)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [tags, setTags] = useState<Array<FilterTagProps>>([])
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [universe, setUniverse] = useState<string | undefined>(undefined)
  const sample = useSelector(selectors.samples.sample)
  const sampleFilters = useSelector(selectors.samples.sampleFilters)

  const handleTabClick = (value: boolean) => {
    setSelectedTab(value)
    setCurrentPage(1)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  const handleFiltersClick = () => {
    setOpenFilters(!openFilters)
  }

  useEffect(() => {
    if (universe) {
      dispatch(actions.samples.sampleSearchFiltersRequest({ universeEbmsId: universe }))
    }
  }, [universe, dispatch])

  const exponentTemplateProps = useExponentSearchProps({
    handleTabClick,
    handleFiltersClick,
    parsedExhibitors,
    tags,
    search,
    handleSearchChange,
    selectedTab,
    setParsedExhibitors,
    exhibitors,
    allExhibitors,
    currentPage,
    setAllExhibitors,
  })
  const sampleTemplateProps = useSamplesSearchProps({
    handleTabClick,
    handleFiltersClick,
    tags,
    search,
    handleSearchChange,
    selectedTab,
    samples,
    currentPage,
    allSamples,
    setAllSamples,
  })
  const filterstemplateProps = useFiltersTemplateProps({
    tags,
    setTags,
    handleFiltersClick,
    openFilters,
    setUniverse,
    universe,
    isExponent: !selectedTab,
    setFilters,
  })

  useEffect(() => {
    dispatch(actions.samples.resetSampleSearch())
    if (!selectedTab) {
      if (exhibitorsSearch && !exhibitorsSearch.pending) {
        const filterParsedCall = findTagsFiltersInDictionaries(tags, config?.dictionaries)
        const countries = filterParsedCall.find((item) => item.type === 'COUNTRIES')?.filters || []
        const universesEbmsIds =
          filterParsedCall.find((item) => item.type === 'UNIVERSES')?.filters || []
        const pavilionsEbmsIds =
          filterParsedCall.find((item) => item.type === 'PAVILIONS')?.filters || []
        config &&
          dispatch(
            actions.exhibitors.exhibitorsServiceRequest({
              q: search,
              universesEbmsIds,
              pavilionsEbmsIds,
              countries,
              exhibitionsApiIds: [config.exhibitionActiveApiId.toString()],
              first: 16,
              page: currentPage,
            })
          )
      } else {
        setAllExhibitors([])
      }
    }
    if (selectedTab) {
      if (!samplesSearch.pending) {
        const data = sampleFilters || []
        const { forums } = data
        const filterParsedCall = findTagsFiltersInDictionaries(tags, config?.dictionaries)
        const countriesInFilters: string[] =
          filterParsedCall.find((item) => item.type === 'COUNTRIES')?.filters || []
        const pavilionsEbmsIds =
          filterParsedCall.find((item) => item.type === 'PAVILIONS')?.filters || []
        const forumsInFilters: string[] | undefined =
          filters &&
          filters.Forum &&
          filters.Forum.map((forum) => {
            const matchingKey = Object.keys(forums).find((key) => forums[key] === forum.trim())
            return matchingKey || ''
          })
        const filteredForumsInFilters =
          forumsInFilters && forumsInFilters.filter((forum) => forum !== '')
        const filteredCountriesInFilters = countriesInFilters.filter((country) => country !== '')

        const findKeysForValues = (searchValues: FilterTagProps[]) => {
          const foundKeys: string[] = []
          //@ts-ignore
          Object.entries(data).forEach(([category, items]) => {
            if (
              category !== 'countries' &&
              category !== 'exhibitions' &&
              category !== 'pavilions' &&
              category !== 'univers' &&
              category !== 'secteur' &&
              category !== 'forums'
            ) {
              const itemsRecord: Record<string, unknown> = items as Record<string, unknown>
              Object.entries(itemsRecord).forEach(([itemId, itemName]) => {
                searchValues.forEach((value: FilterTagProps) => {
                  if (value.text && value.text.includes(itemName as string)) {
                    foundKeys.push(itemId)
                  }
                })
              })
            }
          })

          return foundKeys
        }
        config &&
          dispatch(
            actions.samples.getSamplesRequest({
              q: search,
              page: currentPage,
              universesEbmsIds: universe ? [universe] : undefined,
              countries: filteredCountriesInFilters,
              pavilionsEbmsIds,
              exhibitionsApiIds: [config.exhibitionActiveApiId.toString()],
              forumsIds: filteredForumsInFilters,
              flagItems: findKeysForValues(tags),
              first: 10,
            })
          )
      } else {
        setAllSamples([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    dispatch,
    config?.dictionaries,
    search,
    selectedTab,
    tags,
    universe,
    sampleFilters,
    filters,
  ])
  useEffect(() => {
    let scrollTimeout: any
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement
      const scrollBottom = scrollHeight - (scrollTop + clientHeight)

      if (scrollBottom < 100 && !samplesSearch.pending) {
        clearTimeout(scrollTimeout)
        scrollTimeout = setTimeout(() => {
          setCurrentPage((prevPage) => prevPage + 1)
        }, 200)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(scrollTimeout)
    }
  }, [samplesSearch.pending, setCurrentPage])

  useEffect(() => {
    setParsedExhibitors(undefined)
    setCurrentPage(1)
    setAllExhibitors([])
  }, [search, tags])

  useEffect(() => {
    dispatch(actions.samples.setSample(undefined))
  }, [dispatch])

  useEffect(() => {
    if (sample && !isFirstRender) {
      setShouldRedirect(true)
    }
  }, [sample, isFirstRender])

  useEffect(() => {
    if (sample && shouldRedirect) {
      const sampleId = sample?.id
      const link = router(routesPath.sampleSheetData, { sampleId })
      navigate(link)
      setShouldRedirect(false)
    }
  }, [dispatch, navigate, sample, shouldRedirect])

  useEffect(() => {
    if (isFirstRender && sample) {
      setSelectedTab(true)
      setIsFirstRender(false)
    }
  }, [isFirstRender, sample])

  useEffect(() => {
    dispatch(actions.exhibitors.resetExhibitors())
  }, [dispatch, search, tags])

  return (
    <>
      {selectedTab ? (
        <SampleSearchTemplate {...sampleTemplateProps} errors={samplesSearch.errors} />
      ) : (
        <ExponentSearchTemplate {...exponentTemplateProps} />
      )}
      <Filters {...filterstemplateProps} isOpen={openFilters} onClose={handleFiltersClick} />
    </>
  )
}

export default Search
