import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exportExhibitors = getQueryService({
  query: gql(`
    query exportExhibitors($filters: ExhibitorsExportInput!) {
      exportExhibitors(filters: $filters)
    }
`),
  transformer: (response) => {
    return response
  },
})
