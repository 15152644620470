import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import i18next from 'i18next'
import { useNavigate, useParams } from 'react-router'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import { ParcelCardProps } from '../../components/ParcelCard'
import { actions, selectors } from '../../redux'
import {
  Collection,
  Exhibitor,
  SampleCreateInput,
  SampleStatus,
  UserRole,
} from '../../graphql/generated/graphql'
import { CollectionCommentFormValues } from '../../components/forms/CollectionCommentForm'
import { FormFieldSwitchSize } from '../../components/form/fields/FormFieldSwitch/types'
import { colors } from '../../theme'
import { ServiceValues } from '../../helpers/ReduxHelpers'
import { SampleSelectFormValues } from '../../components/forms/SampleSelectForm'
import { SampleCardProps } from '../../components/SampleCard'
import { router, routesPath } from '../../router'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import { sampleLabelReturn } from '../../relay/SampleSheet/SampleLabelReturn'
import { useExponentSheetTabsProps } from '../../relay/ExponentSheet/UseExponentSheetTabsProps'
import { sampleStatusFrenchMapping } from '../../types/sampleStatusFrenchMapping'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { mapSampleData } from '../../relay/DataSample/MapSampleData'
import { mapSampleDataAllInfo } from '../../relay/DataSample/MapSampleDataAllInfo'
import { convertMkpToString } from '../../relay/SampleSheet/ConvertMkpToString'
import { useLayoutExponentSheetTemplateProps } from '../../layouts/LayoutExponentSheet/useProps'
import { ActionButtonScheme } from '../../components/ActionButton/types'

import { ExponentSheetCollectionTemplateProps } from '.'

export const useExponentSheetCollectionTemplateProps = (
  exhibitor: Exhibitor | null,
  collectionUpdate: ServiceValues<null, any>,
  collection: Collection | null,
  sampleCreate: ServiceValues<null, any>
): ExponentSheetCollectionTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sampleData = useSelector(selectors.samples.sampleData)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const sample = useSelector(selectors.samples.sample)
  const config = useSelector(selectors.configuration.configurationInfo)
  const collectionExhibitionsPending = useSelector(
    selectors.exhibitors.collectionExhibitionsPending
  )

  const [parcelsMode, setParcelMode] = useState<boolean>(true)
  const [commentMode, setCommentMode] = useState<boolean>(false)
  const [shootingEditMode, setShootingEditMode] = useState<boolean>(false)
  const [shootingModalOpen, setShootingModalOpen] = useState<boolean>(false)
  const [addSamplesMode, setAddSamplesMode] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [displayReturn, setDisplayReturn] = useState<boolean | undefined>(false)
  const [routeType, setRouteType] = useState<string | undefined>(undefined)
  const [sampleClicked, setSampleClicked] = useState<boolean>(false)
  const [selectedSamples, setSelectedSamples] = useState<string[]>([])
  const { exposantId } = useParams<{ exposantId: string }>()
  const { apiId } = useParams<{ apiId: string }>()
  const sampleById = useSelector(selectors.samples.getSampleById)

  const refComment = useRef<FormikProps<CollectionCommentFormValues>>(null)
  const refSample = useRef<FormikProps<SampleSelectFormValues>>(null)
  const user = useSelector(selectors.auth.user)
  const sampleUpdateMany = useSelector(selectors.samples.sampleUpdateMany)
  const tabs = useExponentSheetTabsProps(exposantId, apiId, 'collection', user?.role)
  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const layoutPropsExponent = useLayoutExponentSheetTemplateProps(exhibitor, collection)
  const isArchived = collection?.isArchived
  const sampleReceived = collection?.parcels.reduce((sum, item) => sum + item.samplesTotal, 0)
  const hasPreselectedForumSample =
    collection &&
    collection.samples &&
    collection.samples.some(
      (sample) =>
        sample.status === SampleStatus.Selected &&
        !sample.isDeleted &&
        (sample.mkpForum === 'MARKETPLACE_FORUM' || sample.mkpForum === 'MARKETPLACE')
    )
  const areAllSamplesOkShooting =
    collection &&
    collection.samples &&
    collection.samples.every((sample) => sample.status === SampleStatus.SelectedOkShooting)

  const handleChooseMode = useCallback(
    (selectedMode: 'parcels' | 'comment' | 'addSamples' | 'shootingEditMode') => {
      setParcelMode(selectedMode === 'parcels')
      setCommentMode(selectedMode === 'comment')
      setAddSamplesMode(selectedMode === 'addSamples')
      setShootingEditMode(selectedMode === 'shootingEditMode')
    },
    []
  )

  useEffect(() => {
    collectionUpdate && collectionUpdate.success && handleChooseMode('parcels')
    sampleCreate && sampleCreate.success && handleChooseMode('parcels')
  }, [collectionUpdate.success, collectionUpdate, handleChooseMode, sampleCreate])

  useEffect(() => {
    if (exhibitor) {
      dispatch(
        actions.samples.setSampleDataAllInfo({
          ...sampleDataAllInfo,
          routeType: exhibitor.universe.name,
        })
      )
      if (
        exhibitor &&
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.Marketplace &&
        user?.role !== UserRole.InputOperator
      ) {
        setRouteType(exhibitor.universe.name)
      }
      if (
        exhibitor &&
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.InputOperator &&
        (user?.role === UserRole.Marketplace ||
          user?.role === UserRole.ModePv ||
          user?.role === UserRole.Admin ||
          user?.role === UserRole.SuperAdmin ||
          user?.role === UserRole.FashionExpert)
      ) {
        setRouteType(`${exhibitor.universe.name}Marketplace`)
      } else if (
        exhibitor &&
        (user?.role === UserRole.Intern || user?.role === UserRole.InputOperator)
      ) {
        if (exhibitor.universe.name === 'Fabrics' || exhibitor.universe.name === 'Yarns') {
          setRouteType('Trainee')
        }
        if (exhibitor.universe.name === 'Designs') {
          setRouteType('TraineeDesign')
        }
        if (exhibitor.universe.name === 'Smart' || exhibitor.universe.name === 'Smart Creation') {
          setRouteType('Smart')
        }
        if (
          exhibitor.universe.name === 'Manufacturing' ||
          exhibitor.universe.name === 'Manufacturing Leather'
        ) {
          setRouteType('TraineeManuf')
        }
        if (exhibitor.universe.name === 'Leather' || exhibitor.universe.name === 'Accessories') {
          setRouteType('TraineeShort')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, exhibitor, user?.role, sample])

  useEffect(() => {
    if (sample && sample.flagItems) {
      dispatch(
        actions.samples.setSampleData(
          mapSampleData(sample, 'exponent', 'exponent', routeType, user)
        )
      )
      dispatch(actions.samples.setSampleDataAllInfo(mapSampleDataAllInfo(sample)))
    }
  }, [dispatch, routeType, sample, user])

  useEffect(() => {
    if (sample && sampleClicked) {
      setSampleClicked(false)
      if (
        exhibitor &&
        user?.role !== 'INPUT_OPERATOR' &&
        (exhibitor.universe.name === 'Smart' || exhibitor.universe.name === 'Smart Creation')
      ) {
        const link = router(routesPath.exponentSampleDataSmartCreationChoice, {
          exposantId,
          sampleId: sample.id,
        })
        navigate(link)
      } else if (
        exhibitor &&
        exhibitor.universe.name === 'Leather' &&
        user?.role !== 'INPUT_OPERATOR'
      ) {
        const link = router(routesPath.exponentSampleDataLeatherChoice, {
          exposantId,
          sampleId: sample.id,
        })
        navigate(link)
      } else {
        dispatch(
          actions.samples.getSampleFormByUniverseRequest({
            collectionId: sample.collection?.id,
            universeEbmsId: sample.universeRelation
              ? sample.universeRelation.universeEbmsId
              : sample.universe.ebmsId,
          })
        )
        const link = router(sampleDataRoutes(sampleData, 'next'), {
          exposantId,
          sampleId: sample.id,
        })
        navigate(link)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, exhibitor, exposantId, navigate, sampleClicked, sampleData])

  useEffect(() => {
    if (sampleUpdateMany && sampleUpdateMany.success) {
      exposantId &&
        apiId &&
        dispatch(
          actions.exhibitors.exhibitorsCollectionServiceRequest({
            exhibitorEbmsId: exposantId,
            exhibitionApiId: apiId,
          })
        )
      setShootingModalOpen(false)
      setSelectedSamples([])
      handleChooseMode('parcels')
    }
  }, [apiId, dispatch, exposantId, handleChooseMode, sampleUpdateMany])

  const onSampleClick = useCallback(
    (id: string) => {
      if (id) {
        setSampleClicked(true)
        dispatch(actions.samples.getSampleByIdRequest({ sampleId: id }))
      }
    },
    [dispatch]
  )

  const statistics: any = useMemo(() => {
    const statsDeleted =
      collection?.samplesStats &&
      collection?.samplesStats.find((sampleStat) => sampleStat.status === SampleStatus.Deleted)
    if (
      user &&
      user.role !== UserRole.Intern &&
      user.role !== UserRole.FashionExpert &&
      user.role !== UserRole.InputOperator &&
      user.role !== UserRole.Shooting &&
      collection?.comment &&
      parcelsMode &&
      collection.samples &&
      collection.samples.length > 0
    ) {
      const statsValidated = collection?.samplesStats?.find(
        (item) => item.status === SampleStatus.Validated
      )?.count
      const sampleStats = [
        {
          title: `Présélectionné${
            collection.samples.filter((sample) => !sample.isDeleted).length > 1 ? 's' : ''
          }`,
          number: collection.samples.filter((sample) => !sample.isDeleted).length,
          color: '#fff5d9',
        },
        {
          title: i18next.t('exponent.collection.statisticsRecepted'),
          number: collection.parcels.reduce((sum, item) => sum + item.samplesTotal, 0),
          color: '#e0e0e0',
        },
        {
          title: `Retiré${statsDeleted && Number(statsDeleted.count) > 1 ? 's' : ''} du forum`,
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.Deleted)?.count ||
            0,
          color: '#FFE3E3',
        },
        {
          title: `Validé${statsValidated && statsValidated > 1 ? 's' : ''} forum`,
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.Validated)
              ?.count || 0,
          color: '#DAEFE0',
        },
        {
          title: 'Ok Shooting',
          number:
            collection?.samplesStats?.find(
              (item) => item.status === SampleStatus.SelectedOkShooting
            )?.count || 0,
          color: '#FFE7DD',
        },
        {
          title: 'Ok vérif',
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.ValidatedChecked)
              ?.count || 0,
          color: '#34AB55',
        },
        {
          title: 'Ok étiquette',
          number:
            collection?.samplesStats?.find((item) => item.status === SampleStatus.ValidatedLabelled)
              ?.count || 0,
          color: '#98D3A8',
        },
      ]
      return {
        title: i18next.t('sampleCollection.statistics.title'),
        chart: {
          data:
            collection?.parcels &&
            sampleStats &&
            sampleStats.filter((item) => {
              return (
                !(
                  item.title === 'Validé forum' ||
                  item.title === 'Ok Shooting' ||
                  item.title === 'Retiré du forum' ||
                  item.title === 'Ok étiquette' ||
                  item.title === 'Ok vérif'
                ) || item.number > 0
              )
            }),
          label: [
            `${collection.parcels.reduce((sum, item) => sum + item.samplesTotal, 0)} échantillons`,
            'réceptionnés',
          ],
        },
      }
    }
    return undefined
  }, [
    collection?.comment,
    collection?.parcels,
    collection?.samples,
    collection?.samplesStats,
    parcelsMode,
    user,
  ])

  const updateSampleShooting = useCallback(() => {
    dispatch(
      actions.samples.sampleUpdateManyRequest({
        sampleIds: selectedSamples,
        input: {
          status: SampleStatus.SelectedOkShooting,
        },
      })
    )
  }, [dispatch, selectedSamples])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...layoutPropsExponent,
        bottomActions: {
          ...layoutPropsExponent.bottomActions,
          back:
            commentMode || addSamplesMode || shootingEditMode
              ? {
                  leftIconProps: {
                    icon: Icons.arrowLeft,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => {
                    handleChooseMode('parcels')
                  },
                  rounded: true,
                }
              : undefined,
          next: !isArchived
            ? user &&
              user.role !== UserRole.Intern &&
              user.role !== UserRole.InputOperator &&
              user.role !== UserRole.Rex &&
              user.role !== UserRole.Marketplace &&
              user.role !== UserRole.Shooting
              ? collection?.parcels &&
                collection?.parcels.length > 0 &&
                parcelsMode &&
                !collection?.comment &&
                sampleReceived !== 0
                ? {
                    rightIconProps: {
                      icon: Icons.pencil,
                      width: 18,
                      height: 18,
                    },
                    text: 'Commenter la collection',
                    onClick: () => {
                      handleChooseMode('comment')
                    },
                  }
                : commentMode
                ? {
                    rightIconProps: {
                      icon: Icons.check,
                      width: 18,
                      height: 18,
                    },
                    disabled: !isValid,
                    text: 'Valider',
                    onClick: () => {
                      refComment.current?.submitForm()
                    },
                  }
                : parcelsMode && collection?.comment
                ? {
                    rightIconProps: {
                      icon: Icons.plus,
                      width: 18,
                      height: 18,
                    },
                    text: 'Présélection échantillons',
                    onClick: () => {
                      handleChooseMode('addSamples')
                    },
                  }
                : addSamplesMode
                ? {
                    rightIconProps: {
                      icon: Icons.arrowRight,
                      width: 18,
                      height: 18,
                    },
                    disabled: sampleCreate.pending,
                    isPending: sampleCreate.pending,
                    text: 'Génerer les fiches',
                    onClick: () => {
                      refSample.current?.submitForm()
                    },
                  }
                : undefined
              : undefined
            : undefined,
        },
        tabs: tabs,
        deleteModal: {
          text:
            selectedSamples.length > 1
              ? `Les échantillons sélectionnés vont passer en "Ok shooting".`
              : `L'échantillon sélectionné va passer en "Ok shooting".'`,
          cancelButton: {
            text: 'Annuler',
            onClick: () => setShootingModalOpen(false),
          },
          deleteButton: {
            text: 'Oui, valider',
            rightIconProps: { icon: Icons.check, width: 18 },
            onClick: () => {
              updateSampleShooting()
            },
          },
          isOpen: shootingModalOpen,
        },
      },
      pending: !collection || collectionExhibitionsPending,
      sampleEditPending: sampleById.pending,
      samples:
        collection?.comment && parcelsMode && collection.samples
          ? {
              shootingBtn:
                hasPreselectedForumSample &&
                !areAllSamplesOkShooting &&
                (user?.role === UserRole.Admin ||
                  user?.role === UserRole.SuperAdmin ||
                  user?.role === UserRole.Shooting)
                  ? {
                      text: shootingEditMode
                        ? 'Valider les échantillons en "Ok shooting"'
                        : 'Passer les échantillons en "Ok shooting"',
                      rightIconProps: {
                        icon: shootingEditMode ? Icons.check : Icons.pencil,
                        width: 16,
                        height: 16,
                      },

                      onClick: () =>
                        shootingEditMode ? setShootingModalOpen(true) : setShootingEditMode(true),
                      variantScheme: ActionButtonScheme.Primary,
                      disabled: shootingEditMode && selectedSamples.length === 0,
                    }
                  : undefined,
              title: i18next.t('exponent.collection.samples.title'),
              warning:
                collection.samples.filter((sample) => !sample.isDeleted).length <= 4
                  ? {
                      label: sampleLabelReturn(
                        collection.samples.filter((sample) => !sample.isDeleted).length
                      ),
                    }
                  : undefined,
              numbered:
                collection.samples.filter((sample) => !sample.isDeleted).length > 4
                  ? {
                      number: sampleLabelReturn(
                        collection.samples.filter((sample) => !sample.isDeleted).length
                      ),
                    }
                  : undefined,
              list: (config &&
                (collection?.samples?.map((sample) => {
                  const category =
                    sample.flagItems &&
                    sample.flagItems.find((item) => item.group.miraklRef === 'category')
                  const preselected =
                    sample.status === SampleStatus.Selected &&
                    !sample.isDeleted &&
                    (sample.mkpForum === 'MARKETPLACE_FORUM' || sample.mkpForum === 'MARKETPLACE')
                  let differenceInDays = -1
                  if (
                    sample.status === SampleStatus.SelectedOkShooting &&
                    config?.noDaysBeforeSendingToMarketplace !== 0
                  ) {
                    const dateString = sample.statusUpdatedAt
                    const dateObject = dayjs(dateString)
                    const dateInXDays = dateObject.add(
                      config?.noDaysBeforeSendingToMarketplace,
                      'day'
                    )
                    const dateNow = dayjs()
                    differenceInDays = dateInXDays.diff(dateNow, 'day')
                  }
                  return {
                    title: sample.name,
                    category: category && category?.name,
                    status: {
                      type:
                        sample.status === SampleStatus.ValidatedOkMarketplace
                          ? SampleStatus.SelectedOkShooting
                          : sample.status,
                      value:
                        sample.status === SampleStatus.ValidatedOkMarketplace
                          ? (sampleStatusFrenchMapping[SampleStatus.SelectedOkShooting] as string)
                          : (sampleStatusFrenchMapping[sample.status] as string),
                    },
                    forumColor: sample.forum?.color,
                    forum: sample.forum?.name,
                    selectMode: shootingEditMode && preselected,
                    subForum: sample.subForum?.name,
                    mkpForum: sample.mkpForum ? convertMkpToString(sample.mkpForum) : undefined,
                    selected:
                      shootingEditMode && preselected && selectedSamples.includes(sample.id),
                    disabled: shootingEditMode && !preselected,
                    okMkp: sample.marketplaceImported,
                    noMkp: sample.marketplaceImportError,
                    number: `${sample.id}`,
                    isDelete: sample.isDeleted,
                    image:
                      sample.photos && sample.photos[0]
                        ? {
                            src: sample.photos[0].url,
                            alt: sample.photos[0].id,
                          }
                        : undefined,
                    marketplaceWarning:
                      differenceInDays >= 0
                        ? {
                            daysLeft: differenceInDays,
                            text: 'Avant dépôt sur la Marketplace',
                          }
                        : undefined,
                    onClick: () => {
                      if (!shootingEditMode) {
                        const link = router(routesPath.sampleSheetData, {
                          sampleId: sample.id,
                        })
                        navigate(link)
                      }
                      if (preselected) {
                        setSelectedSamples((prevSelectedSamples) => {
                          if (prevSelectedSamples.includes(sample.id)) {
                            return prevSelectedSamples.filter((id) => id !== sample.id)
                          } else {
                            return [...prevSelectedSamples, sample.id]
                          }
                        })
                      }
                    },

                    editButton:
                      !isArchived && user?.role !== UserRole.Rex && user?.role !== UserRole.Shooting
                        ? {
                            text: i18next.t('exponent.collection.samples.liste.title'),
                            rightIconProps: { icon: Icons.pencil, width: 18, height: 18 },
                            onClick: () => {
                              onSampleClick(sample.id)
                            },
                          }
                        : undefined,
                  }
                }) as unknown)) as SampleCardProps[] | undefined,
            }
          : undefined,
      commentText:
        collection?.comment && (parcelsMode || addSamplesMode)
          ? {
              title: 'Commentaire de la collection',
              text: collection.comment,
              note: collection.note?.toString(),
              edit:
                !addSamplesMode &&
                !isArchived &&
                user?.role !== UserRole.Intern &&
                user?.role !== UserRole.InputOperator &&
                user?.role !== UserRole.Rex &&
                user?.role !== UserRole.Marketplace &&
                user?.role !== UserRole.Shooting
                  ? {
                      text: i18next.t('exponent.collection.commentText.edit'),
                      onClick: () => handleChooseMode('comment'),
                    }
                  : undefined,
            }
          : undefined,
      statistics: statistics,
      parcels: {
        list: parcelsMode
          ? {
              addButton: undefined,
              empty: i18next.t('exponent.collection.parcels.empty'),
              linkText: `"colis".`,
              onClick: () => {
                const link = router(routesPath.exponentSheetParcels, {
                  exposantId,
                  apiId: config?.exhibitionActiveApiId.toString(),
                })
                navigate(link)
              },
              title:
                collection?.parcels.length === 1
                  ? i18next.t('exponent.collection.parcels.receivedS')
                  : i18next.t('exponent.collection.parcels.receivedP'),
              parcels: collection?.parcels.map((parcel) => ({
                ...parcel,
                edit: undefined,
              })) as ParcelCardProps[] | undefined,
            }
          : undefined,
      },
      edit: addSamplesMode
        ? {
            formikForm: {
              innerRef: refSample,
              initialValues: {
                number: '',
              } as SampleSelectFormValues,
              validationSchema: Yup.object().shape({
                number: Yup.number()
                  .integer()
                  .min(1, 'Au moins 1 échantillon')
                  .test(
                    'maxNumber',
                    "Il ne peux pas y avoir plus d'échantillons présélectionnés que d'échantillons reçu",
                    function (value) {
                      if (collection) {
                        const numberReceived = collection.samples ? collection.samples.length : 0
                        const numberTotal = collection.parcels.reduce(
                          (sum, item) => sum + item.samplesTotal,
                          0
                        )
                        const maxAllowedNumber = numberTotal - numberReceived

                        return value ? value <= maxAllowedNumber : false
                      } else return true
                    }
                  )
                  .required(),
              }),
              onSubmit: (values) => {
                if (collection) {
                  const numberOfTimes = parseInt(values.number.toString(), 10)
                  const sampleArray = [] as { input: SampleCreateInput }[]
                  for (let i = 0; i < numberOfTimes; i++) {
                    sampleArray.push({
                      input: {
                        collectionId: collection?.id,
                        flagGroups: [],
                      },
                    })
                  }
                  dispatch(actions.samples.sampleCreateRequest({ samples: sampleArray }))
                }
              },
              validateOnChange: true,
            },
            title: i18next.t('exponent.collection.editForm.title'),
            field: {
              name: 'number',
              placeholder: '0',
              required: true,
              hasFloatingLabel: false,
            },
          }
        : undefined,
      comment: commentMode
        ? {
            title: i18next.t('exponent.collection.commentForm.title'),
            displayReturn: displayReturn,
            formikForm: {
              innerRef: refComment,
              initialValues: {
                comment: collection && collection.comment ? collection.comment : '',
                note: collection && collection.note ? collection.note?.toString() : undefined,
                return: collection && collection.samplesToReturn ? true : false,
                samplesToReturn: collection ? collection.samplesToReturn : 0,
              } as CollectionCommentFormValues,
              validationSchema: Yup.object().shape({
                comment: Yup.string().required(),
                note: Yup.number().integer().required(),
                return: Yup.boolean(),
                samplesToReturn: Yup.number().integer().nullable().when('return', {
                  is: true,
                  then: Yup.number().integer(),
                }),
              }),
              onSubmit: (values) => {
                const updatedValues = {
                  ...values,
                  note: parseInt(values.note.toString(), 10),
                  samplesToReturn:
                    values.samplesToReturn && values.return
                      ? parseInt(values.samplesToReturn.toString(), 10)
                      : parseInt('0', 10),
                  return: undefined,
                }

                collection &&
                  dispatch(
                    actions.exhibitors.collectionUpdateRequest({
                      collectionId: collection?.id,
                      input: updatedValues,
                    })
                  )
              },
              onContextUpdate: (context) => {
                setIsValid(context.isValid)
                setDisplayReturn(context.values.return)
              },
              validateOnChange: true,
              validateOnMount: true,
            },
            fields: {
              comment: {
                props: {
                  label: i18next.t('exponent.collection.commentForm.comment.title'),
                  name: 'comment',
                  required: true,
                  hasFloatingLabel: false,
                },
              },
              note: {
                title: i18next.t('exponent.collection.commentForm.note.title'),
                required: true,
                props: {
                  label: i18next.t('exponent.collection.commentForm.note.title'),
                  name: 'note',
                  required: true,
                  options: [
                    {
                      label: '0',
                      value: '0',
                    },
                    {
                      label: '1',
                      value: '1',
                    },
                    {
                      label: '2',
                      value: '2',
                    },
                    {
                      label: '3',
                      value: '3',
                    },
                    {
                      label: '4',
                      value: '4',
                    },
                    {
                      label: '5',
                      value: '5',
                    },
                  ],
                },
              },
              return: {
                props: {
                  name: 'return',
                  inlineLabel: i18next.t('exponent.collection.commentForm.return.title'),
                  onColor: colors.eletricViolet,
                  variantSize: FormFieldSwitchSize.Small,
                },
              },
              samplesToReturn: {
                inlineLabel: i18next.t('exponent.collection.commentForm.samplesToReturn.title'),
                props: {
                  name: 'samplesToReturn',
                  id: 'collection-samplesToReturn',
                },
              },
            },
          }
        : undefined,
    }),
    [
      layoutProps,
      layoutPropsExponent,
      commentMode,
      addSamplesMode,
      shootingEditMode,
      isArchived,
      user,
      collection,
      parcelsMode,
      sampleReceived,
      isValid,
      sampleCreate.pending,
      tabs,
      selectedSamples,
      shootingModalOpen,
      collectionExhibitionsPending,
      sampleById.pending,
      hasPreselectedForumSample,
      areAllSamplesOkShooting,
      config,
      statistics,
      displayReturn,
      handleChooseMode,
      updateSampleShooting,
      navigate,
      onSampleClick,
      exposantId,
      dispatch,
    ]
  )
}
