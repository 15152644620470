import styled from 'styled-components'

import FirstLoginForm from '../../components/forms/FirstLoginForm'
import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 4.4rem;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    text-align: center;
  }
`
export const StyledContentWrapper = styled(ContentWrapper)`
  max-width: 37rem;
  margin-top: 5.6rem;
`

export const Form = styled(FirstLoginForm)`
  margin: 0 auto;
  max-width: 900px;
`
