import React from 'react'
import { RouteProps } from 'react-router-dom'

import LoungesSubForumTemplate from '../../../templates/LoungeSubForum'
import { useLoungeSubForumTemplateProps } from '../../../templates/LoungeSubForum/useProps'

const LoungesSubForumsChoice: React.FC<RouteProps> = () => {
  const templateProps = useLoungeSubForumTemplateProps()

  return <LoungesSubForumTemplate {...templateProps} />
}

export default LoungesSubForumsChoice
