import React from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleSheetsEditTemplate from '../../../templates/SampleSheetsEdit'
import { useSampleSheetEditTemplateProps } from '../../../templates/SampleSheetsEdit/useProps'
import { selectors } from '../../../redux'

const SampleSheetEditTanningType: React.FC<RouteProps> = () => {
  const { routeType } = useParams<{ routeType: string }>()

  const form = useSelector(selectors.samples.flagGroupsByUniverse)
  const templateProps = useSampleSheetEditTemplateProps(
    routeType,
    'Type de tannage',
    form ? form.find((item) => item.name === 'TYPE DE TANNAGE') : undefined
  )
  return <SampleSheetsEditTemplate {...templateProps} />
}

export default SampleSheetEditTanningType
