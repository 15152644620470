import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import NoAuthorizedTemplate, { NoAuthorizedTemplateProps } from '../templates/NoAuthorized'
import { actions, selectors } from '../redux'
import { removeAuthCookie } from '../helpers/CookieHelpers'
import { router, routesPath } from '../router'

function PageNoAuthorized() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const message = useSelector(selectors.api.messageBadIp)
  const noAuhtorizedProps: NoAuthorizedTemplateProps = useMemo(
    () => ({
      title: 'Accès non autorisé',
      text: message ? message : '',
      cta: {
        text: 'Retour connexion',
        onClick: () => {
          dispatch(actions.auth.setToken(''))
          dispatch(actions.auth.setUser(null))
          removeAuthCookie()
          const link = router(routesPath.login)
          navigate(link)
        },
      },
    }),
    [dispatch, message, navigate]
  )

  return <NoAuthorizedTemplate {...noAuhtorizedProps} />
}

export default PageNoAuthorized
