import { SampleLabel } from '../graphql/generated/graphql'

export interface SampleData {
  routeType: string | undefined
  page: string | undefined
  label: string[] | undefined
  exhibitorRef: string | undefined
  accessorieType: string | undefined
  mkpForum: string | undefined
  forum: string | undefined
  subForum: string | undefined
  category: string | undefined
  productDestination: string[] | undefined
  pattern: string | undefined
  performanceCode: string[] | undefined
  shooting: string[] | undefined
  tanningType: string | undefined
  boxe: string | undefined
  numberEnd: string | undefined
  patternType: string | undefined
  animal: string | undefined
  aspect: string[] | undefined
  certifications: string[] | undefined
  unitValue: string | undefined
  technologyActivity: undefined | string
  material: string[] | undefined
  manufacturingType: string | undefined
  color: string | undefined
  [key: string]: string | string[] | SampleLabel[] | undefined
}

export const InitialSampleData = {
  routeType: undefined,
  page: undefined,
  forum: undefined,
  mkpForum: undefined,
  subForum: undefined,
  category: undefined,
  productDestination: undefined,
  pattern: undefined,
  performanceCode: undefined,
  shooting: undefined,
  boxe: undefined,
  label: undefined,
  tanningType: undefined,
  numberEnd: undefined,
  patternType: undefined,
  animal: undefined,
  aspect: undefined,
  certifications: undefined,
  unitValue: undefined,
  technologyActivity: undefined,
  exhibitorRef: undefined,
  accessorieType: undefined,
  material: undefined,
  manufacturingType: undefined,
  color: undefined,
}
