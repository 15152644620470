import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const editorialDelete = getMutationService({
  mutation: gql(`
    mutation editorialDelete($editorialId: ID!) {
        editorialDelete(editorialId: $editorialId) {
            id
            name
            description
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
