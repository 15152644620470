import styled, { css } from 'styled-components'

import { fonts } from '../../theme'
import Icon from '../Icon'

const defaultS = css`
  color: ${(props) => props.theme.colors.tundora};
  background-color: ${(props) => props.theme.colors.pureWhite};
`

const concreteBg = css`
  color: ${(props) => props.theme.colors.tundora};
  background-color: ${(props) => props.theme.colors.concrete};
  padding: 1rem;
`

export const CollapsibleItem = styled.div<{ $noBorder?: boolean }>`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaft};
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border-bottom: none;
      padding: 6px 0;
    `};
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`

export const Title = styled.span<{ $color?: string }>`
  ${fonts.RobotoLight}
  font-size: 17px;
  color: ${(props) => props.theme.colors.tundora};
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
      ${fonts.GothamBold}
      font-size: 12px;
      padding: 5px 0;
    `}
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Head = styled.div<{ $variant?: string; $color?: string; $backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4rem;
  padding: 10px;
  cursor: pointer;
  ${(props) => {
    switch (props.$variant) {
      case 'default':
        return css`
          ${defaultS}
        `
      case 'concreteBg':
        return css`
          ${concreteBg}
        `
    }
  }}
  ${({ $color, $backgroundColor }) =>
    $backgroundColor &&
    css`
      color: ${$color};
      background-color: ${$backgroundColor};
    `}
`

export const Content = styled.div`
  margin-top: 1rem;
`

export const Plus = styled(Icon)``
export const Minus = styled(Icon)``
