import styled from 'styled-components'

import FormFieldText from '../../form/fields/FormFieldInput'
import FormikForm from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { fonts } from '../../../theme'
import CollapsibleColoredItem from '../../CollapsibleColored'
import FormFieldRadioGroup from '../../form/fields/FormFieldRadioGroup'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const FieldText = styled(FormFieldText)`
  width: 100%;
  ${fonts.GothamBold}
`

export const FieldSelect = styled(FormikField)`
  width: 100%;
  max-width: 7rem;
`

export const YearContainer = styled.div`
  position: absolute;
  left: 75px;
  top: 2px;
`

export const TextFieldYear = styled(FormikField)`
  & .styled__ErrorMessage-sc-9m9tzm-7 {
    position: absolute;
    left: -70px;
    bottom: -23px;
    width: 310px;
    text-align: left;
  }
`

export const TextField = styled(FormikField)`
  margin-bottom: 20px;
  & .styled__ErrorMessage-sc-9m9tzm-7 {
    text-align: left;
  }
`

export const Fields = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 0.4rem;
  flex-direction: row;
  margin-bottom: 36px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.concrete};
`

export const Collapsibles = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.8rem;
  margin-bottom: 36px;
`

export const StyledRadiosGroup = styled(FormFieldRadioGroup)`
  margin-left: 3.5rem;
  padding: 6px 0px;
`

export const StyledCollapsible = styled(CollapsibleColoredItem)`
  border-radius: 0.4rem;
  & ${StyledRadiosGroup} {
    margin-top: 1.2rem;
  }
`
