import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const createUser = getMutationService({
  mutation: gql(`
    mutation authCreateUser($input: AuthCreateUserInput!) {
        authCreateUser(input: $input) {
            id
            type
            role
            email
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
