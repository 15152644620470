import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LayoutNotConnectedMocks } from '../../layouts/LayoutNotConnected/mocks'
import { ResetPasswordProps } from '../ResetPassword'
import { resetPasswordFormMocks } from '../../components/forms/ResetPasswordForm/mocks'
import { actions, selectors } from '../../redux'

export const useResetPasswordTemplateProps = (code: string, email: string): ResetPasswordProps => {
  const dispatch = useDispatch()
  const resetPassword = useSelector(selectors.auth.authResetPassword)

  return useMemo(
    () => ({
      title: 'Réinitialisation du mot de passe',
      layout: LayoutNotConnectedMocks.basic,
      form: {
        ...resetPasswordFormMocks.basic,
        formikForm: {
          ...resetPasswordFormMocks.basic.formikForm,
          initialValues: {
            ...resetPasswordFormMocks.basic.formikForm.initialValues,
          },
          onSubmit: (values) => {
            dispatch(
              actions.auth.authResetPasswordRequest({
                code: code,
                email: email,
                password: values.password,
              })
            )
          },
        },
        errors: resetPassword.errors,
        submitButton: {
          isPending: resetPassword.pending,
          isSuccess: resetPassword.success,
          text: 'Se connecter',
          pendingText: 'Pending...',
        },
      },
    }),
    [code, dispatch, email, resetPassword.errors, resetPassword.pending, resetPassword.success]
  )
}
