import styled from 'styled-components'

export const Container = styled.div``

export const Options = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1.2rem;
`

export const Option = styled.label<{
  $isOptionDisabled?: boolean
  $isInputDisabled?: boolean
}>`
  opacity: ${({ $isInputDisabled, $isOptionDisabled }) =>
    $isInputDisabled || $isOptionDisabled ? '0.5' : '1'};
  cursor: ${({ $isInputDisabled, $isOptionDisabled }) =>
    $isInputDisabled || $isOptionDisabled ? 'not-allowed' : 'pointer'};
`

export const OptionRadio = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`

export const Label = styled.span`
  ${({ theme }) => theme.textStyles.titleH5}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 5.5rem;
  border-radius: 0.6rem;
  color: ${({ theme }) => theme.colors.tundora};
  background: ${({ theme }) => theme.colors.concrete};
  transition: transform 200ms ease-in, background-color 150ms ease-in, color 150ms ease-in,
    font-size 200ms ease-in;
  input:not(:disabled) ~ &:hover {
    color: ${({ theme }) => theme.colors.alabaster};
    background: ${({ theme }) => theme.colors.mineShaft};
  }
  input:checked ~ & {
    font-size: 2.6rem;
    color: ${({ theme }) => theme.colors.alabaster};
    transform: scale(1.15);
    background: ${({ theme }) => theme.colors.mineShaft};
  }
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.gray};
  text-align: right;
  margin-top: 0.6rem;
`
export const Error = styled.p`
  ${({ theme }) => theme.textStyles.textItalic}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.6rem;
  text-align: right;
`
