import { loginFormMocks } from '../../components/forms/LoginForm/mocks'
import { LayoutNotConnectedMocks } from '../../layouts/LayoutNotConnected/mocks'

import { LoginTemplateProps } from './index'

const basic: LoginTemplateProps = {
  title: 'Connexion',
  layout: LayoutNotConnectedMocks.basic,
  loginForm: loginFormMocks.basic,
}

export const loginTemplateMocks = {
  basic,
}
