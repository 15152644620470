import { ImgHTMLAttributes, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { EditoCardProps } from '../../components/EditoCard'
import { Editorial, Sample } from '../../graphql/generated/graphql'

import { EditoAddProps } from '.'

export const useSampleSheetEditoAddTemplateProps = (
  setMode: React.Dispatch<React.SetStateAction<string>>,
  search: string,
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void,
  sample: Sample | undefined
): EditoAddProps => {
  const dispatch = useDispatch()
  const { sampleId } = useParams<{ sampleId: string }>()
  const editos = useSelector(selectors.editos.editorials)
  const editoAttach = useSelector(selectors.editos.editorialAttachSamples)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const [parsedEditos, setParsedEditos] = useState<EditoCardProps[] | undefined>(undefined)
  const [selectedEditos, setSelectedEditos] = useState<string[]>([])

  useEffect(() => {
    if (editoAttach && editoAttach.success) setMode('list')
  }, [editoAttach, setMode])

  useEffect(() => {
    if (editos && editos) {
      const parseEditosCards = () => {
        const newCards: EditoCardProps[] = []
        editos.forEach((edito: Editorial) => {
          const photos: ImgHTMLAttributes<HTMLImageElement>[] = []
          edito &&
            edito.samples &&
            edito.samples.forEach((sample) => {
              if (sample.photos) {
                sample.photos.forEach((photo) => {
                  photos.push({ src: photo.url, alt: photo.id })
                })
              }
            })
          const card: EditoCardProps = {
            //@ts-ignore
            id: edito.id,
            title: edito.name,
            text: edito.description,
            images: photos,
            selected: selectedEditos.includes(edito.id),
            itemNbr: edito.samples.length.toString(),
            onClick: () => {
              setSelectedEditos((prevSelectedEditos) => {
                if (prevSelectedEditos.includes(edito.id)) {
                  return prevSelectedEditos.filter((id) => id !== edito.id)
                } else {
                  return [...prevSelectedEditos, edito.id]
                }
              })
            },
          }
          newCards.push(card)
        })
        return newCards
      }
      setParsedEditos(parseEditosCards)
    }
  }, [editos, selectedEditos])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            onClick() {
              setMode('list')
            },
          },
          next: {
            text: 'Valider',
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
            disabled: selectedEditos.length === 0,
            onClick: () => {
              if (selectedEditos.length !== 0 && sampleId) {
                selectedEditos.forEach((editoId) => {
                  dispatch(
                    actions.editos.editorialAttachSamplesRequest({
                      editorialId: editoId,
                      samplesIds: [sampleId],
                    })
                  )
                })
              }
              setMode('list')
            },
          },
        },
      },
      subTitle: 'édito',
      text: 'À quel édito souhaitez-vous ajouter les échantillons ?',
      editoList:
        parsedEditos &&
        parsedEditos.filter((edito) => {
          if (sample && sample.editorials) {
            const filterIds = sample.editorials.map((filter) => filter.id)
            //@ts-ignore
            return !filterIds.includes(edito.id)
          }
          return undefined
        }),
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
    }),
    [
      dispatch,
      handleSearchChange,
      layoutProps,
      parsedEditos,
      sample,
      sampleId,
      search,
      selectedEditos,
      setMode,
    ]
  )
}
