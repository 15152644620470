import styled from 'styled-components'

import Heading from '../../components/Heading'
import ActionButton from '../../components/ActionButton'
import ContentWrapper from '../../components/ContentWrapper'
import { breakpoints } from '../../theme'
import FormikForm from '../../components/form/FormikForm'
import FormFieldSwitch from '../../components/form/fields/FormFieldSwitch'

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.4rem;
  padding-block: 2.4rem;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem;
  }
`

export const Form = styled(FormikForm)`
  flex: 1 1 auto;
  display: flex;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const AllCollectionSwitch = styled(FormFieldSwitch)`
  ${({ theme }) => theme.textStyles.textBook}
  &.is-on {
    ${({ theme }) => theme.textStyles.textBold}
  }
`
export const Title = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.mineShaft};
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.textBook}
  color: ${({ theme }) => theme.colors.mineShaft};
`

export const Subtitle = styled(Heading)`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.4rem;
`

export const StyledActionButton = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`
