import { bottomActionsMocks } from '../../components/BottomActions/mocks'
import { Icons } from '../../components/Icon'
import { stepsMocks } from '../../components/Steps/mocks'

import { LayoutSampleDataProps } from './index'

const basic: LayoutSampleDataProps = {
  title: 'échantillon #1',
  steps: { ...stepsMocks.sampleData },
}

const withTestButtons: LayoutSampleDataProps = {
  ...basic,
  bottomActions: {
    ...bottomActionsMocks.basic,
    back: {
      leftIconProps: {
        icon: Icons.arrowLeft,
        width: 18,
        height: 18,
      },
      rounded: true,
    },
    note: {
      rounded: true,
      number: '1',
    },
    next: {
      text: 'Suivant',
      rightIconProps: {
        icon: Icons.arrowRight,
        width: 18,
        height: 18,
      },
    },
  },
}

export const layoutSampleDataMocks = {
  basic,
  withTestButtons,
}
