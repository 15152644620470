import React, { ImgHTMLAttributes } from 'react'

import * as SC from './styled'

export type ImageColumnsProps = {
  images: ImgHTMLAttributes<HTMLImageElement>[]
}

const ImageColumns = ({ images }: ImageColumnsProps) => {
  const numImages = images.length

  const createColumn = (colImages: ImgHTMLAttributes<HTMLImageElement>[], isBig?: boolean) => {
    return (
      <SC.Column key={colImages[0].src}>
        {colImages.map((image) => (
          <SC.Image key={image.src} src={image.src} isBig={isBig} />
        ))}
      </SC.Column>
    )
  }

  if (numImages >= 8) {
    const numCols = 4
    const numRows = Math.ceil(Math.min(images.length, 8) / numCols)
    const columns = Array.from({ length: numCols }, (_, i) => {
      const colImages = Array.from({ length: numRows }, (_, j) => images[j * numCols + i])
      return createColumn(colImages)
    })
    return <SC.Container>{columns}</SC.Container>
  }

  if (numImages >= 6) {
    const firstImage = images[0]
    const restImages = images.slice(1)
    const columns = Array.from({ length: 3 }, (_, i) => {
      const colImages = restImages.slice(i * 2, i * 2 + 2)
      return createColumn(colImages)
    })
    return (
      <SC.Container>
        {createColumn([firstImage], true)}
        {columns}
      </SC.Container>
    )
  }

  if (numImages === 5) {
    const firstFourImages = images.slice(0, 4)
    const lastImage = images[numImages - 1]
    return (
      <SC.Container>
        {createColumn(firstFourImages.slice(0, 2))}
        {createColumn(firstFourImages.slice(2, 4))}
        {createColumn([lastImage], true)}
      </SC.Container>
    )
  }

  return (
    <SC.Container>
      {images.map((image) => (
        <SC.ImageBasis numImages={numImages} key={image.src} src={image.src} />
      ))}
    </SC.Container>
  )
}

export default ImageColumns
