import { FiltersSmallProps } from './index'

const usersAccess: FiltersSmallProps = {
  formikForm: {
    initialValues: {
      univers: [],
      sectors: [],
      countries: [],
    },
    onSubmit: (values) => console.log('values', values),
    validateOnChange: true,
  },
  field: {
    name: 'univers',
    label: 'Univers',
    required: true,
    displayLabel: false,
    options: [
      {
        label: 'DSI - Super admin',
        value: 'dsi',
        display: 'simple',
      },
      {
        label: 'Équipe mode PV',
        value: 'team-pv',
        display: 'simple',
      },
      {
        label: 'Équipe marketplace',
        value: 'team-marketplace',
        display: 'simple',
      },
      {
        label: 'REX',
        value: 'rex',
        display: 'simple',
      },
      {
        label: 'Expert mode',
        value: 'expert-mode',
        display: 'simple',
      },
      {
        label: 'Opérateur de saisie',
        value: 'operator',
        display: 'simple',
      },
      {
        label: 'Équipe shooting',
        value: 'team-shooting',
        display: 'simple',
      },
      {
        label: 'Stagiaire',
        value: 'trainee',
        display: 'simple',
      },
    ],
  },
}

export const filtersSmallMocks = {
  usersAccess,
}
