import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'
import { Icons } from '../../components/Icon'

import * as SC from './styled'

export type ImportDelayTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title: string
  text: string
  delay: number
  handleDelayChange?: (value: number) => void
  toastModal?: ModalToastProps
}

const ImportDelayTemplate: FC<ImportDelayTemplateProps> = ({
  layoutProps,
  title,
  text,
  delay,
  handleDelayChange,
  toastModal,
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={<>{toastModal && <ModalToast {...toastModal} />}</>}
    >
      <ContentWrapper maxWidth={'default'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Text dangerouslySetInnerHTML={{ __html: text }} />
        {handleDelayChange && (
          <SC.InputContainer>
            <SC.Input
              type="number"
              id="delayInput"
              value={delay}
              onChange={(e) => handleDelayChange(Number(e.target.value))}
            />
            <SC.ButtonContainer>
              <SC.IconContainer onClick={() => handleDelayChange(delay + 1)}>
                <SC.IncrementIcon icon={Icons.chevronDown} />
              </SC.IconContainer>
              <SC.IconContainer onClick={() => handleDelayChange(delay - 1)}>
                <SC.DecrementIcon icon={Icons.chevronDown} />
              </SC.IconContainer>
            </SC.ButtonContainer>
          </SC.InputContainer>
        )}
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default ImportDelayTemplate
