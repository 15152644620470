import React, { FC } from 'react'

import Paragraph from '../Paragraph'
import { Icons } from '../Icon'
import SimpleLoader from '../SimpleLoader'

import * as SC from './styled'

export type SimpleCardProps = {
  className?: string
  topInfo?: {
    tag?: string | null | undefined
    name?: string | null | undefined
    universe?: string
    pavilion?: string
    newTag?: boolean
    returnTag?: boolean
  }
  bottomInfo?: {
    leftText: string
    rightText: string
  }
  selected?: boolean
  onClick?: () => void
}

const SimpleCard: FC<SimpleCardProps> = ({ className, topInfo, bottomInfo, selected, onClick }) => {
  return (
    <SC.Card className={className} $selected={selected} onClick={onClick} $isSelectable={!!onClick}>
      {topInfo ? (
        <SC.Top $hasBottom={!!bottomInfo}>
          <SC.TitleContainer>
            {topInfo.tag && <SC.Tag>{topInfo.tag}</SC.Tag>}
            <SC.TagContainer>
              {topInfo.newTag && <SC.NewIcon icon={Icons.new} width={18} height={18} />}
              {topInfo.returnTag && <SC.NewIcon icon={Icons.return} width={18} height={18} />}
            </SC.TagContainer>
          </SC.TitleContainer>
          {topInfo.name && <SC.Name>{topInfo.name}</SC.Name>}
          {(topInfo.universe || topInfo.pavilion) && (
            <SC.Category>
              {topInfo.universe} <SC.StyledDot variant="forumSelect" little /> {topInfo.pavilion}
            </SC.Category>
          )}
        </SC.Top>
      ) : (
        <SimpleLoader />
      )}
      {bottomInfo && (
        <SC.Bottom>
          <Paragraph text={bottomInfo.leftText} variant={'titleH4'} removeMargins />
          <Paragraph
            text={bottomInfo.rightText}
            variant={'titleH5'}
            color={'tundora'}
            removeMargins
          />
        </SC.Bottom>
      )}
    </SC.Card>
  )
}

export default SimpleCard
