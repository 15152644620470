import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'
import { api } from '../../configuration'

export default class FiltersSagas {
  static *onInit() {
    yield put(actions.configuration.getConfigurationInfoRequest())
  }

  static *onConfigurationSearch() {
    const rs = yield* ApiSagas.call(services.configuration.queries.configuration)

    if (rs.errors) {
      yield put(actions.configuration.getConfigurationInfoError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.configuration.setConfiguration(rs.data.configuration))
      return
    }
  }

  static *onExportRedPoints() {
    const rs = yield* ApiSagas.call(services.configuration.queries.exportRedPoints)

    if (rs.errors) {
      yield put(actions.configuration.exportRedPointsError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.configuration.exportRedPointsSuccess())
      return
    }
  }

  static *onUpload({ payload }: ReturnType<typeof actions.configuration.uploadRequest>) {
    const data = new FormData()
    data.append('file', payload.file)

    //@ts-ignore
    const header: any = yield call(ApiSagas.getHeaders)
    //@ts-ignore
    const rs: any = yield call(axios.post, api.url + '/api/upload', data, {
      headers: { Authorization: header.Authorization, 'Content-Type': 'multipart/form-data' },
    })

    if (rs.errors) {
      yield put(actions.configuration.uploadError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.configuration.uploadSuccess())
      if (payload.sampleLabelId === undefined) {
        const rsSampleCreate = yield* ApiSagas.call(
          services.samples.mutations.sampleCreateImageLabel,
          {
            sampleId: payload.sampleId,
            uploadPath: rs.data.file,
          }
        )
        if (rsSampleCreate.errors) {
          yield put(actions.samples.sampleCreateImageLabelError(rs.errors))
          return
        }
        if (rsSampleCreate.data) {
          yield put(actions.samples.sampleCreateImageLabelSuccess())
          return
        }
      }
      if (payload.sampleLabelId) {
        const rsSampleUpdate = yield* ApiSagas.call(
          services.samples.mutations.sampleUpdateImageLabel,
          {
            sampleLabelId: payload.sampleLabelId,
            uploadPath: rs.data.file,
          }
        )
        if (rsSampleUpdate.errors) {
          yield put(actions.samples.sampleUpdateImageLabelError(rs.errors))
          return
        }
        if (rsSampleUpdate.data) {
          yield put(actions.samples.sampleUpdateImageLabelSuccess())
          return
        }
      }
    }
  }

  static *onImportExhibitorEbms({
    payload,
  }: ReturnType<typeof actions.configuration.uploadRequest>) {
    const data = new FormData()
    data.append('file', payload.file)

    //@ts-ignore
    const header: any = yield call(ApiSagas.getHeaders)
    //@ts-ignore
    const rs: any = yield call(axios.post, api.url + '/api/upload', data, {
      headers: { Authorization: header.Authorization, 'Content-Type': 'multipart/form-data' },
    })

    if (rs.errors) {
      yield put(actions.configuration.uploadError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.configuration.uploadSuccess())
      const rsImportExhibitor = yield* ApiSagas.call(
        services.configuration.mutations.importExhibitorEbms,
        {
          filePath: rs.data.file,
        }
      )
      if (rsImportExhibitor.errors) {
        yield put(actions.configuration.importExhibitorEbmsError(rs.errors))
        return
      }
      if (rsImportExhibitor.data) {
        yield put(actions.configuration.importExhibitorEbmsSuccess())
        return
      }
    }
  }

  static *onConfigurationUpdate({
    payload,
  }: ReturnType<typeof actions.configuration.configurationUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.configuration.mutations.configurationUpdate, payload)

    if (rs.errors) {
      yield put(actions.configuration.configurationUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.configuration.configurationUpdateSuccess())
    }
  }

  static *listeners() {
    yield all([
      takeEvery(actions.configuration.getConfigurationInfoRequest, this.onConfigurationSearch),
      takeEvery(actions.configuration.exportRedPointsRequest, this.onExportRedPoints),
      takeEvery(actions.configuration.uploadRequest, this.onUpload),
      takeEvery(actions.configuration.importExhibitorEbmsRequest.type, this.onImportExhibitorEbms),
      takeEvery(actions.configuration.configurationUpdateRequest, this.onConfigurationUpdate),
    ])
  }
}
