import React, { FC } from 'react'

import LayoutSampleData, { LayoutSampleDataProps } from '../../layouts/LayoutSampleData'
import { SummaryProps } from '../../components/Summary'

import * as SC from './styled'

export type SampleDataSummaryTemplateProps = {
  layoutProps?: LayoutSampleDataProps
  title?: string
  summary?: SummaryProps
  error?: string
}

const SampleDataSummaryTemplate: FC<SampleDataSummaryTemplateProps> = ({
  layoutProps,
  title,
  summary,
  error,
}) => {
  return (
    <LayoutSampleData {...layoutProps}>
      <SC.Content>
        {title && <SC.Title tag="h2" text={title} />}
        {summary && <SC.StyledSummary {...summary} />}
        {error && <SC.Error>{error}</SC.Error>}
      </SC.Content>
    </LayoutSampleData>
  )
}

export default SampleDataSummaryTemplate
