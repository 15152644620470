import styled from 'styled-components'

import Heading from '../../components/Heading'
import { breakpoints, fonts } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'
import Divider from '../../components/Divider'
import Steps from '../../components/Steps'
import CollapsibleColoredItem from '../../components/CollapsibleColored'
import ContentWithSteps from '../../components/ContentWithSteps'

export const Content = styled(ContentWithSteps)``

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
    margin-bottom: 7rem;
  }
`

export const StyledSteps = styled(Steps)``

export const PageTitle = styled.h2`
  ${fonts.GothamBold};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-top: 0.7rem;
  ${breakpoints.up('tablet')} {
    padding-top: 4.4rem;
  }
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
  ${breakpoints.up('tablet')} {
    margin-top: 1rem;
  }
`

export const Collapsible = styled(CollapsibleColoredItem)`
  margin-bottom: 1.2rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
`

export const StyledDivider = styled(Divider)``

export const Resume = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.6rem;
  padding: 1.2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tundora};
`

export const ResumeSubForum = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.2rem 0;
  margin-top: 24px;
  gap: 0.6rem;
`

export const ResumeTitle = styled.div`
  ${fonts.RobotoExtraLight};
  font-size: 1.4rem;
  letter-spacing: 0.5px;
`

export const ResumeText = styled.div`
  font-size: 1.4rem;
  ${fonts.GothamBold};
  letter-spacing: 0.5px;
`

export const SubForum = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.tundora};
  ${({ theme }) => theme.textStyles.titleH6}
`
export const Error = styled.div`
  color: ${({ theme }) => theme.colors.cinnabar};
  ${({ theme }) => theme.textStyles.textItalic};
  padding: 12px;
  text-align: center;
  max-width: 400px;
`
