import styled from 'styled-components'

import QrScanner from '../../components/QrScanner'
import { breakpoints } from '../../theme'
import Icon from '../../components/Icon'

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const Scanner = styled(QrScanner)`
  max-height: 100vh;
  width: 100%;
  ${breakpoints.up('tablet')} {
    overflow: hidden;
  }
`

export const Close = styled(Icon)`
  position: fixed;
  cursor: pointer;
  top: 26px;
  right: 26px;
  z-index: 10;
  ${breakpoints.up('tablet')} {
    top: 26px;
  }
`
