import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import MultipleScanTemplate from '../../../templates/MultipleScan'
import { useMultipleEditionScanTemplateProps } from '../../../templates/MultipleScan/useProps'
import { selectors, actions } from '../../../redux'
import { Icons } from '../../../components/Icon'
import ModalToast from '../../../components/ModalToast'

const MultipleEditionScan: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const templateProps = useMultipleEditionScanTemplateProps()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const sampleUpdateMany = useSelector(selectors.samples.sampleUpdateMany)
  const editorialAttachSamples = useSelector(selectors.editos.editorialAttachSamples)

  useEffect(() => {
    if (
      (sampleUpdateMany && sampleUpdateMany.success) ||
      (editorialAttachSamples && editorialAttachSamples.success)
    ) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        sampleUpdateMany &&
          sampleUpdateMany.success &&
          dispatch(actions.samples.resetSampleUpdateMany())
        editorialAttachSamples &&
          editorialAttachSamples.success &&
          dispatch(actions.editos.resetEditorialAttachSamples())
      }, 2000)
    }
  }, [dispatch, editorialAttachSamples, sampleUpdateMany])

  return (
    <>
      {isModalVisible && (
        <ModalToast
          text="Les changements aux échantillons scannés ont bien été pris en compte."
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}
      <MultipleScanTemplate {...templateProps} />
    </>
  )
}

export default MultipleEditionScan
