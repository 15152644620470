import React, { FC } from 'react'
import { DateRange } from 'react-day-picker'

import LayoutLoungeConfig, { LayoutLoungeConfigProps } from '../../layouts/LayoutLoungeConfig'
import { LoungeCardProps } from '../../components/LoungeCard'

import * as SC from './styled'

export type LoungeDatesTemplateProps = {
  layoutProps?: LayoutLoungeConfigProps
  title: string
  loungeCard?: LoungeCardProps
  calendar?: {
    currentMonth?: {
      year: number
      month: number
    }
    range?: DateRange | undefined
    setRange?: (newRange: DateRange | undefined) => void
  }
  error?: string
}

const LoungeDatesTemplate: FC<LoungeDatesTemplateProps> = ({
  layoutProps,
  title,
  loungeCard,
  calendar,
  error,
}) => {
  return (
    <LayoutLoungeConfig {...layoutProps}>
      <SC.Title tag={'h2'} text={title} />
      {loungeCard && (
        <SC.StyledLoungeCard
          title={loungeCard.title}
          text={loungeCard.text}
          season={loungeCard.season}
        />
      )}
      {calendar && <SC.Calendar pickMode="range" {...calendar} />}
      {error && <SC.Error>{error}</SC.Error>}
    </LayoutLoungeConfig>
  )
}

export default LoungeDatesTemplate
