import React, { FC } from 'react'

import { MenuLinkProps } from '../MenuLink'

import * as SC from './styled'

export type MenuDesktopProps = {
  className?: string
  links: Array<MenuLinkProps>
  route?: string
}

const MenuDesktop: FC<MenuDesktopProps> = ({ className, links }) => {
  return (
    <SC.Menu className={className}>
      <SC.List>
        {links.map((link, idx) => (
          <SC.Link
            key={`menu-link-${idx}`}
            removeBorder={idx === links.length - 1}
            {...link}
            selected={link.ariaCurrent}
          />
        ))}
      </SC.List>
    </SC.Menu>
  )
}

export default MenuDesktop
