import React, { FC } from 'react'

import { Icons } from '../Icon'

import * as SC from './styled'

export type NoteTagProps = {
  className?: string
  variant: SC.TagVariant
  text: string
}

const NoteTag: FC<NoteTagProps> = ({ className, text, variant }) => {
  return (
    <SC.Tag className={className} $variant={variant}>
      {variant === 'new' && <SC.NewIcon icon={Icons.new} width={18} height={18} />}
      {variant === 'return' && <SC.NewIcon icon={Icons.return} width={18} height={18} />}
      <SC.Text text={text} variant="titleH6" />
    </SC.Tag>
  )
}

export default NoteTag
