import styled from 'styled-components'

import { breakpoints } from '../../theme'
import EditoCard from '../../components/EditoCard'
import ActionButton from '../../components/ActionButton'
import Heading from '../../components/Heading'
import SimpleLoader from '../../components/SimpleLoader'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpoints.up('tablet')} {
  }
`

export const Loader = styled(SimpleLoader)`
  margin-left: 50%;
`

export const Edito = styled(EditoCard)``

export const Button = styled(ActionButton)`
  margin-top: 24px;
  width: fit-content;
`

export const Title = styled(Heading)`
  margin: 0;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.gray};
  ${breakpoints.up('tablet')} {
    text-align: center;
  }
`
