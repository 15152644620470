import React, { useEffect, useRef, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { FormikProps } from 'formik'
import { useSelector, useDispatch } from 'react-redux'

import AccountTemplate from '../../templates/Account'
import { useAccountTemplateProps } from '../../templates/Account/useProps'
import { AccountFormValues } from '../../components/forms/AccountForm'
import { actions, selectors } from '../../redux'

const MyAccount: React.FC<RouteProps> = () => {
  const templateProps = useAccountTemplateProps()
  const message = useSelector(selectors.user.successMessage)
  const dispatch = useDispatch()

  const [state, setState] = useState<any>('preview')
  const [isValid, setIsValid] = useState<boolean>(false)

  const [validPasswordLength, setValidPasswordLength] = useState<boolean>(false)
  const [validPasswordUppercase, setValidPasswordUppercase] = useState<boolean>(false)
  const [validPasswordSpecial, setValidPasswordSpecial] = useState<boolean>(false)
  const [validPasswordNumber, setValidPasswordNumber] = useState<boolean>(false)

  const handleOnChange = (value: string) => {
    const hasNumber = /[0-9]/.test(value)
    const hasUppercase = /[A-Z]/.test(value)
    const hasSpecial = /[`!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/.test(value)
    const hasLength = value.replaceAll(' ', '').length >= 12

    if (message !== undefined) {
      dispatch(actions.user.setSuccessMessage(undefined))
    }

    setValidPasswordLength(hasLength)
    setValidPasswordNumber(hasNumber)
    setValidPasswordSpecial(hasSpecial)
    setValidPasswordUppercase(hasUppercase)
  }

  const ref = useRef<FormikProps<AccountFormValues>>(null)

  useEffect(() => {
    if (ref.current) {
      handleOnChange(ref.current.values.newPassword)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ref.current) {
      ref.current.validateForm()
    }
  }, [])

  return (
    <AccountTemplate
      {...templateProps}
      message={message}
      form={{
        ...templateProps.form,
        state,
        formikForm: {
          ...templateProps.form.formikForm,
          onContextUpdate: (context) => {
            setIsValid(context.isValid)
          },
          innerRef: ref,
        },
        rules: {
          length: {
            isValid: validPasswordLength,
            rule: '12 caractères minimum',
          },
          uppercase: {
            isValid: validPasswordUppercase,
            rule: 'Majuscules',
          },
          number: {
            isValid: validPasswordNumber,
            rule: 'Chiffre',
          },
          specialCharacter: {
            isValid: validPasswordSpecial,
            rule: 'Caractères spéciaux',
          },
        },
        fields: {
          ...templateProps.form.fields,
          newPassword: {
            ...templateProps.form.fields.newPassword,
            onChangeCapture: (e: React.FormEvent<HTMLInputElement>) =>
              handleOnChange(e.currentTarget.value),
          },
        },
        submitButton: {
          ...templateProps.form.submitButton,
          disabled: !isValid,
        },
        previewButton: {
          ...templateProps.form.previewButton,
          onClick: () => setState('update'),
        },
      }}
    />
  )
}

export default MyAccount
