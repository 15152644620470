import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const authFirstLogin = getMutationService({
  mutation: gql(`
    mutation authFirstLogin(
      $code: String!
      $email: String!
      $password: String!
  ) {
      authFirstLogin(code: $code, email: $email, password: $password) {
          user {
            id
            type
            role
            firstName
            lastName
            email
            lastLoginAt
            externalAccessFrom
            externalAccessTo
            createdAt
            updatedAt
            type
          }
          token {
              expiresIn
              tokenType
              accessToken
          }
      }
  }
    `),
  transformer: (response) => {
    return response
  },
})
