import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormErrors from '../../form/FormErrors'
import FormFieldInputNumber from '../../form/fields/FormFieldInputNumber'
import Heading from '../../Heading'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
`
export const Title = styled(Heading)`
  margin: 0;
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
`
export const Fieldset = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.8rem;
`
export const StyledField = styled(FormFieldInputNumber)`
  & input {
    text-align: center;
    ${({ theme }) => theme.textStyles.titleH1}
  }
`
export const Errors = styled(FormErrors)``
