import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleUpdateMany = getMutationService({
  mutation: gql(`
    mutation sampleUpdateMany($sampleIds: [ID!]!, $input: SampleUpdateManyInput!) {
      sampleUpdateMany(sampleIds: $sampleIds, input: $input) {
        id
        name
        status
        mkpForum
        forum {
          id
          name
        }
        subForum {
          id
          name
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
