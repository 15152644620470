import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import { SearchBarProps } from '../../components/SearchBar'
import LayoutEditoSheet, { LayoutEditoSheetProps } from '../../layouts/LayoutEditoSheet'
import { ActionButtonProps } from '../../components/ActionButton'
import { FiltersTagsProps } from '../../components/FiltersTags'
import { SampleCardProps } from '../../components/SampleCard'
import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import { ActionButtonSize, ActionButtonScheme } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { FormFieldTextAreaProps } from '../../components/form/fields/FormFieldTextArea'
import FormikField from '../../components/form/FormikField'
import { FormErrorsProps } from '../../components/form/FormErrors'
import { FormFieldInputProps } from '../../components/form/fields/FormFieldInput'
import { FormikFormProps } from '../../components/form/FormikForm'

import * as SC from './styled'

export type EditoEditSheetFormValues = {
  title: string
  description: string
}

export type EditoSheetTemplateProps = {
  className?: string
  layoutProps?: LayoutEditoSheetProps
  subTitle?: string
  searchBar?: SearchBarProps
  searchButton?: ActionButtonProps
  scanButton?: ActionButtonProps
  cards?: SampleCardProps[]
  selectedCards?: SampleCardProps[]
  filtersTags: FiltersTagsProps
  deleteButton?: ActionButtonProps
  deleteEditoButton?: ActionButtonProps
  errors?: GraphqlErrors | null
  editMode?: boolean
  userAdmin?: boolean
  editForm: {
    title?: string
    formikForm: FormikFormProps<EditoEditSheetFormValues>
    fields: {
      title: {
        label?: string
        props: FormFieldInputProps
      }
      description: {
        label?: string
        props: FormFieldTextAreaProps
      }
    }
    errors?: FormErrorsProps['errors']
  }
}

const EditoSheetTemplate: FC<EditoSheetTemplateProps> = ({
  layoutProps,
  subTitle,
  searchBar,
  searchButton,
  scanButton,
  cards,
  selectedCards,
  filtersTags,
  deleteButton,
  deleteEditoButton,
  errors,
  editMode,
  editForm,
  userAdmin,
}) => {
  return (
    <LayoutEditoSheet {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        {!editMode ? (
          <>
            {userAdmin && <SC.SubTitle>{subTitle} </SC.SubTitle>}
            {searchBar && (
              <>
                <SC.Search {...searchBar} />
                <SC.Filter {...filtersTags} />
                <SC.ContentContainer>
                  {cards?.length !== 0 ? (
                    <SC.CardContainer>
                      {cards &&
                        Object.keys(cards).map((key, idx) => <SC.Card key={key} {...cards[idx]} />)}
                    </SC.CardContainer>
                  ) : (
                    <SC.CardContainer>Aucun échantillon</SC.CardContainer>
                  )}
                  {errors && <span>{errors.map((error) => error.label).join(', ')}</span>}
                </SC.ContentContainer>
              </>
            )}
            <SC.EditoListContainer>
              {!searchBar && (
                <>
                  {userAdmin && (
                    <SC.ButtonContainer>
                      <SC.Button {...searchButton} />
                      <SC.Button {...scanButton} />
                    </SC.ButtonContainer>
                  )}
                  <SC.ContentContainer>
                    {selectedCards?.length !== 0 ? (
                      <SC.CardContainer>
                        {selectedCards &&
                          Object.keys(selectedCards).map((key, idx) => (
                            <SC.Card key={key} {...selectedCards[idx]} />
                          ))}
                      </SC.CardContainer>
                    ) : (
                      <SC.CardContainer>Aucun échantillon sélectionné</SC.CardContainer>
                    )}
                  </SC.ContentContainer>
                </>
              )}
              {deleteButton && selectedCards && !searchBar && (
                <SC.Edit
                  variantSize={ActionButtonSize.Small}
                  variantScheme={ActionButtonScheme.Tertiary}
                  rightIconProps={{ icon: Icons.trash, width: 16, height: 16 }}
                  {...deleteButton}
                />
              )}
            </SC.EditoListContainer>
          </>
        ) : (
          <SC.Form {...editForm.formikForm}>
            {editForm.title && <SC.Title tag="h1" text={editForm.title} />}
            <SC.Fieldset>
              <FormikField Component={SC.StyledTextInput} {...editForm.fields.title.props} />
              <FormikField Component={SC.StyledTextarea} {...editForm.fields.description.props} />
            </SC.Fieldset>
            <SC.Delete
              variantSize={ActionButtonSize.Small}
              variantScheme={ActionButtonScheme.Tertiary}
              rightIconProps={{ icon: Icons.trash, width: 16, height: 16 }}
              type="button"
              {...deleteEditoButton}
            />
            {editForm.errors && <SC.Errors errors={editForm.errors} />}
          </SC.Form>
        )}
      </ContentWrapper>
    </LayoutEditoSheet>
  )
}

export default EditoSheetTemplate
