import React from 'react'
import { RouteProps } from 'react-router-dom'

import LoungesForumTemplate from '../../../templates/LoungesForum'
import { useLoungeForumsTemplateProps } from '../../../templates/LoungesForum/useProps'

const LoungesForums: React.FC<RouteProps> = () => {
  const templateProps = useLoungeForumsTemplateProps()

  return <LoungesForumTemplate {...templateProps} />
}

export default LoungesForums
