import React, { FC } from 'react'

import { ModalProps } from '../Modal'
import { ActionButtonProps } from '../ActionButton'
import { Icons } from '../Icon'
import { ActionButtonScheme, ActionButtonSize } from '../ActionButton/types'
import { ModalVariant } from '../Modal/types'

import * as SC from './styled'

export type ModalDeleteProps = ModalProps & {
  className?: string
  text: string
  textBold?: string
  lineBreak?: boolean
  secondText?: string
  warningText?: string
  cancelButton?: ActionButtonProps
  deleteButton?: ActionButtonProps
}

const ModalDelete: FC<ModalDeleteProps> = ({
  className,
  text,
  textBold,
  lineBreak,
  secondText,
  warningText,
  cancelButton,
  deleteButton,
  ...modal
}) => {
  return (
    <SC.Container className={className} variant={ModalVariant.Small} {...modal}>
      <SC.Content>
        {warningText && <SC.TextWarning text={warningText} />}
        {textBold && <SC.TextBold text={textBold} />}
        <SC.Text text={text} $lineBreak={lineBreak} />
        {secondText && <SC.TextBold text={secondText} />}
        <SC.CancelButton {...cancelButton} variantSize={ActionButtonSize.Large} />
        <SC.DeleteButton
          rightIconProps={{ icon: Icons.trash, width: 18, height: 18 }}
          {...deleteButton}
          variantScheme={ActionButtonScheme.Secondary}
          variantSize={ActionButtonSize.Large}
        />
      </SC.Content>
    </SC.Container>
  )
}

export default ModalDelete
