import styled, { css } from 'styled-components'
import Collapsible from 'react-collapsible'
import { Maybe } from 'graphql/jsutils/Maybe'

import Heading from '../../components/Heading'
import { breakpoints, colors } from '../../theme'
import ContentWrapper from '../../components/ContentWrapper'
import SearchBar from '../../components/SearchBar'
import Icon from '../../components/Icon'

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-block: 1.2rem;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem;
  }
`

export const Title = styled(Heading)`
  margin: 0;
`

export const Search = styled(SearchBar)`
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:first-child) {
      margin-top: 3.6rem;
    }
  }
`

export const Subtitle = styled(Heading)`
  ${({ theme }) => theme.textStyles.titleH4}
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('tablet')} {
    &:not(:first-child) {
      margin-top: 3.6rem;
    }
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${colors.gray};
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
`
export const Forums = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column;
  gap: 1.2rem;
  margin: 0;
  padding: 0;
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
`
export const Forum = styled.li``
export const StyledCollapsible = styled(Collapsible)``
const itemStyles = css<{ $color?: Maybe<string>; $backgroundColor?: string }>`
  // default
  --bg: ${({ theme }) => theme.colors.concrete};
  --bg--hover: ${({ $backgroundColor }) => $backgroundColor};
  --border-color: ${({ theme }) => theme.colors.concrete};
  --border-color--hover: ${({ $color }) => $color};
  --color: ${({ theme }) => theme.colors.tundora};
  // styles
  ${({ theme }) => theme.textStyles.titleH6}
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  min-height: 4.6rem;
  margin: 0;
  overflow: hidden;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  border-color: var(--border-color);
  background-color: var(--bg);
  border: 0.1rem solid var(--border-color);
  transition: all 200ms ease-in;
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`
export const Trigger = styled.span`
  ${itemStyles}
  cursor: pointer;
  &:hover {
    border-color: var(--border-color--hover);
    background-color: var(--bg--hover);
  }
`
export const TriggerIcon = styled(Icon)`
  flex: 0 0 auto;
  justify-self: flex-end;
  color: ${({ theme }) => theme.colors.tundora};
  margin-left: auto;
`
export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column;
  gap: 1.2rem;
  margin: 1.2rem 0 0;
  padding-left: 3rem;
`
export const Item = styled.li<{ $color?: Maybe<string>; $backgroundColor?: string }>`
  ${itemStyles}
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`
export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  white-space: normal;
`
export const Number = styled.span``
export const Error = styled.div`
  ${({ theme }) => theme.textStyles.titleH5}
  padding: 24px;
  width: 100%;
  text-align: center;
`
