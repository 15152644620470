import styled from 'styled-components'

import Heading from '../../components/Heading'
import { breakpoints } from '../../theme'
import Summary from '../../components/Summary'
import ActionButton from '../../components/ActionButton'
import FormikForm from '../../components/form/FormikForm'
import FormFieldCheckbox from '../../components/form/fields/FormFieldCheckbox'
import FormFieldInput from '../../components/form/fields/FormFieldInput'
import FormFieldInputNumber from '../../components/form/fields/FormFieldInputNumber'
import SearchBar from '../../components/SearchBar'
import FormFieldSwitch from '../../components/form/fields/FormFieldSwitch'
import SimpleLoader from '../../components/SimpleLoader'

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const Title = styled(Heading)`
  margin: 0;
`
export const Image = styled.img`
  object-fit: contain;
`
export const RefContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 6px;
`
export const Loader = styled(SimpleLoader)`
  margin-left: 50%;
  margin-right: 50%;
`
export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 12px;
  gap: 6px;
  ${breakpoints.up('tablet')} {
    padding-top: 0;
  }
`
export const ExhibitorName = styled.div`
  ${({ theme }) => theme.fonts.GothamBook}
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.tundora};
`
export const Label = styled(Heading)`
  color: ${({ theme }) => theme.colors.gray};
`
export const StyledSummary = styled(Summary)``
export const PictureTrigger = styled.button`
  width: 100%;
  position: relative;
  flex-direction: column;
  gap: 2.4rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0 none;
  cursor: pointer;
`
export const Separator = styled.hr`
  align-self: stretch;
  border: 0;
  margin: 0;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.tundora};
  ${breakpoints.up('tablet')} {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`
export const PictureButton = styled(ActionButton)`
  position: absolute;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(100% - 2.4rem);
  align-self: center;
  white-space: nowrap;
`
export const UploadButton = styled(ActionButton)`
  position: absolute;
  bottom: 4.4rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(100% - 2.4rem);
  align-self: center;
  white-space: nowrap;
`
export const Picture = styled.picture`
  display: block;
  border-radius: 1.4rem;
  overflow: hidden;
  & img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const CameraButton = styled.button`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30rem;
  min-width: min(90vw, 30rem);
  background: transparent;
  border: 0 none;
  cursor: pointer;
  & svg {
    width: 10rem;
    height: 10rem;
  }
`
export const RetakeButton = styled(ActionButton)`
  align-self: center;
`
export const Form = styled(FormikForm)``
export const InputName = styled(FormFieldInput)`
  display: flex;
  flex-wrap: wrap;
`
export const Checkboxes = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.8rem;
`
export const FieldSet = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.8rem;
`
export const StyledCheckbox = styled(FormFieldCheckbox)``
export const Percentage = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`
export const StyledInput = styled(FormFieldInputNumber)`
  ${({ theme }) => theme.textStyles.titleH4}
  align-items: flex-start;
  max-width: 3em;
  text-align: center;
`

export const Search = styled(SearchBar)``

export const AllCollectionSwitch = styled(FormFieldSwitch)`
  ${({ theme }) => theme.textStyles.textBook}
  margin-bottom: 2rem;
  &.is-on {
    ${({ theme }) => theme.textStyles.textBold}
  }
`
export const FormInput = styled.form`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`
export const StyledButton = styled(ActionButton)``
