import styled, { css } from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormSubmitButton from '../../form/FormSubmitButton'
import FormErrors from '../../form/FormErrors'
import Icon from '../../Icon'
import FormFieldInputPassword from '../../form/fields/FormFieldInputPassword'
import FormFieldInput from '../../form/fields/FormFieldInput'
import Link from '../../Link'
import Heading from '../../Heading'
import { fonts } from '../../../theme'
import ActionButton from '../../ActionButton'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`

export const Group = styled.div`
  margin-bottom: 1.2rem;
`

export const Title = styled(Heading).attrs({ tag: 'h5' })`
  color: ${({ theme }) => theme.colors.gray};
`
export const Value = styled(Heading).attrs({ tag: 'h5' })`
  padding: 1rem 0;
  ${({ theme }) => theme.textStyles.textBold};
  font-size: 1.2rem;
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  gap: 3.5rem;
  margin-bottom: 0.6rem;
`

export const FormTitle = styled.p`
  ${fonts.RobotoLight}
  font-size: 1.7rem;
  margin-bottom: 1.2rem;
`

export const FieldEmail = styled(FormFieldInput)`
  width: 100%;
`

export const FieldPassword = styled(FormFieldInputPassword)`
  width: 100%;
`

export const ForgottenPassword = styled(Link)`
  color: ${({ theme }) => theme.colors.gray};
`

export const EyeIcon = styled(Icon)``

export const Errors = styled(FormErrors)`
  padding: 6px 0;
`

export const Button = styled(ActionButton)`
  align-self: center;
  margin-top: 2.4rem;
`

export const SubmitButton = styled(FormSubmitButton)`
  align-self: center;
  margin-top: 3.6rem;
`

export const RulesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.8rem;
  margin: 1rem 0 3rem 0;
`

export const Rule = styled.p<{ $isValid: boolean }>`
  ${({ theme }) => theme.textStyles.titleH8};
  color: ${({ theme }) => theme.colors.silver};
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
  ${({ $isValid, theme }) =>
    $isValid &&
    css`
      color: ${theme.colors.chateauGreen};
    `};
`

export const Check = styled(Icon)<{ $isValid: boolean }>`
  color: ${({ theme }) => theme.colors.silver};
  ${({ $isValid, theme }) =>
    $isValid &&
    css`
      color: ${theme.colors.chateauGreen};
    `};
`
