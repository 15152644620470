import React, { FC } from 'react'

import LoungeSubForumForm, {
  LoungeSubForumFormProps,
} from '../../components/forms/LoungesSubForumForm'
import { ActionButtonProps } from '../../components/ActionButton'
import LayoutLoungeConfig, { LayoutLoungeConfigProps } from '../../layouts/LayoutLoungeConfig'
import LoungeSubForumChoiceForm, {
  LoungeSubForumChoiceFormProps,
} from '../../components/forms/LoungeSubForumChoiceForm'

import * as SC from './styled'

export type LoungesSubForumTemplateProps = {
  layoutProps?: LayoutLoungeConfigProps
  title?: string
  subTitle?: string
  buttonProps?: ActionButtonProps
  editMode?: boolean
  loungeSubForumFormProps: LoungeSubForumFormProps
  loungeSubForumChoiceFormProps: LoungeSubForumChoiceFormProps
}

const LoungesSubForumTemplate: FC<LoungesSubForumTemplateProps> = ({
  layoutProps,
  title,
  buttonProps,
  loungeSubForumFormProps,
  loungeSubForumChoiceFormProps,
  editMode,
  subTitle,
}) => {
  return (
    <LayoutLoungeConfig {...layoutProps}>
      <SC.Content>
        {title && <SC.Title tag={'h2'} text={title} />}
        {subTitle && <SC.SubTitle>{subTitle}</SC.SubTitle>}
        {!editMode ? (
          <>
            <LoungeSubForumForm {...loungeSubForumFormProps} />
            <SC.NewForumButton {...buttonProps} />
          </>
        ) : (
          <LoungeSubForumChoiceForm {...loungeSubForumChoiceFormProps} />
        )}
      </SC.Content>
    </LayoutLoungeConfig>
  )
}

export default LoungesSubForumTemplate
