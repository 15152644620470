import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { SearchBarProps } from '../../components/SearchBar'
import { TabsProps } from '../../components/Tabs'
import { AlphabeticSortProps } from '../../components/AlphabeticSort'
import { ExponentListProps } from '../../components/ExponentList'
import { FiltersTagsProps } from '../../components/FiltersTags'
import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import SimpleLoader from '../../components/SimpleLoader'

import * as SC from './styled'

export type ExponentSearchTemplateProps = {
  title: string
  layoutProps?: LayoutWithBottomActionsProps
  searchBar?: SearchBarProps
  tabs: TabsProps
  filters?: AlphabeticSortProps
  cards?: ExponentListProps
  filtersTags?: FiltersTagsProps
  errors?: GraphqlErrors | null
  pending?: boolean
}

const ExponentSearchTemplate: FC<ExponentSearchTemplateProps> = ({
  layoutProps,
  title,
  searchBar,
  tabs,
  cards,
  filtersTags,
  errors,
  pending,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Search {...searchBar} />
        <SC.StyledTabsBottom {...tabs} />
        {filtersTags && <SC.StyledFilterTags {...filtersTags} />}
        {cards && cards.cards?.length !== 0 ? (
          <SC.ContentContainer>
            <SC.StyledExponentList {...cards} />
          </SC.ContentContainer>
        ) : (
          <SC.ContentContainer>Aucun exposant</SC.ContentContainer>
        )}
        {errors && <span>{errors.map((error) => error.label).join(', ')}</span>}
        {pending && (
          <SC.LoaderContainer>
            {' '}
            <SimpleLoader />
          </SC.LoaderContainer>
        )}
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default ExponentSearchTemplate
