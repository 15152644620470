import { SampleMkpForum } from '../../graphql/generated/graphql'

export function convertMkpToString(input: SampleMkpForum): string {
  switch (input) {
    case SampleMkpForum.Forum:
      return 'Forum'
    case SampleMkpForum.Marketplace:
      return 'Marketplace'
    case SampleMkpForum.MarketplaceForum:
      return 'Marketplace + Forum'
    default:
      throw new Error("Valeur d'énumération non valide")
  }
}
