import React, { FC } from 'react'

import LayoutExponentSheet, { LayoutExponentSheetProps } from '../../layouts/LayoutExponentSheet'
import { LoungeList } from '../Lounges'
import ContentWrapper from '../../components/ContentWrapper'
import { LoungeCardProps } from '../../components/LoungeCard'

import * as SC from './styled'

export type ExponentSheetArchiveTemplateProps = {
  layoutProps?: LayoutExponentSheetProps
  pending?: boolean
  title?: string
  loungeList?: LoungeList[]
}

const ExponentSheetArchiveTemplate: FC<ExponentSheetArchiveTemplateProps> = ({
  layoutProps,
  pending,
  title,
  loungeList,
}) => {
  return (
    <LayoutExponentSheet {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        {pending ? (
          <SC.Loader />
        ) : (
          <>
            {title && <SC.Title tag={'h2'} text={title} />}
            {loungeList &&
              loungeList.map((item: LoungeList, key: number) => (
                <SC.Collapsible
                  key={key}
                  title={item.year}
                  isCollapsibleOpen={item.isCollapsibleOpen}
                >
                  {item.lounge.map((loungeItem: LoungeCardProps, index: number) => (
                    <SC.StyledLoungeCard
                      active={loungeItem.active}
                      key={index}
                      title={loungeItem.title}
                      text={loungeItem.text}
                      season={loungeItem.season}
                      onClick={loungeItem.onClick}
                    />
                  ))}
                </SC.Collapsible>
              ))}
          </>
        )}
      </ContentWrapper>
    </LayoutExponentSheet>
  )
}

export default ExponentSheetArchiveTemplate
