import styled, { css } from 'styled-components'

import ActionButton from '../ActionButton'
import NotificationIcon from '../NotificationIcon'
import { breakpoints } from '../../theme'

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 1;
  pointer-events: none;
`
export const Left = styled.div<{ $minorGap: boolean }>`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  pointer-events: all;
  ${breakpoints.up('tablet')} {
    flex-flow: column-reverse;
  }
  ${({ $minorGap }) =>
    $minorGap === true &&
    css`
      gap: 0.4rem;
      ${breakpoints.up('tablet')} {
        gap: 1.6rem;
      }
    `}
`
export const Right = styled.div<{ $minorGap: boolean }>`
  pointer-events: all;
  display: flex;
  flex-direction: row;
  gap: 20px;
  ${({ $minorGap }) =>
    $minorGap === true &&
    css`
      gap: 6px;
      ${breakpoints.up('tablet')} {
        gap: 20px;
      }
    `}
`

export const NotifIcon = styled(NotificationIcon)``

export const Button = styled(ActionButton)`
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
`
