/* eslint-disable no-case-declarations */
import { Sample, SampleLabel, User, UserRole } from '../../graphql/generated/graphql'
import { InitialSampleData, SampleData } from '../../types/sampleData'

export const mapSampleData = (
  data: Sample,
  page: string | undefined,
  previousPage: string | undefined,
  routeType: string | undefined,
  user: User | null
) => {
  const mappedData: SampleData = { ...InitialSampleData }
  data.flagItems.forEach((item) => {
    if (item.group && item.group.miraklRef) {
      switch (item.group.miraklRef) {
        case 'category':
          mappedData.category = item.name
          break
        case 'performance_code':
          const performanceCodeItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'performance_code')
            .map((item) => item.name)
          mappedData.performanceCode = performanceCodeItems
          break
        case 'NON_MARKETPLACE_forum':
          mappedData.mkpForum = item.name
          break
        case 'end_use':
        case 'end_use_leather':
        case 'yarn_end_use':
          const endUseItems = data.flagItems
            .filter(
              (item) =>
                item.group.miraklRef === 'end_use' ||
                item.group.miraklRef === 'end_use_leather' ||
                item.group.miraklRef === 'yarn_end_use'
            )
            .map((item) => item.name)
          mappedData.productDestination = endUseItems
          break
        case 'pattern':
          mappedData.pattern = item.name
          break
        case 'animal':
          mappedData.animal = item.name
          break
        case 'certification':
          const certificationItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'certification')
            .map((item) => item.name)
          mappedData.certifications = certificationItems
          break
        case 'NON_MARKETPLACE_shooting':
          const shootingItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'NON_MARKETPLACE_shooting')
            .map((item) => item.name)
          mappedData.shooting = shootingItems
          break
        case 'type_of_accessories':
          mappedData.accessorieType = item.name
          break
        case 'unit_value':
        case 'yarn_unit_value':
          mappedData.unitValue = item.name
          break
        case 'pattern_type':
          mappedData.patternType = item.name
          break
        case 'material':
          const materialItems = data.flagItems
            .filter((item) => item.group.miraklRef === 'material')
            .map((item) => item.name)
          mappedData.material = materialItems
          break
        case 'type_of_tanning':
          mappedData.tanningType = item.name
          break
        case 'yarn_number_end':
        case 'number_end':
          mappedData.numberEnd = item.name
          break
        case 'yarn_aspect':
        case 'aspect':
        case 'aspects':
          const aspectsItems = data.flagItems
            .filter(
              (item) =>
                item.group.miraklRef === 'aspect' ||
                item.group.miraklRef === 'aspects' ||
                item.group.miraklRef === 'yarn_aspect'
            )
            .map((item) => item.name)
          mappedData.aspect = aspectsItems
          break
        case 'yarn_technology_activity':
        case 'technology_activity':
          mappedData.technologyActivity = item.name
          break
        case 'type_of_manufacturing':
          mappedData.manufacturingType = item.name
          break
        case 'color':
          mappedData.color = item.name
          break
        default:
          break
      }
    }
  })
  mappedData.forum = data.forum?.name
  mappedData.subForum = data.subForum?.name
  mappedData.boxe = data.box?.name
  mappedData.exhibitorRef =
    user?.role !== UserRole.InputOperator
      ? data.name
      : user?.role === UserRole.InputOperator && data.name === 'ECHANTILLON'
      ? ''
      : data.name
  mappedData.label = data.labels ? data.labels.map((label: SampleLabel) => label.url) : ['']
  mappedData.routeType = routeType && routeType
  mappedData.page = page ? page : previousPage
  return mappedData
}
