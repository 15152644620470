import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'
import { ActionButtonSize, ActionButtonScheme } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type LayoutEditoSheetProps = LayoutWithBottomActionsProps & {
  title?: string
  subTitle?: string
  children?: React.ReactNode
  deleteModal?: ModalDeleteProps
  toastModal?: ModalToastProps
  editButton?: ActionButtonProps
}

const LayoutEditoSheet: FC<LayoutEditoSheetProps> = ({
  title,
  subTitle,
  children,
  deleteModal,
  toastModal,
  editButton,
  ...layoutProps
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={
        <>
          {toastModal && <ModalToast {...toastModal} />}
          {deleteModal && <ModalDelete {...deleteModal} />}
        </>
      }
    >
      <SC.TopContent maxWidth="default">
        {title && <SC.Title tag={'h2'} text={title} />}
        {subTitle && <SC.SubTitle tag={'h5'} text={subTitle} />}
        {editButton && (
          <SC.Edit
            variantSize={ActionButtonSize.Small}
            variantScheme={ActionButtonScheme.Tertiary}
            rightIconProps={{ icon: Icons.pencil, width: 16, height: 16 }}
            {...editButton}
          />
        )}
      </SC.TopContent>
      <SC.Separator />
      <SC.BottomContent maxWidth="default">{children}</SC.BottomContent>
    </LayoutWithBottomActions>
  )
}

export default LayoutEditoSheet
