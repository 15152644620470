import styled, { css } from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormSubmitButton from '../../form/FormSubmitButton'
import FormFieldText from '../../form/fields/FormFieldInput'
import FormErrors from '../../form/FormErrors'
import { colors, fonts } from '../../../theme'
import Icon from '../../Icon'
import FormFieldInputPassword from '../../form/fields/FormFieldInputPassword'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  margin-bottom: 1rem;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`

export const FieldText = styled(FormFieldText)`
  width: 100%;
`

export const FieldPassword = styled(FormFieldInputPassword)`
  width: 100%;
`

export const SectionTitle = styled.h3`
  ${fonts.RobotoLight}
  font-size: 1.7rem;
  letter-spacing: 1px;
`
export const PasswordText = styled.div`
  ${fonts.GothamBold}
  color: ${colors.gray};
  font-size: 1.1rem;
  letter-spacing: 1px;
`

export const EyeIcon = styled(Icon)``

export const Errors = styled(FormErrors)``

export const SubmitButton = styled(FormSubmitButton)`
  align-self: flex-end;
`

export const RulesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.8rem;
  margin: 1rem 0 3rem 0;
`

export const Rule = styled.p<{ $isValid: boolean }>`
  ${({ theme }) => theme.textStyles.titleH8};
  color: ${({ theme }) => theme.colors.silver};
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
  ${({ $isValid, theme }) =>
    $isValid &&
    css`
      color: ${theme.colors.chateauGreen};
    `};
`

export const Check = styled(Icon)<{ $isValid: boolean }>`
  color: ${({ theme }) => theme.colors.silver};
  ${({ $isValid, theme }) =>
    $isValid &&
    css`
      color: ${theme.colors.chateauGreen};
    `};
`
