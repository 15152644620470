import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { useSampleSheetTabsProps } from '../../relay/SampleSheet/useSampleSheetTabsProps'
import { actions, selectors } from '../../redux'
import {
  Box,
  ExhibitionForum,
  FlagItem,
  Maybe,
  SampleStatus,
  UserRole,
} from '../../graphql/generated/graphql'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutSampleSheetTemplateProps } from '../../layouts/LayoutSampleSheet/useProps'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { Icons } from '../../components/Icon'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import { router, routesPath } from '../../router'
import { mapSampleData } from '../../relay/DataSample/MapSampleData'
import { mapSampleDataAllInfo } from '../../relay/DataSample/MapSampleDataAllInfo'

import { SampleSheetShootingTemplateProps } from '.'

export const useSampleSheetShootingTemplateProps = (): SampleSheetShootingTemplateProps => {
  const { sampleId } = useParams<{ sampleId: string }>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [infoShooting, setInfoShooting] = useState<FlagItem[] | undefined>(undefined)
  const [forum, setForum] = useState<ExhibitionForum | undefined>(undefined)
  const [subForum, setSubForum] = useState<ExhibitionForum | undefined>(undefined)
  const [box, setBox] = useState<Maybe<Box> | undefined>(undefined)
  const [status, setStatus] = useState<SampleStatus | undefined>(undefined)
  const [mkpForum, setMkpForum] = useState<FlagItem | undefined>(undefined)
  const [category, setCategory] = useState<FlagItem | undefined>(undefined)
  const [routeType, setRouteType] = useState<string | undefined>(undefined)
  const sampleById = useSelector(selectors.samples.getSampleById)
  const sample = useSelector(selectors.samples.sample)
  const user = useSelector(selectors.auth.user)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const sampleData = useSelector(selectors.samples.sampleData)
  const isArchived = sample?.isArchived

  const sampleLayoutProps = useLayoutSampleSheetTemplateProps(
    status,
    forum,
    subForum,
    mkpForum,
    sample,
    category
  )
  const tabs = useSampleSheetTabsProps(sampleId, 'shooting', user?.role)

  useEffect(() => {
    if (sample) {
      sample.status && setStatus(sample.status)
      sample.subForum && setSubForum(sample.subForum)
      sample.forum && setForum(sample.forum)
      sample.forum && setBox(sample.box)
      if (sample.flagItems) {
        setInfoShooting(
          sample &&
            sample.flagItems &&
            sample.flagItems.filter(
              (item) => item.group && item.group.miraklRef === 'NON_MARKETPLACE_shooting'
            )
        )
        setMkpForum(
          sample &&
            sample.flagItems &&
            sample.flagItems.find(
              (item) => item.group && item.group.miraklRef === 'NON_MARKETPLACE_forum'
            )
        )
        setCategory(
          sample &&
            sample.flagItems &&
            sample.flagItems.find((item) => item.group && item.group.miraklRef === 'category')
        )
      }
    }
  }, [sample])

  useEffect(() => {
    if (sample?.universe) {
      if (
        sample?.universe &&
        user?.role !== UserRole.Intern &&
        user?.role !== UserRole.InputOperator
      ) {
        setRouteType(sample?.universe.name)
      }
      if (
        sample &&
        user?.role !== UserRole.Intern &&
        (user?.role === UserRole.Marketplace ||
          user?.role === UserRole.ModePv ||
          user?.role === UserRole.Admin ||
          user?.role === UserRole.SuperAdmin)
      ) {
        setRouteType(`${sample.universe.name}Marketplace`)
      } else if (
        sample.universe &&
        !(sample.universe.name === 'Smart' || sample.universe.name === 'Smart Creation') &&
        user?.role === UserRole.Intern
      ) {
        if (sample.universe.name === 'Fabrics' || sample.universe.name === 'Yarns') {
          setRouteType('Trainee')
        }
        if (sample.universe.name === 'Designs') {
          setRouteType('TraineeDesign')
        }
        if (sample.universe.name === 'Smart' || sample.universe.name === 'Smart Creation') {
          setRouteType('Smart')
        }
        if (
          sample.universe.name === 'Manufacturing' ||
          sample.universe.name === 'Manufacturing Leather'
        ) {
          setRouteType('TraineeManuf')
        }
        if (sample.universe.name === 'Leather' || sample.universe.name === 'Accessories') {
          setRouteType('TraineeShort')
        }
      }
    }
  }, [user?.role, sample])

  useEffect(() => {
    if (sample && sample.flagItems && routeType) {
      dispatch(
        actions.samples.setSampleData(mapSampleData(sample, 'sample', 'sample', routeType, user))
      )
      dispatch(actions.samples.setSampleDataAllInfo(mapSampleDataAllInfo(sample)))
    }
  }, [dispatch, routeType, sample, user])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...sampleLayoutProps,
        bottomActions: {
          ...sampleLayoutProps.bottomActions,
          back: undefined,
          next:
            !isArchived && user?.role !== UserRole.Shooting && user?.role !== UserRole.Rex
              ? {
                  text: 'Éditer la fiche',
                  rightIconProps: {
                    icon: Icons.pencil,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => {
                    if (
                      sample?.collection &&
                      sample?.collection.exhibitor &&
                      sample.universe &&
                      user?.role !== 'INTERN' &&
                      user?.role !== 'INPUT_OPERATOR' &&
                      (sample?.universe.name === 'Smart' ||
                        sample?.universe.name === 'Smart Creation')
                    ) {
                      const link = router(routesPath.exponentSampleDataSmartCreationChoice, {
                        exposantId: sample.collection.exhibitor.ebmsId,
                        sampleId: sample.id,
                      })
                      navigate(link)
                    } else if (
                      sample?.collection &&
                      sample?.collection.exhibitor &&
                      sample.universe &&
                      sample?.universe.name === 'Leather' &&
                      user?.role !== 'INTERN' &&
                      user?.role !== 'INPUT_OPERATOR'
                    ) {
                      const link = router(routesPath.exponentSampleDataLeatherChoice, {
                        exposantId: sample.collection.exhibitor.ebmsId,
                        sampleId: sample.id,
                      })
                      navigate(link)
                    } else {
                      if (sample?.collection && sample?.collection.exhibitor) {
                        const link = router(sampleDataRoutes(sampleData, 'next'), {
                          exposantId: sample.collection.exhibitor.ebmsId,
                          sampleId: sample.id,
                        })
                        navigate(link)
                      }
                    }
                  },
                }
              : undefined,
        },
        tabs: tabs,
      },
      pending: sampleById.pending,
      shootingInfos:
        infoShooting && infoShooting.length !== 0
          ? {
              infos: [
                {
                  title: 'Info shooting',
                  text: infoShooting
                    ? infoShooting.map((item) => item.name).join(' - ')
                    : 'Donnée manquante',
                },
              ],
            }
          : undefined,
      boxTitle: 'Caisse de rangement post-shooting',
      boxInfo: {
        text: forum?.name as string,
        secondText: box?.name,
        backgroundColor: getContrastColor(forum?.color as string),
        color: forum?.color as string,
        noHover: true,
      },
      sliderProps:
        sample && sample?.photos.length > 0
          ? {
              title: 'Galerie',
              image: sample.photos.map((photo) => ({
                src: photo.url,
                alt: photo.path,
              })),
            }
          : undefined,
    }),
    [
      box?.name,
      forum?.color,
      forum?.name,
      infoShooting,
      isArchived,
      layoutProps,
      navigate,
      sample,
      sampleById.pending,
      sampleData,
      sampleLayoutProps,
      tabs,
      user?.role,
    ]
  )
}
