import { createSelector, createSlice } from '@reduxjs/toolkit'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { User } from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const loginService = getCustomService<'login', null, { email: string; password: string }>(
  'login'
)

export const authByTokenService = getCustomService<'authByToken', null>('authByToken')

export const authFirstLoginService = getCustomService<
  'authFirstLogin',
  null,
  { code: string; email: string; password: string }
>('authFirstLogin')

export const authSendForgotPasswordNotificationService = getCustomService<
  'authSendForgotPasswordNotification',
  null,
  { email: string }
>('authSendForgotPasswordNotification')

export const authResetPasswordService = getCustomService<
  'authResetPassword',
  null,
  { code: string; email: string; password: string }
>('authResetPassword')

export const authCheckEmailExistsService = getCustomService<
  'authCheckEmailExists',
  boolean,
  { email: string }
>('authCheckEmailExists')

export const authUserSetLastVisitedService = getCustomService<
  'authUserSetLastVisited',
  null,
  { href: string }
>('authUserSetLastVisited')

//
// Initial state
//

export type AuthState = {
  user: User | null
  token: string | null
  impersonate?: boolean
  isUserPv: boolean | undefined
  successMessage: string | undefined
  userLastVisited: string | null | undefined
  // services
  login: typeof loginService.state
  authByToken: typeof authByTokenService.state
  authFirstLogin: typeof authFirstLoginService.state
  authSendForgotPasswordNotification: typeof authSendForgotPasswordNotificationService.state
  authResetPassword: typeof authResetPasswordService.state
  authCheckEmailExists: typeof authCheckEmailExistsService.state
  authUserSetLastVisited: typeof authUserSetLastVisitedService.state
}

const initialState: AuthState = {
  user: null,
  token: null,
  impersonate: false,
  isUserPv: undefined,
  successMessage: undefined,
  userLastVisited: null,
  // services
  login: loginService.state,
  authByToken: authByTokenService.state,
  authFirstLogin: authFirstLoginService.state,
  authSendForgotPasswordNotification: authSendForgotPasswordNotificationService.state,
  authResetPassword: authResetPasswordService.state,
  authCheckEmailExists: authCheckEmailExistsService.state,
  authUserSetLastVisited: authUserSetLastVisitedService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setUser: (state, { payload }: actionTypes.setUser) => {
      state.user = payload
    },
    setUserLastVisited: (state, { payload }: actionTypes.userLastVisited) => {
      state.userLastVisited = payload
    },
    setToken: (state, action: actionTypes.setToken) => {
      state.token = action.payload
    },
    setImpersonate: (state, action: actionTypes.setImpersonate) => {
      state.impersonate = action.payload
    },
    onAuthByAzure: (_state, _action: actionTypes.onAuthByAzure) => undefined,
    setSuccessMessage: (state, action: actionTypes.setSuccessMessage) => {
      state.successMessage = action.payload
    },
    resetAuthResetPassword: (state) => {
      state.authResetPassword = initialState.authResetPassword
    },
    resetAuthCheckEmailExistsService: (state) => {
      state.authCheckEmailExists = initialState.authCheckEmailExists
    },
    resetAuthForgottenPasswordNotification: (state) => {
      state.authSendForgotPasswordNotification = initialState.authSendForgotPasswordNotification
    },
    ...loginService.reducers,
    ...authByTokenService.reducers,
    ...authFirstLoginService.reducers,
    ...authSendForgotPasswordNotificationService.reducers,
    ...authResetPasswordService.reducers,
    ...authCheckEmailExistsService.reducers,
    ...authUserSetLastVisitedService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const user = (state: RootState) => root(state).user
const setUserLastVisited = (state: RootState) => root(state).userLastVisited
const impersonate = (state: RootState) => root(state).impersonate
const successMessage = (state: RootState) => root(state).successMessage
const token = (state: RootState) => root(state).token
const jwt = createSelector([token], (tokenString): JwtPayload | null => {
  return tokenString ? jwtDecode<JwtPayload>(tokenString) : null
})
const isConnected = createSelector([token], (t): boolean => {
  return !!t && t !== ''
})
const isExpired = createSelector([jwt], (j): boolean => {
  return !!j && !!j.exp && j.exp * 1000 <= new Date().getTime()
})
const login = (state: RootState) => root(state).login
const authByToken = (state: RootState) => root(state).authByToken
const authFirstLogin = (state: RootState) => root(state).authFirstLogin
const authSendForgotPasswordNotification = (state: RootState) =>
  root(state).authSendForgotPasswordNotification
const authResetPassword = (state: RootState) => root(state).authResetPassword
const authCheckEmailExists = (state: RootState) => root(state).authCheckEmailExists
const authUserSetLastVisited = (state: RootState) => root(state).authUserSetLastVisited

export const selectors = {
  user,
  setUserLastVisited,
  impersonate,
  token,
  successMessage,
  jwt,
  isConnected,
  isExpired,
  login,
  authByToken,
  authFirstLogin,
  authSendForgotPasswordNotification,
  authResetPassword,
  authCheckEmailExists,
  authUserSetLastVisited,
}
