import { ImgHTMLAttributes, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { EditoCardProps } from '../../components/EditoCard'
import { Editorial } from '../../graphql/generated/graphql'
import { router, routesPath } from '../../router'

import { EditoAddProps } from '.'

export const useMultipleEditionEditoTemplateProps = (
  search: string,
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void
): EditoAddProps => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const editos = useSelector(selectors.editos.editorials)
  const sampleEdito = useSelector(selectors.multipleEdition.edito)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const [parsedEditos, setParsedEditos] = useState<EditoCardProps[] | undefined>(undefined)

  const [selectedEdito, setSelectedEdito] = useState<Editorial[]>(sampleEdito || [])

  useEffect(() => {
    if (editos && editos) {
      const parseEditosCards = () => {
        const newCards: EditoCardProps[] = []
        editos?.forEach((edito: Editorial) => {
          const photos: ImgHTMLAttributes<HTMLImageElement>[] = []
          edito &&
            edito.samples &&
            edito.samples.forEach((sample) => {
              if (sample.photos) {
                sample.photos.forEach((photo) => {
                  photos.push({ src: photo.url, alt: photo.id })
                })
              }
            })
          const card: EditoCardProps = {
            title: edito.name,
            text: edito.description,
            images: photos,
            itemNbr: edito.samples.length.toString(),
            itemTitle: 'Echantillon' + (edito.samples.length <= 1 ? '' : 's'),
            selected: selectedEdito && selectedEdito.some((selected) => selected.id === edito.id), // Utilisation du typage pour vérifier si l'edito est sélectionné
            onClick: () => {
              setSelectedEdito((prevSelectedEditos) => {
                if (
                  prevSelectedEditos &&
                  prevSelectedEditos.some((prevEdito) => prevEdito.id === edito.id)
                ) {
                  return prevSelectedEditos.filter((prevEdito) => prevEdito.id !== edito.id)
                } else {
                  return [...prevSelectedEditos, edito]
                }
              })
            },
          }

          newCards.push(card)
        })
        newCards.sort((a, b) => {
          if (a.title && b.title) {
            return a.title.localeCompare(b.title)
          }
          return 0
        })

        return newCards
      }
      setParsedEditos(parseEditosCards)
    }
  }, [editos, selectedEdito])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: undefined,
          next: {
            text: 'Scan échantillons',
            rightIconProps: {
              icon: Icons.scan,
              width: 18,
              height: 18,
            },
            disabled: selectedEdito.length === 0,
            onClick: () => {
              selectedEdito && dispatch(actions.multipleEdition.setEdito(selectedEdito))
              const link = router(routesPath.multipleEditionScan)
              navigate(link)
            },
          },
        },
      },
      subTitle: 'édito',
      text: 'À quel édito souhaitez-vous ajouter les échantillons ?',
      editoList: parsedEditos ? parsedEditos : undefined,
      searchBar: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
    }),
    [dispatch, handleSearchChange, layoutProps, navigate, parsedEditos, search, selectedEdito]
  )
}
