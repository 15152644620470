import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const configuration = getQueryService({
  query: gql(`
	query configuration {
		configuration {
			exhibitionActiveApiId
			noDaysBeforeSendingToMarketplace
			dictionaries {
				type
				items {
					key
					value
				}
			}
			azure {
				loginUrl
				logoutUrl
			}
		}
	}
`),
  transformer: (response) => {
    return response
  },
})
