import React, { FC } from 'react'

import { MenuDesktopProps } from '../MenuDesktop'
import { IconProps } from '../Icon'
import { ActionButtonScheme, ActionButtonSize } from '../ActionButton/types'

import * as SC from './styled'

export type SidebarNavProps = {
  className?: string
  iconProps?: IconProps
  secondIconProps?: IconProps
  search?: boolean
  menu?: MenuDesktopProps
}

const SidebarNav: FC<SidebarNavProps> = ({
  className,
  iconProps,
  menu,
  search,
  secondIconProps,
}) => {
  return (
    <SC.SidebarNav className={className}>
      <SC.LinkHome href="/">
        <SC.Logo />
      </SC.LinkHome>
      {search && (
        <SC.CTA
          variantScheme={ActionButtonScheme.Secondary}
          variantSize={ActionButtonSize.Large}
          text="Recherche"
          leftIconProps={iconProps}
          rightIconProps={secondIconProps}
          href="/recherche"
        />
      )}
      {menu && <SC.Menu {...menu} />}
    </SC.SidebarNav>
  )
}

export default SidebarNav
