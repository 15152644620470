import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { router, routesPath } from '../../router'

import { ImportExhibitorTemplateProps } from '.'

export const useImportExhibitorTemplateProps = (): ImportExhibitorTemplateProps => {
  const importExhibitor = useSelector(selectors.configuration.importExhibitorEbms)
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isModalToastOpen, setIsModalToastOpen] = useState<boolean>(false)

  useEffect(() => {
    if (importExhibitor && importExhibitor.success) {
      setSelectedFile(null)
      const link = router(routesPath.params)
      navigate(link)
    }
    if (importExhibitor && importExhibitor.errors) {
      setIsModalToastOpen(true)
      setTimeout(() => {
        setIsModalToastOpen(false)
        dispatch(actions.configuration.resetImportExhibitorEbms())
      }, 2000)
    }
  }, [importExhibitor, dispatch, navigate])

  const handleFileSelect = useCallback((file: File) => {
    setSelectedFile(file)
  }, [])

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.params)
              navigate(link)
            },
          },
          next: {
            text: 'Valider',
            disabled: selectedFile === null || importExhibitor?.pending,
            isPending: importExhibitor?.pending,
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
            onClick: () => {
              selectedFile &&
                dispatch(actions.configuration.importExhibitorEbmsRequest({ file: selectedFile }))
            },
          },
        },
      },
      title: 'Import Exposant',
      text: `Veuillez importer un CSV`,
      onFileSelect: handleFileSelect,
      filename: selectedFile && `Fichier sélectionné : ${selectedFile.name}`,
      toastModal: {
        text: "Erreur lors de l'import du fichier",
        isOpen: isModalToastOpen,
        icon: { icon: Icons.close, height: 20, width: 20 },
      },
    }
  }, [
    layoutProps,
    selectedFile,
    importExhibitor?.pending,
    handleFileSelect,
    isModalToastOpen,
    navigate,
    dispatch,
  ])
}
