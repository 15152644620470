import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { Icons } from '../../components/Icon'
import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../components/form/fields/FormFieldRadioGroup/types'
import { FormFieldSwitchSize } from '../../components/form/fields/FormFieldSwitch/types'
import { router } from '../../router'
import { actions, selectors } from '../../redux'
import { returnSampleDataSteps } from '../../relay/DataSample/SampleDataSteps'
import { sampleDataRoutes } from '../../relay/DataSample/SampleDataRoutes'
import {
  SampleFlagGroupInput,
  SampleFormGroup,
  SampleFormItem,
  SampleStatus,
  UserRole,
} from '../../graphql/generated/graphql'
import { sampleDataGetKeyToUpdate } from '../../relay/DataSample/SampleDataGetKeyToUpdate'
import { SampleDataAllInfo } from '../../types/sampleDataAllInfo'
import {
  VariantCheckboxFont,
  VariantCheckboxSize,
} from '../../components/form/fields/FormFieldCheckbox/types'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { ModalVariant } from '../../components/Modal/types'

import { SampleDataFormValues, SampleDataTemplateProps } from '.'

export const useSampleDataTemplateProps = (
  title?: string,
  formValues?: SampleFormGroup,
  materials?: SampleFormGroup
): SampleDataTemplateProps => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { exposantId } = useParams<{ exposantId: string }>()
  const { sampleId } = useParams<{ sampleId: string }>()
  const sampleData = useSelector(selectors.samples.sampleData)
  const user = useSelector(selectors.auth.user)
  const config = useSelector(selectors.configuration.configurationInfo)
  const sampleDataAllInfo = useSelector(selectors.samples.sampleDataAllInfo)
  const forums = useSelector(selectors.exhibitions.exhibitions)
  const ref = useRef<FormikProps<SampleDataFormValues>>(null)
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const collection = useSelector(selectors.exhibitors.collection)
  const sample = useSelector(selectors.samples.sample)
  const refresh = useSelector(selectors.auth.authByToken)
  const [forceRefresh, setForceRefresh] = useState<boolean>(true)
  const [lastPerformanceCode, setLastPerformanceCode] = useState('')
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)
  const [isNothingSelected, setIsNothingSelected] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [applyToAllCollection, setApplyToAllCollection] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<SampleFormItem[]>(
    formValues ? formValues.items : []
  )
  const [marketplaceDateHoursLeft, setMarketplaceDateHoursLeft] = useState<string | undefined>(
    undefined
  )
  const [marketplaceDateDaysLeft, setMarketplaceDateDaysLeft] = useState<string | undefined>(
    undefined
  )
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)
  const [deleteElasthanne, setDeleteElasthanne] = useState<boolean>(false)
  // Disabled popin
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addElasthanne, setAddElasthanne] = useState<boolean>(false)

  const someValue = useMemo(() => {
    const valuesToExclude: string[] = ['aucun', 'aucune', 'Aucun', 'Aucune', 'AUCUNE', 'AUCUN']
    return valuesToExclude
  }, [])
  const inPerfCodes = formValues?.name === 'CODES DE PERFORMANCE'
  // Force loader to make reloading more smooth
  useEffect(() => {
    const timerId = setTimeout(() => {
      setForceRefresh(false)
    }, 500)

    return () => clearTimeout(timerId)
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  const setFlagsItems = (data: any) => {
    const flagGroups: SampleFlagGroupInput[] = []
    if (data) {
      for (const key in data) {
        if (data[key] && data[key].items) {
          if (Array.isArray(data[key].items)) {
            const items = data[key]
            flagGroups.push({
              groupId: data[key].id,
              applyToCollection: data[key].applyToAllCollection,
              flagItems: items.items.map((flagItem: any) => ({
                itemId: flagItem.id,
                value: flagItem.value ? flagItem.value : null,
              })),
            })
          }
        }
      }
      return flagGroups
    }
  }

  const updateSample = useCallback(
    (data: SampleDataAllInfo) => {
      if (sampleId) {
        formValues?.miraklRef !== 'subForum'
          ? dispatch(
              actions.samples.sampleUpdateRequest({
                sampleId: sampleId,
                input: {
                  flagGroups: setFlagsItems(data),
                },
              })
            )
          : dispatch(
              actions.samples.sampleUpdateRequest({
                sampleId: sampleId,
                input: {
                  subForumId: data.subForum.id,
                  subForumApplyToCollection: data.subForum.applyToAllCollection,
                },
              })
            )
      }
    },
    [sampleId, dispatch, formValues?.miraklRef]
  )

  const setStoreData = useCallback(
    (values: SampleDataFormValues) => {
      const keyToUpdate: any = sampleDataGetKeyToUpdate(sampleData)
      const filteredItems = formValues?.items.filter((item) => item.name === values.radios) ?? []
      const filteredItemsCheckboxes =
        formValues?.items.filter(
          (item) => values.checkboxes && values.checkboxes.includes(item.name)
        ) ?? []
      const updatedSampleDataAllInfo: SampleDataAllInfo =
        formValues && formValues.name === 'SOUS FORUM'
          ? {
              ...sampleDataAllInfo,
              [keyToUpdate]: {
                id: filteredItems[0].id,
                applyToAllCollection: applyToAllCollection,
              },
            }
          : {
              ...sampleDataAllInfo,
              [keyToUpdate]: {
                id: formValues?.id,
                items:
                  formValues?.responseType === 'SINGLE' ? filteredItems : filteredItemsCheckboxes,
                applyToAllCollection: applyToAllCollection,
              },
            }
      if (
        lastSegment === 'category' &&
        (sampleData.routeType === 'Leather' || sampleData.routeType === 'LeatherMarketplace') &&
        values.radios === 'Textiles maroquinerie-chaussure'
      ) {
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            routeType: 'LeatherSpecial',
            [keyToUpdate]:
              formValues?.responseType === 'SINGLE' ? values.radios : values.checkboxes,
          })
        )
      } else if (
        lastSegment === 'category' &&
        sampleData.routeType === 'LeatherSpecial' &&
        values.radios !== 'Textiles maroquinerie-chaussure'
      ) {
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            routeType: 'Leather',
            [keyToUpdate]:
              formValues?.responseType === 'SINGLE' ? values.radios : values.checkboxes,
          })
        )
      } else {
        updateSample(updatedSampleDataAllInfo)
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            [keyToUpdate]:
              formValues?.responseType === 'SINGLE' ? values.radios : values.checkboxes,
          })
        )
      }

      dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
    },
    [
      applyToAllCollection,
      dispatch,
      formValues,
      lastSegment,
      sampleData,
      sampleDataAllInfo,
      updateSample,
    ]
  )

  useEffect(() => {
    if (ref.current && ref.current?.values.checkboxes && inPerfCodes) {
      const performanceCodes = ['Mono-stretch', 'Bi-stretch'] // Les codes de performance que vous suivez
      const checkboxesValues = ref.current?.values.checkboxes as string[]
      const selectedCodes = checkboxesValues.filter((code: string) =>
        performanceCodes.includes(code)
      )
      if (selectedCodes.length > 0) {
        setLastPerformanceCode(selectedCodes[selectedCodes.length - 1]) // Supposons que le dernier sélectionné est toujours à la fin
      }
    }
  }, [inPerfCodes, ref.current?.values.checkboxes])

  useEffect(() => {
    if (formValues) {
      let filteredItems = formValues.items
      if (search !== '') {
        filteredItems = filteredItems.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      }
      setFilteredItems(filteredItems)
    }
  }, [formValues, search])

  useEffect(() => {
    if (sample?.applyToCollectionUniverse) {
      setApplyToAllCollection(sample?.applyToCollectionUniverse)
      return
    }

    if (sampleDataAllInfo) {
      const filteredItems = sampleDataAllInfo[lastSegment]

      if (
        filteredItems &&
        typeof filteredItems === 'object' &&
        'applyToAllCollection' in filteredItems &&
        filteredItems.applyToAllCollection
      ) {
        setApplyToAllCollection(filteredItems.applyToAllCollection)
      } else {
        setApplyToAllCollection(false)
      }
    }
  }, [dispatch, lastSegment, sampleDataAllInfo, search, sample?.applyToCollectionUniverse])

  const isSkipableStep = useCallback(() => {
    if (
      user?.role === UserRole.Admin ||
      user?.role === UserRole.SuperAdmin ||
      user?.role === UserRole.ModePv ||
      user?.role === UserRole.FashionExpert
    ) {
      if (
        lastSegment === 'material' ||
        lastSegment === 'color' ||
        lastSegment === 'manufacturingType'
      )
        return true
      else return false
    } else {
      return false
    }
  }, [lastSegment, user?.role])

  useEffect(() => {
    if (isNothingSelected) {
      const firstCommonValue = filteredItems.find((item) =>
        someValue.includes(item.name.toLowerCase())
      )?.name
      ref.current?.setFieldValue('checkboxes', [firstCommonValue])
    }
  }, [filteredItems, isNothingSelected, someValue])

  useEffect(() => {
    if (
      config &&
      sample &&
      sample.statusUpdatedAt &&
      sample.status === SampleStatus.SelectedOkShooting
    ) {
      const dateString = sample.statusUpdatedAt
      const dateObject = dayjs(dateString)
      const dateInXDays = dateObject.add(config?.noDaysBeforeSendingToMarketplace, 'day')
      const dateNow = dayjs()
      const differenceInHours = dateInXDays.diff(dateNow, 'hour')
      const differenceInDays = dateInXDays.diff(dateNow, 'day')
      if (differenceInDays >= 0 && config?.noDaysBeforeSendingToMarketplace !== 0) {
        setMarketplaceDateHoursLeft(differenceInHours.toString())
        setMarketplaceDateDaysLeft(differenceInDays.toString())
      }
    }
    if (
      lastSegment === 'category' &&
      (sampleData.routeType === 'Leather' || sampleData.routeType === 'LeatherMarketplace') &&
      sampleData.category === 'Textiles maroquinerie-chaussure'
    ) {
      dispatch(
        actions.samples.setSampleData({
          ...sampleData,
          routeType: 'LeatherSpecial',
        })
      )
    }
  }, [config, dispatch, lastSegment, sample, sampleData])

  const updateMaterials = useCallback(
    (deleteMaterial: boolean) => {
      const elasthanne = materials && materials.items.filter((item) => item.name === 'Élasthanne')
      const previousMaterials = sampleDataAllInfo.material
      if (deleteMaterial) {
        // Filtre les éléments pour enlever 'Élasthanne'
        const newMaterialsItems =
          sampleDataAllInfo.material?.items?.filter(
            (item) => item.name.toLowerCase() !== 'élasthanne'
          ) || []

        // Filtre les codes de performance pour enlever 'Bi-stretch' et 'Mono-stretch'
        const newPerformanceCodeItems =
          sampleDataAllInfo.performanceCode?.items?.filter(
            (item) => item.name !== 'Bi-stretch' && item.name !== 'Mono-stretch'
          ) || []

        // Prépare la mise à jour de 'sampleDataAllInfo' avec les nouvelles valeurs
        const updatedSampleDataAllInfo = {
          ...sampleDataAllInfo,
          material: {
            ...sampleDataAllInfo.material,
            items: newMaterialsItems,
          },
          performanceCode: {
            ...sampleDataAllInfo.performanceCode,
            items: newPerformanceCodeItems,
          },
        }

        // Gère le cas où 'sampleData.material' ou 'sampleData.performanceCode' est undefined
        const compiledMaterialSampleData = sampleData.material
          ? sampleData.material.filter((item) => item.toLowerCase() !== 'élasthanne')
          : []

        const compiledPerformanceCodesSampleData = sampleData.performanceCode
          ? sampleData.performanceCode.filter(
              (item) => item !== 'Bi-stretch' && item !== 'Mono-stretch'
            )
          : []

        // Effectue les mises à jour avec Redux
        dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            performanceCode: compiledPerformanceCodesSampleData,
            material: compiledMaterialSampleData,
          })
        )
        updateSample(updatedSampleDataAllInfo)
      }
      if (!deleteMaterial) {
        const existingMaterials = sampleData.material ? [...sampleData.material] : []
        // Vérifie si 'Élasthanne' est déjà dans le tableau
        const elasthaneExists = existingMaterials.some(
          (item) => item.toLowerCase() === 'élasthanne'
        )
        // Si 'Élasthanne' n'est pas présent, ajoute-le au tableau
        const compiledMaterialSampleData = elasthaneExists
          ? existingMaterials
          : [...existingMaterials, 'élasthanne']

        const compiledMaterials = elasthanne
          ? [...(previousMaterials?.items || []), ...elasthanne]
          : previousMaterials?.items || []

        const performanceOptionToAdd = lastPerformanceCode
        const updatedPerformanceItems = [...(sampleDataAllInfo.performanceCode.items || [])]

        const performanceItems = formValues && formValues.items
        if (performanceItems) {
          const performanceOptionObject = performanceItems.find(
            (option) => option.name === performanceOptionToAdd
          )
          // Vérifiez que performanceOptionObject n'est pas undefined avant de l'ajouter
          if (performanceOptionObject) {
            updatedPerformanceItems.push(performanceOptionObject)
          }
        }

        const updatedSampleDataAllInfo = {
          ...sampleDataAllInfo,
          material: {
            ...sampleDataAllInfo.material,
            items: compiledMaterials,
          },
          performanceCode: {
            ...sampleDataAllInfo.performanceCode,
            items: updatedPerformanceItems,
          },
        }
        dispatch(actions.samples.setSampleDataAllInfo(updatedSampleDataAllInfo))
        dispatch(
          actions.samples.setSampleData({
            ...sampleData,
            performanceCode: Array.isArray(ref.current?.values.checkboxes)
              ? ref.current?.values.checkboxes
              : ref.current?.values.checkboxes
              ? [ref.current?.values.checkboxes]
              : [''],
            material: compiledMaterialSampleData,
          })
        )
        updateSample(updatedSampleDataAllInfo)
      }
    },
    [
      dispatch,
      formValues,
      lastPerformanceCode,
      materials,
      sampleData,
      sampleDataAllInfo,
      updateSample,
    ]
  )
  const resetFormData = useCallback(
    (remove: boolean) => {
      const checkboxes = ref.current?.values.checkboxes as string[]
      if (checkboxes && !remove) {
        // Filtrer les valeurs spécifiques avant toute autre opération
        const filteredCheckboxes = checkboxes.filter(
          (item) => !['aucun', 'aucune', 'Aucun', 'Aucune', 'AUCUNE', 'AUCUN'].includes(item)
        )
        const names = filteredCheckboxes.map((item) => item)
        // Ajoute lastPerformanceCode comme un élément de tableau si non vide
        const lastPerformanceCodeArray = lastPerformanceCode ? [lastPerformanceCode] : []
        // Fusionner les tableaux tout en filtrant les doublons, incluant lastPerformanceCode si présent
        const combinedArray = Array.from(
          new Set([...names, ...filteredCheckboxes, ...lastPerformanceCodeArray])
        )
        return combinedArray
      }
      // Garde les anciennes valeurs et enlève Mono et Bi-stretch
      if (checkboxes && remove) {
        const filteredItems =
          checkboxes &&
          checkboxes.filter((item) => item !== 'Mono-stretch' && item !== 'Bi-stretch')
        const names = checkboxes
          .map((item) => item)
          .filter((item) => item !== 'Mono-stretch' && item !== 'Bi-stretch')
        const combinedArray = Array.from(new Set([...names, ...filteredItems]))
        return combinedArray
      }
      return checkboxes // Retourne le tableau de checkboxes si data est undefined
    },
    [lastPerformanceCode]
  )

  return useMemo(
    () => ({
      title: title,
      isPending: forceRefresh,
      layoutProps: {
        steps: {
          steps: returnSampleDataSteps(sampleData, exposantId, sampleId),
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(sampleDataRoutes(sampleData, 'prev'), {
                exposantId,
                sampleId,
                apiId: collection?.exhibition.apiId as string,
              })
              navigate(link)
            },
          },
          note:
            collection?.internalNotes && collection?.internalNotes.length !== 0
              ? {
                  rounded: true,
                  number: collection?.internalNotes
                    ? collection?.internalNotes.length.toString()
                    : '0',
                  onClick: () => {
                    setIsNoteOpen(true)
                  },
                }
              : undefined,
          next: {
            text: 'Suivant',
            disabled: isSkipableStep() ? false : !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
            rightIconProps: {
              icon: Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
          nextPlus: {
            text: 'Rafraichir',
            isPending: refresh?.pending,
            disabled: refresh?.pending,
            onClick: () => {
              dispatch(actions.auth.authByTokenRequest())
            },
            rightIconProps: {
              icon: Icons.link,
              width: 18,
              height: 18,
            },
          },
        },
        title: `${sampleData.exhibitorRef} #${sampleId}`,
        ...layoutProps,
        notes: {
          title: "Note interne sur l'exposant",
          notes:
            collection && collection.internalNotes && collection.internalNotes.length !== 0
              ? collection.internalNotes.map((note) => ({
                  text: note.body,
                  author: {
                    fullName:
                      collection?.internalNotes.length !== 0
                        ? `par ${note.user.lastName} ${note.user.firstName}`
                        : '',
                    date:
                      collection?.internalNotes.length !== 0
                        ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                        : '',
                  },
                }))
              : [],
          tags: {
            newTag: collection?.exhibitor?.isNew,
            returnTag: collection?.samplesToReturn !== 0,
          },
          onClose: () => setIsNoteOpen(false),
        },
        noteModal: {
          onClose: () => setIsNoteOpen(false),
          isOpen: isNoteOpen,
          variant: ModalVariant.Small,
        },
        marketplaceWarning:
          marketplaceDateDaysLeft && marketplaceDateHoursLeft
            ? {
                number: `J - ${marketplaceDateDaysLeft}`,
                text: `Dépôt sur la Marketplace dans ${marketplaceDateHoursLeft} heures`,
                redBg: true,
              }
            : undefined,
        deleteModal:
          isConfirmationOpen && deleteElasthanne
            ? {
                text: `Vous venez de désélectionner le code de performance
                ${lastPerformanceCode}. En cliquant sur “Valider”, la composition Elasthanne associée sera automatiquement désélectionnée.`,
                isOpen: isConfirmationOpen,
                cancelButton: {
                  text: 'Annuler',
                  onClick: () => {
                    ref.current?.setFieldValue('checkboxes', resetFormData(false))
                    setIsConfirmationOpen(false)
                    setDeleteElasthanne(false)
                  },
                },
                deleteButton: {
                  text: 'Oui, valider',
                  rightIconProps: { icon: Icons.check, width: 18 },
                  onClick: () => {
                    setIsConfirmationOpen(false)
                    setDeleteElasthanne(false)
                    updateMaterials(true)
                    ref.current?.setFieldValue('checkboxes', resetFormData(true))
                  },
                },
              }
            : // Disabled popin
              // isConfirmationOpen && addElasthanne
              //   ? {
              //       text: `Vous venez d'ajouter le code de performance ${lastPerformanceCode}. En cliquant sur “Valider”, la composition Elasthanne sera automatiquement ajoutée à l'échantillon.`,
              //       isOpen: isConfirmationOpen,
              //       cancelButton: {
              //         text: 'Annuler',
              //         onClick: () => {
              //           setIsConfirmationOpen(false)
              //           setAddElasthanne(false)
              //           ref.current?.setFieldValue('checkboxes', resetFormData(true))
              //         },
              //       },
              //       deleteButton: {
              //         text: 'Oui, valider',
              //         rightIconProps: { icon: Icons.check, width: 18 },
              //         onClick: () => {
              //           setIsConfirmationOpen(false)
              //           setAddElasthanne(false)
              //           updateMaterials(false)
              //           ref.current?.setFieldValue('checkboxes', resetFormData(false))
              //         },
              //       },
              //     }
              undefined,
      },
      formikForm: {
        title: title,
        innerRef: ref,
        initialValues: {
          radios:
            sampleData[lastSegment] && formValues?.responseType === 'SINGLE'
              ? sampleData[lastSegment]
              : '',
          checkboxes:
            sampleData[lastSegment] && formValues?.responseType === 'MULTIPLE'
              ? sampleData[lastSegment]
              : '',
        } as SampleDataFormValues,
        validationSchema: !isSkipableStep()
          ? formValues?.responseType === 'SINGLE'
            ? Yup.object().shape({
                radios: Yup.string().required(),
              })
            : Yup.object().shape({
                checkboxes: Yup.array()
                  .of(Yup.string())
                  .test(
                    'mono-or-bi-stretch',
                    "Vous ne pouvez sélectionner qu'une seule option parmi Mono-stretch et Bi-stretch. Veuillez décocher l'une des deux options avant de valider.",
                    () => {
                      if (ref.current && ref.current.values.checkboxes) {
                        const hasMonoStretch = ref.current.values.checkboxes.includes(
                          'Mono-stretch'
                        )
                        const hasBiStretch = ref.current.values.checkboxes?.includes('Bi-stretch')
                        return !(hasMonoStretch && hasBiStretch)
                      }
                      return false
                    }
                  )
                  .min(1, 'Veuillez sélectionner au moins une option.')
                  .required('Ce champ est requis.'),
              })
          : undefined,
        onSubmit: (values) => {
          setStoreData(values)
          const link = router(sampleDataRoutes(sampleData, 'next'), {
            exposantId,
            sampleId,
          })
          navigate(link)
        },
        onContextUpdate: (context) => {
          if (inPerfCodes && context.values.checkboxes) {
            const checkForElasthanne =
              context.values.checkboxes.includes('Bi-stretch') ||
              context.values.checkboxes.includes('Mono-stretch')
            const isElasthanePresent =
              sampleDataAllInfo.material.items &&
              sampleDataAllInfo.material.items.filter(
                (item) => item.name === 'Élasthanne' || item.name === 'élasthanne'
              )[0]
            if (checkForElasthanne && !isElasthanePresent) {
              setIsConfirmationOpen(true)
              setAddElasthanne(true)
            }
            if (!checkForElasthanne && isElasthanePresent) {
              setIsConfirmationOpen(true)
              setDeleteElasthanne(true)
            }
          }
          const isAnyValuePresent = someValue.some((value) =>
            context.values.checkboxes?.includes(value)
          )
          if (context.values.checkboxes && isAnyValuePresent && !isNothingSelected) {
            setIsNothingSelected(true)
          }
          if (context.values.checkboxes && !isAnyValuePresent && isNothingSelected) {
            setIsNothingSelected(false)
          }
          // Passe outre la confirmation custom de YUP qui ne fonctionne pas correctement
          if (formValues?.responseType === 'MULTIPLE') {
            if (
              context.values.checkboxes &&
              context.values.checkboxes.length > 0 &&
              !(
                context.values.checkboxes.includes('Bi-stretch') &&
                context.values.checkboxes.includes('Mono-stretch')
              )
            ) {
              setIsValid(true)
            }
            if (
              (context.values.checkboxes && context.values.checkboxes.length === 0) ||
              (context.values.checkboxes &&
                context.values.checkboxes.includes('Bi-stretch') &&
                context.values.checkboxes.includes('Mono-stretch'))
            ) {
              setIsValid(false)
            }
          }
          if (formValues?.responseType === 'SINGLE') {
            if (context.values.radios !== '') setIsValid(true)
            else setIsValid(context.isValid)
          }
        },
        validateOnChange: true,
        validateOnMount: true,
      },
      allCollection: {
        name: 'allCollection',
        inlineLabel: 'Appliquer à toute la collection',
        variantSize: FormFieldSwitchSize.Small,
        value: applyToAllCollection,
        onChange: (value) => {
          setApplyToAllCollection(value.target.value)
        },
      },
      search: {
        placeholder: 'Recherche',
        value: search,
        onChange: handleSearchChange,
      },
      boxInfo:
        formValues?.miraklRef === 'subForum'
          ? {
              text: sampleData.forum,
              backgroundColor: getContrastColor(
                forums?.forums.find((forum) => forum.id === sampleDataAllInfo.forum.id)
                  ?.color as string
              ),
              color: forums?.forums.find((forum) => forum.id === sampleDataAllInfo.forum.id)
                ?.color as string,
            }
          : undefined,
      radios:
        formValues?.responseType === 'SINGLE'
          ? {
              name: 'radios',
              required: true,
              optionsFont: VariantRadioFont.SmallBold,
              optionsSize: VariantRadioSize.big,
              options: formValues
                ? filteredItems &&
                  filteredItems.map((item: SampleFormItem) => ({
                    label: item.name,
                    value: item.name,
                    dotted: true,
                  }))
                : [],
            }
          : undefined,
      checkboxes:
        formValues?.responseType === 'MULTIPLE'
          ? {
              name: 'checkboxes',
              required: true,
              optionsFont: VariantCheckboxFont.BoldCaps,
              optionsSize: VariantCheckboxSize.big,
              options: formValues
                ? filteredItems &&
                  filteredItems.map((item: SampleFormItem) => ({
                    label: item.name,
                    value: item.name,
                    dotted: true,
                    disabled: !someValue.includes(item.name) && isNothingSelected ? true : false,
                  }))
                : [],
            }
          : undefined,
    }),
    [
      title,
      forceRefresh,
      sampleData,
      exposantId,
      sampleId,
      collection,
      isSkipableStep,
      isValid,
      refresh?.pending,
      layoutProps,
      isNoteOpen,
      marketplaceDateDaysLeft,
      marketplaceDateHoursLeft,
      isConfirmationOpen,
      deleteElasthanne,
      lastPerformanceCode,
      lastSegment,
      formValues,
      applyToAllCollection,
      search,
      forums?.forums,
      filteredItems,
      navigate,
      dispatch,
      resetFormData,
      updateMaterials,
      setStoreData,
      inPerfCodes,
      someValue,
      isNothingSelected,
      sampleDataAllInfo.material.items,
      sampleDataAllInfo.forum.id,
    ]
  )
}
