import * as apiRedux from './api/redux'
import * as appRedux from './app/redux'
import * as authRedux from './auth/redux'
import * as userRedux from './user/redux'
import * as exhibitorsRedux from './exhibitors/redux'
import * as configurationRedux from './configuration/redux'
import * as boxesRedux from './boxes/redux'
import * as samplesRedux from './samples/redux'
import * as exhibitionsRedux from './exhibitions/redux'
import * as editosRedux from './editos/redux'
import * as multipleEditionRedux from './multipleEdition/redux'
import * as mailsRedux from './mails/redux'

export const actions = {
  app: appRedux.actions,
  api: apiRedux.actions,
  auth: authRedux.actions,
  user: userRedux.actions,
  exhibitors: exhibitorsRedux.actions,
  configuration: configurationRedux.actions,
  boxes: boxesRedux.actions,
  samples: samplesRedux.actions,
  exhibitions: exhibitionsRedux.actions,
  editos: editosRedux.actions,
  multipleEdition: multipleEditionRedux.actions,
  mails: mailsRedux.actions,
}

export const selectors = {
  app: appRedux.selectors,
  api: apiRedux.selectors,
  auth: authRedux.selectors,
  user: userRedux.selectors,
  exhibitors: exhibitorsRedux.selectors,
  configuration: configurationRedux.selectors,
  boxes: boxesRedux.selectors,
  samples: samplesRedux.selectors,
  exhibitions: exhibitionsRedux.selectors,
  editos: editosRedux.selectors,
  multipleEdition: multipleEditionRedux.selectors,
  mails: mailsRedux.selectors,
}

export const reducers = {
  app: appRedux.reducer,
  api: apiRedux.reducer,
  auth: authRedux.reducer,
  user: userRedux.reducer,
  exhibitors: exhibitorsRedux.reducer,
  configuration: configurationRedux.reducer,
  boxes: boxesRedux.reducer,
  samples: samplesRedux.reducer,
  exhibitions: exhibitionsRedux.reducer,
  editos: editosRedux.reducer,
  multipleEdition: multipleEditionRedux.reducer,
  mails: mailsRedux.reducer,
}
