import React, { FC } from 'react'

import { FormFieldCheckboxProps } from '../FormFieldCheckbox'
import FormikField from '../../FormikField'
import { VariantCheckboxFont, VariantCheckboxSize } from '../FormFieldCheckbox/types'

import * as SC from './styled'

export type FormFieldCheckboxGroupProps = {
  className?: string
  label?: string
  name: string
  options: Array<Omit<FormFieldCheckboxProps, 'name'>>
  required?: boolean
  disabled?: boolean
  displayLabel?: boolean
  help?: string
  backgroundColor?: string
  hasError?: boolean
  error?: string
  optionsSize?: VariantCheckboxSize
  optionsFont?: VariantCheckboxFont
}

const FormFieldCheckboxGroup: FC<FormFieldCheckboxGroupProps> = ({
  className,
  name,
  label,
  options,
  optionsSize = VariantCheckboxSize.default,
  optionsFont = VariantCheckboxFont.Default,
  required,
  disabled,
  help,
  hasError,
  displayLabel = true,
  error,
}) => {
  return (
    <SC.Wrapper className={className} $isDisabled={disabled}>
      {label && displayLabel && (
        <SC.Label required={required} error={!!error || hasError}>
          {label}
        </SC.Label>
      )}
      <SC.Options>
        {options.map((option, idx) => {
          return (
            <FormikField
              {...option}
              key={`${option.value}-${idx}`}
              name={name}
              type="checkbox"
              Component={SC.Checkbox}
              isGroupChild
              variant={option.variant}
              variantSize={optionsSize}
              variantFont={optionsFont}
              disabled={disabled || option.disabled}
              checked={option.checked}
            />
          )
        })}
      </SC.Options>
      {!error && help && <SC.Help>{help}</SC.Help>}
      {error && <SC.Error>{error}</SC.Error>}
    </SC.Wrapper>
  )
}

export default FormFieldCheckboxGroup
