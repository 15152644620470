import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitorsAutocomplete = getQueryService({
  query: gql(`
	query exhibitorsAutocomplete(
		$q: String!
	) {
		exhibitors(
			q: $q
			universesEbmsIds: null
			countries: null
			exhibitionsApiIds: null
			first: 10
			page: 1
		) {
			paginatorInfo {
				total
				perPage
				lastPage
				currentPage
			}
			data {
				ebmsId
				name
			}
		}
	}
  `),
  transformer: (response) => {
    return response
  },
})
