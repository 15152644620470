import { call, all, put, select, takeEvery } from 'redux-saga/effects'

import { actions, selectors } from '..'
import { services } from '../../graphql'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../../helpers/GraphqlHelpers'
import {
  FlagGroup,
  PaginatorInfo,
  Sample,
  SampleHistoryItem,
} from '../../graphql/generated/graphql'

export default class SamplesSagas {
  static *onSamplesCreate({ payload }: ReturnType<typeof actions.samples.sampleCreateRequest>) {
    const { samples } = payload

    // Créer un tableau de toutes les sagas d'appel pour chaque échantillon
    const callSagas = samples.map((sample) =>
      call(ApiSagas.call, services.samples.mutations.sampleCreate, sample)
    )

    try {
      const results: ApiResponse<any>[] = yield all(callSagas)

      const hasErrors = results.some((result: any) => result.errors)

      if (hasErrors) {
        yield put(actions.samples.sampleCreateError('Une ou plusieurs erreurs se sont produites.'))
      } else {
        yield put(actions.samples.sampleCreateSuccess())
      }
    } catch (error) {
      // Gérer l'exception ici
      yield put(actions.samples.sampleCreateError(error))
    }
  }

  static *onSampleUpdate({ payload }: ReturnType<typeof actions.samples.sampleUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleUpdate, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleUpdateSuccess())
      return
    }
  }

  static *onSampleDelete({ payload }: ReturnType<typeof actions.samples.sampleDeleteRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleDelete, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleDeleteError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleDeleteSuccess())
      return
    }
  }

  static *onSampleRestore({ payload }: ReturnType<typeof actions.samples.sampleRestoreRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleRestore, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleRestoreError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleRestoreSuccess())
      return
    }
  }

  static *onSampleUpdateStatus({
    payload,
  }: ReturnType<typeof actions.samples.sampleUpdateStatusRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleUpdateStatus, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleUpdateStatusError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleUpdateStatusSuccess())
      return
    }
  }

  static *onSampleUpdateMany({
    payload,
  }: ReturnType<typeof actions.samples.sampleUpdateManyRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleUpdateMany, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleUpdateManyError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleUpdateManySuccess())
      return
    }
  }

  static *onSampleUpdateWithUniverse({
    payload,
  }: ReturnType<typeof actions.samples.sampleUpdateWithUniverseRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleUpdateWithUniverse, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleUpdateWithUniverseError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleUpdateWithUniverseSuccess())
      return
    }
  }

  static *onSampleAttachShooting({
    payload,
  }: ReturnType<typeof actions.samples.sampleAttachShootingsRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleAttachShootings, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleAttachShootingsError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleAttachShootingsSuccess())
      return
    }
  }

  static *onSampleUpdateImageLabel({
    payload,
  }: ReturnType<typeof actions.samples.sampleUpdateImageLabelRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleUpdateImageLabel, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleUpdateImageLabelError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleUpdateImageLabelSuccess())
      return
    }
  }

  static *onSampleCreateImageLabel({
    payload,
  }: ReturnType<typeof actions.samples.sampleCreateImageLabelRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleCreateImageLabel, payload)
    if (rs.errors) {
      yield put(actions.samples.sampleCreateImageLabelSuccess(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.sampleCreateImageLabelSuccess())
      return
    }
  }

  static *onGetSampleFormByUniverse({
    payload,
  }: ReturnType<typeof actions.samples.getSampleFormByUniverseRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.sampleFormByUniverse, payload)

    if (rs.errors) {
      yield put(actions.samples.getSampleFormByUniverseError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.getSampleFormByUniverseSuccess())
      yield put(actions.samples.setSampleFormByUniverse(rs.data.sampleFormByUniverse))
      return
    }
  }

  static *onGetSamples({ payload }: ReturnType<typeof actions.samples.getSamplesRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.getSamples, payload)
    if (rs.errors) {
      yield put(actions.samples.getSamplesError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSamples(rs.data.samples.data as Sample[]))
      yield put(actions.samples.setSamplesPaginator(rs.data.samples.paginatorInfo as PaginatorInfo))
      yield put(actions.samples.getSamplesSuccess())
      return
    }
  }

  static *onGetSamplesNumber({
    payload,
  }: ReturnType<typeof actions.samples.getSamplesNumberRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.getSamplesNumber, payload)
    if (rs.errors) {
      yield put(actions.samples.getSamplesNumberError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSamplesNumber(rs.data.samples.paginatorInfo as PaginatorInfo))
      yield put(actions.samples.getSamplesNumberSuccess())
      return
    }
  }

  static *onSampleSearchFilters({
    payload,
  }: ReturnType<typeof actions.samples.sampleSearchFiltersRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.sampleSearchFilters, payload)

    if (rs.errors) {
      yield put(actions.samples.sampleSearchFiltersError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSampleFilters(rs.data.sampleSearchFilters))
      yield put(actions.samples.sampleSearchFiltersSuccess())
      return
    }
  }

  static *onGetSamplePdf({ payload }: ReturnType<typeof actions.samples.getSamplePdfRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.sampleGeneratePdf, payload)

    if (rs.errors) {
      yield put(actions.samples.getSamplePdfError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSamplePdf(rs.data.sampleGeneratePdf))
      yield put(actions.samples.getSamplePdfSuccess())
      return
    }
  }

  static *onGetSampleById({ payload }: ReturnType<typeof actions.samples.getSampleByIdRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.getSampleById, payload)

    if (rs.errors) {
      yield put(actions.samples.getSampleByIdError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSample(rs.data.sampleById as Sample))
      yield put(actions.samples.resetSampleById())
      yield put(actions.samples.getSampleByIdSuccess())
      return
    }
  }

  static *onSamplesCountBy({ payload }: ReturnType<typeof actions.samples.samplesCountByRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.samplesCountBy, payload)

    if (rs.errors) {
      yield put(actions.samples.samplesCountByError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSamplesCountBy(rs.data))
      yield put(actions.samples.samplesCountBySuccess())
      return
    }
  }

  static *onGetSampleHistoryById({
    payload,
  }: ReturnType<typeof actions.samples.getSampleHistoryByIdRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.sampleHistoryById, payload)

    if (rs.errors) {
      yield put(actions.samples.getSampleHistoryByIdError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSampleHistory(rs.data.sampleHistoryById as SampleHistoryItem[]))
      yield put(actions.samples.getSampleHistoryByIdSuccess())
      return
    }
  }

  static *onSamplesCountByUniverse({
    payload,
  }: ReturnType<typeof actions.samples.sampleCountByUniverseRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.samplesCountByUniverse, payload)

    if (rs.errors) {
      yield put(actions.samples.sampleCountByUniverseError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.setSamplesCount(rs.data.samplesCountByUniverse))
      yield put(actions.samples.sampleCountByUniverseSuccess())
      return
    }
  }

  static *onFlagGroupsByUniverse({
    payload,
  }: ReturnType<typeof actions.samples.getFlagGroupsByUniverseRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.flagGroupsByUniverse, payload)

    if (rs.errors) {
      yield put(actions.samples.getFlagGroupsByUniverseError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.getFlagGroupsByUniverseSuccess())
      yield put(
        actions.samples.setFlagGroupsByUniverse(rs.data.flagGroupsByUniverse as FlagGroup[])
      )
      return
    }
  }

  static *onFlagItemCreate({ payload }: ReturnType<typeof actions.samples.flagItemCreateRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.flagItemCreate, payload)
    if (rs.errors) {
      yield put(actions.samples.flagItemCreateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.flagItemCreateSuccess())
      // @ts-ignore
      const form = yield select(selectors.samples.flagGroupsByUniverse)
      const indexToUpdate = form.findIndex(
        // @ts-ignore
        (item) => item.miraklRef === rs.data.flagItemCreate.miraklRef
      )
      if (indexToUpdate !== -1) {
        const updatedForm = [...form]
        updatedForm[indexToUpdate] = rs.data.flagItemCreate
        yield put(actions.samples.setFlagGroupsByUniverse(updatedForm))
      }
      return
    }
  }

  static *onFlagItemUpdate({ payload }: ReturnType<typeof actions.samples.flagItemUpdateRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.flagItemUpdate, payload)
    if (rs.errors) {
      yield put(actions.samples.flagItemUpdateError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.flagItemUpdateSuccess())
      // @ts-ignore
      const form = yield select(selectors.samples.flagGroupsByUniverse)
      const indexToUpdate = form.findIndex(
        // @ts-ignore
        (item) => item.miraklRef === rs.data.flagItemUpdate.miraklRef
      )
      if (indexToUpdate !== -1) {
        const updatedForm = [...form]
        updatedForm[indexToUpdate] = rs.data.flagItemUpdate
        yield put(actions.samples.setFlagGroupsByUniverse(updatedForm))
      }
      return
    }
  }

  static *onFlagItemDelete({ payload }: ReturnType<typeof actions.samples.flagItemDeleteRequest>) {
    const rs = yield* ApiSagas.call(services.samples.mutations.flagItemDelete, payload)
    if (rs.errors) {
      yield put(actions.samples.flagItemDeleteError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.flagItemDeleteSuccess())
      // @ts-ignore
      const form = yield select(selectors.samples.flagGroupsByUniverse)
      const indexToUpdate = form.findIndex(
        // @ts-ignore
        (item) => item.miraklRef === rs.data.flagItemDelete.miraklRef
      )
      if (indexToUpdate !== -1) {
        const updatedForm = [...form]
        updatedForm[indexToUpdate] = rs.data.flagItemDelete
        yield put(actions.samples.setFlagGroupsByUniverse(updatedForm))
      }
      return
    }
  }

  static *onExportSamples({ payload }: ReturnType<typeof actions.samples.exportSamplesRequest>) {
    const rs = yield* ApiSagas.call(services.samples.queries.exportSamples, payload)
    if (rs.errors) {
      yield put(actions.samples.exportSamplesError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.samples.exportSamplesSuccess())
      return
    }
  }

  static *listeners() {
    yield all([takeEvery(actions.samples.getSamplesRequest, this.onGetSamples)])
    yield all([takeEvery(actions.samples.getSamplesNumberRequest, this.onGetSamplesNumber)])
    yield all([takeEvery(actions.samples.sampleCreateRequest, this.onSamplesCreate)])
    yield all([
      takeEvery(actions.samples.getSampleFormByUniverseRequest, this.onGetSampleFormByUniverse),
    ])
    yield all([
      takeEvery(actions.samples.getFlagGroupsByUniverseRequest, this.onFlagGroupsByUniverse),
    ])
    yield all([takeEvery(actions.samples.sampleUpdateRequest, this.onSampleUpdate)])
    yield all([takeEvery(actions.samples.sampleDeleteRequest, this.onSampleDelete)])
    yield all([takeEvery(actions.samples.sampleRestoreRequest, this.onSampleRestore)])
    yield all([takeEvery(actions.samples.getSampleByIdRequest, this.onGetSampleById)])
    yield all([
      takeEvery(actions.samples.sampleAttachShootingsRequest, this.onSampleAttachShooting),
    ])
    yield all([
      takeEvery(actions.samples.sampleUpdateImageLabelRequest, this.onSampleUpdateImageLabel),
    ])
    yield all([
      takeEvery(actions.samples.sampleCreateImageLabelRequest, this.onSampleCreateImageLabel),
    ])
    yield all([takeEvery(actions.samples.getSampleHistoryByIdRequest, this.onGetSampleHistoryById)])
    yield all([takeEvery(actions.samples.getSamplePdfRequest, this.onGetSamplePdf)])
    yield all([takeEvery(actions.samples.sampleUpdateStatusRequest, this.onSampleUpdateStatus)])
    yield all([takeEvery(actions.samples.sampleUpdateManyRequest, this.onSampleUpdateMany)])
    yield all([
      takeEvery(actions.samples.sampleUpdateWithUniverseRequest, this.onSampleUpdateWithUniverse),
    ])
    yield all([takeEvery(actions.samples.flagItemCreateRequest, this.onFlagItemCreate)])
    yield all([takeEvery(actions.samples.flagItemUpdateRequest, this.onFlagItemUpdate)])
    yield all([takeEvery(actions.samples.flagItemDeleteRequest, this.onFlagItemDelete)])
    yield all([takeEvery(actions.samples.sampleSearchFiltersRequest, this.onSampleSearchFilters)])
    yield all([
      takeEvery(actions.samples.sampleCountByUniverseRequest, this.onSamplesCountByUniverse),
    ])
    yield all([takeEvery(actions.samples.exportSamplesRequest, this.onExportSamples)])
    yield all([takeEvery(actions.samples.samplesCountByRequest, this.onSamplesCountBy)])
  }
}
