import styled from 'styled-components'

import FormFieldText from '../../form/fields/FormFieldInput'
import FormikForm from '../../form/FormikForm'
import FormFieldSelect from '../../form/fields/FormFieldSelect'
import FormikField from '../../form/FormikField'
import { fonts } from '../../../theme'
import ColorPicker from '../../ColorPicker'
import ActionButton from '../../ActionButton'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const FieldText = styled(FormFieldText)`
  width: 100%;
  ${fonts.GothamBold}
`

export const FieldSelect = styled(FormFieldSelect)`
  width: 100%;
  max-width: 7rem;
`

export const Title = styled.div`
  font-size: 1.4rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.gray};
`

export const TextField = styled(FormikField)``

export const StyledColorPicker = styled(ColorPicker)``

export const DeleteButton = styled(ActionButton)`
  margin: auto;
  margin-top: 2.4rem;
  padding: 0.1rem 1.2rem;
  width: fit-content;
`
