import React, { useEffect } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ExhibitionSheetInfosTemplate from '../../../templates/ExhibitionSheetInfos'
import { useExhibitionSheetInfosTemplateProps } from '../../../templates/ExhibitionSheetInfos/useProps'
import { actions, selectors } from '../../../redux'

const ExhibitionSheetInfos: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { exhibitionId } = useParams<{ exhibitionId: string }>()
  const exhibition = useSelector(selectors.exhibitions.exhibitions)

  useEffect(() => {
    exhibitionId &&
      dispatch(
        actions.exhibitions.exhibitionByApiIdRequest({
          exhibitionApiId: exhibitionId,
        })
      )
  }, [dispatch, exhibitionId])

  const templateProps = useExhibitionSheetInfosTemplateProps(exhibition)

  return (
    <>
      <ExhibitionSheetInfosTemplate {...templateProps} />
    </>
  )
}

export default ExhibitionSheetInfos
