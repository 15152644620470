import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import {
  MailTemplate,
  MailTemplateType,
  MailTemplateUpsertInput,
} from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const mailTemplateByTypeService = getCustomService<
  'mailTemplateByType',
  null,
  { type: MailTemplateType }
>('mailTemplateByType')

export const mailTemplateUpsertService = getCustomService<
  'mailTemplateUpsert',
  null,
  { input: MailTemplateUpsertInput }
>('mailTemplateUpsert')

//
// Initial state
//

export type MailsState = {
  mailsByType: MailTemplate | null
  // services
  mailTemplateByType: typeof mailTemplateByTypeService.state
  mailTemplateUpsert: typeof mailTemplateUpsertService.state
}

const initialState: MailsState = {
  mailsByType: null,
  // services
  mailTemplateByType: mailTemplateByTypeService.state,
  mailTemplateUpsert: mailTemplateUpsertService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'mails',
  initialState,
  reducers: {
    resetMails: () => initialState,
    setMailsByType: (state, { payload }: actionTypes.setMailsByType) => {
      state.mailsByType = payload
    },

    resetMailTemplateUpsert: (state) => {
      state.mailTemplateUpsert = initialState.mailTemplateUpsert
    },
    ...mailTemplateByTypeService.reducers,
    ...mailTemplateUpsertService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const mailsByType = (state: RootState) => root(state).mailsByType
const mailTemplateByType = (state: RootState) => root(state).mailTemplateByType
const mailTemplateUpsert = (state: RootState) => root(state).mailTemplateUpsert

export const selectors = {
  mailsByType,
  mailTemplateByType,
  mailTemplateUpsert,
}
