import styled from 'styled-components'

export const Html = styled.div`
  ${({ theme }) => theme.textStyles.textBook}
  color: ${({ theme }) => theme.colors.pureBlack};

  & p {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  & h1 {
    ${({ theme }) => theme.textStyles.titleH1}
  }
  & h2 {
    ${({ theme }) => theme.textStyles.titleH2}
  }
  & a {
    color: inherit;
  }
`
