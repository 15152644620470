import { modalFullScreenMocks } from '../ModalFullScreen/mocks'
import { filtersFormMocks } from '../forms/FiltersForm/mocks'

import { FiltersProps } from './index'

const basic: FiltersProps = {
  ...modalFullScreenMocks.basic,
  backButtonProps: {
    open: false,
    text: 'Filtrer',
  },
  iconsColor: 'dark',
  isOpen: true,
  tags: [
    {
      text: 'Fabrics',
    },
    {
      text: 'Italie',
    },
  ],
  form: filtersFormMocks.basic,
}

export const filtersMocks = {
  basic,
}
