import { BurgerProps } from './index'

const defaultProps: BurgerProps = {
  isOpen: false,
}

const basic: BurgerProps = {
  ...defaultProps,
}

export const burgerMocks = {
  basic,
}
