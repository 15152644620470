import React, { FC } from 'react'

import * as SC from './styled'

export type LoungeCardProps = {
  className?: string
  title?: string
  season?: string
  text?: string
  active?: boolean
  onClick?: () => void
}

const LoungeCard: FC<LoungeCardProps> = ({ className, title, text, season, active, onClick }) => {
  return (
    <SC.Card className={className} onClick={onClick} $active={active}>
      <SC.Top>
        {title && <SC.Title>{title}</SC.Title>}
        {season && <SC.Season>{season}</SC.Season>}
      </SC.Top>
      {text && <SC.Text>{text}</SC.Text>}
    </SC.Card>
  )
}

export default LoungeCard
