import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import { FormFieldCheckboxGroupProps } from '../../form/fields/FormFieldCheckboxGroup'

import * as SC from './styled'

export type LoungeForumChoiceFormValues = {
  forumsSelected: string[]
}

export type LoungeForumChoiceFormProps = {
  formikForm: FormikFormProps<LoungeForumChoiceFormValues>
  fields: {
    forumsSelected: FormFieldCheckboxGroupProps
  }
}

const LoungeForumChoiceForm: FC<LoungeForumChoiceFormProps> = ({ formikForm, fields }) => {
  return (
    <SC.StyledFormikForm {...formikForm}>
      <SC.CheckboxGroup {...fields.forumsSelected} />
    </SC.StyledFormikForm>
  )
}

export default LoungeForumChoiceForm
