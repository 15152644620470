import styled from 'styled-components'
import ReactQuill from 'react-quill'

import ActionButton from '../ActionButton'
import Heading from '../Heading'
import Icon, { Icons } from '../Icon'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled(ActionButton)`
  width: fit-content;
  border-radius: 6px;
  width: 40px;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.concrete};
  border-color: ${({ theme }) => theme.colors.concrete};
  color: ${({ theme }) => theme.colors.mineShaft};
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: table-row;
  gap: 12px;
  justify-content: center;
  margin-bottom: 42px;
`

export const Title = styled(Heading)`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.gray};
`

export const StyledReactQuill = styled(ReactQuill)`
  background-color: ${({ theme }) => theme.colors.concrete};
  border-color: transparent;
  .ql-container.ql-snow {
    border: none; /* Remove the border from the .ql-container.ql-snow */
  }
`

export const RichTextHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.tundora};
`
export const RichTextFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.concrete};
  border-top: 1.5px solid ${({ theme }) => theme.colors.silver};
  font-size: 12px;
`

export const Logo = styled(Icon).attrs({ icon: Icons.logo, width: 165, height: 30 })`
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Bold = styled.span`
  ${({ theme }) => theme.textStyles.textBold};
`

export const Italic = styled.span`
  ${({ theme }) => theme.textStyles.textItalic};
`

export const Underline = styled.span`
  ${({ theme }) => theme.textStyles.textItalic};
  text-decoration: underline;
`

export const Link = styled(Icon).attrs({ icon: Icons.link, width: 14, height: 14 })`
  color: ${({ theme }) => theme.colors.gray};
`
