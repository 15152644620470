import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleDataTemplate from '../../../templates/SampleData'
import { selectors } from '../../../redux'
import { useSampleDataTemplateProps } from '../../../templates/SampleData/useProps'

const ExponentSampleDataCertifications: React.FC<RouteProps> = () => {
  const form = useSelector(selectors.samples.sampleFormByUniverse)
  const templateProps = useSampleDataTemplateProps(
    'Certifications',
    form.find((item) => item.miraklRef === 'certification')
  )
  return <SampleDataTemplate {...templateProps} />
}

export default ExponentSampleDataCertifications
