import React, { FC } from 'react'

import LayoutExhibitionSheet, {
  LayoutExhibitionSheetProps,
} from '../../layouts/LayoutExhibitionSheet'
import { SubForum, SubForumList } from '../LoungeSubForumEdit'
import Dot from '../../components/Dot'

import * as SC from './styled'

export type ExhibitionSheetInfosTemplateProps = {
  layoutProps?: LayoutExhibitionSheetProps
  dateTitle?: string
  date?: string
  forumsTitle?: string
  subForumList: SubForumList[]
}

const ExhibitionSheetInfosTemplate: FC<ExhibitionSheetInfosTemplateProps> = ({
  layoutProps,
  dateTitle,
  date,
  forumsTitle,
  subForumList,
}) => {
  return (
    <LayoutExhibitionSheet {...layoutProps}>
      <SC.StyledContentWrapper>
        <SC.Resume>
          {dateTitle && <SC.ResumeTitle>{dateTitle}</SC.ResumeTitle>}
          {date && <SC.ResumeText>{date}</SC.ResumeText>}
        </SC.Resume>

        <SC.ResumeSubForum>
          {forumsTitle && <SC.ResumeTitle>{forumsTitle}</SC.ResumeTitle>}
          {subForumList.map((item: SubForumList, key: number) => (
            <SC.Collapsible
              key={key}
              {...item}
              title={item.name}
              backgroundColor={item.backgroundColor}
              itemNbr={item.subForums ? item.subForums.length.toString() : 'Aucun '}
            >
              {item.subForums &&
                item.subForums.map((subItem: SubForum, key: number) => (
                  <SC.SubForum key={key}>
                    <Dot />
                    {subItem.name}
                  </SC.SubForum>
                ))}
            </SC.Collapsible>
          ))}
        </SC.ResumeSubForum>
      </SC.StyledContentWrapper>
    </LayoutExhibitionSheet>
  )
}

export default ExhibitionSheetInfosTemplate
