import styled from 'styled-components'

import { breakpoints, textStyles } from '../../theme'
import CollapsibleColoredItem from '../../components/CollapsibleColored'
import ContentWrapper from '../../components/ContentWrapper'
import FormikForm from '../../components/form/FormikForm'
import FormFieldCheckboxGroup from '../../components/form/fields/FormFieldCheckboxGroup'

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    margin-bottom: 7rem;
  }
`

export const Collapsible = styled(CollapsibleColoredItem)`
  margin-bottom: 1.2rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
`

export const Infos = styled.div`
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.colors.concrete};
`

export const ExhibitorNbr = styled.span`
  ${textStyles.titleH4}
`

export const Text = styled.span`
  padding-left: 12px;
  ${textStyles.titleH5}
`

export const StyledFormikForm = styled(FormikForm)`
  width: 100%;
`

export const CheckboxGroup = styled(FormFieldCheckboxGroup)`
  & > div {
    gap: 0;
  }
  & label {
    border-color: transparent !important;
    margin-left: 25px;
    margin-top: 6px;
  }
`
