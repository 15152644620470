import { StepsProps } from './index'

const basic: StepsProps = {
  steps: [
    {
      isCurrent: false,
      value: '1. Exposant',
      resume: ['Première vision SS24'],
    },
    {
      isCurrent: true,
      value: '2. Echantillons',
    },
    {
      isCurrent: false,
      value: '3. Unités',
    },
    {
      isCurrent: false,
      value: '4. Récapitulatif',
    },
  ],
}

const second: StepsProps = {
  steps: [
    {
      isCurrent: false,
      value: '1. Exposant',
      resume: ['Première vision SS24', 'Première vision SS24', 'Première vision SS24'],
    },
    {
      isCurrent: true,
      value: '2. Echantillons',
    },
    {
      isCurrent: false,
      value: '3. Unités',
    },
    {
      isCurrent: false,
      value: '4. Récapitulatif',
    },
  ],
}

const third: StepsProps = {
  steps: [
    {
      isCurrent: false,
      value: '1. Exposant',
    },
    {
      isCurrent: false,
      value: '2. Echantillons',
    },
    {
      isCurrent: true,
      value: '3. Unités',
    },
    {
      isCurrent: false,
      value: '4. Récapitulatif',
    },
  ],
}

const fourth: StepsProps = {
  steps: [
    {
      isCurrent: false,
      value: '1. Exposant',
    },
    {
      isCurrent: false,
      value: '2. Echantillons',
    },
    {
      isCurrent: false,
      value: '3. Unités',
    },
    {
      isCurrent: true,
      value: '4. Récapitulatif',
    },
  ],
}

const sampleData: StepsProps = {
  steps: [
    {
      isCurrent: false,
      value: '1. Présélection PV',
      resume: ['Marketplace + Forum'],
    },
    {
      isCurrent: false,
      value: '2. Forum',
      resume: ['Sourcing solution'],
    },
    {
      isCurrent: false,
      value: '3. Sous-forum',
      resume: ['Tailoring'],
    },
    {
      isCurrent: false,
      value: '4. Catégorie',
      resume: ['WOOL - Lainage '],
    },
    {
      isCurrent: false,
      value: '5. Destination produit',
      resume: ['Costume tailleur'],
    },
    {
      isCurrent: false,
      value: '6. motif',
      resume: ['Tissé-teint'],
    },
    {
      isCurrent: false,
      value: '7. codes de performances',
      resume: ['・Biopolymers', '・Breathing'],
    },
    {
      isCurrent: false,
      value: '8. INFO shooting',
      resume: ['Transparent'],
    },
    {
      isCurrent: false,
      value: '9. Caisse de rangement',
      resume: ['Accessories ・Jewel '],
    },
    {
      isCurrent: false,
      value: '10. récapitulatif',
    },
  ],
}

const sampleDataTrainee: StepsProps = {
  steps: [
    {
      isCurrent: false,
      value: '1. étiquette',
      resume: ['Image'],
    },
    {
      isCurrent: false,
      value: '2. référence',
      resume: ['échantillon #1'],
    },
    {
      isCurrent: false,
      value: '3. composition',
      resume: ['・Angora 8%'],
    },
    {
      isCurrent: false,
      value: '4. récapitulatif',
    },
  ],
}

const sampleSheetsEdit: StepsProps = {
  steps: [
    {
      isCurrent: false,
      value: '1. catégories',
      resume: ['Lorem ipsum'],
    },
    {
      isCurrent: false,
      value: '2. sous-catégories',
      resume: ['Lorem ipsum'],
    },
    {
      isCurrent: false,
      value: '3. codes performances',
      resume: ['Lorem ipsum'],
    },
    {
      isCurrent: false,
      value: '4. certifications',
      resume: ['Lorem ipsum'],
    },
    {
      isCurrent: false,
      value: '5. composition',
    },
  ],
}

export const stepsMocks = {
  basic,
  second,
  third,
  fourth,
  sampleData,
  sampleDataTrainee,
  sampleSheetsEdit,
}
