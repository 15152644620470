import styled from 'styled-components'

import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'
import TabsScrollable from '../../components/TabsScrollable'
import ActionButton from '../../components/ActionButton'

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-block: 1.2rem 2.4rem;
`
export const TopContent = styled(StyledContentWrapper)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem 2.4rem;
  }
`

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const BottomContent = styled(StyledContentWrapper)`
  padding-block: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`

export const Title = styled(Heading)`
  margin: 0;
`

export const SubTitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.gray};
`

export const Country = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.silver};
  &:not(:first-child) {
    margin-top: 0.2rem;
  }
  ${breakpoints.up('tablet')} {
  }
`

export const StyledTabs = styled(TabsScrollable)`
  // this is possibly garbage
  max-width: calc(100vw - var(--scrollbar-width, 0px));
  ${breakpoints.up('tablet')} {
    max-width: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`

export const Separator = styled.hr`
  align-self: stretch;
  border: 0;
  margin: 0;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.tundora};
  ${breakpoints.up('tablet')} {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`

export const Edit = styled(ActionButton)`
  margin: auto;
`
