import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'

import { actions, selectors } from '../../redux'
import { useLayoutLoungeConfigTemplateProps } from '../../layouts/LayoutLoungeConfig/useProps'
import { router, routesPath } from '../../router'
import { Icons } from '../../components/Icon'
import { ExhibitionSeasonCode } from '../../graphql/generated/graphql'
import { getContrastColor } from '../../relay/Forums/getContrastColor'

import { LoungesResumeTemplateProps } from '.'

export const useLoungeResumeTemplateProps = (): LoungesResumeTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const layoutLoungeProps = useLayoutLoungeConfigTemplateProps()
  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)
  const config = useSelector(selectors.configuration.configurationInfo)
  const createExhibition = useSelector(selectors.exhibitions.createExhibition)
  const updateExhibition = useSelector(selectors.exhibitions.updateExhibition)

  const formattedStartDate = dayjs(loungeConfig?.dateStart).format('DD')
  const formattedEndDate = dayjs(loungeConfig?.dateEnd).format('DD MMMM YYYY')

  const formattedStartSampleDate = dayjs(loungeConfig?.dateSampleStart).format('DD')
  const formattedEndSampleDate = dayjs(loungeConfig?.dateSampleEnd).format('DD MMMM YYYY')

  const isSameMonth = dayjs(loungeConfig?.dateStart).isSame(dayjs(loungeConfig?.dateEnd), 'month')

  const isSameMonthSample = dayjs(loungeConfig?.dateSampleStart).isSame(
    dayjs(loungeConfig?.dateSampleEnd),
    'month'
  )

  const dateRange = isSameMonth
    ? `${formattedStartDate} - ${formattedEndDate}`
    : `${formattedStartDate} ${dayjs(loungeConfig?.dateStart).format(
        'DD MMMM'
      )} - ${formattedEndDate}`

  const dateRangeSample = isSameMonthSample
    ? `${formattedStartSampleDate} - ${formattedEndSampleDate}`
    : `${formattedStartSampleDate} ${dayjs(loungeConfig?.dateSampleStart).format(
        'MMMM'
      )} - ${formattedEndSampleDate}`

  const mappedForumsAndSubForums = loungeConfig?.forums
    // @ts-ignore
    .filter((item) => item.selected)
    .map((forum) => {
      return {
        id: forum.id,
        name: forum.name,
        backgroundColor: getContrastColor(forum.color as string),
        color: forum.color as string,
        subForums: forum.subForums
          ? forum.subForums
              // @ts-ignore
              .filter((item) => item.selected)
              .map((subForum) => ({
                id: subForum.id,
                name: subForum.name,
              }))
          : undefined,
      }
    })

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutLoungeProps,
        bottomActions: {
          ...layoutLoungeProps.bottomActions,
          next: {
            ...layoutLoungeProps.bottomActions?.next,
            text: 'Valider',
            rightIconProps: {
              icon: Icons.check,
              width: 18,
              height: 18,
            },
            onClick: () => {
              config &&
                loungeConfig &&
                //@ts-ignore
                !loungeConfig.edit &&
                dispatch(
                  actions.exhibitions.createExhibitionRequest({
                    input: {
                      apiId: loungeConfig.apiId as string,
                      name: loungeConfig.name,
                      seasonCode: loungeConfig.seasonCode as ExhibitionSeasonCode,
                      seasonYear: loungeConfig.seasonYear,
                      dateStart: dayjs(loungeConfig.dateStart).format('YYYY-MM-DD'),
                      dateEnd: dayjs(loungeConfig.dateEnd).format('YYYY-MM-DD'),
                      dateSampleStart: dayjs(loungeConfig.dateSampleStart).format('YYYY-MM-DD'),
                      dateSampleEnd: dayjs(loungeConfig.dateSampleEnd).format('YYYY-MM-DD'),
                      shootingFolder: loungeConfig.shootingFolder,
                    },
                    forums: loungeConfig.forums
                      //@ts-ignore
                      .filter((forum) => forum.selected)
                      .map((forum) => ({
                        name: forum.name,
                        color: forum.color as string,
                        subForums: forum.subForums
                          ? forum.subForums
                              //@ts-ignore
                              .filter((forum) => forum.selected)
                              .map((subForum) => subForum.name)
                          : [],
                      })),
                  })
                )
              config &&
                loungeConfig &&
                //@ts-ignore
                loungeConfig.edit &&
                dispatch(
                  actions.exhibitions.updateExhibitionRequest({
                    exhibitionApiId: loungeConfig.apiId as string,
                    input: {
                      apiId: loungeConfig.apiId as string,
                      name: loungeConfig.name,
                      seasonCode: loungeConfig.seasonCode as ExhibitionSeasonCode,
                      seasonYear: loungeConfig.seasonYear,
                      dateStart: dayjs(loungeConfig.dateStart).format('YYYY-MM-DD'),
                      dateEnd: dayjs(loungeConfig.dateEnd).format('YYYY-MM-DD'),
                      dateSampleStart: dayjs(loungeConfig.dateSampleStart).format('YYYY-MM-DD'),
                      dateSampleEnd: dayjs(loungeConfig.dateSampleEnd).format('YYYY-MM-DD'),
                      shootingFolder: loungeConfig.shootingFolder,
                    },
                    forums: loungeConfig.forums
                      //@ts-ignore
                      .filter((forum) => forum.selected)
                      .map((forum) => ({
                        name: forum.name,
                        forumId: forum.id ? forum.id : null,
                        color: forum.color as string,
                        subForums: forum.subForums
                          ? forum.subForums
                              //@ts-ignore
                              .filter((subForum) => subForum.selected)
                              .map((subForum) => ({
                                subForumId: subForum.id ? subForum.id : null,
                                name: subForum.name,
                              }))
                          : [],
                      })),
                  })
                )
            },
          },
          back: {
            ...layoutLoungeProps.bottomActions?.back,
            onClick: () => {
              const link = router(routesPath.loungesSubForumsChoice)
              navigate(link)
            },
          },
        },
      },
      title: 'Vérifiez et confirmez les informations',
      checkBoxTitle: 'Les forums et sous-forums',
      resume: loungeConfig
        ? [
            { title: 'Nom du salon', text: loungeConfig.name },
            {
              title: 'Saison',
              text: `${loungeConfig.seasonCode}${loungeConfig.seasonYear}`,
            },
            { title: 'Exhibition ID', text: loungeConfig?.apiId as string },
            { title: 'Répertoire SFTP Shooting', text: loungeConfig?.shootingFolder as string },
            { title: 'Dates du salon', text: dateRange },
            { title: 'Période de sélection des échantillons', text: dateRangeSample },
          ]
        : [],
      subForumList: mappedForumsAndSubForums ? mappedForumsAndSubForums : [],
      error:
        updateExhibition && updateExhibition.errors
          ? updateExhibition.errors[0].label
          : createExhibition && createExhibition.errors
          ? createExhibition.errors[0].label
          : undefined,
    }
  }, [
    config,
    createExhibition,
    dateRange,
    dateRangeSample,
    dispatch,
    layoutLoungeProps,
    loungeConfig,
    mappedForumsAndSubForums,
    navigate,
    updateExhibition,
  ])
}
