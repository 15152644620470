import styled from 'styled-components'

import Heading from '../../components/Heading'
import { fonts, colors, breakpoints } from '../../theme'
import ActionButton from '../../components/ActionButton'
import SearchBar from '../../components/SearchBar'
import EditoCard from '../../components/EditoCard'
import SampleCard from '../../components/SampleCard'
import FiltersTags from '../../components/FiltersTags'
import { FieldText } from '../BoxesAdd/styled'
import FormFieldTextArea from '../../components/form/fields/FormFieldTextArea'
import FormErrors from '../../components/form/FormErrors'
import FormikForm from '../../components/form/FormikForm'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
`
export const SubTitle = styled.div`
  ${fonts.RobotoLight}
  color: ${colors.tundora};
  font-size: 17px;
  letter-spacing: 0.5px;
  padding-bottom: 1.2rem;
`

export const Text = styled.div`
  ${fonts.RobotoLight}
  color: ${colors.gray};
  font-size: 1.2rem;
  letter-spacing: 1px;
  padding-right: 1rem;
  margin-bottom: 2.2rem;
`

export const Search = styled(SearchBar)`
  margin: 2.2rem 0;
`

export const EditoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 0;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const Edito = styled(EditoCard)``

export const CTA = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
`

export const Button = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: space-between;
  ${breakpoints.up('tablet')} {
    gap: 42px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 1.5rem;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
  height: fit-content;
  ${breakpoints.up('tablet')} {
    justify-content: center;
    gap: 2.2rem;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

export const Filter = styled(FiltersTags)`
  padding-top: 1.5rem;
`

export const Card = styled(SampleCard)`
  width: 100%;
  ${breakpoints.up('tablet')} {
    max-width: 334px;
  }
`

export const Edit = styled(ActionButton)`
  margin: auto;
`

export const Delete = styled(ActionButton)`
  margin: auto;
`

export const Fieldset = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
`

export const StyledTextarea = styled(FormFieldTextArea)``
export const StyledTextInput = styled(FieldText)``

export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 2.4rem;
  ${breakpoints.up('tablet')} {
    gap: 3.6rem;
  }
`
export const Errors = styled(FormErrors)``
