import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import EditoSheetTemplate from '../../templates/EditoSheet'
import { useEditoSheetTemplateProps } from '../../templates/EditoSheet/useProps'
import Filters from '../../components/Filters'
import { useFiltersTemplateProps } from '../../templates/Filters/useProps'
import { actions } from '../../redux'
import { FiltersFormValues } from '../../components/forms/FiltersForm'
import { FilterTagProps } from '../../components/FilterTag'

const EditoSheet: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const [tags, setTags] = useState<Array<FilterTagProps>>([])
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [universe, setUniverse] = useState<string | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filters, setFilters] = useState<FiltersFormValues | undefined>(undefined)

  const handleFiltersClick = () => {
    setOpenFilters(!openFilters)
  }
  const templateProps = useEditoSheetTemplateProps(
    tags,
    handleFiltersClick,
    universe,
    currentPage,
    filters
  )

  useEffect(() => {
    if (universe) {
      dispatch(actions.samples.sampleSearchFiltersRequest({ universeEbmsId: universe }))
    }
  }, [universe, dispatch])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [tags])

  const filterstemplateProps = useFiltersTemplateProps({
    tags,
    setTags,
    handleFiltersClick,
    openFilters,
    setUniverse,
    universe,
    isExponent: false,
    setFilters,
  })

  return (
    <>
      <EditoSheetTemplate {...templateProps} />
      <Filters {...filterstemplateProps} isOpen={openFilters} onClose={handleFiltersClick} />
    </>
  )
}

export default EditoSheet
