import { RouteType } from '../types/routes'
import HomePage from '../pages/Home'
import LoginPage from '../pages/Login'
import FirstLoginPage from '../pages/FirstLogin'
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
import ResetPasswordPage from '../pages/ResetPassword'
import ForgottenPasswordPage from '../pages/ForgottenPassword'
import MyAccount from '../pages/MyAccount'
import SampleScan from '../pages/SampleScan'
import Exponent from '../pages/Exponent'
import Lounges from '../pages//Lounges/LoungesSearch'
import Params from '../pages/Params'
import Boxes from '../pages/Boxes'
import Search from '../pages/Search'
import ParamsImportDelay from '../pages/ParamsImportDelay'
import ParamsUsers from '../pages/ParamsUsers'
import ParamsUserCreate from '../pages/ParamsUserCreate'
import ParamsUserManage from '../pages/ParamsUserManage'
import { UserRole } from '../graphql/generated/graphql'
import MultipleEditionEdito from '../pages/MultipleEdition/MultipleEditionEdito'
import ParamsBoxes from '../pages/ParamsBoxes'
import ParamsAddBoxes from '../pages/ParamsNewBoxes'
import ExponentCollection from '../pages/ExponentSheet/ExponentCollection'
import ExponentParcels from '../pages/ExponentSheet/ExponentParcels'
import ExponentSampleDataStep1 from '../pages/ExponentSampleData/StepMkpForum'
import ExponentSampleDataForum from '../pages/ExponentSampleData/StepForum'
import ExponentSampleDataSubForum from '../pages/ExponentSampleData/StepSubForum'
import ExponentSampleDataCategory from '../pages/ExponentSampleData/StepCategory'
import ExponentSampleDataBoxes from '../pages/ExponentSampleData/StepBoxes'
import ExponentSampleDataPattern from '../pages/ExponentSampleData/StepPattern'
import ExponentSampleDataPerformanceCode from '../pages/ExponentSampleData/StepPerformanceCode'
import ExponentSampleDataProductDestination from '../pages/ExponentSampleData/StepProductDestination'
import ExponentSampleDataShooting from '../pages/ExponentSampleData/StepShooting'
import ExponentSampleDataSummary from '../pages/ExponentSampleData/StepSummary'
import ExponentSampleDataNumberEnd from '../pages/ExponentSampleData/StepNumberEnd'
import ExponentSampleDataTechnologyActivity from '../pages/ExponentSampleData/StepTechnologyActivity'
import ExponentSampleDataAspect from '../pages/ExponentSampleData/StepAspect'
import ExponentSampleDataUnitValue from '../pages/ExponentSampleData/StepUnitValue'
import ExponentSampleDataCertifications from '../pages/ExponentSampleData/StepCertifications'
import ExponentSampleDataPatternType from '../pages/ExponentSampleData/StepPatternType'
import ExponentSampleDataTanningType from '../pages/ExponentSampleData/StepTanningType'
import ExponentSampleDataAnimal from '../pages/ExponentSampleData/StepAnimal'
import ExponentSampleDataLabel from '../pages/ExponentSampleData/StepLabel'
import ExponentSampleDataExhibitorRef from '../pages/ExponentSampleData/StepExhibitorRef'
import ExponentSampleDataAccessorieType from '../pages/ExponentSampleData/StepAccessorieType'
import ExponentSampleDataSmartCreationChoice from '../pages/ExponentSampleData/StepSmartCreationChoice'
import ExponentSampleDataLeatherChoice from '../pages/ExponentSampleData/StepLeatherChoice'
import ExponentSampleDataColor from '../pages/ExponentSampleData/StepColor'
import ExponentSampleDataMaterial from '../pages/ExponentSampleData/StepMaterial'
import SampleSheetData from '../pages/SampleSheet/SampleSheetData'
import SampleSheetCollection from '../pages/SampleSheet/SampleSheetCollection'
import SampleSheetEdito from '../pages/SampleSheet/SampleSheetEdito'
import SampleSheetShooting from '../pages/SampleSheet/SampleSheetShooting'
import SampleSheetHistory from '../pages/SampleSheet/SampleSheetHistory'
import SampleSheetLabel from '../pages/SampleSheet/SampleSheetLabel'
import MultipleEditionScan from '../pages/MultipleEdition/MultipleEditionScan'
import MultipleEditionForum from '../pages/MultipleEdition/MultipleEditionForum'
import MultipleEditionMenu from '../pages/MultipleEdition/MultipleEditionMenu'
import MultipleEditionMkpForum from '../pages/MultipleEdition/MultipleEditionMkpForum'
import MultipleEditionStatutSelection from '../pages/MultipleEdition/MultipleEditionStatutSelection '
import MultipleEditionSubForum from '../pages/MultipleEdition/MultipleEditionSubForum'
import ExponentSheetInfos from '../pages/ExponentSheet/ExponentSheetInfos'
import ExponentSheetInternalNote from '../pages/ExponentSheet/ExponentInternalNote'
import LoungesInfo from '../pages/Lounges/LoungesInfo'
import LoungesDates from '../pages/Lounges/LoungesDates'
import LoungesDatesSamples from '../pages/Lounges/LoungesDatesSamples'
import LoungesForums from '../pages/Lounges/LoungesForums'
import LoungesForumsChoice from '../pages/Lounges/LoungesForumChoice'
import ExponentSampleDataManufacturingType from '../pages/ExponentSampleData/StepManufacturingType'
import LoungesSubForumsChoice from '../pages/Lounges/LoungesSubForumsChoice'
import LoungesResume from '../pages/Lounges/LoungesResume'
import SampleSheets from '../pages/ParamsSampleEdit'
import SampleSheetEditAccessorieType from '../pages/SampleSheetEdit/StepAccessorieType'
import SampleSheetEditAnimal from '../pages/SampleSheetEdit/StepAnimal'
import SampleSheetEditAspect from '../pages/SampleSheetEdit/StepAspect'
import SampleSheetEditCategory from '../pages/SampleSheetEdit/StepCategory'
import SampleSheetEditCertifications from '../pages/SampleSheetEdit/StepCertifications'
import SampleSheetEditColor from '../pages/SampleSheetEdit/StepColor'
import SampleSheetEditManufacturingType from '../pages/SampleSheetEdit/StepManufacturingType'
import SampleSheetEditMaterial from '../pages/SampleSheetEdit/StepMaterial'
import SampleSheetEditNumberEnd from '../pages/SampleSheetEdit/StepNumberEnd'
import SampleSheetEditPattern from '../pages/SampleSheetEdit/StepPattern'
import SampleSheetEditPatternType from '../pages/SampleSheetEdit/StepPatternType'
import SampleSheetEditPerformanceCode from '../pages/SampleSheetEdit/StepPerformanceCode'
import SampleSheetEditProductDestination from '../pages/SampleSheetEdit/StepProductDestination'
import SampleSheetEditShooting from '../pages/SampleSheetEdit/StepShooting'
import SampleSheetEditTanningType from '../pages/SampleSheetEdit/StepTanningType'
import SampleSheetEditTechnologyActivi from '../pages/SampleSheetEdit/StepTechnologyActivity'
import SampleSheetEditUnitValue from '../pages/SampleSheetEdit/StepUnitValue'
import ExponentSheetArchive from '../pages/ExponentSheet/ExponentArchive'
import ParamsEmails from '../pages/ParamsMails'
import ManageEmails from '../pages/ManageEmails'
import ExhibitionSheetInfos from '../pages/ExhibitionSheet/ExhibitionInfos'
import ExhibitionSheetSamples from '../pages/ExhibitionSheet/ExhibitionSamples'
import ExhibitionSheetExhibitor from '../pages/ExhibitionSheet/ExhibitionExhibitor'
import ExhibitionSheetEdito from '../pages/ExhibitionSheet/ExhibitionEdito'
import EditoSheet from '../pages/EditoSheet'
import ParamsImportExhibitor from '../pages/ParamsImportExhibitor'

import { routesPath } from './index'

const publicRoutes: RouteType = {
  path: '',
  component: null,
  guard: GuestGuard,
  children: [
    {
      path: routesPath.login,
      name: 'Login',
      component: LoginPage,
    },
    {
      path: routesPath.firstLogin,
      name: 'FirstLogin',
      component: FirstLoginPage,
    },
    {
      path: routesPath.resetPassword,
      name: 'ResetPassword',
      component: ResetPasswordPage,
    },
    {
      path: routesPath.forgottenPassword,
      name: 'ForgottenPassword',
      component: ForgottenPasswordPage,
    },
  ],
}

const privateRoutes: RouteType = {
  path: '',
  component: null,
  guard: AuthGuard,
  children: [
    {
      path: routesPath.home,
      name: 'Home',
      component: HomePage,
    },
    {
      path: routesPath.myAccount,
      name: 'MyAccount',
      component: MyAccount,
    },
    {
      path: routesPath.sampleScan,
      name: 'SampleScan',
      component: SampleScan,
    },
    {
      path: routesPath.multipleEdition,
      name: 'EditionMultipleMenu',
      component: MultipleEditionMenu,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
        UserRole.Shooting,
      ],
    },
    {
      path: routesPath.multipleEditionScan,
      name: 'MultipleEditionScan',
      component: MultipleEditionScan,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
        UserRole.Shooting,
      ],
    },
    {
      path: routesPath.multipleEditionStatut,
      name: 'MultipleEditionStatutSelection',
      component: MultipleEditionStatutSelection,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
        UserRole.Shooting,
      ],
    },
    {
      path: routesPath.multipleEditionMkpForum,
      name: 'MultipleEditionMkpForum',
      component: MultipleEditionMkpForum,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
      ],
    },
    {
      path: routesPath.multipleEditionForum,
      name: 'MultipleEditionForum',
      component: MultipleEditionForum,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
      ],
    },
    {
      path: routesPath.multipleEditionSubForum,
      name: 'MultipleEditionSubForum',
      component: MultipleEditionSubForum,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
      ],
    },
    {
      path: routesPath.multipleEditionEdito,
      name: 'MultipleEditionEdito',
      component: MultipleEditionEdito,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
      ],
    },
    {
      path: routesPath.editoSheet,
      name: 'editoSheet',
      component: EditoSheet,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.ModePv,
        UserRole.Rex,
        UserRole.Marketplace,
      ],
    },
    {
      path: routesPath.exponent,
      name: 'Exponent',
      component: Exponent,
    },
    {
      path: routesPath.exponentSheet,
      name: 'ExponentCollection',
      component: ExponentCollection,
    },
    {
      path: routesPath.exponentSheetParcels,
      name: 'ExponentParcels',
      component: ExponentParcels,
    },
    {
      path: routesPath.exponentSheetInfos,
      name: 'ExponentSheetInfos',
      component: ExponentSheetInfos,
    },
    {
      path: routesPath.exponentSheetInternalNote,
      name: 'ExponentSheetInternalNote',
      component: ExponentSheetInternalNote,
    },
    {
      path: routesPath.exponentSheetArchive,
      name: 'exponentSheetArchive',
      component: ExponentSheetArchive,
    },
    {
      path: routesPath.exhibitionSheetInfos,
      name: 'ExhibitionSheetInfos',
      component: ExhibitionSheetInfos,
    },
    {
      path: routesPath.exhibitionSheetSamples,
      name: 'ExhibitionSheetSamples',
      component: ExhibitionSheetSamples,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.ModePv,
        UserRole.Rex,
        UserRole.Marketplace,
      ],
    },
    {
      path: routesPath.exhibitionSheetExhibitor,
      name: 'exhibitionSheetExhibitor',
      component: ExhibitionSheetExhibitor,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.ModePv,
        UserRole.Rex,
        UserRole.Marketplace,
      ],
    },
    {
      path: routesPath.exhibitionSheetEdito,
      name: 'ExhibitionSheetEdito',
      component: ExhibitionSheetEdito,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.ModePv,
        UserRole.Rex,
        UserRole.Marketplace,
      ],
    },
    {
      path: routesPath.sampleSheetData,
      name: 'SampleSheetData',
      component: SampleSheetData,
    },
    {
      path: routesPath.sampleSheetCollection,
      name: 'SampleSheetCollection',
      component: SampleSheetCollection,
    },
    {
      path: routesPath.sampleSheetEdito,
      name: 'SampleSheetEdito',
      component: SampleSheetEdito,
    },
    {
      path: routesPath.sampleSheetShooting,
      name: 'SampleSheetShooting',
      component: SampleSheetShooting,
    },
    {
      path: routesPath.sampleSheetHistory,
      name: 'SampleSheetHistory',
      component: SampleSheetHistory,
    },
    {
      path: routesPath.sampleSheetLabel,
      name: 'SampleSheetLabel',
      component: SampleSheetLabel,
    },
    {
      path: routesPath.sampleSheetEditAccessorieType,
      name: 'sampleSheetEditAccessorieType',
      component: SampleSheetEditAccessorieType,
    },
    {
      path: routesPath.sampleSheetEditAnimal,
      name: 'sampleSheetEditAnimal',
      component: SampleSheetEditAnimal,
    },
    {
      path: routesPath.sampleSheetEditAspect,
      name: 'sampleSheetEditAspect',
      component: SampleSheetEditAspect,
    },
    {
      path: routesPath.sampleSheetEditCategory,
      name: 'sampleSheetEditCategory',
      component: SampleSheetEditCategory,
    },
    {
      path: routesPath.sampleSheetEditCertifications,
      name: 'sampleSheetEditCertifications',
      component: SampleSheetEditCertifications,
    },
    {
      path: routesPath.sampleSheetEditColor,
      name: 'sampleSheetEditColor',
      component: SampleSheetEditColor,
    },
    {
      path: routesPath.sampleSheetEditManufacturingType,
      name: 'sampleSheetEditManufacturingType',
      component: SampleSheetEditManufacturingType,
    },
    {
      path: routesPath.sampleSheetEditMaterial,
      name: 'sampleSheetEditMaterial',
      component: SampleSheetEditMaterial,
    },
    {
      path: routesPath.sampleSheetEditNumberEnd,
      name: 'sampleSheetEditNumberEnd',
      component: SampleSheetEditNumberEnd,
    },
    {
      path: routesPath.sampleSheetEditPattern,
      name: 'sampleSheetEditPattern',
      component: SampleSheetEditPattern,
    },
    {
      path: routesPath.sampleSheetEditPatternType,
      name: 'sampleSheetEditPatternType',
      component: SampleSheetEditPatternType,
    },
    {
      path: routesPath.sampleSheetEditPerformanceCode,
      name: 'sampleSheetEditPerformanceCode',
      component: SampleSheetEditPerformanceCode,
    },
    {
      path: routesPath.sampleSheetEditProductDestination,
      name: 'sampleSheetEditProductDestination',
      component: SampleSheetEditProductDestination,
    },
    {
      path: routesPath.sampleSheetEditShooting,
      name: 'sampleSheetEditShooting',
      component: SampleSheetEditShooting,
    },
    {
      path: routesPath.sampleSheetEditTanningType,
      name: 'sampleSheetEditTanningType',
      component: SampleSheetEditTanningType,
    },
    {
      path: routesPath.sampleSheetEditTechnologyActivity,
      name: 'sampleSheetEditTechnologyActivity',
      component: SampleSheetEditTechnologyActivi,
    },
    {
      path: routesPath.sampleSheetEditUnitValue,
      name: 'sampleSheetEditUnitValue',
      component: SampleSheetEditUnitValue,
    },
    // Saisie data echantillons fiche exposant
    {
      path: routesPath.exponentSampleDataMkpForum,
      name: 'ExponentSampleDataStep1',
      component: ExponentSampleDataStep1,
    },
    {
      path: routesPath.exponentSampleDataForum,
      name: 'ExponentSampleDataStep2',
      component: ExponentSampleDataForum,
    },
    {
      path: routesPath.exponentSampleDataSubForum,
      name: 'ExponentSampleDataSubForum',
      component: ExponentSampleDataSubForum,
    },
    {
      path: routesPath.exponentSampleDataCategory,
      name: 'ExponentSampleDataCategory',
      component: ExponentSampleDataCategory,
    },
    {
      path: routesPath.exponentSampleDataBoxes,
      name: 'ExponentSampleDataBoxes',
      component: ExponentSampleDataBoxes,
    },
    {
      path: routesPath.exponentSampleDataPattern,
      name: 'ExponentSampleDataPattern',
      component: ExponentSampleDataPattern,
    },
    {
      path: routesPath.exponentSampleDataPerformanceCode,
      name: 'ExponentSampleDataPerformanceCode',
      component: ExponentSampleDataPerformanceCode,
    },
    {
      path: routesPath.exponentSampleDataProductDestination,
      name: 'ExponentSampleDataProductDestination',
      component: ExponentSampleDataProductDestination,
    },
    {
      path: routesPath.exponentSampleDataShooting,
      name: 'ExponentSampleDataShooting',
      component: ExponentSampleDataShooting,
    },
    {
      path: routesPath.exponentSampleDataSummary,
      name: 'ExponentSampleDataSummary',
      component: ExponentSampleDataSummary,
    },
    {
      path: routesPath.exponentSampleDataNumberEnd,
      name: 'ExponentSampleDataNumberEnd',
      component: ExponentSampleDataNumberEnd,
    },
    {
      path: routesPath.exponentSampleDataTechnologyActivity,
      name: 'ExponentSampleDataTechnologyActivity',
      component: ExponentSampleDataTechnologyActivity,
    },
    {
      path: routesPath.exponentSampleDataAspect,
      name: 'ExponentSampleDataAspect',
      component: ExponentSampleDataAspect,
    },
    {
      path: routesPath.exponentSampleDataUnitValue,
      name: 'ExponentSampleDataUnitValue',
      component: ExponentSampleDataUnitValue,
    },
    {
      path: routesPath.exponentSampleDataCertifications,
      name: 'ExponentSampleDataCertifications',
      component: ExponentSampleDataCertifications,
    },
    {
      path: routesPath.exponentSampleDataPatternType,
      name: 'ExponentSampleDataPatternType',
      component: ExponentSampleDataPatternType,
    },
    {
      path: routesPath.exponentSampleDataTanningType,
      name: 'ExponentSampleDataTanningType',
      component: ExponentSampleDataTanningType,
    },
    {
      path: routesPath.exponentSampleDataAnimal,
      name: 'ExponentSampleDataAnimal',
      component: ExponentSampleDataAnimal,
    },
    {
      path: routesPath.exponentSampleDataLabel,
      name: 'ExponentSampleDataLabel',
      component: ExponentSampleDataLabel,
    },
    {
      path: routesPath.exponentSampleDataExhibitorRef,
      name: 'ExponentSampleDataExhibitorRef',
      component: ExponentSampleDataExhibitorRef,
    },
    {
      path: routesPath.exponentSampleDataAccessorieType,
      name: 'ExponentSampleDataAccessorieType',
      component: ExponentSampleDataAccessorieType,
    },
    {
      path: routesPath.exponentSampleDataSmartCreationChoice,
      name: 'ExponentSampleDataSmartCreationChoice',
      component: ExponentSampleDataSmartCreationChoice,
    },
    {
      path: routesPath.exponentSampleDataLeatherChoice,
      name: 'ExponentSampleDataLeatherChoice',
      component: ExponentSampleDataLeatherChoice,
    },
    {
      path: routesPath.exponentSampleDataColor,
      name: 'ExponentSampleDataColor',
      component: ExponentSampleDataColor,
    },
    {
      path: routesPath.exponentSampleDataMaterial,
      name: 'ExponentSampleDataMaterial',
      component: ExponentSampleDataMaterial,
    },
    {
      path: routesPath.exponentSampleDataManufacturingType,
      name: 'ExponentSampleDataManufacturingType',
      component: ExponentSampleDataManufacturingType,
    },
    // Fin routes saisie data echantillons fiche exposant
    {
      path: routesPath.myAccount,
      name: 'MyAccount',
      component: MyAccount,
    },
    {
      path: routesPath.lounges,
      name: 'Lounges',
      component: Lounges,
    },
    {
      path: routesPath.loungesInfos,
      name: 'LoungesInfo',
      component: LoungesInfo,
    },
    {
      path: routesPath.loungesDates,
      name: 'LoungesDates',
      component: LoungesDates,
    },
    {
      path: routesPath.loungesDatesSamples,
      name: 'LoungesDatesSamples',
      component: LoungesDatesSamples,
    },
    {
      path: routesPath.loungesForums,
      name: 'LoungesForums',
      component: LoungesForums,
    },
    {
      path: routesPath.loungesForumsChoice,
      name: 'LoungesForumsChoice',
      component: LoungesForumsChoice,
    },
    {
      path: routesPath.loungesSubForumsChoice,
      name: 'LoungesSubForumsChoice',
      component: LoungesSubForumsChoice,
    },
    {
      path: routesPath.loungeResume,
      name: 'LoungeResume',
      component: LoungesResume,
    },
    {
      path: routesPath.params,
      name: 'Params',
      component: Params,
      role: [UserRole.SuperAdmin, UserRole.Admin, UserRole.ModePv, UserRole.Marketplace],
    },
    {
      path: routesPath.sampleSheets,
      name: 'sampleSheets',
      component: SampleSheets,
      role: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Marketplace],
    },
    {
      path: routesPath.paramsBoxes,
      name: 'ParamsBoxes',
      component: ParamsBoxes,
      role: [UserRole.SuperAdmin, UserRole.Admin, UserRole.ModePv],
    },
    {
      path: routesPath.paramsAddBoxes,
      name: 'ParamsAddBoxes',
      component: ParamsAddBoxes,
      role: [UserRole.SuperAdmin, UserRole.Admin, UserRole.ModePv],
    },
    {
      path: routesPath.paramsImportDelay,
      name: 'ParamsImportDelay',
      component: ParamsImportDelay,
      role: [UserRole.SuperAdmin, UserRole.Admin],
    },
    {
      path: routesPath.paramsImportExhibitorEbms,
      name: 'ParamsImportExhibitorEbms',
      component: ParamsImportExhibitor,
      role: [UserRole.SuperAdmin, UserRole.Admin],
    },
    {
      path: routesPath.paramsUsers,
      name: 'ParamsUsers',
      component: ParamsUsers,
      role: [UserRole.SuperAdmin, UserRole.Admin],
    },
    {
      path: routesPath.paramsUserCreate,
      name: 'ParamsUserCreate',
      component: ParamsUserCreate,
      role: [UserRole.SuperAdmin, UserRole.Admin],
    },
    {
      path: routesPath.paramsUserManage,
      name: 'ParamsUserManage',
      component: ParamsUserManage,
      role: [UserRole.SuperAdmin, UserRole.Admin],
    },
    {
      path: routesPath.paramsEmails,
      name: 'ParamsEmails',
      component: ParamsEmails,
      role: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Marketplace],
    },
    {
      path: routesPath.manageEmails,
      name: 'ManageEmails',
      component: ManageEmails,
      role: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Marketplace],
    },
    {
      path: routesPath.boxes,
      name: 'Boxes',
      component: Boxes,
    },
    {
      path: routesPath.search,
      name: 'Search',
      component: Search,
      role: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FashionExpert,
        UserRole.InputOperator,
        UserRole.Intern,
        UserRole.ModePv,
        UserRole.Shooting,
        UserRole.Rex,
        UserRole.Marketplace,
      ],
    },
  ],
}

export const defaultLayoutRoutes = [publicRoutes, privateRoutes]
