import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { FormFieldInputNumberProps } from '../../form/fields/FormFieldInputNumber'

import * as SC from './styled'

export type ParcelEditFormValues = {
  total: number
  distincts: number
}

export type ParcelEditFormProps = {
  className?: string
  formikForm: FormikFormProps<ParcelEditFormValues>
  fields: {
    title?: string
    text?: string
    props: FormFieldInputNumberProps
  }[]
  errors?: FormErrorsProps['errors']
}

const ParcelEditForm: FC<ParcelEditFormProps> = ({ className, formikForm, fields, errors }) => {
  return fields && fields.length > 0 ? (
    <SC.Container className={className}>
      <SC.Form {...formikForm}>
        {fields.map((field, index) => (
          <SC.Fieldset key={index}>
            {field.title && <SC.Title tag="h2" text={field.title} />}
            {field.text && <SC.Text>{field.text}</SC.Text>}
            <FormikField hasFloatingLabel={false} Component={SC.StyledField} {...field.props} />
          </SC.Fieldset>
        ))}
        {errors && <SC.Errors errors={errors} />}
      </SC.Form>
    </SC.Container>
  ) : null
}

export default ParcelEditForm
