import { SampleData } from '../../types/sampleData'

import {
  routesAccessories,
  routesAccessoriesMarketplace,
  routesDesign,
  routesDesignMarketplace,
  routesFabrics,
  routesFabricsMarketplace,
  routesLeather,
  routesLeatherMarketplace,
  routesLeatherSpecial,
  routesLeatherSpecialMarketplace,
  routesManufacturing,
  routesManufacturingLeather,
  routesManufacturingLeatherMarketplace,
  routesManufacturingMarketplace,
  routesTrainee,
  routesTraineeShort,
  routesTraineeShortDesign,
  routesTraineeShortManuf,
  routesYarns,
  routesYarnsMarketplace,
} from './RoutesSampleData'

export const sampleDataGetKeyToUpdate = (sampleData: SampleData) => {
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)

  if (sampleData.routeType === 'Fabrics') {
    for (const route of routesFabrics) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'FabricsMarketplace') {
    for (const route of routesFabricsMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Accessories') {
    for (const route of routesAccessories) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }
  if (sampleData.routeType === 'AccessoriesMarketplace') {
    for (const route of routesAccessoriesMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Leather') {
    for (const route of routesLeather) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'LeatherMarketplace') {
    for (const route of routesLeatherMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'LeatherSpecial') {
    for (const route of routesLeatherSpecial) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'LeatherSpecialMarketplace') {
    for (const route of routesLeatherSpecialMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Manufacturing Leather') {
    for (const route of routesManufacturingLeather) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Manufacturing LeatherMarketplace') {
    for (const route of routesManufacturingLeatherMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Manufacturing') {
    for (const route of routesManufacturing) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'ManufacturingMarketplace') {
    for (const route of routesManufacturingMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Yarns') {
    for (const route of routesYarns) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'YarnsMarketplace') {
    for (const route of routesYarnsMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Designs') {
    for (const route of routesDesign) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'DesignsMarketplace') {
    for (const route of routesDesignMarketplace) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'Trainee') {
    for (const route of routesTrainee) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'TraineeManuf') {
    for (const route of routesTraineeShortManuf) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'TraineeDesign') {
    for (const route of routesTraineeShortDesign) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  if (sampleData.routeType === 'TraineeShort') {
    for (const route of routesTraineeShort) {
      if (route.location === lastSegment) {
        return route.location
      }
    }
  }

  return undefined
}
