import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { breakpoints } from '../../theme'
import { SampleStatus } from '../../graphql/generated/graphql'

const TopTagStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
  width: 100%;
  padding: 0.6rem 1rem;
  text-align: right;
  ${breakpoints.up('tablet')} {
    padding: 1.5rem 3.5rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
`

export const Dot = styled.span`
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  background-color: currentColor;
  border-radius: 50%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  ${breakpoints.up('tablet')} {
    flex-direction: row;
    margin-top: 12px;
  }
`

export const Forum = styled.div<{
  $color?: string
  $backgroundColor?: string
}>`
  ${TopTagStyle}
  font-weight: 700;
  ${({ $backgroundColor, $color }) =>
    $backgroundColor &&
    css`
      color: ${$color};
      background-color: ${$backgroundColor};
    `}
`

export const MpkForum = styled.p<{ $status?: string; $okMkp?: boolean; $noMkp?: boolean }>`
  ${TopTagStyle}
  font-weight: 700;
  ${({ $okMkp, $noMkp }) => {
    if ($noMkp) {
      return css`
        ${({ theme }) => css`
          color: ${theme.colors.mineShaft};
          background-color: ${theme.colors.red};
        `};
      `
    }

    switch ($okMkp) {
      case false:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.mineShaft};
            background-color: ${theme.colors.concrete};
          `};
        `
      case true:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.chateauGreen};
            background-color: ${theme.colors.appleGreen};
          `};
        `
    }
  }};
`

export const Status = styled.span<{ $status?: SampleStatus }>`
  ${TopTagStyle}
  font-weight: 700;
  ${({ $status }) => {
    switch ($status) {
      case SampleStatus.Deleted:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.cinnabar};
            background-color: ${rgba(theme.colors.cinnabar, 0.15)};
          `};
        `
      case SampleStatus.SelectedOkShooting:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.selectiveTextYellow};
            background-color: ${rgba(theme.colors.selectiveTextYellowDark, 0.15)};
          `};
        `
      case SampleStatus.Selected:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.selectiveTextYellow};
            background-color: ${rgba(theme.colors.selectiveTextYellowDark, 0.15)};
          `};
        `
      case SampleStatus.Validated:
      case SampleStatus.ValidatedChecked:
      case SampleStatus.ValidatedLabelled:
      case SampleStatus.ValidatedOkMarketplace:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.chateauGreen};
            background-color: ${rgba(theme.colors.chateauGreen, 0.15)};
          `};
        `
    }
  }};
`

// const leatherVariant = css`
//   color: ${(props) => props.theme.colors.roseOfSharon};
//   background-color: ${(props) => props.theme.colors.janna};
// `

// const sourcingVariant = css`
//   color: ${(props) => props.theme.colors.danube};
//   background-color: ${(props) => props.theme.colors.linkWater};
// `

// const accessoriesVariant = css`
//   color: ${(props) => props.theme.colors.pizza};
//   background-color: ${(props) => props.theme.colors.albescentWhite};
// `

// const inspirationVariant = css`
//   color: ${(props) => props.theme.colors.royalPurple};
//   background-color: ${(props) => props.theme.colors.whiteLilac};
// `

// const designVariant = css`
//   color: ${(props) => props.theme.colors.fuchsiaPink};
//   background-color: ${(props) => props.theme.colors.amour};
// `
