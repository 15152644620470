import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { ForumProps } from '../BoxesList'
import { FormikFormProps } from '../../components/form/FormikForm'
import { FormFieldInputProps } from '../../components/form/fields/FormFieldInput'
import { FormErrorsProps } from '../../components/form/FormErrors'
import FormikField from '../../components/form/FormikField'

import * as SC from './styled'

export type BoxesAddFormValues = {
  name: string
}

export type BoxesAddFormProps = {
  formikForm: FormikFormProps<BoxesAddFormValues>
  fields: {
    name: FormFieldInputProps
  }
  errors?: FormErrorsProps['errors']
}

export type BoxesAddTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title?: string
  subtitle?: string
  text?: string
  forum?: ForumProps
  form: BoxesAddFormProps
}

const BoxesAddTemplate: FC<BoxesAddTemplateProps> = ({
  layoutProps,
  title,
  subtitle,
  text,
  forum,
  form,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <SC.StyledContentWrapper maxWidth="default">
        {title && <SC.Title tag={'h2'} text={title} />}
        {subtitle && <SC.Subtitle tag={'h3'} text={subtitle} />}
        {text && <SC.Text>{text}</SC.Text>}
        {forum && (
          <SC.Item $color={forum.color}>
            {forum.number && <SC.Number>{forum.number}</SC.Number>}
            <SC.Label>{forum.name}</SC.Label>
          </SC.Item>
        )}
        <SC.Form {...form.formikForm}>
          <FormikField
            {...form.fields.name}
            type="text"
            hasFloatingLabel={true}
            Component={SC.FieldText}
          />
          {form.errors && <SC.Errors errors={form.errors} />}
        </SC.Form>
      </SC.StyledContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default BoxesAddTemplate
