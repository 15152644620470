import styled, { css } from 'styled-components'

import { fonts } from '../../theme'

export const Content = styled.div`
  width: 100%;
`

export const Resume = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1.2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tundora};
`

export const ResumeTitle = styled.div`
  ${fonts.RobotoExtraLight};
  font-size: 1.4rem;
  letter-spacing: 0.5px;
`

export const ResumeText = styled.div<{ $noUppercase?: boolean }>`
  font-size: 1.4rem;
  ${fonts.GothamBold};
  letter-spacing: 0.5px;
  text-transform: capitalize;
  ${({ $noUppercase }) =>
    $noUppercase &&
    css`
      text-transform: none;
    `}
`
