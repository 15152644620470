import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const samplesCountByUniverse = getQueryService({
  query: gql(`
    query samplesCountByUniverse($exhibitionApiId: ID) {
        samplesCountByUniverse(exhibitionApiId: $exhibitionApiId) {
            ebmsId
            name
            count
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
