import styled from 'styled-components'

import Heading from '../../components/Heading'
import ActionButton from '../../components/ActionButton'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
`

export const CTAListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin: 1rem 0;
`

export const CTA = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`
