import styled, { css } from 'styled-components'
import { Maybe } from 'graphql/jsutils/Maybe'

import Heading from '../../components/Heading'
import FormFieldSwitch from '../../components/form/fields/FormFieldSwitch'
import FormFieldCheckboxGroup from '../../components/form/fields/FormFieldCheckboxGroup'
import FormFieldRadioGroup from '../../components/form/fields/FormFieldRadioGroup'
import FormErrors from '../../components/form/FormErrors'
import FormikForm from '../../components/form/FormikForm'
import SearchBar from '../../components/SearchBar'
import AlphabeticSort from '../../components/AlphabeticSort'
import { breakpoints } from '../../theme'
import ActionButton from '../../components/ActionButton'
import BoxInfo from '../../components/BoxInfo'
import CollapsibleColoredItem from '../../components/CollapsibleColored'
import Icon from '../../components/Icon'
import { PVColors } from '../../types/pv-colors'
import SimpleLoader from '../../components/SimpleLoader'

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`

export const Title = styled(Heading)`
  margin: 0;
`
export const Loader = styled(SimpleLoader)`
  margin-left: 50%;
  margin-right: 50%;
`
export const Search = styled(SearchBar)``

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 1rem;
`
export const StyledAlphabeticSort = styled(AlphabeticSort)``
export const Form = styled(FormikForm)`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const FormFields = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`
export const Collapsibles = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.8rem;
`
export const AllCollectionSwitch = styled(FormFieldSwitch)`
  ${({ theme }) => theme.textStyles.textBook}
  &.is-on {
    ${({ theme }) => theme.textStyles.textBold}
  }
`
export const StyledBoxInfo = styled(BoxInfo)``
export const EditButton = styled(ActionButton)`
  align-self: center;
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
`
export const StyledCheckboxesGroup = styled(FormFieldCheckboxGroup)``
export const StyledRadiosGroup = styled(FormFieldRadioGroup)``
export const StyledCollapsible = styled(CollapsibleColoredItem)`
  border-radius: 0.4rem;
  padding: 1.2rem;
  & ${StyledRadiosGroup} {
    margin-top: 1.2rem;
  }
  & ${StyledRadiosGroup} label {
    padding-right: 0;
  }
`
export const Errors = styled(FormErrors)``

export const Forums = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column;
  gap: 1.2rem;
  margin: 0;
  padding: 0;
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  white-space: normal;
  &::before {
    content: '';
    flex: 0 0 auto;
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: currentColor;
  }
`
export const Forum = styled.li``
// export const StyledCollapsible = styled(Collapsible)``
const itemStyles = css<{ $color?: Maybe<string> | undefined }>`
  // default
  --bg: ${({ theme }) => theme.colors.concrete};
  --bg--hover: ${({ theme }) => theme.colors.grayLight};
  --border-color: ${({ theme }) => theme.colors.concrete};
  --border-color--hover: ${({ theme }) => theme.colors.grayLight};
  --color: ${({ theme }) => theme.colors.tundora};
  // styles
  ${({ theme }) => theme.textStyles.titleH6}
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  min-height: 4.6rem;
  margin: 0;
  overflow: hidden;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  border-color: var(--border-color);
  color: var(--color);
  background-color: var(--bg);
  border: 0.1rem solid var(--border-color);
  transition: all 200ms ease-in;
  background-color: ${({ $color }) => $color || 'var(--bg)'};
  // variants
  /* ${({ theme, $color }) =>
    $color === PVColors.Accessories
      ? css`
          --bg: ${theme.colors.albescentWhite};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.pizza};
          --color: ${theme.colors.pizza};
        `
      : $color === PVColors.Design
      ? css`
          --bg: ${theme.colors.amour};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.fuchsiaPink};
          --color: ${theme.colors.fuchsiaPink};
        `
      : $color === PVColors.Inspiration
      ? css`
          --bg: ${theme.colors.whiteLilac};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.royalPurple};
          --color: ${theme.colors.royalPurple};
        `
      : $color === PVColors.Leather
      ? css`
          --bg: ${theme.colors.janna};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.roseOfSharon};
          --color: ${theme.colors.roseOfSharon};
        `
      : $color === PVColors.Sourcing &&
        css`
          --bg: ${theme.colors.linkWater};
          --bg--hover: var(--bg);
          --border-color: var(--bg);
          --border-color--hover: ${theme.colors.danube};
          --color: ${theme.colors.danube};
        `} */
`
export const Trigger = styled.span`
  ${itemStyles}
  cursor: pointer;
  &:hover {
    border-color: var(--border-color--hover);
    background-color: var(--bg--hover);
  }
`
export const TriggerIcon = styled(Icon)`
  flex: 0 0 auto;
  justify-self: flex-end;
  color: ${({ theme }) => theme.colors.tundora};
  margin-left: auto;
`
export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column;
  gap: 1.2rem;
  margin: 1.2rem 0 0;
  padding-left: 3rem;
`
export const Item = styled.li<{ $color?: PVColors }>`
  ${itemStyles}
`
