import styled from 'styled-components'
import { VictoryLabel } from 'victory'

import { breakpoints } from '../../theme'
import Heading from '../Heading'

export const Label = styled(VictoryLabel)`
  font-size: 1rem;
  font-style: ${({ theme }) => theme.fonts.GothamMedium};
  max-width: 2rem;
`

export const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 2.8rem;
  ${breakpoints.up('tablet')} {
    flex-flow: row nowrap;
  }
  ${breakpoints.up('desktop')} {
    gap: 3.6rem;
  }
`

export const Svg = styled.div`
  flex: 0 0 auto;
  width: 75%;
  max-width: 18rem;
  aspect-ratio: 1/1;
`

export const DataContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    flex-flow: row wrap;
    gap: 1.4rem;
  }
`

export const Data = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`

export const Color = styled.div`
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 100%;
  margin-top: 0.25rem;
`

export const DataContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.titleH6}
`

export const Number = styled.div`
  ${({ theme }) => theme.textStyles.titleH2}
`

export const Subtitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.tundora};
  text-transform: uppercase;
`

export const Section = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  margin: 0;
  margin-bottom: 42px;
`
