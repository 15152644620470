import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const getSamples = getQueryService({
  query: gql(`
    query samples(
      $q: String
      $forumsIds: [ID!]
      $universesEbmsIds: [ID!]
      $pavilionsEbmsIds: [ID!]
      $countries: [String!]
      $exhibitionsApiIds: [ID!]
      $flagItems: [ID!]
      $first: Int!
      $page: Int
    ) {
      samples(
        q: $q
        forumsIds: $forumsIds
        universesEbmsIds: $universesEbmsIds
        pavilionsEbmsIds: $pavilionsEbmsIds
        countries: $countries
        exhibitionsApiIds: $exhibitionsApiIds
        flagItems: $flagItems
        first: $first
        page: $page
      ) {
        paginatorInfo {
          total
          perPage
          lastPage
          currentPage
        }
        data {
          id
          name
          status
          statusUpdatedAt
          marketplaceImported
          marketplaceImportError
          forum{
            name
            color
          }
          subForum{
            name
            color
          }
          applyToCollectionUniverse
          mkpForum
          forumApplyToCollection
          boxApplyToCollection
          subForumApplyToCollection
          flagItems {
            id
            group {
              id
              miraklRef
            }
            name
            cascadingGroups
          }
          photos {
            id
            user {
              id
              email
            }
            url
            path
            order
          }
          updatedAt
          isDeleted
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})

export const getSamplesNumber = getQueryService({
  query: gql(`
    query samplesNumber(
      $q: String
      $forumsIds: [ID!]
      $universesEbmsIds: [ID!]
      $pavilionsEbmsIds: [ID!]
      $countries: [String!]
      $exhibitionsApiIds: [ID!]
      $flagItems: [ID!]
      $withTrashed: Boolean
      $first: Int!
      $page: Int
    ) {
      samples(
        q: $q
        forumsIds: $forumsIds
        universesEbmsIds: $universesEbmsIds
        pavilionsEbmsIds: $pavilionsEbmsIds
        countries: $countries
        exhibitionsApiIds: $exhibitionsApiIds
        flagItems: $flagItems
        withTrashed: $withTrashed
        first: $first
        page: $page
      ) {
        paginatorInfo {
          total
          perPage
          lastPage
          currentPage
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
