import styled, { css } from 'styled-components'

import Icon, { Icons } from '../Icon'

export const Container = styled.div<{ $isOpen?: boolean; $withRightSidebar?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  visibility: hidden;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      visibility: visible;
      ::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.pureBlack};
        opacity: 0.7;
        z-index: -1;
      }
    `};
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    left: ${({ $withRightSidebar }) => ($withRightSidebar ? '32.1rem' : 0)};
  }
`

export const Children = styled.div<{ $removeSpacing?: boolean }>`
  overflow: auto;
  height: 100%;
  padding-top: 5rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  z-index: 10;
  ${({ $removeSpacing }) =>
    $removeSpacing &&
    css`
      padding-top: 0;
    `};
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    background-color: transparent;
  }
`

export const Static = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  z-index: 3;
  padding: 2rem;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    background-color: transparent;
  }
`

export const LogoIcon = styled(Icon).attrs({ icon: Icons.logo, width: 157, height: 18 })`
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    display: none;
  }
`
export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0;
  margin-left: auto;
  padding: 0;
  cursor: pointer;
`

export const CloseIcon = styled(Icon).attrs({ icon: Icons.close, width: 24, height: 24 })``
