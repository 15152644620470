import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const editorialUpdate = getMutationService({
  mutation: gql(`
    mutation editorialUpdate($editorialId: ID!, $input: EditorialUpdateInput!) {
        editorialUpdate(editorialId: $editorialId, input: $input) {
            id
            name
            description
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
