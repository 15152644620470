import { useEffect, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { FormikProps } from 'formik'

import { modalFullScreenMocks } from '../../components/ModalFullScreen/mocks'
import {
  findTagsInDictionaries,
  parseFiltersExhibitorsFromStore,
  parseFiltersSamplesFromStore,
} from '../../components/Filters/utils'
import { actions, selectors } from '../../redux'
import { FiltersExportProps } from '../../components/FiltersExport'
import { FiltersExportFormValues } from '../../components/forms/FiltersExportForm'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'

export const useFiltersExportTemplateProps = ({
  handleFiltersClick,
  openFilters,
  isExponent,
  pavilions,
  universes,
  countries,
  handleExportAllClick,
  initialValues,
}: {
  handleFiltersClick: () => void
  openFilters: boolean
  isExponent: boolean
  pavilions: string[]
  universes: string[]
  countries: string[]
  handleExportAllClick: React.Dispatch<React.SetStateAction<boolean>>
  initialValues: FiltersExportFormValues
}): FiltersExportProps => {
  const dispatch = useDispatch()
  const config = useSelector(selectors.configuration.configurationInfo)
  const filters = useSelector(selectors.samples.sampleFilters)
  const exhibition = useSelector(selectors.exhibitions.exhibitions)
  const filtersParsedSamples = filters && !isExponent && parseFiltersSamplesFromStore(filters)
  const filtersParsedExhibitors =
    config && isExponent && parseFiltersExhibitorsFromStore(config?.dictionaries)
  const [exportAll, setExportAll] = useState<boolean>(true)
  const [tags, setTags] = useState<Array<string>>([])
  const [newtags, setNewtags] = useState<string[]>([])
  const [exhibitorsNbr, setExhibitorsNbr] = useState<string>('')
  const [filtersUniverses, setFiltersUniverses] = useState<string[]>(universes)
  const [filtersPavilions, setFiltersPavilions] = useState<string[]>(pavilions)
  const [filtersCountries, setFiltersCountries] = useState<string[]>(countries)
  const [initialValuesFilters, setInitialValuesFilters] = useState<FiltersExportFormValues>({
    UNIVERSES: [],
    PAVILIONS: [],
    COUNTRIES: [],
  })

  const refFilters = useRef<FormikProps<FiltersExportFormValues>>(null)

  useEffect(() => {
    if (exhibition) {
      let filteredExhibitors = exhibition.exhibitors
      if (filtersUniverses.length !== 0) {
        filteredExhibitors = filteredExhibitors.filter((item) =>
          filtersUniverses.includes(item.universe.name)
        )
      }
      if (filtersPavilions.length !== 0) {
        filteredExhibitors = filteredExhibitors.filter((item) =>
          filtersPavilions.includes(item.pavilion.name)
        )
      }
      if (filtersCountries.length !== 0) {
        filteredExhibitors = filteredExhibitors.filter((item) =>
          filtersCountries.includes(item.country as string)
        )
      }
      setExhibitorsNbr(filteredExhibitors.length.toString())
    }
    if (
      filtersCountries.length === 0 &&
      filtersPavilions.length === 0 &&
      filtersUniverses.length === 0
    )
      setExportAll(true)
    else setExportAll(false)
  }, [exhibition, filtersCountries, filtersPavilions, filtersUniverses])

  useEffect(() => {
    if (config)
      dispatch(
        actions.samples.sampleCountByUniverseRequest({
          exhibitionApiId: config?.exhibitionActiveApiId.toString(),
        })
      )
  }, [config, config?.exhibitionActiveApiId, dispatch])

  useEffect(() => {
    setInitialValuesFilters(initialValues)
  }, [initialValues])

  useEffect(() => {
    refFilters.current &&
      refFilters.current.setValues({
        UNIVERSES: universes,
        PAVILIONS: pavilions,
        COUNTRIES: countries,
      })
  }, [countries, pavilions, universes])

  useEffect(() => {
    const combinedTags = [...newtags, ...filtersUniverses, ...filtersPavilions, ...filtersCountries]
    const uniqueTags = Array.from(new Set(combinedTags))
    setTags(uniqueTags)
  }, [filtersCountries, filtersPavilions, filtersUniverses, newtags])

  useEffect(() => {
    if (refFilters.current) {
      setFiltersUniverses(refFilters.current?.values.UNIVERSES)
      setFiltersPavilions(refFilters.current?.values.PAVILIONS)
      setFiltersCountries(refFilters.current?.values.COUNTRIES)
    }
  }, [initialValuesFilters])

  return useMemo(
    () => ({
      ...modalFullScreenMocks.basic,
      backButtonProps: {
        text: 'Fermer',
        open: !openFilters,
        onClick: () => {
          handleFiltersClick()
        },
      },
      headerTitle: 'Export de statistiques',
      title: 'FILTRER MA LISTE',
      tags: tags.map((tag) => ({ text: tag })),
      form: {
        formikForm: {
          innerRef: refFilters,
          initialValues: initialValues,
          onContextUpdate: (context) => {
            setInitialValuesFilters(context.values)
            const newTags = Object.values(context.values).flat()
            if (!isEqual(newTags, newtags)) {
              setNewtags(newTags)
            }
          },
          onSubmit: () => undefined,
          validateOnMount: true,
        },
        fields: isExponent
          ? filtersParsedExhibitors && filtersParsedExhibitors
          : filtersParsedSamples && filtersParsedSamples,
      },
      exhibitorsNbr: exhibitorsNbr,
      exportAll: exportAll,
      exportTitle: 'Exporter tous les exposants',
      setExportAll: () => handleExportAllClick(!exportAll),
      resetButton: {
        text: 'Réinitialiser les filtres',
        onClick: () => handleExportAllClick(true),
        variantScheme: ActionButtonScheme.Tertiary,
        variantSize: ActionButtonSize.Small,
        rightIconProps: { icon: Icons.trash, height: 18, width: 18 },
      },
      exportButton: {
        text: 'Exporter les statistiques',
        rightIconProps: {
          icon: Icons.export,
          width: 18,
          height: 18,
        },
        onClick: () => {
          const filterParsedCallCountries = findTagsInDictionaries(
            filtersCountries,
            config?.dictionaries
          )
          const filterParsedCallUniverse = findTagsInDictionaries(
            filtersUniverses,
            config?.dictionaries
          )
          const filterParsedCallPavilions = findTagsInDictionaries(
            filtersPavilions,
            config?.dictionaries
          )
          const countries =
            filterParsedCallCountries.find((item) => item.type === 'COUNTRIES')?.filters || []
          const universesEbmsIds =
            filterParsedCallUniverse.find((item) => item.type === 'UNIVERSES')?.filters || []
          const pavilionsEbmsIds =
            filterParsedCallPavilions.find((item) => item.type === 'PAVILIONS')?.filters || []

          dispatch(
            actions.exhibitors.exportExhibitorsRequest({
              filters: {
                universesEbmsIds: universesEbmsIds,
                pavilionsEbmsIds: pavilionsEbmsIds,
                countries: countries,
              },
            })
          )
        },
      },
      dataType: `Exposant${Number(exhibitorsNbr) > 1 ? 's' : ''}`,
    }),
    [
      openFilters,
      tags,
      initialValues,
      isExponent,
      filtersParsedExhibitors,
      filtersParsedSamples,
      exhibitorsNbr,
      exportAll,
      handleFiltersClick,
      newtags,
      handleExportAllClick,
      filtersCountries,
      config?.dictionaries,
      filtersUniverses,
      filtersPavilions,
      dispatch,
    ]
  )
}
