import React, { FC } from 'react'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { TabsScrollableProps } from '../../components/TabsScrollable'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import NoteTag from '../../components/NoteTag'
import Modal, { ModalProps } from '../../components/Modal'
import CollectionNoteForm, {
  CollectionNoteFormProps,
} from '../../components/forms/CollectionNoteForm'
import NoteModal, { NoteModaleProps } from '../../components/NoteModale'
import { LoungeCardProps } from '../../components/LoungeCard'
import { ModalVariant } from '../../components/Modal/types'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type LayoutExponentSheetProps = LayoutWithBottomActionsProps & {
  title?: string | null | undefined
  country?: string | null | undefined
  infos?: string[]
  tabs?: TabsScrollableProps
  newTag?: boolean
  returnTag?: boolean
  deleteModal?: ModalDeleteProps
  noteModal?: ModalProps
  editModal?: ModalProps
  editNoteForm?: CollectionNoteFormProps
  notes?: NoteModaleProps
  children?: React.ReactNode
  isArchived?: LoungeCardProps
  returnCurrentExhibition?: ActionButtonProps
}

const LayoutExponentSheet: FC<LayoutExponentSheetProps> = ({
  title,
  country,
  infos,
  tabs,
  newTag,
  returnTag,
  deleteModal,
  noteModal,
  editModal,
  editNoteForm,
  notes,
  children,
  isArchived,
  returnCurrentExhibition,
  ...layoutProps
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={
        <>
          {noteModal && (
            <Modal {...noteModal}>
              <NoteModal {...notes} />
            </Modal>
          )}
          {editModal && editNoteForm && (
            <SC.NoteModal variant={ModalVariant.Default} {...editModal}>
              <CollectionNoteForm {...editNoteForm} />
            </SC.NoteModal>
          )}
          {deleteModal && <ModalDelete {...deleteModal} />}
        </>
      }
    >
      {isArchived && <SC.StyledLoungeCard {...isArchived} />}

      <SC.TopContent maxWidth="default">
        <SC.FlexContainer>
          <div>
            {title && <SC.Title tag={'h2'} text={title} />}
            {country && <SC.Country>{country}</SC.Country>}
            {infos && infos.length > 0 && (
              <SC.Infos>
                {infos.map((info, index) => (
                  <li key={index}>{info}</li>
                ))}
              </SC.Infos>
            )}
          </div>
          {returnCurrentExhibition && <SC.ReturnActionButton {...returnCurrentExhibition} />}
        </SC.FlexContainer>
        {(newTag || returnTag) && (
          <SC.NoteTagContainer>
            {newTag && <NoteTag text="Nouvel exposant" variant="new" />}
            {returnTag && <NoteTag text="Échantillons à retourner" variant="return" />}
          </SC.NoteTagContainer>
        )}
      </SC.TopContent>
      {tabs ? <SC.StyledTabs {...tabs} /> : <SC.Separator />}
      <SC.BottomContent maxWidth="default">{children}</SC.BottomContent>
    </LayoutWithBottomActions>
  )
}

export default LayoutExponentSheet
