import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { LayoutNotConnectedMocks } from '../../layouts/LayoutNotConnected/mocks'

import { LoginChoiceTemplateProps } from './index'

const basic: LoginChoiceTemplateProps = {
  title: 'Connexion',
  layout: LayoutNotConnectedMocks.basic,
  setChoicePv: () => console.log('Collab PV'),
  setChoiceExt: () => console.log('Collab exté'),
  pvButton: {
    text: 'Collaborateurs PV',
    variantScheme: ActionButtonScheme.Secondary,
    variantSize: ActionButtonSize.Large,
    rightIconProps: {
      icon: Icons.arrowRight,
      width: 20,
    },
  },
  extButton: {
    text: 'Collaborateurs externes',
    variantScheme: ActionButtonScheme.Secondary,
    variantSize: ActionButtonSize.Large,
    rightIconProps: {
      icon: Icons.arrowRight,
      width: 20,
    },
  },
}

export const loginChoiceTemplateMocks = {
  basic,
}
