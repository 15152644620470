import React, { FC, ImgHTMLAttributes } from 'react'

import * as SC from './styled'

export type LabelCardProps = {
  className?: string
  title?: string
  category?: string
  pavillion?: string
  brand?: string
  country?: string
  image: ImgHTMLAttributes<HTMLImageElement>
  onClick?: () => void
}

const LabelCard: FC<LabelCardProps> = ({
  className,
  title,
  category,
  pavillion,
  brand,
  country,
  image,
  onClick,
}) => {
  return (
    <SC.Card className={className} onClick={onClick}>
      <SC.Image {...image} />
      <SC.TextContainer>
        <SC.Title>{title}</SC.Title>
        <SC.InfosContainer>
          <SC.StyledParagraph text={brand} variant={'titleH4'} removeMargins />
          {country && (
            <SC.StyledParagraph text={country} variant={'titleH6'} color="gray" removeMargins />
          )}
        </SC.InfosContainer>
        {pavillion && <SC.Category>{pavillion}</SC.Category>}
        {category && <SC.Category>{category}</SC.Category>}
      </SC.TextContainer>
    </SC.Card>
  )
}

export default LabelCard
