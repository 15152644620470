import React from 'react'
import { RouteProps } from 'react-router-dom'

import LoungeDatesTemplate from '../../../templates/LoungeDates'
import { useLoungeDatesSamplesTemplateProps } from '../../../templates/LoungeDates/usePropsSamples'

const LoungesDatesSamples: React.FC<RouteProps> = () => {
  const templateProps = useLoungeDatesSamplesTemplateProps()

  return <LoungeDatesTemplate {...templateProps} />
}

export default LoungesDatesSamples
