import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const editorialCreate = getMutationService({
  mutation: gql(`
    mutation editorialCreate($exhibitionApiId: ID, $input: EditorialCreateInput!) {
        editorialCreate(exhibitionApiId: $exhibitionApiId, input: $input) {
            id
            name
            description
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
