import { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useNavigate } from 'react-router'

import { actions, selectors } from '../../redux'
import { SeasonFormValues } from '../../components/forms/SeasonSelectForm'
import {
  VariantRadioFont,
  VariantRadioSize,
} from '../../components/form/fields/FormFieldRadioGroup/types'
import { VariantCollapsibleColoredFont } from '../../components/CollapsibleColored/types'
import { useLayoutLoungeConfigTemplateProps } from '../../layouts/LayoutLoungeConfig/useProps'
import { Exhibition } from '../../graphql/generated/graphql'
import { router, routesPath } from '../../router'

import { LoungeConfigTemplateProps } from '.'

export const useLoungeInfosTemplateProps = (): LoungeConfigTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef<FormikProps<SeasonFormValues>>(null)
  const [isValid, setIsValid] = useState<boolean>(false)

  const layoutLoungeProps = useLayoutLoungeConfigTemplateProps()
  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)
  const lounges = useSelector(selectors.exhibitions.exhibitionsSearch)

  const isIdAlreadyPresent = useCallback(
    (idToCheck: string): boolean => {
      if (lounges) {
        //@ts-ignore
        if (loungeConfig && loungeConfig.edit && loungeConfig?.apiId) {
          return lounges.some(
            (lounge) => lounge.apiId === idToCheck && loungeConfig.apiId !== idToCheck
          )
        } else {
          return lounges.some((lounge) => lounge.apiId === idToCheck)
        }
      } else {
        return false
      }
    },
    [lounges, loungeConfig]
  )
  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutLoungeProps,
        bottomActions: {
          ...layoutLoungeProps.bottomActions,
          next: {
            ...layoutLoungeProps.bottomActions?.next,
            disabled: !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
          },
          back: {
            ...layoutLoungeProps.bottomActions?.back,
            onClick: () => {
              const link = router(routesPath.lounges, {
                redirect: 'edition',
              })
              navigate(link)
            },
          },
        },
      },
      title: 'Nom du salon',
      seasonSelectForm: {
        formikForm: {
          innerRef: ref,
          initialValues: {
            radio: loungeConfig && loungeConfig.name !== '' ? loungeConfig.name : '',
            year: loungeConfig && loungeConfig.seasonYear !== '' ? loungeConfig.seasonYear : '',
            id: loungeConfig && loungeConfig.apiId !== '' ? loungeConfig.apiId : '',
            shootingFolder:
              loungeConfig && loungeConfig.shootingFolder !== '' ? loungeConfig.shootingFolder : '',
            season: loungeConfig && loungeConfig.seasonCode !== '' ? loungeConfig.seasonCode : 'SS',
          } as SeasonFormValues,
          validationSchema: Yup.object().shape({
            year: Yup.string()
              .required("L'année est requise")
              .matches(/^(?:\d{2}|\d{4})$/, {
                message: "L'année doit avoir 2 ou 4 caractères",
                excludeEmptyString: true,
              }),
            shootingFolder: Yup.string().required('Le répertoire SFTP Shooting est requis'),
            id: Yup.string()
              .required("L'Exhibition ID est requis")
              .matches(/^\d+$/, {
                message: "L'Exhibition ID doit contenir uniquement des chiffres",
                excludeEmptyString: true,
              })
              .test('uniqueId', 'Cet ID est déjà utilisé', (value) => {
                if (!value) return true
                return !isIdAlreadyPresent(value)
              }),
            season: Yup.string().required(),
            radio: Yup.string().required(),
          }),
          onContextUpdate: (context) => {
            setIsValid(context.isValid)
          },
          onSubmit: (values) => {
            const updatedLoungeConfig = {
              ...loungeConfig,
              seasonCode: values.season,
              seasonYear: values.year,
              apiId: values.id,
              shootingFolder: values.shootingFolder,
              name: values.radio,
            }
            dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
            const link = router(routesPath.loungesDates)
            navigate(link)
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        selectOptions: [
          { value: 'SS', label: 'SS' },
          { value: 'AW', label: 'AW' },
        ],
        fields: {
          year: {
            name: 'year',
            label: 'Année',
            required: true,
          },
          id: {
            name: 'id',
            label: 'Exhibition ID',
            required: true,
          },
          shootingFolder: {
            name: 'shootingFolder',
            label: 'Répertoire SFTP Shooting',
            required: true,
          },
        },
        radiosGroups: {
          collapsible: {
            title: 'Nom du salon',
            variantFont: VariantCollapsibleColoredFont.SmallBold,
            dotted: false,
          },
          radios: {
            name: 'radio',
            required: true,
            insideCollapsible: false,
            optionsFont: VariantRadioFont.SmallBold,
            optionsSize: VariantRadioSize.big,
            backgroundColor: '#F2F2F2',
            options: [
              {
                label: 'PREMIERE VISION PARIS',
                value: 'Première Vision Paris',
                backgroundColor: '#F2F2F2',
              },
              {
                label: 'DENIM',
                value: 'Denim',
                backgroundColor: '#F2F2F2',
              },
            ],
          },
        },
      },
    }
  }, [dispatch, isIdAlreadyPresent, isValid, layoutLoungeProps, loungeConfig, navigate])
}
