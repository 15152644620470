import React, { FC } from 'react'
import cx from 'classnames'

import { BottomActionsProps } from '../../components/BottomActions'

import * as SC from './styled'

export type LayoutNotConnectedProps = {
  className?: string
  children?: React.ReactNode
  bottomActions?: BottomActionsProps
}

const LayoutNotConnected: FC<LayoutNotConnectedProps> = ({
  children,
  className,
  bottomActions,
}) => {
  return (
    <SC.Container className={cx(className)}>
      <SC.Header>
        <SC.Logo />
      </SC.Header>
      <SC.MainContent> {children}</SC.MainContent>
      <SC.Actions {...bottomActions} />
    </SC.Container>
  )
}

export default LayoutNotConnected
