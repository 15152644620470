import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router'

import { actions, selectors } from '../../redux'
import { Collection, Exhibitor } from '../../graphql/generated/graphql'
import { ModalVariant } from '../../components/Modal/types'
import { router, routesPath } from '../../router'

import { LayoutExponentSheetProps } from '.'

export const useLayoutExponentSheetTemplateProps = (
  exhibitor: Exhibitor | null,
  collection: Collection | null
): LayoutExponentSheetProps => {
  const dispatch = useDispatch()
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { apiId } = useParams<{ apiId: string }>()
  const { exposantId } = useParams<{ exposantId: string }>()
  const config = useSelector(selectors.configuration.configurationInfo)
  const collectionUpdate = useSelector(selectors.exhibitors.collectionUpdate)
  const collectionExhibitionsPending = useSelector(
    selectors.exhibitors.collectionExhibitionsPending
  )
  const sampleCreate = useSelector(selectors.samples.sampleCreate)
  const sampleUpdate = useSelector(selectors.samples.sampleUpdate)
  const internalNoteCreate = useSelector(selectors.exhibitors.internalNoteCreate)
  const internalNoteDelete = useSelector(selectors.exhibitors.internalNoteDelete)
  const internalNoteUpdate = useSelector(selectors.exhibitors.internalNoteUpdate)
  const activeApiId = config?.exhibitionActiveApiId.toString()

  useEffect(() => {
    if (
      exposantId &&
      apiId &&
      (!exhibitor?.contacts ||
        exhibitor?.ebmsId !== exposantId ||
        collection?.exhibition.apiId !== apiId) &&
      !isRefresh
    ) {
      dispatch(
        actions.exhibitors.exhibitorsCollectionServiceRequest({
          exhibitorEbmsId: exposantId,
          exhibitionApiId: apiId,
        })
      )
    } else if (exposantId && apiId && isRefresh) {
      setIsRefresh(false)
      dispatch(
        actions.exhibitors.exhibitorsCollectionServiceRequest({
          exhibitorEbmsId: exposantId,
          exhibitionApiId: activeApiId,
        })
      )
      const link = router(routesPath.exponentSheet, {
        exposantId: exposantId,
        apiId: activeApiId,
      })
      navigate(link)
    }
    dispatch(actions.samples.setSample(undefined))
  }, [
    dispatch,
    exposantId,
    collectionUpdate.success,
    apiId,
    internalNoteCreate.success,
    internalNoteDelete.success,
    internalNoteUpdate.success,
    exhibitor,
    isRefresh,
    activeApiId,
    navigate,
    collection?.exhibition.apiId,
  ])

  useEffect(() => {
    exposantId &&
      (sampleCreate.success || sampleUpdate.success) &&
      dispatch(
        actions.exhibitors.exhibitorsCollectionServiceRequest({
          exhibitorEbmsId: exposantId,
          exhibitionApiId: apiId,
        })
      )
    dispatch(actions.samples.setSample(undefined))
  }, [apiId, dispatch, exposantId, sampleCreate.success, sampleUpdate.success])

  return useMemo(() => {
    return {
      bottomActions: {
        note:
          collection?.internalNotes.length !== 0
            ? {
                rounded: true,
                number: collection?.internalNotes
                  ? collection?.internalNotes.length.toString()
                  : '0',
                onClick: () => {
                  setIsNoteOpen(true)
                },
              }
            : undefined,
      },
      notes: {
        title: "Note interne sur l'exposant",
        notes:
          collection && collection.internalNotes && collection.internalNotes.length !== 0
            ? collection.internalNotes.map((note) => ({
                text: note.body,
                author: {
                  fullName:
                    collection?.internalNotes.length !== 0
                      ? `par ${note.user.lastName} ${note.user.firstName}`
                      : '',
                  date:
                    collection?.internalNotes.length !== 0
                      ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                      : '',
                },
              }))
            : [],
        tags: {
          newTag: exhibitor?.isNew,
          returnTag: collection?.samplesToReturn !== 0,
        },
        onClose: () => setIsNoteOpen(false),
      },
      noteModal: {
        onClose: () => setIsNoteOpen(false),
        isOpen: isNoteOpen,
        variant: ModalVariant.Small,
      },
      isArchived: collection?.isArchived
        ? {
            title: collection.exhibition.name,
            text: `Exhibition ID ${collection.exhibition.apiId}`,
            season: `${collection.exhibition.seasonCode}${collection.exhibition.seasonYear}`,
            active: true,
            onClick: () => {
              const link = router(routesPath.exhibitionSheetInfos, {
                exhibitionId: collection.exhibition.apiId as string,
              })
              navigate(link)
            },
          }
        : undefined,
      returnCurrentExhibition: collection?.isArchived
        ? {
            text: 'Revenir au salon en cours',
            isPending: collectionExhibitionsPending,
            disabled: collectionExhibitionsPending,
            onClick: () => {
              setIsRefresh(true)
            },
            leftIconProps: { icon: 'returnThin' },
            rounded: true,
          }
        : undefined,
      title: exhibitor ? exhibitor.name : '',
      country: exhibitor ? exhibitor.country : '',
      infos: exhibitor ? [exhibitor.universe.name, exhibitor.pavilion.name] : [],
      newTag: exhibitor?.isNew,
      returnTag:
        collection && collection.samplesToReturn && collection.samplesToReturn > 0 ? true : false,
    }
  }, [collection, exhibitor, isNoteOpen, navigate, collectionExhibitionsPending])
}
