import React, { FC } from 'react'

import LayoutSampleSheet, { LayoutSampleSheetProps } from '../../layouts/LayoutSampleSheet'
import { InfosDisplayProps } from '../../components/InfosDisplay'
import SimpleSlider, { SimpleSliderProps } from '../../components/SimpleSlider'
import BoxInfo, { BoxInfoProps } from '../../components/BoxInfo'

import * as SC from './styled'

export type SampleSheetShootingTemplateProps = {
  layoutProps?: LayoutSampleSheetProps
  pending?: boolean
  sliderProps?: SimpleSliderProps
  shootingInfos?: InfosDisplayProps
  boxTitle?: string
  boxInfo?: BoxInfoProps
}

const SampleSheetShootingTemplate: FC<SampleSheetShootingTemplateProps> = ({
  layoutProps,
  pending,
  shootingInfos,
  sliderProps,
  boxTitle,
  boxInfo,
}) => {
  return (
    <LayoutSampleSheet {...layoutProps}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          <SC.Container>
            {sliderProps && (
              <SC.SliderContainer>
                {sliderProps && <SimpleSlider {...sliderProps} />}
              </SC.SliderContainer>
            )}
            <SC.InfosContainer>
              {shootingInfos && <SC.Infos {...shootingInfos} />}
              {boxTitle && boxInfo && boxInfo.text ? (
                <>
                  <SC.BoxTitle>{boxTitle}</SC.BoxTitle>
                  <BoxInfo {...boxInfo} />
                </>
              ) : (
                <SC.BoxTitle>Pas de caisse de rangement</SC.BoxTitle>
              )}
            </SC.InfosContainer>
          </SC.Container>
        </>
      )}
    </LayoutSampleSheet>
  )
}

export default SampleSheetShootingTemplate
