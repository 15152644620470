import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectors } from '../../../redux'
import ExponentSheetInfosTemplate from '../../../templates/ExponentSheetInfos'
import { useExponentSheetInfosTemplateProps } from '../../../templates/ExponentSheetInfos/useProps'

const ExponentSheetInfos: React.FC<RouteProps> = () => {
  const collection = useSelector(selectors.exhibitors.collection)
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)

  const templateProps = useExponentSheetInfosTemplateProps(exhibitor, collection)

  return (
    <>
      <ExponentSheetInfosTemplate {...templateProps} />
    </>
  )
}

export default ExponentSheetInfos
