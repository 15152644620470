import React, { FC } from 'react'

import LayoutSampleData, { LayoutSampleDataProps } from '../../layouts/LayoutSampleData'
import { ConfigSamplesProps } from '../../components/ConfigSamples'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'

import * as SC from './styled'

export type SampleSheetsEditTemplateProps = {
  layoutProps?: LayoutSampleDataProps
  title?: string
  configSamples?: ConfigSamplesProps
  deleteModal?: ModalDeleteProps
  toastModal?: ModalToastProps
}

const SampleSheetsEditTemplate: FC<SampleSheetsEditTemplateProps> = ({
  layoutProps,
  title,
  configSamples,
  deleteModal,
  toastModal,
}) => {
  return (
    <LayoutSampleData
      {...layoutProps}
      modals={
        <>
          {deleteModal && <ModalDelete {...deleteModal} />}
          {toastModal && <ModalToast {...toastModal} />}
        </>
      }
    >
      <SC.Content>
        {title && <SC.Title tag="h2" text={title} />}
        {configSamples && <SC.StyledConfigSamples {...configSamples} />}
      </SC.Content>
    </LayoutSampleData>
  )
}

export default SampleSheetsEditTemplate
