import React, { useEffect, useState } from 'react'
import { RouteProps, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'

import ExponentSheetParcelsTemplate from '../../../templates/ExponentSheetParcels'
import { useExponentSheetParcelsTemplateProps } from '../../../templates/ExponentSheetParcels/useProps'
import { actions, selectors } from '../../../redux'
import ModalToast from '../../../components/ModalToast'
import { Icons } from '../../../components/Icon'
import { router, routesPath } from '../../../router'
import ModalDelete from '../../../components/ModalDelete'
import { modalDeleteMocks } from '../../../components/ModalDelete/mocks'
import { Parcel } from '../../../graphql/generated/graphql'

const ExponentBoxes: React.FC<RouteProps> = () => {
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false)
  const [parcel, setParcel] = useState<Parcel | undefined>(undefined)

  const collectionParcelCreate = useSelector(selectors.exhibitors.collectionParcelCreate)
  const collectionParcelUpdate = useSelector(selectors.exhibitors.collectionParcelUpdate)
  const collectionParcelDelete = useSelector(selectors.exhibitors.collectionParcelDelete)
  const templateProps = useExponentSheetParcelsTemplateProps(
    exhibitor,
    setIsModalDeleteVisible,
    parcel,
    setParcel
  )
  const { exposantId } = useParams<{ exposantId: string }>()
  const { apiId } = useParams<{ apiId: string }>()

  useEffect(() => {
    if (collectionParcelCreate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitors.resetCreateParcel())
      }, 2000)
    }
    if (collectionParcelUpdate.success) {
      setIsModalVisible(true)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitors.resetUpdateParcel())
      }, 2000)
    }
    if (collectionParcelDelete.success) {
      setIsModalVisible(true)
      setIsModalDeleteVisible(false)
      setTimeout(() => {
        setIsModalVisible(false)
        dispatch(actions.exhibitors.resetDeleteParcel())
      }, 2000)
    }
    if (!exhibitor) {
      const link = router(routesPath.exponentSheet, {
        exposantId,
        apiId: apiId,
      })
      navigate(link)
    }
  }, [
    apiId,
    collectionParcelCreate,
    collectionParcelCreate.success,
    collectionParcelDelete.success,
    collectionParcelUpdate.success,
    dispatch,
    exhibitor,
    exposantId,
    navigate,
  ])

  return exhibitor ? (
    <>
      {isModalVisible && (
        <ModalToast
          text={
            collectionParcelCreate.success
              ? i18next.t('exponent.parcels.collectionParcelCreate.success', {
                  exhibitorName: exhibitor?.name,
                })
              : collectionParcelUpdate.success
              ? i18next.t('exponent.parcels.collectionParcelUpdate.success', {
                  exhibitorName: exhibitor?.name,
                })
              : collectionParcelDelete.success
              ? i18next.t('exponent.parcels.collectionParcelDelete.success', {
                  exhibitorName: exhibitor?.name,
                })
              : ''
          }
          isOpen={isModalVisible}
          icon={{ icon: Icons.check, height: 20, width: 20 }}
        />
      )}
      {isModalDeleteVisible && (
        <ModalDelete
          {...modalDeleteMocks.parcel}
          cancelButton={{
            ...modalDeleteMocks.parcel.cancelButton,
            onClick: () => setIsModalDeleteVisible(false),
          }}
          deleteButton={{
            ...modalDeleteMocks.parcel.deleteButton,
            onClick: () => {
              parcel &&
                dispatch(actions.exhibitors.collectionParcelDeleteRequest({ parcelId: parcel?.id }))
            },
          }}
        />
      )}
      <ExponentSheetParcelsTemplate {...templateProps} />
    </>
  ) : null
}

export default ExponentBoxes
