import styled, { css } from 'styled-components'

// import { mq, sizes } from '../../theme'

const transform = 'translate(-50%, -50%)'

export const Burger = styled.button`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
`

export const Line = styled.span<{
  $first?: boolean
  $last?: boolean
  $isOpen: boolean
}>`
  display: inline-block;
  width: 1.8rem;
  height: 1.5px;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.colors.mineShaft};

  // Transitioning
  transition: all 0.4s ease;
  transition-delay: transform 0.4s;

  // Positioning
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${transform};

  ${({ $first, $last, $isOpen }) =>
    !$first &&
    !$last &&
    $isOpen &&
    css`
      opacity: 0;
    `};

  ${({ $first, $isOpen }) =>
    $first &&
    css`
      top: ${$isOpen ? '50%' : 'calc(50% - 6px)'};
      transform: ${$isOpen ? `${transform} rotate(45deg)` : transform};
    `};

  ${({ $last, $isOpen }) =>
    $last &&
    css`
      top: ${$isOpen ? '50%' : 'calc(50% + 6px)'};
      transform: ${$isOpen ? `${transform} rotate(-45deg)` : transform};
    `};
`
