import React, { FC } from 'react'

import { FormFieldInputProps } from '../form/fields/FormFieldInput'

import * as SC from './styled'

export type SearchBarProps = FormFieldInputProps & {
  className?: string
  onSearch?: () => void
}

const SearchBar: FC<SearchBarProps> = ({ className, ...input }) => {
  return (
    <SC.SearchBar className={className}>
      <SC.Input
        {...input}
        rightAdornment={<SC.Search />}
        placeholder="Recherche"
        hasFloatingLabel={false}
      />
    </SC.SearchBar>
  )
}

export default SearchBar
