import { ModalFullScreenProps } from './index'

const basic: ModalFullScreenProps = {
  isOpen: true,
  onClose: () => console.log('closing'),
  removeChildrenSpacing: true,
}

export const modalFullScreenMocks = {
  basic,
}
