import React, { useEffect, useState } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useMultipleEditionEditoTemplateProps } from '../../../templates/EditoAdd/usePropsMultipleEdition'
import { actions, selectors } from '../../../redux'
import EditoAdd from '../../../templates/EditoAdd'
import { Icons } from '../../../components/Icon'
import { router, routesPath } from '../../../router'

const MultipleEditionEdito: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const configuration = useSelector(selectors.configuration.configurationInfo)

  const [search, setSearch] = useState<string>('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  useEffect(() => {
    dispatch(
      actions.editos.editorialsSearchRequest({
        exhibitionApiId: configuration?.exhibitionActiveApiId.toString() as string,
        q: search,
        first: 1000,
        page: 1,
      })
    )
  }, [configuration?.exhibitionActiveApiId, dispatch, search])

  const templateProps = useMultipleEditionEditoTemplateProps(search, handleSearchChange)

  return (
    <EditoAdd
      {...templateProps}
      layoutProps={{
        ...templateProps.layoutProps,
        bottomActions: {
          ...templateProps.layoutProps?.bottomActions,
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              const link = router(routesPath.multipleEdition)
              navigate(link)
            },
          },
        },
      }}
    />
  )
}

export default MultipleEditionEdito
