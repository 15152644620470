import React, { FC } from 'react'

import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import { FormikFormProps } from '../../form/FormikForm'
import FormFieldInputNumber from '../../form/fields/FormFieldInputNumber'
import { CollapsibleColoredItemProps } from '../../CollapsibleColored'
import FormikField from '../../form/FormikField'
import { FormFieldRadioGroupProps } from '../../form/fields/FormFieldRadioGroup'
import FormFieldSelect from '../../form/fields/FormFieldSelect'

import * as SC from './styled'

export type SeasonFormValues = {
  year: string
  id: string
  shootingFolder: string
  radio: string
  season: string
}

export type SelectOption = {
  value: string
  label: string
}

export type SeasonSelectFormProps = {
  selectOptions: SelectOption[]
  formikForm: FormikFormProps<SeasonFormValues>
  radiosGroups?: {
    radios: FormFieldRadioGroupProps
    collapsible: CollapsibleColoredItemProps
  }
  fields: {
    year: FormFieldInputProps
    id: FormFieldInputProps
    shootingFolder: FormFieldInputProps
  }
}

const SeasonSelectForm: FC<SeasonSelectFormProps> = ({
  selectOptions,
  fields,
  formikForm,
  radiosGroups,
}) => {
  return (
    <SC.Form {...formikForm}>
      {radiosGroups && (
        <SC.Collapsibles>
          <SC.StyledCollapsible
            key={`multipleRadiosGroups`}
            title={radiosGroups.collapsible.title}
            variantFont={radiosGroups.collapsible.variantFont}
            backgroundColor={radiosGroups.collapsible.backgroundColor}
            color={radiosGroups.collapsible.color}
            open={true}
            {...(radiosGroups.collapsible.onClick && {
              onClick: radiosGroups.collapsible.onClick,
            })}
            variant={'bursted'}
          >
            <FormikField Component={SC.StyledRadiosGroup} {...radiosGroups.radios} />
          </SC.StyledCollapsible>
        </SC.Collapsibles>
      )}
      <SC.Fields>
        <SC.FieldSelect name="season" Component={FormFieldSelect} options={selectOptions} />
        <SC.YearContainer>
          <SC.TextFieldYear
            {...fields.year}
            type="text"
            hasFloatingLabel={true}
            Component={FormFieldInputNumber}
          />
        </SC.YearContainer>
      </SC.Fields>
      <SC.TextField {...fields.id} type="text" hasFloatingLabel={true} Component={SC.FieldText} />
      <SC.TextField
        {...fields.shootingFolder}
        type="text"
        hasFloatingLabel={true}
        Component={SC.FieldText}
      />
    </SC.Form>
  )
}

export default SeasonSelectForm
