import React, { FC } from 'react'

import { Icons } from '../../components/Icon'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { ActionButtonScheme, ActionButtonSize } from '../../components/ActionButton/types'
import { ActionButtonProps } from '../../components/ActionButton'
import FormikField from '../../components/form/FormikField'
import { FormikFormProps } from '../../components/form/FormikForm'
import { FormFieldSwitchProps } from '../../components/form/fields/FormFieldSwitch'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'

import * as SC from './styled'

export type SampleDataLeatherFormValues = {
  allCollection?: boolean
}

export type SampleDataLeatherTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  title?: string
  text?: string
  subtitle?: string
  buttons?: ActionButtonProps[]
  formikForm: FormikFormProps<SampleDataLeatherFormValues>
  allCollection: FormFieldSwitchProps
  deleteModal?: ModalDeleteProps
}

const SampleDataLeatherTemplate: FC<SampleDataLeatherTemplateProps> = ({
  layoutProps,
  title,
  subtitle,
  text,
  buttons,
  formikForm,
  allCollection,
  deleteModal,
}) => {
  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={<>{deleteModal && <ModalDelete {...deleteModal} />}</>}
    >
      <SC.StyledContentWrapper maxWidth={'default'}>
        {title && <SC.Title tag={'h2'} text={title} />}
        {subtitle && <SC.Subtitle tag={'h5'} text={subtitle} />}
        {text && <SC.Text></SC.Text>}
        <SC.Form {...formikForm}>
          {allCollection && (
            <FormikField
              Component={SC.AllCollectionSwitch}
              {...allCollection}
              textValue={allCollection.value ? 'true' : 'false'}
            />
          )}
        </SC.Form>
        {buttons && buttons.length > 0 && (
          <SC.List>
            {buttons.map((button, index) => (
              <SC.StyledActionButton
                key={`SampleSheets-${index}`}
                rightIconProps={{
                  icon: Icons.arrowRight,
                  width: 20,
                }}
                variantScheme={ActionButtonScheme.Secondary}
                variantSize={ActionButtonSize.Large}
                {...button}
              />
            ))}
          </SC.List>
        )}
      </SC.StyledContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default SampleDataLeatherTemplate
