import React, { FC } from 'react'

import LoungeForumChoiceForm, {
  LoungeForumChoiceFormProps,
} from '../../components/forms/LoungeForumChoice'
import { ActionButtonProps } from '../../components/ActionButton'
import LayoutLoungeConfig, { LayoutLoungeConfigProps } from '../../layouts/LayoutLoungeConfig'
import { IconProps } from '../../components/Icon'
import { ExhibitionForum } from '../../graphql/generated/graphql'
import LoungeNewForumForm, {
  LoungeNewForumFormProps,
} from '../../components/forms/LoungeNewForumForm'

import * as SC from './styled'

export type LoungesForumChoiceTemplateProps = {
  layoutProps?: LayoutLoungeConfigProps
  title: string
  subTitle?: string
  subTitleEdit?: string
  newButtonProps?: ActionButtonProps
  editButtonProps?: ActionButtonProps
  editMode?: boolean
  selectedForum?: {
    selected: boolean
    forum?: ExhibitionForum
  }
  editIconProps: IconProps
  loungeConfigFormProps: LoungeForumChoiceFormProps
  setSelectedForum?: (forumId: string | undefined) => void
  loungesNewForum: LoungeNewForumFormProps
}

const LoungesForumChoiceTemplate: FC<LoungesForumChoiceTemplateProps> = ({
  layoutProps,
  title,
  subTitle,
  newButtonProps,
  editButtonProps,
  loungeConfigFormProps,
  editMode,
  editIconProps,
  selectedForum,
  setSelectedForum,
  loungesNewForum,
}) => {
  return (
    <LayoutLoungeConfig {...layoutProps}>
      <SC.Content>
        <SC.Title tag={'h2'} text={title} />
        {loungeConfigFormProps &&
          !selectedForum &&
          loungeConfigFormProps.fields.forumsSelected.options.length > 0 && (
            <>
              <SC.SubTitle>{subTitle}</SC.SubTitle>
              {loungeConfigFormProps && (
                <SC.CTAListContainer>
                  {!editMode && <LoungeForumChoiceForm {...loungeConfigFormProps} />}
                  {loungeConfigFormProps.fields &&
                    editMode &&
                    loungeConfigFormProps.fields.forumsSelected.options.map((item, key) => {
                      return (
                        <SC.ForumSelect
                          key={key}
                          icon={{ ...editIconProps }}
                          text={item.label as string}
                          backgroundColor={item.forceBackgroundColor}
                          color={item.color}
                          onClick={() =>
                            setSelectedForum ? setSelectedForum(item.value) : undefined
                          }
                        />
                      )
                    })}
                </SC.CTAListContainer>
              )}
            </>
          )}
        {loungeConfigFormProps && !editMode && !selectedForum && (
          <SC.ButtonContainer
            style={{
              justifyContent:
                loungeConfigFormProps.fields.forumsSelected.options.length > 0 ? '' : 'center',
            }}
          >
            <SC.NewForumButton {...newButtonProps} />
            {loungeConfigFormProps.fields.forumsSelected.options.length > 0 && (
              <SC.EditButton {...editButtonProps} />
            )}
          </SC.ButtonContainer>
        )}
        {selectedForum && editMode && loungesNewForum && (
          <LoungeNewForumForm {...loungesNewForum} />
        )}
      </SC.Content>
    </LayoutLoungeConfig>
  )
}

export default LoungesForumChoiceTemplate
