import React from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SampleSheetsEditTemplate from '../../../templates/SampleSheetsEdit'
import { useSampleSheetEditTemplateProps } from '../../../templates/SampleSheetsEdit/useProps'
import { selectors } from '../../../redux'

const SampleSheetEditProductDestination: React.FC<RouteProps> = () => {
  const { routeType } = useParams<{ routeType: string }>()
  const form = useSelector(selectors.samples.flagGroupsByUniverse)
  const templateProps = useSampleSheetEditTemplateProps(
    routeType,
    'Destination produit',
    form
      ? form.find(
          (item) =>
            item.miraklRef === 'end_use' ||
            item.miraklRef === 'end_use_leather' ||
            item.miraklRef === 'yarn_end_use'
        )
      : undefined
  )
  return <SampleSheetsEditTemplate {...templateProps} />
}

export default SampleSheetEditProductDestination
