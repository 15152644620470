import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { actions, selectors } from '../../../redux'
import SampleSheetEditoTemplate from '../../../templates/SampleSheetEdito'
import { useSampleSheetEditoTemplateProps } from '../../../templates/SampleSheetEdito/useProps'
import EditoAdd from '../../../templates/EditoAdd'
import { useSampleSheetEditoAddTemplateProps } from '../../../templates/EditoAdd/useProps'

const SampleSheetEdito: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const sample = useSelector(selectors.samples.sample)

  const [mode, setMode] = useState<string>('list' || 'add')
  const [search, setSearch] = useState<string>('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  useEffect(() => {
    if (sample) {
      dispatch(
        actions.editos.editorialsSearchRequest({
          exhibitionApiId: sample.collection.exhibitionApiId,
          q: search,
          first: 10,
          page: 1,
        })
      )
    }
  }, [dispatch, sample, search])

  const templateProps = useSampleSheetEditoTemplateProps(setMode)
  const templatePropsAdd = useSampleSheetEditoAddTemplateProps(
    setMode,
    search,
    handleSearchChange,
    sample
  )

  return (
    <>
      {mode === 'list' && <SampleSheetEditoTemplate {...templateProps} />}
      {mode === 'add' && <EditoAdd {...templatePropsAdd} />}
    </>
  )
}

export default SampleSheetEdito
