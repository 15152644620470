import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { useExponentSheetTabsProps } from '../../relay/ExponentSheet/UseExponentSheetTabsProps'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import {
  Collection,
  Exhibitor,
  InternalNote,
  InternalNoteCommentableType,
  UserRole,
} from '../../graphql/generated/graphql'
import { Icons } from '../../components/Icon'
import { CollectionNoteFormValues } from '../../components/forms/CollectionNoteForm'
import { actions, selectors } from '../../redux'
import { ServiceValues } from '../../helpers/ReduxHelpers'
import { useLayoutExponentSheetTemplateProps } from '../../layouts/LayoutExponentSheet/useProps'

import { ExponentSheetInternalNoteTemplateProps } from '.'

export const useExponentSheetInternalNoteTemplateProps = (
  exhibitor: Exhibitor | null,
  collection: Collection | null,
  internalNoteCreate: ServiceValues<null, any>,
  internalNoteDelete: ServiceValues<null, any>,
  internalNoteUpdate: ServiceValues<null, any>
): ExponentSheetInternalNoteTemplateProps => {
  const { exposantId } = useParams<{ exposantId: string }>()
  const { apiId } = useParams<{ apiId: string }>()

  const dispatch = useDispatch()
  const ref = useRef<FormikProps<CollectionNoteFormValues>>(null)
  const refEdit = useRef<FormikProps<CollectionNoteFormValues>>(null)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false)
  const [canUserEdit, setCanUserEdit] = useState<boolean>(false)
  const [clickedNote, setClickedNote] = useState<InternalNote | undefined>(undefined)
  const user = useSelector(selectors.auth.user)
  const tabs = useExponentSheetTabsProps(exposantId, apiId, 'note', user?.role)
  const isArchived = collection?.isArchived

  const layoutProps = useDefaultLayoutTemplateProps('exponents')
  const layoutPropsExponent = useLayoutExponentSheetTemplateProps(exhibitor, collection)

  const setCtaValue = useCallback(() => {
    if (!editMode) {
      return 'Ajouter une note interne'
    }
    if (editMode) {
      return 'Valider'
    }
  }, [editMode])

  useEffect(() => {
    if (
      user &&
      (user.role === UserRole.Admin ||
        user.role === UserRole.SuperAdmin ||
        user.role === UserRole.ModePv ||
        user.role === UserRole.FashionExpert ||
        user.role === UserRole.Rex ||
        user.role === UserRole.Marketplace)
    )
      setCanUserEdit(true)
    else if (
      user &&
      collection &&
      collection.internalNotes[0] &&
      collection.internalNotes[0].user.id === user.id
    )
      setCanUserEdit(true)
  }, [collection, editMode, isValid, user])

  useEffect(() => {
    if (internalNoteCreate.success) setEditMode(false)
    if (internalNoteDelete.success) setIsModalOpen(false)
    if (internalNoteUpdate.success) setIsModalEditOpen(false)
  }, [internalNoteCreate.success, internalNoteDelete.success, internalNoteUpdate.success])

  useEffect(() => {
    if (isModalEditOpen && clickedNote) {
      refEdit.current?.setValues({
        note: clickedNote.body,
      })
    }
  }, [isModalEditOpen, clickedNote])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...layoutPropsExponent,
        bottomActions: {
          ...layoutPropsExponent.bottomActions,
          back: undefined,
          next:
            user &&
            user.role !== UserRole.Intern &&
            user.role !== UserRole.InputOperator &&
            !isArchived &&
            canUserEdit
              ? {
                  text: setCtaValue(),
                  disabled: editMode && !isValid,
                  rightIconProps: {
                    icon: editMode ? Icons.check : Icons.plus,
                    width: 18,
                    height: 18,
                  },
                  onClick: () => {
                    if (!editMode) setEditMode(true)
                    if (editMode) {
                      ref.current?.submitForm()
                    }
                  },
                }
              : undefined,
        },
        tabs: tabs,
        deleteModal: {
          text: 'Veuillez valider la suppression de la note interne selectionée.',
          cancelButton: {
            text: 'Annuler',
            onClick: () => setIsModalOpen(false),
          },
          deleteButton: {
            text: 'Oui, supprimer',
            onClick: () => {
              setIsModalOpen(false)
              setIsModalEditOpen(false)
              collection &&
                clickedNote &&
                dispatch(
                  actions.exhibitors.internalNoteDeleteRequest({
                    internalNoteId: clickedNote?.id,
                  })
                )
            },
          },
          isOpen: isModalOpen,
        },
        editModal: {
          onClose: () => setIsModalEditOpen(false),
          isOpen: isModalEditOpen,
        },
        editNoteForm: {
          formikForm: {
            innerRef: refEdit,
            initialValues: {
              note: '',
            } as CollectionNoteFormValues,
            validationSchema: Yup.object().shape({
              note: Yup.string().required(),
            }),
            onSubmit: (values) => {
              collection &&
                clickedNote &&
                dispatch(
                  actions.exhibitors.internalNoteUpdateRequest({
                    internalNoteId: clickedNote?.id,
                    body: values.note,
                  })
                )
            },
            onContextUpdate: (context) => {
              canUserEdit && setIsValid(context.isValid)
            },
            validateOnChange: true,
            validateOnMount: true,
          },
          fields: {
            note: {
              title: 'Éditer la note interne',
              props: {
                label: undefined,
                name: 'note',
                required: true,
                hasFloatingLabel: false,
              },
            },
          },
          submit: {
            text: 'Valider',
            onClick: () => {
              setIsModalEditOpen(false)
            },
          },
          deleteButton: {
            text: 'Supprimer la note',
            onClick: () => {
              setIsModalOpen(true)
            },
          },
        },
      },
      pending: !collection,
      returnTag: !!collection?.samplesToReturn,
      newTag: !!exhibitor?.isNew,
      title: 'Note interne',
      editNote: editMode,
      edit: {
        formikForm: {
          innerRef: ref,
          initialValues: {
            note: '',
          } as CollectionNoteFormValues,
          validationSchema: Yup.object().shape({
            note: Yup.string().required(),
          }),
          onSubmit: (values) => {
            collection &&
              dispatch(
                actions.exhibitors.internalNoteCreateRequest({
                  commentableType: InternalNoteCommentableType.Collection,
                  commentableId: collection?.id,
                  body: values.note,
                })
              )
          },
          onContextUpdate: (context) => {
            canUserEdit && setIsValid(context.isValid)
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          note: {
            title: 'Ajouter une note interne',
            props: {
              label: undefined,
              name: 'note',
              required: true,
              hasFloatingLabel: false,
              placeholder: 'Votre message ...',
            },
          },
        },
      },
      notes:
        collection?.internalNotes.length !== 0
          ? collection?.internalNotes.map((internalNote) => ({
              text: internalNote.body,
              author: {
                fullName: ('par ' +
                  internalNote.user.lastName +
                  ' ' +
                  internalNote.user.firstName) as string,
                date: 'le ' + dayjs(internalNote.writtenAt).format('DD.MM.YYYY'),
              },
              editButton:
                internalNote.user.id === user?.id
                  ? {
                      text: 'Éditer la note',
                      onClick: () => {
                        setClickedNote(internalNote)
                        setIsModalEditOpen(true)
                        setEditMode(false)
                      },
                    }
                  : undefined,
            }))
          : undefined,
    }),
    [
      canUserEdit,
      clickedNote,
      collection,
      dispatch,
      editMode,
      exhibitor?.isNew,
      isArchived,
      isModalEditOpen,
      isModalOpen,
      isValid,
      layoutProps,
      layoutPropsExponent,
      setCtaValue,
      tabs,
      user,
    ]
  )
}
