import styled from 'styled-components'

import ImageColumns from '../ImageColumns'
import Paragraph from '../Paragraph'
import { breakpoints } from '../../theme'

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 14px;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 8px;
  box-shadow: 2px 2px 34px 0px #00000026;
  ${breakpoints.up('tablet')} {
    gap: 24px;
    padding: 24px;
    align-items: flex-start;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  ${({ theme }) => theme.textStyles.titleH1};
  color: ${({ theme }) => theme.colors.mineShaft};
  padding-bottom: 11px;
  max-width: 400px;
  line-break: anywhere;
`

export const Category = styled.span`
  ${({ theme }) => theme.textStyles.titleH6};
  color: ${({ theme }) => theme.colors.tundora};
  padding: 5px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tundora};
  text-transform: uppercase;
`

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px 0px;
`

export const Image = styled.img<{ isFullHeight?: boolean }>`
  width: 168px;
  height: 168px;
  object-fit: cover;
`

export const BlockImage = styled(ImageColumns)`
  flex: 1;
`

export const StyledParagraph = styled(Paragraph)`
  text-transform: uppercase;
`
