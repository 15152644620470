import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleRestore = getMutationService({
  mutation: gql(`
        mutation sampleRestore($sampleId: ID!) {
            sampleRestore(sampleId: $sampleId)
        }
    `),
  transformer: (response) => {
    return response
  },
})
