import React, { FC } from 'react'

import { IconProps, Icons } from '../Icon'

import * as SC from './styled'

export type SearchButtonProps = {
  className?: string
  href?: string | undefined
  icon?: IconProps
}

const SearchButton: FC<SearchButtonProps> = ({ className, href, icon }) => {
  return (
    <SC.LinkTo className={className} href={href}>
      <SC.SearchIcon icon={Icons.search} width={22} height={22} {...icon} />
    </SC.LinkTo>
  )
}

export default SearchButton
