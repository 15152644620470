import styled from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { fonts } from '../../../theme'
import ColorPicker from '../../ColorPicker'
import FormFieldCheckboxGroup from '../../form/fields/FormFieldCheckboxGroup'
import CollapsibleColoredItem from '../../CollapsibleColored'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const StyledFormikForm = styled(FormikForm)`
  width: 100%;
`

export const CheckboxGroup = styled(FormFieldCheckboxGroup)`
  & > div {
    gap: 0;
  }
  & label {
    padding-right: 0;
    border-color: transparent !important;
  }
`

export const Title = styled.div`
  font-size: 1.4rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.gray};
`

export const Collapsible = styled(CollapsibleColoredItem)`
  margin-bottom: 1.2rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
`

export const TextField = styled(FormikField)``

export const StyledColorPicker = styled(ColorPicker)``
