import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SampleDataTemplate from '../../../templates/SampleData'
import { actions, selectors } from '../../../redux'
import { useSampleDataForumTemplateProps } from '../../../templates/SampleData/usePropsForum'

const ExponentSampleDataForum: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const configuration = useSelector(selectors.configuration.configurationInfo)
  const forums = useSelector(selectors.exhibitions.exhibitions)

  useEffect(() => {
    configuration &&
      dispatch(
        actions.exhibitions.exhibitionByApiIdRequest({
          exhibitionApiId: configuration?.exhibitionActiveApiId.toString(),
        })
      )
  }, [configuration, configuration?.exhibitionActiveApiId, dispatch])

  const templateProps = useSampleDataForumTemplateProps('Forums', forums)
  return <SampleDataTemplate {...templateProps} />
}

export default ExponentSampleDataForum
