import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { actions, selectors } from '../../redux'
import { Icons } from '../../components/Icon'

import { DefaultLayoutProps } from '.'

export const useDefaultLayoutTemplateProps = (
  currentTab:
    | 'exponents'
    | 'samples'
    | 'scan'
    | 'params'
    | 'parcels'
    | 'lounges'
    | 'account'
    | undefined,
  noSearch?: boolean
): DefaultLayoutProps => {
  const dispatch = useDispatch()
  const user = useSelector(selectors.auth.user)
  const config = useSelector(selectors.configuration.configurationInfo)

  useEffect(() => {
    !config && dispatch(actions.configuration.getConfigurationInfoRequest())
  }, [config, dispatch])

  const location = useLocation()

  useEffect(() => {
    dispatch(actions.auth.authUserSetLastVisitedRequest({ href: location.pathname }))
  }, [dispatch, location.pathname])

  return useMemo(() => {
    const links = [
      {
        text: 'Gestion colis',
        href: '/exposants/redirect',
        ariaCurrent: currentTab === 'parcels' ? true : undefined,
      },
      {
        text: 'Séléction échantillons',
        href: '/exposants/samples',
        ariaCurrent: currentTab === 'samples' ? true : undefined,
      },
      {
        text: 'Scan échantillons',
        href: '/scan-echantillon',
        ariaCurrent: currentTab === 'scan' ? true : undefined,
      },
      {
        text: 'Exposants',
        href: '/exposants/search',
        ariaCurrent: currentTab === 'exponents' ? true : undefined,
      },
      {
        text: 'Salons',
        href: '/salons/recherche',
        ariaCurrent: currentTab === 'lounges' ? true : undefined,
      },
      {
        text: 'Paramètres',
        href: '/parametres',
        ariaCurrent: currentTab === 'params' ? true : undefined,
      },
      {
        text: 'Mon compte',
        href: '/mon-compte',
        ariaCurrent: currentTab === 'account' ? true : undefined,
      },
    ]

    const mobileLinks = [
      {
        text: 'Exposants',
        href: '/exposants/search',
      },
      {
        text: 'Salons',
        href: '/salons/recherche',
      },
      {
        text: 'Paramètres',
        href: '/parametres',
      },
      {
        text: 'Mon compte',
        href: '/mon-compte',
      },
    ]

    const filteredLinks = links.filter((link) => {
      if (
        link.text === 'Séléction échantillons' &&
        !['SUPER_ADMIN', 'ADMIN', 'FASHION_EXPERT', 'MODE_PV', 'REX'].includes(user?.role as string)
      ) {
        return false
      }

      if (
        link.text === 'Paramètres' &&
        !['SUPER_ADMIN', 'MODE_PV', 'ADMIN', 'MARKETPLACE'].includes(user?.role as string)
      ) {
        return false
      }

      return true
    })

    const filteredMobileLinks = mobileLinks.filter((link) => {
      if (
        link.text === 'Séléction échantillons' &&
        !['SUPER_ADMIN', 'ADMIN', 'FASHION_EXPERT', 'MODE_PV'].includes(user?.role as string)
      ) {
        return false
      }

      if (
        link.text === 'Paramètres' &&
        !['SUPER_ADMIN', 'MODE_PV', 'ADMIN', 'MARKETPLACE'].includes(user?.role as string)
      ) {
        return false
      }

      return true
    })

    return {
      mobileNav: {
        logoLink: {
          href: '/',
        },
        burger: {
          isOpen: false,
        },
        menu: {
          links: filteredMobileLinks,
          coloredLinks: [
            {
              text: 'Gestion colis',
              colorVariant: 'yellow',
              href: '/exposants/redirect',
            },
            {
              text: 'Présélection échantillons',
              colorVariant: 'purple',
              href: '/exposants/samples',
            },
            {
              text: 'Scan échantillons',
              colorVariant: 'green',
              href: '/scan-echantillon',
            },
          ],
          isOpen: false,
        },
        variant: 'default',
        search: noSearch ? false : true,
        searchButton: {},
      },
      sidebarNav: {
        iconProps: {
          icon: Icons.search,
          width: 20,
        },
        search: true,
        secondIconProps: {
          icon: Icons.arrowRight,
          width: 20,
        },
        menu: {
          links: filteredLinks,
        },
      },
      marginMainContent: true,
    }
  }, [currentTab, noSearch, user?.role])
}
