import styled from 'styled-components'

import { breakpoints } from '../../theme'
import InfosDisplay from '../../components/InfosDisplay'
import SimpleLoader from '../../components/SimpleLoader'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  ${breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const Loader = styled(SimpleLoader)`
  margin-left: 50%;
`

export const SliderContainer = styled.div`
  display: block;
  max-width: 350px;
  margin: auto;
  width: 100%;
`

export const InfosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const BoxTitle = styled.div`
  margin-top: 12px;
  ${(props) => props.theme.textStyles.titleH5};
  color: ${(props) => props.theme.colors.gray};
  padding-bottom: 6px;
`

export const Infos = styled(InfosDisplay)``
