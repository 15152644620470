import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { Icons } from '../../components/Icon'
import { useSampleSheetTabsProps } from '../../relay/SampleSheet/useSampleSheetTabsProps'
import { SampleStatus, FlagItem, ExhibitionForum } from '../../graphql/generated/graphql'
import { selectors } from '../../redux'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutSampleSheetTemplateProps } from '../../layouts/LayoutSampleSheet/useProps'

import { SampleSheetLabelTemplateProps } from '.'

export const useSampleSheetLabelTemplateProps = (): SampleSheetLabelTemplateProps => {
  const { sampleId } = useParams<{ sampleId: string }>()
  const [forum, setForum] = useState<ExhibitionForum | undefined>(undefined)
  const [subForum, setSubForum] = useState<ExhibitionForum | undefined>(undefined)
  const [status, setStatus] = useState<SampleStatus | undefined>(undefined)
  const [mkpForum, setMkpForum] = useState<FlagItem | undefined>(undefined)
  const [category, setCategory] = useState<FlagItem | undefined>(undefined)
  const sample = useSelector(selectors.samples.sample)
  const samplePdf = useSelector(selectors.samples.samplePdf)
  const samplePdfRequest = useSelector(selectors.samples.getSamplePdf)
  const user = useSelector(selectors.auth.user)
  const sampleById = useSelector(selectors.samples.getSampleById)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const sampleLayoutProps = useLayoutSampleSheetTemplateProps(
    status,
    forum,
    subForum,
    mkpForum,
    sample,
    category
  )
  const tabs = useSampleSheetTabsProps(sampleId, 'label', user?.role)

  useEffect(() => {
    if (sample) {
      sample.status && setStatus(sample.status)
      sample.subForum && setSubForum(sample.subForum)
      sample.forum && setForum(sample.forum)
      if (sample.flagItems) {
        setMkpForum(
          sample &&
            sample.flagItems &&
            sample.flagItems.find(
              (item) => item.group && item.group.miraklRef === 'NON_MARKETPLACE_forum'
            )
        )
        setCategory(
          sample &&
            sample.flagItems &&
            sample.flagItems.find((item) => item.group && item.group.miraklRef === 'category')
        )
      }
    }
  }, [sample])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...sampleLayoutProps,
        bottomActions: {
          ...sampleLayoutProps.bottomActions,
          back: undefined,
          next: undefined,
        },
        tabs: tabs,
      },
      pending: sampleById.pending,
      title: "L'étiquette échantillon",
      labelCard: sample
        ? {
            title: sample?.name,
            category: category ? category?.name : undefined,
            pavillion:
              sample.collection && sample.collection.exhibitor.pavilion.name
                ? sample.collection.exhibitor.pavilion.name
                : undefined,
            country: sample.collection.exhibitor.country as string,
            brand: sample.collection.exhibitor.name,
            image: {
              src: sample?.qrCode as string,
              alt: 'test',
            },
          }
        : undefined,
      labelExport: {
        text: "Exporter l'étiquette",
        rightIconProps: {
          icon: Icons.export,
          width: 24,
          height: 24,
        },
        disabled: !samplePdfRequest.complete,
        isPending: !samplePdfRequest.complete,
        onClick: () => samplePdf && window.open(samplePdf),
      },
    }),
    [
      category,
      layoutProps,
      sample,
      sampleById.pending,
      sampleLayoutProps,
      samplePdf,
      samplePdfRequest.complete,
      tabs,
    ]
  )
}
