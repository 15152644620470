import styled, { css } from 'styled-components'

import { ModalVariant } from './types'

export const Container = styled.div<{
  $isOpen: boolean
  $variant?: ModalVariant
  $withRightSidebar?: boolean
}>`
  display: ${(props) => (props.$isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $variant }) => ($variant === ModalVariant.Toast ? 'flex-start' : 'center')};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    left: ${({ $withRightSidebar }) => ($withRightSidebar ? '32.1rem' : 0)};
  }
`

export const Content = styled.div<{ $variant?: ModalVariant }>`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 2rem;
  border-radius: 1rem;
  overflow: auto;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    width: 70%;
  }
  ${({ $variant }) => {
    switch ($variant) {
      case ModalVariant.Small:
        return css`
          width: 100%;
          max-width: min(100%, 35rem);
        `
      case ModalVariant.Toast:
        return css`
          width: 100%;
          max-width: min(100%, 73rem);
        `
    }
  }}
`

export const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.pureBlack};
  opacity: 0.7;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
