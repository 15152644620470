import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

import Link from '../Link'

export const Container = styled.nav`
  border-top: 1px solid ${({ theme }) => theme.colors.tundora};
  border-bottom: 1px solid ${({ theme }) => theme.colors.tundora};
`
export const Wrapper = styled(ScrollContainer)<{ $scrollable?: boolean }>`
  --cursor--wrapper: ${({ $scrollable }) => ($scrollable === true ? 'grab' : 'initial')};
  --cursor--link: ${({ $scrollable }) => ($scrollable === true ? 'inherit' : 'pointer')};
  display: flex;
  align-items: stretch;
  cursor: var(--cursor--wrapper);
`

export const Tab = styled(Link)`
  ${({ theme }) => theme.textStyles.titleH6}
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mineShaft};
  background: ${({ theme }) => theme.colors.pureWhite};
  transition: all 300ms ease-in;
  cursor: var(--cursor--link);
  &:first-child {
    margin-inline-start: auto;
  }
  &:not(:first-child) {
    border-left: 0.1rem solid ${({ theme }) => theme.colors.grayLight};
  }
  &:last-child {
    margin-inline-end: auto;
  }
  &:hover,
  &:focus,
  &:active,
  &[aria-current] {
    color: ${({ theme }) => theme.colors.pureWhite};
    background: ${({ theme }) => theme.colors.tundora};
  }
`
