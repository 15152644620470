import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleStats = getQueryService({
  query: gql(`
    query sampleStats($exhibitionApiId: ID, $forumId: Int) {
        sampleStats(exhibitionApiId: $exhibitionApiId, forumId: $forumId){
            status,
            count
        }
    }
`),
  transformer: (response) => {
    return response
  },
})
