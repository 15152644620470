import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import { Icons } from '../Icon'
import { NotificationIconProps } from '../NotificationIcon'
import { ActionButtonScheme, ActionButtonSize } from '../ActionButton/types'

import * as SC from './styled'

export type BottomActionsProps = {
  className?: string
  back?: ActionButtonProps
  note?: ActionButtonProps & NotificationIconProps
  next?: ActionButtonProps
  nextPlus?: ActionButtonProps
}

const BottomActions: FC<BottomActionsProps> = ({ className, back, note, next, nextPlus }) => {
  return (
    <SC.Actions className={className}>
      <SC.Left $minorGap={!!nextPlus}>
        {back && (
          <SC.Button
            variantSize={ActionButtonSize.Large}
            variantScheme={ActionButtonScheme.White}
            leftIconProps={{ icon: Icons.arrowLeft, width: 22, height: 22 }}
            {...back}
          />
        )}
        {note && (
          <SC.Button
            variantSize={ActionButtonSize.Large}
            variantScheme={ActionButtonScheme.White}
            {...note}
          >
            <SC.NotifIcon number={note.number} />
          </SC.Button>
        )}
      </SC.Left>
      <SC.Right $minorGap={!!nextPlus}>
        {nextPlus && (
          <SC.Button
            variantSize={ActionButtonSize.Large}
            rightIconProps={{ icon: Icons.arrowRight, width: 20, height: 11 }}
            {...nextPlus}
          />
        )}
        {next && (
          <SC.Button
            variantSize={ActionButtonSize.Large}
            rightIconProps={{ icon: Icons.arrowRight, width: 20, height: 11 }}
            {...next}
          />
        )}
      </SC.Right>
    </SC.Actions>
  )
}

export default BottomActions
