import React, { FC, useEffect, useState } from 'react'

import { FilterTagProps } from '../FilterTag'
import { Icons } from '../Icon'
import { ActionButtonProps } from '../ActionButton'
import { FiltersSmallProps } from '../forms/FiltersSmall'
import { ActionButtonScheme } from '../ActionButton/types'

import * as SC from './styled'

export type FiltersInlineProps = {
  className?: string
  filters?: FiltersSmallProps
  tags: Array<FilterTagProps>
  button?: ActionButtonProps
  isOpen?: boolean
}

const FiltersInline: FC<FiltersInlineProps> = ({
  className,
  tags,
  filters,
  button,
  isOpen = false,
}) => {
  const [stateOpen, setStateOpen] = useState<boolean>()

  useEffect(() => {
    setStateOpen(isOpen)
  }, [isOpen])

  return filters ? (
    <SC.Container className={className}>
      <SC.TagList>
        {tags.map((tag, idx) => (
          <SC.Tag key={`tag-${idx}`} {...tag} />
        ))}
      </SC.TagList>
      {button && (
        <SC.Button
          {...button}
          onClick={() => setStateOpen(!stateOpen)}
          variantScheme={ActionButtonScheme.Link}
          rightIconProps={{ icon: Icons.caretDown, width: 11, height: 11 }}
          $isOpen={stateOpen}
        />
      )}
      <SC.Content $isOpen={stateOpen}>
        <SC.Filters {...filters} $isOpen={stateOpen} />
      </SC.Content>
    </SC.Container>
  ) : null
}

export default FiltersInline
