import React, { FC } from 'react'

import { FilterTagProps } from '../FilterTag'
import { ModalFullScreenProps } from '../ModalFullScreen'
import { FilterButtonProps } from '../FilterButton'
import { FiltersExportFormProps } from '../forms/FiltersExportForm'
import { ActionButtonProps } from '../ActionButton'
import { FormikFormProps } from '../form/FormikForm'
import { UniversesFormValues } from '../forms/FiltersForm'
import { colors } from '../../theme'
import { ActionButtonSize } from '../ActionButton/types'
import FormikField from '../form/FormikField'
import { ConfigurationDictionary, SamplesCountByQuery } from '../../graphql/generated/graphql'
import { FormSubmitButtonProps } from '../form/FormSubmitButton'
import { OptionRadio } from '../form/fields/FormFieldRadioGroup/styled'
import SimpleLoader from '../SimpleLoader'

import * as SC from './styled'

export type FiltersExportProps = ModalFullScreenProps & {
  className?: string
  headerTitle?: string
  tags: Array<FilterTagProps>
  form: FiltersExportFormProps
  backButtonProps: FilterButtonProps
  title?: string
  exhibitorsNbr?: string
  exportAll?: boolean
  resetButton?: ActionButtonProps
  exportButton?: ActionButtonProps
  dataType?: string
  universe?: string
  universes?: ConfigurationDictionary
  exhibitorFilters?: boolean
  formikFormUniverses?: FormikFormProps<UniversesFormValues>
  samplesCount?: SamplesCountByQuery | undefined
  submitUniverseButton?: FormSubmitButtonProps
  cleanUniverseButton?: ActionButtonProps
  exportTitle?: string
  pending?: boolean
  setExportAll?: () => void
}

const FiltersExport: FC<FiltersExportProps> = ({
  className,
  headerTitle,
  backButtonProps,
  tags,
  form,
  title,
  exhibitorsNbr,
  exportAll,
  setExportAll,
  resetButton,
  exportButton,
  dataType,
  universe,
  universes,
  exhibitorFilters,
  formikFormUniverses,
  samplesCount,
  pending,
  submitUniverseButton,
  cleanUniverseButton,
  exportTitle,
  ...modal
}) => {
  return (
    <SC.Container className={className} {...modal}>
      <SC.Content>
        <SC.BackButton {...backButtonProps} />
        {!exhibitorFilters && (
          <>
            <SC.Title tag="h3" text="Univers" />
            {!universe ? (
              <SC.SubTitle>
                Veuillez sélectionner un univers pour afficher les filtres correspondants.
              </SC.SubTitle>
            ) : (
              <>
                <SC.Radio>
                  <OptionRadio type="radio" name={universe} checked={true} />
                  {universe}
                </SC.Radio>
                <SC.SubmitUniverseButton
                  {...cleanUniverseButton}
                  variantSize={ActionButtonSize.Small}
                  type="button"
                />
              </>
            )}
          </>
        )}
        {!exhibitorFilters &&
        !universe &&
        formikFormUniverses &&
        samplesCount &&
        samplesCount.samplesCountBy ? (
          <SC.FormUniverse className={className} {...formikFormUniverses}>
            <FormikField
              Component={SC.StyledRadiosGroup}
              name="universe"
              optionBefore={true}
              options={
                universes?.items.map((universe) => ({
                  label: universe.value,
                  value: universe.key,
                  count: samplesCount?.samplesCountBy.find((item) => item.ebmsId === universe.key)
                    ?.count,
                  backgroundColor: colors.pureWhite,
                })) || []
              }
            />
            <SC.SubmitUniverseButton
              {...submitUniverseButton}
              variantSize={ActionButtonSize.Small}
              type="button"
            />
          </SC.FormUniverse>
        ) : !exhibitorFilters ? (
          <>
            {headerTitle && <SC.Header text={headerTitle} tag="h1" />}
            <SC.Checkbox
              name="exportAll"
              label={exportTitle}
              display="simple"
              checked={exportAll}
              onClick={setExportAll}
            />
            {title && <SC.Title text={title} tag="h4" />}
            {tags.length > 0 && (
              <SC.Tags>
                {tags.map((tag, idx) => (
                  <SC.Tag key={`filter-tag-${idx}`} {...tag} />
                ))}
              </SC.Tags>
            )}
            <SC.InfosContainer>
              <SC.Infos>
                {pending ? <SimpleLoader /> : <SC.ExhibitorNbr>{exhibitorsNbr}</SC.ExhibitorNbr>}
                <SC.Text>{dataType}</SC.Text>
              </SC.Infos>
            </SC.InfosContainer>
            <SC.Form {...form} />
            <SC.Button {...resetButton} />
            {exportButton && <SC.ExportButton {...exportButton} />}
          </>
        ) : undefined}
        {exhibitorFilters && (
          <>
            {headerTitle && <SC.Header text={headerTitle} tag="h1" />}
            <SC.Checkbox
              name="exportAll"
              label="Exporter tous les exposants"
              display="simple"
              checked={exportAll}
              onClick={setExportAll}
            />
            {title && <SC.Title text={title} tag="h4" />}
            {tags.length > 0 && (
              <SC.Tags>
                {tags.map((tag, idx) => (
                  <SC.Tag key={`filter-tag-${idx}`} {...tag} />
                ))}
              </SC.Tags>
            )}
            <SC.InfosContainer>
              <SC.Infos>
                {pending ? <SimpleLoader /> : <SC.ExhibitorNbr>{exhibitorsNbr}</SC.ExhibitorNbr>}
                <SC.Text>{dataType}</SC.Text>
              </SC.Infos>
            </SC.InfosContainer>
            <SC.Form {...form} />
            <SC.Button {...resetButton} />
            {exportButton && <SC.ExportButton {...exportButton} />}
          </>
        )}
      </SC.Content>
    </SC.Container>
  )
}

export default FiltersExport
