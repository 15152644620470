import React, { FC } from 'react'

import { SimpleCardProps } from '../SimpleCard'
import { AlphabetKeys } from '../AlphabeticSort'

import * as SC from './styled'

export type ExponentListProps = {
  className?: string
  cards?: SimpleCardProps[]
  selectedLetter?: AlphabetKeys
}

const ExponentList: FC<ExponentListProps> = ({ className, cards, selectedLetter }) => {
  return cards && cards.length > 0 ? (
    <SC.Container className={className}>
      {cards
        .filter((card) => {
          if (selectedLetter && card.topInfo?.tag) {
            return card.topInfo.tag.charAt(0).toUpperCase() === selectedLetter
          }
          return true
        })
        .map((card, index) => (
          <SC.Card key={index} {...card} />
        ))}
    </SC.Container>
  ) : null
}

export default ExponentList
