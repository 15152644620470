import * as Yup from 'yup'

import { AccountFormProps } from './index'

const basic: AccountFormProps = {
  state: 'preview',
  formikForm: {
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(),
      newPassword: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?-_@#$%^&*])(?=.{12,})/,
          'Mot de passe incorrect'
        ),
      newPasswordConfirm: Yup.string()
        .oneOf([Yup.ref('password')], 'Les deux mots de passe saisis doivent être identiques.')
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?-_@#$%^&*])(?=.{12,})/,
          'Mot de passe incorrect'
        )
        .test(
          'passwords-match',
          'Les deux mots de passe saisis doivent être identiques.',
          function (value) {
            return value === this.parent.password
          }
        ),
    }),
    onSubmit: () => undefined,
    validateOnChange: true,
  },
  rules: {
    length: {
      isValid: true,
      rule: '12 caractères minimum',
    },
    uppercase: {
      isValid: false,
      rule: 'Majuscules',
    },
    number: {
      isValid: false,
      rule: 'Chiffre',
    },
    specialCharacter: {
      isValid: false,
      rule: 'Caractères spéciaux',
    },
  },
  submitButton: {
    text: 'Valider le changement de mot de passe',
  },
  fields: {
    password: {
      name: 'password',
      label: 'Mot de passe',
      required: true,
    },
    newPassword: {
      name: 'newPassword',
      label: 'Nouveau mot de passe',
      required: true,
    },
    newPasswordConfirm: {
      name: 'newPasswordConfirm',
      label: 'Confirmer le nouveau mot de passe',
      required: true,
    },
  },
  previewButton: {
    text: 'Modifier mon mot de passe',
  },
  info: {
    email: {
      title: 'Email',
      value: 'nom.prenom@mail.com',
    },
    role: {
      title: 'Mon rôle',
      value: 'Expert mode',
    },
  },
}

export const accountFormMocks = {
  basic,
}
