import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const sampleUpdateWithUniverse = getMutationService({
  mutation: gql(`
    mutation sampleUpdateWithUniverse($sampleId: ID!, $universeEbmsId: String!, $subUniverseEbmsId: String!) {
      sampleUpdateWithUniverse(sampleId: $sampleId, universeEbmsId: $universeEbmsId, subUniverseEbmsId: $subUniverseEbmsId) {
        id
        name
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
