import styled from 'styled-components'

import { breakpoints, textStyles } from '../../theme'
import InfosDisplay from '../../components/InfosDisplay'
import Heading from '../../components/Heading'
import SimpleLoader from '../../components/SimpleLoader'

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  ${breakpoints.up('tablet')} {
    gap: 2.4rem;
  }
`

export const Loader = styled(SimpleLoader)`
  margin: auto;
`

export const Section = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 1.2rem;
  margin: 0;
`

export const Infos = styled(InfosDisplay)``

export const Title = styled(Heading)`
  margin-top: 0;
`

export const Text = styled.div`
  ${textStyles.titleH5}
`
