import React, { FC } from 'react'

import ExponentParcels, { ExponentParcelsProps } from '../../components/ExponentParcels'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import LayoutExponentSheet, { LayoutExponentSheetProps } from '../../layouts/LayoutExponentSheet'
import CollectionCommentForm, {
  CollectionCommentFormProps,
} from '../../components/forms/CollectionCommentForm'
import CollectionComment, { CollectionCommentProps } from '../../components/CollectionComment'
import { NumberedTagProps } from '../../components/NumberedTag'
import { SampleCardProps } from '../../components/SampleCard'
import { SampleChartProps } from '../../components/SampleChart'
import { WarningProps } from '../../components/Warning'
import { SampleSelectFormProps } from '../../components/forms/SampleSelectForm'
import { ActionButtonSize } from '../../components/ActionButton/types'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type ExponentSheetCollectionTemplateProps = {
  layoutProps?: LayoutExponentSheetProps
  pending?: boolean
  parcels?: ExponentParcelsProps
  comment?: CollectionCommentFormProps
  deleteModal?: ModalDeleteProps
  commentText?: CollectionCommentProps
  statistics?: {
    title?: string
    chart?: SampleChartProps
  }
  samples?: {
    shootingBtn?: ActionButtonProps
    title?: string
    warning?: WarningProps
    numbered?: NumberedTagProps
    list?: SampleCardProps[]
    marketplaceWarning?: NumberedTagProps
  }
  sampleEditPending: boolean
  edit?: SampleSelectFormProps
}

const ExponentSheetCollectionTemplate: FC<ExponentSheetCollectionTemplateProps> = ({
  layoutProps,
  pending,
  parcels,
  comment,
  commentText,
  deleteModal,
  edit,
  samples,
  sampleEditPending,
  statistics,
}) => {
  return (
    <LayoutExponentSheet {...layoutProps} modals={deleteModal && <ModalDelete {...deleteModal} />}>
      {pending ? (
        <SC.Loader />
      ) : (
        <>
          {edit && <SC.StyledSampleSelectForm {...edit} />}
          <CollectionComment {...commentText} />
          {statistics && (
            <SC.Section>
              {statistics.title && <SC.Title tag="h5" text={statistics.title} />}
              {statistics.chart && <SC.Chart {...statistics.chart} />}
            </SC.Section>
          )}
          {samples && (
            <SC.Section>
              {samples.title && <SC.Title tag="h5" text={samples.title} />}
              {samples.numbered && <SC.StyledNumberedTag {...samples.numbered} />}
              {samples.marketplaceWarning && (
                <SC.MarketplaceWarning {...samples.marketplaceWarning} />
              )}
              {samples.warning && <SC.StyledWarning {...samples.warning} />}
              {samples.shootingBtn && (
                <SC.ShootingBtn variantSize={ActionButtonSize.Small} {...samples.shootingBtn} />
              )}
              {samples.list && samples.list.length > 0 && (
                <SC.Samples $isBlurred={sampleEditPending}>
                  {samples.list.map((sample, index) => {
                    return (
                      <SC.Sample
                        $isBlurred={sampleEditPending}
                        key={`Sample-${index}`}
                        {...sample}
                      />
                    )
                  })}
                </SC.Samples>
              )}
            </SC.Section>
          )}
          <SC.Section>{parcels && <ExponentParcels {...parcels} />}</SC.Section>
          {comment ? <CollectionCommentForm {...comment} /> : null}
        </>
      )}
    </LayoutExponentSheet>
  )
}

export default ExponentSheetCollectionTemplate
