import styled from 'styled-components'

import Heading from '../../components/Heading'
import SearchBar from '../../components/SearchBar'
import CollapsibleItem from '../../components/CollapsibleItem'
import LoungeCard from '../../components/LoungeCard'
import { breakpoints } from '../../theme'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
`

export const Search = styled(SearchBar)`
  margin: 2.2rem 0;
  ${breakpoints.up('tablet')} {
    margin: 2.2rem 0 4rem 0;
  }
`

export const LoaderContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Collapsible = styled(CollapsibleItem)``

export const StyledLoungeCard = styled(LoungeCard)`
  margin-bottom: 1rem;
`
