import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectors } from '../../../redux'
import SampleSheetCollectionTemplate from '../../../templates/SampleSheetCollection'
import { useSampleSheetCollectionTemplateProps } from '../../../templates/SampleSheetCollection/useProps'

const SampleSheetCollection: React.FC<RouteProps> = () => {
  const sample = useSelector(selectors.samples.sample)
  const collection = useSelector(selectors.exhibitors.collection)

  const templateProps = useSampleSheetCollectionTemplateProps(collection, sample)

  return (
    <>
      <SampleSheetCollectionTemplate {...templateProps} />
    </>
  )
}

export default SampleSheetCollection
