import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const editorialDetachSamples = getMutationService({
  mutation: gql(`
	mutation editorialDetachSamples($editorialId: ID!, $samplesIds: [ID!]!) {
        editorialDetachSamples(editorialId: $editorialId, samplesIds: $samplesIds) {
            id
            name
            description
            exhibition {
                id
            }
            samples {
                id
                name
                status
            }
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
