import React, { FC, ImgHTMLAttributes } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as SC from './styled'

export type SimpleSliderProps = {
  className?: string
  title?: string
  image?: ImgHTMLAttributes<HTMLImageElement>[]
}

const SimpleSlider: FC<SimpleSliderProps> = ({ className, title, image }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <SC.SliderContainer className={className}>
      <SC.Title>{title}</SC.Title>
      {image ? (
        <Slider {...settings}>
          {image.map((item, key) => (
            <SC.ImageContainer key={key}>
              <SC.Image src={item.src} alt={item.alt} />
            </SC.ImageContainer>
          ))}
        </Slider>
      ) : (
        <SC.Error>Donnée manquante</SC.Error>
      )}
    </SC.SliderContainer>
  )
}

export default SimpleSlider
