import React from 'react'
import { RouteProps } from 'react-router-dom'

import SampleDataLeatherTemplate from '../../../templates/SampleDataLeather'
import { useSampleDataLeatherTemplateProps } from '../../../templates/SampleDataLeather/useProps'

const ExponentSampleDataLeatherChoice: React.FC<RouteProps> = () => {
  const templateProps = useSampleDataLeatherTemplateProps()
  return <SampleDataLeatherTemplate {...templateProps} />
}

export default ExponentSampleDataLeatherChoice
