import { filtersSmallMocks } from '../forms/FiltersSmall/mocks'

import { FiltersInlineProps } from './index'

const basic: FiltersInlineProps = {
  button: {
    text: 'Filtrer',
  },
  filters: filtersSmallMocks.usersAccess,
  tags: [],
}

export const filtersInlineMocks = {
  basic,
}
