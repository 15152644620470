import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { FormFieldCheckboxGroupProps } from '../../form/fields/FormFieldCheckboxGroup'

import * as SC from './styled'

export type FiltersExportFormValues = {
  [key: string]: Array<string>
}

export type FiltersExportFormProps = {
  className?: string
  formikForm: FormikFormProps<FiltersExportFormValues>
  fields: Array<FormFieldCheckboxGroupProps>
  errors?: FormErrorsProps['errors']
}

const FiltersExportForm: FC<FiltersExportFormProps> = ({
  className,
  formikForm,
  fields,
  errors,
}) => {
  return (
    <SC.Form className={className} {...formikForm}>
      <SC.Fields>
        {fields &&
          fields.map((field, idx) => {
            return (
              <SC.Collapsible key={idx} title={field.label ?? ''} operatorIcon>
                <FormikField Component={SC.FieldCheckboxGroup} display={'default'} {...field} />
              </SC.Collapsible>
            )
          })}
      </SC.Fields>
      {errors && <SC.Errors errors={errors} />}
    </SC.Form>
  )
}

export default FiltersExportForm
