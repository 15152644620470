import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { useSampleSheetTabsProps } from '../../relay/SampleSheet/useSampleSheetTabsProps'
import { selectors } from '../../redux'
import { ExhibitionForum, SampleStatus, FlagItem } from '../../graphql/generated/graphql'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { useLayoutSampleSheetTemplateProps } from '../../layouts/LayoutSampleSheet/useProps'

import { SampleSheetHistoryTemplateProps } from '.'

export const useSampleSheetHistoryTemplateProps = (): SampleSheetHistoryTemplateProps => {
  const { sampleId } = useParams<{ sampleId: string }>()
  const sampleHistory = useSelector(selectors.samples.sampleHistory)
  const user = useSelector(selectors.auth.user)
  const [forum, setForum] = useState<ExhibitionForum | undefined>(undefined)
  const [subForum, setSubForum] = useState<ExhibitionForum | undefined>(undefined)
  const [status, setStatus] = useState<SampleStatus | undefined>(undefined)
  const [mkpForum, setMkpForum] = useState<FlagItem | undefined>(undefined)
  const [category, setCategory] = useState<FlagItem | undefined>(undefined)
  const sample = useSelector(selectors.samples.sample)
  const sampleById = useSelector(selectors.samples.getSampleById)
  const tabs = useSampleSheetTabsProps(sampleId, 'history', user?.role)
  const layoutProps = useDefaultLayoutTemplateProps('samples')
  const sampleLayoutProps = useLayoutSampleSheetTemplateProps(
    status,
    forum,
    subForum,
    mkpForum,
    sample,
    category
  )
  useEffect(() => {
    if (sample) {
      sample.status && setStatus(sample.status)
      sample.subForum && setSubForum(sample.subForum)
      sample.forum && setForum(sample.forum)
      if (sample.flagItems) {
        setMkpForum(
          sample &&
            sample.flagItems &&
            sample.flagItems.find(
              (item) => item.group && item.group.miraklRef === 'NON_MARKETPLACE_forum'
            )
        )
        setCategory(
          sample &&
            sample.flagItems &&
            sample.flagItems.find((item) => item.group && item.group.miraklRef === 'category')
        )
      }
    }
  }, [sample])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        ...sampleLayoutProps,
        bottomActions: {
          ...sampleLayoutProps.bottomActions,
          back: undefined,
          next: undefined,
        },
        tabs: tabs,
      },
      pending: sampleById.pending,
      history: {
        history: sampleHistory
          ? sampleHistory.map((item) => ({
              title: item.label as string,
              text: item.subLabel as string,
              user: item.user as string,
              date: item.date as string,
              variant:
                item.label === 'Sélection validée (OK vérif)' ||
                item.label === 'Sélection validée (OK étiquette)' ||
                item.label === 'Sélection validée'
                  ? 'validated'
                  : item.label === 'Supprimé'
                  ? 'deleted'
                  : item.label === 'Sélectionné' || item.label === 'OK Shooting'
                  ? 'selected'
                  : 'default',
            }))
          : [],
      },
    }),
    [layoutProps, sampleById.pending, sampleHistory, sampleLayoutProps, tabs]
  )
}
