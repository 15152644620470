import { useMemo, useRef, useState } from 'react'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { Icons } from '../../components/Icon'

import {
  MultipleEditionDataSelectionTemplateFormValues,
  MultipleEditionDataSelectionTemplateProps,
} from '.'

export const useStatutSelectionProps = (
  onSubmitForm: (values: MultipleEditionDataSelectionTemplateFormValues) => void
): MultipleEditionDataSelectionTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('scan')
  const ref = useRef<FormikProps<MultipleEditionDataSelectionTemplateFormValues>>(null)

  const [isValid, setIsValid] = useState<boolean>(false)

  return useMemo(
    () => ({
      title: 'Edition multiple',
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
          },
          next: {
            text: 'Scan échantillons',
            rightIconProps: {
              icon: Icons.scan,
              width: 18,
              height: 18,
            },
            disabled: !isValid,
            onClick: () => {
              ref.current?.submitForm()
            },
          },
        },
      },
      formikForm: {
        innerRef: ref,
        initialValues: {
          radios: '',
        } as MultipleEditionDataSelectionTemplateFormValues,
        validationSchema: Yup.object().shape({
          radios: Yup.string().required(),
        }),
        onSubmit: (values) => {
          onSubmitForm(values)
        },
        onContextUpdate: (context) => {
          setIsValid(context.isValid)
        },
        validateOnChange: true,
        validateOnMount: true,
      },
    }),
    [isValid, layoutProps, onSubmitForm]
  )
}
