import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const authRefreshToken = getQueryService({
  query: gql(`
    query authRefreshToken {
        authRefreshToken {
            expiresIn
            tokenType
            accessToken
        }
    }
  `),
  transformer: (response) => {
    return response
  },
})
