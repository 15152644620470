import styled, { css } from 'styled-components'
import { Maybe } from 'graphql/jsutils/Maybe'

import { fonts } from '../../theme'
import Icon from '../Icon'
import Dot from '../Dot'

import { VariantCollapsibleColoredFont } from './types'

const leatherVariant = css`
  --color: ${(props) => props.theme.colors.roseOfSharon};
  --background-color: ${(props) => props.theme.colors.janna};
  --border-color--active: ${(props) => props.theme.colors.roseOfSharon};
`

const sourcingVariant = css`
  --color: ${(props) => props.theme.colors.danube};
  --background-color: ${(props) => props.theme.colors.linkWater};
  --border-color--active: ${(props) => props.theme.colors.danube};
`

const accessoriesVariant = css`
  --color: ${(props) => props.theme.colors.pizza};
  --background-color: ${(props) => props.theme.colors.albescentWhite};
  --border-color--active: ${(props) => props.theme.colors.pizza};
`

const inspirationVariant = css`
  --color: ${(props) => props.theme.colors.royalPurple};
  --background-color: ${(props) => props.theme.colors.whiteLilac};
  --border-color--active: ${(props) => props.theme.colors.royalPurple};
`

const designVariant = css`
  --color: ${(props) => props.theme.colors.fuchsiaPink};
  --background-color: ${(props) => props.theme.colors.amour};
  --border-color--active: ${(props) => props.theme.colors.fuchsiaPink};
`

const defaultVariant = css`
  --color: ${(props) => props.theme.colors.tundora};
  --background-color: ${(props) => props.theme.colors.concrete};
  --border-color--active: ${(props) => props.theme.colors.concrete};
`

const burstedVariant = css`
  --color: ${(props) => props.theme.colors.tundora};
  --background-color: ${(props) => props.theme.colors.pureWhite};
  --border-color--active: ${(props) => props.theme.colors.pureWhite};
  --background-color--active: ${(props) => props.theme.colors.mineShaft};
`

const burstedVariantHead = css`
  color: ${(props) => props.theme.colors.tundora};
  background-color: ${(props) => props.theme.colors.concrete};
  padding: 1.2rem 2rem;
  border-radius: 0.4rem;
`

export const CollapsibleItem = styled.div<{
  $noBorder?: boolean
  $variant?:
    | 'leather'
    | 'sourcing'
    | 'accessories'
    | 'inspiration'
    | 'design'
    | 'default'
    | 'bursted'
  $color?: string
  $backgroundColor?: Maybe<string>
}>`
  ${(props) => {
    switch (props.$variant) {
      case 'leather':
        return leatherVariant
      case 'sourcing':
        return sourcingVariant
      case 'accessories':
        return accessoriesVariant
      case 'inspiration':
        return inspirationVariant
      case 'design':
        return designVariant
      case 'bursted':
        return burstedVariant
      default:
        return defaultVariant
    }
  }}
  // custom colors
  ${({ $color, $backgroundColor }) =>
    $backgroundColor &&
    css`
      --color: ${$color};
      --background-color: ${$backgroundColor};
      --border-color--active: ${$color};
    `}
  padding: 0.5rem 0;
  border: 0.1rem solid var(--background-color);
  color: var(--color);
  background-color: var(--background-color);
  transition: border-color 300ms ease-in-out;
  &:hover {
    border-color: var(--border-color--active);
  }
`

export const Title = styled.span<{ $variantFont?: VariantCollapsibleColoredFont }>`
  ${({ theme, $variantFont }) =>
    $variantFont === VariantCollapsibleColoredFont.SmallBold
      ? theme.textStyles.titleH6
      : $variantFont === VariantCollapsibleColoredFont.DefaultBold
      ? theme.textStyles.titleH4
      : css`
          ${fonts.GothamMedium}
          font-size: 1.2rem;
        `}
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 0.4rem;
  cursor: pointer;
`

export const Content = styled.div`
  margin-top: 1rem;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
  overflow: auto;
  white-space: pre;
`

export const StyledDot = styled(Dot)``

export const Plus = styled(Icon)`
  color: ${(props) => props.theme.colors.tundora};
`
export const Minus = styled(Icon)`
  color: ${(props) => props.theme.colors.tundora};
`

export const HeaderTop = styled.div<{
  $variant?:
    | 'leather'
    | 'sourcing'
    | 'accessories'
    | 'inspiration'
    | 'design'
    | 'default'
    | 'bursted'
}>`
  ${(props) => {
    switch (props.$variant) {
      case 'bursted':
        return burstedVariantHead
      default:
        return null
    }
  }}
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderBottom = styled.div`
  display: flex;
  align-items: flex-start;
  ${(props) => props.theme.fonts.RobotoExtraLight};
  font-size: 1.4rem;
  padding-top: 1.6rem;
  color: ${(props) => props.theme.colors.tundora};
`
