import styled from 'styled-components'

import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'
import { breakpoints } from '../../theme'
import ActionButton from '../../components/ActionButton'

export const Wrapper = styled(ContentWrapper)`
  padding-block: 2.4rem;
  ${breakpoints.up('large')} {
    padding-block: 4.8rem 2.4rem;
  }
`
export const Title = styled(Heading)`
  margin: 0;
`
export const Subtitle = styled(Heading)`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
  ${breakpoints.up('large')} {
    &:not(:first-child) {
      margin-top: 3.6rem;
    }
  }
`
export const Content = styled.div`
  &:not(:first-child) {
    margin-top: 2.4rem;
  }
  ${breakpoints.up('large')} {
    &:not(:first-child) {
      margin-top: 3.6rem;
    }
  }
`
export const DeleteButton = styled(ActionButton)`
  display: flex;
  max-width: max-content;
  margin-inline: auto;
  white-space: nowrap;
  &:not(:first-child) {
    margin-top: 3.6rem;
  }
`
