import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { SearchBarProps } from '../../components/SearchBar'
import { EditoCardProps } from '../../components/EditoCard'

import * as SC from './styled'

export type EditoAddProps = {
  className?: string
  layoutProps?: LayoutWithBottomActionsProps
  subTitle: string
  text: string
  editoList?: EditoCardProps[]
  searchBar?: SearchBarProps
}

const EditoAdd: FC<EditoAddProps> = ({ layoutProps, subTitle, text, searchBar, editoList }) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        <SC.SubTitle>{subTitle} </SC.SubTitle>
        <SC.Text>{text}</SC.Text>
        <SC.Search {...searchBar} />
        <SC.EditoListContainer>
          {editoList
            ? Object.keys(editoList).map((key, idx) => <SC.Edito key={key} {...editoList[idx]} />)
            : 'Aucun édito'}
        </SC.EditoListContainer>
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default EditoAdd
