import styled from 'styled-components'

import Heading from '../../components/Heading'
import { fonts, colors, breakpoints } from '../../theme'
import ActionButton from '../../components/ActionButton'
import SearchBar from '../../components/SearchBar'
import EditoCard from '../../components/EditoCard'

export const Content = styled.div`
  flex-grow: 1;
  padding: 2.4rem 2rem 0;
`

export const Title = styled(Heading)`
  margin-bottom: 2.4rem;
`
export const SubTitle = styled.div`
  ${fonts.GothamBold}
  color: ${colors.tundora};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-top: 2.4rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

export const Text = styled.div`
  ${fonts.RobotoLight}
  color: ${colors.gray};
  font-size: 1.2rem;
  letter-spacing: 1px;
  padding-right: 1rem;
  margin-bottom: 2.2rem;
`

export const Search = styled(SearchBar)`
  margin: 2.2rem 0;
`

export const EditoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
  height: fit-content;
  ${breakpoints.up('tablet')} {
    justify-content: space-between;
    gap: 2.2rem;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

export const Edito = styled(EditoCard)`
  break-inside: avoid;
  margin-bottom: 2rem;
`

export const CTA = styled(ActionButton)`
  width: 100%;
  justify-content: space-between;
`
