import React, { FC, useMemo } from 'react'

import * as SC from './styled'

export type LinkProps = {
  className?: string
  id?: string
  target?: string
  title?: string
  role?: string
  tabIndex?: number
  rel?: string
  href?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLLinkElement | HTMLAnchorElement>) => void
  label?: string
  text?: string
  route?: string
  ariaLabel?: string
  ariaCurrent?: boolean
  disabled?: boolean
  children?: React.ReactNode
  type?: React.ButtonHTMLAttributes<any>['type']
  itemProp?: string
}

const Link: FC<LinkProps> = (props) => {
  const {
    className,
    children,
    href,
    target,
    title,
    label,
    role,
    tabIndex,
    rel,
    onClick,
    ariaLabel,
    ariaCurrent,
    disabled,
    type,
    text,
    ...extraProps
  } = props

  const isInternalLink = useMemo(() => href?.indexOf('http') !== 0, [href])

  return (
    <>
      {href && isInternalLink && (
        <SC.DomLink
          className={`${className} is-link`}
          $disabled={disabled}
          to={href}
          {...(tabIndex && { tabIndex })}
          {...(target && { target })}
          {...(title && { title })}
          {...(role && { role })}
          {...(rel && { rel })}
          {...(onClick && { onClick })}
          {...(ariaCurrent && { 'aria-current': 'true' })}
          {...extraProps}
        >
          {children || label || text}
        </SC.DomLink>
      )}
      {href && !isInternalLink && (
        <SC.Link
          $disabled={disabled}
          href={href}
          title={title}
          className={`${className} is-link`}
          {...(ariaCurrent && { 'aria-current': 'true' })}
          {...extraProps}
        >
          {children || label || text}
        </SC.Link>
      )}
      {!href && (onClick || !!type) && (
        <SC.Button
          $disabled={disabled}
          disabled={disabled}
          className={`${className} is-button`}
          onClick={onClick}
          type={type}
          {...(ariaLabel && { 'aria-label': ariaLabel })}
          {...(ariaCurrent && { 'aria-current': 'true' })}
          {...extraProps}
        >
          {children || label || text}
        </SC.Button>
      )}
      {!href && !(onClick || !!type) && (
        <SC.Span
          $disabled={disabled}
          className={`${className} is-span`}
          {...(ariaCurrent && { 'aria-current': 'true' })}
          {...extraProps}
        >
          {children || label || text}
        </SC.Span>
      )}
    </>
  )
}

export default Link
