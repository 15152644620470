import React, { FC } from 'react'

import * as SC from './styled'

export type ContentWrapperProps = {
  className?: string
  children?: React.ReactNode
}

const ContentWithSteps: FC<ContentWrapperProps> = ({ className, children }) => {
  return <SC.Container className={className}>{children}</SC.Container>
}

export default ContentWithSteps
