import React, { FC } from 'react'

import * as SC from './styled'

export type NotificationIconProps = {
  className?: string
  number?: string
}

const NotificationIcon: FC<NotificationIconProps> = ({ className, number }) => {
  return (
    <SC.Svg
      className={className}
      width="26"
      height="26"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6375 26.7688L9.675 23.3694C9.85209 23.2151 10.0784 23.1302 10.3125 23.1301H27C27.2652 23.1301 27.5196 23.024 27.7071 22.8351C27.8946 22.6462 28 22.39 28 22.1229V6.00723C28 5.7401 27.8946 5.4839 27.7071 5.29501C27.5196 5.10612 27.2652 5 27 5H5C4.73478 5 4.48043 5.10612 4.29289 5.29501C4.10536 5.4839 4 5.7401 4 6.00723V26.0007C4.0015 26.1912 4.05658 26.3773 4.15886 26.5375C4.26114 26.6977 4.40642 26.8255 4.57784 26.9059C4.74927 26.9863 4.93981 27.0161 5.12737 26.9918C5.31493 26.9675 5.49181 26.8902 5.6375 26.7688ZM11.6362 15.9874C12.2387 15.9874 12.7271 15.4954 12.7271 14.8886C12.7271 14.2817 12.2387 13.7898 11.6362 13.7898C11.0337 13.7898 10.5453 14.2817 10.5453 14.8886C10.5453 15.4954 11.0337 15.9874 11.6362 15.9874ZM15.9999 15.9874C16.6024 15.9874 17.0908 15.4954 17.0908 14.8886C17.0908 14.2817 16.6024 13.7898 15.9999 13.7898C15.3974 13.7898 14.909 14.2817 14.909 14.8886C14.909 15.4954 15.3974 15.9874 15.9999 15.9874ZM21.4544 14.8886C21.4544 15.4954 20.966 15.9874 20.3635 15.9874C19.761 15.9874 19.2726 15.4954 19.2726 14.8886C19.2726 14.2817 19.761 13.7898 20.3635 13.7898C20.966 13.7898 21.4544 14.2817 21.4544 14.8886Z"
        fill="currentColor"
      />
      <path
        d="M9.675 23.3694L5.6375 26.7688C5.49181 26.8902 5.31493 26.9675 5.12737 26.9918C4.93981 27.0161 4.74927 26.9863 4.57784 26.9059C4.40642 26.8255 4.26114 26.6977 4.15886 26.5375C4.05658 26.3773 4.0015 26.1912 4 26.0007L4 6.00723C4 5.7401 4.10536 5.4839 4.29289 5.29501C4.48043 5.10612 4.73478 5 5 5L27 5C27.2652 5 27.5196 5.10612 27.7071 5.29501C27.8946 5.4839 28 5.7401 28 6.00723V22.1229C28 22.39 27.8946 22.6462 27.7071 22.8351C27.5196 23.024 27.2652 23.1301 27 23.1301H10.3125C10.0784 23.1302 9.85209 23.2151 9.675 23.3694Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="19" y="16" width="13" height="13" rx="5.5" fill="#E0E0E0" />
      <text
        fill="#232323"
        xmlSpace="preserve"
        style={{ whiteSpace: 'pre' }}
        fontFamily="Roboto"
        fontSize="13"
        fontWeight="600"
        letterSpacing="0px"
      >
        <tspan x="21.5" y="26.5508">
          {number}
        </tspan>
      </text>
    </SC.Svg>
  )
}

export default NotificationIcon
