import React, { FC } from 'react'

import { ForumVariant } from '../../types/forumVariant'

import * as SC from './styled'

export type DotProps = {
  className?: string
  variant?: ForumVariant
  little?: boolean
}

const Dot: FC<DotProps> = ({ className, variant, little }) => {
  return (
    <SC.DotContainer className={className}>
      <SC.Dot variant={variant} $little={little} />
    </SC.DotContainer>
  )
}

export default Dot
