import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const collectionUpdate = getMutationService({
  mutation: gql(`
    mutation collectionUpdate($collectionId: ID!, $input: CollectionUpdateInput!) {
        collectionUpdate(collectionId: $collectionId, input: $input) {
            id
            exhibitor {
                ebmsId
                name
            }
            stand
            comment
            note
            samplesToReturn
            createdAt
            updatedAt
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
