import React, { FC } from 'react'

import { FiltersProps } from '../Filters'
import { FilterTagProps } from '../FilterTag'
import { ActionButtonProps } from '../ActionButton'
import { Icons } from '../Icon'
import { ActionButtonScheme } from '../ActionButton/types'

import * as SC from './styled'

export type FiltersTagsProps = {
  className?: string
  filters: FiltersProps
  tags: Array<FilterTagProps>
  button: ActionButtonProps
}

const FiltersTags: FC<FiltersTagsProps> = ({ className, filters, tags, button }) => {
  return (
    <SC.Container className={className}>
      <SC.TagList>
        {tags.map((tag, idx) => (
          <SC.Tag key={`tag-${idx}`} {...tag} />
        ))}
      </SC.TagList>
      <SC.Button
        {...button}
        variantScheme={ActionButtonScheme.Link}
        rightIconProps={{ icon: Icons.caretRight, width: 9, height: 9 }}
      />
      <SC.ModalFilters {...filters} />
    </SC.Container>
  )
}

export default FiltersTags
