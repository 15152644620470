import styled from 'styled-components'

import SampleHistory from '../../components/SampleHistory'
import SimpleLoader from '../../components/SimpleLoader'

export const History = styled(SampleHistory)``

export const Loader = styled(SimpleLoader)`
  margin-left: 50%;
`
