import React from 'react'
import { RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectors } from '../../../redux'
import SampleDataTraineeTemplate from '../../../templates/SampleDataTrainee'
import { useSampleDataMaterialTemplateProps } from '../../../templates/SampleData/usePropsMaterial'

const ExponentSampleDataMaterial: React.FC<RouteProps> = () => {
  const form = useSelector(selectors.samples.sampleFormByUniverse)
  const templateProps = useSampleDataMaterialTemplateProps(
    'Composition',
    form.find((item) => item.miraklRef === 'material'),
    form.find((item) => item.miraklRef === 'performance_code')
  )

  return <SampleDataTraineeTemplate {...templateProps} />
}

export default ExponentSampleDataMaterial
