import { all, put, takeEvery } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'
import { Exhibition } from '../../graphql/generated/graphql'

export default class FiltersSagas {
  static *onGetExhibitionByApiId({
    payload,
  }: ReturnType<typeof actions.exhibitions.exhibitionByApiIdRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitions.queries.exhibitionByApiId, payload)

    if (rs.errors) {
      yield put(actions.exhibitions.exhibitionByApiIdError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitions.exhibitionByApiIdSuccess())
      yield put(actions.exhibitions.setExhibitions(rs.data.exhibitionByApiId as Exhibition))
      return
    }
  }

  static *onGetExhibitions({
    payload,
  }: ReturnType<typeof actions.exhibitions.getExhibitionsRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitions.queries.exhibitions, payload)

    if (rs.errors) {
      yield put(actions.exhibitions.getExhibitionsError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitions.getExhibitionsSuccess())
      yield put(actions.exhibitions.setSearchExhibitions(rs.data.exhibitions.data as Exhibition[]))
      return
    }
  }

  static *onGetSampleStats({
    payload,
  }: ReturnType<typeof actions.exhibitions.getSampleStatsRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitions.queries.sampleStats, payload)

    if (rs.errors) {
      yield put(actions.exhibitions.getSampleStatsError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitions.getSampleStatsSuccess())
      yield put(actions.exhibitions.setSampleStats(rs.data.sampleStats))
      return
    }
  }

  // eslint-disable-next-line require-yield
  static *onCreateExhibition({
    payload,
  }: ReturnType<typeof actions.exhibitions.createExhibitionRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitions.mutations.exhibitionCreate, payload)
    if (rs.errors) {
      yield put(actions.exhibitions.createExhibitionError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitions.createExhibitionSuccess())
      return
    }
  }

  static *onUpdateExhibition({
    payload,
  }: ReturnType<typeof actions.exhibitions.updateExhibitionRequest>) {
    const rs = yield* ApiSagas.call(services.exhibitions.mutations.exhibitionUpdate, payload)
    if (rs.errors) {
      yield put(actions.exhibitions.updateExhibitionError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.exhibitions.updateExhibitionSuccess())
      return
    }
  }

  static *listeners() {
    yield all([
      takeEvery(actions.exhibitions.exhibitionByApiIdRequest, this.onGetExhibitionByApiId),
      takeEvery(actions.exhibitions.getExhibitionsRequest, this.onGetExhibitions),
      takeEvery(actions.exhibitions.createExhibitionRequest, this.onCreateExhibition),
      takeEvery(actions.exhibitions.updateExhibitionRequest, this.onUpdateExhibition),
      takeEvery(actions.exhibitions.getSampleStatsRequest, this.onGetSampleStats),
    ])
  }
}
