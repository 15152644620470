import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const internalNoteDelete = getMutationService({
  mutation: gql(`
    mutation internalNoteDelete($internalNoteId: ID!) {
      internalNoteDelete(internalNoteId: $internalNoteId)
    }
    `),
  transformer: (response) => {
    return response
  },
})
