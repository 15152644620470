import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import { ActionButtonScheme, ActionButtonSize } from '../ActionButton/types'
import { Icons } from '../Icon'

import * as SC from './styled'

export type CollectionCommentProps = {
  className?: string
  title?: string | React.ReactNode
  text?: string
  note?: string | number
  noteMax?: string | number
  edit?: ActionButtonProps
}

const CollectionComment: FC<CollectionCommentProps> = ({
  className,
  title,
  text,
  note,
  noteMax = '5',
  edit,
}) => {
  return (
    <SC.Container className={className}>
      {title && (typeof title === 'string' ? <SC.Title tag="h5" text={title} /> : <title />)}
      {(title || text || note) && (
        <SC.Content>
          {text && <SC.Text>{text}</SC.Text>}
          {note && (
            <SC.Score>
              <strong>{note}</strong>
              {noteMax && ` / ${noteMax}`}
            </SC.Score>
          )}
        </SC.Content>
      )}
      {edit && (
        <SC.Edit
          variantSize={ActionButtonSize.Small}
          variantScheme={ActionButtonScheme.Tertiary}
          rightIconProps={{ icon: Icons.pencil, width: 16, height: 16 }}
          {...edit}
        />
      )}
    </SC.Container>
  )
}

export default CollectionComment
