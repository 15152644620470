import styled, { css, keyframes } from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'

import { ActionButtonScheme, ActionButtonSize } from './types'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const ActionButton = styled(Link)<{
  disabled?: boolean
  $rounded?: boolean
  $size?: ActionButtonSize
  $scheme?: ActionButtonScheme
  $isPending?: boolean
}>`
  // spacing
  --padding--h: ${({ $size, $rounded }) =>
    $rounded ? 0 : $size === ActionButtonSize.Small ? '1.2rem' : '1.6rem'};
  --padding--text: ${({ $size }) => ($size === ActionButtonSize.Small ? '0' : '0.8rem')};
  // size
  --size: ${({ $size }) =>
    $size === ActionButtonSize.Small
      ? '2.8rem'
      : $size === ActionButtonSize.Large
      ? '4.8rem'
      : '4.4rem'};
  // icon max dimensions
  --size--icon: calc(var(--size) / 2);
  // scheme
  ${({ $scheme, theme }) =>
    $scheme === ActionButtonScheme.Secondary
      ? css`
          --color: ${theme.colors.mineShaft};
          --color--hover: ${theme.colors.alabaster};
          --color--active: ${theme.colors.alabaster};
          --color--disabled: ${theme.colors.alabaster};
          --border-color: ${theme.colors.mineShaft};
          --border-color--hover: ${theme.colors.mineShaft};
          --border-color--active: ${theme.colors.mineShaft};
          --border-color--disabled: ${theme.colors.silver};
          --background-color: ${theme.colors.alabaster};
          --background-color--hover: ${theme.colors.mineShaft};
          --background-color--active: ${theme.colors.mineShaft};
          --background-color--disabled: ${theme.colors.silver};
        `
      : $scheme === ActionButtonScheme.Tertiary
      ? css`
          --color: ${theme.colors.tundora};
          --color--hover: ${theme.colors.alabaster};
          --color--active: ${theme.colors.alabaster};
          --color--disabled: ${theme.colors.alabaster};
          --border-color: ${theme.colors.concrete};
          --border-color--hover: ${theme.colors.mineShaft};
          --border-color--active: ${theme.colors.mineShaft};
          --border-color--disabled: ${theme.colors.silver};
          --background-color: ${theme.colors.concrete};
          --background-color--hover: ${theme.colors.mineShaft};
          --background-color--active: ${theme.colors.mineShaft};
          --background-color--disabled: ${theme.colors.silver};
        `
      : $scheme === ActionButtonScheme.White
      ? css`
          --color: ${theme.colors.mineShaft};
          --color--hover: ${theme.colors.mineShaft};
          --color--active: ${theme.colors.alabaster};
          --color--disabled: ${theme.colors.alabaster};
          --border-color: ${theme.colors.alabaster};
          --border-color--hover: ${theme.colors.mineShaft};
          --border-color--active: ${theme.colors.mineShaft};
          --border-color--disabled: ${theme.colors.silver};
          --background-color: ${theme.colors.alabaster};
          --background-color--hover: ${theme.colors.alabaster};
          --background-color--active: ${theme.colors.mineShaft};
          --background-color--disabled: ${theme.colors.silver};
        `
      : $scheme === ActionButtonScheme.Link
      ? css`
          --color: ${theme.colors.mineShaft};
          --color--hover: ${theme.colors.tundora};
          --color--active: ${theme.colors.tundora};
          --color--disabled: ${theme.colors.silver};
          --border-color: transparent;
          --border-color--hover: transparent;
          --border-color--active: transparent;
          --border-color--disabled: transparent;
          --background-color: transparent;
          --background-color--hover: transparent;
          --background-color--active: transparent;
          --background-color--disabled: transparent;
        `
      : $scheme === ActionButtonScheme.Transparent
      ? css`
          --color: ${theme.colors.mineShaft};
          --color--hover: ${theme.colors.alabaster};
          --color--active: ${theme.colors.alabaster};
          --color--disabled: ${theme.colors.alabaster};
          --border-color: ${theme.colors.mineShaft};
          --border-color--hover: ${theme.colors.mineShaft};
          --border-color--active: ${theme.colors.mineShaft};
          --border-color--disabled: ${theme.colors.silver};
          --background-color: transparent;
          --background-color--hover: ${theme.colors.mineShaft};
          --background-color--active: ${theme.colors.mineShaft};
          --background-color--disabled: ${theme.colors.silver};
        `
      : //default
        css`
          --color: ${theme.colors.alabaster};
          --color--hover: ${theme.colors.mineShaft};
          --color--active: ${theme.colors.alabaster};
          --color--disabled: ${theme.colors.alabaster};
          --border-color: ${theme.colors.mineShaft};
          --border-color--hover: ${theme.colors.mineShaft};
          --border-color--active: ${theme.colors.mineShaft};
          --border-color--disabled: ${theme.colors.silver};
          --background-color: ${theme.colors.mineShaft};
          --background-color--hover: ${theme.colors.alabaster};
          --background-color--active: ${theme.colors.mineShaft};
          --background-color--disabled: ${theme.colors.silver};
        `}
  ${({ $size, theme }) =>
    $size === ActionButtonSize.Small
      ? css`
          ${theme.textStyles.titleH7}
          font-weight: 700;
        `
      : theme.textStyles.textBold};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  min-height: var(--size);
  min-width: var(--size);
  padding-inline: var(--padding--h);
  padding-block: 0.4rem;
  border: 0.1rem solid var(--border-color);
  border-radius: var(--size);
  color: var(--color);
  background-color: var(--background-color);
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  text-decoration: none;
  &:hover:not(:disabled) {
    border-color: var(--border-color--hover);
    color: var(--color--hover);
    background-color: var(--background-color--hover);
  }
  &:active:not(:disabled) {
    border-color: var(--border-color--active);
    color: var(--color--active);
    background-color: var(--background-color--active);
  }
  &:disabled {
    border-color: var(--border-color--disabled);
    color: var(--color--disabled);
    background-color: var(--background-color--disabled);
  }
  ${({ disabled }) =>
    disabled === true &&
    css`
      &,
      &:hover:hover {
        border-color: var(--border-color--disabled);
        color: var(--color--disabled);
        background-color: var(--background-color--disabled);
      }
    `}
  // fucking exception
  ${({ $scheme }) =>
    $scheme === ActionButtonScheme.Link &&
    css`
      --padding--text: 0;
      min-height: 0;
      border-radius: 0;
      padding-block: 0;
      padding-inline: 0;
    `}
    ${(props) =>
    props?.$isPending &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`

export const Label = styled.span<{ $isTextOnly?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:not(:first-child):not(:last-child) {
    margin-inline-end: auto;
  }
  ${({ $isTextOnly }) =>
    $isTextOnly === true &&
    css`
      &:first-child {
        padding-left: var(--padding--text, 0);
      }
      &:last-child {
        padding-right: var(--padding--text, 0);
      }
    `}
`
export const ActionIcon = styled(Icon)`
  flex: 0 0 auto;
  max-width: var(--size--icon);
  max-height: var(--size--icon);
`
