import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type BurgerProps = {
  className?: string
  isOpen: boolean
  onClick?: () => void
}

const Burger: FC<BurgerProps> = ({ className, isOpen, onClick }) => {
  const lineProps = { $isOpen: isOpen }

  return (
    <SC.Burger className={cx('Burger', className)} onClick={onClick}>
      <SC.Line $first {...lineProps} />
      <SC.Line {...lineProps} />
      <SC.Line $last {...lineProps} />
    </SC.Burger>
  )
}

export default Burger
