import React, { FC } from 'react'

import ContentWrapper from '../../components/ContentWrapper'
import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { SearchBarProps } from '../../components/SearchBar'
import { LoungeCardProps } from '../../components/LoungeCard'
import SimpleLoader from '../../components/SimpleLoader'

import * as SC from './styled'

export interface LoungeList {
  year: string
  isCollapsibleOpen?: boolean
  noIcon?: boolean
  lounge: LoungeCardProps[]
}

export type LoungesTemplateProps = {
  layoutProps?: LayoutWithBottomActionsProps
  searchBar?: SearchBarProps
  title: string
  loungeList?: LoungeList[]
  pending?: boolean
}

const LoungesTemplate: FC<LoungesTemplateProps> = ({
  layoutProps,
  title,
  searchBar,
  loungeList,
  pending,
}) => {
  return (
    <LayoutWithBottomActions {...layoutProps}>
      <ContentWrapper maxWidth={'default'}>
        <SC.Title tag={'h2'} text={title} />
        <SC.Search {...searchBar} />
        {loungeList &&
          loungeList.map((item: LoungeList, key: number) => (
            <SC.Collapsible
              key={key}
              title={item.year}
              isCollapsibleOpen={item.isCollapsibleOpen}
              noIcon={item.noIcon}
            >
              {item.lounge.map((loungeItem: LoungeCardProps, index: number) => (
                <SC.StyledLoungeCard key={index} {...loungeItem} />
              ))}
            </SC.Collapsible>
          ))}
        {pending && (
          <SC.LoaderContainer>
            {' '}
            <SimpleLoader />
          </SC.LoaderContainer>
        )}
      </ContentWrapper>
    </LayoutWithBottomActions>
  )
}

export default LoungesTemplate
