import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const samplesCountBy = getQueryService({
  query: gql(`
    query samplesCountBy($universesEbmsIds: [ID!]!, $exhibitionsApiIds: [ID!], $forumsIds: [ID!], $pavilionsEbmsIds: [ID!], $countries: [String!], $flagItems: [ID!]){ 
        samplesCountBy(universesEbmsIds: $universesEbmsIds, exhibitionsApiIds: $exhibitionsApiIds, forumsIds: $forumsIds, pavilionsEbmsIds: $pavilionsEbmsIds, countries: $countries, flagItems: $flagItems){ 
            ebmsId 
            name 
            count 
        } 
    }
    `),
  transformer: (response) => {
    return response
  },
})
