import styled, { css } from 'styled-components'

import FormikForm from '../../form/FormikForm'
import FormFieldText from '../../form/fields/FormFieldInput'
import FormErrors from '../../form/FormErrors'
import FormFieldRadioGroup from '../../form/fields/FormFieldRadioGroup'
import { breakpoints } from '../../../theme'

const stylesLabels = css`
  ${({ theme }) => theme.textStyles.titleH3}
`

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  gap: 2.4rem;
  ${breakpoints.up('large')} {
    gap: 3.6rem;
  }
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  gap: 2.4rem;
  ${breakpoints.up('large')} {
    gap: 4.6rem;
  }
`
export const Fieldset = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  gap: 3.4rem;
  border: 0 none;
  padding: 0;
  margin: 0;
`
export const Legend = styled.p`
  ${stylesLabels}
  margin-bottom: 1rem;
`
export const FieldText = styled(FormFieldText)`
  width: 100%;
`
export const Radios = styled(FormFieldRadioGroup)`
  --vertical-space: 1.2rem;
  & > label {
    ${stylesLabels}
    margin-bottom: var(--vertical-space);
  }
  & > div {
    gap: var(--vertical-space);
  }
`
export const Errors = styled(FormErrors)``
