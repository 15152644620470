import styled, { css } from 'styled-components'

import { breakpoints } from '../../theme'

export type MaxWidth = 'default' | 'small'

export const Container = styled.div<{ $maxWidth: MaxWidth; $noMargin: boolean | undefined }>`
  flex-grow: 0;
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;

  ${({ $maxWidth }) => {
    switch ($maxWidth) {
      case 'default':
        return css`
          max-width: 100vw;
          ${breakpoints.up('tablet')} {
            max-width: 77rem;
          }
        `
      case 'small':
        return css`
          max-width: 100vw;
          ${breakpoints.up('tablet')} {
            max-width: 35rem;
          }
        `
    }
  }};
  ${({ $noMargin }) => {
    switch ($noMargin) {
      case false:
        return css`
          margin: 0 auto;
          ${breakpoints.up('tablet')} {
            margin: 0 auto;
          }
        `
      case true:
        return css`
          margin: 0 auto;
          ${breakpoints.up('tablet')} {
            margin: inherit;
          }
        `
    }
  }};
`
