import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import { FormFieldInputProps } from '../../form/fields/FormFieldInput'
import { FormSubmitButtonProps } from '../../form/FormSubmitButton'
import FormikField from '../../form/FormikField'
import { FormErrorsProps } from '../../form/FormErrors'
import { Icons } from '../../Icon'

import * as SC from './styled'

export type ForgottenPasswordFormValues = {
  email: string
}

export type ForgottenPasswordFormProps = {
  className?: string
  formikForm: FormikFormProps<ForgottenPasswordFormValues>
  fields: {
    email: FormFieldInputProps
  }
  errors?: FormErrorsProps['errors']
  submitButton: FormSubmitButtonProps
}

const ForgottenPasswordForm: FC<ForgottenPasswordFormProps> = ({
  className,
  formikForm,
  fields,
  submitButton,
  errors,
}) => {
  return (
    <SC.Form className={className} {...formikForm}>
      <SC.Fields>
        <FormikField
          {...fields.email}
          type="email"
          hasFloatingLabel={true}
          Component={SC.FieldText}
        />
      </SC.Fields>
      {errors && <SC.Errors errors={errors} />}
      <SC.SubmitButton
        {...submitButton}
        rightIconProps={{ icon: Icons.arrowRight, width: 22, height: 22 }}
      />
    </SC.Form>
  )
}

export default ForgottenPasswordForm
