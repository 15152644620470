import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectors } from '../redux'
import { router, routesPath } from '../router'

interface GuestGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const isConnected = useSelector(selectors.auth.isConnected)

  if (isConnected) {
    return <Navigate to={router(routesPath.home)} />
  }

  return children
}

export default GuestGuard
