import styled from 'styled-components'

import { breakpoints } from '../../theme'
import Heading from '../../components/Heading'
import ContentWrapper from '../../components/ContentWrapper'
import TabsScrollable from '../../components/TabsScrollable'
import NumberedTag from '../../components/NumberedTag'
import LoungeCard from '../../components/LoungeCard'
import ActionButton from '../../components/ActionButton'

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-block: 1.2rem 2.4rem;
`
export const TopContent = styled(StyledContentWrapper)`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  ${breakpoints.up('tablet')} {
    padding-block: 3.6rem 2.4rem;
  }
`
export const CopyPastBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  transition: transform 200ms ease-in, background-color 150ms ease-in, color 150ms ease-in;
  &:hover {
    transform: scale(1.01);
  }
`
export const BottomContent = styled(StyledContentWrapper)`
  padding-block: 2.4rem;
`
export const IdContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StyledLoungeCard = styled(LoungeCard)`
  margin-bottom: 1rem;
  border-radius: 0px;
`

export const Title = styled(Heading)`
  margin: 0;
  line-break: anywhere;
  ${breakpoints.up('tablet')} {
  }
`
export const SampleId = styled.div`
  ${({ theme }) => theme.textStyles.titleH3}
`

export const Country = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.silver};
  &:not(:first-child) {
    margin-top: 0.2rem;
  }
  ${breakpoints.up('tablet')} {
  }
`
export const Button = styled(ActionButton)`
  position: relative;
  width: fit-content;
  margin-bottom: 20px;
  ${breakpoints.up('laptop')} {
    position: absolute;
    margin-bottom: 0px;
    right: 20px;
    bottom: 20px;
  }
`
export const Top = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpoints.up('laptop')} {
  }
`
export const StyledNumberedTag = styled(NumberedTag)``
export const Infos = styled.ul`
  ${({ theme }) => theme.textStyles.titleH5}
  list-style: none;
  margin: 0;
  padding: 0;
  &,
  & li {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  &:not(:first-child) {
    margin-top: 0.8rem;
  }
  & li:not(:first-child)::before {
    content: '';
    flex: 0 0 auto;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.mineShaft};
  }
`

export const Samplecategory = styled.div`
  ${({ theme }) => theme.textStyles.titleH4}
  padding: 10px 2.4rem;
  background: ${({ theme }) => theme.colors.concrete};
`

export const StyledTabs = styled(TabsScrollable)`
  // this is possibly garbage
  max-width: calc(100vw - var(--scrollbar-width, 0px));
  ${breakpoints.up('tablet')} {
    max-width: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`

export const Separator = styled.hr`
  align-self: stretch;
  border: 0;
  margin: 0;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.tundora};
  ${breakpoints.up('tablet')} {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`
