import * as Yup from 'yup'

import { ResetPasswordFormProps } from './index'

const basic: ResetPasswordFormProps = {
  formikForm: {
    initialValues: {
      password: '',
      terms: false,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?-_@#$%^&*])(?=.{12,})/,
          'Mot de passe incorrect'
        ),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password')], 'Les deux mots de passe saisis doivent être identiques')
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?-_@#$%^&*])(?=.{12,})/,
          'Mot de passe incorrect'
        )
        .test(
          'passwords-match',
          'Les deux mots de passe saisis doivent être identiques',
          function (value) {
            return value === this.parent.password
          }
        ),
    }),
    onSubmit: () => undefined,
    validateOnChange: true,
    validateOnMount: true,
  },
  rules: {
    length: {
      isValid: false,
      rule: '12 caractères minimum',
    },
    uppercase: {
      isValid: false,
      rule: 'Majuscules',
    },
    number: {
      isValid: false,
      rule: 'Chiffres',
    },
    specialCharacter: {
      isValid: false,
      rule: 'Caractères spéciaux',
    },
  },
  fields: {
    password: {
      name: 'password',
      label: 'Mot de passe',
      required: true,
    },
    passwordConfirm: {
      name: 'passwordConfirm',
      label: 'Confirmer mon mot de passe',
      required: true,
    },
  },
  texts: {
    passwordSectionTitle: "Création d'un nouveau mot de passe",
    passwordSectionText:
      'Le mot de passe doit faire 12 caractères minimum avec des majuscules, minuscules, chiffres et caractères spéciaux.',
  },
  submitButton: {
    text: 'Se connecter',
  },
}

const basicPartialValidated: ResetPasswordFormProps = {
  ...basic,
  rules: {
    ...basic.rules,
    length: {
      isValid: true,
      rule: '12 caractères minimum',
    },
    uppercase: {
      isValid: true,
      rule: 'Majuscules',
    },
  },
  submitButton: {
    ...basic.submitButton,
    disabled: true,
  },
}

const basicValidated: ResetPasswordFormProps = {
  ...basic,
  rules: {
    length: {
      isValid: true,
      rule: '12 caractères minimum',
    },
    uppercase: {
      isValid: true,
      rule: 'Majuscules',
    },
    number: {
      isValid: true,
      rule: 'Chiffre',
    },
    specialCharacter: {
      isValid: true,
      rule: 'Caractères spéciaux',
    },
  },
  submitButton: {
    ...basic.submitButton,
    disabled: false,
  },
}

export const resetPasswordFormMocks = {
  basic,
  basicPartialValidated,
  basicValidated,
}
