import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const getUsers = getQueryService({
  query: gql(`
    query users($q: String, $roles: [UserRole!], $first: Int!, $page: Int!) {
      users(q: $q, roles: $roles, first: $first, page: $page) {
        paginatorInfo {
          total
          perPage
          lastPage
          currentPage
        }
        data {
          id
          type
          role
          email
          firstName
          lastName
        }
      }
    }
    `),
  transformer: (response) => {
    return response
  },
})
