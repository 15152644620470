import styled, { css } from 'styled-components'

import BottomActions from '../../components/BottomActions'
import DefaultLayout from '../DefaultLayout'
import { breakpoints } from '../../theme'

export const DefaultLayoutStyled = styled(DefaultLayout)`
  ${breakpoints.up('tablet')} {
    width: 100%;
  }
`

export const Actions = styled(BottomActions)<{ $minorPadding: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 2rem 2rem;
  box-sizing: border-box;
  ${breakpoints.up('tablet')} {
    left: 32rem;
  }
  ${({ $minorPadding }) =>
    $minorPadding === true &&
    css`
      padding-left: 5px;
      padding-bottom: 2rem;
      ${breakpoints.up('tablet')} {
        padding: 0 2rem 2rem;
      }
    `}
`
