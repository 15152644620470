import styled, { css } from 'styled-components'

export type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8'

export const Heading = styled.span<{ $variant: Variant; $withBorderBottom?: boolean }>`
  ${({ $variant, theme }) => {
    switch ($variant) {
      case 'h1':
        return css`
          ${theme.textStyles.titleH1}
        `
      case 'h2':
        return css`
          ${theme.textStyles.titleH2}
          margin-top: 2.4rem;
        `
      case 'h3':
        return css`
          ${theme.textStyles.titleH3}
        `
      case 'h4':
        return css`
          ${theme.textStyles.titleH4}
        `
      case 'h5':
        return css`
          ${theme.textStyles.titleH5}
        `
      case 'h6':
        return css`
          ${theme.textStyles.titleH6}
        `
      case 'h7':
        return css`
          ${theme.textStyles.titleH7}
        `
      case 'h8':
        return css`
          ${theme.textStyles.titleH8}
        `
    }
  }};
`
