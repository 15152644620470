import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import ActionButton from '../ActionButton'
import Icon, { Icons } from '../Icon'
import { SampleStatus } from '../../graphql/generated/graphql'
import Warning from '../Warning'

const TopTagStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.6rem 1rem;
  text-align: right;
  font-weight: 700;
`

export const Dot = styled.span`
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  background-color: currentColor;
  border-radius: 50%;
`

export const Deleted = styled(Warning)`
  margin-bottom: 12px;
`

export const ForumSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
`

export const Card = styled.div<{ $selected?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 2px 2px 34px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  ${({ $selected }) =>
    $selected &&
    css`
      opacity: 0.5;
    `};
`

export const Top = styled.div``

export const Bottom = styled.div`
  display: flex;
  gap: 1.2rem;
  margin-top: auto;
`

export const Forum = styled.div<{ $color?: string | null }>`
  ${TopTagStyle}
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
      background-color: ${rgba($color, 0.15)};
    `};
`
export const MkpForum = styled.div<{ $okMkp?: boolean; $noMkp?: boolean }>`
  ${TopTagStyle}
  ${({ $okMkp, $noMkp }) => {
    if ($noMkp) {
      return css`
        ${({ theme }) => css`
          color: ${theme.colors.mineShaft};
          background-color: ${theme.colors.red};
        `};
      `
    }

    switch ($okMkp) {
      case false:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.mineShaft};
            background-color: ${theme.colors.concrete};
          `};
        `
      case true:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.chateauGreen};
            background-color: ${theme.colors.appleGreen};
          `};
        `
    }
  }};
`

export const Status = styled.span<{ $status?: SampleStatus }>`
  ${TopTagStyle}
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: 700;
  ${({ $status }) => {
    switch ($status) {
      case SampleStatus.Deleted:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.cinnabar};
            background-color: ${rgba(theme.colors.cinnabar, 0.15)};
          `};
        `
        break
      case SampleStatus.SelectedOkShooting:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.selectiveTextYellow};
            background-color: ${rgba(theme.colors.selectiveTextYellowDark, 0.15)};
          `};
        `
      case SampleStatus.Selected:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.selectiveTextYellow};
            background-color: ${rgba(theme.colors.selectiveTextYellowDark, 0.15)};
          `};
        `
      case SampleStatus.Validated:
      case SampleStatus.ValidatedChecked:
      case SampleStatus.ValidatedLabelled:
      case SampleStatus.ValidatedOkMarketplace:
        return css`
          ${({ theme }) => css`
            color: ${theme.colors.chateauGreen};
            background-color: ${rgba(theme.colors.chateauGreen, 0.15)};
          `};
        `
    }
  }};
`

export const Number = styled.span`
  ${({ theme }) => theme.textStyles.titleH5};
  color: ${({ theme }) => theme.colors.gray};
  text-transform: uppercase;
  min-width: 30px;
`

export const Name = styled.span`
  ${({ theme }) => theme.textStyles.titleH5};
  color: ${({ theme }) => theme.colors.gray};
  text-transform: uppercase;
`

export const Category = styled.span`
  display: block;
  ${({ theme }) => theme.textStyles.titleH4};
  text-transform: uppercase;
  padding: 1.2rem 0;
  margin-top: 1.2rem;
  border-top: 1.5px solid ${({ theme }) => theme.colors.mineShaft};
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.mineShaft};
`

export const Image = styled.img`
  max-width: 100px;
  max-height: 80px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
export const BlockImage = styled.div`
  flex: 1;
`
export const Description = styled.div`
  display: flex;
  gap: 0.6rem;
`
export const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  overflow-wrap: anywhere;
`

export const EditButton = styled(ActionButton)`
  margin-top: 1.2rem;
  align-self: flex-end;
`

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gray};
  position: absolute;
  top: 3px;
  right: 3px;
  transform: translate(50%, -50%);
  border: none;
  cursor: pointer;
  &:hover {
    transition: transform 200ms ease-in, background-color 150ms ease-in, color 150ms ease-in;
    background: ${({ theme }) => theme.colors.tundora};
  }
`
export const DeleteIcon = styled(Icon).attrs({ icon: Icons.close, width: 25, height: 25 })`
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const SelectIcon = styled(Icon).attrs({ icon: Icons.close, width: 32, height: 32 })`
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const SelectModeIcon = styled(Icon).attrs({ icon: Icons.plus, width: 25, height: 25 })`
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const MarketplaceWarning = styled.div`
  margin-bottom: 12px;
`

export const DaysLeftMarketplace = styled.span`
  padding: 8px 12px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.redLight};
  ${({ theme }) => theme.textStyles.titleH4};
`
export const TextLeftMarketplace = styled.span`
  padding: 8px 12px 8px 6px;
  ${({ theme }) => theme.textStyles.titleH5};
`
