import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const configurationUpdate = getMutationService({
  mutation: gql(`
    mutation configurationUpdate($noDaysBeforeSendingToMarketplace: Int) {
        configurationUpdate(noDaysBeforeSendingToMarketplace: $noDaysBeforeSendingToMarketplace) {
            exhibitionActiveApiId
            noDaysBeforeSendingToMarketplace
            dictionaries {
                type
                items {
                    key
                    value
                }
            }
            azure {
                loginUrl
                logoutUrl
            }
        }
    }
    `),
  transformer: (response) => {
    return response
  },
})
