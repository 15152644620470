import React, { FC } from 'react'

import Dot from '../Dot'
import { IconProps } from '../Icon'

import * as SC from './styled'

export type variant =
  | 'default'
  | 'validate'
  | 'delete'
  | 'leather'
  | 'sourcing'
  | 'accessories'
  | 'inspiration'
  | 'design'
  | 'forumSelect'
  | 'bursted'

export type StatutSelectionProps = {
  text: string
  secondText?: string
  radioName?: string
  checkBox?: boolean
  icon?: IconProps
  variant?: variant
  backgroundColor?: string
  color?: string
  selected?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  children?: React.ReactNode
}

const StatutSelection: FC<StatutSelectionProps> = ({
  text,
  secondText,
  radioName,
  variant,
  backgroundColor,
  color,
  selected,
  onClick,
  checkBox,
  icon,
  children,
}) => {
  return (
    <SC.StatutSelection
      variant={variant}
      $selected={selected}
      $backgroundColor={backgroundColor}
      $color={color}
      onClick={onClick}
      $checkBoxStyle={checkBox || !!icon}
    >
      <Dot variant={variant} />
      {text && radioName && (
        <SC.Radio label={text} secondLabel={secondText} variant={variant} radioName={radioName} />
      )}
      {text && checkBox && <>{children}</>}
      {text && icon && (
        <SC.IconContainer>
          <SC.TextContainer className="text-content">{text}</SC.TextContainer>
          <SC.StyledIcon {...icon} />
        </SC.IconContainer>
      )}
    </SC.StatutSelection>
  )
}

export default StatutSelection
