import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  height: 177px;
`

export const Column = styled.div<{ isBig?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 100%;
  flex: ${({ isBig }) => (isBig ? '2' : '1')};
`

export const Image = styled.img<{ isBig?: boolean }>`
  height: ${({ isBig }) => (isBig ? '100%' : '50%')};
  width: 100%;
  object-fit: cover;
`

export const ImageBasis = styled.img<{ numImages: number }>`
  flex-basis: calc(100% / ${({ numImages }) => numImages});
  width: calc(100% / ${({ numImages }) => numImages});
  object-fit: cover;
  box-sizing: border-box;
`
