import React, { FC } from 'react'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type ColoredLinkBlockProps = LinkProps & {
  className?: string
  colorVariant: SC.ColorVariant
  forceColor?: string
  selected?: boolean
}

const ColoredLinkBlock: FC<ColoredLinkBlockProps> = ({
  className,
  colorVariant,
  forceColor,
  selected,
  ...link
}) => {
  return (
    <SC.ColoredLinkBlock
      className={className}
      $colorVariant={colorVariant}
      $forceColor={forceColor}
      $selected={selected}
      {...link}
    />
  )
}

export default ColoredLinkBlock
