import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import { useLayoutLoungeConfigTemplateProps } from '../../layouts/LayoutLoungeConfig/useProps'
import { router, routesPath } from '../../router'
import { ActionButtonScheme } from '../../components/ActionButton/types'
import { Icons } from '../../components/Icon'
import { actions, selectors } from '../../redux'
import { Exhibition, ExhibitionForum, SampleStatus } from '../../graphql/generated/graphql'
import { LoungeNewForumFormValues } from '../../components/forms/LoungeNewForumForm'
import { LoungeForumChoiceFormValues } from '../../components/forms/LoungeForumChoice'
import { getContrastColor } from '../../relay/Forums/getContrastColor'

import { LoungesForumChoiceTemplateProps } from '.'

export const useLoungeForumsChoiceTemplateProps = (): LoungesForumChoiceTemplateProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const layoutLoungeProps = useLayoutLoungeConfigTemplateProps()
  const loungeConfig = useSelector(selectors.exhibitions.exhibitionConfig)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isValidEdit, setIsValidEdit] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [selectedForumEdit, setSelectedForumEdit] = useState<ExhibitionForum | undefined>(undefined)
  const [selectedForum, setSelectedForum] = useState<string[] | undefined>(undefined)
  const ref = useRef<FormikProps<LoungeForumChoiceFormValues>>(null)
  const refEdit = useRef<FormikProps<LoungeNewForumFormValues>>(null)
  const sampleStats = useSelector(selectors.exhibitions.sampleStats)

  const [background, setBackground] = useState<string>('#ffff')
  const [selectOption, setSelectOption] = useState<string>('Hex')

  const handleBackgroundInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const colorValue = e.target.value
    setBackground && setBackground(colorValue)
  }

  const handleSelectOptionInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue = e.target.value
    setSelectOption && setSelectOption(selectValue)
  }

  const handleBackgroundChange = (color: string) => {
    setBackground && setBackground(color)
  }

  useEffect(() => {
    if (selectedForumEdit) {
      setBackground(selectedForumEdit.color as string)
    }
  }, [selectedForumEdit])

  useEffect(() => {
    loungeConfig &&
      selectedForumEdit &&
      dispatch(
        actions.exhibitions.getSampleStatsRequest({
          exhibitionApiId: loungeConfig.apiId as string,
          forumId: Number(selectedForumEdit.id),
        })
      )
  }, [dispatch, loungeConfig, selectedForumEdit])

  useEffect(() => {
    //@ts-ignore
    const selectedForums = loungeConfig && loungeConfig.forums.filter((forum) => forum.selected)
    const selectedForumNames = selectedForums && selectedForums.map((forum) => forum.id)
    setSelectedForum(selectedForumNames)
  }, [loungeConfig])

  useEffect(() => {
    selectedForum &&
      ref.current &&
      ref.current.setValues({
        forumsSelected: selectedForum,
      })
  }, [selectedForum])

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutLoungeProps,
        bottomActions: {
          ...layoutLoungeProps.bottomActions,
          next: !editMode
            ? {
                ...layoutLoungeProps.bottomActions?.next,
                disabled: !isValid,
                onClick: () => {
                  if (ref) ref.current?.submitForm()
                },
              }
            : {
                text: 'Valider',
                disabled: !isValidEdit,
                rightIconProps: {
                  icon: Icons.check,
                  width: 18,
                  height: 18,
                },
                onClick: () => {
                  refEdit.current?.submitForm()
                },
              },
          back: !editMode
            ? {
                ...layoutLoungeProps.bottomActions?.back,
                onClick: () => {
                  const updatedLoungeConfig = {
                    ...loungeConfig,
                    forums: [],
                  }
                  dispatch(
                    actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition)
                  )

                  const link = router(routesPath.loungesForums)
                  navigate(link)
                },
              }
            : {
                leftIconProps: {
                  icon: Icons.arrowLeft,
                  width: 18,
                  height: 18,
                },
                rounded: true,
                onClick: () => {
                  if (selectedForumEdit) setSelectedForumEdit(undefined)
                  else setEditMode(false)
                },
              },
        },
        deleteModal: {
          isOpen: isDeleteModalOpen,
          onClose: () => setIsDeleteModalOpen(false),
          textBold:
            sampleStats &&
            sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Selected)?.count !==
              0
              ? 'Attention, ce forum a déjà été attribué à des échantillons présélectionnés.'
              : undefined,
          text:
            sampleStats &&
            sampleStats.find((sampleStat) => sampleStat.status === SampleStatus.Selected)?.count !==
              0
              ? "En supprimant ce forum, l'affectation de l'échantillon sera supprimée également"
              : 'Vous êtes sur le point de supprimer le forum du salon. Confirmez ou annulez la suppression.',
          cancelButton: { text: 'Annuler', onClick: () => setIsDeleteModalOpen(false) },
          deleteButton: {
            text: 'Oui, supprimer',
            onClick: () => {
              if (selectedForumEdit) {
                const newArray = loungeConfig?.forums.filter(
                  (item) => item.id !== selectedForumEdit.id
                )
                const updatedLoungeConfig = {
                  ...loungeConfig,
                  forums: newArray,
                }
                dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
                setSelectedForumEdit(undefined)
                setEditMode(false)
                setIsDeleteModalOpen(false)
              }
            },
          },
        },
      },
      title: 'Les forums',
      subTitle: 'Sélectionnez les forums',
      editButtonProps: {
        text: 'Editer',
        variantScheme: ActionButtonScheme.Secondary,
        rightIconProps: { icon: Icons.pencil, height: 18, width: 18 },
        onClick: () => setEditMode(true),
      },
      newButtonProps: {
        text: 'Nouveau forum',
        variantScheme: ActionButtonScheme.Secondary,
        rightIconProps: { icon: Icons.plus, height: 18, width: 18 },
        onClick: () => {
          setEditMode(true)
          setSelectedForumEdit({ id: 'new', name: '', subForums: [], createdAt: '', updatedAt: '' })
        },
      },
      loungeConfigFormProps: {
        formikForm: {
          innerRef: ref,
          initialValues: {
            forumsSelected: selectedForum ? selectedForum : [''],
          },
          validationSchema: Yup.object().shape({
            forumsSelected: Yup.array()
              .min(1, 'Vous devez sélectionner au moins une option')
              .required(),
          }),
          onContextUpdate(context) {
            setIsValid(context.isValid)
          },
          onSubmit: (values) => {
            const newArray = loungeConfig?.forums.map((item) => ({
              ...item,
              selected: values.forumsSelected.includes(item.id),
            }))
            const updatedLoungeConfig = {
              ...loungeConfig,
              forums: newArray,
            }
            dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
            const link = router(routesPath.loungesSubForumsChoice)
            navigate(link)
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          forumsSelected: {
            name: 'forumsSelected',
            required: true,
            options:
              loungeConfig && loungeConfig.forums
                ? loungeConfig.forums.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                    forceBackgroundColor: getContrastColor(item.color as string),
                    color: item.color,
                    display: 'withDot',
                  }))
                : [],
          },
        },
      },
      editIconProps: { icon: Icons.pencil, height: 22, width: 22, color: '#464646' },
      editMode: editMode,
      setSelectedForum: (forumId) => {
        const forum = loungeConfig && loungeConfig.forums.find((item) => item.id === forumId)
        setSelectedForumEdit(forum)
      },
      selectedForum: selectedForumEdit
        ? {
            selected: true,
            forum: selectedForumEdit,
          }
        : undefined,
      loungesNewForum: {
        textFieldTitle: 'Nom du forum',
        formikForm: {
          innerRef: refEdit,
          initialValues: {
            forumName: selectedForumEdit?.name,
          } as LoungeNewForumFormValues,
          validationSchema: Yup.object().shape({
            forumName: Yup.string()
              .required()
              .test(
                'unique-subforum',
                'Un forum porte le même nom, Veuillez corriger le nom du forum.',
                function (value) {
                  if (!value) {
                    return true
                  }
                  if (value === selectedForumEdit?.name) return true
                  else {
                    const matchingSubForum =
                      loungeConfig?.forums && value
                        ? loungeConfig.forums.find(
                            (forum) => forum.name.toLowerCase() === value.toLowerCase()
                          )
                        : false

                    return !matchingSubForum
                  }
                }
              ),
          }),
          onSubmit: (values) => {
            if (selectedForumEdit && selectedForumEdit.name === '') {
              const newForum = {
                name: values.forumName,
                color: background,
                id:
                  loungeConfig && loungeConfig?.forums.length > 0
                    ? `new${loungeConfig?.forums.length.toString()}`
                    : 'new0',
                selected: false,
              }
              const updatedLoungeConfig = {
                ...loungeConfig,
                forums: loungeConfig?.forums ? [...loungeConfig.forums, newForum] : [newForum],
              }
              dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
            }
            if (selectedForumEdit && selectedForumEdit.name !== '') {
              const updatedForums = loungeConfig?.forums
                ? loungeConfig.forums.map((forum) =>
                    forum.id === selectedForumEdit.id
                      ? { ...forum, name: values.forumName, color: background }
                      : forum
                  )
                : []
              const updatedLoungeConfig = {
                ...loungeConfig,
                forums: updatedForums,
              }
              dispatch(actions.exhibitions.setExhibitionConfig(updatedLoungeConfig as Exhibition))
            }
            setSelectedForumEdit(undefined)
            setEditMode(false)
          },
          onContextUpdate(context) {
            setIsValidEdit(context.isValid)
          },
          validateOnChange: true,
          validateOnMount: true,
        },
        fields: {
          forumName: {
            name: 'forumName',
            required: true,
          },
        },
        colorPickerProps: {
          selectOptions: [
            { value: 'Hex', label: 'Hex' },
            { value: 'Rgb', label: 'Rgb' },
          ],
          title: 'Code couleur',
          background: background,
          selectOption: selectOption,
          handleBackgroundInputChange: handleBackgroundInputChange,
          handleSelectOptionInputChange: handleSelectOptionInputChange,
          handleBackgroundChange: handleBackgroundChange,
        },
        deleteButtonProps:
          selectedForumEdit && selectedForumEdit.name !== ''
            ? {
                text: 'Supprimer',
                onClick: () => {
                  setIsDeleteModalOpen(true)
                },
              }
            : undefined,
      },
    }
  }, [
    background,
    dispatch,
    editMode,
    isDeleteModalOpen,
    isValid,
    isValidEdit,
    layoutLoungeProps,
    loungeConfig,
    navigate,
    sampleStats,
    selectOption,
    selectedForum,
    selectedForumEdit,
  ])
}
