import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const editorialAttachSamples = getMutationService({
  mutation: gql(`
		mutation editorialAttachSamples($editorialId: ID!, $samplesIds: [ID!]!) {
		editorialAttachSamples(editorialId: $editorialId, samplesIds: $samplesIds) {
			id
			name
			description
			exhibition {
				id
			}
			samples {
				id
				name
				status
			}
		}
	}
    `),
  transformer: (response) => {
    return response
  },
})
