import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Icons } from '../../components/Icon'
import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { router, routesPath } from '../../router'

import { ParamsTemplateProps } from '.'

export const useParamsMailsTemplateProps = (): ParamsTemplateProps => {
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const navigate = useNavigate()

  return useMemo(() => {
    return {
      layoutProps: {
        ...layoutProps,
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            onClick: () => {
              const link = router(routesPath.params)
              navigate(link)
            },
            rounded: true,
          },
        },
      },
      title: 'Emails automatiques',
      iconProps: {
        icon: Icons.arrowRight,
        width: 20,
      },
      inputList: [
        { text: 'Réception colis', href: '/parametres/emails-automatiques/edition/parcels' },
        {
          text: 'Mise en ligne marketplace',
          href: '/parametres/emails-automatiques/edition/marketplace',
        },
      ],
    }
  }, [layoutProps, navigate])
}
