import React, { useEffect, useState } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import ExponentIndexTemplate from '../../templates/ExponentIndex'
import { actions, selectors } from '../../redux'
import { findTagsFiltersInDictionaries } from '../../components/Filters/utils'
import { useFiltersTemplateProps } from '../../templates/Filters/useProps'
import { SimpleCardProps } from '../../components/SimpleCard'
import { Exhibitor } from '../../graphql/generated/graphql'
import { useExponentSearchProps } from '../../templates/ExponentSearch/useProps'
import Filters from '../../components/Filters'
import { router, routesPath } from '../../router'
import { FiltersFormValues } from '../../components/forms/FiltersForm'
import { FilterTagProps } from '../../components/FilterTag'

const Exponent: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const exhibitors = useSelector(selectors.exhibitors.exhibitors)
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)
  const exhibitorsSearch = useSelector(selectors.exhibitors.exhibitorsSearch)
  const config = useSelector(selectors.configuration.configurationInfo)
  const sampleFilters = useSelector(selectors.samples.sampleFilters)

  const [filters, setFilters] = useState<FiltersFormValues | undefined>(undefined)
  const [selectedTab, setSelectedTab] = useState<boolean>(false)
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [parsedExhibitors, setParsedExhibitors] = useState<SimpleCardProps[] | undefined>(undefined)
  const [allExhibitors, setAllExhibitors] = useState<Exhibitor[]>([])
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [tags, setTags] = useState<Array<FilterTagProps>>([])
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [universe, setUniverse] = useState<string | undefined>(undefined)

  const handleTabClick = (value: boolean) => {
    setSelectedTab(value)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof event === 'string') {
      setSearch(event)
    } else {
      setSearch(event.target.value)
    }
  }

  const handleFiltersClick = () => {
    setOpenFilters(!openFilters)
  }

  const exponentTemplateProps = useExponentSearchProps({
    handleTabClick,
    handleFiltersClick,
    parsedExhibitors,
    tags,
    search,
    handleSearchChange,
    selectedTab,
    setParsedExhibitors,
    exhibitors,
    allExhibitors,
    currentPage,
    setAllExhibitors,
  })
  const filterstemplateProps = useFiltersTemplateProps({
    tags,
    setTags,
    handleFiltersClick,
    openFilters,
    setUniverse,
    universe,
    isExponent: true,
    setFilters,
  })

  useEffect(() => {
    dispatch(actions.samples.resetSampleData())
    dispatch(actions.samples.resetSampleDataAllInfo())
  }, [dispatch])

  useEffect(() => {
    if (exhibitorsSearch && !exhibitorsSearch.pending && config) {
      const filterParsedCall = findTagsFiltersInDictionaries(tags, config?.dictionaries)
      const countries = filterParsedCall.find((item) => item.type === 'COUNTRIES')?.filters || []
      const universesEbmsIds =
        filterParsedCall.find((item) => item.type === 'UNIVERSES')?.filters || []
      const pavilionsEbmsIds =
        filterParsedCall.find((item) => item.type === 'PAVILIONS')?.filters || []

      dispatch(
        actions.exhibitors.exhibitorsServiceRequest({
          q: search,
          universesEbmsIds,
          pavilionsEbmsIds,
          countries,
          exhibitionsApiIds: [config.exhibitionActiveApiId.toString()],
          first: 16,
          page: currentPage,
        })
      )
    } else {
      setAllExhibitors([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    search,
    currentPage,
    tags,
    universe,
    config?.exhibitionActiveApiId,
    config,
    sampleFilters,
    filters,
  ])

  useEffect(() => {
    let scrollTimeout: any
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        if (scrollTimeout) {
          clearTimeout(scrollTimeout)
        }
        scrollTimeout = setTimeout(() => {
          setCurrentPage((prevPage) => prevPage + 1)
        }, 500)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(scrollTimeout)
    }
  }, [])

  useEffect(() => {
    setParsedExhibitors(undefined)
    setCurrentPage(1)
    setAllExhibitors([])
  }, [search, tags])

  // Redirect & reset exhibitor

  useEffect(() => {
    if (exhibitor && !isFirstRender) {
      setShouldRedirect(true)
    }
  }, [exhibitor, isFirstRender])

  useEffect(() => {
    if (shouldRedirect) {
      const exposantId = exhibitor?.ebmsId
      const link = router(routesPath.exponentSheet, {
        exposantId,
        apiId: config?.exhibitionActiveApiId.toString(),
      })
      navigate(link)
      setShouldRedirect(false)
    }
  }, [shouldRedirect, navigate, exhibitor?.ebmsId, config?.exhibitionActiveApiId])

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    }
  }, [isFirstRender])

  useEffect(() => {
    dispatch(actions.exhibitors.resetExhibitors())
  }, [dispatch, search, tags])

  return (
    <>
      <ExponentIndexTemplate
        {...exponentTemplateProps}
        title="Exposants"
        searchBar={{
          value: search,
          onChange: handleSearchChange,
        }}
      />
      <Filters {...filterstemplateProps} isOpen={openFilters} onClose={handleFiltersClick} />
    </>
  )
}

export default Exponent
