import { gql } from '@apollo/client'

export const UserFragment = gql(`
  fragment UserFragment on User {
    id
    type
    role
    firstName
    lastName
    email
    lastLoginAt
    externalAccessFrom
    externalAccessTo
    createdAt
    updatedAt
  }
`)
