import { getMutationService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const collectionParcelUpdate = getMutationService({
  mutation: gql(`
    mutation collectionParcelUpdate(
        $parcelId: ID!
        $samplesTotal: Int!
        $samplesDistinct: Int!
    ) {
        collectionParcelUpdate(
            parcelId: $parcelId
            samplesTotal: $samplesTotal
            samplesDistinct: $samplesDistinct
        ) {
            id
            isArchived
            exhibition {
                apiId
                seasonCode
                seasonYear
                name
                dateStart
                dateEnd
            }
            exhibitor {
                ebmsId
                name
                country
                countryCode
                isNew
                universe {
                    ebmsId
                    name
                }
                pavilion {
                    ebmsId
                    name
                }
                contacts {
                    apiId
                    firstName
                    lastName
                    email
                    phone1
                    phone2
                    languageCode
                    language
                    accountTypes
                    isAccountTypeFashion
                    isAccountTypeMarketplace
                    updatedAt
                }
            }
            stand
            comment
            note
            samplesToReturn
            samplesStats {
                status
                count
            }
            samples {
                id
			    isDeleted
                collectionId
                universeEbmsId
                statusUpdatedAt
                marketplaceImported
                flagItems {
                    id
                    group {
                        id
                        miraklRef
                    }
                    name
                }
                photos {
                    id
                    user {
                        id
                        email
                    }
                    url
                    path
                    order
                }
                name
                forum {
                    id
                    name
                    color
                }
                subForum {
                    id
                    name
                }
                mkpForum
                status
                updatedAt
            }
            parcels {
                id
                user {
                    id
                    firstName
                    lastName
                }
                name
                samplesTotal
                samplesDistinct
                createdAt
            }
            internalNotes {
                id
                user {
                    id
                    firstName
                    lastName
                }
                body
                writtenAt
            }
            createdAt
            updatedAt
        }
    }

    `),
  transformer: (response) => {
    return response
  },
})
