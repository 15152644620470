import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'

import { actions, selectors } from '../../redux'
import { loginFormMocks } from '../../components/forms/LoginForm/mocks'
import { Icons } from '../../components/Icon'

import { LoginTemplateProps } from '.'
import { loginTemplateMocks } from './mocks'

export const useLoginTemplateProps = (): LoginTemplateProps => {
  const dispatch = useDispatch()

  const login = useSelector(selectors.auth.login)

  return useMemo(
    () => ({
      ...loginTemplateMocks.basic,
      loginForm: {
        ...loginFormMocks.basic,
        formikForm: {
          ...loginFormMocks.basic.formikForm,
          onSubmit: (values) => {
            dispatch(
              actions.auth.loginRequest({
                email: values.email,
                password: values.password,
              })
            )
          },
        },
        forgottenPasswordLink: {
          text: 'Mot de passe oublié ?',
        },
        errors: login?.errors || undefined,
        submitButton: {
          isPending: login?.pending,
          isSuccess: login?.success,
          rightIconProps: { icon: Icons.arrowRight, width: 22, height: 22 },
          text: 'Se connecter',
          pendingText: 'Pending...',
        },
      },
    }),
    [dispatch, login?.errors, login?.pending, login?.success]
  )
}
