import styled, { css } from 'styled-components'
import { HuePicker } from 'react-color'

import { fonts } from '../../theme'
import FormFieldSelect from '../form/fields/FormFieldSelect'

export const ColorPickerContainer = styled.div`
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
`

export const Title = styled.div`
  font-size: 1.4rem;
  ${fonts.RobotoExtraLight};
  color: ${({ theme }) => theme.colors.gray};
`

export const PickerContainer = styled.div`
  border-radius: 0.4rem;
  padding: 3rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${({ theme }) => theme.colors.concrete};
`

export const Picker = styled(HuePicker)`
  max-width: 100% !important;
  width: auto !important;
  .hue-horizontal {
    border-radius: 11px !important;
  }
  .hue-horizontal > div:last-child > div {
    width: 26px !important;
    height: 26px !important;
    border-radius: 50% !important;
    transform: translate(-6px, -5px) !important;
    background-color: transparent !important;
    box-shadow: rgba(0, 0, 0, 0.37) 0px 1px 4px 0px !important;
    border: 6px solid ${({ theme }) => theme.colors.pureWhite} !important;
    border-top: 6px solid ${({ theme }) => theme.colors.pureWhite} !important;
  }
`

export const PickerPreview = styled(HuePicker)<{
  $backgroundColor: string
  $backgroundLightColor: string
}>`
  max-width: 100% !important;
  width: auto !important;
  .hue-horizontal {
    background: transparent !important;
  }
  .hue-horizontal > div:last-child > div {
    width: 36px !important;
    height: 41px !important;
    border-radius: 0% !important;
    transform: translate(-11px, -20px) !important;
    background-color: transparent !important;
    box-shadow: rgba(0, 0, 0, 0.37) 0px 1px 4px 0px !important;
    border: 2px solid ${({ theme }) => theme.colors.pureWhite} !important;
    ${({ $backgroundColor, $backgroundLightColor }) =>
      $backgroundColor &&
      $backgroundLightColor &&
      css`
        background-image: linear-gradient(
          to bottom,
          ${$backgroundColor} 50%,
          ${$backgroundLightColor} 50%
        ) !important;
      `};
  }
`

export const FieldsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.concrete};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 0.4rem;
`

export const HexInput = styled.input`
  font-size: 1rem;
  padding: 0.5rem 0rem;
  border: unset;
  width: 100%;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.tundora};
  background-color: ${({ theme }) => theme.colors.concrete};
  transition: border-color 0.2s ease-in-out;
  font-size: 1.4rem;
  ${fonts.RobotoExtraLight}
  &:focus {
    border-color: ${({ theme }) => theme.colors.tundora};
    outline: none;
  }
`

export const FieldSelect = styled(FormFieldSelect)`
  width: 100%;
  max-width: 7rem;
  select {
    padding: 0rem 2.4rem 0rem 0.5rem;
  }
`
