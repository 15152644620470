import styled, { css } from 'styled-components'

import Modal from '../Modal'
import ActionButton from '../ActionButton'
import Html from '../Html'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const TextBold = styled(Html)`
  ${({ theme }) => theme.textStyles.titleH3}
  margin-bottom: 2.4rem;
  font-weight: bold;
  & b,
  & strong {
    font-weight: 700;
  }
`

export const Text = styled(Html)<{ $lineBreak: boolean | undefined }>`
  ${({ theme }) => theme.textStyles.titleH3}
  margin-bottom: 2.4rem;
  & b,
  & strong {
    font-weight: 700;
  }
  ${({ $lineBreak }) =>
    $lineBreak === true &&
    css`
      line-break: anywhere;
    `};
`

export const TextWarning = styled(Html)`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.cinnabar};
  margin-bottom: 2.4rem;
  & b,
  & strong {
    font-weight: 700;
  }
`

export const DeleteButton = styled(ActionButton)``
export const CancelButton = styled(ActionButton)`
  margin-bottom: 1.4rem;
`
