import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import dayjs from 'dayjs'

import { actions, selectors } from '../../redux'
import { sampleStatusFrenchMapping } from '../../types/sampleStatusFrenchMapping'
import {
  ExhibitionForum,
  FlagItem,
  Sample,
  SampleStatus,
  UserRole,
} from '../../graphql/generated/graphql'
import { sampleLabelReturn } from '../../relay/SampleSheet/SampleLabelReturn'
import { router, routesPath } from '../../router'
import { ModalVariant } from '../../components/Modal/types'
import { getContrastColor } from '../../relay/Forums/getContrastColor'
import { Icons } from '../../components/Icon'

import { LayoutSampleSheetProps } from '.'

export const useLayoutSampleSheetTemplateProps = (
  status: SampleStatus | undefined,
  forum: ExhibitionForum | undefined,
  subForm: ExhibitionForum | undefined,
  mkpForum: FlagItem | undefined,
  sample: Sample | undefined,
  category: FlagItem | undefined
): LayoutSampleSheetProps => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false)
  const config = useSelector(selectors.configuration.configurationInfo)
  const user = useSelector(selectors.auth.user)
  const collection = useSelector(selectors.exhibitors.collection)
  const exhibitor = useSelector(selectors.exhibitors.exhibitor)
  const sampleDelete = useSelector(selectors.samples.sampleDelete)
  const sampleRestore = useSelector(selectors.samples.sampleRestore)
  const editoAttach = useSelector(selectors.editos.editorialAttachSamples)
  const { sampleId } = useParams<{ sampleId: string }>()

  const isArchived = sample?.isArchived

  useEffect(() => {
    if (sampleId && (!sample || sample.id !== sampleId || !sample.collection)) {
      dispatch(actions.samples.getSampleByIdRequest({ sampleId }))
    }
  }, [dispatch, sample, sampleId, editoAttach?.success])

  useEffect(() => {
    if (sample && sample.id === sampleId && sample.collection) {
      const { exhibitor, exhibitionApiId } = sample.collection
      if (
        exhibitor &&
        exhibitor.ebmsId &&
        sample.collection.exhibitor.ebmsId !== exhibitor.ebmsId
      ) {
        dispatch(
          actions.exhibitors.exhibitorsCollectionServiceRequest({
            exhibitorEbmsId: exhibitor.ebmsId,
            exhibitionApiId,
          })
        )
      }
    }
  }, [dispatch, sample, sample?.collection, sampleId])

  useEffect(() => {
    if (sampleDelete && sampleDelete?.success && sample) {
      dispatch(actions.samples.resetSampleDelete())
      const link = router(routesPath.exponentSheet, {
        exposantId: sample.collection.exhibitor.ebmsId,
        apiId: config?.exhibitionActiveApiId.toString(),
      })
      navigate(link)
    }
    if (sampleRestore && sampleRestore?.success && sample) {
      dispatch(actions.samples.resetSampleRestore())
      dispatch(actions.samples.getSampleByIdRequest({ sampleId: sample.id }))
      const link = router(routesPath.sampleSheetData, {
        sampleId: sample.id,
      })
      navigate(link)
    }
  }, [
    config?.exhibitionActiveApiId,
    dispatch,
    navigate,
    sample,
    sampleDelete,
    sampleDelete?.success,
    sampleRestore,
  ])

  useEffect(() => {
    if (isToastOpen) {
      setTimeout(() => {
        setIsToastOpen(false)
      }, 1000)
    }
  }, [isToastOpen])

  // useEffect(() => {
  //   sampleId && dispatch(actions.samples.getSampleByIdRequest({ sampleId }))
  // }, [dispatch, sampleId, editoAttach.success])

  return useMemo(() => {
    return {
      bottomActions: {
        note:
          collection?.internalNotes && collection?.internalNotes.length !== 0
            ? {
                rounded: true,
                number: collection?.internalNotes
                  ? collection?.internalNotes.length.toString()
                  : '0',
                onClick: () => {
                  setIsNoteOpen(true)
                },
              }
            : undefined,
      },
      notes: {
        title: "Note interne sur l'exposant",
        notes:
          collection && collection.internalNotes && collection.internalNotes.length !== 0
            ? collection.internalNotes.map((note) => ({
                text: note.body,
                author: {
                  fullName:
                    collection?.internalNotes.length !== 0
                      ? `par ${note.user.lastName} ${note.user.firstName}`
                      : '',
                  date:
                    collection?.internalNotes.length !== 0
                      ? `le ${dayjs(note.writtenAt).format('DD.MM.YYYY')}`
                      : '',
                },
              }))
            : [],
        tags: {
          newTag: exhibitor?.isNew,
          returnTag: collection?.samplesToReturn !== 0,
        },
        onClose: () => setIsNoteOpen(false),
      },
      noteModal: {
        onClose: () => setIsNoteOpen(false),
        isOpen: isNoteOpen,
        variant: ModalVariant.Small,
      },
      deleteModal: sample?.isDeleted
        ? {
            isOpen: isDeleteModalOpen,
            onClose: () => setIsDeleteModalOpen(false),
            text: 'Êtes-vous sûr de vouloir réactiver cette fiche échantillon ?',
            cancelButton: {
              text: 'Annuler',
              onClick: () => setIsDeleteModalOpen(false),
            },
            deleteButton: {
              text: 'Oui',
              rightIconProps: {
                icon: Icons.check,
              },
              onClick: () => {
                setIsDeleteModalOpen(false)
                sample && dispatch(actions.samples.sampleRestoreRequest({ sampleId: sample?.id }))
              },
            },
          }
        : {
            isOpen: isDeleteModalOpen,
            onClose: () => setIsDeleteModalOpen(false),
            text: 'Êtes-vous sûr de vouloir supprimer cette fiche échantillon ?',
            warningText:
              sample?.status === SampleStatus.ValidatedOkMarketplace
                ? 'Cet échantillon a déjà été intégré dans la marketplace.'
                : undefined,
            cancelButton: {
              text: 'Annuler',
              onClick: () => setIsDeleteModalOpen(false),
            },
            deleteButton: {
              text: 'Oui, supprimer',
              onClick: () => {
                setIsDeleteModalOpen(false)
                sample && dispatch(actions.samples.sampleDeleteRequest({ sampleId: sample?.id }))
              },
            },
          },
      modalToast: isToastOpen
        ? {
            text: "La référence de l'échantillon a bien été copiée.",
            isOpen: true,
            icon: { icon: Icons.check, height: 20, width: 20 },
          }
        : undefined,
      sampleInfos: {
        status: status
          ? {
              type:
                status === SampleStatus.ValidatedOkMarketplace
                  ? SampleStatus.SelectedOkShooting
                  : status,
              value:
                status === SampleStatus.ValidatedOkMarketplace
                  ? (sampleStatusFrenchMapping[SampleStatus.SelectedOkShooting] as string)
                  : (sampleStatusFrenchMapping[status] as string),
            }
          : undefined,
        forum:
          forum && subForm && forum.color
            ? {
                values: [forum?.name, subForm?.name],
                backgroundColor: getContrastColor(forum.color) as string,
                color: forum.color,
              }
            : undefined,
        mkpForum: mkpForum ? mkpForum.name : undefined,
        okMkp: sample?.marketplaceImported,
        noMkp: sample?.marketplaceImportError,
        isDelete: sample?.isDeleted,
      },
      exponentInfos: sample?.collection
        ? {
            onClick: () => {
              const link = router(routesPath.exponentSheet, {
                exposantId: sample.collection.exhibitor.ebmsId,
                apiId: config?.exhibitionActiveApiId.toString(),
              })
              navigate(link)
            },
            topInfo: {
              newTag: sample && sample.collection && sample.collection.exhibitor.isNew,
              returnTag: sample && sample.collection && sample.collection.samplesToReturn !== null,
              tag: sample?.collection.exhibitor.name,
              name: sample?.collection.exhibitor.country,
              universe: sample?.universe.name,
              pavilion: sample?.collection.exhibitor.pavilion.name,
            },
          }
        : undefined,
      samplecategory: category ? category.name : undefined,
      sampleId: sample ? sample.id : undefined,
      copyPastOnClick: () => {
        setIsToastOpen(true)
        navigator.clipboard.writeText(`${sample?.name}_${sample?.id}_001`)
      },
      copyPastBtn: {
        icon: Icons.copy,
        width: 18,
        height: 18,
      },
      title: sample ? sample.name : undefined,
      isArchived:
        isArchived && collection
          ? {
              title: collection.exhibition.name,
              text: `Exhibition ID ${collection.exhibition.apiId}`,
              season: `${collection.exhibition.seasonCode}${collection.exhibition.seasonYear}`,
              active: true,
              onClick: () => {
                const link = router(routesPath.exhibitionSheetInfos, {
                  exhibitionId: collection.exhibition.apiId as string,
                })
                navigate(link)
              },
            }
          : undefined,
      warnings:
        sample?.collection && sample?.collection.samples && sample?.collection.samples.length < 5
          ? [sampleLabelReturn(sample?.collection.samples.length)]
          : undefined,
      numbered:
        sample?.collection && sample?.collection.samples && sample?.collection.samples.length > 5
          ? {
              number: sampleLabelReturn(sample?.collection.samples.length),
            }
          : undefined,
      rightButton:
        (user?.role === UserRole.Admin ||
          user?.role === UserRole.SuperAdmin ||
          user?.role === UserRole.ModePv) &&
        !sample?.isDeleted
          ? {
              text: 'Supprimer la fiche',
              rightIconProps: {
                icon: sample?.isDeleted ? Icons.check : Icons.trash,
                width: 18,
                height: 18,
              },
              onClick: () => {
                setIsDeleteModalOpen(true)
              },
            }
          : (user?.role === UserRole.Admin || user?.role === UserRole.SuperAdmin) &&
            sample?.isDeleted
          ? {
              text: 'Réactiver la fiche',
              rightIconProps: {
                icon: sample?.isDeleted ? Icons.returnThin : Icons.trash,
                width: 22,
                height: 22,
              },
              onClick: () => {
                setIsDeleteModalOpen(true)
              },
            }
          : undefined,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    collection,
    config?.exhibitionActiveApiId,
    dispatch,
    exhibitor?.isNew,
    forum,
    isArchived,
    isDeleteModalOpen,
    isNoteOpen,
    mkpForum,
    navigate,
    sample,
    status,
    subForm,
    user?.role,
    sample,
    isToastOpen,
  ])
}
