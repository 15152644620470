import { routesPath } from '../../router'

import {
  routesAccessories,
  routesDesign,
  routesFabrics,
  routesLeather,
  routesYarns,
  routesManufacturing,
} from './RoutesSampleEdit'

const findNextIndex = (
  routes: {
    location: string
    href: string
  }[],
  lastSegment: string,
  direction: 'prev' | 'next'
) => {
  const currentIndex = routes.findIndex((route) => route.location === lastSegment)
  let nextIndex
  if (currentIndex !== -1) {
    if (direction === 'prev') {
      nextIndex = currentIndex - 1
    }
    if (direction === 'prev' && currentIndex === 0) {
      return routesPath.sampleSheets
    } else if (direction === 'next') {
      nextIndex = currentIndex + 1
    }
    if ((nextIndex && nextIndex >= 0 && nextIndex <= routes.length) || nextIndex === 0) {
      const nextLocation = routes[nextIndex].href
      return nextLocation
    }
  }

  return null
}

export const sampleEditRouting = (routeType: string, direction: 'prev' | 'next'): string => {
  const currentURL = window.location.href
  const lastSegment = currentURL.substring(currentURL.lastIndexOf('/') + 1)
  const isInList = currentURL.includes('parametres')
  if (routeType === 'Fabrics') {
    if (isInList) {
      return routesFabrics[0].href
    } else {
      return findNextIndex(routesFabrics, lastSegment, direction)!
    }
  }

  if (routeType === 'Accessories') {
    if (isInList) return routesAccessories[0].href
    else return findNextIndex(routesAccessories, lastSegment, direction)!
  }

  if (routeType === 'Leather') {
    if (isInList) return routesLeather[0].href
    else {
      return findNextIndex(routesLeather, lastSegment, direction)!
    }
  }

  if (routeType === 'Yarns') {
    if (isInList) return routesYarns[0].href
    else return findNextIndex(routesYarns, lastSegment, direction)!
  }

  if (routeType === 'Designs') {
    if (isInList) return routesDesign[0].href
    else return findNextIndex(routesDesign, lastSegment, direction)!
  }

  if (routeType === 'Manufacturing' || routeType === 'Manufacturing-Leather') {
    if (isInList) return routesManufacturing[0].href
    else return findNextIndex(routesManufacturing, lastSegment, direction)!
  }

  return ''
}
