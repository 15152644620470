import React, { FC } from 'react'

import * as SC from './styled'

export type SimpleCheckBoxProps = {
  className?: string
  label?: string
  checked?: boolean
  variant?:
    | 'default'
    | 'validate'
    | 'delete'
    | 'leather'
    | 'sourcing'
    | 'accessories'
    | 'inspiration'
    | 'design'
    | 'forumSelect'
}

const SimpleCheckBox: FC<SimpleCheckBoxProps> = ({ className, label, checked, ...rest }) => {
  return (
    <SC.CheckBoxContainer className={className} htmlFor={label}>
      <SC.TextContainer className="text-content">{label}</SC.TextContainer>
      <input type="checkbox" {...rest} checked={checked} name={label} id={label} />
    </SC.CheckBoxContainer>
  )
}

export default SimpleCheckBox
