import { getQueryService } from '../../../../helpers/GraphqlHelpers'
import { gql } from '../../../generated'

export const exhibitionByApiId = getQueryService({
  query: gql(`
	query exhibitionByApiId($exhibitionApiId: ID!) {
		exhibitionByApiId(exhibitionApiId: $exhibitionApiId) {
			id
			apiId
			name
			seasonCode
			seasonYear
			dateStart
			dateEnd
			shootingFolder
			dateSampleStart
			dateSampleEnd
			isActive
			exhibitors {
				ebmsId
				name
				pavilion {
					name
					ebmsId
				}
				universe {
					name
					ebmsId
				}
				country
				countryCode
			}
			forums {
				id
				name
				color
				subForums {
					id
					name
				}
			}

			editorials {
				id
				name
				description
				samples {
					id
					isDeleted
					name
					photos {
					id
					user {
					id
					email
					}
					url
					path
					order
				}
				}
			}
			boxes {
				id
				name
			}
			createdAt
			updatedAt
		}
	}
`),
  transformer: (response) => {
    return response
  },
})
