import { all, put, takeEvery } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { services } from '../../graphql'

export default class BoxesSagas {
  static *onInit() {
    yield put(actions.boxes.resetForumBoxes())
  }

  static *onForumBoxesSearch({ payload }: ReturnType<typeof actions.boxes.getForumBoxesRequest>) {
    const rs = yield* ApiSagas.call(services.boxes.queries.GetForumBox, payload)
    if (rs.errors) {
      yield put(actions.boxes.getForumBoxesError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.boxes.setForumBoxes(rs.data.boxes))
      yield put(actions.boxes.getForumBoxesSuccess())
      return
    }
  }

  static *onBoxesCreate({ payload }: ReturnType<typeof actions.boxes.createBoxesRequest>) {
    const rs = yield* ApiSagas.call(services.boxes.mutations.createBoxes, payload)
    if (rs.errors) {
      yield put(actions.boxes.createBoxesError(rs.errors))
      return
    }
    if (rs.data) {
      yield put(actions.boxes.createBoxesSuccess())
      return
    }
  }

  static *listeners() {
    yield all([takeEvery(actions.boxes.getForumBoxesRequest, this.onForumBoxesSearch)])
    yield all([takeEvery(actions.boxes.createBoxesRequest, this.onBoxesCreate)])
  }
}
