import React, { ChangeEvent, ForwardRefRenderFunction, useCallback, useId, useMemo } from 'react'
import { ReactSwitchProps } from 'react-switch'
import { useTheme } from 'styled-components'
import classNames from 'classnames'

import * as SC from './styled'
import { FormFieldSwitchSize } from './types'

export type FormFieldSwitchProps = Omit<ReactSwitchProps, 'onChange' | 'checked'> & {
  name: string
  className?: string
  error?: string
  hasError?: boolean
  helpText?: string
  errors?: { message?: string }[]
  label?: string
  inlineLabel?: string
  value?: boolean
  textValue?: string
  required?: boolean
  variantSize?: FormFieldSwitchSize
  onColor?: string
  offColor?: string
  onChange?: (event: ChangeEvent<any>) => void
}

const FormFieldSwitch: ForwardRefRenderFunction<any, FormFieldSwitchProps> = ({
  className,
  name,
  label,
  inlineLabel,
  error,
  helpText,
  textValue,
  onChange,
  value,
  hasError,
  required,
  variantSize,
  onColor,
  offColor,
  ...extraProps
}) => {
  const localId = useId()
  const theme = useTheme()
  const handleChange = useCallback(
    (checked: boolean) => {
      onChange?.({
        target: {
          name,
          value: checked,
        },
      } as ChangeEvent<any>)
    },
    [name, onChange]
  )
  const parsedValue = useMemo(() => JSON.parse(textValue || 'false'), [textValue])
  return (
    <SC.Container className={classNames(className, value ? 'is-on' : 'is-off')}>
      {label && (
        <SC.Label error={!!error || hasError} required={required}>
          {label}
        </SC.Label>
      )}
      <SC.Wrapper>
        <SC.SwitchStyled
          id={localId}
          name={name}
          width={variantSize === FormFieldSwitchSize.Small ? 40 : 56}
          height={variantSize === FormFieldSwitchSize.Small ? 20 : 28}
          handleDiameter={variantSize === FormFieldSwitchSize.Small ? 16 : 20}
          required={required}
          {...extraProps}
          checked={!value ? parsedValue : value}
          onChange={handleChange}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={onColor ? onColor : theme.colors.mineShaft}
          offColor={offColor ? offColor : theme.colors.silver}
        />
        {inlineLabel && <SC.InlineLabel htmlFor={localId}>{inlineLabel}</SC.InlineLabel>}
      </SC.Wrapper>
      {!error && helpText && <SC.Help>{helpText}</SC.Help>}
      {error && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldSwitch
