import styled, { css } from 'styled-components'

import { fonts } from '../../theme'
import Link from '../Link'

export const MenuLink = styled(Link)<{ $removeBorder?: boolean; $selected?: boolean }>`
  ${fonts.RobotoLight};
  display: block;
  font-size: 2rem;
  line-height: 1.2;
  text-align: left;
  width: 100%;
  padding: 2.3rem 0;
  padding-left: 1.2rem;
  border: none;
  text-decoration: none;

  ${({ $removeBorder }) =>
    !$removeBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaft};
    `};

  ${({ $selected }) =>
    $selected &&
    css`
      ${fonts.RobotoRegular};
    `};

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    color: ${({ theme }) => theme.colors.pureWhite};
    border-color: ${({ theme }) => theme.colors.pureWhite};
    ${({ theme }) => theme.textStyles.textBook};
    display: flex;
    align-items: center;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      background-color: ${({ theme }) => theme.colors.pureWhite};
      opacity: 0;
      border-radius: 50%;
      margin-right: 1.2rem;
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    ${({ $selected }) =>
      $selected &&
      css`
        ${({ theme }) => theme.textStyles.textBold};
        &:before {
          opacity: 1;
        }
      `};
  }
`
