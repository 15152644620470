import React from 'react'
import { RouteProps } from 'react-router-dom'

import BoxesAddTemplate from '../../templates/BoxesAdd'
import { boxesAddTemplateMocks } from '../../templates/BoxesAdd/mocks'

const Boxes: React.FC<RouteProps> = () => {
  return <BoxesAddTemplate {...boxesAddTemplateMocks.basic} />
}

export default Boxes
