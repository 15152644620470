import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import {
  AuthCreateUserInput,
  InputMaybe,
  User,
  UserRole,
  UserUpdateInput,
} from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const updatePasswordService = getCustomService<
  'passwordUpdate',
  null,
  { oldPassword: string; newPassword: string }
>('passwordUpdate')

export const getUsersService = getCustomService<
  'getUsers',
  null,
  {
    q: string
    first: number
    page: number
    roles?: InputMaybe<UserRole | UserRole[]> | undefined
  }
>('getUsers')

export const createUserService = getCustomService<
  'createUser',
  null,
  {
    input: AuthCreateUserInput
  }
>('createUser')

export const updateUserService = getCustomService<
  'updateUser',
  User,
  {
    userId: string
    input: UserUpdateInput
  }
>('updateUser')

export const userDeleteService = getCustomService<
  'userDelete',
  User,
  {
    userId: string
  }
>('userDelete')

//
// Initial state
//

export type UserState = {
  successMessage: string | undefined
  users: any
  user: User | undefined
  // services
  passwordUpdate: typeof updatePasswordService.state
  getUsers: typeof getUsersService.state
  createUser: typeof createUserService.state
  updateUser: typeof updateUserService.state
  userDelete: typeof userDeleteService.state
}

const initialState: UserState = {
  successMessage: undefined,
  users: undefined,
  user: undefined,
  // services
  passwordUpdate: updatePasswordService.state,
  getUsers: getUsersService.state,
  createUser: createUserService.state,
  updateUser: updateUserService.state,
  userDelete: userDeleteService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: () => initialState,
    setSuccessMessage: (state, action: actionTypes.setSuccessMessage) => {
      state.successMessage = action.payload
    },
    setUsers: (state, { payload }: actionTypes.setUsers) => {
      state.users = payload
    },
    setUser: (state, { payload }: actionTypes.setUser) => {
      state.user = payload
    },
    resetCreateUser: (state) => {
      state.createUser = initialState.createUser
    },
    resetUpdateUser: (state) => {
      state.updateUser = initialState.updateUser
    },
    resetUserDelete: (state) => {
      state.userDelete = initialState.userDelete
    },
    ...updatePasswordService.reducers,
    ...getUsersService.reducers,
    ...createUserService.reducers,
    ...updateUserService.reducers,
    ...userDeleteService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const passwordUpdate = (state: RootState) => root(state).passwordUpdate
const getUsers = (state: RootState) => root(state).getUsers
const createUser = (state: RootState) => root(state).createUser
const updateUser = (state: RootState) => root(state).updateUser
const userDelete = (state: RootState) => root(state).userDelete
const successMessage = (state: RootState) => root(state).successMessage
const users = (state: RootState) => root(state).users
const user = (state: RootState) => root(state).user

export const selectors = {
  passwordUpdate,
  getUsers,
  createUser,
  updateUser,
  userDelete,
  users,
  user,
  successMessage,
}
